import { BulkImportFinishedStates } from '@constants/commontypes';
import includes from 'lodash/includes';
import { useEffect } from 'react';

const useRefetchImportStatusInterval = ({ status, setShouldRefetchImportStatus }) => {
  useEffect(() => {
    setShouldRefetchImportStatus(!includes(BulkImportFinishedStates, status));
  }, [status]);
};

export default useRefetchImportStatusInterval;
