import {
  cacheKeydownloadURL,
  dataSourceLandingPagesUrl,
  getAttributeSetsUrl,
  getCategoryNodesUrl,
  getCollectionsUrl,
  getLanguagesUrl,
  getMeUrl,
  getProductCollectionWidgetsUrl,
  getRejectionReasonsUrl,
  getWidgetSchemaUrl,
  patchWidgetUrl,
  postProductCollectionWidgetUrl,
  resetPasswordUrl,
  resetPasswordWithTokenUrl,
  widgetsUrl,
} from '@constants/apiUrls';
import { limitQuery } from '@constants/commontypes';
import { client } from '@services/api/client';

export const getLanguages = () => {
  return client.get(getLanguagesUrl);
};

export const getUser = () => {
  return client.get(getMeUrl);
};

export const getDownloadUrl = (cacheKey) => {
  return client.get(cacheKeydownloadURL, { urlParams: { cacheKey } });
};

export const getCategories = () => {
  return client.get(getCategoryNodesUrl, {
    params: {
      ...limitQuery,
    },
  });
};

/**
 * @param {{ requestConfig: import('axios').AxiosRequestConfig }} args
 */
export const getRejectionReasons = ({ requestConfig }) => {
  return client.get(getRejectionReasonsUrl, requestConfig);
};

/**
 * @param {{ requestConfig: import('axios').AxiosRequestConfig }} args
 */
export const getAttributeSets = ({ requestConfig }) => {
  return client.get(getAttributeSetsUrl, requestConfig);
};

export const getDownloadableContent = ({ downloadUrl }) => {
  delete client.defaults.headers.Authorization;

  return client.get(downloadUrl, {
    responseType: 'blob',
  });
};

export const getWidgetSchema = ({ requestConfig, schemaId }) => {
  return client.get(getWidgetSchemaUrl, { ...requestConfig, urlParams: { schemaId } });
};

export const getWidgets = ({ requestConfig }) => {
  return client.get(widgetsUrl, requestConfig);
};

export const postWidget = ({ requestConfig, requestBody }) => {
  return client.post(widgetsUrl, requestBody, requestConfig);
};

export const patchWidget = ({ requestConfig, requestBody, widgetId }) => {
  return client.patch(patchWidgetUrl, requestBody, {
    ...requestConfig,
    urlParams: { widgetId },
  });
};

export const getCollections = ({ requestConfig }) => {
  return client.get(getCollectionsUrl, requestConfig);
};

export const postProductCollectionWidgets = ({ requestConfig, requestBody }) => {
  return client.post(postProductCollectionWidgetUrl, requestBody, requestConfig);
};

export const getProductCollectionWidgets = ({ requestConfig }) => {
  return client.get(getProductCollectionWidgetsUrl, requestConfig);
};

export const getDataSourceLandingPages = ({ requestConfig }) => {
  return client.get(dataSourceLandingPagesUrl, requestConfig);
};

export const postDataSourceLandingPages = ({ requestConfig, requestBody }) => {
  return client.post(dataSourceLandingPagesUrl, requestBody, requestConfig);
};

export const postResetPassword = ({ requestConfig, requestBody }) => {
  return client.post(resetPasswordUrl, requestBody, requestConfig);
};

export const putResetPassword = ({ requestConfig, requestBody, resetToken }) => {
  return client.put(resetPasswordWithTokenUrl, requestBody, {
    ...requestConfig,
    urlParams: { resetToken },
  });
};
