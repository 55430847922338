import {
  AttributeDataType,
  booleanAttributeOptions,
  FilterType,
  WidgetType,
} from '@constants/commontypes';
import { QueryKey } from '@constants/query';
import { queryClient } from '@services/api/queryClient';
import cloneDeep from 'lodash/cloneDeep';

export const getModalFormMeta = (fields) => ({
  columns: 4,
  fields,
});

export const getDrawerFormMeta = (fields) => ({
  columns: 1,
  fields,
});

export const transformFiltersFormMetaFieldsToModalFormMetaFields = (filtersFormMetaFields) =>
  filtersFormMetaFields.map((metaField) => ({
    key: metaField.key,
    widget: WidgetType.CHECKBOX,
    children: metaField.placeholder,
  }));

export const getModalFormInitialValues = (currentFiltersFormMeta) =>
  currentFiltersFormMeta.fields.reduce(
    (modalFormInitialValues, metaField) => ({
      ...modalFormInitialValues,
      [metaField.key]: true,
    }),
    {}
  );

export const getActiveFilters = ({
  selectedModalFilterKeys,
  staticFiltersFormMetaFields,
  dynamicFiltersFormMetaFields,
}) => {
  return [...staticFiltersFormMetaFields, ...dynamicFiltersFormMetaFields].filter((metaField) =>
    selectedModalFilterKeys.includes(metaField.key)
  );
};

export const fillFilterWidgets = async ({ metaFields }) => {
  const fetchQuery = ({ key, id }) => {
    return queryClient.fetchQuery({
      queryKey: [key, id],
      staleTime: Infinity,
    });
  };

  const newMetaFields = await Promise.all(
    metaFields.map(async (metaField) => {
      const newMetaField = cloneDeep(metaField);

      if (metaField.filterType !== FilterType.DYNAMIC) {
        return metaField;
      }

      switch (metaField.data_type) {
        case AttributeDataType.BOOLEAN:
          newMetaField.widget = WidgetType.SELECT;
          newMetaField.options = booleanAttributeOptions;
          break;
        case AttributeDataType.DROPDOWN:
          const response = await fetchQuery({
            key: QueryKey.ATTRIBUTE_VALUES,
            id: newMetaField.id,
          });
          newMetaField.options = response?.results ?? [];
          break;
        default:
          break;
      }

      return newMetaField;
    })
  );

  return newMetaFields;
};
