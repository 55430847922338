import { resolveIntegrationErrorUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useQueryClient } from '@tanstack/react-query';

import { useAkinonMutation } from '../adapters/useAkinonMutation';
import { client } from '../client';

export function useResolveIntegrationErrorMutation() {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ integrationErrorId }) => {
      return client.post(resolveIntegrationErrorUrl, undefined, {
        urlParams: { integrationErrorId },
        params: { integrationErrorId },
      });
    },
    onSuccess() {
      queryClient.invalidateQueries(QueryKey.INTEGRATION_ERRORS);
    },
  });

  return {
    resolveIntegrationError: mutate,
    isResolvingIntegrationError: isLoading,
  };
}
