import { RouteUrls } from '@constants/routeUrls';
import map from 'lodash/map';

export const authenticationPaths = [
  RouteUrls.public.signIn,
  RouteUrls.public.forgotPassword,
  RouteUrls.public.resetPassword,
];

/**
 * `appPath = /{rootPath}/{subPath}`
 * `mainPath = rootPath + subPath`
 */
export const getPathFragments = (path) => {
  const pathSlices = path.split('/');

  return {
    rootPath: `/${pathSlices[1]}`,
    subPath: `/${pathSlices.slice(2).join('/')}`,
    mainPath: `/${pathSlices.slice(1).join('/')}`,
  };
};

export const getRedirectLocationDescriptor = ({ targetUrl, redirectUrl }) => ({
  pathname: targetUrl,
  search: `?redirectUrl=${redirectUrl}`,
});

export function getWithAncestorKeys(path) {
  if (!path) return [];
  return map(path.split('/'), (part, index, array) => {
    return array.slice(0, index + 1).join('/');
  }).slice(1);
}
