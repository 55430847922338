import { approvePreOfferURL, submitPreOfferURL } from '@constants/apiUrls';
import { client } from '@services/api/client';

import { PreOfferStatus } from '../../common';

const useApproveOffers = ({ refetchOffers }) => {
  const approveSelectedItems = async ({ selectedRows, mutationOptions }) => {
    const { onSuccess, onError } = mutationOptions;

    const approvalRequiredSelectedRows = selectedRows.filter(
      (selectedRow) => selectedRow.approval_status === PreOfferStatus.APPROVAL_REQUIRED
    );

    const approvePreOfferPromises = approvalRequiredSelectedRows.map((row) => {
      return () => client.post(submitPreOfferURL, {}, { urlParams: { id: row.id } });
    });

    const approvalPromises = selectedRows.map((row) => {
      return () =>
        client
          .post(approvePreOfferURL, {}, { urlParams: { id: row.id } })
          .then((result) => {
            onSuccess(result);
          })
          .catch((error) => {
            onError(error);
          });
    });

    await Promise.allSettled(approvePreOfferPromises.map((f) => f()));
    await Promise.allSettled(approvalPromises.map((f) => f()));
    refetchOffers();
  };

  return { approveSelectedItems };
};

export default useApproveOffers;
