import AkinonFilter from '@components/AkinonFilter';
import { usePageFilters } from '@components/AkinonFilter/hooks/store/usePageFilters';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { getCollectionsUrl } from '@constants/apiUrls';
import { UserRole } from '@constants/auth';
import { defaultRowKey } from '@constants/index';
import { RouteUrls } from '@constants/routeUrls';
import { useTour } from '@reactour/tour';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { useUserRole } from '@utils/hooks/useUserRole';
import { Divider } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getColumns, getTourSteps } from './common';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { useCollectionsData } from './hooks/useCollectionsData';
import { useDynamicFilters } from './hooks/useDynamicFilters';
import { usePageMeta } from './hooks/usePageMeta';
import { useStaticFilters } from './hooks/useStaticFilters';

const CollectionList = () => {
  const { t } = useTranslation('Marketing');
  const navigate = useAppNavigate();
  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;
  const filters = usePageFilters();
  const [pagination, setPagination] = usePagination();
  const { data, isLoading, total } = useCollectionsData({ pagination, filters });
  const staticFilters = useStaticFilters({ isSuperUser, isPending: isLoading });
  const dynamicFilters = useDynamicFilters({ isPending: isLoading });
  const columns = getColumns({ t, isSuperUser });

  const { setSteps } = useTour();

  useEffect(() => {
    setSteps(
      getTourSteps({
        t,
      })
    );
  }, [t]);

  useBreadcrumbs();
  usePageMeta();

  const onRowClick = (record) => () => {
    navigate(RouteUrls.marketing.productCollections.form, { id: record?.id });
  };

  return (
    <section>
      <AkinonFilter title={t('filters')} {...staticFilters} {...dynamicFilters} total={total} />
      <Divider />
      <AkinonTable
        title={t('collections')}
        columns={columns}
        dataSource={data}
        optionsUrl={getCollectionsUrl}
        loading={isLoading}
        total={total}
        pagination={pagination}
        rowKey={defaultRowKey}
        setPagination={setPagination}
        description={`${total} ${t('results.found')}`}
        onRow={(record) => ({ onClick: onRowClick(record) })}
      />
    </section>
  );
};

export default CollectionList;
