import './styles.scss';

import { Empty } from 'antd';
import clsx from 'clsx';
import React from 'react';

/**
 * @param {import('antd').EmptyProps} props
 */
function AkinonEmpty({ children, className, ...otherProps }) {
  return (
    <Empty className={clsx('akinon-empty', className)} {...otherProps}>
      {children}
    </Empty>
  );
}

export default AkinonEmpty;
