import { createSelectOptions } from '@common/index';
import {
  getApprovalStatusOptions,
  getIsNullBooleanAttributeOptions,
  WidgetType,
} from '@constants/commontypes';
import cond from 'lodash/cond';
import constant from 'lodash/constant';
import stubTrue from 'lodash/stubTrue';

export const getFormMeta = ({ t }) => [
  {
    key: 'size',
    label: t('product.pool.selected.product.modal.form.size'),
    placeholder: t('product.pool.selected.product.modal.form.size'),
  },
  {
    key: 'stock.code',
    label: t('product.pool.selected.product.modal.form.stock.code'),
    placeholder: t('product.pool.selected.product.modal.form.stock.code'),
  },
  {
    key: 'stock.amount',
    label: t('product.pool.selected.product.modal.form.stock.amount'),
    placeholder: t('product.pool.selected.product.modal.form.stock.amount'),
  },
  {
    key: 'price',
    label: t('product.pool.selected.product.modal.form.price'),
    placeholder: t('product.pool.selected.product.modal.form.price'),
  },
];

export const StaticFilterKey = {
  DATASOURCE: 'datasource',
  NAME: 'name__icontains',
  SKU: 'sku',
  BASE_CODE: 'base_code',
  CATEGORY: 'category',
  PRODUCT_ISNULL: 'product__isnull',
  APPROVAL_STATUS: 'approval_status',
  STATUS: 'status',
  IMAGE_ISNULL: 'productimage__isnull',
};

export const getStaticFilters = ({
  t,
  dataSources,
  categoryTreeData,
  isDataSourcesLoading,
  isCategoriesLoading,
  isSuperUser,
}) => ({
  staticFiltersFormMetaFields: [
    isSuperUser && {
      key: StaticFilterKey.DATASOURCE,
      widget: WidgetType.SELECT,
      widgetProps: {
        showSearch: true,
        optionFilterProp: 'label',
      },
      placeholder: t('seller'),
      options: createSelectOptions(dataSources, {
        labelKey: 'name',
        valueKey: 'id',
      }),
    },
    {
      key: StaticFilterKey.NAME,
      widget: WidgetType.INPUT,
      placeholder: t('product_name'),
    },
    {
      key: StaticFilterKey.SKU,
      widget: WidgetType.INPUT,
      placeholder: t('sku'),
    },
    {
      key: StaticFilterKey.BASE_CODE,
      widget: WidgetType.INPUT,
      placeholder: t('basecode'),
    },
    {
      key: StaticFilterKey.PRODUCT_ISNULL,
      widget: WidgetType.CHECKBOX_GROUP,
      placeholder: t('offer.product.related'),
      options: [{ label: t('offer.product.related'), value: false }],
    },
    {
      key: StaticFilterKey.CATEGORY,
      widget: WidgetType.TREESELECT,
      placeholder: t('category'),
      widgetProps: {
        showSearch: true,
        treeDefaultExpandAll: true,
        virtual: false,
        style: { width: '100%' },
        loading: isCategoriesLoading,
        dropdownStyle: { maxHeight: 400, overflow: 'auto' },
        treeData: categoryTreeData,
        treeNodeFilterProp: 'title',
        allowClear: true,
      },
    },
    {
      key: StaticFilterKey.APPROVAL_STATUS,
      widget: WidgetType.SELECT,
      widgetProps: {
        showSearch: true,
        optionFilterProp: 'label',
      },
      placeholder: t('offer.status'),
      options: getApprovalStatusOptions({ t }),
    },
    {
      key: StaticFilterKey.IMAGE_ISNULL,
      widget: WidgetType.SELECT,
      widgetProps: {
        showSearch: true,
        optionFilterProp: 'label',
      },
      placeholder: t('product_image'),
      options: getIsNullBooleanAttributeOptions({ t }),
    },
  ].filter(Boolean),
  isStaticFiltersLoading: isDataSourcesLoading,
});

export const PreOfferStatus = {
  REJECTED: 0,
  APPROVED: 1,
  PENDING_APPROVAL: 2,
  APPROVAL_REQUIRED: 3,
};

export const OfferStatus = {
  rejected: '0',
  approved: '1',
  'pending-approval': '2,3,4',
};

export const SuperUserOfferStatus = {
  rejected: '0',
  approved: '1',
  'pending-approval': '2,3',
};

export const ApprovalStatus = {
  SUBMITTED: 2,
  WAITING_FOR_APPROVAL: 3,
  SENDING: 4,
};

export const getOfferStatusMap = ({ isSuperUser, filters }) => {
  const offerStatusMap = new Map(Object.entries(OfferStatus));
  offerStatusMap.set(
    'pending-approval',
    cond([
      [({ filters }) => filters?.approval_status__in, constant(filters.approval_status__in)],
      [({ isSuperUser }) => isSuperUser, constant('2')],
      [stubTrue, constant('2,3,4')],
    ])({ filters, isSuperUser })
  );
  return offerStatusMap;
};

export const PendingOffersColumKey = {
  MSP: 'MSP',
  PRICE: 'PRICE',
  CURRENCY: 'CURRENCY',
  STOCK: 'STOCK',
};

export const PendingOffersDataIndex = {
  MSP: ['prices', '0', 'retail_price'],
  PRICE: ['prices', '0', 'price'],
  CURRENCY: ['prices', '0', 'currency_type'],
  STOCK: ['stocks', '0', 'stock'],
};

export const RejectedOffersColumKey = {
  MSP: 'MSP',
  PRICE: 'PRICE',
  CURRENCY: 'CURRENCY',
  STOCK: 'STOCK',
};

export const RejectedOffersDataIndex = {
  MSP: ['prices', '0', 'retail_price'],
  PRICE: ['prices', '0', 'price'],
  CURRENCY: ['prices', '0', 'currency_type'],
  STOCK: ['stocks', '0', 'stock'],
};
