import { openNotification } from '@components/akinonNotification';
import {
  approvePreOfferUrl,
  downloadBulkProductOfferCSVTemplateURL,
  downloadBulkProductOfferXLSXTemplateURL,
  exportPreOffersUrl,
  getPreOfferUrl,
  patchPreOffersUrl,
  postPreOfferFileUploadURL,
  postPreOffersUrl,
  preOfferImagesUrl,
  rejectPreOfferUrl,
  submitAllPreOffersUrl,
  submitPreOfferUrl,
} from '@constants/apiUrls';
import { HttpMethod } from '@constants/commontypes';
import { client } from '@services/api/client';
import { T } from '@utils/languageProvider';

export const approvePreOffer = ({ preOfferId, requestBody }) => {
  return client.post(approvePreOfferUrl, requestBody, {
    urlParams: {
      preOfferId,
    },
  });
};

export const submitPreOffer = ({ preOfferId, requestBody }) => {
  return client.post(submitPreOfferUrl, requestBody, {
    urlParams: {
      preOfferId,
    },
  });
};

export const submitAllPreOffers = () => {
  return client.post(submitAllPreOffersUrl);
};

export const postPreOffers = async ({ requestBodyList, imageFileListSet = [] }) => {
  const settledPreOffers = await Promise.allSettled(
    requestBodyList.map(
      (requestBody) => requestBody.sku && client.post(postPreOffersUrl, requestBody)
    )
  );
  const numberOfRejectedPreOffers = settledPreOffers.filter(
    (settledPreOffer) => settledPreOffer.status === 'rejected'
  ).length;
  if (numberOfRejectedPreOffers === requestBodyList.length) {
    return Promise.reject();
  }
  if (numberOfRejectedPreOffers > 0) {
    openNotification({
      message: T('process.failed'),
      description: T('pre.offers.post.error.message', { amount: numberOfRejectedPreOffers }),
      type: 'error',
    });
  }
  return await Promise.allSettled(
    imageFileListSet.map(async (imageFileList, index) => {
      const preOffer = settledPreOffers[index].value;
      if (preOffer) {
        return Promise.allSettled(
          imageFileList.map((imageFile, imageFileIndex) => {
            const formData = new FormData();
            formData.append('parent', preOffer.id);
            formData.append('image', imageFile);
            formData.append('order', imageFileIndex);
            return client.post(preOfferImagesUrl, formData);
          })
        ).then((settledPreOfferImages) => {
          const numberOfRejectedPreOfferImages = settledPreOfferImages.filter(
            (settledPreOfferImage) => settledPreOfferImage.status === 'rejected'
          ).length;
          if (numberOfRejectedPreOfferImages > 0) {
            openNotification({
              message: T('process.failed'),
              description: T('pre.offer.images.post.error.message', {
                amount: numberOfRejectedPreOfferImages,
              }),
              type: 'error',
            });
          }
        });
      }
    })
  );
};

export const patchPreOffers = ({ preOfferId, requestBody }) => {
  return client.patch(patchPreOffersUrl, requestBody, {
    urlParams: { preOfferId },
  });
};

export const rejectPreOffer = ({ preOfferId, requestBody }) => {
  return client.post(rejectPreOfferUrl, requestBody, {
    urlParams: {
      preOfferId,
    },
  });
};

export const getPreOffer = ({ preOfferId }) => {
  return client.get(getPreOfferUrl, {
    urlParams: {
      preOfferId,
    },
  });
};

export const postBulkImportPreOffer = ({ formData, onProgress }) => {
  return client.post(postPreOfferFileUploadURL, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent) => onProgress?.(progressEvent),
  });
};

/**
 * @param {import('axios').AxiosRequestConfig} requestConfig
 */
export const getPreOfferImages = (requestConfig) => {
  return client.get(preOfferImagesUrl, requestConfig);
};

export const TemplateTypeURLPreOffer = {
  CSV: downloadBulkProductOfferCSVTemplateURL,
  XLSX: downloadBulkProductOfferXLSXTemplateURL,
};

/**
 *
 * @param {Object} args
 * @param {string} args.attribute_set
 * @param {keyof TemplateTypeURLPreOffer} args.type
 * @returns
 */
export const getBulkTemplatePreOffer = ({ category, type }) => {
  return client.get(TemplateTypeURLPreOffer[type], { params: { category } });
};

export const getExportPreOffers = async ({ requestConfig, fileFilter }) => {
  if (fileFilter) {
    const formData = new FormData();
    formData.append('filter_file', new Blob([fileFilter.originFileObj]), fileFilter.name);
    return await client.post(exportPreOffersUrl, formData, {
      ...requestConfig,
      headers: {
        'Origin-Method': HttpMethod.GET,
        ...requestConfig?.headers,
      },
    });
  } else {
    return await client.get(exportPreOffersUrl, requestConfig);
  }
};
