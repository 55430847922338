import { UserRole } from '@constants/auth';
import { usePaginatedTicketsQuery } from '@services/api/hooks/usePaginatedTicketsQuery';
import { useUsersQuery } from '@services/api/hooks/usePaginatedUsersQuery';
import identity from 'lodash/identity';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

export function useDataSource({ pagination, filters, dataSources, userRole }) {
  const { tickets, totalTickets, isFetchingTickets } = usePaginatedTicketsQuery({
    pagination,
    filters,
  });
  const assigneeIds = uniq(tickets?.map((ticket) => ticket.assignee)?.filter(identity));
  const { users, isFetchingUsers } = useUsersQuery({
    params: {
      id__in: assigneeIds.join(','),
    },
    queryOptions: {
      enabled: !isEmpty(assigneeIds) && !isFetchingTickets && userRole === UserRole.SUPER_USER,
    },
  });

  const dataSource = tickets?.map((ticket) => {
    const userName = dataSources?.find((seller) => seller.id === ticket.datasource)?.name;
    const assignee = users?.find((user) => user.id === ticket.assignee);

    return {
      ...ticket,
      assignee,
      seller: userName,
    };
  });

  return {
    dataSource,
    total: totalTickets,
    isDataSourceLoading: isFetchingTickets || isFetchingUsers,
  };
}
