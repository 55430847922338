import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { QueryKey } from '@constants/query';
import { getCancellationRequests } from '@services/api/orders/cancellation';
import { useQuery } from '@tanstack/react-query';

export function useCancellationRequestsQuery({ pagination, filters, enabled = true }) {
  const paginationParams = getRequestPaginationParams(pagination);
  const filtersParams = getRequestFiltersParams(filters);

  const { data, isFetching, refetch, ...rest } = useQuery({
    queryKey: [QueryKey.CANCELLATION, paginationParams, filtersParams],
    queryFn: () => getCancellationRequests({ ...paginationParams, ...filtersParams }),
    enabled,
  });

  return {
    ...rest,
    cancellationRequests: data?.results,
    isCancellationRequestsLoading: isFetching,
    refetchCancellationRequests: refetch,
    total: data?.count,
  };
}
