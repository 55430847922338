import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

export const FormKey = {
  REASON: 'reason',
};

export const rejectionReasonFormResolver = zodResolver(
  z.object({
    [FormKey.REASON]: z.string().min(1),
  })
);
