import { getTotalItemsUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { useAkinonQuery } from '../adapters/useAkinonQuery';
import { client } from '../client';

export function useTotalItemsQuery({ params }) {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.TOTAL_ITEMS, params],
    queryFn: () => client.get(getTotalItemsUrl, { params }),
  });

  return {
    totalItems: data?.total_items,
    isFetchingTotalItems: isFetching,
  };
}
