import { defaultDateFormat } from '@constants/index';
import { StatusText } from '@pages/Orders/common';
import moment from 'moment';

export const FormFieldKey = {
  QUICK_MESSAGE: 'quick_message',
  MESSAGE_CONTENT: 'message_content',
};

export const ContentType = {
  USER: 'user',
  DATASOURCE: 'datasource',
};

export const getOrderInformations = ({ t, conversation, order }) => {
  const { item_object, user } = conversation || {};

  return [
    {
      name: t('order.number'),
      value: item_object?.order_number,
    },
    {
      name: t('order.date'),
      value: moment(order?.date_placed).format(defaultDateFormat),
    },
    {
      name: t('order.status'),
      value: StatusText({ t })?.[order?.status],
    },
    {
      name: t('customer.name'),
      value: [user?.first_name, user?.last_name].join(' '),
    },
  ];
};
