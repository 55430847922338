import { oAuthProviderBySlugUrl } from '@constants/apiUrls';

import { useAkinonMutation } from '../adapters/useAkinonMutation';
import { client } from '../client';

export function usePatchOauthProviderMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ slug, requestBody }) =>
      client.patch(oAuthProviderBySlugUrl, requestBody, { urlParams: { slug } }),
    ...mutationOptions,
  });

  return {
    updateProvider: mutate,
    isProviderUpdating: isLoading,
  };
}
