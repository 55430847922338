import {
  createMessageUrl,
  getConversationsUrl,
  getConversationUrl,
  getQuickRepliesUrl,
  getQuickReplyUrl,
} from '@constants/apiUrls';
import { client } from '@services/api/client';

export const getConversation = ({ conversationId }) => {
  return client.get(getConversationUrl, {
    urlParams: { conversationId },
  });
};

export const getConversations = ({ requestConfig }) => {
  return client.get(getConversationsUrl, requestConfig);
};

export const getQuickReplies = ({ requestConfig }) => {
  return client.get(getQuickRepliesUrl, requestConfig);
};

export const getQuickReply = ({ quickReplyId }) => {
  return client.get(getQuickReplyUrl, {
    urlParams: { quickReplyId },
  });
};

export const createQuickReply = ({ requestBody }) => {
  return client.post(getQuickRepliesUrl, requestBody);
};

export const updateQuickReply = ({ quickReplyId, requestBody }) => {
  return client.patch(getQuickReplyUrl, requestBody, {
    urlParams: { quickReplyId },
  });
};

export const createMessage = ({ requestBody }) => {
  return client.post(createMessageUrl, requestBody);
};

export const patchConversation = ({ conversationId, requestBody }) => {
  return client.patch(getConversationUrl, requestBody, { urlParams: { conversationId } });
};
