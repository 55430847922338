import * as z from 'zod';

export const DescriptionFormKey = {
  SELLER_DESCRIPTION: `descriptionForm.seller_description`,
};

export const getDescriptionFormSchema = ({ t }) => {
  return z.object({
    descriptionForm: z.object({
      seller_description: z
        .string()
        .min(1)
        .transform((value, ctx) => {
          if (value === '<p><br></p>') {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: t('formRule.required'),
            });
            return z.NEVER;
          } else return value;
        }),
    }),
  });
};
