import AkinonFilter from '@components/AkinonFilter';
import { usePageFilters } from '@components/AkinonFilter/hooks/store/usePageFilters';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { getBasketOfferUrl } from '@constants/apiUrls';
import { UserRole } from '@constants/auth';
import { defaultRowKey } from '@constants/index';
import { RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { useBasketOfferQuery } from '@services/api/hooks/useBasketOfferQuery';
import { useUserRole } from '@utils/hooks/useUserRole';
import { Divider } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getColumns } from './common';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { useDynamicFilters } from './hooks/useDynamicFilters';
import { usePageMeta } from './hooks/usePageMeta';
import { useStaticFilters } from './hooks/useStaticFilters';

const CampaignList = () => {
  const { t } = useTranslation('Marketing');
  const navigate = useAppNavigate();
  const userRole = useUserRole();
  const isSuperuser = userRole === UserRole.SUPER_USER;
  const filters = usePageFilters();
  const [pagination, setPagination] = usePagination();
  const { basketOffers, isBasketOffersLoading, total } = useBasketOfferQuery({
    pagination,
    filters,
  });

  const staticFilters = useStaticFilters({ isSuperuser, isPending: isBasketOffersLoading });
  const dynamicFilters = useDynamicFilters({ isPending: isBasketOffersLoading });

  const columns = getColumns({ t });

  useBreadcrumbs();
  usePageMeta();

  const onRowClick = (record) => () => {
    navigate(RouteUrls.marketing.campaigns.form, { id: record?.id });
  };

  return (
    <section>
      <AkinonFilter title={t('filters')} {...staticFilters} {...dynamicFilters} total={total} />
      <Divider />
      <AkinonTable
        title={t('campaigns')}
        columns={columns}
        dataSource={basketOffers}
        optionsUrl={getBasketOfferUrl}
        loading={isBasketOffersLoading}
        total={total}
        pagination={pagination}
        rowKey={defaultRowKey}
        setPagination={setPagination}
        description={`${total} ${t('results.found')}`}
        onRow={(record) => ({ onClick: onRowClick(record) })}
      />
    </section>
  );
};

export default CampaignList;
