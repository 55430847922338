import './Dashboard.scss';

import { dateFormatWithHyphen } from '@common/date';
import basicStyle from '@common/theme/basicStyle';
import AkinonRangePicker from '@components/AkinonRangePicker';
import If from '@components/If';
import Icon from '@components/Intrinsic';
import Box from '@components/utility/box/index';
import { PackageStatus } from '@constants/commontypes';
import { useLanguage } from '@root/hooks/useLanguage';
import { Skeleton } from 'antd';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { generateChartData } from './common';
import OrderByDateChart from './components/OrderByDateChart';
import { useDashboardQueries } from './hooks/useDashboardQueries';

const DataNotFound = ({ t }) => {
  return (
    <div className="dataWarning">
      <Icon className="icon-warning_triangle" />
      <span>{t('data.not.found')}</span>
    </div>
  );
};

const SmallCardWithSkeleton = ({ children, loading }) => {
  return (
    <If
      condition={!loading}
      then={children}
      otherwise={<Skeleton.Input className="dashboard__small-card-with-skeleton" active />}
    />
  );
};

const BigCardWithSkeleton = ({ children, loading, skeletonRows = 5 }) => {
  return (
    <Skeleton
      loading={loading}
      active
      title={false}
      className="dashboard__big-card-with-skeleton"
      round
      paragraph={{
        rows: skeletonRows,
        width: '100%',
      }}
    >
      {children}
    </Skeleton>
  );
};

const Dashboard = () => {
  const { t } = useTranslation('Dashboard');
  const { rowStyle } = basicStyle;
  const { language } = useLanguage();
  const [dateRange, setDateRange] = useState([moment().subtract(1, 'month'), moment()]);
  const [startDate, endDate] = dateRange;

  const {
    activeSku,
    isFetchingActiveSku,
    totalStock,
    isFetchingTotalStock,
    totalAmount,
    isFetchingTotalAmount,
    totalItems,
    isFetchingTotalItems,
    fulfillmentTime,
    isFetchingFulfillmentTime,
    grade,
    isFetchingGrade,
    cancelRatio,
    isFetchingCancelRatio,
    orderCountByDate,
    isFetchingOrderCountByDate,
    mostSellingProducts,
    mostSellingCategories,
    cancellationRefundCount,
    actionRequiredPackages,
    isFetchingActionRequiredPackages,
    isFetchingCancellationRefundCount,
    isFetchingMostSellingProducts,
    isFetchingProducts,
    isFetchingMostSellingCategories,
    isFetchingCategories,
  } = useDashboardQueries({
    params: {
      start: startDate?.format(dateFormatWithHyphen),
      end: endDate?.format(dateFormatWithHyphen),
    },
  });

  const disabledDate = useCallback(
    (currentDate) => {
      if (!startDate) {
        return true;
      }

      const sixMonthsLater = moment(startDate).add(6, 'months');
      return moment(currentDate).isAfter(sixMonthsLater);
    },
    [startDate, endDate]
  );

  const onCalendarChange = (currentDateRange) => {
    const [startDate, endDate] = currentDateRange;
    if (startDate && endDate) {
      setDateRange(currentDateRange);
    }
  };

  return (
    <section className="dashboard">
      <div className="sale-channel">
        <div className="left-side">
          <span className="sale-channel-title"> {t('dashboard')} </span>
        </div>
      </div>

      <div className="chart-box-container">
        <div className="channel-card-box-wrapper">
          <div className="channel-card-head">
            <AkinonRangePicker
              allowClear={false}
              disabledDate={disabledDate}
              onCalendarChange={onCalendarChange}
              value={[startDate, endDate]}
              className="akinon-range-picker"
              popupClassName="akinon-range-picker-dropdown"
            />
          </div>
          <div className="card-box--container">
            <SmallCardWithSkeleton loading={isFetchingActiveSku}>
              <div className="card-box--item">
                <div className="card-box--header"> {t('total.product.count').toUpperCase()} </div>
                <div className="card-box--content">
                  <If
                    condition={isNil(activeSku)}
                    otherwise={new Intl.NumberFormat(language).format(activeSku)}
                    then={'-'}
                  />
                </div>
              </div>
            </SmallCardWithSkeleton>
            <SmallCardWithSkeleton loading={isFetchingTotalStock}>
              <div className="card-box--item">
                <div className="card-box--header"> {t('total.product.stocks').toUpperCase()} </div>
                <div className="card-box--content">
                  <If
                    condition={isNil(totalStock)}
                    otherwise={new Intl.NumberFormat(language).format(totalStock)}
                    then={'-'}
                  />
                </div>
              </div>
            </SmallCardWithSkeleton>
            <SmallCardWithSkeleton loading={isFetchingTotalAmount}>
              <div className="card-box--item">
                <div className="card-box--header"> {t('turnover')} </div>
                <div className="card-box--content success">
                  <If
                    condition={isNil(totalAmount)}
                    otherwise={new Intl.NumberFormat(language, {
                      minimumFractionDigits: 2,
                    }).format(totalAmount)}
                    then={'-'}
                  />
                </div>
              </div>
            </SmallCardWithSkeleton>
            <SmallCardWithSkeleton loading={isFetchingTotalItems}>
              <div className="card-box--item">
                <div className="card-box--header">
                  {' '}
                  {t('total.order.item.quantity').toUpperCase()}{' '}
                </div>
                <div className="card-box--content">
                  <If
                    condition={isNil(totalItems)}
                    otherwise={new Intl.NumberFormat(language).format(totalItems)}
                    then={'-'}
                  />
                </div>
              </div>
            </SmallCardWithSkeleton>
            <SmallCardWithSkeleton loading={isFetchingGrade}>
              <div className="card-box--item">
                <div className="card-box--header">
                  {t('seller.performance.seller.evaluation.score').toUpperCase()}
                </div>
                <div className="card-box--content">{grade ?? '-'}</div>
              </div>
            </SmallCardWithSkeleton>
            <SmallCardWithSkeleton loading={isFetchingFulfillmentTime}>
              <div className="card-box--item">
                <div className="card-box--header">
                  {t('seller.performance.average.delivery.time').toUpperCase()}
                </div>
                <div className="card-box--content">
                  <If
                    condition={isNil(fulfillmentTime)}
                    otherwise={`${fulfillmentTime} ${t('day')}`}
                    then={'-'}
                  />
                </div>
              </div>
            </SmallCardWithSkeleton>
            <SmallCardWithSkeleton loading={isFetchingCancelRatio}>
              <div className="card-box--item">
                <div className="card-box--header">
                  {t('seller.performance.cancellation.rate.before.delivery').toUpperCase()}
                </div>
                <div className="card-box--content fail">
                  <If condition={isNil(cancelRatio)} otherwise={`${cancelRatio} %`} then={'-'} />
                </div>
              </div>
            </SmallCardWithSkeleton>
          </div>
        </div>
        <OrderByDateChart
          data={generateChartData(
            orderCountByDate,
            startDate?.format(dateFormatWithHyphen),
            endDate?.format(dateFormatWithHyphen)
          )}
          isFetchingOrderCountByDate={isFetchingOrderCountByDate}
          endDate={endDate}
        />
      </div>
      <div className="box-container" style={rowStyle}>
        <div className="box-container--item">
          <div className="card-head--title">
            <span className="card-name city">{t('actions')}</span>
          </div>
          <Box className="box-item">
            <div className="card-head">
              <div className="card-head--desc"> {t('transactions').toUpperCase()} </div>
              <div className="card-head--value"> {t('quantity').toUpperCase()} </div>
            </div>
            <BigCardWithSkeleton
              skeletonRows={4}
              loading={isFetchingActionRequiredPackages || isFetchingCancellationRefundCount}
            >
              <div className="card-item">
                <div className="card--title"> {t('orders.pending.packaging')} </div>
                <div className="card--value">
                  {get(actionRequiredPackages, PackageStatus.PREPARING, '-')}
                </div>
              </div>
              <div className="card-item">
                <div className="card--title"> {t('pending.shipment.packages')} </div>
                <div className="card--value">
                  {get(actionRequiredPackages, PackageStatus.READY, '-')}
                </div>
              </div>
              <div className="card-item">
                <div className="card--title"> {t('pending.delivery.packages')} </div>
                <div className="card--value">
                  {get(actionRequiredPackages, PackageStatus.SHIPPED, '-')}
                </div>
              </div>
              <div className="card-item">
                <div className="card--title"> {t('cancel.refund.transactions')} </div>
                <div className="card--value">{get(cancellationRefundCount, 'total', '-')}</div>
              </div>
            </BigCardWithSkeleton>
          </Box>
        </div>

        <div className="box-container--item">
          <div className="card-head--title">
            <span className="card-name product">{t('top.five.product')}</span>
          </div>
          <Box className="box-item">
            <div className="card-head">
              <div className="card-head--desc"> {t('product').toUpperCase()} </div>
              <div className="card-head--value"> {t('total.sales').toUpperCase()} </div>
            </div>
            <BigCardWithSkeleton loading={isFetchingMostSellingProducts || isFetchingProducts}>
              {mostSellingProducts?.length > 0 ? (
                mostSellingProducts?.slice(0, 5).map((item, index) => {
                  return (
                    <div className="card-item" key={index}>
                      <div className="card--title"> {item.name} </div>
                      <div className="card--value"> {item.total_sold} </div>
                    </div>
                  );
                })
              ) : (
                <DataNotFound t={t} />
              )}
            </BigCardWithSkeleton>
          </Box>
        </div>

        <div className="box-container--item">
          <div className="card-head--title">
            <span className="card-name category">{t('top.five.category')}</span>
          </div>
          <Box className="box-item">
            <div className="card-head">
              <div className="card-head--desc"> {t('category').toUpperCase()} </div>
              <div className="card-head--value"> {t('total.sales').toUpperCase()} </div>
            </div>
            <BigCardWithSkeleton loading={isFetchingMostSellingCategories || isFetchingCategories}>
              {mostSellingCategories?.length > 0 ? (
                mostSellingCategories?.slice(0, 5).map((item, index) => {
                  return (
                    <div className="card-item" key={index}>
                      <div className="card--title"> {item.name} </div>
                      <div className="card--value"> {item.total_sold} </div>
                    </div>
                  );
                })
              ) : (
                <DataNotFound t={t} />
              )}
            </BigCardWithSkeleton>
          </Box>
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
