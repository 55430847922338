import './style.scss';

import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import AkinonInput from '@components/AkinonInput';
import AkinonSwitch from '@components/AkinonSwitch';
import Box from '@components/utility/box';
import { overallDateFormat } from '@constants/index';
import { verticalFormItemLayout } from '@constants/layoutTypes';
import { RouteUrls } from '@constants/routeUrls';
import { zodResolver } from '@hookform/resolvers/zod';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { useDataSourceUpdateUserMutation } from '@services/hooks/datasources/useDataSourceUpdateUserMutation';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import { FormKey, getFormSchema } from './common';

const SellerEditUser = () => {
  const { t } = useTranslation('Settings');
  const navigate = useAppNavigate();
  const {
    state: { user },
  } = useLocation();
  const { userId, dataSourceId } = useParams();

  const schema = getFormSchema();

  const { handleSubmit, reset, control, getValues } = useForm({
    defaultValues: {},
    resolver: zodResolver(schema),
  });

  useEffect(() => {
    reset(user);
  }, [userId]);

  const { updateUser } = useDataSourceUpdateUserMutation({
    mutationOptions: {
      key: dataSourceId + userId,
      onSuccess: () => navigate(RouteUrls.settings.sellerUserManagementSettings),
    },
  });

  const onSubmit = (values) => {
    updateUser({ dataSourceId, userId, params: values });
  };

  return (
    <Box
      className="box-primary form-box seller-edit-user"
      title={t('add_edit_user')}
      subtitle={t('add_edit_user_desc')}
    >
      <AkinonForm className="ant-form" layout="vertical" {...verticalFormItemLayout}>
        <AkinonInput
          formItemProps={{
            control,
            name: FormKey.EMAIL,
            label: t('email'),
          }}
          placeholder={t('enter_variable', { variable: t('email') })}
          disabled
        />
        <AkinonSwitch
          formItemProps={{
            control,
            name: FormKey.IS_ACTIVE,
            label: t('status'),
          }}
          checkedChildren={t('active')}
          unCheckedChildren={t('passive')}
        />
        <AkinonInput
          formItemProps={{
            control,
            name: FormKey.LAST_LOGIN,
            label: t('last_login'),
          }}
          value={moment(getValues(FormKey.LAST_LOGIN)).format(overallDateFormat)}
          placeholder={t('enter_variable', { variable: t('last_login') })}
          disabled
        />
        <AkinonButton
          htmlType="button"
          type="primary"
          className="akn-submit-button"
          onClick={handleSubmit(onSubmit)}
        >
          {t('save')}
        </AkinonButton>
      </AkinonForm>
    </Box>
  );
};

export default SellerEditUser;
