import { useState } from 'react';

const useModalAction = ({ mutateFn, enforceRowSelection = false }) => {
  const [selectedRows, setSelectedRows] = useState();
  const [isVisible, setIsVisible] = useState(false);

  const onShowModal = (_selectedRows, _setSelectedRows) => {
    if (enforceRowSelection && !_selectedRows?.length) return;
    setSelectedRows(_selectedRows);
    setIsVisible(true);
  };
  const onCancel = () => {
    setIsVisible(false);
  };

  const onOk = () => {
    setIsVisible(false);

    mutateFn({ selectedRows });
  };

  return {
    onShowModal,
    visible: isVisible,
    onOk,
    onCancel,
  };
};

export default useModalAction;
