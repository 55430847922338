import AkinonButton from '@components/AkinonButton';
import { WidgetType } from '@constants/commontypes';
import { defaultDateTimeFormat } from '@constants/index';
import { Space } from 'antd';
import moment from 'moment';

export const StatusKey = {
  WAITING_APPROVAL: 0,
  APPROVED: 1,
  REJECTED: 2,
};

const StaticFilterKey = {
  DATASOURCE: 'datasource',
  STATUS: 'status',
};

const getStatusMapping = ({ t }) => ({
  [StatusKey.WAITING_APPROVAL]: t('waiting_for_approval'),
  [StatusKey.APPROVED]: t('approved'),
  [StatusKey.REJECTED]: t('rejected'),
});

const getStatusOptions = ({ t }) => [
  {
    label: t('waiting_for_approval'),
    value: StatusKey.WAITING_APPROVAL,
  },
  {
    label: t('approved'),
    value: StatusKey.APPROVED,
  },
  {
    label: t('rejected'),
    value: StatusKey.REJECTED,
  },
];

const DataIndex = {
  SELLER: ['datasource', 'name'],
  APPROVED_AT: 'approved_at',
  STATUS: 'status',
};
export const getColumns = ({
  t,
  onApproveDataSourceLandingPage,
  onRejectDataSourceLandingPage,
}) => [
  {
    dataIndex: DataIndex.SELLER,
    visibleOnFilter: [StaticFilterKey.DATASOURCE],
    title: t('seller'),
    render: (data_source) => data_source || '-',
  },
  {
    dataIndex: DataIndex.STATUS,
    visibleOnFilter: [StaticFilterKey.STATUS],
    title: t('status'),
    render: (status) => getStatusMapping({ t })[status] ?? '-',
  },
  {
    dataIndex: DataIndex.APPROVED_AT,
    title: t('approved_at'),
    render: (approved_at) =>
      approved_at ? moment(approved_at).format(defaultDateTimeFormat) : '-',
  },
  {
    title: t('actions'),
    key: 'actions',
    width: 350,
    render: (_, record) => {
      const status = record[DataIndex.STATUS];
      const isWaitingApproval = status === StatusKey.WAITING_APPROVAL;
      return isWaitingApproval ? (
        <Space>
          <AkinonButton type="primary" onClick={() => onApproveDataSourceLandingPage(record)}>
            {t('approve')}
          </AkinonButton>
          <AkinonButton type="danger" onClick={() => onRejectDataSourceLandingPage(record)}>
            {t('reject')}
          </AkinonButton>
        </Space>
      ) : null;
    },
  },
];

export const getStaticFilters = ({ t, dataSources, isDataSourcesLoading, isSuperUser }) => ({
  staticFiltersFormMetaFields: [
    isSuperUser && {
      key: StaticFilterKey.DATASOURCE,
      widget: WidgetType.SELECT,
      widgetProps: {
        showSearch: true,
        optionFilterProp: 'label',
      },
      placeholder: t('seller'),
      options: dataSources?.map(({ id, name }) => ({ label: name, key: id, value: id })) || [],
    },
    {
      key: StaticFilterKey.STATUS,
      widget: WidgetType.SELECT,
      widgetProps: {
        showSearch: true,
        optionFilterProp: 'label',
      },
      placeholder: t('status'),
      options: getStatusOptions({ t }),
    },
  ].filter(Boolean),
  isStaticFiltersLoading: isDataSourcesLoading,
});
