import * as z from 'zod';

export const FormKey = {
  NAME: 'name',
  PRIORITY: 'priority',
  LOCATION_CODE: 'location_code',
  ADDRESS_ID: 'address_id',
  LOCATION_ADDRESS_TITLE: 'address_title',
  LOCATION_ADDRESS: 'location_address',
  COUNTRY: 'country',
  PROVINCE: 'province',
  DISTRICT: 'district',
  NEIGHBORHOOD: 'neighborhood',
  POSTAL_CODE: 'postal_code',
  LATITUDE: 'latitude',
  LONGITUDE: 'longitude',
  PHONE_NUMBER: 'phone_number',
  EMAIL: 'email',
};

export const getFormSchema = () => {
  return z.object({
    [FormKey.NAME]: z.string().min(1),
    [FormKey.PRIORITY]: z.number().int().positive(1),
    [FormKey.LOCATION_CODE]: z.number().min(1),
    [FormKey.ADDRESS_ID]: z.string().min(1),
    [FormKey.LOCATION_ADDRESS_TITLE]: z.string().min(1),
    [FormKey.LOCATION_ADDRESS]: z.string().min(1),
    [FormKey.COUNTRY]: z.string().min(1),
    [FormKey.PROVINCE]: z.string().min(1),
    [FormKey.DISTRICT]: z.string().min(1),
    [FormKey.NEIGHBORHOOD]: z.string().optional(),
    [FormKey.POSTAL_CODE]: z.string().optional(),
    [FormKey.LATITUDE]: z.number().min(-90).max(90),
    [FormKey.LONGITUDE]: z.number().min(-180).max(180),
    [FormKey.PHONE_NUMBER]: z.string().optional(),
    [FormKey.EMAIL]: z.string().email().optional(),
  });
};
