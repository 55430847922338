import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { getPackageItemsUrl } from '@constants/apiUrls';
import { PackageItemStatus } from '@constants/commontypes';
import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { client } from '@services/api/client';

export const usePaginatedPackageItemsQuery = ({
  queryOptions,
  pagination,
  filters,
  params,
  onSuccess,
}) => {
  const { data, isFetching, error } = useAkinonQuery({
    queryKey: [QueryKey.PACKAGE_ITEMS, pagination, filters, params],
    async queryFn() {
      const response = await client.get(getPackageItemsUrl, {
        params: {
          status: PackageItemStatus.ACTIVE,
          ...getRequestPaginationParams(pagination),
          ...getRequestFiltersParams(filters),
          ...params,
        },
      });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    packageItemsResponse: data,
    packageItems: data?.results,
    isFetchingPackageItems: isFetching,
    totalPackageItems: data?.count,
    packageItemsError: error,
  };
};
