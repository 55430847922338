import FormBuilder from 'antd-form-builder';
import { useMemo } from 'react';

import { customizeMetaFields } from './common';

const AkinonFormBuilder = ({ meta, ...otherProps }) => {
  const overridenMeta = useMemo(() => customizeMetaFields({ meta }), [meta]);

  // eslint-disable-next-line no-restricted-syntax
  return <FormBuilder meta={overridenMeta} {...otherProps} />;
};

export default AkinonFormBuilder;

AkinonFormBuilder.defineWidget = FormBuilder.defineWidget;
AkinonFormBuilder.useForceUpdate = FormBuilder.useForceUpdate;
AkinonFormBuilder.useForm = FormBuilder.useForm;
AkinonFormBuilder.createForm = FormBuilder.createForm;
