import AkinonFilter from '@components/AkinonFilter';
import { usePageFilters } from '@components/AkinonFilter/hooks/store/usePageFilters';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import Modal from '@components/Modal/Modal';
import { getDataSourcesUrl } from '@constants/apiUrls';
import { RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { usePaginatedDataSourcesQuery } from '@services/hooks/datasources/usePaginatedDataSourcesQuery';
import { useResetTableCurrentPageWhenFiltersChanged } from '@utils/hooks';
import { Divider } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import NewSellerForm from '../NewSellerForm';
import { getColumns, getStaticFilters } from './common';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { usePageMeta } from './hooks/usePageMeta';

const SellerManagementList = () => {
  const { t } = useTranslation('Finance');
  const navigate = useAppNavigate();
  const filters = usePageFilters();
  const [pagination, setPagination] = usePagination();
  const { data, total, isLoading } = usePaginatedDataSourcesQuery({ pagination, filters });
  const [isNewSellerFormVisible, setIsNewSellerFormVisible] = useState(false);

  useResetTableCurrentPageWhenFiltersChanged({ pagination, setPagination, filters });
  useBreadcrumbs();
  usePageMeta({ setIsNewSellerFormVisible });

  const columns = getColumns({ t });
  const staticFilters = getStaticFilters({ t });

  const onRowClick = (record) => {
    navigate(RouteUrls.finance.sellerManagement.sellerDetail, { id: record.id });
  };

  const onRow = (record) => {
    return {
      onClick: () => {
        onRowClick(record);
      },
    };
  };

  const onAddNewSellerModalClose = () => {
    setIsNewSellerFormVisible(false);
  };

  return (
    <section>
      <AkinonFilter
        title={t('filter')}
        staticFiltersFormMetaFields={staticFilters.staticFiltersFormMetaFields}
        total={total}
      />

      <Divider />

      <AkinonTable
        title={t('seller')}
        columns={columns}
        loading={isLoading}
        dataSource={data}
        optionsUrl={getDataSourcesUrl}
        total={total}
        className="[&_tbody_tr]:cursor-pointer"
        rowKey="id"
        pagination={pagination}
        setPagination={setPagination}
        description={`${total} ${t('results.found')}`}
        onRow={onRow}
      />
      <Modal destroyOnClose onCancel={onAddNewSellerModalClose} open={isNewSellerFormVisible}>
        <NewSellerForm
          queryKey={['data-sources', undefined, filters, pagination]}
          setIsNewSellerFormVisible={setIsNewSellerFormVisible}
        />
      </Modal>
    </section>
  );
};

export default SellerManagementList;
