import './styles.scss';

import { Typography } from 'antd';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const { Title, Text } = Typography;

/**
 * @param {React.HTMLAttributes & { title: string, description: string, bannerColor: string }} props
 */
const AkinonBox = (props) => {
  return (
    <section className={classNames('akinon-box', props.className)}>
      <span
        data-testid="akinon-box__banner"
        className="akinon-box__banner"
        style={{ backgroundColor: props.bannerColor }}
      />
      {props.title && (
        <Title level={1} className="akinon-box__title">
          {props.title}
        </Title>
      )}
      {props.description && <Text className="akinon-box__description">{props.description}</Text>}
      {props.children}
    </section>
  );
};

AkinonBox.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  bannerColor: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default AkinonBox;
