import enUS from 'antd/es/locale/en_US';
import trTR from 'antd/es/locale/tr_TR';

export const Language = {
  ENGLISH: 'en-us',
  TURKISH: 'tr-tr',
};

export const formatLanguageCode = (languageCode) => {
  if (!languageCode) return;
  const parts = languageCode.split('-');
  parts[1] = parts[1].toUpperCase();
  return parts.join('-');
};

export const languageToAntdLocaleMap = {
  [Language.ENGLISH]: enUS,
  [Language.TURKISH]: trTR,
};

export const i18nDefaultLanguageKey = 'i18nextLng';
