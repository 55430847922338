import './index.scss';

import AkinonButton from '@components/AkinonButton';
import If from '@components/If';
import Progress from '@components/UIElements/Progress';
import { antdProgressStatus } from '@constants/commontypes';
import useAppNavigate from '@root/hooks/useAppNavigate';
import useDocumentDownloadQuery from '@services/api/hooks/useDocumentDownloadQuery';
import { Col, Modal, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useImportStatus, { MAX_PROGRESS } from '../hooks/useImportStatus';
import useRefetchImportStatusInterval from '../hooks/useRefetchImportStatusInterval';

/**
 *
 * @param {Object} args
 * @param {keyof BulkImportTypes} args.type
 * @param {Function} args.onCancel
 * @param {boolean} args.open
 * @param {string} args.bulkImportKey
 * @param {string} [args.navigateTo]
 * @returns
 */
export const BulkActionsProgressModal = ({
  onCancel,
  open,
  bulkImportKey,
  navigateTo,
  messages,
}) => {
  const { successCount, failCount, finishButtonText } = messages ?? {};
  const { t } = useTranslation('ProductsAndCategories');
  const [shouldRefetchImportStatus, setShouldRefetchImportStatus] = useState(false);
  const [shouldStartDownload, setShouldStartDownload] = useState(false);

  const navigate = useAppNavigate();

  const { importStatus, importStatusPercentage } = useImportStatus({
    bulkImportKey,
    shouldRefetchImportStatus,
  });
  const { data: documentData, isFetched } = useDocumentDownloadQuery({
    documentUrl: importStatus?.efile,
    queryOptions: {
      enabled: shouldStartDownload && Boolean(importStatus?.efile),
    },
    onSettled: () => {
      setShouldStartDownload(false);
    },
  });

  useEffect(() => {
    if (isFetched && documentData) {
      const a = document.createElement('a');
      a.href = URL.createObjectURL(documentData);
      a.download = importStatus?.efile;
      a.click();
      setShouldStartDownload(false);
    }
  }, [documentData, isFetched]);

  const startDownload = () => {
    setShouldStartDownload(true);
  };

  useRefetchImportStatusInterval({
    status: importStatus?.status,
    setShouldRefetchImportStatus,
  });

  function destroyModal() {
    onCancel?.();
    setShouldStartDownload(false);
  }

  return (
    <Modal
      open={open}
      title={t('upload.started')}
      className="ant-modal-progress bulk-actions-progress-modal"
      closable={true}
      footer={null}
      onCancel={destroyModal}
      centered
      width={650}
    >
      <Row className="body" gutter={[0, 30]} justify="center" align="center">
        <Col span={24}>
          <span>
            <strong>{t('process.status')}&nbsp;</strong>
          </span>

          <Progress
            strokeLinecap="butt"
            percent={importStatusPercentage}
            status={
              importStatusPercentage === MAX_PROGRESS && !shouldRefetchImportStatus
                ? antdProgressStatus.success
                : antdProgressStatus.active
            }
          />
        </Col>
        <Col span={24} sm={12}>
          <If
            condition={importStatus?.state?.succeeded && navigateTo}
            then={
              <Space direction="vertical">
                <span>{successCount({ succeededCount: importStatus?.state?.succeeded })}</span>
                <AkinonButton
                  type="primary"
                  loading={importStatusPercentage !== MAX_PROGRESS}
                  onClick={() => navigate(navigateTo)}
                >
                  {finishButtonText}
                </AkinonButton>
              </Space>
            }
            otherwise={null}
          />
        </Col>
        <Col span={24} sm={12} className="download-error-records-wrapper">
          <If
            condition={importStatus?.state?.failed}
            then={
              <Space direction="vertical">
                <span>{failCount({ failedCount: importStatus?.state?.failed })}</span>
                <AkinonButton
                  loading={importStatusPercentage !== MAX_PROGRESS || shouldStartDownload}
                  type="danger"
                  disabled={
                    importStatusPercentage !== MAX_PROGRESS ||
                    shouldStartDownload ||
                    !importStatus?.efile
                  }
                  onClick={startDownload}
                >
                  {importStatusPercentage !== MAX_PROGRESS
                    ? t('file.preparing')
                    : t('download_error_records')}
                </AkinonButton>
              </Space>
            }
            otherwise={null}
          />
        </Col>
      </Row>
    </Modal>
  );
};
