import { getCancellationPlanStatuses } from '@constants/commontypes';
import { useCustomerAddressesQuery } from '@services/api/hooks/useCustomerAddressQuery';
import { useDataSourcesQuery } from '@services/api/hooks/usePaginatedDataSourcesQuery';
import { useCancellationPlans } from '@services/hooks/cancellation/useCancellationPlans';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';
import { useTranslation } from 'react-i18next';

import { useOrders } from './api/useOrders';

export const useMergeData = ({ pagination, filters }) => {
  const { orders, isFetching: isOrdersFetching, total } = useOrders({ pagination, filters });
  const datasourceIds = uniq(orders?.map(({ datasource }) => datasource));
  const { dataSources, isFetchingDataSources } = useDataSourcesQuery({
    queryOptions: {
      enabled: !isOrdersFetching && !isEmpty(datasourceIds),
    },
    params: {
      id__in: datasourceIds?.join(','),
      limit: orders?.length,
    },
  });
  const customerShippingAddressIds = uniq(orders?.map(({ shipping_address }) => shipping_address));
  const { customerAddresses, isCustomerAddressesFetching } = useCustomerAddressesQuery({
    pagination: {},
    filters: {
      id__in: customerShippingAddressIds?.join(','),
      limit: orders?.length,
    },
    queryOptions: {
      enabled: !isOrdersFetching && !isEmpty(customerShippingAddressIds),
    },
  });
  const { t } = useTranslation('Orders');
  // TODO: Refactor useCancellationPlans hook to documented usage
  const { data: cancellationPlans, isFetching: isCancellationPlansFetching } = useCancellationPlans(
    {
      pagination: {},
      filters: {
        order__in: orders?.map(({ id }) => id).join(','),
        limit: orders?.length,
      },
      enabled: !isOrdersFetching && !isEmpty(orders),
    }
  );

  const data = orders?.map((order) => {
    const datasource = dataSources?.find(({ id }) => id === order?.datasource);
    const customer = customerAddresses?.find(({ id }) => id === order?.shipping_address);
    const cancellation_plan = getCancellationPlanStatuses({ t })?.find(
      (status) =>
        status.value ===
        cancellationPlans?.find(({ order: orderId }) => orderId === order?.id)?.status
    )?.label;

    return {
      ...order,
      customer,
      datasource,
      cancellation_plan,
    };
  });

  const isLoading =
    isOrdersFetching ||
    isFetchingDataSources ||
    isCancellationPlansFetching ||
    isCustomerAddressesFetching;

  return {
    data,
    isLoading,
    total,
  };
};
