import { Modal } from 'antd';
import React from 'react';

const RevisionActionConfirmationModal = ({ title, description, isVisible, onCancel, onOk }) => {
  return (
    <Modal
      open={isVisible}
      title={title}
      centered
      onCancel={() => onCancel()}
      onOk={() => {
        onOk();
        onCancel();
      }}
    >
      <div>{description}</div>
    </Modal>
  );
};

export default RevisionActionConfirmationModal;
