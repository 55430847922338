import AkinonButton from '@components/AkinonButton';
import { Switch, Typography } from 'antd';
import clsx from 'clsx';

export const DataIndex = {
  REASON: 'reason',
  IS_ACTIVE: 'is_active',
};

export const getColumns = ({
  t,
  formRuleMap,
  editingKey,
  isEditing,
  handleEditRow,
  handleCancelEditing,
  handleSaveRow,
  isMobile,
}) =>
  [
    {
      dataIndex: DataIndex.REASON,
      title: t('reason'),
      editable: true,
      inputProps: {
        className: 'editable-cell-input',
      },
    },
    {
      dataIndex: DataIndex.IS_ACTIVE,
      title: t('status'),
      editable: true,
      inputProps: {
        type: 'checkbox',
      },
      render: (value, record) => {
        const editable = isEditing(record);
        return (
          <Switch
            disabled={!editable}
            checked={value}
            checkedChildren={t('active')}
            unCheckedChildren={t('passive')}
          />
        );
      },
    },
    {
      title: t('save'),
      dataIndex: t('save'),
      fixed: isMobile ? false : 'right',
      render: (_, record, index) => {
        const editable = isEditing(record);
        const isFirstRow = index === 0;
        return editable ? (
          <span className="offer-reject-reasons__actions">
            <AkinonButton
              className="offer-reject-reasons__actions__button"
              onClick={() => handleSaveRow(record)}
            >
              {t('save')}
            </AkinonButton>
            <AkinonButton
              className="offer-reject-reasons__actions__button"
              type="link"
              onClick={handleCancelEditing}
            >
              <Typography.Text underline type="danger">
                {t('cancel')}
              </Typography.Text>
            </AkinonButton>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ''}
            onClick={() => handleEditRow(record)}
            className={clsx('offer-reject-reasons__edit-button', {
              'offer-reject-reasons__edit-button--first-row': isFirstRow,
            })}
          >
            {t('edit')}
          </Typography.Link>
        );
      },
    },
  ].map((col) => {
    if (!col?.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        ...col,
        record,
        editing: isEditing(record),
        rules: [formRuleMap.required],
      }),
    };
  });
