import { UserRole } from '@constants/auth';
import { RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { useUserRole } from '@utils/hooks/useUserRole';
import useStore from '@zustand-store/index';
import { setPageMetaSelector } from '@zustand-store/selectors/app';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

export function usePageMeta() {
  const { t } = useTranslation('Support');
  const navigate = useAppNavigate();
  const setPageMeta = useStore(setPageMetaSelector);
  const userRole = useUserRole();
  const isSeller = userRole === UserRole.SELLER;

  useEffect(() => {
    setPageMeta({
      title: t('support_ticket_title'),
      description: t('support_ticket_description'),
      actions: isSeller && [
        {
          label: t('createTicket'),
          icon: <i className="icon-arti" />,
          onClick() {
            navigate(RouteUrls.support.supportTicketForm);
          },
        },
      ],
    });
  }, [t, isSeller]);

  useUnmount(() => {
    setPageMeta({});
  });
}
