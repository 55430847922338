import { getImportsUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { useAkinonQuery } from '../adapters/useAkinonQuery';
import { client } from '../client';

export function useImportOptionsQuery({ queryOptions } = {}) {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.IMPORT_OPTIONS],
    queryFn: () => client.options(getImportsUrl),
    ...queryOptions,
  });

  return {
    importOptions: data,
    isFetchingImportOptions: isFetching,
  };
}
