import { dateAndTimeFormat } from '@common/date';
import { Switch } from 'antd';
import moment from 'moment';

export const DataIndex = {
  SHIPPING_COMPANY: ['shipping_company_obj', 'name'],
  SHIPPING_OPTION: ['shipping_option_obj', 'name'],
  IS_REAL_ACTIVE: 'is_real_active',
  UPDATED_AT_SELLER: ['shipping_company_option_config_obj', 'updated_at'],
};

export const getColumns = ({ t }) => {
  return [
    {
      dataIndex: DataIndex.SHIPPING_COMPANY,
      title: t('firm_name'),
    },
    {
      dataIndex: DataIndex.SHIPPING_OPTION,
      title: t('delivery_option'),
    },
    {
      dataIndex: DataIndex.IS_REAL_ACTIVE,
      title: t('operating_status'),
      render: (isRealActive) => (
        <Switch
          checked={isRealActive}
          checkedChildren={t('active')}
          unCheckedChildren={t('passive')}
          onClick={(_, e) => e.stopPropagation()}
        />
      ),
    },
    {
      dataIndex: DataIndex.UPDATED_AT_SELLER,
      title: t('last_updated_date'),
      render(sellerUpdatedAt) {
        return moment(sellerUpdatedAt).format(dateAndTimeFormat);
      },
    },
  ];
};
