import ImageTableHead from '@components/ImageTableHead';
import { Form, Input, Upload } from 'antd';

const DataIndex = {
  IMAGES: 'images',
  EXPLANATION: 'explanation',
  NAME: 'name',
  SKU: 'sku',
  PRODUCT_OBJ: 'product_obj',
  PRODUCT_OFFER_OBJ: 'product_offer_obj',
};

export const getColumns = ({ t }) => {
  return [
    {
      dataIndex: DataIndex.IMAGES,
      title: <ImageTableHead required={true} />,
      align: 'center',
      render: (_, record) => {
        return (
          <Form.Item name={[`${record?.cancellation_id}`, DataIndex.IMAGES]} noStyle required>
            <Upload
              listType="picture-card"
              showUploadList={{
                showPreviewIcon: false,
                showRemoveIcon: true,
              }}
              accept="image/*"
              multiple
              beforeUpload={() => {
                return false;
              }}
            >
              <span>+</span>
            </Upload>
          </Form.Item>
        );
      },
    },
    {
      title: t('product_name'),
      dataIndex: [DataIndex.PRODUCT_OBJ, DataIndex.NAME],
    },
    {
      title: t('sku'),
      dataIndex: [DataIndex.PRODUCT_OFFER_OBJ, DataIndex.SKU],
    },
    {
      title: t('explanation'),
      dataIndex: DataIndex.EXPLANATION,
      render: (_, record) => {
        return (
          <Form.Item name={[`${record?.cancellation_id}`, DataIndex.EXPLANATION]} noStyle>
            <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
          </Form.Item>
        );
      },
    },
  ];
};
