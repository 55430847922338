import * as z from 'zod';

export const getCategoryBasedCommissionFormSchema = ({ t }) => {
  return z.object({
    formData: z.array(
      z.object({
        ruleId: z.string().nullable().optional(),
        category_node: z.string().min(1),
        commission: z.coerce
          .number({ invalid_type_error: t('number.validation.message') })
          .min(0.00001, { message: t('formRule.min', { amount: 0.00001 }) })
          .max(100, { message: t('formRule.max', { amount: 100 }) }),
        maturity: z.coerce
          .number({ invalid_type_error: t('number.validation.message') })
          .int({ message: t('integer.validation.message') })
          .min(1, { message: t('formRule.min', { amount: 1 }) }),
      })
    ),
  });
};
