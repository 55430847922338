export const boxFormItemLayout = {
  labelCol: {
    xs: { span: 8 },
    sm: { span: 8 },
    md: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 16 },
    sm: { span: 16 },
    md: { span: 19 },
  },
};

export const verticalFormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

export const formBuilderLayout = {
  wrapperCol: {
    xs: { span: 12, offset: 12 },
    sm: { span: 12, offset: 12 },
  },
};

export const formBuilderVerticalLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

export const wrapperCol = { span: 8, offset: 8 };
