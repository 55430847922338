import useLanguageSlice from '@components/AkinonFilter/hooks/store/useLanguageSlice';
import { Language } from '@constants/locale';
import { useMeMutation } from '@services/api/hooks/useMeMutation';
import { useLanguagesQuery } from '@services/hooks';
import isEmpty from 'lodash/isEmpty';

export function useLanguage() {
  const { patchMe, isPatchingMe } = useMeMutation();
  const { language, setLanguage, resetLanguage } = useLanguageSlice();
  const { languages } = useLanguagesQuery({
    cacheTime: Infinity,
    placeholderData: {},
  });

  const onSetLanguage = (language) => {
    patchMe({ requestBody: { language } });
    setLanguage(language);
  };

  return {
    languages,
    isPatchingMe,
    isLanguageReady: !isEmpty(language),
    language: language ?? Language.ENGLISH,
    setLanguage: onSetLanguage,
    resetLanguage,
  };
}
