import { getUserRole } from '@common/auth';
import { UserRole } from '@constants/auth';
import { useUser } from '@root/contexts/hooks/useUser';
import useStore from '@zustand-store/index';
import { setPageMetaSelector } from '@zustand-store/selectors/app';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

export function usePageMeta({ showOpenFormModal }) {
  const { t } = useTranslation('Settings');
  const setPageMeta = useStore(setPageMetaSelector);

  const user = useUser();

  const userRole = getUserRole({ user });
  const isSeller = userRole === UserRole.SELLER;

  useEffect(() => {
    setPageMeta({
      title: t('access_tokens_form_title'),
      description: t('access_tokens_form_description'),
      actions: isSeller && [
        {
          label: t('generate_new_access_token'),
          onClick() {
            showOpenFormModal();
          },
        },
      ],
    });
  }, [t, isSeller]);

  useUnmount(() => {
    setPageMeta({});
  });
}
