import { patchPreOffers } from '@services/api';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';

export function usePatchPreOffersMutation() {
  const mutationResponse = useAkinonMutation({
    mutationFn: patchPreOffers,
  });

  return {
    ...mutationResponse,
    patchPreOffers: mutationResponse.mutate,
    isPatchPreOffersMutating: mutationResponse.isLoading,
  };
}
