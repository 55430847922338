export const DataIndex = {
  TITLE: 'title',
  CONTENT: 'content',
};

export const getColumns = ({ t }) => [
  {
    dataIndex: DataIndex.TITLE,
    title: t('title'),
  },
  {
    dataIndex: DataIndex.CONTENT,
    title: t('content'),
  },
];
