import { dateWith12HoursFormat } from '@common/date';
import { createSelectOptions } from '@common/index';
import Modal from '@components/Modal/Modal';
import { WidgetType } from '@constants/commontypes';
import { Tag, Typography } from 'antd';
import { entries, map } from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';

export const ExportTableColumnDataIndex = {
  ID: 'id',
  STATUS: 'status',
  CREATED_AT: 'created_at',
  FILE: 'file',
};

export const ExportStatus = {
  PENDING: 1,
  PROCESSING: 2,
  SUCCEEDED: 3,
  FAILED: 4,
};

export const getExportStatusMap = ({ t }) => ({
  [ExportStatus.PENDING]: t('pending'),
  [ExportStatus.PROCESSING]: t('processing'),
  [ExportStatus.SUCCEEDED]: t('succeeded'),
  [ExportStatus.FAILED]: t('failed'),
});

export const getTagColor = (status) => {
  switch (status) {
    case ExportStatus.PENDING:
      return 'blue';
    case ExportStatus.PROCESSING:
      return 'purple';
    case ExportStatus.SUCCEEDED:
      return 'green';
    case ExportStatus.FAILED:
      return 'red';
    default:
      return 'default';
  }
};

export const getExportTableColumns = ({ t }) => {
  const exportStatusMap = getExportStatusMap({ t });

  return [
    {
      dataIndex: ExportTableColumnDataIndex.ID,
      title: t('batch_request_id'),
    },
    {
      dataIndex: ExportTableColumnDataIndex.STATUS,
      title: t('file_status'),
      render(status) {
        return <Tag color={getTagColor(status)}>{exportStatusMap[status]}</Tag>;
      },
    },
    {
      dataIndex: ExportTableColumnDataIndex.CREATED_AT,
      title: t('created_at'),
      render(createdAt) {
        return moment(createdAt).format(dateWith12HoursFormat);
      },
    },
    {
      dataIndex: ExportTableColumnDataIndex.FILE,
      title: t('error'),
      render(fileUrl, rowData) {
        const status = rowData?.status;
        if (status === ExportStatus.FAILED) {
          return (
            <Typography.Paragraph
              ellipsis={{
                suffix: (
                  <Typography.Text
                    type="danger"
                    onClick={() => {
                      Modal.confirm({
                        content: rowData?.error,
                        closable: true,
                        okButtonProps: { hidden: true },
                        cancelButtonProps: { hidden: true },
                        maskClosable: true,
                      });
                    }}
                  >
                    {t('more')}
                  </Typography.Text>
                ),
              }}
            >
              {rowData?.error}
            </Typography.Paragraph>
          );
        }
        return <Link to={fileUrl}>{t('download')}</Link>;
      },
    },
  ];
};

export const StaticFilterKey = {
  ID: 'id',
  STATUS: 'status',
};

export const getStaticFilters = ({ t }) => {
  return {
    staticFiltersFormMetaFields: [
      {
        key: StaticFilterKey.ID,
        placeholder: t('batch_request_id'),
        widget: WidgetType.INPUT,
      },
      {
        key: StaticFilterKey.STATUS,
        placeholder: t('file_status'),
        widget: WidgetType.SELECT,
        options: createSelectOptions(
          map(entries(getExportStatusMap({ t })), ([key, value]) => ({ key, value })),
          {
            valueKey: 'key',
            labelKey: 'value',
          }
        ),
      },
    ],
  };
};
