import './index.scss';

import AkinonTable from '@components/AkinonTable';
import AkinonDraggableBodyRow from '@components/AkinonTable/components/AkinonDraggableBodyRow';
import { UserRole } from '@constants/auth';
import { RouteUrls } from '@constants/routeUrls';
import { arrayMove } from '@dnd-kit/sortable';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { usePatchDataSourceShippingCompanyOptionConfigMutation } from '@services/api/hooks/usePatchDataSourceShippingCompanyOptionConfigMutation';
import { usePatchShippingCompanyOptionMutation } from '@services/api/hooks/usePatchShippingCompanyOptionMutation';
import { usePostDataSourceShippingCompanyOptionConfigMutation } from '@services/api/hooks/usePostDataSourceShippingCompanyOptionConfigMutation';
import { useUserRole } from '@utils/hooks/useUserRole';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { createTableActions, DataIndex, getColumns } from './common';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { useDataSource } from './hooks/useDataSource';
import { usePageMeta } from './hooks/usePageMeta';

function DeliverySettingsList() {
  const { t } = useTranslation('Settings');
  const navigate = useAppNavigate();
  const userRole = useUserRole();
  const { updateShippingCompanyOption } = usePatchShippingCompanyOptionMutation();
  const { createDataSourceShippingCompanyOptionConfig } =
    usePostDataSourceShippingCompanyOptionConfigMutation();
  const { updateDataSourceShippingCompanyOptionConfig } =
    usePatchDataSourceShippingCompanyOptionConfigMutation();
  const { dataSource, isLoading, total } = useDataSource();
  usePageMeta();
  useBreadcrumbs();

  const updateShippingCompanyOptionForUser = (row) => {
    if (userRole === UserRole.SUPER_USER) {
      updateShippingCompanyOption({
        optionId: row.id,
        requestBody: {
          is_active: !row[DataIndex.IS_REAL_ACTIVE],
        },
      });
    } else {
      const { shipping_company_option_config_obj } = row;

      if (!shipping_company_option_config_obj) {
        createDataSourceShippingCompanyOptionConfig({
          requestBody: {
            shipping_company_option: row.id,
            is_active: !row[DataIndex.IS_REAL_ACTIVE],
          },
        });

        return;
      }

      updateDataSourceShippingCompanyOptionConfig({
        configId: shipping_company_option_config_obj?.id,
        requestBody: {
          is_active: !row[DataIndex.IS_REAL_ACTIVE],
        },
      });
    }
  };

  const updatePriority = ({ dragRow, hoverRow }) => {
    const { id: shippingCompanyOptionId, shipping_company_option_config_obj } = dragRow ?? {};

    const newPriority = hoverRow?.priority;

    if (userRole === UserRole.SUPER_USER) {
      updateShippingCompanyOption({
        optionId: shippingCompanyOptionId,
        requestBody: {
          priority: newPriority,
        },
      });
    } else {
      const isConfigObjectInactive =
        !shipping_company_option_config_obj?.id && !shipping_company_option_config_obj?.is_active;

      if (isConfigObjectInactive) {
        createDataSourceShippingCompanyOptionConfig({
          requestBody: {
            shipping_company_option: dragRow.id,
            is_active: dragRow[DataIndex.IS_REAL_ACTIVE],
            priority: newPriority,
          },
        });

        return;
      }

      updateDataSourceShippingCompanyOptionConfig({
        configId: shipping_company_option_config_obj?.id,
        requestBody: {
          priority: newPriority,
        },
      });
    }
  };

  const columns = getColumns({
    t,
    handleSwitchChange: updateShippingCompanyOptionForUser,
    userRole,
  });
  const tableActions = createTableActions({ t, updateShippingCompanyOptionForUser });

  const moveRow = useCallback(
    (activeIndex, hoverIndex) => {
      const [dragRow] = dataSource.slice(activeIndex, activeIndex + 1);
      const [hoverRow] = dataSource.slice(hoverIndex, hoverIndex + 1);
      dataSource.splice(hoverIndex, 0, dragRow);
      arrayMove(dataSource, activeIndex, hoverIndex);

      if (dragRow.priority === hoverRow.priority) {
        return;
      }

      updatePriority({ dragRow, hoverRow });
    },
    [dataSource]
  );

  const onRow = (record, index) => ({
    onClick() {
      navigate(RouteUrls.settings.deliverySettingsShipmentSettingsDetail, {
        id: record.id,
        configId: record.shipping_company_option_config_obj?.id,
      });
    },
    index,
    moveRow,
  });

  return (
    <section className="shipment-settings">
      <AkinonTable
        title={t('shipping_companies')}
        rowKey="id"
        dataSource={dataSource}
        columns={columns}
        loading={isLoading}
        total={total}
        rowSelection={true}
        tableActions={tableActions}
        pagination={false}
        components={{
          body: {
            row: AkinonDraggableBodyRow,
          },
        }}
        onRow={onRow}
      />
    </section>
  );
}

export default DeliverySettingsList;
