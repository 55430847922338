import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

export const FormMetaKey = {
  EMAIL: 'email',
};

export const forgotPasswordFormResolver = zodResolver(
  z.object({
    [FormMetaKey.EMAIL]: z.string().email(),
  })
);
