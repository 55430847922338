import { defaultRedirectionUrl } from '@constants/routeUrls';
import { useIsLoggedIn } from '@utils/hooks/useIsLoggedIn';
import React from 'react';
import { Navigate, useOutlet, useSearchParams } from 'react-router-dom';

/**
 * @param {import('react-router-dom').RouteProps} routeProps
 */
const PublicRoute = () => {
  const isLoggedIn = useIsLoggedIn();
  const outlet = useOutlet();
  const [searchParams] = useSearchParams();

  const redirectUrl = searchParams.get('redirectUrl');

  return isLoggedIn ? <Navigate to={redirectUrl ?? defaultRedirectionUrl} replace /> : outlet;
};

export default PublicRoute;
