import * as z from 'zod';

import { DescriptionFormKey } from './components/DescriptionForm/common';

export const getDescriptionFormSchema = ({ t }) => {
  return z.object({
    [DescriptionFormKey.SELLER_DESCRIPTION]: z
      .string()
      .min(1)
      .transform((value, ctx) => {
        if (value === '<p><br></p>') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('formRule.required'),
          });
          return z.NEVER;
        } else return value;
      }),
  });
};
