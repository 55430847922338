import './styles.scss';

import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import { AkinonPasswordInput } from '@components/AkinonInput';
import { openNotification } from '@components/akinonNotification';
import Box from '@components/utility/box/index';
import { zodResolver } from '@hookform/resolvers/zod';
import { useChangePasswordMutation } from '@services/hooks/useChangePasswordMutation';
import { Form } from 'antd';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormKey, getFormSchema } from './common';

const ChangePasswordForm = () => {
  const { t } = useTranslation('Profile');
  const schema = getFormSchema({ t });
  const { control, handleSubmit } = useForm({
    resolver: zodResolver(schema),
  });

  const { changePassword } = useChangePasswordMutation();

  const onSubmit = (values) => {
    changePassword(
      {
        requestBody: {
          password: values.new_password,
          current_password: values.old_password,
        },
      },
      {
        onSuccess: () => {
          openNotification({
            message: t('process.successful'),
            description: t('password_changed'),
            type: 'success',
          });
        },
        onError: (err) => {
          openNotification({
            message: t('process.failed'),
            description:
              err?.response?.data?.code === 'invalid_current_password'
                ? t('invalid_current_password')
                : err?.response?.data?.detail,
            type: 'error',
          });
        },
      }
    );
  };

  return (
    <section className="change-password">
      <Box
        className="box-primary form-box"
        title={t('change.password')}
        subtitle={t('user_password_desc')}
      >
        <AkinonForm
          control={control}
          schema={schema}
          layout="vertical"
          onFinish={handleSubmit(onSubmit)}
        >
          <AkinonPasswordInput
            placeholder={t('password')}
            name={FormKey.OLD_PASSWORD}
            label={t('password')}
          />
          <AkinonPasswordInput
            placeholder={t('new_password')}
            name={FormKey.NEW_PASSWORD}
            label={t('new_password')}
          />
          <AkinonPasswordInput
            placeholder={t('new_password_repeat')}
            name={FormKey.NEW_PASSWORD_REPEAT}
            label={t('new_password_repeat')}
          />
          <Form.Item wrapperCol={{ span: 24 }}>
            <AkinonButton
              htmlType="submit"
              type="primary"
              name="change-password-submit"
              className="change-password__submit-button"
            >
              {t('submit')}
            </AkinonButton>
          </Form.Item>
        </AkinonForm>
      </Box>
    </section>
  );
};

export default ChangePasswordForm;
