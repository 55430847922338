import { QueryKey } from '@constants/query';
import { getQuickReplies } from '@services/api/orders/message';
import { useQuery } from '@tanstack/react-query';

export function useQuickRepliesQuery({ requestConfig, queryOption = {} }) {
  const queryKey = [QueryKey.QUICK_REPLIES];

  const queryFn = () => getQuickReplies({ requestConfig });

  const { data, isFetching, isLoading, error, ...rest } = useQuery({
    queryKey,
    queryFn,
    ...queryOption,
  });

  return {
    ...rest,
    quickReplies: data?.results,
    isQuickRepliesLoading: isLoading,
    isQuickRepliesFetching: isFetching,
    quickRepliesError: error,
  };
}
