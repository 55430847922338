import useFilterForm from '@components/AkinonFilter/hooks/context/useFilterForm';

import { fillFilterWidgets, getActiveFilters } from '../common';

export default function useOnModalCancel({
  setCurrentFiltersFormMeta,
  staticFiltersFormMetaFields,
  dynamicFiltersFormMetaFields,
  modalForm,
  setIsFilterOptionsModalOpen,
}) {
  const filterForm = useFilterForm();

  const onModalCancel = async () => {
    const selectedModalFilterKeys = Object.entries(modalForm.getFieldsValue(true))
      .filter(([_, modalFormItemValue]) => modalFormItemValue)
      .map(([modalFormItemKey]) => modalFormItemKey);

    const activeFilters = getActiveFilters({
      selectedModalFilterKeys,
      staticFiltersFormMetaFields,
      dynamicFiltersFormMetaFields,
    });

    setIsFilterOptionsModalOpen(false);

    let newFilterValue = filterForm.getFieldsValue(true);
    for (const key in newFilterValue) {
      if (!selectedModalFilterKeys.includes(key)) {
        delete newFilterValue[key];
      }
    }

    filterForm.setFieldsValue(newFilterValue);

    try {
      const newWidgets = await fillFilterWidgets({
        metaFields: activeFilters,
      });
      setCurrentFiltersFormMeta(newWidgets);
    } catch (error) {
      setCurrentFiltersFormMeta(activeFilters);
    }
  };

  return onModalCancel;
}
