import If from '@components/If';
import Box from '@components/utility/box/index';
import { Skeleton } from 'antd';
import {
  CategoryScale,
  Chart,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from 'chart.js';
import ZoomPlugin from 'chartjs-plugin-zoom';
import React from 'react';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler,
  ZoomPlugin
);

export default function OrderByDateChart({ data, endDate, isFetchingOrderCountByDate }) {
  const { t } = useTranslation('Dashboard');
  const { labels, values } = data;

  const lineChartData = {
    labels,
    datasets: [
      {
        backgroundColor: 'rgba(98, 109, 144, 0.3)',
        borderColor: '#037cf9',
        borderWidth: 4,
        hoverBackgroundColor: 'rgba(98, 109, 144, 1)',
        hoverBorderColor: 'rgba(98, 109, 144, 1)',
        tension: 0.1,
        yAxisID: 'yAxes',
        xAxisID: 'xAxes',
        data: values,
        fill: true,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: {
        axis: 'y',
        grid: {
          display: false,
        },
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
      },
      xAxes: {
        axis: 'x',
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
        },
        zoom: {
          onZoomStart: ({ event, chart }) => {
            const isZoomInEvent = event.deltaY < 0;
            const hasLessThan5Elems = chart.scales.xAxes.ticks?.length <= 5;
            // zoom can drop below to 5 items if the user's mouse's scroll step is big enough
            if (chart.scales.xAxes.ticks?.length <= 4 && isZoomInEvent) {
              chart.zoom(chart, { x: 5 });
            }
            if (isZoomInEvent && hasLessThan5Elems) return false;
          },
          pinch: {
            enabled: true,
          },
          wheel: {
            enabled: true,
          },
          mode: 'x',
        },
      },
      legend: {
        display: false,
      },
      filler: {
        propagate: false,
      },
      tooltip: {
        mode: 'x',
        intersect: false,
        callbacks: {
          label: (tooltipItem) =>
            t('total.order.count.with.no', {
              totalOrders: tooltipItem.formattedValue,
            }),
        },
      },
    },
  };

  return (
    <div className="chart-box-container--chart">
      <div className="chart-head">
        <span className="chart-name"> {t('dashboard.orderPerHour')} </span>
      </div>
      <If
        condition={isFetchingOrderCountByDate || !endDate}
        then={<Skeleton.Image active className="dashboard__chart-skeleton" />}
        otherwise={
          <Box className="chart-wrapper">
            <Line data={lineChartData} options={options} />
          </Box>
        }
      />
    </div>
  );
}
