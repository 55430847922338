import 'antd/dist/antd.css';
import './assets/styles/main.scss';
import './index.scss';
import './i18n';
import 'react-image-crop/dist/ReactCrop.css';

import * as Sentry from '@sentry/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { router } from './router';

Sentry.init({
  dsn:
    import.meta.env.SENTRY_DSN ??
    'https://0db9e89e3cf8430baa021a9058abae86@o64293.ingest.us.sentry.io/4505782609182720',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: import.meta.env.PROD ? 1.0 : 0.25,
  release: import.meta.env.ACC_APP_VERSION,
});

const container = document.getElementById('root');
const root = createRoot(container);

window.history.replaceState({}, document.title);

root.render(<RouterProvider router={router} />);
