import { getDataSourceDetailUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';

import { client } from '../client';

/**
 * @param {{ mutationOptions: import('@tanstack/react-query').UseMutationOptions }} args
 */
export function usePatchDatasourceDetailMutation({ mutationOptions } = {}) {
  const { mutate } = useAkinonMutation({
    mutationFn: ({ requestBody, requestConfig, dataSourceId }) =>
      client.patch(getDataSourceDetailUrl, requestBody, {
        ...requestConfig,
        urlParams: {
          dataSourceId,
        },
      }),
    ...mutationOptions,
  });

  return {
    updateDataSourceDetail: mutate,
  };
}
