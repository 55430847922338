import './style.scss';

import classnames from 'classnames';
import React from 'react';

const AntProgress = (Component) => (props) => {
  const className = classnames(props.className, 'antProgress');
  return <Component {...props} className={className} />;
};

export default AntProgress;
