import * as React from 'react';

const IconBanner = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={36}
    height={23}
    {...props}
  >
    <title>{'banner'}</title>
    <defs>
      <path id="a" d="M0 0h36v14.933H0z" />
      <path id="c" d="M0 0h2.888v2.573H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <path
        fill="#BCBCBC"
        d="M34.869 13.925H1.132V1.008h33.736l.001 12.917ZM34.833 0H1.167C.524 0 .001.467 0 1.04v12.853c0 .573.524 1.04 1.167 1.04h33.666c.643 0 1.166-.467 1.167-1.04V1.04C36 .467 35.476 0 34.833 0Z"
        mask="url(#b)"
      />
      <path
        fill="#BCBCBC"
        d="M22.111 4.115h11.965c.35 0 .636-.252.636-.562 0-.31-.285-.563-.636-.563H22.11c-.35 0-.636.253-.636.563 0 .31.285.562.636.562M22.111 6.504h10.612c.351 0 .637-.252.637-.563 0-.31-.286-.562-.637-.562H22.111c-.35 0-.636.252-.636.562 0 .31.285.563.636.563M22.111 8.893h6.554c.351 0 .637-.252.637-.562 0-.31-.286-.563-.637-.563h-6.554c-.35 0-.636.252-.636.563 0 .31.285.562.636.562M16.165 12.031h-2.862l-.565-.923 1.431-2.34 1.996 3.263Zm-9.246-.348L9.36 7.696l2.654 4.335H6.919v-.348Zm6.225-3.346-1.051 1.718-1.709-2.792c-.204-.333-.597-.54-1.024-.54-.427 0-.82.207-1.024.542L6.919 9.578v-6.18h9.69v7.254l-1.415-2.315c-.205-.334-.598-.542-1.025-.542-.427 0-.82.208-1.025.542Zm-6.19 4.702h9.62a1.228 1.228 0 0 0 .887-.372.972.972 0 0 0 .28-.668V3.43c0-.572-.524-1.039-1.167-1.04h-9.62c-.642.001-1.165.468-1.166 1.04V12c0 .572.524 1.039 1.167 1.04Z"
      />
      <path
        fill="#BCBCBC"
        d="M13.603 5.759c0 .313-.285.567-.636.567-.351 0-.637-.254-.637-.567 0-.312.286-.567.637-.567.35 0 .636.254.636.567m-.636-1.575c-.975 0-1.768.706-1.768 1.575 0 .868.794 1.574 1.768 1.575.975 0 1.768-.706 1.768-1.575s-.793-1.575-1.768-1.575"
      />
      <g transform="translate(13.657 19.448)">
        <mask id="d" fill="#fff">
          <use xlinkHref="#c" />
        </mask>
        <path
          fill="#BCBCBC"
          d="M1.444.782c.312 0 .566.226.566.505 0 .278-.254.504-.566.504-.312 0-.566-.227-.566-.504 0-.279.254-.505.566-.505m0-.782C.648 0 0 .577 0 1.287c0 .709.648 1.286 1.444 1.286.795 0 1.443-.578 1.444-1.286C2.888.577 2.24 0 1.444 0"
          mask="url(#d)"
        />
      </g>
      <path
        fill="#BCBCBC"
        d="M20.716 20.25c-.395 0-.716.285-.716.634 0 .348.322.632.716.632.394 0 .715-.284.715-.633 0-.348-.32-.632-.715-.632M26.127 20.25c-.395 0-.716.285-.716.633 0 .35.321.633.716.633.394 0 .715-.284.716-.633 0-.348-.322-.632-.716-.632"
      />
    </g>
  </svg>
);
export default IconBanner;
