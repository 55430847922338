import { useImportStatusQuery } from '@services/api/hooks/useImportStatusQuery';
import round from 'lodash/round';

const INTERVAL_DURATION = 3000; // 3 seconds
export const MAX_PROGRESS = 100;

const useImportStatus = ({ bulkImportKey, shouldRefetchImportStatus }) => {
  const { importStatus } = useImportStatusQuery({
    key: bulkImportKey,
    queryOptions: {
      refetchInterval: shouldRefetchImportStatus ? INTERVAL_DURATION : false,
    },
  });

  const importStatusPercentage =
    ((importStatus?.state?.succeeded + importStatus?.state?.failed) / importStatus?.state?.total) *
    100;

  return {
    importStatus,
    importStatusPercentage: round(importStatusPercentage, 2),
  };
};

export default useImportStatus;
