import { wildButtonProps } from '@components/WildButton';
import { UserRole } from '@constants/auth';
import { useTour } from '@reactour/tour';
import { useUserRole } from '@utils/hooks/useUserRole';
import useStore from '@zustand-store/index';
import { setPageMetaSelector } from '@zustand-store/selectors/app';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

export function usePageMeta({ toggleIsBulkUpdateProductStocksActive }) {
  const { t } = useTranslation('ProductsAndCategories');
  const setPageMeta = useStore(setPageMetaSelector);
  const { setIsOpen, setCurrentStep } = useTour();
  const userRole = useUserRole();

  useEffect(() => {
    setPageMeta({
      title: t('stock.list'),
      description: t('stock.list.desc'),
      actions: [
        {
          label: t('bulk_stock_info_update'),
          type: 'primary',
          onClick: toggleIsBulkUpdateProductStocksActive,
          className: 'stock-detail__bulk-update-stock-info-button',
        },
        userRole === UserRole.SELLER && {
          ...wildButtonProps,
          label: t('start_tour'),
          onClick() {
            setCurrentStep(0);
            setIsOpen(true);
          },
        },
      ].filter(Boolean),
    });
  }, [t, userRole]);

  useUnmount(() => {
    setPageMeta({});
  });
}
