import * as z from 'zod';

export const FormKey = {
  SUPPLIER_DETAIL: {
    TITLE: 'supplier_detail.title',
    ADDRESS: 'supplier_detail.address',
    TRADE_ASSOCIATION: 'supplier_detail.trade_association',
    IBAN_NUMBER: 'supplier_detail.iban_number',
    PHONE_NUMBER: 'supplier_detail.phone_number',
    FAX_NUMBER: 'supplier_detail.fax_number',
    EMAIL: 'supplier_detail.email',
    KEP_ADDRESS: 'supplier_detail.kep_address',
    MERSIS_NUMBER: 'supplier_detail.mersis_number',
  },
  NAME: 'name',
  SUPPLIER_CODE: 'supplier_code',
  COMMISSION: 'commission',
  MATURITY: 'maturity',
  SUPPLIER_SEGMENT: 'supplier_segment',
  CONFIG: {
    INTEGRATION_URL: 'conf.integration_url',
    RECONCILIATION: {
      CREATION_STRATEGY: {
        TIME_ZONE: 'conf.reconciliation_conf.creation_strategy.time_zone',
        DAY_OF_WEEK: 'conf.reconciliation_conf.creation_strategy.day_of_week',
        DAY_OF_MONTH: 'conf.reconciliation_conf.creation_strategy.day_of_month',
        STRATEGY_TYPE: 'conf.reconciliation_conf.creation_strategy.strategy_type',
      },
      ITEM_SELECTION_STRATEGY: {
        DAYS_LATER: 'conf.reconciliation_conf.item_selection_strategy.days_later',
        STRATEGY_TYPE: 'conf.reconciliation_conf.item_selection_strategy.strategy_type',
      },
    },
  },
  IS_ACTIVE: 'is_active',
  AUTO_APPROVE_ENABLED: 'auto_approve_enabled',
  STOCK_RELEASE_TYPE: 'stock_release_type',
  PACKAGE_MANAGEMENT_TYPE: 'package_management_type',
  EXTRAS: 'extras',
};

export const getFormSchema = ({ dynamicUserSettings }) => {
  return z.object({
    supplier_detail: z.object({
      title: z.string().optional(),
      address: z.string().optional(),
      trade_association: z.string().optional(),
      iban_number: z.string().optional(),
      phone_number: z.string().optional(),
      fax_number: z.string().optional(),
      email: z.string().email().or(z.literal('')).optional(),
      kep_address: z.string().optional(),
      mersis_number: z.string().optional(),
    }),
    is_active: z.boolean().optional(),
    package_management_type: z.coerce.boolean().optional(),
    auto_approve_enabled: z.boolean().optional(),
    stock_release_type: z.coerce.boolean().optional(),
    conf: z
      .object({
        integration_url: z.coerce.string().optional(),
        reconciliation_conf: z
          .object({
            creation_strategy: z.object({
              time_zone: z.coerce.number().optional(),
              day_of_week: z.coerce.number().optional(),
              day_of_month: z.coerce.number().optional(),
              strategy_type: z.string().optional(),
            }),
            item_selection_strategy: z
              .object({
                days_later: z.coerce.number().optional(),
                strategy_type: z.string().optional(),
              })
              .required(),
            creation_strategy_change_date: z.string().optional(),
          })
          .required(),
      })
      .required(),
    commission: z.coerce.number().optional(),
    maturity: z.number().optional(),
    extras: z
      .object({
        ...dynamicUserSettings?.reduce((acc, key) => {
          acc[key] = z.string().nullish();
          return acc;
        }, {}),
      })
      .nullish(),
  });
};
