import AkinonFormBuilder from '@components/AkinonFormBuilder';
import { AkinonSearch } from '@components/AkinonInput';
import { Divider, Form, Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getModalFormMeta } from '../FilterOptions/common';

const FilterOptionsModal = ({
  isFilterOptionsModalOpen,
  onModalCancel,
  search,
  onSearch,
  modalFormInitialValues,
  modalForm,
  formMetaFields,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      className="filter-options__modal"
      onCancel={() => {
        onModalCancel();
      }}
      open={isFilterOptionsModalOpen}
      destroyOnClose
      footer={null}
      width={'80%'}
    >
      <div className="filter-options__modal-header">
        <div className="filter-options__modal-header-banner" />
        <h1 className="filter-options__modal-header-title">{t('filters')}</h1>
      </div>
      <Divider className="filter-options__modal-divider" />
      <div className="filter-options__search-filter">
        <AkinonSearch
          defaultValue={search}
          onKeyUp={(e) => onSearch(e.target.value)}
          onBlur={(e) => onSearch(e.target.value)}
          style={{ width: 300 }}
          placeholder={t('search')}
          data-testid="search-filter"
          onSearch={onSearch}
        />
      </div>
      <Form
        data-testid="filter-options-modal-form"
        className="filter-options__modal-form"
        initialValues={modalFormInitialValues}
        form={modalForm}
      >
        <AkinonFormBuilder form={modalForm} meta={getModalFormMeta(formMetaFields)} />
      </Form>
    </Modal>
  );
};

export default FilterOptionsModal;
