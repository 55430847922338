import './style.scss';

import AkinonButton from '@components/AkinonButton';
import { openNotification } from '@components/akinonNotification';
import Box from '@components/utility/box/index';
import { AttributeDataType, validationTypes } from '@constants/commontypes';
import { useAttributesQuery } from '@services/api/hooks/useAttributesQuery';
import { useDownloadBulkTemplateAttribute } from '@services/hooks/products/useDownloadBulkTemplateAttribute';
import { Form, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormField } from './common';

export default function BulkActionAttributes() {
  const { downloadBulkTemplateAttribute, isDownloadBulkDownloadTemplateMutating } =
    useDownloadBulkTemplateAttribute();

  const { t } = useTranslation('ProductsAndCategories');
  const [form] = Form.useForm();

  const { attributes, isAttributesLoading } = useAttributesQuery();

  const createSelectOptions = attributes
    ?.filter((attribute) => attribute?.data_type === AttributeDataType.DROPDOWN)
    .map((attribute) => ({
      label: attribute.name,
      value: attribute.id,
    }));

  const downloadXLSXTemplate = () => {
    form.validateFields([FormField.ATTRIBUTE]).then(({ attribute }) => {
      downloadBulkTemplateAttribute(
        { attribute },
        {
          onSuccess: () => {
            openNotification({
              message: t('file.preparing'),
              description: t('file.download.modal.description'),
              type: 'success',
            });
          },
        }
      );
    });
  };

  return (
    <section className="bulk-actions">
      <Box className="box-primary form-box" title={t('bulk.actions.download.attribute.values')}>
        <Form className="ant-form" form={form} layout="vertical">
          <div>
            <Form.Item
              rules={[validationTypes.required(t('target.attribute'))]}
              name={FormField.ATTRIBUTE}
              label={t('select.attribute')}
              style={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}
            >
              <Select
                virtual={false}
                showSearch
                optionFilterProp="label"
                placeholder={t('select.attribute')}
                options={createSelectOptions}
              ></Select>
            </Form.Item>
          </div>

          <div className="download-template">
            <span>{t('bulk.actions.download')}</span>
            <div style={{ marginInlineStart: 'auto', display: 'flex', gap: '1rem' }}>
              <AkinonButton
                loading={isAttributesLoading || isDownloadBulkDownloadTemplateMutating}
                type="default"
                size="small"
                onClick={downloadXLSXTemplate}
                icon={<i className="icon-download" />}
              >
                XLSX
              </AkinonButton>
            </div>
          </div>
        </Form>
      </Box>
    </section>
  );
}
