import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { defaultRowKey } from '@constants/index';
import { RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { TranslationKey } from '@root/i18n';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getColumns } from './common';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { usePageMeta } from './hooks/usePageMeta';

const StockLocationList = () => {
  const { t } = useTranslation(TranslationKey.LOCATIONS);
  const navigate = useAppNavigate();
  const [pagination, setPagination] = usePagination();

  const columns = getColumns({ t });

  useBreadcrumbs();
  usePageMeta();

  const onRowClick = (record) => () => {
    navigate(RouteUrls.stockLocations.detail, { locationId: record?.id });
  };

  return (
    <section>
      <AkinonTable
        title={t('locations_title')}
        columns={columns}
        dataSource={[
          {
            id: 1,
          },
        ]}
        optionsUrl={'/'}
        loading={false}
        total={1}
        pagination={pagination}
        rowKey={defaultRowKey}
        setPagination={setPagination}
        description={`${1} ${t('results.found')}`}
        onRow={(record) => ({ onClick: onRowClick(record) })}
      />
    </section>
  );
};

export default StockLocationList;
