import AkinonFormItem from '@components/AkinonFormItem';
import { Editor, useMonaco } from '@monaco-editor/react';
import React, { useEffect } from 'react';

/**
 * @param {import('@monaco-editor/react').EditorProps} props
 */
const AkinonCodeEditor = (props) => {
  const monaco = useMonaco();
  const { options, ...restProps } = props;

  const onMountImpl = (monaco) => {
    monaco.editor.defineTheme('default', {
      base: 'vs-dark',
      inherit: true,
      rules: [],
      colors: {
        'editor.background': '#2b344c',
        'editor.lineHighlightBackground': '#2b344c',
      },
    });
  };

  const onEditorMount = (monaco) => {
    monaco && onMountImpl(monaco);
  };

  useEffect(() => {
    if (monaco) {
      onMountImpl(monaco);
    }
  }, [monaco]);

  const component = (
    <Editor
      defaultLanguage="json"
      theme="default"
      {...restProps}
      options={{
        minimap: { enabled: false },
        padding: { top: 10 },
        ...options,
      }}
      beforeMount={onEditorMount}
    />
  );

  if (props?.formItemProps) {
    return <AkinonFormItem {...props.formItemProps}>{component}</AkinonFormItem>;
  }

  return component;
};

export default AkinonCodeEditor;
