import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import AkinonSpin from '@components/AkinonSpin';
import Box from '@components/utility/box';
import { QueryKey } from '@constants/query';
import { RouteUrls } from '@constants/routeUrls';
import { zodResolver } from '@hookform/resolvers/zod';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { queryClient } from '@services/api/queryClient';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useDynamicSettingsDataSource } from '../hooks/useDynamicSettingsDataSource';
import { generateFormFields, getFormInitialValues, getFormSchema } from './common';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { usePageMeta } from './hooks/usePageMeta';

const DynamicSettingsForm = () => {
  const { t } = useTranslation('Settings');
  const navigate = useAppNavigate();
  const { dynamicSettingName, dynamicSettingId } = useParams();
  const {
    dynamicSettingsDataSource,
    dynamicSettingDetail,
    isDynamicSettingLoading,
    updateDynamicSetting,
    createDynamicSetting,
    deleteDynamicSetting,
  } = useDynamicSettingsDataSource({
    dynamicSettingId: dynamicSettingId,
    dynamicSettingMutationOptions: {
      async onSuccess() {
        await queryClient.refetchQueries({
          queryKey: [QueryKey.DYNAMIC_SETTINGS],
          exact: true,
        });
        navigate(RouteUrls.settings.dynamicSettings);
      },
    },
  });

  const { description, key: settingKey = dynamicSettingName } = dynamicSettingDetail ?? {};

  const dynamicSetting = dynamicSettingsDataSource?.find?.(
    (item) => item.key === dynamicSettingName
  );

  useBreadcrumbs({ dynamicSettingName, settingKey });
  usePageMeta();

  const submitDynamicSetting = (submitValue, dynamicSettingId) => {
    if (dynamicSettingId && (submitValue?.value === '0' || submitValue?.value === 0)) {
      deleteDynamicSetting(dynamicSettingId);
      return;
    }

    if (dynamicSettingId) {
      updateDynamicSetting({
        dynamicSettingId: dynamicSettingId,
        data: submitValue,
      });
    } else {
      createDynamicSetting({
        data: submitValue,
      });
    }
  };

  const onSubmit = (formValues) => {
    submitDynamicSetting(formValues, dynamicSettingId);
  };

  const form = useForm({
    mode: 'onChange',
    resolver: zodResolver(getFormSchema({ schema: dynamicSetting })),
    defaultValues: {},
  });
  const { handleSubmit, reset } = form;

  useEffect(() => {
    if (dynamicSetting) reset(getFormInitialValues({ dynamicSetting }));
  }, [dynamicSetting]);

  return (
    <section>
      <AkinonSpin spinning={Boolean(dynamicSettingId && isDynamicSettingLoading)}>
        <Box
          title={settingKey}
          subtitle={description}
          className="box-primary form-box db-settings-form"
        >
          <AkinonForm layout="vertical">
            {generateFormFields({ t, dynamicSetting, form })}
            <AkinonButton htmlType="submit" type="primary" onClick={handleSubmit(onSubmit)}>
              {t('submit')}
            </AkinonButton>
          </AkinonForm>
        </Box>
      </AkinonSpin>
    </section>
  );
};

export default DynamicSettingsForm;
