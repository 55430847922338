import './style.scss';

import AkinonButton from '@components/AkinonButton';
import AkinonFormItem from '@components/AkinonFormItem';
import AkinonInput from '@components/AkinonInput';
import AkinonSelect from '@components/AkinonSelect';
import Box from '@components/utility/box';
import { zodResolver } from '@hookform/resolvers/zod';
import { Checkbox, Divider, Form, Modal, Space, Typography } from 'antd';
import pick from 'lodash/pick';
import range from 'lodash/range';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  getBulkPriceUpdateModalFormSchema,
  ProductsToBeAddedTableFormFormItemKey,
} from '../../common';

const { Text } = Typography;

/**
 * @param {{ form: import('react-hook-form').UseFormReturn, }} props
 */
const BulkPriceUpdateModal = ({
  form,
  setInvisible,
  isVisible,
  dataSource,
  currencyOptions,
  variants,
  variantDynamicFields,
}) => {
  const { t } = useTranslation('ProductsAndCategories');
  const [selectedVariants, setSelectedVariants] = useState([]);
  const { reset, control, trigger } = useForm({
    mode: 'onChange',
    resolver: zodResolver(getBulkPriceUpdateModalFormSchema({ t })),
  });

  const bulkPriceFormValues = useWatch({
    control: control,
    defaultValue: {
      ...pick(form.getValues(), [
        ProductsToBeAddedTableFormFormItemKey.RETAIL_PRICE,
        ProductsToBeAddedTableFormFormItemKey.PRICE,
        ProductsToBeAddedTableFormFormItemKey.CURRENCY_TYPE,
        ProductsToBeAddedTableFormFormItemKey.TAX_RATE,
      ]),
    },
  });

  const { bulk, ...restFormValues } = bulkPriceFormValues;

  const handleCheckboxChange = (event) => {
    setSelectedVariants((prevSelectedVariant) => {
      if (event.target.checked) {
        return [...prevSelectedVariant, event.target.value];
      }
      return prevSelectedVariant.filter((variantIndex) => variantIndex !== event.target.value);
    });
  };

  const onModalClose = useCallback(() => {
    setSelectedVariants([]);
    setInvisible();
    reset({
      bulk: {},
    });
  }, [setInvisible, setSelectedVariants, reset]);

  const handleApplyForSelectedVariants = useCallback(() => {
    if (selectedVariants.length === 0) return;
    trigger().then((isValid) => {
      if (!isValid) return;
      reset({
        bulk: {},
        ...restFormValues,
        ...Object.entries(bulk)
          .filter(([_, value]) => !!value)
          .reduce((acc, [key, value]) => {
            acc[key] = range(dataSource.length).map((index) =>
              selectedVariants.includes(index) ? value : restFormValues[key]?.[index]
            );
            return acc;
          }, {}),
      });
    });
  }, [reset, bulk, dataSource, selectedVariants, restFormValues]);

  const handleBulkPriceUpdate = useCallback(() => {
    trigger().then((isValid) => {
      if (!isValid) return;

      form.reset({
        ...form.getValues(),
        ...restFormValues,
      });
      onModalClose();
    });
  }, [restFormValues, form]);

  const handleCleanAllContent = useCallback(() => {
    reset({
      bulk: {},
    });
  }, [reset]);

  const toggleAllVariantsCheckbox = (event) => {
    if (event.target.checked) {
      setSelectedVariants(range(dataSource.length));
    } else {
      setSelectedVariants([]);
    }
  };

  useEffect(() => {
    if (isVisible)
      reset({
        ...pick(form.getValues(), [
          ProductsToBeAddedTableFormFormItemKey.RETAIL_PRICE,
          ProductsToBeAddedTableFormFormItemKey.PRICE,
          ProductsToBeAddedTableFormFormItemKey.CURRENCY_TYPE,
          ProductsToBeAddedTableFormFormItemKey.TAX_RATE,
        ]),
      });
  }, [isVisible]);

  return (
    <Modal
      className="form-modal"
      onCancel={onModalClose}
      open={isVisible}
      width={'75%'}
      destroyOnClose
      footer={null}
    >
      <Box className="box-primary form-box">
        <Form className="akn-form" layout="vertical">
          <div className="form-modal__header">
            <div className="form-modal__header-banner" />
            <div className="form-modal__header-title">{t('bulk.update.price')}</div>
          </div>
          <Divider className="form-modal__divider" />
          <div className="form-modal__description">{t('bulk.update.price.description')}</div>
          <Divider orientation="left" className="form-modal__divider">
            {t('update.price').toUpperCase()}
          </Divider>
          <div className="form-modal__root-form-builder">
            <AkinonFormItem
              label={t('retail.price')}
              control={control}
              name={`bulk.${ProductsToBeAddedTableFormFormItemKey.RETAIL_PRICE}`}
            >
              <AkinonInput />
            </AkinonFormItem>
            <AkinonFormItem
              label={t('selling.price')}
              control={control}
              name={`bulk.${ProductsToBeAddedTableFormFormItemKey.PRICE}`}
            >
              <AkinonInput />
            </AkinonFormItem>
            <AkinonFormItem
              label={t('currency')}
              control={control}
              name={`bulk.${ProductsToBeAddedTableFormFormItemKey.CURRENCY_TYPE}`}
            >
              <AkinonSelect options={currencyOptions} />
            </AkinonFormItem>
            <AkinonFormItem
              control={control}
              label={t('vat')}
              name={`bulk.${ProductsToBeAddedTableFormFormItemKey.TAX_RATE}`}
            >
              <AkinonInput />
            </AkinonFormItem>
          </div>
          <Space direction="horizontal" className="form-modal__form-actions">
            <AkinonButton onClick={handleApplyForSelectedVariants} htmlType="button" type="primary">
              {t('apply_to_selected_variants').toUpperCase()}
            </AkinonButton>
          </Space>
          <Divider orientation="left" />
          <Checkbox
            checked={selectedVariants.length === dataSource.length}
            className="form-modal-item__checkbox"
            onChange={toggleAllVariantsCheckbox}
          >
            {t('select_all_variants')}
          </Checkbox>

          {dataSource.map((item, index) => (
            <div className="form-modal-item" key={index}>
              <div className="form-modal-item__wrapper">
                <Checkbox
                  value={index}
                  checked={selectedVariants.includes(index)}
                  className="form-modal-item__checkbox"
                  onChange={handleCheckboxChange}
                />
                <Text className={'form-modal-item__variant'}>
                  {Object.keys(variants)
                    .map((variantKey) => {
                      const variantValue = item[variantKey];
                      const variantLabel = variantDynamicFields
                        .find((field) => field.key === variantKey)
                        .options.find((option) => option.value === variantValue)?.label;
                      return variantLabel ?? variantValue;
                    })
                    .join('/')}
                </Text>
                <AkinonFormItem
                  label={t('retail.price')}
                  control={control}
                  name={`${ProductsToBeAddedTableFormFormItemKey.RETAIL_PRICE}.${index}`}
                >
                  <AkinonInput />
                </AkinonFormItem>
                <AkinonFormItem
                  label={t('selling.price')}
                  control={control}
                  name={`${ProductsToBeAddedTableFormFormItemKey.PRICE}.${index}`}
                >
                  <AkinonInput />
                </AkinonFormItem>
                <AkinonFormItem
                  label={t('currency')}
                  control={control}
                  name={`${ProductsToBeAddedTableFormFormItemKey.CURRENCY_TYPE}.${index}`}
                >
                  <AkinonSelect options={currencyOptions} />
                </AkinonFormItem>
                <AkinonFormItem
                  label={t('vat')}
                  control={control}
                  name={`${ProductsToBeAddedTableFormFormItemKey.TAX_RATE}.${index}`}
                >
                  <AkinonInput />
                </AkinonFormItem>
              </div>
              <Divider className="form-modal-item__divider" />
            </div>
          ))}
          <Space>
            <AkinonButton type="primary" htmlType="button" onClick={handleBulkPriceUpdate}>
              {t('save').toUpperCase()}
            </AkinonButton>
            <AkinonButton onClick={handleCleanAllContent} type="danger" htmlType="button">
              {t('clean.all.content').toUpperCase()}
            </AkinonButton>
          </Space>
        </Form>
      </Box>
    </Modal>
  );
};

export default BulkPriceUpdateModal;
