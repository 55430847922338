import useInputHasOverflow from '@utils/hooks/useInputHasOverflow';
import { Input, Tooltip } from 'antd';
import React, { useRef } from 'react';

/*
@description - This component wraps an input and displays a tooltip if the input's value overflows.
*/
const TooltipInput = ({ data }) => {
  const inputRef = useRef(null);
  const hasOverflow = useInputHasOverflow({ inputRef, inputValue: data.value });

  return (
    <Tooltip title={hasOverflow ? data.value : null}>
      <Input {...data} ref={inputRef} />
    </Tooltip>
  );
};

export default TooltipInput;
