import { getMeUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { useAkinonQuery } from '../adapters/useAkinonQuery';
import { client } from '../client';

export function useMeQuery({ queryOptions, onSuccess } = {}) {
  const { data, isFetching, error, isFetched, refetch } = useAkinonQuery({
    queryKey: [QueryKey.USER_ME],
    async queryFn() {
      const response = await client.get(getMeUrl);
      onSuccess?.(response);
      return response;
    },
    cacheTime: Infinity,
    networkMode: 'offlineFirst',
    ...queryOptions,
  });

  return {
    me: data,
    getMe: refetch,
    isMeFetching: isFetching,
    isMeFetched: isFetched,
    meError: error,
  };
}
