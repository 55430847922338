import { rejectCancellationRequest } from '@services/api';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';

const mutationKey = ['reject-cancellation'];

/**
 * @typedef {import('@tanstack/react-query').UseMutationResult} UseMutationResult
 * @returns {import('@tanstack/react-query').UseMutationResult}
 */
export const useRejectCancellationMutation = () => {
  const mutationFn = ({ requestId }) => rejectCancellationRequest({ options: { requestId } });

  const { data, mutate, isLoading, error, ...rest } = useAkinonMutation({
    mutationKey,
    mutationFn,
  });

  return {
    ...rest,
    data: data?.results,
    total: data?.count,
    isLoading,
    error,
    mutate,
  };
};
