import merge from 'lodash/merge';
import uniqBy from 'lodash/uniqBy';

const initialState = {
  breadcrumbs: [],
  openMenuKeys: [],
  selectedMenuKeys: [],
  isLogCenterOpen: false,
  logCenterRecords: [],
  pageMeta: {},
  tokens: [],
};

export const createAppSlice = (set) => ({
  ...initialState,
  // at the 0 index token will be the active token
  addToken: (token) => set((state) => ({ tokens: uniqBy([token, ...state.tokens], 'access') })),
  updateToken: (access, token) =>
    set((state) => ({
      tokens: state.tokens.map((_token) =>
        _token.access === access ? merge(_token, token) : _token
      ),
    })),
  removeToken: (access) =>
    set((state) => ({ tokens: state.tokens.filter((_token) => _token.access !== access) })),
  resetTokens: () => set(() => ({ tokens: [] })),
  setBreadcrumbs: (breadcrumbs) => set(() => ({ breadcrumbs })),
  setOpenMenuKeys: (openMenuKeys) => set(() => ({ openMenuKeys })),
  setSelectedMenuKeys: (selectedMenuKeys) => set(() => ({ selectedMenuKeys })),
  setIsLogCenterOpen: (isLogCenterOpen) => set(() => ({ isLogCenterOpen })),
  addLogCenterRecord: (logCenterRecord) =>
    set((state) => ({ logCenterRecords: [logCenterRecord, ...state.logCenterRecords] })),
  addLogCenterRecords: (logCenterRecords) =>
    set((state) => ({ logCenterRecords: [...logCenterRecords, ...state.logCenterRecords] })),
  setLogCenterRecords: (logCenterRecords) => set(() => ({ logCenterRecords })),
  setPageMeta: (pageMeta) => set(() => ({ pageMeta })),
  resetApp: () => set(() => ({ ...initialState })),
});
