import { usePaginatedPackagesQuery, usePaginatedPackagesWithFileQuery } from '@services/api/hooks';
import { useCustomerAddressesQuery } from '@services/api/hooks/useCustomerAddressQuery';
import { useDataSourcesQuery } from '@services/api/hooks/usePaginatedDataSourcesQuery';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import reject from 'lodash/reject';
import uniq from 'lodash/uniq';

import { useDeliveryOptionForPackage, useOrderDetailsForPackage } from './index';

export const useDataSource = ({ pagination, filters, params = {}, isSuperUser }) => {
  const hasFileFilter = Object.values(filters ?? {}).some((filter) => filter?.originFileObj);
  const { packages, totalPackages, isFetchingPackages } = usePaginatedPackagesQuery({
    pagination,
    filters,
    params,
    queryOptions: {
      enabled: !hasFileFilter,
    },
  });

  const {
    packages: _packages,
    totalPackages: _totalPackages,
    isFetchingPackages: _isFetchingPackages,
  } = usePaginatedPackagesWithFileQuery({
    pagination,
    filters,
    params,
    queryOptions: {
      enabled: hasFileFilter,
    },
  });

  const _dataSource = hasFileFilter ? _packages : packages;

  const orderIds = uniq(reject(map(_dataSource, 'order'), isNil));

  const { packagesWithOrders, isOrdersLoading } = useOrderDetailsForPackage({
    orderIds,
    dataSource: _dataSource,
  });

  const { dataSources } = useDataSourcesQuery({
    queryOptions: {
      enabled: Boolean(isSuperUser),
    },
  });

  const {
    packagesWithDeliveryOption,
    isDeliveryOptionsLoading,
    shippingCompanies,
    shippingMethodOptions,
  } = useDeliveryOptionForPackage({
    dataSource: packagesWithOrders,
  });

  const shippingAddresses = uniq(
    reject(map(packagesWithDeliveryOption, 'order.shipping_address'), isNil)
  );

  const { customerAddresses } = useCustomerAddressesQuery({
    filters: {
      id__in: shippingAddresses?.join(','),
      limit: shippingAddresses?.length,
    },
    queryOptions: {
      enabled: !isEmpty(shippingAddresses),
    },
  });

  const __dataSource = map(packagesWithDeliveryOption, (_package) => ({
    ..._package,
    order: {
      ...get(_package, 'order'),
      datasource: find(dataSources, { id: get(_package, 'order.datasource') }),
      shipping_address: find(customerAddresses, { id: get(_package, 'order.shipping_address') }),
    },
  }));

  const __totalPackages = hasFileFilter ? _totalPackages : totalPackages;
  const __isFetchingPackages = hasFileFilter ? _isFetchingPackages : isFetchingPackages;

  return {
    dataSource: __dataSource,
    dataSources,
    isDataSourceLoading: __isFetchingPackages || isOrdersLoading || isDeliveryOptionsLoading,
    total: __totalPackages || 0,
    shippingCompanies,
    shippingMethodOptions,
  };
};
