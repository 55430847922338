import './style.scss';

import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import AkinonFormItem from '@components/AkinonFormItem';
import AkinonInput, { AkinonTextArea } from '@components/AkinonInput';
import { AkinonRadio } from '@components/AkinonRadio';
import AkinonSelect from '@components/AkinonSelect';
import AkinonSpin from '@components/AkinonSpin';
import Box from '@components/utility/box';
import { RouteUrls } from '@constants/routeUrls';
import { zodResolver } from '@hookform/resolvers/zod';
import useAppNavigate from '@hooks/useAppNavigate';
import ProductImageUpload from '@pages/ProductsAndCategories/ProductForm/components/ProductsToBeAddedTableForm/components/ProductImageUpload';
import { TranslationKey } from '@root/i18n';
import { useDataSourcesQuery } from '@services/api/hooks';
import useImageDimensionsDataSourceQuery from '@services/api/hooks/useImageDimensionsDataSourceQuery';
import { useNotificationQuery } from '@services/api/hooks/useNotificationQuery';
import { usePostNotificationAttachmentMutation } from '@services/api/hooks/usePostNotificationAttachmentMutation';
import { usePostNotificationAudienceMutation } from '@services/api/hooks/usePostNotificationAudienceMutation';
import { usePostNotificationMutation } from '@services/api/hooks/usePostNotificationMutation';
import get from 'lodash/get';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  DeliveryType,
  FormKey,
  getDeliveryTypeOptions,
  getFormSchema,
  getTargetAudienceOptions,
  SchemaKey,
} from './common';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { usePageMeta } from './hooks/usePageMeta';

const NotificationsForm = () => {
  const { t } = useTranslation(TranslationKey.NOTIFICATION_MANAGEMENT);
  const { id: notificationId } = useParams();
  const { notificationDetail, isFetchingNotificationDetail } = useNotificationQuery({
    notificationId,
  });
  const { postNotification, isPostingNotification } = usePostNotificationMutation();
  const { postNotificationAudience } = usePostNotificationAudienceMutation();
  const { postNotificationAttachment } = usePostNotificationAttachmentMutation();

  const detailAudiences = notificationDetail?.[FormKey.AUDIENCES];
  const { dataSources, isFetchingDataSources } = useDataSourcesQuery({
    params: {
      ...(detailAudiences?.length > 0 && {
        id__in: detailAudiences.join(','),
        limit: detailAudiences.length,
      }),
    },
  });
  const navigate = useAppNavigate();
  const { dimensionBounds } = useImageDimensionsDataSourceQuery();
  const schema = getFormSchema({ t, dimensionBounds });
  const { control, handleSubmit, watch, reset } = useForm({
    resolver: zodResolver(schema),
  });
  useBreadcrumbs();
  usePageMeta();

  const initialAttachments =
    notificationDetail?.attachments?.map((image) => {
      return {
        url: image?.file,
        uuid: image?.file,
      };
    }) ?? [];

  useEffect(() => {
    if (notificationId && notificationDetail) {
      reset({
        ...notificationDetail,
        [FormKey.ATTACHMENTS]: initialAttachments,
        [FormKey.DELIVERY_TYPE]: notificationDetail?.[SchemaKey.DELIVERY_TYPE],
      });
    }
  }, [notificationId, notificationDetail]);

  const deliveryType = watch(FormKey.DELIVERY_TYPE);
  const attachments = watch(FormKey.ATTACHMENTS);
  const shouldShowAttachment =
    deliveryType === DeliveryType.POPUP ||
    notificationDetail?.[FormKey.DELIVERY_TYPE] === DeliveryType.POPUP;

  const onSubmit = (formValues) => {
    postNotification(
      {
        requestBody: {
          ntype: get(formValues, FormKey.DELIVERY_TYPE),
          title: get(formValues, FormKey.TITLE),
          message: get(formValues, FormKey.MESSAGE),
        },
      },
      {
        onSuccess: (result) => {
          postNotificationAudience({
            requestBody: {
              audiences: formValues[FormKey.AUDIENCES],
            },
            notificationId: result.id,
          });
          if (attachments?.length > 0) {
            const requestBody = new FormData();
            attachments.forEach((file) => {
              requestBody.append(SchemaKey.ATTACHMENTS, file.originFileObj);
            });
            postNotificationAttachment({
              requestBody: requestBody,
              notificationId: result.id,
            });
          }
          navigate(RouteUrls.notificationManagement.landing);
        },
      }
    );
  };

  const deliveryTypeOptions = getDeliveryTypeOptions({ t });
  const targetAudienceOptions = getTargetAudienceOptions({ users: dataSources });

  return (
    <AkinonSpin spinning={Boolean(notificationId && isFetchingNotificationDetail)}>
      <section className="notifications-form">
        <Box className="box-primary form-box">
          <div className="notifications-form__title">{t('notification_detail')}</div>
          <AkinonForm layout="vertical">
            <AkinonFormItem
              name={FormKey.AUDIENCES}
              label={t('target_audience')}
              control={control}
              required
              disabled={Boolean(notificationId)}
            >
              <AkinonSelect
                mode="multiple"
                optionFilterProp="label"
                showSearch
                options={targetAudienceOptions}
                placeholder={t('target_audience')}
                loading={isFetchingDataSources}
              />
            </AkinonFormItem>
            <AkinonFormItem
              name={FormKey.TITLE}
              label={t('message_title')}
              control={control}
              required
              disabled={Boolean(notificationId)}
            >
              <AkinonInput placeholder={t('message_title')} />
            </AkinonFormItem>
            <AkinonFormItem
              name={FormKey.MESSAGE}
              label={t('message')}
              control={control}
              required
              disabled={Boolean(notificationId)}
            >
              <AkinonTextArea placeholder={t('message')} />
            </AkinonFormItem>
            <AkinonFormItem
              name={FormKey.DELIVERY_TYPE}
              label={t('delivery_type')}
              control={control}
              required
              disabled={Boolean(notificationId)}
            >
              <AkinonRadio options={deliveryTypeOptions} />
            </AkinonFormItem>
            {shouldShowAttachment && (
              <AkinonFormItem
                name={FormKey.ATTACHMENTS}
                label={t('popup_attachment')}
                control={control}
                required
                disabled={Boolean(notificationId)}
              >
                <ProductImageUpload
                  multiple
                  itemRender={() => null}
                  placeholder={t('popup_attachment')}
                  fileList={attachments ?? initialAttachments}
                />
              </AkinonFormItem>
            )}
            <div className="notifications-form__footer">
              <AkinonFormItem>
                <AkinonButton
                  htmlType="submit"
                  onClick={handleSubmit(onSubmit)}
                  disabled={Boolean(notificationId) || isPostingNotification}
                >
                  {t('send').toUpperCase()}
                </AkinonButton>
              </AkinonFormItem>
            </div>
          </AkinonForm>
        </Box>
      </section>
    </AkinonSpin>
  );
};

export default NotificationsForm;
