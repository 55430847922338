import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { createDynamicSetting } from '@services/api/dynamicSettings';

export function useCreateDynamicSettingMutation({ mutationOptions = {} }) {
  const mutationResponse = useAkinonMutation({
    mutationFn: createDynamicSetting,
    ...mutationOptions,
  });

  return {
    createDynamicSetting: mutationResponse.mutate,
    isDynamicSettingCreating: mutationResponse.isLoading,
  };
}
