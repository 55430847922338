import { openNotification } from '@components/akinonNotification';
import { limitQuery } from '@constants/commontypes';
import { getOrderItems } from '@services/api';
import { useQuery } from '@tanstack/react-query';
import { T } from '@utils/languageProvider';

/**
 * @typedef {import('@tanstack/react-query').UseQueryResult} UseQueryResult
 * @returns {import('@tanstack/react-query').UseQueryResult}
 */

export const useOrderItemsQuery = ({ orderItemIds, enabled = true, params = {} }) => {
  const queryKey = ['order-items', { orderItemIds: orderItemIds?.join(',') }];
  const queryFn = () =>
    getOrderItems({
      params: { ...limitQuery, id__in: orderItemIds?.join(','), ...params },
    });

  const { data, error, mutate, isFetching, ...rest } = useQuery({
    queryKey,
    queryFn,
    enabled,
  });

  const onError = () => {
    const message = error?.response?.data?.detail || error?.message;

    openNotification({
      message: T('error'),
      description: message,
      type: 'error',
    });
  };

  if (error) {
    onError();
  }

  return {
    ...rest,
    data: data?.results,
    mutate,
    isLoading: isFetching,
    error,
    total: data?.count,
  };
};
