import './style.scss';

import { FileImageOutlined } from '@ant-design/icons';
import React from 'react';

const ImageTableHead = ({ required }) => {
  return (
    <div className="image-table-head">
      <FileImageOutlined />
      {required && <div className="image-table-head__required-mark color-red">*</div>}
    </div>
  );
};

export default ImageTableHead;
