import { useShipmentCompanyOptionsQuery } from '@services/api/hooks/useShipmentCompanyOptionsQuery';
import { useShippingCompaniesQuery } from '@services/api/hooks/useShippingCompaniesQuery';
import { useShippingOptionsQuery } from '@services/api/hooks/useShippingOptionsQuery';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import reject from 'lodash/reject';
import uniq from 'lodash/uniq';

export function useDeliveryOptionForPackage({ dataSource }) {
  const { shipmentCompanyOptions, isFetchingShipmentCompanyOptions } =
    useShipmentCompanyOptionsQuery();

  const shippingCompanyIds = uniq(reject(map(shipmentCompanyOptions, 'shipping_company'), isNil));
  const { shippingCompanies, isFetchingShippingCompanies } = useShippingCompaniesQuery({
    params: {
      id__in: shippingCompanyIds.join(','),
      limit: shippingCompanyIds?.length,
    },
    queryOptions: {
      enabled: !isEmpty(shippingCompanyIds),
    },
  });

  const shippingOptionIds = uniq(reject(map(shipmentCompanyOptions, 'shipping_option'), isNil));
  const { shippingOptions, isFetchingShippingOptions } = useShippingOptionsQuery({
    params: {
      id__in: shippingOptionIds.join(','),
      limit: shippingOptionIds?.length,
    },
    queryOptions: {
      enabled: !isEmpty(shippingOptionIds),
    },
  });

  const packagesWithDeliveryOption = dataSource?.map((packageItem) => {
    const shippingMethod = shipmentCompanyOptions?.find(
      (method) => method.id === packageItem.shipping_method
    );
    const shippingCompany = shippingCompanies?.find(
      (company) => company.id === shippingMethod?.shipping_company
    );
    const shippingOption = shippingOptions?.find(
      (option) => option.id === shippingMethod?.shipping_option
    );

    return {
      ...packageItem,
      shipping_method: shippingMethod,
      shipping_company: shippingCompany,
      shipping_option: shippingOption,
    };
  });

  const loading =
    isFetchingShipmentCompanyOptions || isFetchingShippingCompanies || isFetchingShippingOptions;

  const shippingMethodOptions = !loading
    ? shipmentCompanyOptions?.map((option) => {
        const shippingCompany = shippingCompanies?.find(
          (company) => company?.id === option?.shipping_company
        );

        const shippingOption = shippingOptions?.find(
          (shippingOption) => shippingOption.id === option?.shipping_option
        );

        return {
          label: `${shippingCompany?.name} / ${shippingOption?.name}`,
          value: option.id,
        };
      })
    : [];

  return {
    packagesWithDeliveryOption: packagesWithDeliveryOption,
    isDeliveryOptionsLoading: loading,
    shippingMethodOptions,
    shippingCompanies,
  };
}
