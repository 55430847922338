import { postDataSourceLandingPageUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';

import { client } from '../client';

export function usePostDataSourceLandingPageMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody }) => client.post(postDataSourceLandingPageUrl, requestBody),
    ...mutationOptions,
  });

  return {
    postDataSourceLandingPage: mutate,
    isPostingDataSourceLandingPage: isLoading,
  };
}
