import { getRejectionsUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { useAkinonQuery } from '../adapters/useAkinonQuery';
import { client } from '../client';

export const useRejectionsQuery = ({ params, queryOptions }) => {
  const { data, isFetching, isLoading, error } = useAkinonQuery({
    queryKey: [QueryKey.REJECTIONS, params],
    queryFn: () => client.get(getRejectionsUrl, { params }),
    ...queryOptions,
  });

  return {
    rejectionsResponse: data,
    rejections: data?.results,
    totalRejections: data?.count,
    isFetchingRejections: isFetching,
    isRejectionsLoading: isLoading,
    rejectionsError: error,
  };
};
