import { postProductCollectionWidgetUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';

import { client } from '../client';

export function usePostProductCollectionWidgetMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody }) => client.post(postProductCollectionWidgetUrl, requestBody),
    ...mutationOptions,
  });

  return {
    postProductCollectionWidget: mutate,
    isPostingProductCollectionWidget: isLoading,
  };
}
