import { useCategoriesQuery } from '@services/api/hooks/useCategoriesQuery';

import { generateTreeData } from '..';

export function useCategoryTreeData({ queryOptions, generateTreeDataOptions } = {}) {
  const { categories, isCategoriesLoading, isCategoriesFetching } = useCategoriesQuery({
    queryOptions,
  });

  const categoryTreeData = generateTreeData({
    data: categories,
    ...(generateTreeDataOptions ?? {}),
  });

  return {
    categoryTreeData,
    categories,
    isCategoriesLoading,
    isCategoriesFetching,
  };
}
