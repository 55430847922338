import { useStore } from '@zustand-store/index';
import { setPageMetaSelector } from '@zustand-store/selectors/app';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

export function usePageMeta({ subject }) {
  const { t } = useTranslation('Support');
  const setPageMeta = useStore(setPageMetaSelector);

  useEffect(() => {
    setPageMeta({
      title: t('support_ticket_detail_subject', { subject }),
      description: t('support_ticket_detail_description'),
    });
  }, [t, subject]);

  useUnmount(() => {
    setPageMeta({});
  });
}
