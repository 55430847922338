import { useDynamicColumnsAttributeValues } from '@pages/ProductsAndCategories/hooks/useDynamicColumnsAttributeValues';
import { useDataSourcesQuery } from '@services/api/hooks/usePaginatedDataSourcesQuery';
import { usePaginatedProductImagesQuery } from '@services/api/hooks/usePaginatedProductImagesQuery';
import { usePaginatedProductOffersQuery } from '@services/api/hooks/usePaginatedProductOffersQuery';
import { useProductsQuery } from '@services/api/hooks/usePaginatedProductsQuery';
import { usePaginatedProductStockListQuery } from '@services/api/hooks/usePaginatedProductStockListQuery';
import entries from 'lodash/entries';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import reject from 'lodash/reject';
import uniq from 'lodash/uniq';

export const useDataSource = ({ dynamicFormMetaFields, pagination, filters, stockId }) => {
  const { productStocks, totalProductStocks, isFetchingProductStocks, productStocksError } =
    usePaginatedProductStockListQuery({
      pagination,
      filters,
      params: {
        'stock-list': stockId,
      },
    });

  const datasourceIds = reject(uniq(map(productStocks, 'datasource')), isNil);
  const { dataSources, isFetchingDataSources } = useDataSourcesQuery({
    params: {
      id__in: datasourceIds?.join(','),
    },
    queryOptions: {
      enabled: !isFetchingProductStocks && !isEmpty(datasourceIds),
    },
  });

  const offerIds = reject(uniq(map(productStocks, 'offer')), isNil);
  const { productOffers, isFetchingProductOffers } = usePaginatedProductOffersQuery({
    params: {
      id__in: offerIds?.join(','),
      limit: offerIds?.length,
    },
    queryOptions: {
      enabled: !isFetchingProductStocks && !isEmpty(offerIds),
    },
  });

  const productIds = reject(uniq(map(productOffers, 'product')), isNil);
  const { products, isFetchingProducts } = useProductsQuery({
    params: { id__in: productIds.join(','), limit: productIds?.length },
    queryOptions: { enabled: !isFetchingProductOffers && !isEmpty(productIds) },
  });

  const { productImages, isFetchingProductImages } = usePaginatedProductImagesQuery({
    params: {
      limit: productIds?.length,
      parent__in: productIds?.join(','),
      order: 0,
    },
    queryOptions: {
      enabled: !isFetchingProductOffers && !isEmpty(productIds),
    },
  });

  const { attributeValues, isFetchingAttributeValues } = useDynamicColumnsAttributeValues({
    dynamicFormMetaFields,
  });

  const isLoading =
    isFetchingDataSources ||
    isFetchingProductStocks ||
    isFetchingProductImages ||
    isFetchingProductOffers ||
    isFetchingProducts ||
    isFetchingAttributeValues;

  // Map related data.
  const data = productStocks?.map((item) => {
    const datasource = dataSources?.find(({ id }) => id === item.datasource);
    const offer = productOffers?.find(({ id }) => id === item.offer);
    const product = products?.find(({ id }) => id === offer?.product);
    const image = productImages?.find(({ parent }) => parent === product?.id);
    const attributes = {
      ...reduce(
        entries({ ...product?.attributes, ...offer?.attributes }),
        (acc, [key, value]) => {
          const attributeOptions = attributeValues?.[key] ?? [];
          const valueLabel = get(find(attributeOptions, { value }), 'label', value);
          return {
            ...acc,
            [key]: valueLabel,
          };
        },
        {}
      ),
    };
    return {
      ...item,
      attributes,
      datasource,
      product,
      image,
    };
  });

  return {
    data,
    isLoading,
    total: totalProductStocks,
    error: productStocksError,
  };
};
