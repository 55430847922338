import { postBulkStatusUpdateUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { client } from '@services/api/client';

export function usePostBulkStatusUpdateMutation({ mutationOptions } = {}) {
  const { mutate: postBulkStatusUpdate, isLoading: isPostingBulkStatusUpdate } = useAkinonMutation({
    mutationFn: ({ requestBody, datasource, filters }) =>
      client.post(postBulkStatusUpdateUrl, requestBody, {
        params: { datasource, ...filters },
      }),
    ...mutationOptions,
  });

  return {
    postBulkStatusUpdate,
    isPostingBulkStatusUpdate,
  };
}
