import './styles.scss';

import If from '@components/If';
import React from 'react';
import { useTranslation } from 'react-i18next';

import AkinonPagination from '../AkinonPagination';
import AkinonTableActions from '../AkinonTableActions';

const StandardAkinonTableFooter = ({
  selectedRows,
  tableActions,
  pagination,
  setPagination,
  shouldShowTableActionsInFooter,
  pageSizeLabel,
  setSelectedRows,
}) => {
  const { t } = useTranslation();
  return (
    <div className="standard-akinon-table-footer">
      <If
        condition={shouldShowTableActionsInFooter}
        then={
          <AkinonTableActions
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            tableActions={tableActions}
            placeholder={t('select.action')}
          />
        }
        otherwise={<div />}
      />
      {pagination && (
        <AkinonPagination
          pageSizeLabel={pageSizeLabel}
          pagination={pagination}
          setPagination={setPagination}
        />
      )}
    </div>
  );
};

export default StandardAkinonTableFooter;
