/**
 * @typedef {{ customElement: React.ReactNode,title: string, description: string, actions: (import('antd').ButtonProps & { label: string })[], tutorials: { title: string, description: string }[]}} PageMeta
 */
export const breadcrumbsSelector = (state) => state.breadcrumbs;
export const setBreadcrumbsSelector = (state) => state.setBreadcrumbs;
export const openMenuKeysSelector = (state) => state.openMenuKeys;
export const setOpenMenuKeysSelector = (state) => state.setOpenMenuKeys;
export const selectedMenuKeysSelector = (state) => state.selectedMenuKeys;
export const setSelectedMenuKeysSelector = (state) => state.setSelectedMenuKeys;
export const isLogCenterOpenSelector = (state) => state.isLogCenterOpen;
export const setIsLogCenterOpenSelector = (state) => state.setIsLogCenterOpen;
export const logCenterRecordsSelector = (state) => state.logCenterRecords;
export const addLogCenterRecordSelector = (state) => state.addLogCenterRecord;
export const addLogCenterRecordsSelector = (state) => state.addLogCenterRecords;
export const setLogCenterRecordsSelector = (state) => state.setLogCenterRecords;
/** @type {() => PageMeta} */
export const pageMetaSelector = (state) => state.pageMeta;
/** @type {() => (pageMeta: PageMeta) => void} */
export const setPageMetaSelector = (state) => state.setPageMeta;
export const resetAppSelector = (state) => state.resetApp;
