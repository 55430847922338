import './MobileSelectDropdown.scss';

import AkinonButton from '@components/AkinonButton';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

const MobileSelectDropdown = ({ title, menu, onClear, onClose, onApply, isFadingOut }) => {
  const { t } = useTranslation();

  return (
    <div
      className="mobile-select-dropdown backdrop-blur-sm"
      onClick={(e) => {
        if (e.target !== e.currentTarget) return;
        onClose();
      }}
    >
      <div
        className={clsx({
          'mobile-select-dropdown__inner': true,
          'mobile-select-dropdown__inner--fade-out': isFadingOut,
        })}
      >
        <div className="mobile-select-dropdown__header">
          <AkinonButton type="text" onClick={onClear}>
            {t('clear')}
          </AkinonButton>
          {title && <div className="mobile-select-dropdown__title">{title}</div>}
          <AkinonButton
            type="link"
            className="mobile-select-dropdown__title-apply-button"
            onClick={onApply}
          >
            {t('apply')}
          </AkinonButton>
        </div>
        {menu}
      </div>
    </div>
  );
};

export default MobileSelectDropdown;
