import { approveBulkRefundUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { client } from '@services/api/client';

export function useApproveBulkRefundMutation({ mutationOptions = {} }) {
  const { mutate: approveBulkRefund, isLoading: isApproveBulkRefundMutating } = useAkinonMutation({
    mutationFn: ({ requestBody }) => client.post(approveBulkRefundUrl, requestBody),
    ...mutationOptions,
  });

  return {
    approveBulkRefund,
    isApproveBulkRefundMutating,
  };
}
