import { createSelectOptions } from '@common/index';
import { getAttributeInputOptionsUrl } from '@constants/apiUrls';
import { client } from '@services/api/client';
import isEmpty from 'lodash/isEmpty';
import partition from 'lodash/partition';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ApiConfigType } from '../common';

export function useDynamicFormFields({ forbiddenKeys, attributeConfigs, productAttributes, t }) {
  const [variantDynamicFields, setVariantDynamicFields] = useState([]);
  const [isVariantDynamicFieldsLoading, setIsVariantDynamicFieldsLoading] = useState(true);
  const [variantListAttributeFields, setVariantListAttributeFields] = useState([]);
  const [isVariantListAttributeFieldsLoading, setIsVariantListAttributeFieldsLoading] =
    useState(true);

  const dynamicFormFields = useMemo(() => {
    if (isEmpty(attributeConfigs) || isEmpty(productAttributes)) return [];

    return partition(
      attributeConfigs
        .map((config) => {
          const matchedAttribute = productAttributes.find(
            (attribute) => attribute.id == config.attribute
          );
          if (!matchedAttribute) return null;
          const { id: attributeId, key, name, data_type = '' } = matchedAttribute;
          const isRequired = config.is_required || config.is_variant || config.is_offer;
          const field = !forbiddenKeys?.includes(key) && {
            key,
            placeholder: t('attribute.set.desc'),
            required: isRequired,
            id: attributeId,
            attributeId,
            initialValue: config.default_value,
            is_variant: config.is_variant,
            is_offer: config.is_offer,
            label: name,
            data_type,
          };
          if (field?.data_type === ApiConfigType.DROPDOWN) {
            field.labelKey = 'label';
            field.valueKey = 'value';
            field.showSearch = true;
            field.optionFilterProp = 'label';
          }
          if (field?.data_type === ApiConfigType.BOOL) {
            field.checkedChildren = t('yes');
            field.unCheckedChildren = t('no');
          }

          return field;
        })
        .filter(Boolean),
      (dynamicFormItem) => dynamicFormItem?.is_variant
    );
  }, [attributeConfigs, productAttributes, t, ...forbiddenKeys]);

  const getOptions = useCallback(async (attributeId) => {
    try {
      const { results } = await client.get(getAttributeInputOptionsUrl(attributeId));
      return createSelectOptions(results, { labelKey: 'label', valueKey: 'value' });
    } catch (e) {
      return [];
    }
  }, []);

  useEffect(() => {
    const _variantListAttributeFields = dynamicFormFields.at(1);
    const _variantDynamicFields = dynamicFormFields.at(0);
    setIsVariantListAttributeFieldsLoading(true);
    setIsVariantDynamicFieldsLoading(true);

    if (_variantListAttributeFields?.length > 0) {
      Promise.all(
        _variantListAttributeFields.map((field) =>
          field.data_type === ApiConfigType.DROPDOWN
            ? getOptions(field.attributeId).then((options) => ({ ...field, options }))
            : field
        )
      )
        .then((fieldsWithOptions) => {
          setVariantListAttributeFields(fieldsWithOptions);
        })
        .finally(() => {
          setIsVariantListAttributeFieldsLoading(false);
        });
    } else {
      setIsVariantListAttributeFieldsLoading(false);
      setVariantListAttributeFields([]);
    }

    if (_variantDynamicFields?.length > 0) {
      Promise.all(
        _variantDynamicFields.map((field) =>
          field.data_type === ApiConfigType.DROPDOWN
            ? getOptions(field.attributeId).then((options) => ({ ...field, options }))
            : field
        )
      )
        .then((fieldsWithOptions) => {
          setVariantDynamicFields(fieldsWithOptions);
          setIsVariantDynamicFieldsLoading(false);
        })
        .finally(() => {
          setIsVariantDynamicFieldsLoading(false);
        });
    } else {
      setIsVariantDynamicFieldsLoading(false);
      setVariantDynamicFields([]);
    }
  }, [dynamicFormFields]);

  return {
    variantDynamicFields,
    isVariantDynamicFieldsLoading,
    variantListAttributeFields,
    isVariantListAttributeFieldsLoading,
  };
}
