import './styles.scss';

import { Divider, Modal, Progress, Typography } from 'antd';
import clsx from 'clsx';
import merge from 'lodash/merge';
import omit from 'lodash/omit';
import React from 'react';

/**
 * @param {{ errorMessage: string, description: string, progressProps: import('antd').ProgressProps, modalProps: import('antd').ModalProps }} props
 */

const AkinonProgressModal = ({
  errorMessage,
  description,
  progressProps,
  modalProps,
  children = () => null,
}) => {
  // Set default progressProps and merge with rest
  const akinonProgressProps = merge(
    { status: 'active', strokeColor: 'green', size: 20 },
    progressProps
  );
  // Set default modalProps and merge with rest
  const akinonModalProps = merge({ footer: null, centered: true }, modalProps);

  return (
    <Modal
      className={clsx('akinon-progress-modal', akinonModalProps?.className)}
      {...omit(akinonModalProps, ['className'])}
    >
      <Typography.Text>{description}</Typography.Text>
      <Progress
        data-testid="progress"
        className={clsx('akinon-progress-modal__progress', akinonProgressProps?.className)}
        {...omit(akinonProgressProps, ['className'])}
      />
      {children}
      {errorMessage && (
        <>
          <Divider className="akinon-progress-modal__divider" />
          <Typography.Text type="danger">{errorMessage}</Typography.Text>
        </>
      )}
    </Modal>
  );
};

export default AkinonProgressModal;
