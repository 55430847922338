import { getOrderDetailUrl, getOrderItemsUrl, getOrdersUrl } from '@constants/apiUrls';
import { client } from '@services/api/client';

export const getOrderDetail = ({ orderId }) => {
  return client.get(getOrderDetailUrl, { urlParams: { orderId } });
};

export const getOrderItems = ({ params }) => {
  return client.get(getOrderItemsUrl, { params });
};

export const getOrders = ({ requestConfig }) => {
  return client.get(getOrdersUrl, requestConfig);
};
