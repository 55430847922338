import { getOrderItemsUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { client } from '@services/api/client';

import { useAkinonQuery } from '../adapters/useAkinonQuery';

export const useOrderItemsQuery = ({ queryOptions, params, onSuccess } = {}) => {
  const { data, isLoading, error, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.ORDER_ITEMS, params],
    async queryFn() {
      const response = await client.get(getOrderItemsUrl, { params });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    orderItems: data?.results,
    isOrderItemsLoading: isLoading,
    isFetchingOrderItems: isFetching,
    orderItemsError: error,
  };
};
