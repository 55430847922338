import { useRef, useState } from 'react';

export function useCrop() {
  const imageRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState(null);
  const [scale, setScale] = useState(1);
  const [rotation, setRotation] = useState(0);

  const resetCrop = () => {
    setCrop(null);
    setCompletedCrop(null);
    setScale(1);
    setRotation(0);
  };

  const isCropValid = completedCrop && completedCrop.width > 1 && completedCrop.height > 1;

  return {
    imageRef,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    setScale,
    rotation,
    setRotation,
    resetCrop,
    isCropValid,
  };
}
