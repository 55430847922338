import { getShippingCompanyOptionUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { useAkinonQuery } from '../adapters/useAkinonQuery';
import { client } from '../client';

export function useShippingCompanyOptionHttpOptionsQuery({ optionId, queryOptions }) {
  const { data, isLoading, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.SHIPPING_COMPANY_OPTIONS_HTTP_OPTIONS, optionId],
    async queryFn() {
      const response = await client.options(getShippingCompanyOptionUrl, {
        urlParams: { optionId },
      });
      return response;
    },
    ...queryOptions,
  });

  return {
    shippingCompanyOptionHttpOptions: data,
    isShippingCompanyOptionHttpOptionsLoading: isLoading,
    isFetchingShippingCompanyOptionHttpOptions: isFetching,
  };
}
