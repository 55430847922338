import { usePaginatedNotificationsQuery } from '@services/api/hooks/usePaginatedNotificationsQuery';

export function useDataSource({ pagination, filters }) {
  const { notifications, totalNotifications, isFetchingNotifications } =
    usePaginatedNotificationsQuery({
      pagination,
      filters,
    });

  return {
    dataSource: notifications,
    total: totalNotifications,
    isDataSourceLoading: isFetchingNotifications,
  };
}
