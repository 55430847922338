import { wildButtonProps } from '@components/WildButton';
import { UserRole } from '@constants/auth';
import { useTour } from '@reactour/tour';
import { useUserRole } from '@utils/hooks/useUserRole';
import useStore from '@zustand-store/index';
import { setPageMetaSelector } from '@zustand-store/selectors/app';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

import { getTourSteps } from '../common';

export function useRejectedOffersPageMeta() {
  const { t } = useTranslation('ProductsAndCategories');
  const setPageMeta = useStore(setPageMetaSelector);
  const { setIsOpen, setCurrentStep, setSteps } = useTour();
  const userRole = useUserRole();

  useEffect(() => {
    setSteps(
      getTourSteps({
        t,
      })
    );
  }, [t]);

  const onStartTour = () => {
    setCurrentStep(0);
    setIsOpen(true);
  };

  useEffect(() => {
    setPageMeta({
      title: t('product.offer.declined'),
      description: t('product.offer.declined.desc'),
      actions: [
        userRole === UserRole.SELLER && {
          ...wildButtonProps,
          label: t('start_tour'),
          onClick: onStartTour,
        },
      ].filter(Boolean),
    });
  }, [t]);

  useUnmount(() => {
    setPageMeta({});
  });
}
