import { patchDataSourceShippingCompanyOptionConfigUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useQueryClient } from '@tanstack/react-query';

import { useAkinonMutation } from '../adapters/useAkinonMutation';
import { client } from '../client';

export function usePatchDataSourceShippingCompanyOptionConfigMutation() {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody, configId }) =>
      client.patch(patchDataSourceShippingCompanyOptionConfigUrl, requestBody, {
        urlParams: { configId },
      }),
    onSuccess() {
      queryClient.invalidateQueries([QueryKey.DATA_SOURCE_SHIPPING_COMPANY_OPTION_CONFIGS]);
    },
  });

  return {
    updateDataSourceShippingCompanyOptionConfig: mutate,
    isUpdatingDataSourceShippingCompanyOptionConfig: isLoading,
  };
}
