import { useDataSourcesQuery } from '@services/api/hooks/usePaginatedDataSourcesQuery';
import { usePaginatedUsersQuery } from '@services/hooks/users/useUsers';
import isEmpty from 'lodash/isEmpty';

import { StaticFilterKey, UserSources } from '../common';

export const useDataSource = ({ pagination, filters }) => {
  const shouldShowIntegrationUsers = Boolean(filters[StaticFilterKey.SOURCE_IN]);
  const sources = [UserSources.LOCAL, UserSources.OMNITRON];
  if (shouldShowIntegrationUsers) sources.push(UserSources.INTEGRATION);

  const { users, isFetchingUsers, totalUsers } = usePaginatedUsersQuery({
    pagination,
    filters,
    params: {
      source__in: sources.join(','),
    },
  });

  const userDataSources = users?.map((user) => user.datasource) ?? [];

  const { dataSources, isFetchingDataSources } = useDataSourcesQuery({
    params: {
      limit: userDataSources.length,
      id__in: userDataSources.join(','),
    },
    queryOptions: {
      enabled: !isFetchingUsers && !isEmpty(users),
    },
  });

  const isDataSourceLoading = isFetchingUsers || isFetchingDataSources;

  const usersDataSource = users?.map((user) => ({
    ...user,
    datasource: dataSources?.find((dataSource) => dataSource.id === user.datasource),
  }));

  return {
    dataSource: usersDataSource,
    isDataSourceLoading,
    total: totalUsers,
  };
};
