import './styles.scss';

import AkinonButton from '@components/AkinonButton';
import { openNotification } from '@components/akinonNotification';
import { Modal, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import useRemoveProductsCommission from '../../hooks/useRemoveProductsCommission';

const { Text } = Typography;

const DeleteProductCommissionModal = ({
  specialCommissionProductSelectedRowKeys,
  refetchProductOffers,
  refetchReconciliationRules,
  deleteReconciliationRules,
  deleteReconciliationRulesAsync,
  modalProps,
}) => {
  const { t } = useTranslation('Finance');

  const { onRemoveProductsCommission } = useRemoveProductsCommission({
    t,
    refetchProductOffers,
    refetchReconciliationRules,
    deleteReconciliationRulesAsync,
  });

  const handleClose = () => {
    modalProps?.onCancel();
  };

  const onSubmit = () => {
    const isMultiple = specialCommissionProductSelectedRowKeys.length > 1;
    if (isMultiple) {
      onRemoveProductsCommission({
        selectedRows: specialCommissionProductSelectedRowKeys,
      });
    } else {
      const reconciliationRuleId = specialCommissionProductSelectedRowKeys[0];
      deleteReconciliationRules(
        {
          ruleId: reconciliationRuleId,
          requestConfig: {
            suppressedNotifications: ['success', 'error'],
          },
        },
        {
          onSuccess: () => {
            refetchProductOffers();
            refetchReconciliationRules();
            openNotification({
              message: t('transaction_success'),
              description: t('transactions.body.delete_commission_success'),
              type: 'success',
            });
          },
          onError: (error) => {
            openNotification({
              message: t('transaction_failed'),
              description: error.response?.data?.detail ?? error.message,
              type: 'error',
            });
          },
        }
      );
    }
    handleClose();
  };

  return (
    <Modal
      {...modalProps}
      onCancel={handleClose}
      footer={null}
      className="remove-product-commission-modal"
    >
      <Text className="remove-product-commission-modal__title">
        {specialCommissionProductSelectedRowKeys?.length > 1
          ? t('delete_commissions_definition', {
              count: specialCommissionProductSelectedRowKeys?.length,
            })
          : t('delete_commission_definition')}
      </Text>
      <div className="remove-product-commission-modal__buttons">
        <AkinonButton onClick={onSubmit}>{t('save')}</AkinonButton>
        <AkinonButton type="danger" onClick={handleClose}>
          {t('cancel')}
        </AkinonButton>
      </div>
    </Modal>
  );
};

export default DeleteProductCommissionModal;
