import './styles.scss';

import { CloseOutlined } from '@ant-design/icons';
import { Color } from '@constants/theme';
import { components } from '@reactour/tour';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

export function Badge({ children }) {
  return (
    <components.Badge
      styles={{
        badge: (base) => ({ ...base, backgroundColor: Color.DODGER_BLUE, display: 'none' }),
      }}
    >
      {children}
    </components.Badge>
  );
}

export function Close({ onClick }) {
  return (
    <button className="akinon-tour-close" onClick={onClick}>
      <CloseOutlined />
    </button>
  );
}

export function Content({ content }) {
  return <components.Content className="akinon-tour-content" content={content} />;
}

export function Navigation({ ...otherProps }) {
  return <components.Navigation {...otherProps} hideDots />;
}

export function ContentComponent(props) {
  const { t } = useTranslation();
  const { currentStep, steps, setCurrentStep, setIsOpen } = props;
  const isLastStep = currentStep === steps.length - 1;
  const content = steps[currentStep].content;

  function onClose() {
    setIsOpen(false);
  }

  function onNextStep() {
    if (isLastStep) {
      onClose();
    } else {
      setCurrentStep(currentStep + 1);
    }
  }

  return (
    <div className="akinon-tour-content-component">
      {typeof content === 'function' ? content({ ...props }) : content}
      <button className="akinon-tour-content-component__close-button" onClick={onClose}>
        <CloseOutlined />
      </button>
      <div className="akinon-tour-content-component__navigation">
        <button
          className="akinon-tour-content-component__navigation-button"
          onClick={() => setCurrentStep(currentStep - 1)}
          disabled={currentStep === 0}
        >
          {t('previous')}
        </button>
        <Text className="akinon-tour-content-component__navigation-text">
          {currentStep + 1} / {steps.length}
        </Text>
        <button className="akinon-tour-content-component__navigation-button" onClick={onNextStep}>
          {isLastStep ? t('finish') : t('next')}
        </button>
      </div>
    </div>
  );
}
