import React from 'react';

/**
 * @param {Object} props
 * @param {string} [props.id]
 * @param {{
 * name: string;
 * Element: React.ElementType;
 * elementProps?: {
 * children?: React.ReactNode;
 * onClick?: React.MouseEventHandler<HTMLButtonElement>;
 * value?: string;
 * };
 * }[][]} props.formats
 * @returns {React.JSX.Element}
 */
export const QuillToolbar = ({ id, formats }) => {
  return (
    <div id={id ?? 'quill-toolbar'} className="quill-toolbar">
      {formats.map((group, index) => (
        <span key={index} className="ql-formats">
          {group.map(({ name, Element, elementProps: { children, ...otherProps } = {} }, index) => (
            <Element key={index} {...otherProps} className={`ql-${name}`}>
              {children}
            </Element>
          ))}
        </span>
      ))}
    </div>
  );
};
