import * as z from 'zod';

export const FormKey = {
  EMAIL: 'email',
  IS_ACTIVE: 'is_active',
  LAST_LOGIN: 'last_login',
};

export const getFormSchema = () => {
  return z.object({
    email: z.string().email().min(1),
    is_active: z.coerce.boolean(),
  });
};
