import './styles.scss';

import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import { lockBodyScroll, unlockBodyScroll } from '@utils/index';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import AkinonMenu from '../AkinonMenu';

const AkinonMobileMenu = (props) => {
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (isMenuOpen) {
      lockBodyScroll();
    } else {
      unlockBodyScroll();
    }
    return () => {
      unlockBodyScroll();
    };
  }, [isMenuOpen]);

  const closeWithFadeout = () => {
    setIsFadingOut(true);
    setTimeout(() => {
      setIsMenuOpen(false);
      setIsFadingOut(false);
    }, 300);
  };

  return (
    <div className={'akinon-mobile-menu'}>
      {!isMenuOpen && (
        <MenuOutlined
          style={{ color: '#fff', fontSize: 18 }}
          onClick={() => setIsMenuOpen((prev) => !prev)}
        />
      )}
      {isMenuOpen && (
        <div
          className={clsx('akinon-mobile-menu-inner', {
            'akinon-mobile-menu-inner--fade-out': isFadingOut,
          })}
        >
          <div className="akinon-mobile-menu__close-icon-container">
            <CloseOutlined style={{ color: '#fff', fontSize: 18 }} onClick={closeWithFadeout} />
          </div>
          <AkinonMenu {...props} closeMobileDrawer={closeWithFadeout} />
        </div>
      )}
    </div>
  );
};

export default AkinonMobileMenu;
