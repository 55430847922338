import { getTownshipsUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { client } from '@services/api/client';

import { useAkinonQuery } from '../adapters/useAkinonQuery';

export function useTownshipsQuery({ queryOptions } = {}) {
  const { data, isLoading } = useAkinonQuery({
    queryKey: [QueryKey.CITIES],
    queryFn: () => client.get(getTownshipsUrl),
    ...queryOptions,
  });

  return {
    townships: data?.results,
    isTownshipsLoading: isLoading,
  };
}
