import './styles.scss';

import AkinonButton from '@components/AkinonButton';
import { Modal, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const RevokeModal = ({ show, loading, onClose, onClick }) => {
  const { t } = useTranslation('Settings');

  return (
    <Modal open={show} onCancel={onClose} footer={null} title={t('revoke_modal_title')}>
      <Text className="revoke-modal__description">{t('revoke_modal_description')}</Text>
      <AkinonButton
        type="primary"
        htmlType="submit"
        className="revoke-modal__submit-button"
        loading={loading}
        onClick={onClick}
      >
        {t('approve').toUpperCase()}
      </AkinonButton>
    </Modal>
  );
};

export default RevokeModal;
