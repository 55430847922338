import { useShippingCompanyQuery } from '@services/api/hooks/useShippingCompanyQuery';
import { useShippingMethodQuery } from '@services/api/hooks/useShippingMethodQuery';
import { useShippingOptionQuery } from '@services/api/hooks/useShippingOptionQuery';
import isEmpty from 'lodash/isEmpty';

export const useShippingDetail = ({ packageDetail }) => {
  const { shippingOption, isShippingOptionLoading, refetchShippingOption } = useShippingOptionQuery(
    {
      shippingMethodId: packageDetail?.shipping_method,
      queryOptions: {
        enabled: !isEmpty(packageDetail?.shipping_method),
      },
    }
  );

  const { shippingCompany, isShippingCompanyLoading } = useShippingCompanyQuery({
    shippingCompanyId: shippingOption?.shipping_company,
    queryOptions: {
      enabled: !isEmpty(shippingOption?.shipping_company),
    },
  });

  const { shippingMethod, isShippingMethodLoading } = useShippingMethodQuery({
    shippingOptionId: shippingOption?.shipping_option,
    queryOptions: {
      enabled: !isEmpty(shippingOption?.shipping_option),
    },
  });

  const shippingDetail = {
    ...shippingOption,
    shipping_company: shippingCompany,
    shipping_method: shippingMethod,
  };

  const isShippingDetailLoading =
    isShippingOptionLoading || isShippingCompanyLoading || isShippingMethodLoading;

  return {
    shippingDetail,
    refetchShippingDetail: refetchShippingOption,
    isShippingDetailLoading,
  };
};
