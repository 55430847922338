import { WidgetType } from '@constants/commontypes';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

export const getCurrentFiltersFormMeta = (fields, isMobile) => ({
  columns: isMobile ? 1 : 4,
  fields,
});

export const getCompoundFiltersFormMeta = ({
  staticFiltersFormMetaFields,
  dynamicFiltersFormMetaFields,
  pageFilters,
}) => {
  if (isEmpty(pageFilters)) {
    return staticFiltersFormMetaFields;
  }

  const pageFiltersDynamicFormMetaFields = dynamicFiltersFormMetaFields.filter(
    ({ key }) => pageFilters[key]
  );

  return [...staticFiltersFormMetaFields, ...pageFiltersDynamicFormMetaFields];
};

export const getInitialFormValues = ({ pageFilters, fields, searchQuery }) => {
  const initialValues = {};

  Object.entries(pageFilters).forEach(([pageFilterKey, pageFilterValue]) => {
    const widget = find(fields, { key: pageFilterKey })?.widget;
    if (widget === WidgetType.DATE_PICKER) {
      initialValues[pageFilterKey] = pageFilterValue
        ? moment.isMoment(pageFilterValue)
          ? pageFilterValue
          : moment(pageFilterValue)
        : undefined;
    } else {
      initialValues[pageFilterKey] = pageFilterValue;
    }
  });

  if (searchQuery) {
    initialValues.q = searchQuery;
  }

  return initialValues;
};

export const removeEmptyFilters = (filters) => {
  return Object.keys(filters).reduce((acc, key) => {
    if (filters[key] === '') {
      acc[key] = undefined;
    } else {
      acc[key] = filters[key];
    }
    return acc;
  }, {});
};
