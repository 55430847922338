import { patchWidgetUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';

import { client } from '../client';

export function usePatchWidgetMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody, widgetId }) =>
      client.patch(patchWidgetUrl, requestBody, {
        urlParams: { widgetId },
      }),
    ...mutationOptions,
  });

  return {
    patchWidget: mutate,
    isPatchingWidget: isLoading,
  };
}
