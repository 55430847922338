import { postProductCollectionURL } from '@constants/apiUrls';

import { useAkinonMutation } from '../adapters/useAkinonMutation';
import { client } from '../client';

export function useAddProductToCollectionMutation({ mutationOptions } = {}) {
  const { mutate, mutateAsync, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody }) => client.post(postProductCollectionURL, requestBody),
    ...mutationOptions,
  });

  return {
    addProductToCollection: mutate,
    addProductToCollectionAsync: mutateAsync,
    isAddingProductToCollection: isLoading,
  };
}
