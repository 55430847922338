import AkinonFilter from '@components/AkinonFilter';
import { usePageFilters } from '@components/AkinonFilter/hooks/store/usePageFilters';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { getImportsUrl } from '@constants/apiUrls';
import { defaultRowKey } from '@constants/index';
import useDocumentDownloadQuery from '@services/api/hooks/useDocumentDownloadQuery';
import { useImportOptionsQuery } from '@services/api/hooks/useImportOptionsQuery';
import { downloadBinaryFile, getFilenameFromURL } from '@utils/index';
import { Divider } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getImportTableColumns, getStaticFilters } from './common';
import { useDataSource } from './hooks/useDataSource';

export default function ImportsTab() {
  const { t } = useTranslation('ImportExportOperations');
  const filters = usePageFilters();
  const [pagination, setPagination] = usePagination();
  const { dataSource, isLoading, total } = useDataSource({ pagination, filters });
  const [downloadUrl, setDownloadUrl] = useState(null);

  const { isFetching: isDownloading } = useDocumentDownloadQuery({
    documentUrl: downloadUrl,
    onSuccess(data) {
      downloadBinaryFile(data, getFilenameFromURL(downloadUrl));
    },
    onSettled() {
      setDownloadUrl(null);
    },
    queryOptions: {
      enabled: Boolean(downloadUrl),
    },
  });

  const exportTableColumns = getImportTableColumns({
    t,
    setDownloadUrl,
    isDownloading,
    downloadUrl,
  });

  const { importOptions } = useImportOptionsQuery();
  const registryOptions = importOptions?.registry || {};

  const staticFilters = getStaticFilters({ t, registryOptions });

  return (
    <section>
      <AkinonFilter title={t('filters')} {...staticFilters} />
      <Divider />
      <AkinonTable
        pagination={pagination}
        setPagination={setPagination}
        rowKey={defaultRowKey}
        title={t('imports')}
        optionsUrl={getImportsUrl}
        dataSource={dataSource}
        loading={isLoading}
        total={total}
        columns={exportTableColumns}
        description={
          <>
            {t('n_results_found', { count: total })}
            <br />
            {t('imports_description')}
          </>
        }
      />
    </section>
  );
}
