import { usePageFilters } from '@components/AkinonFilter/hooks/store/usePageFilters';
import { usePaginatedExportsQuery } from '@services/api/hooks/usePaginatedExportsQuery';

export function useDataSource({ pagination }) {
  const filters = usePageFilters();
  const { exports, isFetchingExports, totalExports } = usePaginatedExportsQuery({
    pagination,
    filters,
  });

  return {
    dataSource: exports,
    isLoading: isFetchingExports,
    total: totalExports,
  };
}
