import * as React from 'react';

const IconPopup = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={32}
    height={30}
    {...props}
  >
    <title>{'popup'}</title>
    <defs>
      <path id="a" d="M0 0h32v30H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#BCBCBC"
        d="M7.741 27.416c.12 0 .24-.046.332-.138l1.9-1.911a.473.473 0 0 0 0-.667.467.467 0 0 0-.662 0l-1.576 1.584-.69-.67a.467.467 0 0 0-.662.012.473.473 0 0 0 .011.666l1.021.991c.091.089.209.133.326.133M25.038 24.71a.467.467 0 0 0-.663 0l-.61.613-.609-.613a.467.467 0 0 0-.662 0 .473.473 0 0 0 0 .667l.609.612-.61.612a.473.473 0 0 0 .332.804c.12 0 .24-.046.332-.137l.609-.613.609.613a.466.466 0 0 0 .663 0 .473.473 0 0 0 0-.667l-.61-.612.61-.612a.473.473 0 0 0 0-.667ZM2.934 1.71a.47.47 0 0 0-.47.47c0 .26.21.472.47.472h.663a.47.47 0 0 0 .469-.471.47.47 0 0 0-.469-.472h-.663ZM5.559 1.71a.47.47 0 0 0-.469.47c0 .26.21.472.469.472h.663a.47.47 0 0 0 .47-.471.47.47 0 0 0-.47-.472H5.56ZM8.184 1.71a.47.47 0 0 0-.469.47c0 .26.21.472.47.472h.662a.47.47 0 0 0 .47-.471.47.47 0 0 0-.47-.472h-.663Z"
      />
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <path
        fill="#BCBCBC"
        d="M.938 21.978V4.36h30.125v17.617H.938ZM30.416 0h-6.654a.47.47 0 0 0-.469.471c0 .26.21.471.469.471h6.654a.65.65 0 0 1 .646.65V3.42H.938V1.592c0-.358.29-.65.647-.65h20.304a.47.47 0 0 0 .469-.47.47.47 0 0 0-.47-.472H1.585A1.59 1.59 0 0 0 0 1.592v23.455a.47.47 0 1 0 .938 0V22.92H15.53v6.138H1.604a.669.669 0 0 1-.667-.67v-1.457a.47.47 0 1 0-.937 0v1.457C0 29.277.72 30 1.604 30H25.43a.47.47 0 0 0 .469-.471.47.47 0 0 0-.469-.471H16.47V22.92h14.593v5.468c0 .37-.298.67-.666.67h-3.092a.47.47 0 0 0-.468.47c0 .261.21.472.468.472h3.092A1.61 1.61 0 0 0 32 28.388V1.592A1.59 1.59 0 0 0 30.416 0Z"
        mask="url(#b)"
      />
      <path
        fill="#BCBCBC"
        d="M25.502 18.314c0 .224-.18.405-.403.405H6.901a.404.404 0 0 1-.403-.405V8.024c0-.223.18-.404.403-.404h18.198c.222 0 .403.181.403.405v10.29ZM25.1 6.677H6.901c-.74 0-1.34.605-1.34 1.347v10.29c0 .743.6 1.348 1.34 1.348h18.198c.74 0 1.34-.605 1.34-1.348V8.024c0-.742-.6-1.347-1.34-1.347Z"
      />
      <path
        fill="#BCBCBC"
        d="M9.294 10.247h9.662a.47.47 0 0 0 .468-.471.47.47 0 0 0-.468-.471H9.294a.47.47 0 0 0-.468.47c0 .261.21.472.468.472M22.706 11.567H9.294a.47.47 0 0 0-.468.471c0 .26.21.471.468.471h13.412a.47.47 0 0 0 .468-.47.47.47 0 0 0-.468-.472M22.706 13.83H9.294a.47.47 0 0 0-.468.47c0 .26.21.472.468.472h13.412a.47.47 0 0 0 .468-.471.47.47 0 0 0-.468-.471M22.706 16.092H9.294a.47.47 0 0 0-.468.471c0 .26.21.471.468.471h13.412a.47.47 0 0 0 .468-.47.47.47 0 0 0-.468-.472"
      />
    </g>
  </svg>
);
export default IconPopup;
