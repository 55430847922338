import React from 'react';

import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { usePageMeta } from './hooks/usePageMeta';

const FinanceDashboard = () => {
  useBreadcrumbs();
  usePageMeta();

  return <div>Finance Dashboard</div>;
};

export default FinanceDashboard;
