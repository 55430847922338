import './styles.scss';

import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import AkinonInput from '@components/AkinonInput';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, Modal, Typography } from 'antd';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import useUpdateProductSpecialCommission from '../../hooks/useUpdateProductSpecialCommission';
import { FormKey, getFormSchema } from './common';

const { Text } = Typography;

const AddSpecialCommissionProductFormModal = ({
  specialCommissionProductSelectedRowKeys,
  specialCommissionProductDataSource,
  refetchProductOffers,
  refetchReconciliationRules,
  postReconciliationRulesAsync,
  deleteReconciliationRulesAsync,
  modalProps,
}) => {
  const { t } = useTranslation('Finance');

  const { onUpdateProductSpecialCommission } = useUpdateProductSpecialCommission({
    t,
    specialCommissionProductDataSource,
    deleteReconciliationRulesAsync,
    postReconciliationRulesAsync,
    refetchProductOffers,
    refetchReconciliationRules,
  });

  const schema = getFormSchema();
  const { control, handleSubmit, reset } = useForm({
    mode: 'onChange',
    resolver: zodResolver(schema),
  });

  const handleClose = () => {
    modalProps?.onCancel();
    reset();
  };

  const onSubmit = (formValues) => {
    onUpdateProductSpecialCommission({
      formValues,
      selectedRows: specialCommissionProductSelectedRowKeys,
    });

    handleClose();
  };

  return (
    <Modal
      {...modalProps}
      onCancel={handleClose}
      footer={null}
      className="add-special-commission-product-form"
    >
      <Text className="add-special-commission-product-form__desc">
        {specialCommissionProductSelectedRowKeys?.length > 1
          ? t('delete_commissions_definition', {
              count: specialCommissionProductSelectedRowKeys?.length,
            })
          : t('update_commission_and_term_settings_desc')}
      </Text>
      <AkinonForm
        control={control}
        schema={schema}
        onFinish={handleSubmit(onSubmit)}
        layout="vertical"
      >
        <AkinonInput
          label={t('commission')}
          name={FormKey.COMMISSION}
          placeholder={t('commission')}
        />
        <AkinonInput label={t('maturity')} name={FormKey.MATURITY} placeholder={t('maturity')} />
        <Form.Item wrapperCol={{ span: 24 }}>
          <AkinonButton htmlType="submit" type="primary">
            {t('submit')}
          </AkinonButton>
        </Form.Item>
      </AkinonForm>
    </Modal>
  );
};

export default AddSpecialCommissionProductFormModal;
