import { readStorageItem, writeStorageItem } from '@common/storage';
import { formatLanguageCode, i18nDefaultLanguageKey, Language } from '@constants/locale';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const detectionOptions = {
  order: [
    'htmlTag',
    'localStorage',
    'sessionStorage',
    'cookie',
    'querystring',
    'path',
    'subdomain',
    'navigator',
  ],
};

// Force default language to be English regardless of browser settings
const currentLang = readStorageItem(i18nDefaultLanguageKey);
!currentLang && writeStorageItem(i18nDefaultLanguageKey, formatLanguageCode(Language.ENGLISH));

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    defaultNS: 'common',
    fallbackNS: 'common',
    react: {
      useSuspense: true,
    },
    detection: detectionOptions,
    fallbackLng: formatLanguageCode(Language.ENGLISH),
    supportedLngs: [formatLanguageCode(Language.ENGLISH), formatLanguageCode(Language.TURKISH)],
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

export const TranslationKey = {
  AUTHENTICATION: 'Authentication',
  COMMON: 'common',
  DASHBOARD: 'Dashboard',
  DOWNLOAD: 'Download',
  FINANCE: 'Finance',
  IMPORT_EXPORT_OPERATIONS: 'ImportExportOperations',
  MARKETING: 'Marketing',
  MESSAGES: 'Messages',
  NOTIFICATION_MANAGEMENT: 'NotificationManagement',
  ORDERS: 'Orders',
  PRODUCTS_AND_CATEGORIES: 'ProductsAndCategories',
  PROFILE: 'Profile',
  REPORTING: 'Reporting',
  SELLER_STORE_MANAGEMENT: 'SellerStoreManagement',
  SETTINGS: 'Settings',
  SUPPORTS: 'Supports',
  LOCATIONS: 'Locations',
};
