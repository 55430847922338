import AkinonFormItem from '@components/AkinonFormItem';
import AkinonSelect from '@components/AkinonSelect';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormKey } from '../common';
import { getCalculatorTypeOptions, getSelectedCalculatorFields } from './common';

const CalculatorFields = ({ control, shippingCompanyOptionHttpOptions, disabled }) => {
  const { t } = useTranslation();
  const calculatorType = useWatch({
    control,
    name: FormKey.CALCULATOR_TYPE,
  });

  const calculators = shippingCompanyOptionHttpOptions?.calculators;

  const calculatorTypeSelect = (
    <AkinonFormItem name={FormKey.CALCULATOR_TYPE} label={t('calculator')} control={control}>
      <AkinonSelect
        options={getCalculatorTypeOptions(calculators)}
        disabled={disabled}
        placeholder={t('calculator')}
      />
    </AkinonFormItem>
  );

  if (!calculators) return calculatorTypeSelect;
  const selectedCalculator = calculators.find((calculator) => calculator.slug === calculatorType);
  const selectedCalculatorFields = getSelectedCalculatorFields({
    control,
    selectedCalculator,
    disabled,
  })?.filter(Boolean);

  return (
    <>
      {calculatorTypeSelect}
      {...selectedCalculatorFields}
    </>
  );
};

export default CalculatorFields;
