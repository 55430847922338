import { exportOrderItemsUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { useAkinonQuery } from '../adapters/useAkinonQuery';
import { client } from '../client';

export function useExportOrderItemsQuery({ params, queryOptions, onSuccess }) {
  const { refetch, isRefetching } = useAkinonQuery({
    queryKey: [QueryKey.EXPORT_ORDER_ITEMS, params],
    async queryFn() {
      const response = await client.get(exportOrderItemsUrl, { params });
      onSuccess?.(response);
      return response;
    },
    enabled: false,
    ...queryOptions,
  });

  return {
    exportOrderItems: refetch,
    isExportingOrderItems: isRefetching,
  };
}
