import { patchRejectionReasonUrl } from '@constants/apiUrls';

import { useAkinonMutation } from '../adapters/useAkinonMutation';
import { client } from '../client';

export function usePatchOfferRejectReasonMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody, rejectionReasonId }) =>
      client.patch(patchRejectionReasonUrl, requestBody, { urlParams: { rejectionReasonId } }),
    ...mutationOptions,
  });

  return {
    patchOfferRejectReason: mutate,
    isPatchingOfferRejectReason: isLoading,
  };
}
