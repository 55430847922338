import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

export const FormKey = {
  REJECTION_REASON: 'rejection_reason',
  REJECTION_EXPLANATION: 'rejection_explanation',
  ADD_TO_CATALOG: 'add_to_catalog',
};

export const getFormResolver = ({ rejectionReasonsOptions }) => {
  return zodResolver(
    z.object({
      [FormKey.REJECTION_REASON]: z.union(
        rejectionReasonsOptions?.map((rejectionReason) => z.literal(rejectionReason.value))
      ),
      [FormKey.REJECTION_EXPLANATION]: z.string().optional(),
      [FormKey.ADD_TO_CATALOG]: z.boolean().optional(),
    })
  );
};
