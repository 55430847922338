import './styles.scss';

import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import AkinonFormBuilder from '@components/AkinonFormBuilder';
import Box from '@components/utility/box';
import { Form, Modal, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getFormMeta } from './common';

const BulkRejectModal = ({
  open,
  onStartBulkAction,
  onCancel,
  rejectionReasons,
  rejectionFormRef,
  rejectionDescription,
}) => {
  const { t } = useTranslation('ProductsAndCategories');
  const [form] = Form.useForm();

  const meta = getFormMeta({ t, rejectionReasons });

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleFinish = (values) => {
    onStartBulkAction(values);
    form.resetFields();
  };

  return (
    <Modal
      open={open}
      title={t('rejection.reason.modal.title')}
      className="permission-save-modal"
      onCancel={handleCancel}
      footer={null}
    >
      <Box className="box-primary form-box">
        <AkinonForm form={form} onFinish={handleFinish} layout="vertical" ref={rejectionFormRef}>
          <AkinonFormBuilder form={form} meta={meta} />
          <Typography.Text className="rejection-description">
            {rejectionDescription}
          </Typography.Text>
          <AkinonButton htmlType="submit" type="primary">
            {t('save')}
          </AkinonButton>
        </AkinonForm>
      </Box>
    </Modal>
  );
};

export default BulkRejectModal;
