import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { getTicketsUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { useAkinonQuery } from '../adapters/useAkinonQuery';
import { client } from '../client';

export function usePaginatedTicketsQuery({ queryOptions, filters, pagination } = {}) {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.TICKETS, filters, pagination],
    queryFn: () =>
      client.get(getTicketsUrl, {
        params: {
          ...getRequestFiltersParams(filters),
          ...getRequestPaginationParams(pagination),
        },
      }),
    ...queryOptions,
  });

  return {
    tickets: data?.results,
    totalTickets: data?.count,
    isFetchingTickets: isFetching,
  };
}
