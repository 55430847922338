import { WidgetType } from '@constants/commontypes';
import { useTranslation } from 'react-i18next';

import { StaticFilterKey } from './common';

export default function useStaticFilters() {
  const { t } = useTranslation('ProductsAndCategories');

  const staticFiltersFormMetaFields = [
    {
      key: StaticFilterKey.PRODUCT_NAME,
      widget: WidgetType.INPUT,
      placeholder: t('product_name'),
    },
    {
      key: StaticFilterKey.PRODUCT_BASE_CODE,
      widget: WidgetType.INPUT,
      placeholder: t('base.code'),
    },
    {
      key: StaticFilterKey.OFFER_SKU,
      widget: WidgetType.INPUT,
      placeholder: t('offer_code'),
    },
    {
      key: StaticFilterKey.PRODUCT_SKU,
      widget: WidgetType.INPUT,
      placeholder: t('sku'),
    },
    {
      key: StaticFilterKey.IS_ACTIVE,
      widget: WidgetType.SELECT,
      placeholder: t('status'),
      options: [
        { label: t('active'), value: true },
        { label: t('passive'), value: false },
      ],
      widgetProps: {
        showSearch: true,
        optionFilterProp: 'label',
      },
    },
  ];

  return {
    staticFiltersFormMetaFields,
    isStaticFiltersLoading: false,
  };
}
