import { getProductUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { useAkinonQuery } from '../adapters/useAkinonQuery';
import { client } from '../client';

export function useProductQuery({ productId, params, queryOptions, onSuccess } = {}) {
  const { data, isLoading, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.PRODUCT, productId, params],
    async queryFn() {
      const response = await client.get(getProductUrl, {
        params,
        urlParams: { productId },
      });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    product: data,
    isProductLoading: isLoading,
    isFetchingProduct: isFetching,
  };
}
