import { deleteCategoryNodePermissionsUrl } from '@constants/apiUrls';

import { useAkinonMutation } from '../adapters/useAkinonMutation';
import { client } from '../client';

export function useDeleteCategoryNodePermissionsMutation({ mutationOptions } = {}) {
  const { mutateAsync, isLoading } = useAkinonMutation({
    mutationFn: ({ categoryNodeId }) => {
      client.delete(deleteCategoryNodePermissionsUrl, { urlParams: { categoryNodeId } });
    },
    ...mutationOptions,
  });

  return {
    deleteCategoryNodePermission: mutateAsync,
    isDeletingNewCategoryNodePermission: isLoading,
  };
}
