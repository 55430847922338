import { QueryKey } from '@constants/query';
import { getProductOffers } from '@services/api/products/offers';
import { useQuery } from '@tanstack/react-query';

export function useProductOffersQuery({ requestConfig, queryOption }) {
  const queryKey = [QueryKey.PRODUCT_OFFERS, requestConfig];

  const queryFn = () => getProductOffers(requestConfig.params);

  const { data, isFetching, isLoading, error } = useQuery({
    queryKey,
    queryFn,
    ...queryOption,
  });
  return {
    productOffers: data?.results,
    productOffersTotal: data?.count,
    isProductOffersLoading: isLoading,
    isProductOffersFetching: isFetching,
    productOffersError: error,
  };
}
