import { limitQuery } from '@constants/commontypes';
import { useDataSourceShippingCompanyOptionConfigsQuery } from '@services/api/hooks/usePaginatedDataSourceShippingCompanyOptionConfigsQuery';
import { useShippingCompaniesQuery } from '@services/api/hooks/useShippingCompaniesQuery';
import { useShippingCompanyOptionsQuery } from '@services/api/hooks/useShippingCompanyOptionsQuery';
import { useShippingOptionsQuery } from '@services/api/hooks/useShippingOptionsQuery';
import filter from 'lodash/filter';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import property from 'lodash/property';
import uniq from 'lodash/uniq';

export default function useDatasource({ datasourceId }) {
  const {
    dataSourceShippingCompanyOptionConfigs,
    isFetchingDataSourceShippingCompanyOptionConfigs,
  } = useDataSourceShippingCompanyOptionConfigsQuery({
    queryOptions: {
      enabled: Boolean(datasourceId),
    },
    params: {
      datasource: datasourceId,
    },
  });

  const dataSourceShippingCompanyOptions = map(
    dataSourceShippingCompanyOptionConfigs,
    property('shipping_company_option')
  );

  const { shippingCompanyOptions, isFetchingShippingCompanyOptions, totalShippingCompanyOptions } =
    useShippingCompanyOptionsQuery({
      params: {
        id__in: dataSourceShippingCompanyOptions.join(','),
        ...limitQuery,
      },
      queryOptions: {
        enabled:
          !isFetchingDataSourceShippingCompanyOptionConfigs &&
          !isEmpty(dataSourceShippingCompanyOptions),
      },
    });

  const companyIds = uniq(map(shippingCompanyOptions, property('shipping_company')));
  const shippingOptionIds = uniq(map(shippingCompanyOptions, property('shipping_option')));

  const { shippingCompanies, isFetchingShippingCompanies } = useShippingCompaniesQuery({
    params: {
      id__in: companyIds.join(','),
      length: companyIds.length,
    },
    queryOptions: {
      enabled: !isFetchingShippingCompanyOptions && !isEmpty(companyIds),
    },
  });

  const { shippingOptions, isFetchingShippingOptions } = useShippingOptionsQuery({
    params: {
      id__in: shippingOptionIds.join(','),
      length: shippingOptionIds.length,
    },
    queryOptions: {
      enabled: !isFetchingShippingCompanyOptions && !isEmpty(shippingOptionIds),
    },
  });

  const datasource = filter(
    map(shippingCompanyOptions, (shippingCompanyOption) => {
      const shippingCompany = find(shippingCompanies, {
        id: shippingCompanyOption.shipping_company,
      });
      const shippingOption = find(shippingOptions, {
        id: shippingCompanyOption.shipping_option,
      });

      const shippingCompanyOptionConfig = find(dataSourceShippingCompanyOptionConfigs, {
        shipping_company_option: shippingCompanyOption.id,
      });

      if (!shippingCompanyOptionConfig) {
        return false;
      }

      return {
        ...shippingCompanyOption,
        shipping_company_obj: shippingCompany,
        shipping_option_obj: shippingOption,
        shipping_company_option_config_obj: shippingCompanyOptionConfig,
        is_real_active: shippingCompanyOptionConfig?.is_active,
      };
    }),
    Boolean
  );

  return {
    datasource,
    total: totalShippingCompanyOptions,
    isLoading:
      isFetchingShippingCompanyOptions ||
      isFetchingShippingCompanies ||
      isFetchingShippingOptions ||
      isFetchingDataSourceShippingCompanyOptionConfigs,
  };
}
