import useLanguageSlice from '@components/AkinonFilter/hooks/store/useLanguageSlice';
import { formatLanguageCode } from '@constants/locale';
import { useUser } from '@root/contexts/hooks/useUser';
import { useLanguagesQuery } from '@services/hooks/common/useLanguagesQuery';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export function useSetDefaultLanguage() {
  const { i18n } = useTranslation();
  const user = useUser();
  const { language, setLanguage } = useLanguageSlice();
  const { languages } = useLanguagesQuery({
    cacheTime: Infinity,
    placeholderData: {},
  });
  useEffect(() => {
    if (user?.language) {
      setLanguage(formatLanguageCode(user?.language));
    }
  }, [user?.language]);

  useEffect(() => {
    const { default: defaultLanguage } = languages || {};
    const didUserSelectALanguage = user?.language || language;
    if (!didUserSelectALanguage) setLanguage(formatLanguageCode(defaultLanguage));
  }, [languages, language, user]);

  useEffect(() => {
    if (!language) return;
    i18n.changeLanguage(formatLanguageCode(language));
  }, [language]);
}
