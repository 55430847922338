import { patchShippingCompanyOptionUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useQueryClient } from '@tanstack/react-query';

import { useAkinonMutation } from '../adapters/useAkinonMutation';
import { client } from '../client';

export function usePatchShippingCompanyOptionMutation() {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ optionId, requestBody }) =>
      client.patch(patchShippingCompanyOptionUrl, requestBody, {
        urlParams: { optionId },
      }),
    onSuccess() {
      queryClient.invalidateQueries([QueryKey.SHIPPING_COMPANY_OPTIONS]);
    },
  });

  return {
    updateShippingCompanyOption: mutate,
    isUpdatingShippingCompanyOption: isLoading,
  };
}
