import AkinonButton from '@components/AkinonButton';
import { openNotification } from '@components/akinonNotification/index.jsx';
import AkinonPopover from '@components/AkinonPopover';
import If from '@components/If/index.jsx';
import Show from '@components/Show';
import { wildButtonProps } from '@components/WildButton';
import { UserRole } from '@constants/auth';
import { PackageStatus } from '@constants/commontypes';
import { useTour } from '@reactour/tour';
import { useUserRole } from '@utils/hooks/useUserRole';
import useStore from '@zustand-store/index';
import { setPageMetaSelector } from '@zustand-store/selectors/app';
import { Col, Modal, Row } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ShippingMethodSlug } from '../common';

export function usePageMeta({
  packageStatus,
  patchPackageMutate,
  refetchShippingOptionList,
  setIsCargoInfoModalVisible,
  packageDetail,
  onShipAllHandler,
  shippingMethod,
  setIsShipAllModalVisible,
  setIsInvoiceInfoModalVisible,
  refetchGenerateLabel,
}) {
  const { t } = useTranslation('Orders');
  const setPageMeta = useStore(setPageMetaSelector);
  const userRole = useUserRole();
  const { setIsOpen, setCurrentStep } = useTour();

  const onMarkAsDelivered = () => {
    Modal.confirm({
      title: t('markAsDelivered'),
      content: t('markAsDeliveredContent'),
      onOk() {
        patchPackageMutate({ params: { status: PackageStatus.DELIVERED } });
      },
    });
  };

  const onMarkAsFailed = () => {
    Modal.confirm({
      title: t('markAsFailed'),
      content: t('markAsFailedContent'),
      onOk() {
        patchPackageMutate({ params: { status: PackageStatus.FAILED } });
      },
    });
  };

  const onChangeCargoCompany = () => {
    refetchShippingOptionList();
    setIsCargoInfoModalVisible(true);
  };

  const onGenerateLabel = () => {
    refetchGenerateLabel();
  };

  const onReadyToShip = () => {
    patchPackageMutate(
      {
        params: {
          status: PackageStatus.READY,
        },
      },
      {
        onSuccess() {
          openNotification({
            type: 'success',
            message: t('success'),
            description: t('package_is_ready_to_ship_success_message'),
          });
        },
        onError() {
          openNotification({
            type: 'error',
            message: t('error'),
            description: t('package_is_ready_to_ship_error_message'),
          });
        },
      }
    );
  };

  const onShipAll = () => {
    const hasTrackingInfo = packageDetail?.tracking_url && packageDetail?.tracking_number;

    hasTrackingInfo
      ? onShipAllHandler({
          cargo_tracking_number: packageDetail?.tracking_number,
          cargo_tracking_url: packageDetail?.tracking_url,
        })
      : setIsShipAllModalVisible(true);
  };

  const onEnterInvoiceInfoForAll = () => {
    setIsInvoiceInfoModalVisible(true);
  };

  const onStartTour = () => {
    setCurrentStep(0);
    setIsOpen(true);
  };

  const onMarkAllAsDelivered = () => {
    patchPackageMutate(
      {
        params: {
          status: PackageStatus.DELIVERED,
        },
      },
      {
        onSuccess() {
          openNotification({
            type: 'success',
            message: t('success'),
            description: t('mark_all_as_delivered_success_message'),
          });
        },
        onError() {
          openNotification({
            type: 'error',
            message: t('error'),
            description: t('mark_all_as_delivered_error_message'),
          });
        },
      }
    );
  };

  const onDeliveryFailed = () => {
    patchPackageMutate(
      {
        params: {
          status: PackageStatus.FAILED,
        },
      },
      {
        onSuccess() {
          openNotification({
            type: 'success',
            message: t('success'),
            description: t('delivery_failed_success_message'),
          });
        },
        onError() {
          openNotification({
            type: 'error',
            message: t('error'),
            description: t('delivery_failed_error_message'),
          });
        },
      }
    );
  };

  useEffect(() => {
    setPageMeta({
      title: t('sidebar_package_detail'),
      description: t('package_detail_desc'),
      customElement: (
        <Row gutter={16}>
          <Show when={packageStatus !== PackageStatus.CANCELED}>
            <Col>
              <AkinonPopover
                placement="bottom"
                content={
                  <>
                    <Show when={packageStatus === PackageStatus.SHIPPED}>
                      <AkinonButton type="text" onClick={onEnterInvoiceInfoForAll}>
                        {t('enter_invoice_info_all')}
                      </AkinonButton>
                      <If
                        condition={shippingMethod?.slug === ShippingMethodSlug.OTHER}
                        then={
                          <>
                            <AkinonButton type="text" onClick={onMarkAllAsDelivered}>
                              {t('mark_all_as_delivered')}
                            </AkinonButton>
                            <AkinonButton type="text" onClick={onDeliveryFailed}>
                              {t('delivery_failed')}
                            </AkinonButton>
                          </>
                        }
                        otherwise={
                          <AkinonButton type="text" onClick={onGenerateLabel}>
                            {t('generateLabel')}
                          </AkinonButton>
                        }
                      />
                    </Show>
                    <Show when={packageStatus === PackageStatus.FAILED}>
                      <AkinonButton type="text" onClick={onEnterInvoiceInfoForAll}>
                        {t('enter_invoice_info_all')}
                      </AkinonButton>
                      <If
                        condition={shippingMethod?.slug === ShippingMethodSlug.OTHER}
                        then={
                          <AkinonButton type="text" onClick={onMarkAsDelivered}>
                            {t('markAsDelivered')}
                          </AkinonButton>
                        }
                        otherwise={
                          <AkinonButton type="text" onClick={onGenerateLabel}>
                            {t('generateLabel')}
                          </AkinonButton>
                        }
                      />
                    </Show>
                    <Show when={packageStatus === PackageStatus.DELIVERED}>
                      <AkinonButton type="text" onClick={onEnterInvoiceInfoForAll}>
                        {t('enter_invoice_info_all')}
                      </AkinonButton>
                      <If
                        condition={shippingMethod?.slug === ShippingMethodSlug.OTHER}
                        then={
                          <AkinonButton type="text" onClick={onMarkAsFailed}>
                            {t('markAsFailed')}
                          </AkinonButton>
                        }
                        otherwise={
                          <AkinonButton type="text" onClick={onGenerateLabel}>
                            {t('generateLabel')}
                          </AkinonButton>
                        }
                      />
                    </Show>
                    <Show when={packageStatus === PackageStatus.PREPARING}>
                      <AkinonButton type="text" onClick={onChangeCargoCompany}>
                        {t('change_cargo_company')}
                      </AkinonButton>
                      <Show
                        when={shippingMethod?.slug === ShippingMethodSlug.OTHER}
                        fallback={
                          <AkinonButton type="text" onClick={onReadyToShip}>
                            {t('package_ready_to_ship')}
                          </AkinonButton>
                        }
                      >
                        <AkinonButton type="text" onClick={onShipAll}>
                          {t('ship_all')}
                        </AkinonButton>
                      </Show>
                    </Show>
                    <Show when={packageStatus === PackageStatus.READY}>
                      <AkinonButton type="text" onClick={onEnterInvoiceInfoForAll}>
                        {t('enter_invoice_info_all')}
                      </AkinonButton>
                      <Show
                        when={shippingMethod?.slug === ShippingMethodSlug.OTHER}
                        fallback={
                          <AkinonButton type="text" onClick={onGenerateLabel}>
                            {t('generateLabel')}
                          </AkinonButton>
                        }
                      >
                        <AkinonButton type="text" onClick={onShipAll}>
                          {t('ship_all')}
                        </AkinonButton>
                      </Show>
                    </Show>
                  </>
                }
                trigger="click"
              >
                <AkinonButton id="package_detail_show_actions">{t('actions')}</AkinonButton>
              </AkinonPopover>
            </Col>
          </Show>
          <Col>
            <Show when={userRole === UserRole.SELLER}>
              <AkinonButton {...wildButtonProps} onClick={onStartTour}>
                {t('start_tour')}
              </AkinonButton>
            </Show>
          </Col>
        </Row>
      ),
    });
  }, [t, packageStatus, userRole, packageDetail, shippingMethod, setPageMeta]);
}
