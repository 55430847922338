import './styles.scss';

import { MinusCircleOutlined } from '@ant-design/icons';
import AkinonButton from '@components/AkinonButton';
import DynamicFormElement from '@components/DynamicFormElement';
import { WidgetType } from '@constants/commontypes';
import { Form } from 'antd';
import capitalize from 'lodash/capitalize';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const Field = ({
  formItemProps,
  disabled,
  widget = WidgetType.INPUT,
  fieldKey,
  required = false,
}) => {
  const { t } = useTranslation();
  const { control, label, name } = formItemProps;

  const { fields, append, remove } = useFieldArray({
    control,
    name: name + '.' + fieldKey,
  });

  return (
    <>
      {fields.map(({ id }, index) => {
        return (
          <div key={id} className="form-list__wrapper">
            <div className="form-list__title-wrapper">
              <AkinonButton
                type="ghost"
                className="border-none !bg-transparent p-0 h-5 cursor-pointer"
                disabled={disabled}
                onClick={() => remove(index)}
                icon={<MinusCircleOutlined />}
              />
              <div className="form-list__title">{`${index + 1}.${t('field')}`}</div>
            </div>
            <DynamicFormElement
              key={fieldKey}
              widget={widget}
              formItemProps={{
                control,
                label: label ?? capitalize(fieldKey),
                name: `${name}.${fieldKey}[${index}].listItemValue`,
                required,
              }}
              placeholder={label ?? capitalize(fieldKey)}
              disabled={disabled}
            />
          </div>
        );
      })}
      <Form.Item>
        <AkinonButton
          type="secondary"
          icon={<i className="icon-arti" />}
          onClick={() => append({ value: '' })}
          disabled={disabled}
        >
          {t('add_field')}
        </AkinonButton>
      </Form.Item>
    </>
  );
};

export default Field;
