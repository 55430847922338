import './styles.scss';

import AkinonForm from '@components/AkinonForm';
import { QuillEditor } from '@components/QuillEditor';
import Box from '@components/utility/box';
import { verticalFormItemLayout } from '@constants/layoutTypes';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { DescriptionFormKey } from './common';

const DescriptionForm = ({ form, schema, disabled = false }) => {
  const { t } = useTranslation('ProductsAndCategories');
  const { control } = form;

  return (
    <Box
      className="box-primary form-box description-form"
      title="Description"
      subtitle={<div>{t('product.description.form.description')}</div>}
    >
      <AkinonForm
        className="language-settings__form"
        layout="vertical"
        control={control}
        schema={schema}
      >
        <QuillEditor
          isReactHookForm
          name={DescriptionFormKey.SELLER_DESCRIPTION}
          label={t('product.description')}
          wrapperCol={verticalFormItemLayout.wrapperCol}
          readOnly={disabled}
        />
      </AkinonForm>
    </Box>
  );
};

export default DescriptionForm;
