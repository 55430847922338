import { useRejectCancellationMutation } from '@services/hooks/orders/useRejectCancellationMutation';

const useBulkRejectRefundRequests = ({ onSettled }) => {
  const { mutateAsync: rejectCancellation } = useRejectCancellationMutation();
  const onRejectRefundRequests = async ({ selectedRows, mutationOptions }) => {
    for (const row of selectedRows) {
      try {
        await rejectCancellation(
          {
            requestId: row.cancellation_id,
          },
          mutationOptions
        );
      } catch (error) {
        continue;
      }

      onSettled();
    }
  };

  return { onRejectRefundRequests };
};

export default useBulkRejectRefundRequests;
