export const FileType = {
  IMAGE: 'image',
  DOCUMENT: 'document',
};

export const getFileTypeOfUrl = (url) => {
  if (url.match(/\.(jpeg|jpg|gif|svg|webp|png)$/)) {
    return FileType.IMAGE;
  } else {
    return FileType.DOCUMENT;
  }
};
