import { createSelectOptions } from '@common/index';
import { WidgetType } from '@constants/commontypes';
import { useCategoriesQuery } from '@services/api/hooks/useCategoriesQuery';
import { useTranslation } from 'react-i18next';

import { StaticFilterKey } from '../common';

export function useStaticFilters({ product }) {
  const { t } = useTranslation();
  const { categories, isCategoriesLoading } = useCategoriesQuery();

  return {
    staticFiltersFormMetaFields: [
      {
        key: StaticFilterKey.NAME,
        widget: WidgetType.INPUT,
        placeholder: t('name'),
      },
      {
        key: StaticFilterKey.BASE_CODE,
        widget: WidgetType.INPUT,
        placeholder: t('base.code'),
      },
      {
        key: StaticFilterKey.SKU,
        widget: WidgetType.INPUT,
        placeholder: t('sku'),
        initialValue: product?.sku,
      },
      {
        key: StaticFilterKey.CATEGORY,
        widget: WidgetType.SELECT,
        placeholder: t('category'),
        options: createSelectOptions(categories, {
          valueKey: 'id',
          labelKey: 'name',
        }),
      },
    ],
    isStaticFiltersLoading: isCategoriesLoading,
  };
}
