const useOnSubmit = ({ collectionId, createCollection, putCollection }) => {
  return (formValues) => {
    if (collectionId) {
      putCollection({ collectionId, requestBody: formValues });
    } else {
      createCollection({ requestBody: formValues });
    }
  };
};

export default useOnSubmit;
