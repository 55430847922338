import './style.scss';

import AkinonButton from '@components/AkinonButton';
import AkinonFormItem from '@components/AkinonFormItem';
import Box from '@components/utility/box';
import { ConfigTypeInputMap } from '@pages/ProductsAndCategories/ProductForm/common';
import { Checkbox, Divider, Form, Modal, Space, Typography } from 'antd';
import pick from 'lodash/pick';
import range from 'lodash/range';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

/**
 * @param {{ form: import('react-hook-form').UseFormReturn, }} props
 */
const BulkAttributeUpdateModal = ({
  form,
  setInvisible,
  isVisible,
  dataSource,
  variants,
  variantDynamicFields,
  variantListAttributeFields,
}) => {
  const { t } = useTranslation('ProductsAndCategories');
  const [selectedVariants, setSelectedVariants] = useState([]);
  const { reset, control, trigger } = useForm({
    mode: 'onChange',
  });

  const defaultValues = useMemo(() => {
    return {
      ...pick(
        form.getValues(),
        variantListAttributeFields?.map((field) => field.key)
      ),
    };
  }, [variantListAttributeFields]);

  const bulkAttributeValues = useWatch({
    control: control,
    defaultValues,
  });

  const { bulk, ...restFormValues } = bulkAttributeValues;

  const handleCheckboxChange = (event) => {
    setSelectedVariants((prevSelectedVariant) => {
      if (event.target.checked) {
        return [...prevSelectedVariant, event.target.value];
      }
      return prevSelectedVariant.filter((variantIndex) => variantIndex !== event.target.value);
    });
  };

  const onModalClose = useCallback(() => {
    setSelectedVariants([]);
    setInvisible();
    reset({
      bulk: {},
    });
  }, [setInvisible, setSelectedVariants, reset]);

  const handleApplyForSelectedVariants = useCallback(() => {
    if (selectedVariants.length === 0) return;
    trigger().then((isValid) => {
      if (!isValid) return;
      reset({
        bulk: {},
        ...restFormValues,
        ...Object.entries(bulk)
          .filter(([_, value]) => value !== undefined && value !== null)
          .reduce((acc, [key, value]) => {
            acc[key] = range(dataSource.length).map((index) =>
              selectedVariants.includes(index) ? value : restFormValues[key]?.[index]
            );
            return acc;
          }, {}),
      });
    });
  }, [reset, bulk, dataSource, selectedVariants, restFormValues]);

  const handleBulkAttributeUpdate = useCallback(() => {
    trigger().then((isValid) => {
      if (!isValid) return;

      form.reset({
        ...form.getValues(),
        ...restFormValues,
      });
      onModalClose();
    });
  }, [restFormValues, form]);

  const handleCleanAllContent = useCallback(() => {
    reset({
      bulk: {},
    });
  }, [reset]);

  const toggleAllVariantsCheckbox = (event) => {
    if (event.target.checked) {
      setSelectedVariants(range(dataSource.length));
    } else {
      setSelectedVariants([]);
    }
  };

  useEffect(() => {
    if (isVisible)
      reset({
        ...pick(
          form.getValues(),
          variantListAttributeFields?.map((field) => field.key)
        ),
      });
  }, [isVisible, variantListAttributeFields]);

  const bulkFormFields = useMemo(() => {
    return variantListAttributeFields?.map((field) => {
      const { data_type, label, required, initialValue, ...restProps } = field;
      const Component = ConfigTypeInputMap[data_type];

      return (
        <AkinonFormItem key={field.key} label={label} name={`bulk.${field.key}`} control={control}>
          <Component {...restProps} />
        </AkinonFormItem>
      );
    });
  }, [variantListAttributeFields]);

  const formValues = useMemo(() => {
    return dataSource?.map((item, index) => {
      return (
        <div className="form-modal-item" key={index}>
          <div className="form-modal-item__wrapper">
            <Checkbox
              value={index}
              checked={selectedVariants.includes(index)}
              className="form-modal-item__checkbox"
              onChange={handleCheckboxChange}
            />
            <Text className={'form-modal-item__variant'}>
              {Object.keys(variants)
                .map((variantKey) => {
                  const variantValue = item[variantKey];
                  const variantLabel = variantDynamicFields
                    .find((field) => field.key === variantKey)
                    .options.find((option) => option.value === variantValue)?.label;
                  return variantLabel ?? variantValue;
                })
                .join('/')}
            </Text>
            <div className="form-modal__root-form-builder">
              {variantListAttributeFields?.map((field) => {
                const { data_type, label, required, initialValue, ...restProps } = field;
                const Component = ConfigTypeInputMap[data_type];

                return (
                  <AkinonFormItem
                    key={field.key}
                    label={label}
                    name={`${field.key}.${index}`}
                    control={control}
                  >
                    <Component {...restProps} />
                  </AkinonFormItem>
                );
              })}
            </div>
          </div>
          <Divider className="form-modal-item__divider" />
        </div>
      );
    });
  }, [variantListAttributeFields, dataSource, selectedVariants, handleCheckboxChange]);

  return (
    <Modal
      className="form-modal"
      onCancel={onModalClose}
      open={isVisible}
      width={'75%'}
      destroyOnClose
      footer={null}
    >
      <Box className="box-primary form-box">
        <Form className="akn-form" layout="vertical">
          <div className="form-modal__header">
            <div className="form-modal__header-banner" />
            <div className="form-modal__header-title">{t('bulk.update.product.attributes')}</div>
          </div>
          <Divider className="form-modal__divider" />
          <div className="form-modal__description">
            {t('bulk.update.product.attributes.description')}
          </div>
          <Divider orientation="left" className="form-modal__divider">
            {t('edit.product.attributes').toUpperCase()}
          </Divider>
          <div className="form-modal__root-form-builder">{bulkFormFields}</div>
          <Space direction="horizontal" className="form-modal__form-actions">
            <AkinonButton onClick={handleApplyForSelectedVariants} htmlType="button" type="primary">
              {t('apply_to_selected_variants').toUpperCase()}
            </AkinonButton>
          </Space>
          <Divider orientation="left" />
          <Checkbox
            checked={selectedVariants.length === dataSource.length}
            className="form-modal-item__checkbox"
            onChange={toggleAllVariantsCheckbox}
          >
            {t('select_all_variants')}
          </Checkbox>

          {formValues}
          <Space>
            <AkinonButton type="primary" htmlType="button" onClick={handleBulkAttributeUpdate}>
              {t('save').toUpperCase()}
            </AkinonButton>
            <AkinonButton onClick={handleCleanAllContent} type="danger" htmlType="button">
              {t('clean.all.content').toUpperCase()}
            </AkinonButton>
          </Space>
        </Form>
      </Box>
    </Modal>
  );
};

export default BulkAttributeUpdateModal;
