import './style.scss';

import {
  DragOutlined,
  InfoCircleOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import AkinonDividerBox from '@components/AkinonDividerBox';
import AkinonFlex from '@components/AkinonFlex';
import AkinonFormItem from '@components/AkinonFormItem';
import DynamicFormElement from '@components/DynamicFormElement';
import If from '@components/If';
import { WidgetType } from '@constants/commontypes';
import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Collapse, Space, Tooltip, Typography } from 'antd';
import { memo } from 'react';
import { useFieldArray } from 'react-hook-form';

import { SchemaDataType } from '../common';
import CollapseExpandIcon from '../components/CollapseExpandIcon';
import CustomDragger from './CustomDragger';

const MultiNestedWidget = memo(({ control, schema, name, parentSchemaType, label, t }) => {
  const { fields, append, remove, swap } = useFieldArray({
    control,
    name,
  });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id)
      swap(active.data.current.sortable.index, over?.data.current.sortable.index);
  };

  return (
    <AkinonDividerBox
      header={
        <AkinonFlex justifyContent="center" alignItems="center" className="gap-1">
          <span>{label}</span>
          <Tooltip title="This is a multi widget you can create a list of schemas here.">
            <InfoCircleOutlined />
          </Tooltip>
        </AkinonFlex>
      }
    >
      <If
        condition={fields.length == 0}
        then={
          <Space className="multi-nested-widget__empty">
            <PlusCircleOutlined
              onClick={() =>
                append(
                  parentSchemaType === 'MultiWidget'
                    ? Object.values(schema ?? {}).map((value) =>
                        value.type === 'file' || value.type === 'image' ? [] : undefined
                      )
                    : Object.entries(schema ?? {}).reduce(
                        (acc, [key, value]) => ({
                          ...acc,
                          [key]: value.type === 'file' || value.type === 'image' ? [] : undefined,
                        }),
                        {}
                      )
                )
              }
              className="multi-nested-widget__widget-button multi-nested-widget__widget-button--add"
            />
            <Typography.Text className="multi-nested-widget__empty-text">
              {t('subcontent_add')}
            </Typography.Text>
          </Space>
        }
        otherwise={
          <DndContext onDragEnd={onDragEnd} sensors={sensors}>
            <SortableContext items={fields?.map?.((field) => field.id) ?? []}>
              {fields.map((field, index) => {
                return (
                  <SortableField
                    field={field}
                    key={field.id}
                    index={index}
                    append={append}
                    remove={remove}
                    schema={schema}
                    control={control}
                    t={t}
                    name={name}
                    parentSchemaType={parentSchemaType}
                  />
                );
              })}
            </SortableContext>
          </DndContext>
        }
      />
    </AkinonDividerBox>
  );
});

const SortableField = ({
  field,
  index,
  name,
  append,
  remove,
  t,
  parentSchemaType,
  schema,
  control,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: field.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <article {...attributes} ref={setNodeRef} style={style}>
      <Collapse
        className="multi-nested-widget"
        expandIcon={({ isActive }) => <CollapseExpandIcon isExpanded={isActive} />}
        bordered={false}
      >
        <Collapse.Panel
          header={
            <div className="multi-widget__panel-header">
              <DragOutlined
                onClick={(event) => {
                  event.stopPropagation();
                }}
                onPointerDown={listeners.onPointerDown}
                className="absolute cursor-none -left-2"
              />
              <span>{t('subcontent', { index: index + 1 })}</span>
              <PlusCircleOutlined
                onClick={(event) => {
                  event.stopPropagation();
                  append(
                    parentSchemaType === 'MultiWidget'
                      ? Object.values(schema ?? {}).map((value) =>
                          value.type === 'file' || value.type === 'image' ? [] : undefined
                        )
                      : Object.entries(schema ?? {}).reduce(
                          (acc, [key, value]) => ({
                            ...acc,
                            [key]: value.type === 'file' || value.type === 'image' ? [] : undefined,
                          }),
                          {}
                        )
                  );
                }}
                className="multi-widget__widget-button multi-widget__widget-button--add"
              />
              <MinusCircleOutlined
                onClick={(event) => {
                  event.stopPropagation();
                  remove(index);
                }}
                className="multi-widget__widget-button multi-widget__widget-button--remove"
              />
            </div>
          }
        >
          {...Object.entries(schema ?? {}).map(([key, { label, type, multi }]) => {
            let widgetType = WidgetType.INPUT;

            if (type === SchemaDataType.FILE || type === SchemaDataType.IMAGE) {
              widgetType = WidgetType.CUSTOM;
            }
            if (type === SchemaDataType.HTML_EDITOR) {
              widgetType = WidgetType.EDITOR;
            }

            const _name = `${name}.${index}${multi ? '' : `.${key}`}`;

            return (
              <AkinonFormItem control={control} name={_name} label={label} key={_name}>
                <DynamicFormElement
                  widget={widgetType}
                  Component={(props) => <CustomDragger {...props} maxCount={1} />}
                />
              </AkinonFormItem>
            );
          })}
        </Collapse.Panel>
      </Collapse>
    </article>
  );
};

export default MultiNestedWidget;
