import { wildButtonProps } from '@components/WildButton';
import { useTour } from '@reactour/tour';
import useStore from '@zustand-store/index';
import { setPageMetaSelector } from '@zustand-store/selectors/app';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

export function usePageMeta() {
  const { t } = useTranslation('Messages');
  const setPageMeta = useStore(setPageMetaSelector);
  const { setIsOpen, setCurrentStep } = useTour();

  useEffect(() => {
    setPageMeta({
      title: t('product.messages'),
      description: t('product.messages.desc'),
      actions: [
        {
          ...wildButtonProps,
          label: t('start_tour'),
          onClick() {
            setCurrentStep(0);
            setIsOpen(true);
          },
        },
      ],
    });
  }, [t]);

  useUnmount(() => {
    setPageMeta({});
  });
}
