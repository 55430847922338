import './style.scss';

import classnames from 'classnames';
import React from 'react';

const InputWrapper = (Component) => (props) => {
  const className = classnames(props.className, 'inputWrapper');
  return <Component {...props} className={className} />;
};

const InputGroupWrapper = (Component) => (props) => {
  const className = classnames(props.className, 'inputGroupWrapper');
  return <Component {...props} className={className} />;
};

const TextAreaWrapper = (Component) => (props) => {
  const className = classnames(props.className, 'textAreaWrapper');
  return <Component {...props} className={className} />;
};

export { InputGroupWrapper, InputWrapper, TextAreaWrapper };
