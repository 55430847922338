import { submitPreOfferUrl } from '@constants/apiUrls';

import { useAkinonMutation } from '../adapters/useAkinonMutation';
import { client } from '../client';

export function useSubmitPreOfferMutation() {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn({ preOfferId }) {
      return client.post(submitPreOfferUrl, null, { urlParams: { preOfferId } });
    },
  });

  return {
    submitPreOffer: mutate,
    isSubmittingPreOffer: isLoading,
  };
}
