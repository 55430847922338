import { WidgetType } from '@constants/commontypes';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import * as z from 'zod';

export const DataType = {
  TEXT: 'text',
  AREA: 'area',
  DROPDOWN: 'dropdown',
  BOOL: 'bool',
};

export const BasicProductInfoFormItemKey = {
  NAME: 'basicProductInfo.name',
  BASE_CODE: 'basicProductInfo.base_code',
  CATEGORY: 'basicProductInfo.category',
};

export const DataTypeWidgetMap = {
  [DataType.TEXT]: WidgetType.INPUT,
  [DataType.AREA]: WidgetType.TEXTAREA,
  [DataType.DROPDOWN]: WidgetType.SELECT,
  [DataType.BOOL]: WidgetType.SWITCH,
};

export const ProductToBeAddedFormKey = {
  PRODUCT_IMAGE: 'productToBeAdded.product_image',
  SKU: 'productToBeAdded.sku',
  STOCK: 'productToBeAdded.stock',
  PRICE: 'productToBeAdded.price',
  RETAIL_PRICE: 'productToBeAdded.retail_price',
  REFERENCE_PRICE: 'productToBeAdded.buybox_price',
  LOWEST_PRICE: 'productToBeAdded.lowest_price',
  TAX_RATE: 'productToBeAdded.tax_rate',
  CURRENCY_TYPE: 'productToBeAdded.currency_type',
};

const getIntegerSchema = ({ t }) =>
  z.coerce
    .string()
    .superRefine((value, ctx) => {
      if (trim(value) !== value) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('white_space_validation_message'),
        });
        return z.NEVER;
      } else if (isEmpty(value)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('formRule.required'),
        });
        return z.NEVER;
      }
    })
    .pipe(
      z.coerce
        .number({
          invalid_type_error: t('number.validation.message'),
        })
        .int({ message: t('integer.validation.message') })
        .nonnegative({ message: t('positive_validation_message') })
    );

const getFloatSchema = ({ t }) =>
  z
    .string()
    .superRefine((value, ctx) => {
      if (trim(value) !== value) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('white_space_validation_message'),
        });
      } else if (isEmpty(value)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: t('formRule.required'),
        });
      }
    })
    .pipe(
      z.coerce
        .number({
          invalid_type_error: t('integer.validation.message'),
        })
        .nonnegative({ message: t('positive_validation_message') })
    );

export const getSchema = ({ t, dynamicFields }) => {
  const integerSchema = getIntegerSchema({ t });
  const floatSchema = getFloatSchema({ t });
  return z.object({
    productToBeAdded: z.object({
      stock: integerSchema,
      price: floatSchema,
      retail_price: floatSchema,
      buybox_price: floatSchema.optional(),
      lowest_price: floatSchema.optional(),
      tax_rate: floatSchema,

      attributes: z.object({
        ...dynamicFields.reduce((acc, field) => {
          let schema;

          if (field.data_type === DataType.BOOL) {
            schema = field.required && field.is_offer ? z.boolean() : z.boolean().optional();
          } else {
            schema = field.required && field.is_offer ? z.string().min(1) : z.string().optional();
          }

          return {
            ...acc,
            [field.key]: schema,
          };
        }, {}),
      }),
    }),
  });
};
