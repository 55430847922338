import { approvePreOffer } from '@services/api';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';

export function useApprovePreOfferMutation() {
  const mutationResponse = useAkinonMutation({
    mutationFn: approvePreOffer,
  });

  return {
    ...mutationResponse,
    approvePreOffer: mutationResponse.mutate,
    isApprovePreOfferMutating: mutationResponse.isLoading,
  };
}
