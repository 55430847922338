import { getDataSourceDetailUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { client } from '@services/api/client';

export function useDataSourceDetailQuery({ dataSourceId, queryOptions, onSuccess }) {
  const { data, isLoading, error, isFetching, refetch } = useAkinonQuery({
    queryKey: [QueryKey.DATA_SOURCE_DETAIL, dataSourceId],
    async queryFn() {
      const response = await client.get(getDataSourceDetailUrl, { urlParams: { dataSourceId } });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    dataSourceDetail: data,
    isDataSourceDetailLoading: isLoading,
    isFetchingDataSourceDetail: isFetching,
    dataSourceDetailError: error,
    refetchDataSourceDetail: refetch,
  };
}
