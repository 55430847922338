import { getBulkTemplatePreOffer } from '@services/api';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';

export function useDownloadBulkTemplatePreOffer() {
  const mutationResponse = useAkinonMutation({
    mutationFn: getBulkTemplatePreOffer,
  });

  return {
    ...mutationResponse,
    mutate: mutationResponse.mutate,
    isMutating: mutationResponse.isLoading,
  };
}
