import { approveCancellationPlansUrl } from '@constants/apiUrls';

import { useAkinonMutation } from '../adapters/useAkinonMutation';
import { client } from '../client';

export function useApproveCancellationPlansMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ cancellationPlanId, requestBody }) =>
      client.post(approveCancellationPlansUrl, requestBody, {
        urlParams: {
          cancellationPlanId,
        },
      }),
    ...mutationOptions,
  });

  return {
    approveCancellationPlans: mutate,
    isApprovingCancellationPlans: isLoading,
  };
}
