import AkinonFilter from '@components/AkinonFilter';
import { usePageFilters } from '@components/AkinonFilter/hooks/store/usePageFilters';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { getReconciliationsURL } from '@constants/apiUrls';
import { UserRole } from '@constants/auth';
import { defaultRowKey } from '@constants/index';
import { useDataSourcesQuery } from '@services/api/hooks/usePaginatedDataSourcesQuery';
import { useReconciliations } from '@services/hooks/finance/useReconciliations';
import { useUserRole } from '@utils/hooks/useUserRole';
import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';

import { getColumns } from './common';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { usePageMeta } from './hooks/usePageMeta';
import { useStaticFilters } from './hooks/useStaticFilters';

const ReconciliationListing = () => {
  const { t } = useTranslation('Finance');
  const filters = usePageFilters();
  const [pagination, setPagination] = usePagination();
  const { reconciliations, isLoading, total } = useReconciliations({ pagination, filters });
  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;

  const { dataSources, isFetchingDataSources } = useDataSourcesQuery({
    queryOptions: {
      enabled: isSuperUser,
    },
  });
  const staticFilters = useStaticFilters({
    dataSources,
    isFetchingDataSources,
    userRole,
  });

  useBreadcrumbs();
  usePageMeta();

  const columns = getColumns({ t, isSuperUser });

  return (
    <section>
      <AkinonFilter title={t('filters')} {...staticFilters} total={total} />
      <Divider />
      <AkinonTable
        title={t('reconciliations')}
        columns={columns}
        dataSource={reconciliations}
        optionsUrl={getReconciliationsURL}
        loading={isLoading}
        total={total}
        pagination={pagination}
        rowKey={defaultRowKey}
        setPagination={setPagination}
        description={`${total} ${t('results.found')}`}
      />
    </section>
  );
};

export default ReconciliationListing;
