import './style.scss';

import Icon from '@components/Intrinsic';
import Show from '@components/Show';
import { copyTextToClipboard } from '@utils/index';
import { Button, Tooltip } from 'antd';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const CopyButton = ({ text, label, isColumn, className }) => {
  const { t } = useTranslation();
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const onButtonClick = (event) => {
    event.stopPropagation();
    copyTextToClipboard(text);
    setTooltipVisible(t('copy.text.done'));

    setTimeout(() => {
      setTooltipVisible(t('copy.text'));
    }, 1500);
  };
  const tooltipText = tooltipVisible || t('copy.text');
  return (
    <div className="copy-button">
      <Show when={!!label}>
        <span className="copy-button__label">{label}</span>
      </Show>
      <Tooltip title={tooltipText}>
        <Button
          className={classNames(className, {
            'copy-button__button--is-column': isColumn,
          })}
          type="link"
          onClick={onButtonClick}
        >
          <Icon
            className={classNames({
              'icon-copy': true,
              'copy-button__icon--is-column': isColumn,
            })}
          />
        </Button>
      </Tooltip>
    </div>
  );
};

export default CopyButton;
