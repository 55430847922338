import { postTokenObtainUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { client } from '@services/api/client';

/**
 * @param {import('@tanstack/react-query').UseMutationOptions} mutationOptions
 */
export function useTokenObtainMutation(mutationOptions) {
  const mutationResponse = useAkinonMutation({
    mutationFn({ requestBody, requestConfig }) {
      return client.post(postTokenObtainUrl, requestBody, requestConfig);
    },
    ...mutationOptions,
  });

  return {
    ...mutationResponse,
    postTokenObtain: mutationResponse.mutate,
    accessToken: mutationResponse.access,
    refreshToken: mutationResponse.refresh,
    isPostTokenObtainMutating: mutationResponse.isLoading,
    isPostTokenObtainError: mutationResponse.isError,
    resetPostTokenObtain: mutationResponse.reset,
  };
}
