import './style.scss';

import { CloseOutlined } from '@ant-design/icons';
import AkinonBox from '@components/AkinonBox';
import AkinonFormBuilder from '@components/AkinonFormBuilder';
import Drawer from '@components/Drawer';
import { Col, Form, Row, Spin, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import FiltersDrawerFooter from './FiltersDrawerFooter';

const { Title, Text } = Typography;

const FiltersDrawer = ({
  isFiltersModalVisible,
  setIsFiltersModalVisible,
  isFiltersLoading,
  filterForm,
  currentFiltersFormMeta,
  applyFilters,
  clearFilters,
  total,
}) => {
  const mobileCurrentFiltersFormMeta = currentFiltersFormMeta?.fields
    ? {
        ...currentFiltersFormMeta,
        fields: currentFiltersFormMeta?.fields.map?.((field) => ({
          ...field,
          widgetProps: { ...field.widgetProps, allowClear: true },
        })),
      }
    : currentFiltersFormMeta;

  const { t } = useTranslation();

  const onClose = () => {
    setIsFiltersModalVisible(false);
  };

  const onApplyFilters = () => {
    applyFilters();
    onClose();
  };

  const onClearFilters = () => {
    clearFilters();
  };

  return (
    <Drawer
      open={isFiltersModalVisible}
      closable={false}
      width={'100%'}
      className="filters-drawer"
      footer={
        <FiltersDrawerFooter onApplyFilters={onApplyFilters} onClearFilters={onClearFilters} />
      }
    >
      <Row justify="space-between" className="filter-drawer-header">
        <Col>
          <Title className="filters-drawer__title">
            {t('filters')} ({mobileCurrentFiltersFormMeta?.fields?.length ?? 0})
          </Title>
        </Col>
        <CloseOutlined className="filters-drawer__close-icon" onClick={onClose} />
      </Row>
      <AkinonBox>
        <Spin spinning={isFiltersLoading}>
          <div className="filters-drawer__info">
            <Text>{`${total ?? '0'} ${t('results.found')}`}</Text>
          </div>

          <Form className="akinon-form-dark akinon-filter__form" form={filterForm}>
            <AkinonFormBuilder form={filterForm} meta={mobileCurrentFiltersFormMeta} />
          </Form>
        </Spin>
      </AkinonBox>
    </Drawer>
  );
};

export default FiltersDrawer;
