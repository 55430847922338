import { downloadBulkAttributeXLSXTemplateURL } from '@constants/apiUrls';
import { client } from '@services/api/client';

/**
 *
 * @param {Object} args
 * @param {string} args.attribute
 * @returns
 */

export const getBulkTemplateAttributes = ({ attribute }) => {
  return client.get(downloadBulkAttributeXLSXTemplateURL, { params: { attribute } });
};
