import { getCategoryNodesUrl } from '@constants/apiUrls';
import { limitQuery } from '@constants/commontypes';
import { QueryKey } from '@constants/query';

import { useAkinonQuery } from '../adapters/useAkinonQuery';
import { client } from '../client';

export function useCategoriesQuery({ queryOptions, params, onSuccess } = {}) {
  const { data, isLoading, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.CATEGORIES, params],
    async queryFn() {
      const response = await client.get(getCategoryNodesUrl, {
        params: {
          ...limitQuery,
          ...params,
        },
      });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    categories: data?.results,
    isCategoriesLoading: isLoading,
    isFetchingCategories: isFetching,
  };
}
