import { AppRenderer, useAppShell } from '@akinon/app-shell';
import { AppShellAppType, AppTypeToAppShellType } from '@common/index';
import { RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@hooks/useAppNavigate';
import { useParams } from 'react-router-dom';

export default function AppShellFullPageAppGuard() {
  const { apps } = useAppShell();
  const navigate = useAppNavigate();
  const fullPageApps = apps.filter(
    (app) => app.type === AppTypeToAppShellType[AppShellAppType.FULL_PAGE]
  );
  const { appSlug, appPath } = useParams();

  const currentApp = fullPageApps.find((app) => app.slug === appSlug);

  if (!currentApp) {
    navigate(RouteUrls.errors.notFound);
    return;
  }

  return <AppRenderer id={currentApp.id} path={appPath ? '/' + appPath : '/'} />;
}
