import { QueryKey } from '@constants/query';
import { getConversation } from '@services/api/orders/message';
import { useQuery } from '@tanstack/react-query';

export function useConversationQuery({ conversationId, queryOption = {} }) {
  const queryKey = [QueryKey.CONVERSATIONS, conversationId];

  const queryFn = () => getConversation({ conversationId });

  const { data, isFetching, isLoading, error } = useQuery({
    queryKey,
    queryFn,
    ...queryOption,
  });

  return {
    conversation: data,
    isConversationLoading: isLoading,
    isConversationFetching: isFetching,
    conversationError: error,
  };
}
