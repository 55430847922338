import { putResetPassword } from '@services/api';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';

export function usePutResetPasswordMutation({ mutationOptions } = {}) {
  const { mutate, isLoading, error } = useAkinonMutation({
    mutationFn: putResetPassword,
    ...mutationOptions,
  });

  return {
    resetPassword: mutate,
    isResettingPassword: isLoading,
    resetPasswordError: error,
  };
}
