export function buildTransformString(animate) {
  let translateString = '';
  const { x, y, rotate, scale } = animate;

  x && (translateString += `translateX(${x}) `);
  y && (translateString += `translateY(${y}) `);
  rotate && (translateString += `rotate(${rotate}) `);
  scale && (translateString += `scale(${scale}) `);

  return translateString;
}

export const Animations = {
  fade: {
    initial: { y: '-5px', otherStyles: { opacity: 0.5, maxHeight: 0 } },
    animate: { y: '0px', otherStyles: { opacity: 1, maxHeight: '100vh' } },
    transition: 'all 0.3s ease-in, max-height 0.3s ease-in',
  },
};
