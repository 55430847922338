import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { getQuickReply } from '@services/api/orders/message';

export const useQuickReply = ({ quickReplyId, queryOptions }) => {
  const queryKey = [QueryKey.QUICK_REPLY, quickReplyId];

  const queryFn = () => getQuickReply({ quickReplyId });
  const { data, isFetching, isLoading, error, ...rest } = useAkinonQuery({
    queryKey,
    queryFn,
    ...queryOptions,
  });

  return {
    ...rest,
    quickReply: data,
    isLoading,
    isFetching,
    error,
  };
};
