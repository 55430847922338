import Link from '@components/Link';
import { RouteUrls } from '@constants/routeUrls';
import useStore from '@zustand-store/index';
import { setBreadcrumbsSelector } from '@zustand-store/selectors/app';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

export function useBreadcrumbs() {
  const { t } = useTranslation('Messages');
  const setBreadcrumbs = useStore(setBreadcrumbsSelector);

  useEffect(() => {
    setBreadcrumbs([
      <Link key={RouteUrls.messages.landing} to={RouteUrls.messages.landing}>
        {t('messages')}
      </Link>,
      <Link key={RouteUrls.messages.quickReplies} to={RouteUrls.messages.quickReplies}>
        {t('quick.replies')}
      </Link>,
      t('quick.reply'),
    ]);
  }, [t]);

  useUnmount(() => {
    setBreadcrumbs([]);
  });
}
