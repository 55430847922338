import { patchPreOfferImageUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useQueryClient } from '@tanstack/react-query';
import omit from 'lodash/omit';

import { useAkinonMutation } from '../adapters/useAkinonMutation';
import { client } from '../client';

export function usePatchPreOfferImageMutation({ mutationOptions } = {}) {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody, imageId }) =>
      client.patch(patchPreOfferImageUrl, requestBody, {
        urlParams: { imageId },
      }),
    onSuccess() {
      mutationOptions?.onSuccess?.();
      queryClient.invalidateQueries([QueryKey.PRE_OFFER_IMAGES]);
    },
    ...omit(mutationOptions, ['onSuccess']),
  });

  return {
    patchPreOfferImage: mutate,
    isPatchingPreOfferImage: isLoading,
  };
}
