const getQueryParamsBuilder = (queryMap) => {
  return {
    getByFields(fields) {
      queryMap.set('fields', fields.join(','));
      return this;
    },
    includesValues(key, values) {
      queryMap.set(key.concat('__in'), values.join(','));
      return this;
    },
    set(key, value) {
      queryMap.set(key, value);
      return this;
    },
    load(newQuery) {
      Object.entries(newQuery).forEach(([queryKey, queryValue]) => {
        queryMap.set(queryKey, queryValue);
      });
    },
    build() {
      return Object.fromEntries(queryMap.entries());
    },
    getQueryMap() {
      return new Map(queryMap);
    },
  };
};

export const QueryParamsBuilder = (() => {
  const createQueryParamsBuilder = () => {
    let queryMap = new Map();

    return getQueryParamsBuilder(queryMap);
  };
  return {
    new() {
      return createQueryParamsBuilder();
    },
    from(anotherQueryParamsBuilder) {
      const queryParamsBuilder = createQueryParamsBuilder();
      queryParamsBuilder.load(anotherQueryParamsBuilder.build());
      return queryParamsBuilder;
    },
  };
})();
