import i18n from '@root/i18n';

/**
 * @deprecated
 * Please use `useTranslation` in components.
 * Since we use backend services for translation files, we should not use this function.
 * @type {import('i18next').TFunction}
 */
export const T = (key) => {
  return i18n.t(key);
};
