import { RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@root/hooks/useAppNavigate';
import useStore from '@zustand-store/index';
import { setPageMetaSelector } from '@zustand-store/selectors/app';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

export function usePageMeta() {
  const { t } = useTranslation('Settings');
  const setPageMeta = useStore(setPageMetaSelector);
  const navigate = useAppNavigate();

  useEffect(() => {
    setPageMeta({
      title: t('sso_settings.title'),
      description: t('sso_settings.description'),
      actions: [
        {
          label: t('sso_settings.add_new'),
          icon: <i className="icon-arti" />,
          onClick() {
            navigate(RouteUrls.settings.ssoLoginSettingsFormUrl, {
              providerSlug: undefined,
            });
          },
        },
      ],
    });
  }, [t]);

  useUnmount(() => {
    setPageMeta({});
  });
}
