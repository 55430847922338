export const variantsFormDynamicMetaFieldsSelector = (state) => state.variantsFormDynamicMetaFields;
export const variantsListAttributeMetaFieldsSelector = (state) =>
  state.variantsListAttributeMetaFields;
export const isVariantsFormDynamicMetaFieldsLoadingSelector = (state) =>
  state.isVariantsFormDynamicMetaFieldsLoading;
export const setIsVariantsFormDynamicMetaFieldsLoadingSelector = (state) =>
  state.setIsVariantsFormDynamicMetaFieldsLoading;
export const isVariantsListAttributeMetaFieldsLoadingSelector = (state) =>
  state.isVariantsListAttributeMetaFieldsLoading;
export const setIsVariantsListAttributeMetaFieldsLoadingSelector = (state) =>
  state.setIsVariantsListAttributeMetaFieldsLoading;
export const setVariantsFormDynamicMetaFieldsSelector = (state) =>
  state.setVariantsFormDynamicMetaFields;
export const setVariantsListAttributeMetaFieldsSelector = (state) =>
  state.setVariantsListAttributeMetaFields;
export const productsToBeAddedTableFormDataSourceSelector = (state) =>
  state.productsToBeAddedTableFormDataSource;
export const setProductsToBeAddedTableFormDataSourceSelector = (state) =>
  state.setProductsToBeAddedTableFormDataSource;
export const selectedRelatedProductSelector = (state) => state.selectedRelatedProduct;
export const setSelectedRelatedProductSelector = (state) => state.setSelectedRelatedProduct;
export const resetPreOfferSelector = (state) => state.resetPreOffer;
