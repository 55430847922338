import '../style.scss';

import { QueryParamsBuilder } from '@common/query-params-builder';
import AkinonFilter from '@components/AkinonFilter';
import { usePageFilters } from '@components/AkinonFilter/hooks/store/usePageFilters';
import { openNotification } from '@components/akinonNotification';
import AkinonTable from '@components/AkinonTable';
import { ExportFormat } from '@components/AkinonTable/components/StandardAkinonTableHeader/ExportModal';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { Animations } from '@components/Animate/common';
import Show from '@components/Show';
import { exportOffersUrl, getProductOffersUrl } from '@constants/apiUrls';
import { UserRole } from '@constants/auth';
import { FileExtension } from '@constants/commontypes';
import { defaultRowKey } from '@constants/index';
import { QueryKey } from '@constants/query';
import { RouteUrls } from '@constants/routeUrls';
import { useIsMobile } from '@hooks/useIsMobile';
import useModalAction from '@hooks/useModalAction';
import BulkUpdateOfferStatus from '@pages/ProductsAndCategories/BulkActions/components/BulkUpdateOfferStatus';
import { getDynamicColumns, transformDynamicFilters } from '@pages/ProductsAndCategories/common';
import { useUser } from '@root/contexts/hooks/useUser';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { client } from '@services/api/client';
import { useExportOffersMutation } from '@services/api/hooks/useExportOffersMutation';
import { queryClient } from '@services/api/queryClient';
import { useAllDataSources } from '@services/hooks/datasources/useAllDataSources';
import { useIsFetching } from '@tanstack/react-query';
import { useResetTableCurrentPageWhenFiltersChanged } from '@utils/hooks';
import { useCategoryTreeData } from '@utils/hooks/useCategoryTreeData';
import { useDynamicFilters } from '@utils/hooks/useDynamicFilters';
import { useUserRole } from '@utils/hooks/useUserRole';
import { Modal } from 'antd';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBoolean } from 'react-use';

import { usePostBulkStatusUpdateMutation } from '../hooks/api/usePostBulkStatusUpdateMutation';
import { useApprovedOffersBreadcrumbs } from '../hooks/useApprovedOffersBreadcrumbs';
import { useApprovedOffersPageMeta } from '../hooks/useApprovedOffersPageMeta';
import { getColumns, getStaticFilters, getTableActions, OfferStatus } from './common';
import { useDataSource } from './hooks/useDataSource';

/**
 * TODO: We should create folders for each of these offer list components since each of them has different logic and different filters.
 */
export default function OfferListApproved() {
  const { t } = useTranslation('ProductsAndCategories');
  const isMobile = useIsMobile();
  const filters = usePageFilters();
  const navigate = useAppNavigate();
  const [pagination, setPagination] = usePagination();
  const user = useUser();
  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;
  const { data: dataSources, isLoading: isDataSourcesLoading } = useAllDataSources({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isBulkUpdateOfferStatusActive, toggleIsBulkUpdateOfferStatusActive] = useBoolean(false);

  const { postBulkStatusUpdate } = usePostBulkStatusUpdateMutation({
    mutationOptions: {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKey.PRODUCT_OFFERS]);
      },
    },
  });
  const { categoryTreeData, isCategoriesLoading } = useCategoryTreeData({
    generateTreeDataOptions: {
      valueKey: 'path',
    },
  });
  const hasAppliedFilters = Object.values(filters).filter((val) => !isUndefined(val)).length > 0;

  const onExportSuccess = () => {
    openNotification({
      message: t('file.preparing'),
      description: t('file.download.modal.description'),
      type: 'success',
    });
  };

  const { exportOffers, isExportingOffers } = useExportOffersMutation({
    mutationOptions: {
      onSuccess: onExportSuccess,
    },
  });

  useApprovedOffersBreadcrumbs();
  useApprovedOffersPageMeta({ toggleIsBulkUpdateOfferStatusActive });

  const dynamicFilters = transformDynamicFilters(useDynamicFilters());
  const staticFilters = getStaticFilters({
    t,
    dataSources,
    categoryTreeData,
    isDataSourcesLoading,
    isCategoriesLoading,
    isSuperUser,
  });

  useResetTableCurrentPageWhenFiltersChanged({ pagination, setPagination, filters });

  const { dynamicFiltersFormMetaFields } = dynamicFilters;

  const { dynamicFormMetaFields } = getDynamicColumns({
    filters,
    dynamicFiltersFormMetaFields,
  });

  const { data, isLoading, total } = useDataSource({
    pagination,
    filters,
    dynamicFormMetaFields,
  });
  const columns = getColumns({ t, isMobile });

  const isExportOffersFetching = useIsFetching({ queryKey: [QueryKey.EXPORT_PRODUCT_OFFERS] });

  const onXlsxExport = async ({ fieldsToExport }) => {
    if (filters.filter_file) {
      exportOffers({ requestBody: { ...filters, extension: FileExtension.XLSX } });
    } else {
      const params = {
        ...filters,
        ...(fieldsToExport &&
          QueryParamsBuilder.new()
            .set('extension', FileExtension.XLSX)
            .getByFields(fieldsToExport)
            .build()),
      };

      await queryClient.fetchQuery({
        queryKey: [QueryKey.EXPORT_PRODUCT_OFFERS, params],
        queryFn: async () => {
          await client.get(exportOffersUrl, {
            params,
          });
          onExportSuccess();
        },
      });
    }
  };

  const onExport = async ({ fieldsToExport, exportFormat }) => {
    if (filters.filter_file) {
      exportOffers({ requestBody: { ...filters, extension: FileExtension.XLSX } });
    } else if (exportFormat === ExportFormat.XLSX) {
      const params = {
        ...filters,
        ...(fieldsToExport &&
          QueryParamsBuilder.new()
            .set('extension', FileExtension.XLSX)
            .getByFields(fieldsToExport)
            .build()),
      };

      await queryClient.fetchQuery({
        queryKey: [QueryKey.EXPORT_PRODUCT_OFFERS, params],
        queryFn: async () => {
          await client.get(exportOffersUrl, {
            params,
          });
          onExportSuccess();
        },
      });
    }
  };

  const { onShowModal: onShowMakeOfferActiveModal, ...bulkMakeOfferActiveProps } = useModalAction({
    mutateFn: ({ selectedRows }) =>
      postBulkStatusUpdate({
        datasource: user.datasource,
        requestBody: {
          status: OfferStatus.ACTIVE,
        },
        filters: {
          id__in: selectedRows.map((row) => row.id).join(','),
        },
      }),
    enforceRowSelection: true,
  });
  const { onShowModal: onShowMakeOfferPassiveModal, ...bulkMakeOfferPassiveProps } = useModalAction(
    {
      mutateFn: ({ selectedRows }) =>
        postBulkStatusUpdate({
          datasource: user.datasource,
          requestBody: {
            status: OfferStatus.PASSIVE,
          },
          filters: {
            id__in: selectedRows.map((row) => row.id).join(','),
          },
        }),
      enforceRowSelection: true,
    }
  );
  const {
    onShowModal: onShowMakeFilteredOffersActiveModal,
    ...makeFilteredOffersActiveModalProps
  } = useModalAction({
    mutateFn: ({ selectedRows }) => {
      postBulkStatusUpdate({
        datasource: user.datasource,
        requestBody: {
          status: OfferStatus.ACTIVE,
        },
        filters: {
          ...omitBy(filters, isUndefined),
          ...(selectedRows?.length > 1 && { id__in: selectedRows.map((row) => row.id) }),
        },
      });
    },
  });

  const { onShowModal: onShowMakeFilteredOffersPassiveModal, ...makeFilteredOffersPassiveProps } =
    useModalAction({
      mutateFn: ({ selectedRows }) => {
        postBulkStatusUpdate({
          datasource: user.datasource,
          requestBody: {
            status: OfferStatus.PASSIVE,
          },
          filters: {
            ...omitBy(filters, isUndefined),
            ...(selectedRows?.length > 1 && {
              id__in: selectedRows.map((row) => row.id).join(','),
            }),
          },
        });
      },
    });

  const tableActions = getTableActions({
    t,
    onShowMakeOfferActiveModal,
    onShowMakeOfferPassiveModal,
    onShowMakeFilteredOffersActiveModal,
    onShowMakeFilteredOffersPassiveModal,
    hasAppliedFilters,
    selectedRowKeys,
  });

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const onRow = (record) => ({
    onClick: () => {
      navigate(RouteUrls.productsAndCategories.offerList.approvedDetail, {
        offerId: record.id,
      });
    },
  });

  return (
    <section className="offer-list">
      <Show when={isBulkUpdateOfferStatusActive} animateProps={Animations.fade}>
        <BulkUpdateOfferStatus
          downloadBulkProductOffersTemplate={() => onXlsxExport({ fieldsToExport: null })}
          isDownloadBulkProductOffersTemplateFetching={isExportOffersFetching || isExportingOffers}
        />
      </Show>
      <AkinonFilter
        title={t('filter')}
        {...staticFilters}
        {...dynamicFilters}
        includeFileFilter={true}
        total={total}
      />

      <div className="offer-list__table">
        <AkinonTable
          title={t('offer.list')}
          columns={columns}
          dataSource={data}
          optionsUrl={getProductOffersUrl}
          loading={isLoading}
          total={total}
          rowKey={defaultRowKey}
          exportFormats={[ExportFormat.XLSX]}
          rowSelection={rowSelection}
          tableActions={tableActions}
          pagination={pagination}
          setPagination={setPagination}
          description={`${total} ${t('results.found')}`}
          onRow={onRow}
          enableDynamicColumns
          onExport={onExport}
        />
      </div>

      <Modal width={800} title={t('bulk.make.offers.active')} {...bulkMakeOfferActiveProps}>
        <div>
          {t('bulk.make.offers.active.desc', {
            count: selectedRowKeys?.length,
          })}
        </div>
      </Modal>
      <Modal width={800} title={t('bulk.make.offers.passive')} {...bulkMakeOfferPassiveProps}>
        <div>
          {t('bulk.make.offers.passive.desc', {
            count: selectedRowKeys?.length,
          })}
        </div>
      </Modal>
      <Modal
        width={800}
        title={
          selectedRowKeys?.length > 0
            ? t('make_filtered_offers_active')
            : t('make_all_offers_active')
        }
        {...makeFilteredOffersActiveModalProps}
      >
        <div>
          {selectedRowKeys?.length > 0
            ? t('make_filtered_offers_active_desc')
            : t('make_all_offers_active_desc')}
        </div>
      </Modal>
      <Modal
        width={800}
        title={
          selectedRowKeys?.length > 0
            ? t('make_filtered_offers_passive')
            : t('make_all_offers_passive')
        }
        {...makeFilteredOffersPassiveProps}
      >
        <div>
          {selectedRowKeys?.length > 0
            ? t('make_filtered_offers_passive_desc')
            : t('make_all_offers_passive_desc')}
        </div>
      </Modal>
    </section>
  );
}
