import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { client } from '@services/api/client';

export function useOrderingQuery({ url, queryOptions } = {}) {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [url],
    queryFn: () => client.options(url),
    ...queryOptions,
  });

  return {
    ordering: data?.ordering,
    isFetchingOrdering: isFetching,
  };
}
