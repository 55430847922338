import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { getReconciliationItems } from '@services/api/index';
import { useQuery } from '@tanstack/react-query';

/**
 * @typedef {import('@tanstack/react-query').UseQueryResult} UseQueryResult
 * @returns {import('@tanstack/react-query').UseQueryResult}
 */
export const useReconciliationItems = ({ pagination, filters, id, enabled = true }) => {
  const queryKey = ['reconciliation-items', pagination, filters, id];

  const queryFn = () =>
    getReconciliationItems({
      reconciliation: id,
      ...getRequestPaginationParams(pagination),
      ...getRequestFiltersParams(filters),
    });

  const response = useQuery({ queryKey, queryFn, enabled });

  return {
    ...response,
    total: response.data?.count,
    reconciliations: response.data?.results,
  };
};
