import { resetAuthObjects } from '@common/auth';
import If from '@components/If';
import { QueryKey } from '@constants/query';
import { RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { queryClient } from '@services/api/queryClient';
import { useBlacklistRefreshTokenMutation } from '@services/hooks/auth/useBlacklistRefreshTokenMutation';
import { IconUserCircle } from '@tabler/icons-react';
import { Avatar, Badge, Popover, Skeleton, Space, Typography } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const ProfilePopover = ({ user }) => {
  const { t } = useTranslation();
  const navigate = useAppNavigate();
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const { blacklistRefreshToken } = useBlacklistRefreshTokenMutation({
    onSettled() {
      queryClient.removeQueries(QueryKey.USER_ME);
      resetAuthObjects();
    },
  });

  const onLanguageSettingsClick = () => {
    navigate(RouteUrls.profile.languageSettings);
    setIsPopoverVisible(false);
  };

  const onSignOutClick = () => {
    blacklistRefreshToken({
      requestConfig: {
        suppressedNotifications: ['success'],
      },
    });
  };

  const onProfileClick = () => {
    navigate(RouteUrls.profile.activeUser);
    setIsPopoverVisible(false);
  };

  const userFullName = `${user?.first_name} ${user?.last_name}`;

  return (
    <div className="app-layout__header-horizontal-border-box">
      <Popover
        open={isPopoverVisible}
        onOpenChange={setIsPopoverVisible}
        overlayClassName="app-layout__header-user-popover-overlay"
        placement="bottom"
        trigger={['click']}
        content={
          <>
            <Text onClick={onProfileClick} className="app-layout__header-user-popover-item">
              {t('profile')}
            </Text>
            <Text
              onClick={onLanguageSettingsClick}
              className="app-layout__header-user-popover-item"
            >
              {t('languageSettings')}
            </Text>
            <Text onClick={onSignOutClick} className="app-layout__header-user-popover-item">
              {t('sign_out')}
            </Text>
          </>
        }
      >
        <section className="app-layout__header-user-box">
          <If
            condition={isEmpty(user)}
            then={
              <Space>
                <Skeleton.Input active />
                <Skeleton.Avatar active />
              </Space>
            }
            otherwise={
              <>
                <div className="app-layout__header-user-info">
                  <Text className="app-layout__header-user-name">{userFullName}</Text>
                  <Text className="app-layout__header-user-email">{user?.email}</Text>
                </div>
                <Badge className="app-layout__header-user-badge" status="success" dot>
                  <Avatar
                    className="app-layout__header-user-avatar"
                    src={user?.image ?? <IconUserCircle size={32} stroke="1" />}
                    shape="circle"
                  />
                </Badge>
              </>
            }
          />
        </section>
      </Popover>
    </div>
  );
};

export default ProfilePopover;
