import { Cookies } from 'react-cookie';

export function readStorageItem(key) {
  return localStorage.getItem(key);
}
export function writeStorageItem(key, value) {
  localStorage.setItem(key, value);
}
export function removeStorageItem(key) {
  localStorage.removeItem(key);
}

export const getCookie = (cookieName) => {
  const cookies = new Cookies();
  return cookies.get(cookieName);
};

export const setCookie = (cookieName, cookieValue) => {
  const cookies = new Cookies();
  cookies.set(cookieName, cookieValue, { path: '/' });
};

export const removeCookie = (cookieName) => {
  const cookies = new Cookies();
  cookies.remove(cookieName, { path: '/' });
};
