import { WidgetType } from '@constants/commontypes';
import { useTranslation } from 'react-i18next';

import { StaticFilterKey } from '../common';

export const useDynamicFilters = () => {
  const { t } = useTranslation('Marketing');
  return {
    dynamicFiltersFormMetaFields: [
      {
        widget: WidgetType.DATE_PICKER,
        key: StaticFilterKey.FIRST_CREATED_DATE,
        placeholder: t('first.creation.date'),
      },
      {
        widget: WidgetType.DATE_PICKER,
        key: StaticFilterKey.LAST_CREATED_DATE,
        placeholder: t('last.creation.date'),
      },
      {
        widget: WidgetType.DATE_PICKER,
        key: StaticFilterKey.FIRST_EXPIRATION_DATE,
        placeholder: t('first_expiration_date'),
      },
      {
        widget: WidgetType.DATE_PICKER,
        key: StaticFilterKey.LAST_EXPIRATION_DATE,
        placeholder: t('last_expiration_date'),
      },
    ].map((item, index) => ({ pk: index, ...item })),
  };
};
