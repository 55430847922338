import { getTicketUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { useAkinonQuery } from '../adapters/useAkinonQuery';
import { client } from '../client';

export function useTicketQuery({ queryOptions, ticketId }) {
  const { data, isLoading } = useAkinonQuery({
    queryKey: [QueryKey.TICKET, ticketId],
    queryFn: () => client.get(getTicketUrl, { urlParams: { ticketId } }),
    ...queryOptions,
  });

  return {
    ticket: data,
    isTicketLoading: isLoading,
  };
}
