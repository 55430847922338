import { getProductsUrl } from '@constants/apiUrls';
import { limitQuery } from '@constants/commontypes';
import { useQuery } from '@services/api';
import { fetcher } from '@services/api/client';

export function useProductsQuery({ productOffers, params }) {
  const queryResponse = useQuery(
    () => [
      getProductsUrl,
      {
        params: {
          ...limitQuery,
          ...params,
          ...(productOffers?.length && {
            id__in: productOffers.map(({ product }) => product).join(','),
          }),
        },
      },
    ],
    fetcher
  );

  return {
    ...queryResponse,
    products: queryResponse.data?.results,
    isProductsLoading: queryResponse.isLoading,
    isProductsFetching: queryResponse.isFetching,
  };
}
