import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { createSearchParams, generatePath, useNavigate } from 'react-router-dom';

const reactRouterNavigateOptions = [
  'replace',
  'state',
  'relative',
  'unstable_flushSync',
  'unstable_viewTransition',
];

const useAppNavigate = () => {
  // eslint-disable-next-line no-restricted-syntax
  const navigate = useNavigate();

  /**
   * @param {import('react-router-dom').To || number} to
   * @param {import('react-router-dom').NavigateOptions & Record<string, any>} options
   */
  return (to, options) => {
    const routeParams = omit(options, reactRouterNavigateOptions);
    const navigateOptions = pick(options, reactRouterNavigateOptions);
    let queryParams = '';
    if (options?.queryParams) {
      queryParams = '?' + new createSearchParams(options?.queryParams).toString();
    }
    if (!options) {
      return navigate(to);
    } else if (isEmpty(routeParams)) return navigate(to, options);
    else {
      return navigate(generatePath(to, routeParams) + queryParams, navigateOptions);
    }
  };
};

export default useAppNavigate;
