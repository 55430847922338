import useInputHasOverflow from '@utils/hooks/useInputHasOverflow';
import { Tooltip } from 'antd';
import { memo, useRef } from 'react';
import { useController } from 'react-hook-form';

export const withTooltip = (Component) => {
  return memo(({ children, ...props }) => {
    const inputRef = useRef(null);
    const { field } = useController({ control: props.control, name: props.name });
    const hasOverflow = useInputHasOverflow({ inputRef, inputValue: field.value });

    return (
      <Tooltip title={hasOverflow ? props.tooltipTitle : null}>
        <Component {...props} inputRef={inputRef}>
          {children}
        </Component>
      </Tooltip>
    );
  });
};
