import { getRequestFiltersParams } from '@common/index';
import { postPreOfferBulkApproveUrl } from '@constants/apiUrls';

import { useAkinonMutation } from '../adapters/useAkinonMutation';
import { client } from '../client';

export function useCreatePreOfferBulkApproveMutation({ params, filters, mutationOptions } = {}) {
  const { is_active = true } = params || {};
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: () =>
      client.post(postPreOfferBulkApproveUrl, null, {
        params: {
          is_active,
          ...getRequestFiltersParams(filters),
          ...params,
        },
      }),
    ...mutationOptions,
  });

  return {
    createPreOfferBulkApprove: mutate,
    isCreatingPreOfferBulkApprove: isLoading,
  };
}
