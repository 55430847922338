import AkinonButton from '@components/AkinonButton';
import Modal from '@components/Modal/Modal';
import { Image } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import keys from 'lodash/keys';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import values from 'lodash/values';
import { useTranslation } from 'react-i18next';

const CurrentAttributesSummary = ({
  attributes,
  currentAttributes,
  record,
  setIsRevisionDetailModalVisible,
  setRevisionDetailModalInfo,
}) => {
  const { t } = useTranslation('ProductsAndCategories');
  const requestedAttributes = record?.attributes;
  const attributesToDisplay = omit(pick(currentAttributes, keys(requestedAttributes)), 'name');
  const { images, revisionImages } = record;
  const shouldShowImages = revisionImages?.length > 0 || images?.length > 0;

  // fill default values
  keys(requestedAttributes).forEach((key) => {
    if (!(key in currentAttributes)) {
      attributesToDisplay[key] = '-';
    }
  });

  const hasLongItems =
    keys(attributesToDisplay).length > 3 ||
    values(attributesToDisplay).some((value) => value.length > 150);

  const onDisplayDetailModal = () => {
    setRevisionDetailModalInfo((draft) => {
      draft.hasLongItems = hasLongItems;
      draft.currentAttributes = cloneDeep(currentAttributes);
      draft.requestedAttributes = cloneDeep(requestedAttributes);
      draft.record = cloneDeep(record);
    });
    setIsRevisionDetailModalVisible(true);
  };

  return (
    <div className="old-offer-attributes">
      <div className="offer-attribute">
        <div className="offer-attribute-name">{t('product_name')}:</div>{' '}
        <div className="offer-attribute-value">{currentAttributes?.name ?? '-'}</div>
      </div>
      {shouldShowImages && (
        <div className="revision-images-container">
          <div className="offer-attribute-name">
            {t('product_images')}
            {!images && ': -'}
          </div>
          {images && (
            <div className="revision-images-container__images">
              <Image.PreviewGroup>
                {images.slice(0, 3).map((item) => (
                  <Image width={50} key={item.id} src={item.image} />
                ))}
                {images.slice(3, -1).map((item) => (
                  <Image
                    wrapperStyle={{ display: 'none' }}
                    width={50}
                    key={item.id}
                    src={item.image}
                  />
                ))}
              </Image.PreviewGroup>
              {images?.length > 3 && (
                <div
                  className="revision-images-container__images-more"
                  onClick={() => {
                    Modal.confirm({
                      title: t('product_images'),
                      icon: null,
                      maskClosable: true,
                      width: 800,
                      content: (
                        <div className="columns-1 md:columns-2 xl:columns-3">
                          {revisionImages.map((item) => (
                            <div key={item.id} className="m-2">
                              <Image src={item.image} />
                            </div>
                          ))}
                        </div>
                      ),
                      okButtonProps: { style: { display: 'none' } },
                      cancelButtonProps: { style: { display: 'none' } },
                      closable: true,
                    });
                  }}
                >
                  {t('n_more', { count: images?.length - 3 })}
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {Object.entries(attributesToDisplay ?? {})
        .slice(0, 2)
        .map(([key, value]) => {
          const attributeName = attributes.find((attribute) => attribute.key === key)?.name ?? key;
          return (
            <div className="offer-attribute" key={key}>
              <div className="offer-attribute-name">{attributeName}:</div>
              <div className="offer-attribute-value">{value}</div>
            </div>
          );
        })}
      {hasLongItems && (
        <AkinonButton type="link" onClick={onDisplayDetailModal}>
          {t('view_revision_details', { count: keys(requestedAttributes).length })}
        </AkinonButton>
      )}
    </div>
  );
};

export default CurrentAttributesSummary;
