import './styles.scss';

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * @param {import('antd').SpinProps} props
 */
const AkinonSpin = ({ wrapperClassName, children, tip, ...otherProps }) => {
  const { t } = useTranslation();
  return (
    <Spin
      wrapperClassName={clsx('akinon-spin', wrapperClassName)}
      indicator={
        <LoadingOutlined className="akinon-spin__indicator" style={{ fontSize: 24 }} spin />
      }
      tip={tip ?? `${t('loading')}...`}
      {...otherProps}
    >
      {children}
    </Spin>
  );
};

export default AkinonSpin;
