import { createNavigationItems, getUserRole } from '@common/auth';
import { RouteUrls } from '@constants/routeUrls';
import { IconBuildingWarehouse } from '@tabler/icons-react';
import React from 'react';

export const getNavigationItems = ({ t, user, isLiveAppVersion }) => {
  const userRole = getUserRole({ user });

  return createNavigationItems({
    navigationItems: [
      {
        to: RouteUrls.productsAndCategories.addProductsFromCatalog,
        title: t('add.from.marketplace.catalog'),
        description: t('add.from.marketplace.catalog.desc'),
        icon: <i className="icon-katalogdan-urun-ekle" />,
      },
      {
        to: RouteUrls.productsAndCategories.bulkActions.landing,
        title: t('bulk.actions'),
        description: t('bulk.actions.desc'),
        icon: <i className="icon-toplu-islemler" />,
      },
      {
        to: RouteUrls.productsAndCategories.productRevisions.landing,
        title: t('product_revisions'),
        description: t('product_revisions_desc'),
        icon: <i className="icon-toplu-islemler" />,
      },
      {
        to: RouteUrls.productsAndCategories.offerList.landing,
        title: t('offer.actions'),
        description: t('offer.actions.desc'),
        icon: <i className="icon-teklif-listesi" />,
      },
      {
        to: RouteUrls.productsAndCategories.inventoryManagement.landing,
        title: t('inventory.management'),
        description: t('inventory.management.desc'),
        icon: <IconBuildingWarehouse size={36} stroke={1.5} />,
      },
    ],
    userRole,
    isLiveAppVersion,
  });
};
