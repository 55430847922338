import { getStockListsUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { client } from '@services/api/client';
import { useQuery } from '@tanstack/react-query';

/**
 * @param {{ queryOptions: import('@tanstack/react-query').UseQueryOptions }} args
 */
export function useStockListsQuery({ queryOptions } = {}) {
  const { data } = useQuery({
    queryKey: [QueryKey.STOCK_LISTS],
    queryFn: () =>
      client.get(getStockListsUrl, {
        params: {
          is_master: true,
        },
      }),
    ...queryOptions,
  });

  return {
    stockLists: data?.results,
  };
}
