import './style.scss';

import { createSelectOptions } from '@common/index';
import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import AkinonFormItem from '@components/AkinonFormItem';
import AkinonInput, { AkinonInputNumber, AkinonTextArea } from '@components/AkinonInput';
import AkinonSelect from '@components/AkinonSelect';
import AkinonSpin from '@components/AkinonSpin';
import Box from '@components/utility/box';
import { zodResolver } from '@hookform/resolvers/zod';
import { TranslationKey } from '@root/i18n';
import { Map } from '@vis.gl/react-google-maps';
import { Divider } from 'antd';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// import { useParams } from 'react-router-dom';
import { FormKey, getFormSchema } from './common';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { useDatasource } from './hooks/useDatasource';
import { usePageMeta } from './hooks/usePageMeta';

const LocationsForm = () => {
  const { countries, cities, districts, townships } = useDatasource();
  const { t } = useTranslation(TranslationKey.LOCATIONS);
  // const { id: locationId } = useParams();

  const schema = getFormSchema();
  const { control, handleSubmit /*reset*/, watch } = useForm({
    resolver: zodResolver(schema),
  });
  useBreadcrumbs();
  usePageMeta();

  const latitude = watch(FormKey.LATITUDE);
  const longitude = watch(FormKey.LONGITUDE);
  // useEffect(() => {
  //   if (locationId && locationDetail) {
  //     reset({
  //       ...locationDetail,
  //     });
  //   }
  // }, [locationId, locationDetail]);

  const onSubmit = (formValues) => {};

  return (
    <section className="locations-form">
      <AkinonSpin spinning={false /*Boolean(locationId && isFetchingLocationDetail)*/}>
        <Box
          className="box-primary form-box"
          title={t('location_edit_title')}
          subtitle={t('location_edit_desc')}
        >
          <AkinonForm className="akn-form akn-form-v2" layout="vertical">
            <Divider className="form-divider mt-0" orientation="left">
              {t('tag')}
            </Divider>
            <AkinonInput
              formItemProps={{
                name: FormKey.NAME,
                label: t('location_name'),
                control,
              }}
              placeholder={t('location_name')}
              required
            />
            <AkinonInputNumber
              formItemProps={{
                name: FormKey.LOCATION_CODE,
                label: t('location_code'),
                control,
              }}
              placeholder={t('location_code')}
              required
            />
            <Divider className="form-divider" orientation="left">
              {t('behaviors')}
            </Divider>
            <AkinonInputNumber
              formItemProps={{
                name: FormKey.PRIORITY,
                label: t('location_priority'),
                control,
              }}
              required
              placeholder={t('location_priority')}
            />
            <Divider className="form-divider" orientation="left">
              {t('address')}
            </Divider>
            <AkinonInput
              formItemProps={{
                name: FormKey.ADDRESS_ID,
                label: t('address_id'),
                control,
              }}
              placeholder={t('address_id')}
              required
            />
            <AkinonInput
              formItemProps={{
                name: FormKey.LOCATION_ADDRESS_TITLE,
                label: t('location_address_title'),
                control,
              }}
              placeholder={t('location_address_title')}
              required
            />
            <AkinonSelect
              formItemProps={{
                name: FormKey.COUNTRY,
                label: t('country'),
                control,
              }}
              placeholder={t('country')}
              options={createSelectOptions(countries, {
                labelKey: 'name',
                valueKey: 'id',
              })}
              required
            />
            <AkinonInput
              formItemProps={{
                name: FormKey.LOCATION_ADDRESS,
                label: t('postal_code'),
                control,
              }}
              placeholder={t('postal_code')}
            />
            <AkinonTextArea
              formItemProps={{
                name: FormKey.LOCATION_ADDRESS,
                label: t('location_address'),
                control,
              }}
              placeholder={t('location_address')}
              required
            />
            <div className="locations-form__two-column">
              <AkinonSelect
                formItemProps={{
                  name: FormKey.PROVINCE,
                  label: t('province'),
                  control,
                }}
                placeholder={t('province')}
                options={createSelectOptions(cities, {
                  labelKey: 'name',
                  valueKey: 'id',
                })}
                required
              />
              <AkinonSelect
                formItemProps={{
                  name: FormKey.DISTRICT,
                  label: t('district'),
                  control,
                }}
                placeholder={t('district')}
                options={createSelectOptions(districts, {
                  labelKey: 'name',
                  valueKey: 'id',
                })}
                required
              />
            </div>
            <div className="w-1/2 mb-3">
              <AkinonSelect
                formItemProps={{
                  name: FormKey.NEIGHBORHOOD,
                  label: t('neighborhood'),
                  control,
                }}
                placeholder={t('neighborhood')}
                options={createSelectOptions(townships, {
                  labelKey: 'name',
                  valueKey: 'id',
                })}
              />
            </div>
            <AkinonInputNumber
              formItemProps={{
                name: FormKey.PHONE_NUMBER,
                label: t('phone_number'),
                control,
              }}
              placeholder={t('phone_number')}
            />
            <AkinonInput
              formItemProps={{
                name: FormKey.EMAIL,
                label: t('email'),
                control,
              }}
              placeholder={t('email')}
            />
            <div className="locations-form__two-column">
              <AkinonInputNumber
                formItemProps={{
                  name: FormKey.LATITUDE,
                  label: t('latitude'),
                  control,
                }}
                placeholder={t('latitude')}
                min={-90}
                max={90}
                required
              />
              <AkinonInputNumber
                formItemProps={{
                  name: FormKey.LONGITUDE,
                  label: t('longitude'),
                  control,
                }}
                placeholder={t('longitude')}
                min={-180}
                max={180}
                required
              />
            </div>
            <div className="mb-3">
              <Map
                style={{ width: '100%', height: '400px', borderRadius: 5, overflow: 'hidden' }}
                center={{ lat: latitude ?? 41, lng: longitude ?? 29 }}
                defaultZoom={9}
                gestureHandling={'greedy'}
              />
            </div>
            <div className="locations-form__footer">
              <AkinonFormItem>
                <AkinonButton htmlType="submit" onClick={handleSubmit(onSubmit)}>
                  {t('send').toUpperCase()}
                </AkinonButton>
              </AkinonFormItem>
            </div>
          </AkinonForm>
        </Box>
      </AkinonSpin>
    </section>
  );
};

export default LocationsForm;
