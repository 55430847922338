import { patchTicketUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useQueryClient } from '@tanstack/react-query';
import omit from 'lodash/omit';

import { useAkinonMutation } from '../adapters/useAkinonMutation';
import { client } from '../client';

export function usePatchTicketMutation({ mutationOptions } = {}) {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody, ticketId, requestConfig = {} }) => {
      return client.patch(patchTicketUrl, requestBody, {
        ...requestConfig,
        urlParams: { ticketId },
      });
    },
    onSuccess() {
      mutationOptions?.onSuccess?.();
      queryClient.invalidateQueries([QueryKey.TICKET]);
    },
    ...omit(mutationOptions, ['onSuccess']),
  });

  return {
    updateTicket: mutate,
    isUpdatingTicket: isLoading,
  };
}
