import useStore from '@zustand-store/index';
import {
  setIsVariantsFormDynamicMetaFieldsLoadingSelector,
  setIsVariantsListAttributeMetaFieldsLoadingSelector,
  setVariantsFormDynamicMetaFieldsSelector,
  setVariantsListAttributeMetaFieldsSelector,
} from '@zustand-store/selectors/preOffer';
import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { getFormDynamicMetaFields, injectOptionsIntoMetaFields } from './common';

export default function useFormMetaFields({ attributeConfigs, productAttributes }) {
  const { t } = useTranslation('ProductsAndCategories');
  const setVariantsListAttributeMetaFields = useStore(setVariantsListAttributeMetaFieldsSelector);
  const setVariantsFormDynamicMetaFields = useStore(setVariantsFormDynamicMetaFieldsSelector);
  const setIsVariantsListAttributeMetaFieldsLoading = useStore(
    setIsVariantsListAttributeMetaFieldsLoadingSelector
  );
  const setIsVariantsFormDynamicMetaFieldsLoading = useStore(
    setIsVariantsFormDynamicMetaFieldsLoadingSelector
  );

  useEffect(() => {
    if (!isEmpty(attributeConfigs) && !isEmpty(productAttributes)) {
      setIsVariantsListAttributeMetaFieldsLoading(true);
      setIsVariantsFormDynamicMetaFieldsLoading(true);
      const { variantsListAttributeMetaFields, variantsFormDynamicMetaFields } =
        getFormDynamicMetaFields({
          t,
          attributeConfigs,
          productAttributes,
        });
      Promise.all(
        injectOptionsIntoMetaFields({ metaFields: variantsListAttributeMetaFields, t })
      ).then((enhancedVariantsListAttributeMetaFields) => {
        setIsVariantsListAttributeMetaFieldsLoading(false);
        setVariantsListAttributeMetaFields(enhancedVariantsListAttributeMetaFields);
      });
      Promise.all(
        injectOptionsIntoMetaFields({ metaFields: variantsFormDynamicMetaFields, t })
      ).then((enhancedVariantsFormDynamicMetaFields) => {
        setIsVariantsFormDynamicMetaFieldsLoading(false);
        setVariantsFormDynamicMetaFields(enhancedVariantsFormDynamicMetaFields);
      });
    }
  }, [attributeConfigs, productAttributes]);
}
