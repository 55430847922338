import {
  useOAuthOptionsQuery,
  useOAuthProvidersQuery,
} from '@services/api/hooks/useOAuthProvidersQuery';

export function useDataSource({ filters } = {}) {
  const { providers, isFetchingProviders } = useOAuthProvidersQuery({ filters });
  const { oAuthOptions, isFetchingOauthOptions } = useOAuthOptionsQuery();

  const providerChoices = oAuthOptions?.actions?.POST?.provider?.choices;

  const dataSource = providers?.map(({ provider, ...rest }) => ({
    ...rest,
    provider: providerChoices?.find(({ value }) => value === provider)?.display_name || provider,
  }));

  return {
    dataSource,
    isDataSourceLoading: isFetchingProviders || isFetchingOauthOptions,
  };
}
