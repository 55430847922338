import { TranslationKey } from '@root/i18n';
import useStore from '@zustand-store/index';
import { setPageMetaSelector } from '@zustand-store/selectors/app';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

export function usePageMeta() {
  const { t } = useTranslation(TranslationKey.LOCATIONS);
  const setPageMeta = useStore(setPageMetaSelector);

  useEffect(() => {
    setPageMeta({
      title: t('location_edit_title'),
      description: t('location_edit_desc'),
    });
  }, [t]);

  useUnmount(() => {
    setPageMeta({});
  });
}
