import './styles.scss';

import { StopOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const { Title, Text } = Typography;

const UserNotAuthorized = () => {
  const { t } = useTranslation();
  return (
    <article className="user-not-authorized">
      <Title className="user-not-authorized__title" level={2}>
        {t('userNotAuthorized.title')}
      </Title>
      <Text className="user-not-authorized__text">
        <StopOutlined className="user-not-authorized__icon" /> {t('userNotAuthorized.text')}
      </Text>
    </article>
  );
};

export default UserNotAuthorized;
