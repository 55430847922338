import './style.scss';

import If from '@components/If';
import Show from '@components/Show';
import Popover from '@components/UIElements/Popover';
import Spin from '@components/UIElements/spin';
import { useExportQuery } from '@services/api/hooks/useExportQuery.js';
import { useDownloadableContent } from '@services/hooks/common/useDownloadableContent';
import { downloadBinaryFile, getFilenameFromURL } from '@utils/index';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export default function Download() {
  const { t } = useTranslation('Download');
  const { cacheKey } = useParams();
  const { downloadUrl, exportError, isExporting } = useExportQuery({ cacheKey });
  const [isDownloadSuccessTooltip, setDownloadSuccessTooltip] = useState(false);
  const {
    mutate: downloadMutate,
    isMutating: isDownloadMutating,
    error: downloadMutationError,
  } = useDownloadableContent();

  const download = () => {
    downloadMutate(
      { downloadUrl },
      {
        onSuccess: (data) => {
          downloadBinaryFile(data, getFilenameFromURL(downloadUrl));
          setDownloadSuccessTooltip(true);
        },
      }
    );
  };

  return (
    <section>
      <If
        condition={isExporting}
        then={
          <div>
            <span className="download-info-message">{t('download.page.info.unready')}</span>
            <Spin className="download-info-message__spinner" size="small" />
          </div>
        }
        otherwise={
          <If
            condition={!isExporting && !exportError}
            then={
              <div>
                <div className="download-info-message">{t('download.page.info.ready')}</div>
                <Popover
                  content={t('download.success')}
                  open={isDownloadSuccessTooltip}
                  disabled={!isDownloadSuccessTooltip || exportError || isExporting}
                  placement="bottomLeft"
                >
                  <span
                    className="download-action"
                    onClick={() => !isDownloadMutating && download()}
                    onMouseLeave={() => setDownloadSuccessTooltip(false)}
                  >
                    {downloadUrl}
                  </span>
                </Popover>
              </div>
            }
            otherwise={
              <Show when={(!isDownloadMutating && downloadMutationError) || exportError}>
                <div>
                  <span className="download-info-message">{t('encountered.error.try.again')}</span>
                </div>
              </Show>
            }
          />
        }
      />
    </section>
  );
}
