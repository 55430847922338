import { defaultDateFormat } from '@constants/index';
import moment from 'moment';

export const DataIndex = {
  NAME: 'name',
  IS_AUTO_SYNC: 'is_auto_sync',
  CREATED_AT: 'created_at',
  UPDATED_AT: 'updated_at',
};

export const getColumns = ({ t }) => [
  {
    dataIndex: DataIndex.NAME,
    title: t('stock.list'),
  },
  {
    dataIndex: DataIndex.IS_AUTO_SYNC,
    title: t('erp.connection'),
    render: (isAutoSync) => (isAutoSync ? t('yes') : t('no')),
  },
  {
    dataIndex: DataIndex.CREATED_AT,
    title: t('creation.date1'),
    render: (createdAt) => moment(createdAt).format(defaultDateFormat),
  },
  {
    dataIndex: DataIndex.UPDATED_AT,
    title: t('last.update'),
    render: (updatedAt) => moment(updatedAt).format(defaultDateFormat),
  },
];
