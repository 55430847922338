import { useQuickReply } from './api/useQuickReply';

export const useQuickReplyData = ({ quickReplyId }) => {
  const {
    quickReply,
    isFetching: isFetchingQuickReply,
    mutate,
  } = useQuickReply({
    quickReplyId,
    queryOptions: { enabled: Boolean(quickReplyId) },
  });

  return {
    quickReply,
    isFetchingQuickReply,
    mutate,
  };
};
