import './style.scss';

import { formatDateTime } from '@common/dateTime';
import i18n from '@root/i18n';
import { Tag, Tooltip } from 'antd';
import React from 'react';

export const ReconciliationStatusToColor = {
  waiting: 'orange',
  started: 'green',
  finished: 'green',
  waiting_for_approval: 'orange',
  sending_for_approval: 'orange',
  waiting_for_modified: 'orange',
  approved: 'green',
  completed: 'green',
};

export const StaticFilterKey = {
  ORDER_NUMBER: 'order__number',
  NAME: 'name',
  NUMBER: 'number',
  SKU: 'sku',
  STATUS: 'status',
  START_DATE_GTE: 'start_date__gte',
  START_DATE_LTE: 'start_date__lte',
  END_DATE_GTE: 'end_date__gte',
  END_DATE_LTE: 'end_date__lte',
  DATASOURCE: 'datasource',
};

export const DataIndex = {
  NUMBER: 'number',
  NAME: 'name',
  STATUS: 'status',
  TOTAL_INCOME: 'total_income',
  TOTAL_EXPANSE: 'total_expanse',
  TOTAL_COMMISSION: 'total_commission',
  START_DATE: 'start_date',
  END_DATE: 'end_date',
};

export const getColumns = ({ t, isSuperUser }) => {
  return [
    {
      dataIndex: DataIndex.NUMBER,
      title: t('reconciliation_number'),
      render: (number) => <Tooltip title={number}>{number}</Tooltip>,
    },
    isSuperUser && {
      dataIndex: DataIndex.NAME,
      visibleOnFilter: [StaticFilterKey.DATASOURCE],
      title: t('seller.name'),
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      dataIndex: DataIndex.STATUS,
      title: t('status'),
      onCell: () => ({
        className: 'reconciliation-status-cell',
      }),
      render: (status) => {
        const statusText = t(`reconciliation_status.${status}`);
        return <Tag color={ReconciliationStatusToColor[status]}>{statusText}</Tag>;
      },
    },
    {
      dataIndex: DataIndex.TOTAL_INCOME,
      title: t('total_sale_count'),
      render: (totalIncome) => {
        const formattedTotalIncome = new Intl.NumberFormat(i18n.language, {
          minimumFractionDigits: 2,
        }).format(totalIncome);
        return <Tooltip title={formattedTotalIncome}>{formattedTotalIncome}</Tooltip>;
      },
    },
    {
      dataIndex: DataIndex.TOTAL_EXPANSE,
      title: t('total_refund'),
      render: (totalRefund) => {
        const formattedTotalRefund = new Intl.NumberFormat(i18n.language, {
          minimumFractionDigits: 2,
        }).format(totalRefund);
        return <Tooltip title={formattedTotalRefund}>{formattedTotalRefund}</Tooltip>;
      },
    },
    {
      dataIndex: DataIndex.TOTAL_COMMISSION,
      title: t('total_commmission'),
      render: (totalCommission) => {
        const formattedTotalCommission = new Intl.NumberFormat(i18n.language, {
          minimumFractionDigits: 2,
        }).format(totalCommission);
        return <Tooltip title={formattedTotalCommission}>{formattedTotalCommission}</Tooltip>;
      },
    },
    {
      dataIndex: DataIndex.START_DATE,
      title: t('sale_start_date'),
      render: formatDateTime,
    },
    {
      dataIndex: DataIndex.END_DATE,
      title: t('sale_end_date'),
      render: formatDateTime,
    },
  ];
};
