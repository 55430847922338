import './styles.scss';

import AkinonFormItem from '@components/AkinonFormItem';
import { Switch } from 'antd';
import clsx from 'clsx';
import { forwardRef } from 'react';

/**
 * @param {import('antd').SwitchProps && { value: boolean }} props
 */
const AkinonSwitch = forwardRef(({ formItemProps, className, value, ...otherProps }, ref) => {
  const component = (
    <Switch
      ref={ref}
      className={clsx('akinon-switch', className)}
      checked={value}
      {...otherProps}
    />
  );
  if (formItemProps) {
    return (
      <AkinonFormItem valuePropName="checked" {...formItemProps}>
        {component}
      </AkinonFormItem>
    );
  }

  return component;
});

export default AkinonSwitch;
