import { getAttributeOptionsUrl } from '@constants/apiUrls';
import { limitQuery } from '@constants/commontypes';
import { QueryKey } from '@constants/query';
import { useQueries } from '@tanstack/react-query';

import { client } from '../client';

export function useAttributeValuesMapQueries({ queryOptionList }) {
  return useQueries({
    queries:
      queryOptionList.map(({ attribute, ...queryOptions }) => ({
        queryKey: [QueryKey.ATTRIBUTE_VALUES, attribute],
        queryFn: () =>
          client.get(getAttributeOptionsUrl, {
            params: {
              attribute,
              ...limitQuery,
            },
          }),
        enabled: false,
        ...queryOptions,
      })) ?? [],
  });
}
