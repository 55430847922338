import { usePaginatedDataSourcesQuery } from '@services/hooks/datasources/usePaginatedDataSourcesQuery';
import { useTranslation } from 'react-i18next';

import { getStaticFiltersFormMetaFields } from '../common';

export const useStaticFilters = () => {
  const { t } = useTranslation('Settings');
  const { data: dataSources, isLoading } = usePaginatedDataSourcesQuery({
    pagination: {},
    filters: [],
  });

  return {
    staticFiltersFormMetaFields: getStaticFiltersFormMetaFields({ t, dataSources }),
    isStaticFiltersLoading: isLoading,
  };
};
