import Link from '@components/Link';
import { RouteUrls } from '@constants/routeUrls';
import useStore from '@zustand-store/index';
import { setBreadcrumbsSelector } from '@zustand-store/selectors/app';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

export function useBreadcrumbs() {
  const { t } = useTranslation('Marketing');
  const setBreadcrumbs = useStore(setBreadcrumbsSelector);

  useEffect(() => {
    setBreadcrumbs([
      <Link key={RouteUrls.marketing.landing} to={RouteUrls.marketing.landing}>
        {t('marketing')}
      </Link>,
      t('collections'),
    ]);
  }, [t]);

  useUnmount(() => {
    setBreadcrumbs([]);
  });
}
