import useStore from '@zustand-store/index';
import { setPageMetaSelector } from '@zustand-store/selectors/app';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

export function usePageMeta() {
  const { t } = useTranslation('Messages');
  const setPageMeta = useStore(setPageMetaSelector);

  useEffect(() => {
    setPageMeta({
      title: t('order_messages_detail'),
      description: t('order_messages_detail_desc'),
    });
  }, [t]);

  useUnmount(() => {
    setPageMeta({});
  });
}
