import './styles.scss';

import { MinusCircleOutlined } from '@ant-design/icons';
import AkinonButton from '@components/AkinonButton';
import AkinonFormItem from '@components/AkinonFormItem';
import AkinonInput from '@components/AkinonInput';
import { Form } from 'antd';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormKey } from '../common';

const ConfigFormList = ({ control, disabled }) => {
  const { t } = useTranslation('Settings');

  const { fields, append, remove } = useFieldArray({
    control,
    name: FormKey.CONFIG,
  });

  return (
    <AkinonFormItem name={FormKey.CONFIG} label={t('config')}>
      {fields.map(({ id }, index) => {
        return (
          <div key={id} className="config-form-list__wrapper">
            <div className="config-form-list__title-wrapper">
              <AkinonButton
                type="ghost"
                className="border-none !bg-transparent p-0 h-5 cursor-pointer"
                disabled={disabled}
                onClick={() => remove(index)}
                icon={<MinusCircleOutlined />}
              />
              <div className="config-form-list__title">{`${index + 1}.${t('field')}`}</div>
            </div>
            <AkinonInput
              formItemProps={{
                control,
                label: 'Key',
                name: `${FormKey.CONFIG}[${index}].key`,
                required: true,
              }}
              placeholder="Key"
              disabled={disabled}
            />
            <AkinonInput
              formItemProps={{
                control,
                label: 'Value',
                name: `${FormKey.CONFIG}[${index}].value`,
                required: true,
              }}
              placeholder="Value"
              disabled={disabled}
            />
          </div>
        );
      })}
      <Form.Item>
        <AkinonButton
          type="secondary"
          icon={<i className="icon-arti" />}
          onClick={() => append({ key: '', value: '' })}
          disabled={disabled}
        >
          {t('add_field')}
        </AkinonButton>
      </Form.Item>
    </AkinonFormItem>
  );
};

export default ConfigFormList;
