import './styles.scss';

import { DraggerInput } from '@components/FileInput';
import { Space, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const DraggerInputWithLabel = ({ label, ...draggerInputProps }) => {
  const { t } = useTranslation('Finance');

  return (
    <Space className="dragger-input-with-label" direction="vertical">
      <DraggerInput {...draggerInputProps} />
      <Text className="dragger-input-with-label__label">{label ?? t('uploadLimit5Mb')}</Text>
    </Space>
  );
};

export default DraggerInputWithLabel;
