import { RouteUrls } from '@constants/routeUrls';
import { useIsLoggedIn } from '@utils/hooks/useIsLoggedIn';
import React from 'react';
import { Navigate } from 'react-router-dom';

function Root() {
  const isLoggedIn = useIsLoggedIn();

  return <Navigate to={isLoggedIn ? RouteUrls.dashboard.landing : RouteUrls.public.signIn} />;
}

export default Root;
