import { QueryKey } from '@constants/query';
import { getDynamicSetting } from '@services/api/dynamicSettings';
import { useQuery } from '@tanstack/react-query';

export const useDynamicSettingDetailQuery = ({ dynamicSettingId, queryOptions } = {}) => {
  const queryKey = [QueryKey.DYNAMIC_SETTINGS, dynamicSettingId];

  const queryFn = () =>
    getDynamicSetting({
      dynamicSettingId,
    });

  const { data, isFetching, isLoading, error } = useQuery({
    queryKey,
    queryFn,
    enabled: Boolean(dynamicSettingId),
    ...queryOptions,
  });

  return {
    dynamicSettingDetail: data,
    isDynamicSettingFetching: isFetching,
    isDynamicSettingLoading: isLoading,
    dynamicSettingError: error,
  };
};
