import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { getReconciliations } from '@services/api/index';
import { useQuery } from '@tanstack/react-query';

/**
 * @typedef {import('@tanstack/react-query').UseQueryResult} UseQueryResult
 * @returns {import('@tanstack/react-query').UseQueryResult}
 */
export const useReconciliations = ({ pagination, filters, enabled = true }) => {
  const queryKey = ['reconciliations', filters];

  const queryFn = () =>
    getReconciliations({
      ...getRequestPaginationParams(pagination),
      ...getRequestFiltersParams(filters),
    });
  const response = useQuery({ queryKey, queryFn, enabled });

  return {
    ...response,
    total: response.data?.count,
    reconciliations: response.data?.results,
  };
};
