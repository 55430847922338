import './styles.scss';

import { DisconnectOutlined } from '@ant-design/icons';
import AkinonButton from '@components/AkinonButton';
import { Modal, Space, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNetworkState } from 'react-use';

const { Title, Text } = Typography;

const NoNetworkConnectionModal = () => {
  const { t } = useTranslation();
  const { online } = useNetworkState();

  const onTryAgainClick = () => {
    window.location.reload();
  };

  return (
    <Modal className="no-network-connection-modal" open={!online} footer={null} closable={false}>
      <Space direction="vertical" size="large">
        <Space direction="vertical" size="small">
          <Space align="middle">
            <DisconnectOutlined className="no-network-connection-modal__icon" />
            <Title level={4} className="no-network-connection-modal__title">
              {t('noNetworkConnectionModal.title')}
            </Title>
          </Space>
          <Text className="no-network-connection-modal__text">
            {t('noNetworkConnectionModal.text')}
          </Text>
        </Space>
        <AkinonButton onClick={onTryAgainClick}>{t('tryAgain')}</AkinonButton>
      </Space>
    </Modal>
  );
};

export default NoNetworkConnectionModal;
