import { getUserDetailURL, getUsersUrl } from '@constants/apiUrls';
import { client } from '@services/api/client';

export const getUserDetail = (userId, requestConfig) => {
  return client.get(getUserDetailURL, {
    ...requestConfig,
    urlParams: {
      userId,
    },
  });
};

export const postUserDetail = (requestBody) => {
  return client.post(getUsersUrl, requestBody);
};

export const putUserDetail = (userId, requestBody) => {
  return client.put(getUserDetailURL, requestBody, {
    urlParams: { userId },
  });
};
