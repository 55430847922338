import * as React from 'react';

const IconPush = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={32}
    height={32}
    {...props}
  >
    <title>{'push'}</title>
    <defs>
      <path id="a" d="M0 0h32v32H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <path
        fill="#BCBCBC"
        d="M30.933 24.01c0 .882-.716 1.598-1.6 1.598H14.4a.533.533 0 0 0-.31.1c-5.846 4.177-6.934 4.953-7.156 5.12v-.003a.566.566 0 0 1-.267.101.414.414 0 0 0-.106.009.747.747 0 0 1-.554-.282l-.025-.036a.574.574 0 0 1-.115-.354V26.14a.533.533 0 0 0-.534-.532H2.667a1.6 1.6 0 0 1-1.6-1.598V6.963a1.588 1.588 0 0 1 1.6-1.598h7.196a7.444 7.444 0 0 0-1.33 4.262v6.172l-2.355 2.35c-.2.2-.31.47-.311.752v.847c0 .588.477 1.065 1.066 1.065h5.915a3.196 3.196 0 0 0 6.304 0h5.915c.589 0 1.066-.477 1.066-1.065v-.847c0-.281-.112-.551-.31-.751l-2.356-2.351V12.29a4.787 4.787 0 0 0 4.293-6.925h1.573a1.586 1.586 0 0 1 1.591 1.45c.005.049.01.099.01.148V24.01Zm-12.875-3.197a2.123 2.123 0 0 1-4.116 0h4.116ZM14.054 3.54a.533.533 0 0 0 .366-.586 1.599 1.599 0 1 1 3.16-.001.533.533 0 0 0 .365.586c.65.206 1.263.516 1.813.919a4.783 4.783 0 0 0 2.642 7.709v3.852c0 .141.056.277.156.377l2.51 2.505v.847H6.934v-.843l2.512-2.51c.1-.1.155-.235.155-.376V9.627a6.356 6.356 0 0 1 4.454-6.087Zm9.413.227a3.736 3.736 0 0 1 3.733 3.73 3.731 3.731 0 0 1-3.733 3.728 3.731 3.731 0 0 1-3.734-3.729 3.731 3.731 0 0 1 3.734-3.729Zm8.52 2.958A2.649 2.649 0 0 0 29.334 4.3h-2.299a4.778 4.778 0 0 0-6.5-.59 7.34 7.34 0 0 0-1.867-1.047A2.665 2.665 0 0 0 16 0a2.665 2.665 0 0 0-2.667 2.663 7.422 7.422 0 0 0-2.56 1.637H2.667A2.65 2.65 0 0 0 .012 6.728C.006 6.807 0 6.885 0 6.963V24.01a2.668 2.668 0 0 0 2.667 2.663H4.8v3.59a1.643 1.643 0 0 0 .326.995c.332.454.856.728 1.418.742.04 0 .08-.002.12-.006h.01c.32-.015.63-.123.89-.312.145-.106 6.023-4.305 7.007-5.007h14.762A2.668 2.668 0 0 0 32 24.012V6.963c0-.078-.006-.156-.012-.238Z"
        mask="url(#b)"
      />
      <path
        fill="#BCBCBC"
        d="M23.1 8.829a.55.55 0 1 0 0 1.099h2.2a.55.55 0 1 0 0-1.099h-.55V6.082a.55.55 0 0 0-.939-.388l-1.1 1.099a.549.549 0 0 0 .778.776l.161-.16v1.42h-.55Z"
      />
    </g>
  </svg>
);
export default IconPush;
