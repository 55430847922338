import { isEmpty } from 'lodash';
import * as z from 'zod';

export const FormKey = {
  EMAIL: 'email',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  IS_ACTIVE: 'is_active',
  IS_STAFF: 'is_staff',
  LAST_LOGIN: 'last_login',
  IS_SUPERUSER: 'user_level.is_superuser',
  DATASOURCE: 'user_level.datasource',
  USER_LEVEL: 'user_level',
};
export const SchemaKey = {
  ...FormKey,
  IS_SUPERUSER: 'is_superuser',
  DATASOURCE: 'datasource',
};

export const getFormSchema = ({ t, dynamicUserSettings }) => {
  const schema = {
    [SchemaKey.EMAIL]: z.string().email(),
    [SchemaKey.FIRST_NAME]: z.string().min(1),
    [SchemaKey.LAST_NAME]: z.string().min(1),
    [SchemaKey.IS_ACTIVE]: z.coerce.boolean(),
    [SchemaKey.USER_LEVEL]: z
      .object({
        [SchemaKey.IS_SUPERUSER]: z.coerce.boolean(),
        [SchemaKey.DATASOURCE]: z.any().optional(), // never when superUser
      })
      .superRefine((value, ctx) => {
        const isSuperUser = value[SchemaKey.IS_SUPERUSER];
        const datasource = value[SchemaKey.DATASOURCE];
        if (!isSuperUser && isEmpty(datasource)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('formRule.required'),
            path: [SchemaKey.DATASOURCE],
          });
          return z.NEVER;
        }
      }),
    [SchemaKey.LAST_LOGIN]: z.coerce.date().nullish(),
    extra: z
      .object({
        ...dynamicUserSettings?.reduce((acc, key) => {
          acc[key] = z.string().nullish();
          return acc;
        }, {}),
      })
      .nullish(),
  };

  return z.object(schema);
};
