import './styles.scss';

import { CloseOutlined } from '@ant-design/icons';
import AkinonFlex from '@components/AkinonFlex';
import { openNotification } from '@components/akinonNotification';
import Modal from '@components/Modal/Modal';
import { useGetAnnouncementsQuery } from '@services/api/hooks/useGetAnnouncementsQuery';
import { usePostNotificationStateMutation } from '@services/api/hooks/usePostNotificationStateMutation';
import { IconX } from '@tabler/icons-react';
import { Alert, notification, Tooltip, Typography } from 'antd';
import first from 'lodash/first';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { AnnouncementPopupContent } from './AnnouncementPopupContent';

const { Text, Paragraph, Title } = Typography;

const Announcements = () => {
  const { t } = useTranslation();
  const { postNotificationState } = usePostNotificationStateMutation();
  const { announcements: popupAnnouncementsData, refetchAnnouncements: refetchPopupAnnouncements } =
    useGetAnnouncementsQuery({
      params: {
        ntype: 2,
        limit: 1,
        is_seen: false,
      },
    });

  const {
    announcements: bannerAnnouncementsData,
    refetchAnnouncements: refetchBannerAnnouncements,
  } = useGetAnnouncementsQuery({
    params: {
      ntype: 3,
      limit: 1,
      is_seen: false,
    },
  });

  const { announcements: pushNotifications } = useGetAnnouncementsQuery({
    params: {
      ntype: 1,
      limit: 1,
      is_seen: false,
      is_read: false,
    },
    queryOptions: {
      refetchInterval: 30 * 1000,
    },
  });
  const modalRef = useRef();

  const popupAnnouncement = first(popupAnnouncementsData);
  const bannerAnnouncement = first(bannerAnnouncementsData);
  const pushNotification = first(pushNotifications);

  useEffect(() => {
    if (pushNotification) {
      openNotification({
        description: (
          <div className="max-h-32 overflow-y-auto">
            <Paragraph
              className="text-white"
              ellipsis={{
                expandable: true,
                symbol: <span>{t('more')}</span>,
                rows: 4,
              }}
            >
              {pushNotification.message}
            </Paragraph>
          </div>
        ),

        message: (
          <Title
            level={4}
            ellipsis={{
              tooltip: <span> {pushNotification.title} </span>,
            }}
            className="font-bold !text-white mb-1"
          >
            {pushNotification.title}
          </Title>
        ),
        type: 'success',
        icon: <div />,
        key: pushNotification.id,
        closeIcon: (
          <CloseOutlined
            as="button"
            className="text-white"
            onClick={(event) => {
              event.stopPropagation();
              postNotificationState(
                {
                  notificationId: pushNotification.id,
                  requestBody: {
                    is_read: true,
                    is_seen: true,
                  },
                },
                {
                  onSuccess: () => {
                    notification.close(pushNotification.id);
                  },
                }
              );
            }}
          />
        ),
        onClose: (e) => {
          postNotificationState({
            notificationId: pushNotification.id,
            requestBody: {
              is_read: false,
              is_seen: true,
            },
          });
        },
      });
    }
  }, [pushNotification]);

  useEffect(() => {
    if (popupAnnouncement) {
      const modal = Modal.confirm({
        icon: null,
        closable: false,
        maskClosable: false,
        keyboard: false,
        width: 800,
        className: 'akinon-announcement-popup__modal',
        cancelButtonProps: {
          hidden: true,
        },
        okButtonProps: {
          hidden: true,
        },
        content: (
          <AnnouncementPopupContent
            onClose={() => {
              postNotificationState(
                {
                  notificationId: popupAnnouncement.id,
                  requestBody: {
                    is_seen: true,
                    is_read: true,
                  },
                },
                {
                  onSuccess: refetchPopupAnnouncements,
                }
              );
              modalRef.current.destroy();
            }}
            announcement={popupAnnouncement}
          />
        ),
      });
      modalRef.current = modal;
    }
    return () => {
      if (modalRef.current) {
        modalRef.current.destroy();
      }
    };
  }, [popupAnnouncement]);

  if (bannerAnnouncement) {
    const { title, message } = bannerAnnouncement ?? {};
    return (
      <Alert
        icon={() => null}
        closeIcon={<IconX />}
        className="akinon-announcement-banner"
        message={
          <>
            <AkinonFlex
              alignItems="center"
              direction="column"
              className="md:flex-row md:gap-1 w-full"
            >
              <Text strong>{title && title + ': '}</Text>
              <Tooltip
                title={message}
                overlayInnerStyle={{ minWidth: 300, overflowY: 'auto', maxHeight: 200 }}
                overlayClassName="banner-announcement-tooltip"
              >
                <Paragraph ellipsis={true} className="akinon-announcement-banner__text m-0">
                  {message}
                </Paragraph>
              </Tooltip>
              <IconX
                className="right-2 absolute cursor-pointer text-slate-gray"
                onClick={() => {
                  postNotificationState(
                    {
                      notificationId: bannerAnnouncement.id,
                      requestBody: {
                        is_seen: true,
                        is_read: true,
                      },
                    },
                    {
                      onSuccess: refetchBannerAnnouncements,
                    }
                  );
                }}
              />
            </AkinonFlex>
          </>
        }
        banner
      />
    );
  }

  return null;
};

export default Announcements;
