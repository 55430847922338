import { useOrderItemsQuery } from '@services/api/hooks/useOrderItemsQuery';
import { usePaginatedPackageItemsQuery } from '@services/api/hooks/usePaginatedPackageItemsQuery';
import { usePaginatedProductImagesQuery } from '@services/api/hooks/usePaginatedProductImagesQuery';
import { usePaginatedProductOffersQuery } from '@services/api/hooks/usePaginatedProductOffersQuery';
import { useProductsQuery } from '@services/api/hooks/usePaginatedProductsQuery';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

export const useDataSource = ({
  pagination,
  filters,
  shippingDetail,
  packageDetail,
  packageId,
}) => {
  const { packageItems, isFetchingPackageItems, totalPackageItems } = usePaginatedPackageItemsQuery(
    {
      pagination,
      filters,
      params: {
        package: packageId,
      },
      queryOptions: {
        enabled: !isEmpty(packageId),
      },
    }
  );

  const orderItemIds = packageItems?.map((item) => item.order_item);

  const { orderItems, isFetchingOrderItems } = useOrderItemsQuery({
    params: {
      id__in: orderItemIds?.join(','),
      limit: orderItemIds?.length,
    },
    queryOptions: {
      enabled: !isEmpty(orderItemIds),
    },
  });

  const packageItemsWithOrderItems =
    packageItems?.map((item) => {
      const orderItem = orderItems?.find((orderItem) => orderItem.id === item.order_item);

      return {
        ...item,
        ...orderItem,
      };
    }) || [];

  const productOfferIds = packageItemsWithOrderItems?.map((item) => item.product_offer);

  const { productOffers, isFetchingProductOffers } = usePaginatedProductOffersQuery({
    params: {
      id__in: productOfferIds?.join(','),
      limit: productOfferIds?.length,
    },
    queryOptions: {
      enabled: !isEmpty(productOfferIds),
    },
  });

  const packageItemsWithProductOffers = packageItemsWithOrderItems?.map((item) => {
    const productOffer = productOffers?.find(
      (productOffer) => productOffer.id === item.product_offer
    );

    return {
      ...productOffer,
      ...item,
    };
  });

  const productIds = productOffers?.map((productOffer) => productOffer.product).filter(Boolean);

  const { productImages, isFetchingProductImages } = usePaginatedProductImagesQuery({
    queryOptions: {
      enabled: Boolean(productIds?.length),
    },
    params: {
      limit: productIds?.length,
      parent__in: uniq(productIds).join(','),
      order: 0,
    },
  });

  const packageItemsWithProductImages = packageItemsWithProductOffers?.map((item) => {
    const productImage = productImages?.find(
      (productImage) => productImage.parent === item.product
    );

    return {
      ...item,
      product_image: productImage?.image,
    };
  });

  const { products, isProductsLoading } = useProductsQuery({
    params: {
      id__in: productIds?.join(','),
      limit: productIds?.length,
    },
    queryOptions: {
      enabled: !isEmpty(productIds),
    },
  });

  const packageItemsWithProducts = packageItemsWithProductImages?.map((item) => {
    const product = products?.find((product) => product.id === item.product);

    return {
      ...item,
      product,
      shipping_company: packageDetail?.shipping_company,
      delivery_type: shippingDetail?.shipping_company?.name,
      invoice_number: packageDetail?.invoice_number,
      tracking_number: packageDetail?.tracking_number,
      tracking_url: packageDetail?.tracking_url,
    };
  });

  const isDataSourceLoading =
    isFetchingPackageItems ||
    isFetchingOrderItems ||
    isFetchingProductOffers ||
    isFetchingProductImages;

  return {
    dataSource: packageItemsWithProducts,
    isDataSourceLoading,
    isProductsLoading,
    total: totalPackageItems,
  };
};
