import { get } from 'lodash';

import { useDynamicSettingsQuery } from './useDynamicSettingsQuery';

const useImageDimensionsDataSourceQuery = () => {
  const { dynamicSettings: maxImageDimensionSettings } = useDynamicSettingsQuery({
    params: {
      key: 'MAX_IMAGE_DIMENSIONS',
      is_active: true,
    },
  });
  const { dynamicSettings: minImageDimensionSettings } = useDynamicSettingsQuery({
    params: {
      key: 'MIN_IMAGE_DIMENSIONS',
      is_active: true,
    },
  });

  const maxImageDimensions = maxImageDimensionSettings?.find(
    (setting) => setting.key === 'MAX_IMAGE_DIMENSIONS'
  )?.value;
  const minImageDimensions = minImageDimensionSettings?.find(
    (setting) => setting.key === 'MIN_IMAGE_DIMENSIONS'
  )?.value;

  const maxWidth = get(maxImageDimensions, 'WIDTH');
  const maxHeight = get(maxImageDimensions, 'HEIGHT');
  const minWidth = get(minImageDimensions, 'WIDTH');
  const minHeight = get(minImageDimensions, 'HEIGHT');

  return {
    maxWidth,
    maxHeight,
    minWidth,
    minHeight,
    dimensionBounds: { maxWidth, maxHeight, minWidth, minHeight },
  };
};

export default useImageDimensionsDataSourceQuery;
