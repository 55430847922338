import useAppNavigate from '@root/hooks/useAppNavigate';
import PropTypes from 'prop-types';
import React from 'react';

// TODO Get rid of me, prefer <Link> from react-router-dom
const Anchor = ({ children, href, wrapHref = true, className = '', ...rest }) => {
  const navigate = useAppNavigate();

  if (href) {
    if (wrapHref) {
      rest = {
        ...rest,
        onClick: () => navigate(href),
      };
    } else {
      rest = {
        ...rest,
        href,
      };
    }
  }
  className = 'pointer '.concat(className);
  return (
    <a {...rest} className={className} data-testid="anchor-element">
      {children}
    </a>
  );
};

Anchor.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

export default Anchor;
