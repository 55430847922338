import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { getProductRevisionsUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { client } from '@services/api/client';

/**
 * @param {Object} [args]
 * @param {Object} [args.params]
 * @param {import('@tanstack/react-query').UseQueryOptions} [args.queryOptions]
 * @param {(data: any) => void} [args.onSuccess]
 */
export function usePaginatedProductRevisionsQuery({
  queryOptions,
  params,
  onSuccess,
  pagination,
  filters,
} = {}) {
  const { data, isFetching, refetch } = useAkinonQuery({
    queryKey: [QueryKey.PRODUCT_REVISIONS, params, filters, pagination],
    async queryFn() {
      const response = await client.get(getProductRevisionsUrl, {
        params: {
          ...getRequestPaginationParams(pagination),
          ...getRequestFiltersParams(filters),
          ...params,
        },
      });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    productRevisions: data?.results,
    totalProductRevisions: data?.count,
    isFetchingProductRevisions: isFetching,
    refetchProductRevisions: refetch,
  };
}
