import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { getProductImagesUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useQuery } from '@tanstack/react-query';

import { client } from '../client';

export function useProductImagesQuery({ queryOptions, params }) {
  const { data, isFetching, isLoading, error } = useQuery({
    queryKey: [QueryKey.PRODUCT_IMAGES, params],
    queryFn: () =>
      client.get(getProductImagesUrl, {
        params: {
          ...params,
        },
      }),
    ...queryOptions,
  });

  return {
    productImages: data?.results,
    isFetchingProductImages: isFetching,
    isLoadingProductImages: isLoading,
    productImagesError: error,
  };
}
export function usePaginatedProductImagesQuery({ pagination, filters, params, queryOptions }) {
  const { data, isFetching, error } = useQuery({
    queryKey: [QueryKey.PRODUCT_IMAGES, pagination, filters, params],
    queryFn: () =>
      client.get(getProductImagesUrl, {
        params: {
          ...params,
          ...getRequestPaginationParams(pagination),
          ...getRequestFiltersParams(filters),
        },
      }),
    ...queryOptions,
  });

  return {
    productImagesResponse: data,
    productImages: data?.results,
    isFetchingProductImages: isFetching,
    totalProductImages: data?.count,
    productImagesError: error,
  };
}
