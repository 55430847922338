import { patchProductStock } from '@services/api';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';

export function usePatchProductStockMutation({ mutationOptions = {} }) {
  const { mutate, mutateAsync, isLoading } = useAkinonMutation({
    mutationFn: patchProductStock,
    ...mutationOptions,
  });

  return {
    patchProductStock: mutate,
    patchProductStockAsync: mutateAsync,
    isPatchProductStockLoading: isLoading,
  };
}
