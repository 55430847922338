import { getRequestFiltersParams } from '@common/index';
import { oAuthProviderBySlugUrl, oAuthProvidersUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { client } from '@services/api/client';

import { useAkinonQuery } from '../adapters/useAkinonQuery';

export function useOAuthProvidersQuery({ queryOptions, filters } = {}) {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.OAUTH_PROVIDERS, filters],
    queryFn: () =>
      client.get(oAuthProvidersUrl, {
        params: {
          ...getRequestFiltersParams(filters),
        },
      }),
    ...queryOptions,
  });

  return {
    providers: data,
    isFetchingProviders: isFetching,
  };
}

export function useOAuthOptionsQuery({ queryOptions } = {}) {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.OAUTH_OPTIONS],
    queryFn: () => client.options(oAuthProvidersUrl),
    ...queryOptions,
  });

  return {
    oAuthOptions: data,
    isFetchingOauthOptions: isFetching,
  };
}

export function useOAuthProviderBySlugQuery({ queryOptions, slug } = {}) {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.OAUTH_PROVIDERS_BY_SLUG, slug],
    queryFn: () => client.get(oAuthProviderBySlugUrl, { urlParams: { slug } }),
    ...queryOptions,
  });

  return {
    provider: data,
    isFetchingProvider: isFetching,
  };
}
