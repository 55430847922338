import { createSelectOptions } from '@common/index';
import { WidgetPropsKeys, WidgetType } from '@constants/commontypes';
import useStore from '@zustand-store/index';
import {
  setVariantsListAttributeMetaFieldsSelector,
  variantsListAttributeMetaFieldsSelector,
} from '@zustand-store/selectors/preOffer';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { isModalVisibleMapInitialState, ProductsToBeAddedTableFormColumnKey } from '../../common';
import FormModal from '../FormModal';

const ActionButtonsFormModalList = ({
  dataSource,
  productsToBeAddedTableForm,
  isModalVisibleMap,
  setIsModalVisibleMap,
  productPricesOptions,
  isProductPricesOptionsLoading,
}) => {
  const { t } = useTranslation('ProductsAndCategories');
  const variantsListAttributeMetaFields = useStore(variantsListAttributeMetaFieldsSelector);
  const setVariantsListAttributeMetaFields = useStore(setVariantsListAttributeMetaFieldsSelector);

  useEffect(() => {
    const newVariantsListAttributeMetaFields = variantsListAttributeMetaFields?.map((metaField) => {
      metaField.widgetProps[WidgetPropsKeys.SHOW_SEARCH] = true;
      return metaField;
    });

    setVariantsListAttributeMetaFields([...newVariantsListAttributeMetaFields]);
  }, []);

  const currencyOptions = createSelectOptions(
    productPricesOptions?.actions?.POST.currency_type.choices,
    {
      valueKey: 'value',
      labelKey: 'display_name',
    }
  );

  const handleSetInvisible = () => {
    setIsModalVisibleMap({
      ...isModalVisibleMapInitialState,
    });
  };

  return (
    <>
      <FormModal
        isVisible={isModalVisibleMap[ProductsToBeAddedTableFormColumnKey.PRICE]}
        title={t('bulk.update.price')}
        updateFormTitle={t('update.price')}
        description={t('bulk.update.price.description')}
        productsToBeAddedTableForm={productsToBeAddedTableForm}
        setInvisible={handleSetInvisible}
        dataSource={dataSource}
        formMeta={[
          {
            key: ProductsToBeAddedTableFormColumnKey.RETAIL_PRICE,
            label: t('retail.price'),
            widget: WidgetType.INPUT,
          },
          {
            key: ProductsToBeAddedTableFormColumnKey.PRICE,
            label: t('selling.price'),
            widget: WidgetType.INPUT,
          },
          {
            key: ProductsToBeAddedTableFormColumnKey.CURRENCY_TYPE,
            label: 'Currency',
            widget: WidgetType.SELECT,
            options: currencyOptions,
            isLoading: isProductPricesOptionsLoading,
          },
          {
            key: ProductsToBeAddedTableFormColumnKey.TAX_RATE,
            label: 'VAT',
            widget: WidgetType.INPUT,
          },
        ]}
      />
      <FormModal
        isVisible={isModalVisibleMap[ProductsToBeAddedTableFormColumnKey.STOCK]}
        title={t('bulk.update.stock')}
        updateFormTitle={t('update.stock.amount')}
        description={t('bulk.update.stock.description')}
        productsToBeAddedTableForm={productsToBeAddedTableForm}
        setInvisible={handleSetInvisible}
        dataSource={dataSource}
        formMeta={[
          {
            key: ProductsToBeAddedTableFormColumnKey.STOCK,
            label: t('total.product.stocks'),
            widget: WidgetType.INPUT,
          },
        ]}
      />
    </>
  );
};

export default ActionButtonsFormModalList;
