import { openNotification } from '@components/akinonNotification';
import { OfferApprovalStatus } from '@constants/commontypes';
import { RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@root/hooks/useAppNavigate';
import useStore from '@zustand-store/index';
import { selectedRelatedProductSelector } from '@zustand-store/selectors/preOffer';
import first from 'lodash/first';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { useTranslation } from 'react-i18next';

import { FormKey } from '../common';

export function useOnSubmit({
  preOffer,
  priceLists,
  stockLists,
  dataSources,
  patchPreOffers,
  submitPreOffer,
  approvePreOffer,
  setIsRejectModalVisible,
  isSuperUser,
  variantListAttributeFields,
}) {
  const { t } = useTranslation('ProductsAndCategories');
  const navigate = useAppNavigate();

  const selectedRelatedProduct = useStore(selectedRelatedProductSelector);

  const variantsListAttributeKeys = variantListAttributeFields?.map(({ key }) => key);
  const preOfferApprovalStatus = preOffer?.approval_status;

  const onSubmit = ({ formValues, isRejectAction = false, isSubmitAction = false }) => {
    const pricesKeys = [
      FormKey.CURRENCY_TYPE,
      FormKey.PRICE,
      FormKey.RETAIL_PRICE,
      FormKey.TAX_RATE,
    ];
    const stocksKeys = [FormKey.STOCK];
    const priceListMasterId = priceLists.find(({ is_master }) => is_master)?.id;
    const stockListMasterId = stockLists.find(({ is_master }) => is_master)?.id;
    const sellerDescription = get(formValues, FormKey.SELLER_DESCRIPTION);
    const commonRequestBody = {
      attribute_set: preOffer?.attribute_set,
      datasource: first(dataSources)?.id,
      description: sellerDescription,
    };
    const requestBodyObject = {
      ...commonRequestBody,
      sku: get(formValues, FormKey.SKU),
      attributes: {
        ...variantsListAttributeKeys?.reduce?.(
          (preOfferAttributes, attributeKey) => ({
            ...preOfferAttributes,
            [attributeKey]: get(formValues, attributeKey),
          }),
          {}
        ),
        SELLER_BARCODE: get(formValues, FormKey.SELLER_BARCODE),
        SELLER_DESCRIPTION: sellerDescription,
        SELLER_BASECODE: preOffer?.base_code,
      },
      prices: [
        {
          price_list: priceListMasterId,
          ...Object.entries(pick(formValues, pricesKeys)).reduce(
            (priceItem, [priceKey, priceValueMap]) => ({
              ...priceItem,
              [priceKey]: priceValueMap,
            }),
            {}
          ),
        },
      ],
      stocks: [
        {
          stock_list: stockListMasterId,
          ...Object.entries(pick(formValues, stocksKeys)).reduce(
            (stockItem, [stockKey, stockValueMap]) => ({
              ...stockItem,
              [stockKey]: stockValueMap,
            }),
            {}
          ),
        },
      ],
      product: isSuperUser ? selectedRelatedProduct?.id : preOffer?.product,
    };

    patchPreOffers(
      {
        preOfferId: preOffer?.id,
        requestBody: requestBodyObject,
      },
      {
        onSuccess() {
          if (isSuperUser) {
            const requestBody = selectedRelatedProduct
              ? { product: selectedRelatedProduct.id }
              : {};
            if (isRejectAction) {
              setIsRejectModalVisible(true);
            } else {
              if (preOfferApprovalStatus === OfferApprovalStatus.PENDING_APPROVAL) {
                approvePreOffer(
                  { preOfferId: preOffer?.id, requestBody },
                  {
                    onSuccess() {
                      navigate(RouteUrls.productsAndCategories.offerList.pendingApproval);
                      openNotification({
                        message: t('transaction_success'),
                        description: t('offer_approved_success'),
                        type: 'success',
                      });
                    },
                    onError(error) {
                      openNotification({
                        message: t('transaction_failed'),
                        description: error.response?.data?.detail || t('offer_approval_error'),
                        type: 'error',
                      });
                    },
                  }
                );
              } else if (preOfferApprovalStatus === OfferApprovalStatus.APPROVAL_REQUIRED) {
                submitPreOffer(
                  { preOfferId: preOffer?.id, requestBody },
                  {
                    onSuccess() {
                      approvePreOffer(
                        { preOfferId: preOffer?.id, requestBody },
                        {
                          onSuccess() {
                            navigate(RouteUrls.productsAndCategories.offerList.pendingApproval);
                            openNotification({
                              message: t('transaction_success'),
                              description: t('offer_approved_success'),
                              type: 'success',
                            });
                          },
                          onError(error) {
                            openNotification({
                              message: t('transaction_failed'),
                              description:
                                error.response?.data?.detail || t('offer_approval_error'),
                              type: 'error',
                            });
                          },
                        }
                      );
                    },
                    onError(error) {
                      openNotification({
                        message: t('transaction_failed'),
                        description: error.response?.data?.detail || t('offer_approval_error'),
                        type: 'error',
                      });
                    },
                  }
                );
              } else {
                navigate(RouteUrls.productsAndCategories.offerList.pendingApproval);
                openNotification({
                  message: t('transaction_success'),
                  description: t('changes_saved'),
                  type: 'success',
                });
              }
            }
          } else {
            if (isSubmitAction) {
              const requestBody = selectedRelatedProduct
                ? { product: selectedRelatedProduct.id }
                : {};
              submitPreOffer(
                { preOfferId: preOffer?.id, requestBody },
                {
                  onSuccess() {
                    navigate(RouteUrls.productsAndCategories.offerList.pendingApproval);
                    openNotification({
                      message: t('transaction_success'),
                      description: t('offer_sent_for_approval_message'),
                      type: 'success',
                    });
                  },
                  onError() {
                    openNotification({
                      message: t('transaction_failed'),
                      description: t('offer_sent_for_approval_error'),
                      type: 'error',
                    });
                  },
                }
              );
            } else {
              openNotification({
                message: t('transaction_success'),
                description: t('changes_saved'),
                type: 'success',
              });
            }
          }
        },
        onError() {
          openNotification({
            message: t('transaction_failed'),
            description: t('changes_not_saved'),
            type: 'error',
            t,
          });
        },
      }
    );
  };

  return onSubmit;
}
