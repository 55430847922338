import If from '@components/If';
import Show from '@components/Show';
import Box from '@components/utility/box';
import { Divider, Skeleton } from 'antd';
import clsx from 'clsx';
import dompurify from 'dompurify';
import moment from 'moment';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { ContentType } from '../common';

export const ContentBox = ({ t, message }) => {
  const { content_type, content_object, message_content, created_date } = message ?? {};

  const getMessageContentFullName = () => {
    switch (content_type) {
      case ContentType.USER:
        return [content_object?.first_name, content_object?.last_name].join(' ');
      case ContentType.DATASOURCE:
        return `(${t('you')})`;
      default:
        return '';
    }
  };

  const sanitizer = dompurify.sanitize;
  const messageContentFullName = getMessageContentFullName();
  const messageCreatedDate = moment(created_date).utc(true).format('DD.MM.YYYY HH:mm');

  return (
    <div
      className={clsx({
        'message-content__user-box': content_type === ContentType.USER,
        'message-content__datasource-box': content_type === ContentType.DATASOURCE,
      })}
    >
      <b>{`${messageContentFullName}: ${messageCreatedDate}`}</b>
      <div dangerouslySetInnerHTML={{ __html: sanitizer(message_content) }} />
    </div>
  );
};

export const MessagesBox = ({ isConversationLoading, conversation }) => {
  const { t } = useTranslation('Messages');

  const isLastMessage = (index) => {
    return index === conversation?.message_set.length - 1;
  };

  return (
    <Box className="box-primary form-box messages-box" title={t('messages')}>
      <If
        condition={isConversationLoading}
        then={<Skeleton active />}
        otherwise={conversation?.message_set.map((message, index) => {
          return (
            <Fragment key={index}>
              <ContentBox t={t} message={message} />
              <Show when={!isLastMessage(index)}>
                <Divider />
              </Show>
            </Fragment>
          );
        })}
      />
    </Box>
  );
};
