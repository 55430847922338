import { UserRole } from '@constants/auth';
import { WidgetType } from '@constants/commontypes';
import { useTranslation } from 'react-i18next';

export const StaticFilterKey = {
  ORDER_NUMBER: 'order__number',
  RECONCILIATION_NAME: 'reconciliation__name',
  RECONCILIATION_NO: 'reconciliation__no',
  PRODUCT_CODE: 'product__code',
  STATUS: 'status',
  START_DATE_GTE: 'start_date__gte',
  START_DATE_LTE: 'start_date__lte',
  END_DATE_GTE: 'end_date__gte',
  END_DATE_LTE: 'end_date__lte',
  SUPPLIER: 'supplier',
};

export function useStaticFilters({ userRole }) {
  const { t } = useTranslation('Finance');
  const isSuperUser = userRole === UserRole.SUPER_USER;

  return {
    staticFiltersFormMetaFields: [
      {
        key: StaticFilterKey.ORDER_NUMBER,
        placeholder: t('order_number_1'),
      },
      isSuperUser && {
        key: StaticFilterKey.RECONCILIATION_NAME,
        placeholder: t('seller.name'),
      },
      {
        key: StaticFilterKey.RECONCILIATION_NO,
        placeholder: t('reconciliation_number'),
      },
      {
        key: StaticFilterKey.PRODUCT_CODE,
        placeholder: t('sku'),
      },
      {
        key: StaticFilterKey.STATUS,
        widget: WidgetType.SELECT,
        placeholder: t('status'),
        options: [
          { label: t('active'), value: true },
          { label: t('passive'), value: false },
        ],
        widgetProps: {
          showSearch: true,
          optionFilterProp: 'label',
        },
      },
      {
        widget: WidgetType.DATE_PICKER,
        key: StaticFilterKey.START_DATE_GTE,
        placeholder: t('first_sale_start_date'),
      },
      {
        widget: WidgetType.DATE_PICKER,
        key: StaticFilterKey.START_DATE_LTE,
        placeholder: t('last_sale_start_date'),
      },
      {
        widget: WidgetType.DATE_PICKER,
        key: StaticFilterKey.END_DATE_GTE,
        placeholder: t('first_sale_end_date'),
      },
      {
        widget: WidgetType.DATE_PICKER,
        key: StaticFilterKey.END_DATE_LTE,
        placeholder: t('last_sale_end_date'),
      },
      {
        key: StaticFilterKey.SUPPLIER,
        placeholder: t('supplier_company_select'),
      },
    ]
      .filter(Boolean)
      .map((filter, pk) => ({ ...filter, active: true, pk })),
    isStaticFiltersLoading: false,
  };
}
