import { CloseOutlined } from '@ant-design/icons';
import AkinonSpin from '@components/AkinonSpin';
import Drawer from '@components/Drawer';
import { Col, Divider, Image, Row, Typography } from 'antd';
import capitalize from 'lodash/capitalize';
import map from 'lodash/map';
import React from 'react';
import { useTranslation } from 'react-i18next';

const { Text, Title } = Typography;

const ProductInfoDrawer = ({ open, onClose, productDetail, isProductDetailLoading }) => {
  const { t } = useTranslation('Orders');
  return (
    <Drawer
      className="package-detail__product-drawer"
      open={open}
      closable={false}
      width={600}
      mask={false}
    >
      <Row justify="space-between">
        <Col>
          <Title className="package-detail__product-drawer-title">{t('productInformation')}</Title>
        </Col>
        <Col>
          <CloseOutlined className="package-detail__product-drawer-close-icon" onClick={onClose} />
        </Col>
      </Row>
      <div className="package-detail__product-drawer-divider-box">
        <Divider className="package-detail__product-drawer-divider" />
      </div>
      <AkinonSpin spinning={isProductDetailLoading} tip={t('loading')}>
        <Row className="package-detail__product-drawer-image-row">
          <Col span={12} offset={12}>
            <Image src={productDetail?.product_image} />
          </Col>
        </Row>
        <Row className="package-detail__product-drawer-product-info-row">
          <Text className="package-detail__product-drawer-product-info-label">
            {t('product_name')}
          </Text>
          <Text className="package-detail__product-drawer-product-info-value">
            {productDetail?.product?.name}
          </Text>
        </Row>
        <Row className="package-detail__product-drawer-product-info-row">
          <Text className="package-detail__product-drawer-product-info-label">
            {t('base.code')}
          </Text>
          <Text className="package-detail__product-drawer-product-info-value">
            {productDetail?.product?.base_code}
          </Text>
        </Row>
        <Row className="package-detail__product-drawer-product-info-row">
          <Text className="package-detail__product-drawer-product-info-label">
            {capitalize(t('barcode'))}
          </Text>
          <Text className="package-detail__product-drawer-product-info-value">
            {productDetail?.attributes?.SELLER_BARCODE}
          </Text>
        </Row>
        <Row className="package-detail__product-drawer-product-info-row">
          <Text className="package-detail__product-drawer-product-info-label">
            {t('offer_code')}
          </Text>
          <Text className="package-detail__product-drawer-product-info-value">
            {productDetail?.sku}
          </Text>
        </Row>
        <Row className="package-detail__product-drawer-product-info-row">
          <Text className="package-detail__product-drawer-product-info-label">
            {t('shipping.company')}
          </Text>
          <Text className="package-detail__product-drawer-product-info-value">
            {productDetail?.shipping_company}
          </Text>
        </Row>
        {map(productDetail?.product?.attributes, ({ name, attribute_value, attribute_label }) => (
          <Row key={name} className="order-detail__product-drawer-product-info-row">
            <Text className="order-detail__product-drawer-product-info-label">{name}</Text>
            <Text className="order-detail__product-drawer-product-info-value">
              {attribute_label ?? attribute_value ?? '-'}
            </Text>
          </Row>
        ))}
      </AkinonSpin>
    </Drawer>
  );
};

export default ProductInfoDrawer;
