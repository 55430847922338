import './styles.scss';

import noImage from '@assets/images/noimage.png';
import { getSmallImageSource } from '@common/image';
import { Image } from 'antd';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const SmallColumnImage = ({ preview, src, onClick, srcResolver }) => {
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    srcResolver && srcResolver(setImageSrc, noImage);
  }, []);

  useEffect(() => {
    const isSrcBase64 = src?.includes('data:image');
    const modifiedSrc = isSrcBase64 ? src : !isEmpty(src) ? getSmallImageSource(src) : noImage;

    setImageSrc(modifiedSrc);
  }, [src]);

  useEffect(() => {
    srcResolver && srcResolver(setImageSrc, noImage);
  }, [srcResolver]);

  return (
    <div onClick={onClick} className={clsx('small-column-image')}>
      <Image preview={preview} className="small-column-image__image" src={imageSrc} />
    </div>
  );
};

SmallColumnImage.propTypes = {
  preview: PropTypes.bool,
  src: PropTypes.string,
  onClick: PropTypes.func,
  srcResolver: PropTypes.func,
};

SmallColumnImage.defaultProps = {
  preview: false,
  customHandleShowImageClick: null,
  onClick: noop,
  srcResolver: null,
};

export default SmallColumnImage;
