import { submitPreOffer } from '@services/api';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';

export function useSubmitPreOfferMutation() {
  const mutationResponse = useAkinonMutation({
    mutationFn: submitPreOffer,
  });

  return {
    ...mutationResponse,
    submitPreOffer: mutationResponse.mutate,
    isSubmitPreOfferMutating: mutationResponse.isLoading,
  };
}
