import { RouteUrls } from '@constants/routeUrls';
import { useEffect } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

const scrollRestorationDisabledPaths = [RouteUrls.support.supportTicketDetail];

function useScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    const isEnableScrollRestoration = !scrollRestorationDisabledPaths.some((path) =>
      matchPath({ path }, pathname)
    );
    if (isEnableScrollRestoration) {
      window.scrollTo({ behavior: 'smooth', top: 0, left: 0 });
    }
  }, [pathname, scrollRestorationDisabledPaths]);
}

export default useScrollToTop;
