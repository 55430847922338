import './styles.scss';

import Field from './Field';

const AkinonFieldList = ({ fieldKeys = ['key', 'value'], ...rest }) => {
  return (
    <>
      {fieldKeys.map((fieldKey) => {
        return <Field key={fieldKey} fieldKey={fieldKey} {...rest} />;
      })}
    </>
  );
};

export default AkinonFieldList;
