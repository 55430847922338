import { useCityQuery } from '@services/api/hooks/useCityQuery';
import { useCountryQuery } from '@services/api/hooks/useCountryQuery';
import { useCustomerAddressQuery } from '@services/api/hooks/useCustomerAddressQuery';
import { useDistrictQuery } from '@services/api/hooks/useDistrictQuery';
import { useTownshipQuery } from '@services/api/hooks/useTownshipQuery';
import { useOrderDetailQuery } from '@services/hooks/orders/useOrderDetailQuery';
import isEmpty from 'lodash/isEmpty';

export const useOrderItemDetail = ({ orderId }) => {
  const { data: order, isLoading: isLoadingOrderDetail } = useOrderDetailQuery({ orderId });

  const { data: shippingAddress, isLoading: isLoadingCustomerAddress } = useCustomerAddressQuery({
    addressId: order?.shipping_address,
    queryOptions: {
      enabled: Boolean(order?.shipping_address),
    },
  });

  const { data: billingAddress, isLoading: isLoadingBillingAddress } = useCustomerAddressQuery({
    addressId: order?.billing_address,
    queryOptions: {
      enabled: Boolean(order?.billing_address),
    },
  });
  const { country: shippingCountry, isCountryLoading: isShippingCountryLoading } = useCountryQuery({
    countryId: shippingAddress?.country,
    queryOptions: {
      enabled: !isEmpty(shippingAddress),
    },
  });
  const { city: shippingCity, isCityLoading: isShippingCityLoading } = useCityQuery({
    cityId: shippingAddress?.city,
    queryOptions: {
      enabled: !isEmpty(shippingAddress),
    },
  });
  const { township: shippingTownship, isTownshipLoading: isShippingTownshipLoading } =
    useTownshipQuery({
      townshipId: shippingAddress?.township,
      queryOptions: {
        enabled: !isEmpty(shippingAddress),
      },
    });
  const { district: shippingDistrict, isDistrictLoading: isShippingDistrictLoading } =
    useDistrictQuery({
      districtId: shippingAddress?.district,
      queryOptions: {
        enabled: !isEmpty(shippingAddress),
      },
    });
  const { country: billingCountry, isCountryLoading: isBillingCountryLoading } = useCountryQuery({
    countryId: billingAddress?.country,
    queryOptions: {
      enabled: !isEmpty(billingAddress),
    },
  });
  const { city: billingCity, isCityLoading: isBillingCityLoading } = useCityQuery({
    cityId: billingAddress?.city,
    queryOptions: {
      enabled: !isEmpty(billingAddress),
    },
  });
  const { township: billingTownship, isTownshipLoading: isBillingTownshipLoading } =
    useTownshipQuery({
      townshipId: billingAddress?.township,
      queryOptions: {
        enabled: !isEmpty(billingAddress),
      },
    });
  const { district: billingDistrict, isDistrictLoading: isBillingDistrictLoading } =
    useDistrictQuery({
      districtId: billingAddress?.district,
      queryOptions: {
        enabled: !isEmpty(billingAddress),
      },
    });

  const loading =
    isLoadingOrderDetail ||
    isLoadingCustomerAddress ||
    isLoadingBillingAddress ||
    isShippingCountryLoading ||
    isShippingCityLoading ||
    isShippingTownshipLoading ||
    isShippingDistrictLoading ||
    isBillingCountryLoading ||
    isBillingCityLoading ||
    isBillingTownshipLoading ||
    isBillingDistrictLoading;

  const orderDetail = {
    ...order,
    shippingCountry,
    shippingCity,
    shippingTownship,
    shippingDistrict,
    billingCountry,
    billingCity,
    billingTownship,
    billingDistrict,
    shipping_address_obj: shippingAddress,
    billing_address_obj: billingAddress,
  };
  return {
    orderDetail,
    isLoadingOrderDetail: loading,
  };
};
