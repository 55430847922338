import { usePackageStatusesMutation } from '@services/hooks/packages/usePackageStatusesMutation';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const usePackageStatuses = () => {
  const { t } = useTranslation('Orders');
  const [statuses, setStatuses] = useState({});
  const {
    data: packageStatuses,
    mutate: mutatePackageStatuses,
    isLoading: isPackageStatusesLoading,
    error: isPackageStatusesError,
  } = usePackageStatusesMutation();

  useEffect(() => {
    if (!packageStatuses) return;
    const statusData = packageStatuses?.POST?.status?.choices?.map((choice) => {
      const display_name = t(choice.display_name?.toString().replaceAll(' ', '.').toLowerCase());
      return {
        [choice.value]: display_name,
      };
    });

    setStatuses(statusData.reduce((acc, cur) => ({ ...acc, ...cur }), {}));
  }, [packageStatuses]);

  return {
    packageStatuses: statuses,
    isPackageStatusesLoading,
    isPackageStatusesError,
    mutatePackageStatuses,
  };
};
