import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { getProductStockListUrl } from '@constants/apiUrls';
import { HttpMethod } from '@constants/commontypes';
import { QueryKey } from '@constants/query';

import { useAkinonQuery } from '../adapters/useAkinonQuery';
import { client } from '../client';

const fetchProductStockList = async (queryParams, fileFilter) => {
  if (fileFilter) {
    const formData = new FormData();
    formData.append('filter_file', new Blob([fileFilter.originFileObj]), fileFilter.name);
    return await client.post(getProductStockListUrl, formData, {
      params: queryParams,
      headers: {
        'Origin-Method': HttpMethod.GET,
      },
    });
  }
  return await client.get(getProductStockListUrl, { params: queryParams });
};

export function useProductStockListQuery({ requestConfig, queryOptions = {}, onSuccess } = {}) {
  const { data, isFetching, isLoading, error } = useAkinonQuery({
    queryKey: [QueryKey.PRODUCT_STOCKS, requestConfig],
    async queryFn() {
      const response = await client.get(getProductStockListUrl, requestConfig);
      onSuccess?.(response);

      return response;
    },
    ...queryOptions,
  });

  return {
    productStocks: data?.results,
    isProductStocksLoadingMutating: isLoading,
    isProductStocksFetching: isFetching,
    productStocksError: error,
  };
}

export function usePaginatedProductStockListQuery({
  pagination,
  filters,
  params,
  onSuccess,
  queryOptions,
} = {}) {
  const { data, isFetching, error, refetch } = useAkinonQuery({
    queryKey: [QueryKey.PRODUCT_STOCKS, pagination, filters, params],
    async queryFn() {
      const fileFilter = Object.values(filters ?? {}).find((filter) => filter?.originFileObj);
      const filterParams = getRequestFiltersParams(filters);
      delete filterParams?.filter_file;
      const queryParams = {
        ...getRequestPaginationParams(pagination),
        ...filterParams,
        ...params,
      };

      const response = await fetchProductStockList(queryParams, fileFilter);
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    productStocks: data?.results,
    totalProductStocks: data?.count,
    isFetchingProductStocks: isFetching,
    productStocksError: error,
    refetchProductStocks: refetch,
  };
}
