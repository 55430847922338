import { AppTypeToAppShellType } from '@common/index';
import { getApplicationsUrl } from '@constants/apiUrls';
import { limitQuery } from '@constants/commontypes';
import { QueryKey } from '@constants/query';
import slugify from 'slugify';

import { useAkinonQuery } from '../adapters/useAkinonQuery';
import { client } from '../client';

export function useApplicationsQuery() {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.APPLICATIONS],
    queryFn: () => client.get(getApplicationsUrl, { params: { ...limitQuery } }),
  });

  const apps =
    data?.results
      .filter?.((app) => app?.config?.visible_type && app?.config?.visible_type !== 'empty')
      ?.map?.((app) => ({
        id: app.pk,
        url: app.config.web_url,
        slug: slugify(app.name + app.pk),
        type: AppTypeToAppShellType[app.config?.visible_type],
      })) ?? [];

  return {
    apps,
    isFetchingApplications: isFetching,
  };
}
