import { queryClient } from '@services/api/queryClient';

import { DatasourcePermissionLevel } from '../common';

export function useOnSubmit({
  createdDataSource,
  setCreatedDataSource,
  createSeller,
  createDataSource,
  setIsNewSellerFormVisible,
  queryKey,
  successMessage,
}) {
  const onSubmit = (formValues) => {
    const { name, supplier_code, supplier_segment, is_active, stock_release_type } = formValues;
    const { email, first_name, last_name, conf } = formValues;
    const reconciliationConf = conf?.reconciliation_conf;

    if (createdDataSource) {
      createSeller(
        {
          requestBody: {
            email,
            first_name,
            last_name,
            datasource: createdDataSource.id,
            datasource_permission_level: DatasourcePermissionLevel.ADMIN,
          },
          requestConfig: {
            successMessage,
            successDescription: successMessage,
          },
        },
        {
          onSuccess() {
            setIsNewSellerFormVisible(false);
            queryClient.invalidateQueries(queryKey);
          },
        }
      );
    } else {
      createDataSource(
        {
          requestBody: {
            name,
            supplier_code,
            supplier_segment,
            is_active,
            stock_release_type: Number(stock_release_type),
            conf: {
              reconciliation_conf: reconciliationConf,
            },
          },
          requestConfig: {
            suppressedNotifications: ['success', 'error'],
          },
        },
        {
          onSuccess: (dataSource) => {
            setCreatedDataSource(dataSource);
            createSeller(
              {
                requestBody: {
                  email,
                  first_name,
                  last_name,
                  datasource: dataSource.id,
                  datasource_permission_level: DatasourcePermissionLevel.ADMIN,
                },
                requestConfig: {
                  successMessage,
                  successDescription: successMessage,
                },
              },
              {
                onSuccess() {
                  setIsNewSellerFormVisible(false);
                  queryClient.invalidateQueries(queryKey);
                },
              }
            );
          },
        }
      );
    }
  };

  return onSubmit;
}
