import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export function useCurrentStep({ stepCount, fallbackStep }) {
  if (!stepCount) {
    throw new Error('stepCount is required');
  } else if (typeof fallbackStep !== 'number') {
    throw new Error('fallbackStep must be a number');
  } else if (typeof stepCount !== 'number') {
    throw new Error('stepCount must be a number');
  }

  const [searchParams, setSearchParams] = useSearchParams();
  const step = searchParams.get('step');
  const currentStep = Number(step);
  const isStepValid = currentStep >= 0 && currentStep < stepCount;

  useEffect(() => {
    if (step === null || !isStepValid) {
      setSearchParams({ step: fallbackStep });
    }
  }, [step, isStepValid, setSearchParams, fallbackStep]);

  const setCurrentStep = (step) => {
    setSearchParams({ step });
  };

  return { currentStep: isStepValid ? currentStep : fallbackStep, setCurrentStep };
}
