import {
  getReconciliationDetailURL,
  getReconciliationItemDetailURL,
  getReconciliationsURL,
  postReconciliationStatusURL,
} from '@constants/apiUrls';
import { client } from '@services/api/client';

export const getReconciliations = (params) => {
  return client.get(getReconciliationsURL, { params });
};

export const getReconciliationDetail = (reconciliationId) => {
  return client.get(getReconciliationDetailURL, { urlParams: { reconciliationId } });
};

export const getReconciliationItems = (params) => {
  return client.get(getReconciliationItemDetailURL, {
    params,
  });
};

export const postReconciliationStatus = (reconciliationId, status) => {
  return client.post(
    postReconciliationStatusURL,
    {
      status,
    },
    {
      urlParams: { reconciliationId },
    }
  );
};
