import './style.scss';

import { createSelectOptions } from '@common/index';
import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import AkinonFormItem from '@components/AkinonFormItem';
import AkinonInput from '@components/AkinonInput';
import AkinonSelect from '@components/AkinonSelect';
import AkinonSwitch from '@components/AkinonSwitch';
import Box from '@components/utility/box';
import { formBuilderVerticalLayout } from '@constants/layoutTypes';
import { QueryKey } from '@constants/query';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDataSourcesQuery } from '@services/api/hooks';
import { useDynamicSettingsQuery } from '@services/api/hooks/useDynamicSettingsQuery';
import { queryClient } from '@services/api/queryClient';
import { useSaveUserMutation } from '@services/hooks/user/useSaveUserMutation';
import { Modal } from 'antd';
import first from 'lodash/first';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormKey, getFormSchema } from '../common';

/**
 * @param {{ modalProps: import('antd').ModalProps }} props
 */

export const UserManagementFormModal = ({ modalProps, filters, pagination }) => {
  const { t } = useTranslation('Settings');
  const { dataSources, isFetchingDataSources } = useDataSourcesQuery();
  const { dynamicSettings } = useDynamicSettingsQuery({
    params: {
      key: 'USER_CUSTOM_FIELDS',
      is_active: true,
    },
  });
  const dynamicUserSettings = first(dynamicSettings)?.value;

  const schema = getFormSchema({ dynamicUserSettings });

  const { control, reset, handleSubmit, watch } = useForm({
    mode: 'onChange',
    resolver: zodResolver(schema),
  });

  const formIsSuperUser = watch(FormKey.IS_SUPERUSER);

  const dynamicFormFields = useMemo(
    () =>
      dynamicUserSettings?.map((key) => (
        <AkinonFormItem key={key} control={control} name={`extra.${key}`} label={key}>
          <AkinonInput placeholder={t('enter_variable', { variable: key })} />
        </AkinonFormItem>
      )),
    [dynamicUserSettings]
  );

  const { saveUser } = useSaveUserMutation({
    mutationOptions: {
      onSuccess: () => {
        handleClose();
        queryClient.invalidateQueries([QueryKey.USERS, filters, pagination]);
      },
    },
  });

  const handleClose = () => {
    modalProps?.onCancel();
    reset();
  };

  const onSubmit = (requestBody) => {
    saveUser({ requestBody });
  };

  return (
    <Modal {...modalProps} onCancel={handleClose} footer={null}>
      <section className="user-management-content">
        <Box
          className="box-primary form-box"
          title={t('add_edit_user')}
          subtitle={t('add_edit_user_desc')}
        >
          <AkinonForm
            preserve={false}
            layout="vertical"
            className="akn-form akn-form-v2"
            wrapperCol={formBuilderVerticalLayout.wrapperCol}
          >
            <AkinonFormItem control={control} label={t('email')} required name={FormKey.EMAIL}>
              <AkinonInput placeholder={t('enter_variable', { variable: t('email') })} />
            </AkinonFormItem>
            <AkinonFormItem
              control={control}
              label={t('first_name')}
              required
              name={FormKey.FIRST_NAME}
            >
              <AkinonInput placeholder={t('enter_variable', { variable: t('first_name') })} />
            </AkinonFormItem>
            <AkinonFormItem
              control={control}
              label={t('last_name')}
              required
              name={FormKey.LAST_NAME}
            >
              <AkinonInput placeholder={t('enter_variable', { variable: t('last_name') })} />
            </AkinonFormItem>
            <AkinonFormItem control={control} label={t('status')} name={FormKey.IS_ACTIVE}>
              <AkinonSwitch checkedChildren={t('active')} unCheckedChildren={t('passive')} />
            </AkinonFormItem>
            <AkinonFormItem
              control={control}
              label={t('marketplace_owner')}
              name={FormKey.IS_SUPERUSER}
            >
              <AkinonSwitch checkedChildren={t('yes')} unCheckedChildren={t('no')} />
            </AkinonFormItem>
            <AkinonFormItem
              control={control}
              label={t('seller.name')}
              name={FormKey.DATASOURCE}
              required={!formIsSuperUser}
              disabled={formIsSuperUser}
            >
              <AkinonSelect
                placeholder={t('enter_variable', { variable: t('seller.name') })}
                showSearch
                loading={isFetchingDataSources}
                options={createSelectOptions(dataSources, {
                  valueKey: 'id',
                  labelKey: 'name',
                })}
                optionFilterProp="label"
              />
            </AkinonFormItem>

            {dynamicFormFields}
            <AkinonFormItem className="ant-col-md-24 ant-col-sm-24 ant-col-xs-24">
              <AkinonButton
                onClick={handleSubmit(onSubmit)}
                type="primary"
                className="akn-submit-button"
              >
                {t('save')}
              </AkinonButton>
            </AkinonFormItem>
          </AkinonForm>
        </Box>
      </section>
    </Modal>
  );
};
