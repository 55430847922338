import AkinonLink from '@components/AkinonLink';
import TitleWithHint from '@components/TitleWithHint';
import { RouteUrls } from '@constants/routeUrls';
import { Tooltip, Typography } from 'antd';
import React from 'react';
import { generatePath } from 'react-router-dom';

const { Text } = Typography;

export const DataIndex = {
  NAME: 'name',
  SLUG: 'slug',
  PROVIDER: 'provider',
  CLIENT_ID: 'client_id',
  AUTHORIZATION_URL: 'authorization_url',
  TOKEN_URL: 'token_url',
  USER_INFO_URL: 'userinfo_url',
  REDIRECT_URL: 'redirect_url',
  ADDITIONAL_ARGS: 'kwargs',
  IS_ACTIVE: 'is_active',
};

export const getColumns = ({ t }) => {
  return [
    {
      title: <TitleWithHint text={t('sso_settings.name')} hint={t('sso_settings.name_hint')} />,
      dataIndex: DataIndex.NAME,
      render(name, record) {
        return (
          <AkinonLink
            to={generatePath(RouteUrls.settings.ssoLoginSettingsFormUrl, {
              providerSlug: record?.slug,
            })}
          >
            <Tooltip title={name}>{name}</Tooltip>
          </AkinonLink>
        );
      },
    },
    {
      title: <TitleWithHint text={t('sso_settings.slug')} hint={t('sso_settings.slug_hint')} />,
      dataIndex: DataIndex.SLUG,
      render(slug) {
        return <Tooltip title={slug}>{slug}</Tooltip>;
      },
    },
    {
      title: (
        <TitleWithHint text={t('sso_settings.provider')} hint={t('sso_settings.provider_hint')} />
      ),
      dataIndex: DataIndex.PROVIDER,
      render(provider) {
        return <Tooltip title={provider}>{provider}</Tooltip>;
      },
    },
    {
      title: (
        <TitleWithHint text={t('sso_settings.client_id')} hint={t('sso_settings.client_id_hint')} />
      ),
      dataIndex: DataIndex.CLIENT_ID,
      render(client_id) {
        return <Tooltip title={client_id}>{client_id}</Tooltip>;
      },
    },
    {
      title: (
        <TitleWithHint text={t('sso_settings.is_active')} hint={t('sso_settings.is_active')} />
      ),
      dataIndex: DataIndex.IS_ACTIVE,
      render(is_active) {
        return is_active ? (
          <Text strong className="green">
            {t('yes').toUpperCase()}
          </Text>
        ) : (
          <Text strong className="red">
            {t('no').toUpperCase()}
          </Text>
        );
      },
    },
  ];
};
