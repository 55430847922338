import './index.scss';

import { UserRole } from '@constants/auth';
import { zodResolver } from '@hookform/resolvers/zod';
import { usePostPreOfferMutation } from '@services/api/hooks/usePostPreOfferMutation';
import { useSubmitPreOfferMutation } from '@services/api/hooks/useSubmitPreOfferMutation';
import { useUserRole } from '@utils/hooks/useUserRole';
import useStore from '@zustand-store/index';
import { resetPreOfferSelector } from '@zustand-store/selectors/preOffer';
import { Form, Space } from 'antd';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useUnmount } from 'react-use';

import { getDescriptionFormSchema } from './common';
import BasicProductInfoForm from './components/BasicProductInfoForm';
import DescriptionForm from './components/DescriptionForm';
import ProductRevisionModal from './components/ProductRevision/ProductRevisionModal';
import ProductsToBeAddedTableForm from './components/ProductsToBeAddedTableForm';
import SubmitForm from './components/SubmitForm';
import { QueriesProvider } from './contexts/Queries';
import { useQueries } from './hooks/api/useQueries';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import useFormMetaFields from './hooks/useFormMetaFields';
import { useOnSubmit } from './hooks/useOnSubmit';
import { usePageMeta } from './hooks/usePageMeta';

const ProductDetail = () => {
  const { t } = useTranslation();
  const [isProductRevisionModalVisible, setIsProductRevisionModalVisible] = useState(false);
  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;
  const [basicProductInfoForm] = Form.useForm();
  const [productsToBeAddedTableForm] = Form.useForm();
  const schema = getDescriptionFormSchema({ t });
  const descriptionForm = useForm({
    resolver: zodResolver(schema),
  });
  const [submitForm] = Form.useForm();
  const { state } = useLocation();

  const { isEditDisabled } = state ?? { isEditDisabled: false };

  const { postPreOffer, isPostingPreOffer } = usePostPreOfferMutation();
  const { submitPreOffer, isSubmittingPreOffer } = useSubmitPreOfferMutation();
  const resetPreOffer = useStore(resetPreOfferSelector);
  const {
    product,
    attributeConfigs,
    attributeSetId,
    dataSources,
    priceLists,
    productAttributes,
    stockLists,
    productMeta,
    buyboxPrice,
  } = useQueries();
  const onSubmit = useOnSubmit({
    submitPreOffer,
    basicProductInfoForm,
    productsToBeAddedTableForm,
    descriptionForm,
    priceLists,
    stockLists,
    dataSources,
    attributeSetId,
    postPreOffer,
  });

  useUnmount(() => {
    resetPreOffer();
  });

  useFormMetaFields({ attributeConfigs, productAttributes });

  const onCancelProductRevisionModal = () => {
    setIsProductRevisionModalVisible(false);
  };
  const onShowProductRevisionModal = () => setIsProductRevisionModalVisible(true);

  useBreadcrumbs();
  usePageMeta();

  return (
    <section className="single-product-form">
      <ProductRevisionModal
        isVisible={isProductRevisionModalVisible}
        onCancel={onCancelProductRevisionModal}
        productAttributes={productAttributes}
        attributeConfigs={attributeConfigs}
        productMeta={productMeta}
        isLoading={!productAttributes}
      />
      <QueriesProvider
        value={{
          product,
          attributeSetId,
          dataSources,
          priceLists,
          stockLists,
          attributeConfigs,
          productAttributes,
          productMeta,
          buyboxPrice,
        }}
      >
        <BasicProductInfoForm form={basicProductInfoForm} />
        <Space style={{ width: '100%' }} direction="vertical" size="large">
          <ProductsToBeAddedTableForm
            form={productsToBeAddedTableForm}
            onShowProductRevisionModal={onShowProductRevisionModal}
          />
          <DescriptionForm form={descriptionForm} schema={schema} disabled={isSuperUser} />
          {!isSuperUser && !isEditDisabled && (
            <SubmitForm
              form={submitForm}
              isPostingPreOffer={isPostingPreOffer}
              isSubmittingPreOffer={isSubmittingPreOffer}
              onSubmit={onSubmit}
            />
          )}
        </Space>
      </QueriesProvider>
    </section>
  );
};

export default ProductDetail;
