import { getOrdersUrl } from '@constants/apiUrls';
import { fetcher } from '@services/api/client';

import { useQuery } from '..';

export function useOrdersQuery({ orderIds }) {
  const { data, isLoading, mutate, ...rest } = useQuery(
    orderIds
      ? [getOrdersUrl, { params: { id__in: orderIds.join(','), limit: orderIds.length } }]
      : null,
    fetcher
  );

  return {
    ...rest,
    orders: data?.results,
    isOrdersLoading: isLoading,
    mutateOrders: mutate,
  };
}
