import { getActiveSkuUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { useAkinonQuery } from '../adapters/useAkinonQuery';
import { client } from '../client';

export function useActiveSkuQuery({ params }) {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.ACTIVE_SKU, params],
    queryFn: () => client.get(getActiveSkuUrl, { params }),
  });

  return {
    activeSku: data?.active_sku,
    isFetchingActiveSku: isFetching,
  };
}
