import { createSelectOptions } from '@common/index';
import { UserRole } from '@constants/auth';
import { getIsNullBooleanAttributeOptions, WidgetType } from '@constants/commontypes';
import { useAllDataSources } from '@services/hooks/datasources/useAllDataSources';
import { useCategoryTreeData } from '@utils/hooks/useCategoryTreeData';
import { useUserRole } from '@utils/hooks/useUserRole';

import { StaticFilterKey } from '../common';

export const useStaticFilters = ({ t, filters }) => {
  const userRole = useUserRole();

  const isSuperUser = userRole === UserRole.SUPER_USER;
  const { categoryTreeData, isCategoriesLoading } = useCategoryTreeData({
    generateTreeDataOptions: {
      valueKey: 'path',
    },
  });
  const { data: dataSources, isLoading: isDataSourcesLoading } = useAllDataSources({ filters });

  return {
    staticFiltersFormMetaFields: [
      isSuperUser && {
        key: StaticFilterKey.DATASOURCE,
        widget: WidgetType.SELECT,
        widgetProps: {
          showSearch: true,
          optionFilterProp: 'label',
        },
        placeholder: t('seller'),
        options: createSelectOptions(dataSources, {
          labelKey: 'name',
          valueKey: 'id',
        }),
      },
      {
        key: StaticFilterKey.NAME,
        widget: WidgetType.INPUT,
        placeholder: t('product_name'),
      },
      {
        key: StaticFilterKey.SKU,
        widget: WidgetType.INPUT,
        placeholder: t('sku'),
      },
      {
        key: StaticFilterKey.BASE_CODE,
        widget: WidgetType.INPUT,
        placeholder: t('basecode'),
      },
      {
        key: StaticFilterKey.PRODUCT_ISNULL,
        widget: WidgetType.CHECKBOX_GROUP,
        placeholder: t('offer.product.related'),
        options: [{ label: t('offer.product.related'), value: false }],
      },
      {
        key: StaticFilterKey.CATEGORY,
        widget: WidgetType.TREESELECT,
        placeholder: t('category'),
        widgetProps: {
          showSearch: true,
          treeDefaultExpandAll: true,
          virtual: false,
          style: { width: '100%' },
          loading: isCategoriesLoading,
          dropdownStyle: { maxHeight: 400, overflow: 'auto' },
          treeData: categoryTreeData,
          treeNodeFilterProp: 'title',
          allowClear: true,
        },
      },
      {
        key: StaticFilterKey.IMAGE_ISNULL,
        widget: WidgetType.SELECT,
        widgetProps: {
          showSearch: true,
          optionFilterProp: 'label',
        },
        placeholder: t('product_image'),
        options: getIsNullBooleanAttributeOptions({ t }),
      },
    ].filter(Boolean),
    isStaticFiltersLoading: isDataSourcesLoading || isCategoriesLoading,
  };
};
