import './style.scss';

import { Col, Row } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';

import { MessagesBox } from './components/MessagesBox';
import { OrderInformationBox } from './components/OrderInformationBox';
import { ReplyMessageBox } from './components/ReplyMessageBox';
import { useConversationAndOrdersQuery } from './hooks/api/useConversationAndOrdersQuery';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { usePageMeta } from './hooks/usePageMeta';

const OrderAnswer = () => {
  const { id } = useParams();

  useBreadcrumbs();
  usePageMeta();

  const { conversation, order, isConversationLoading } = useConversationAndOrdersQuery({
    conversationId: id,
  });

  return (
    <section className="order-answer-detail">
      <Row
        gutter={40}
        style={{
          rowGap: '40px',
        }}
      >
        <Col span={24} lg={12} xl={8} style={{ maxHeight: 400 }}>
          <OrderInformationBox conversation={conversation} order={order} />
        </Col>

        <Col
          span={24}
          lg={16}
          xl={14}
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '40px',
          }}
        >
          <MessagesBox isConversationLoading={isConversationLoading} conversation={conversation} />
          <ReplyMessageBox conversationId={id} />
        </Col>
      </Row>
    </section>
  );
};

export default OrderAnswer;
