import { wildButtonProps } from '@components/WildButton';
import { useTour } from '@reactour/tour';
import useStore from '@zustand-store/index';
import { setPageMetaSelector } from '@zustand-store/selectors/app';
import { Typography } from 'antd';
import { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

const { Title, Text } = Typography;

const getTourSteps = ({ t }) => {
  return [
    {
      selector: '.standard-akinon-table-header__title',
      content: (
        <>
          <Title level={4}>{t('order_messages_tutorial.table_title')}</Title>
          <Text>
            <Trans i18nKey="Messages:order_messages_tutorial.table" />
          </Text>
        </>
      ),
    },
    {
      selector: '.filter-options',
      content: (
        <>
          <Title level={4}>{t('order_messages_tutorial.dynamic_filters_title')}</Title>
          <Text>{t('order_messages_tutorial.dynamic_filters')}</Text>
        </>
      ),
    },
  ];
};

export function usePageMeta() {
  const { t } = useTranslation('Messages');
  const setPageMeta = useStore(setPageMetaSelector);
  const { setSteps, setIsOpen, setCurrentStep } = useTour();

  useEffect(() => {
    setSteps(getTourSteps({ t }));
  }, [t]);

  useEffect(() => {
    setPageMeta({
      title: t('order.messages'),
      description: t('order.messages.desc'),
      actions: [
        {
          ...wildButtonProps,
          label: t('start_tour'),
          onClick() {
            setCurrentStep(0);
            setIsOpen(true);
          },
        },
      ],
    });
  }, [t]);

  useUnmount(() => {
    setPageMeta({});
  });
}
