import './styles.scss';

import { CloseOutlined } from '@ant-design/icons';
import Show from '@components/Show';
import { notification } from 'antd';
import clsx from 'clsx';
import debounce from 'lodash/debounce';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import React from 'react';

/**
 * @param {import('antd/lib/notification').ArgsProps} args
 */
export const openNotification = ({ className, ...otherArgs }) => {
  notification.open({
    className: clsx('akinon-notification', className),
    closeIcon: (
      <button className="akinon-notification__close-button">
        <CloseOutlined />
      </button>
    ),
    ...otherArgs,
  });
};

export const openDebouncedNotification = (function () {
  let argsList = [];
  const debouncedOpenNotification = debounce(
    function ({ className, message, description, type, t, ...otherArgs }) {
      const areThereMultipleNotifications = argsList.length > 1;
      if (areThereMultipleNotifications) {
        const groupedCompoundNotificationMap = mapValues(
          groupBy(argsList, 'type'),
          (notifications) => {
            const notificationsCountMap = mapValues(
              groupBy(notifications, 'message'),
              (notifications) => ({
                ...notifications[0],
                count: notifications.length,
              })
            );
            return Object.values(notificationsCountMap);
          }
        );
        Object.entries(groupedCompoundNotificationMap).forEach(([type, notifications]) => {
          notification.open({
            className: clsx('akinon-notification', className),
            closeIcon: (
              <button className="akinon-notification__close-button">
                <CloseOutlined />
              </button>
            ),
            type,
            message: t('youHaveNNotifications', {
              count: notifications.length,
            }),
            description: (
              <div className="akinon-notification__messages">
                {notifications.map(({ message, description, count }, index) => (
                  <div key={index} className="akinon-notification__message">
                    <Show when={count > 1}>
                      <div className="akinon-notification__message-count">{count}x</div>
                    </Show>
                    <div className="akinon-notification__message-title">{message}</div>
                    <div className="akinon-notification__message-description">{description}</div>
                  </div>
                ))}
              </div>
            ),
            ...otherArgs,
          });
        });
      } else {
        notification.open({
          className: clsx('akinon-notification', className),
          message,
          description,
          type,
          closeIcon: (
            <button className="akinon-notification__close-button">
              <CloseOutlined />
            </button>
          ),
          ...otherArgs,
        });
      }
      if (areThereMultipleNotifications) {
        argsList = [];
      }
    },
    500,
    { leading: false }
  );

  /**
   * @param {import('antd/lib/notification').ArgsProps & { t: import('i18next').TFunction }} args
   */
  return function (args) {
    argsList.push(args);
    debouncedOpenNotification(args);
  };
})();
