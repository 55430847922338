import { getCountryUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { client } from '@services/api/client';
import { useQuery } from '@tanstack/react-query';

export function useCountryQuery({ queryOptions, countryId }) {
  const { data, isLoading } = useQuery({
    queryKey: [QueryKey.COUNTRY, countryId],
    queryFn: () => client.get(getCountryUrl, { urlParams: { countryId } }),
    ...queryOptions,
  });

  return {
    country: data,
    isCountryLoading: isLoading,
  };
}
