import { openNotification } from '@components/akinonNotification';
import { useDynamicColumnsAttributeValues } from '@pages/ProductsAndCategories/hooks/useDynamicColumnsAttributeValues';
import { useMasterProductPricesQuery } from '@services/api/hooks/useMasterProductPricesQuery';
import { useDataSourcesQuery } from '@services/api/hooks/usePaginatedDataSourcesQuery';
import { usePreOfferImagesQuery } from '@services/api/hooks/usePaginatedPreOfferImagesQuery';
import { usePaginatedPreOffersQuery } from '@services/api/hooks/usePaginatedPreOffersQuery';
import { useProductsQuery } from '@services/api/hooks/usePaginatedProductsQuery';
import { useCategoryTreeData } from '@utils/hooks/useCategoryTreeData';
import { getTreeNodeByValue } from '@utils/index';
import entries from 'lodash/entries';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import reject from 'lodash/reject';
import uniq from 'lodash/uniq';
import { useTranslation } from 'react-i18next';

export const useOffersByStatus = ({ dynamicFormMetaFields, pagination, filters, params }) => {
  const { t } = useTranslation('ProductsAndCategories');
  const { preOffers, isFetchingPreOffers, totalPreOffers, refetchPreOffers } =
    usePaginatedPreOffersQuery({
      pagination,
      filters,
      params,
      onError: (error) => {
        openNotification({
          message: t('filtering_unsuccessful'),
          description: error.response?.data?.detail ?? error.message,
          type: 'error',
          t,
        });
      },
    });

  const preOfferIds = preOffers?.map(({ id }) => id);
  const productIds = uniq(reject(map(preOffers, 'product'), isNil));
  const dataSourceIds = uniq(reject(map(preOffers, 'datasource'), isNil));

  const { dataSources } = useDataSourcesQuery({
    params: {
      limit: dataSourceIds?.length,
      id__in: dataSourceIds?.join(','),
    },
    queryOptions: {
      enabled: !isFetchingPreOffers,
    },
  });
  const { preOfferImages } = usePreOfferImagesQuery({
    params: {
      limit: preOfferIds?.length,
      parent__in: preOfferIds?.join(','),
      order: 0,
    },
    queryOptions: {
      enabled: !isEmpty(preOfferIds),
    },
  });
  const { products } = useProductsQuery({
    params: { id__in: productIds.join(','), limit: productIds?.length },
    queryOptions: {
      enabled: !isEmpty(productIds) && !isFetchingPreOffers,
    },
  });

  const { masterProductPrices } = useMasterProductPricesQuery({
    params: {
      limit: productIds?.length,
      product__in: productIds?.join(','),
    },
    queryOptions: {
      enabled: !isEmpty(productIds) && !isFetchingPreOffers,
    },
  });

  const { categoryTreeData } = useCategoryTreeData();
  const { attributeValues, isFetchingAttributeValues } = useDynamicColumnsAttributeValues({
    dynamicFormMetaFields,
  });

  const data = preOffers?.map((offer) => {
    const image = preOfferImages?.find(({ parent }) => parent === offer?.id);
    const seller = dataSources?.find(({ id }) => id === offer?.datasource);
    const category = getTreeNodeByValue({ tree: categoryTreeData, nodeValue: offer?.category });
    const product = products?.find(({ id }) => id === offer?.product);
    const masterProductPrice = masterProductPrices?.find(
      ({ product }) => product === offer?.product
    )?.price;
    const attributes = reduce(
      entries(offer?.attributes),
      (acc, [key, value]) => {
        const attributeOptions = attributeValues?.[key] ?? [];
        const valueLabel = get(find(attributeOptions, { value }), 'label', value);
        return {
          ...acc,
          [key]: valueLabel,
        };
      },
      {}
    );
    return {
      ...offer,
      attributes,
      buybox_price: masterProductPrice,
      seller,
      category,
      image,
      product,
    };
  });

  return {
    data,
    isLoading: isFetchingPreOffers || isFetchingAttributeValues,
    total: totalPreOffers,
    refetch: refetchPreOffers,
  };
};
