import { StatusText } from '@pages/Orders/common';
import { dateFormatter } from '@utils/index';
import isEmpty from 'lodash/isEmpty';
import React from 'react';

const OrderInfo = ({ t, orderDetail }) => {
  return (
    <div className="detail">
      <div className="detail-item">
        <div className="detail-item--title">{t('order.number')}</div>
        <span className="detail-item--desc">{orderDetail?.number ?? '-'}</span>
      </div>
      <div className="detail-item">
        <div className="detail-item--title">{t('order.status')}</div>
        <span className="detail-item--desc">{StatusText({ t })?.[orderDetail?.status] ?? '-'}</span>
      </div>
      <div className="detail-item">
        <div className="detail-item--title">{t('order.date')}</div>
        <span className="detail-item--desc">
          {!isEmpty(orderDetail?.date_placed) ? dateFormatter(orderDetail?.date_placed, true) : '-'}
        </span>
      </div>
      <div className="detail-item">
        <div className="detail-item--title">{t('address_notes')}</div>
        <span className="detail-item--desc">{orderDetail?.shipping_address_obj?.notes ?? '-'}</span>
      </div>

      <div className="detail-item">
        <div className="detail-item--title">{t('order_note')}</div>
        <span className="detail-item--desc">{orderDetail?.notes ?? '-'}</span>
      </div>

      <div className="detail-item">
        <div className="detail-item--title">{t('gift_box')}</div>
        <span className="detail-item--desc">
          {orderDetail?.has_gift_box ? t('exist') : t('not.exist')}
        </span>
      </div>

      <div className="detail-item">
        <div className="detail-item--title">{t('gift_note')}</div>
        <span className="detail-item--desc">{orderDetail?.gift_box_note || t('not.exist')}</span>
      </div>
    </div>
  );
};

export default OrderInfo;
