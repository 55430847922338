import { getActiveAuthObject, removeAuthObject, updateAuthObject } from '@common/auth';
import { createLogCenterRecord } from '@common/index';
import { transformApiErrorToErrorMessages } from '@common/network';
import { getPathFragments } from '@common/routing';
import { openDebouncedNotification, openNotification } from '@components/akinonNotification';
import { getMeUrl, postRefreshTokenUrl, postTokenObtainUrl } from '@constants/apiUrls';
import { HttpStatus, REQUEST_AUTHORIZATION_HEADER } from '@constants/auth';
import { Language } from '@constants/locale';
import { HttpMethods } from '@constants/network';
import { guestRoutes, RouteUrls } from '@constants/routeUrls';
import i18n from '@root/i18n';
import useStore from '@zustand-store/index';
import { Space, Typography } from 'antd';
import { Mutex } from 'async-mutex';
import axios from 'axios';
import QuerySerializer from 'qs';
import React from 'react';
import { generatePath, matchPath } from 'react-router-dom';

const { Text } = Typography;

export const API_BASE_URL = '/api/v1/';
export const getApiUrl = (path) => {
  return `${API_BASE_URL}${path}`;
};
export const abortControllers = new Map();

// TODO: This is a temporary solution. We should handle this in a better way.
const nonNotificationPaths = [RouteUrls.productsAndCategories.landing];

const getControllerSignal = () => {
  const currentPage = window.location.pathname;
  let controllers = abortControllers.get(currentPage);

  if (!controllers) {
    controllers = [];
    abortControllers.set(currentPage, controllers);
  }

  const controller = new AbortController();
  controllers.push(controller);
  return controller.signal;
};

export const cancelPreviousRequests = (pagePath) => {
  const controllers = abortControllers.get(pagePath);
  if (controllers) {
    controllers.forEach((controller) => controller.abort());
    abortControllers.delete(pagePath);
  }
};

export const client = axios.create({
  baseURL: API_BASE_URL,
  paramsSerializer: (params) => QuerySerializer.stringify(params, { indices: false }),
});

client.interceptors.request.use((config) => {
  const language = useStore.getState().language;
  const accessToken = getActiveAuthObject()?.access;

  if (!config.url.includes(getMeUrl)) {
    config.signal = getControllerSignal();
  }

  if (config?.urlParams) {
    for (const param in config.urlParams) {
      const paramValue = config.urlParams[param];
      config.urlParams[param] = paramValue ?? '';
    }

    config.url = `${generatePath(config.url, config.urlParams)}/`;
  }

  config.headers = {
    Accept: ' application/json',
    Authorization: accessToken ? `${REQUEST_AUTHORIZATION_HEADER} ${accessToken}` : null,
    'Accept-Language': language ?? null,
    ...config.headers,
  };

  return config;
});

export function resetAuthenticationAndRedirectToSignIn() {
  const currentUrl = getPathFragments(window.location.pathname).mainPath;
  // FIXME: This is a temporary solution. We should handle this in a better way.
  const isGuestRoute = guestRoutes.some((route) => currentUrl.includes(route.replace(/:\w+/g, '')));
  // TODO can we use react-router-dom instead of overriding window.location.href? When data router is ready, we can use it.
  if (!isGuestRoute) {
    // reset active auth object
    removeAuthObject(getActiveAuthObject()?.access);
  }
}

const apiResponseSuccessInterceptor = (response) => {
  const targetedMethods = [
    HttpMethods.POST,
    HttpMethods.PUT,
    HttpMethods.DELETE,
    HttpMethods.PATCH,
  ];
  const currentLocation = window.location.pathname;

  if (
    response?.config?.suppressedNotifications?.includes?.('success') ||
    !targetedMethods.includes(response?.config?.method?.toUpperCase())
  )
    return response?.data;

  if (nonNotificationPaths.some((path) => currentLocation.includes(path))) return response?.data;

  i18n.loadLanguages(Object.values(Language), () => {
    openDebouncedNotification({
      message: response?.config?.successMessage ?? i18n.t('api.request.successMessage'),
      type: 'success',
      t: i18n.t,
      description: response?.config?.successDescription ?? (
        <Space direction="vertical">
          <Space direction="vertical" size="small">
            <Text style={{ color: 'white' }}>{i18n.t('api.request.successMessage')}</Text>
          </Space>
        </Space>
      ),
    });
  });

  return response?.data;
};

const apiResponseErrorInterceptor = async (responseError) => {
  if (axios.isCancel(responseError)) return;

  const currentLocation = window.location.pathname;
  const refreshToken = getActiveAuthObject()?.refresh;
  const isStatusUnauthorized = responseError?.response?.status === HttpStatus.UNAUTHORIZED;
  const shouldRedirectToSignIn =
    isStatusUnauthorized &&
    !matchPath({ path: RouteUrls.public.signIn }, currentLocation) &&
    !matchPath({ path: RouteUrls.public.signInCallback }, currentLocation);
  if (refreshToken && isStatusUnauthorized) {
    const axiosRequest = await tryToRefreshToken({ responseError, shouldRedirectToSignIn });
    if (axiosRequest) return axiosRequest;
  } else if (shouldRedirectToSignIn) {
    resetAuthenticationAndRedirectToSignIn();
  }
  const { apiErrorMessages } = transformApiErrorToErrorMessages(responseError);
  i18n.loadLanguages(Object.values(Language), () => {
    useStore.getState().addLogCenterRecords(
      apiErrorMessages.map((apiErrorMessage) =>
        createLogCenterRecord({
          type: 'error',
          message: i18n.t('api.request.errorMessage'),
          description: apiErrorMessage,
        })
      )
    );
    if (responseError?.response?.config?.suppressedNotifications?.includes?.('error'))
      throw responseError;

    // TODO: This is a temporary solution. We should handle this in a better way.
    if (nonNotificationPaths.some((path) => currentLocation.includes(path))) throw responseError;

    openDebouncedNotification({
      duration: 20000,
      message: responseError?.response?.config?.errorMessage ?? i18n.t('api.request.errorMessage'),
      type: 'error',
      t: i18n.t,
      description: responseError?.response?.config?.errorDescription ?? (
        <Space direction="vertical">
          <Space direction="vertical" size="small">
            {apiErrorMessages.map((apiErrorMessage, index) => (
              <Text style={{ color: 'white' }} key={index}>
                {apiErrorMessage}
              </Text>
            ))}
          </Space>
        </Space>
      ),
    });
  });

  throw responseError;
};

const refreshMutex = new Mutex();

const tryToRefreshToken = async ({ responseError, shouldRedirectToSignIn }) => {
  const originalRequest = responseError.config;
  // wait & retry all failed requests while we were getting a new token
  if (refreshMutex.isLocked()) {
    await refreshMutex.waitForUnlock();
    const accessToken = getActiveAuthObject()?.access;
    originalRequest.headers.Authorization = `${REQUEST_AUTHORIZATION_HEADER} ${accessToken}`;
    return client(originalRequest);
  }

  const release = await refreshMutex.acquire();
  const activeToken = getActiveAuthObject();
  const refreshToken = activeToken?.refresh;

  const reset = () => {
    openNotification({
      duration: 20000,
      type: 'error',
      message: i18n.t('session.expired'),
      description: i18n.t('session.expired.description'),
    });
    shouldRedirectToSignIn && resetAuthenticationAndRedirectToSignIn();
    return Promise.reject(responseError);
  };

  const isSignInRequest = originalRequest.url === postTokenObtainUrl;
  const isUnauthorized = responseError?.response?.status === HttpStatus.UNAUTHORIZED;
  const shouldTryToObtainToken = !isSignInRequest && isUnauthorized;

  if (!shouldTryToObtainToken) {
    release();
    return null;
  }

  try {
    const response = await axios.post(
      getApiUrl(postRefreshTokenUrl),
      { refresh: refreshToken },
      {
        'Content-Type': 'application/json',
      }
    );
    const newAccessToken = response?.data?.access;
    if (newAccessToken) {
      updateAuthObject(activeToken?.access, response?.data);
      const newAuthHeader = `${REQUEST_AUTHORIZATION_HEADER} ${newAccessToken}`;
      originalRequest.headers.Authorization = newAuthHeader;
      return client(originalRequest);
    } else {
      reset();
    }
  } catch (e) {
    reset();
  } finally {
    release();
  }
};

client.interceptors.response.use(apiResponseSuccessInterceptor, apiResponseErrorInterceptor);
/**
 * @param {string} url
 * @param {import('axios').AxiosRequestConfig} config
 */
export const fetcher = ([url, config]) => client.get(url, config);

export const multipleFetcher = (fetcherArgsList) =>
  Promise.all(fetcherArgsList.map(([url, config]) => fetcher([url, config])));

export const getAxiosInstance = () => client;
