import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { deleteDynamicSetting } from '@services/api/dynamicSettings';

export function useDeleteDynamicSettingMutation({ mutationOptions = {} }) {
  const mutationResponse = useAkinonMutation({
    mutationFn: (dynamicSettingId) => deleteDynamicSetting({ dynamicSettingId }),
    ...mutationOptions,
  });

  return {
    deleteDynamicSetting: mutationResponse.mutate,
    isDynamicSettingDeleting: mutationResponse.isLoading,
  };
}
