import { patchPackageUrl } from '@constants/apiUrls';
import { patchPackage } from '@services/api';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';

/**
 * @typedef {import('@tanstack/react-query').UseMutationResult} UseMutationResult
 * @returns {import('@tanstack/react-query').UseMutationResult}
 */

export function usePatchPackageMutation({ onErrorHandler = () => {}, packageId }) {
  const mutationKey = ['patch-package', { packageId }];

  const mutationFn = ({ params = {} }) =>
    patchPackage(patchPackageUrl, { params }, { urlParams: { packageId } });

  const { mutate, mutateAsync, ...rest } = useAkinonMutation({
    mutationKey,
    mutationFn,
    onError: (error) => {
      onErrorHandler(error);
    },
  });

  return {
    ...rest,
    mutate,
    mutateAsync,
  };
}
