import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { getProductsUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { client } from '@services/api/client';

export const useProductsQuery = ({ queryOptions, params, onSuccess } = {}) => {
  const { data, isLoading, isFetching, error } = useAkinonQuery({
    queryKey: [QueryKey.PRODUCTS, params],
    async queryFn() {
      const response = await client.get(getProductsUrl, { params });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    products: data?.results,
    isProductsLoading: isLoading,
    isFetchingProducts: isFetching,
    productsError: error,
  };
};

export const usePaginatedProductsQuery = ({ queryOptions, pagination, filters, onSuccess }) => {
  const { data, isFetching, error } = useAkinonQuery({
    queryKey: [QueryKey.PRODUCTS, pagination, filters],
    async queryFn() {
      const response = await client.get(getProductsUrl, {
        params: {
          ...getRequestPaginationParams(pagination),
          ...getRequestFiltersParams(filters),
        },
      });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    products: data?.results,
    totalProducts: data?.count,
    isFetchingProducts: isFetching,
    productsError: error,
  };
};
