import { getPathFragments } from '@common/routing';
import useStore from '@zustand-store/index';
import { filtersSelector } from '@zustand-store/selectors/filters';
import { useLocation } from 'react-router-dom';

export const usePageFilters = () => {
  const { pathname, search } = useLocation();
  const { mainPath } = getPathFragments(pathname);
  const filters = useStore(filtersSelector);

  return filters[`${mainPath}${search}`] ?? filters.emptyPath;
};
