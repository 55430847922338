import './styles.scss';

import { DragOutlined } from '@ant-design/icons';
import ActionIcon from '@components/ActionIcon/ActionIcon';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { IconTrash } from '@tabler/icons-react';
import clsx from 'clsx';
import omit from 'lodash/omit';
import React from 'react';

function SortableDocItem({ file, onRemoveFile, disabled }) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: file.uid,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: 'pointer',
  };

  const onRemoveHandler = (fileId) => {
    if (disabled) return;
    onRemoveFile?.({ fileId });
  };

  const isLocalFile = Boolean(file?.originFileObj?.thumbUrl);
  return (
    <div>
      <article ref={setNodeRef} key={file.uid} className="sortable-doc-item" style={style}>
        <div
          {...attributes}
          {...omit(listeners, 'onPointerDown')}
          className="sortable-doc-item__wrapper"
        >
          {isLocalFile ? (
            <div className="sortable-doc-item__file-embed-wrapper">
              <div className="sortable-doc-item__file-embed-title">{file.name}</div>
              <embed
                src={file.originFileObj.thumbUrl}
                className="sortable-doc-item__file-embed"
              ></embed>
            </div>
          ) : (
            <div className="sortable-doc-item__file-embed-wrapper">
              <div className="sortable-doc-item__file-embed-title">
                {file?.name ?? file?.url?.split?.('/')?.pop()}
              </div>
              <embed src={file.url} className="sortable-doc-item__file-embed"></embed>
            </div>
          )}

          <DragOutlined
            onClick={(event) => {
              event.stopPropagation();
            }}
            onPointerDown={listeners.onPointerDown}
            style={{
              position: 'absolute',
              cursor: 'move',
              top: -5,
              right: -5,
            }}
          />
        </div>

        <div className={clsx({ 'sortable-doc-item__actions': true, 'is-disabled': disabled })}>
          <ActionIcon Icon={IconTrash} onClick={() => onRemoveHandler(file.uid)} />
        </div>
      </article>
    </div>
  );
}
export default SortableDocItem;
