import { RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@hooks/useAppNavigate';
import useStore from '@zustand-store/index';
import { setPageMetaSelector } from '@zustand-store/selectors/app';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

export function usePageMeta() {
  const { t } = useTranslation('NotificationManagement');
  const setPageMeta = useStore(setPageMetaSelector);
  const navigate = useAppNavigate();

  useEffect(() => {
    setPageMeta({
      title: t('notification_management_title'),
      description: t('notification_management_description'),
      actions: [
        {
          label: t('set_new_notification'),
          icon: <i className="icon-arti" />,
          onClick() {
            navigate(RouteUrls.notificationManagement.form);
          },
        },
      ],
    });
  }, [t]);

  useUnmount(() => {
    setPageMeta({});
  });
}
