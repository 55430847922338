import { openNotification } from '@components/akinonNotification';
import { RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@root/hooks/useAppNavigate';
import useStore from '@zustand-store/index';
import {
  productsToBeAddedTableFormDataSourceSelector,
  variantsListAttributeMetaFieldsSelector,
} from '@zustand-store/selectors/preOffer';
import first from 'lodash/first';
import pick from 'lodash/pick';
import { useTranslation } from 'react-i18next';

import { BasicProductInfoFormItemKey } from '../components/BasicProductInfoForm/common';
import { DescriptionFormKey } from '../components/DescriptionForm/common';
import { ProductsToBeAddedTableFormColumnKey } from '../components/ProductsToBeAddedTableForm/common';

export function useOnSubmit({
  submitPreOffer,
  basicProductInfoForm,
  productsToBeAddedTableForm,
  descriptionForm,
  priceLists,
  stockLists,
  dataSources,
  attributeSetId,
  postPreOffer,
}) {
  const { t } = useTranslation('ProductsAndCategories');
  const navigate = useAppNavigate();
  const productsToBeAddedTableFormDataSource = useStore(
    productsToBeAddedTableFormDataSourceSelector
  );
  const variantsListAttributeMetaFields = useStore(variantsListAttributeMetaFieldsSelector);

  const variantsListAttributeKeys = variantsListAttributeMetaFields.map(({ key }) => key);

  const onSubmit = () => {
    Promise.allSettled(
      [basicProductInfoForm, productsToBeAddedTableForm, descriptionForm].map(
        (form) => form?.trigger?.() || form?.validateFields()
      )
    ).then((formsPromiseResult) => {
      const areFormsValid = formsPromiseResult.every(
        ({ status, value }) => status === 'fulfilled' && value !== false
      );
      if (!areFormsValid) {
        openNotification({
          message: t('transaction_failed'),
          description: t('required_fields_error_message'),
          type: 'error',
        });
        return;
      }
      const pricesKeys = [
        ProductsToBeAddedTableFormColumnKey.CURRENCY_TYPE,
        ProductsToBeAddedTableFormColumnKey.PRICE,
        ProductsToBeAddedTableFormColumnKey.RETAIL_PRICE,
        ProductsToBeAddedTableFormColumnKey.TAX_RATE,
      ];
      const stocksKeys = [ProductsToBeAddedTableFormColumnKey.STOCK];
      const priceListMasterId = priceLists.find(({ is_master }) => is_master)?.id;
      const stockListMasterId = stockLists.find(({ is_master }) => is_master)?.id;
      const sellerDescription = descriptionForm.getValues(DescriptionFormKey.SELLER_DESCRIPTION);

      const baseCode = basicProductInfoForm.getFieldValue(BasicProductInfoFormItemKey.BASE_CODE);
      const commonRequestBody = {
        name: basicProductInfoForm.getFieldValue(BasicProductInfoFormItemKey.NAME),
        base_code: baseCode,
        attribute_set: attributeSetId,
        datasource: first(dataSources)?.id,
        description: sellerDescription,
      };
      const requestBody = first(
        productsToBeAddedTableFormDataSource.map((preOffer, index) => ({
          ...commonRequestBody,
          sku: productsToBeAddedTableForm.getFieldValue(ProductsToBeAddedTableFormColumnKey.SKU)[
            index
          ],
          attributes: {
            ...preOffer,
            ...variantsListAttributeKeys.reduce(
              (preOfferAttributes, attributeKey) => ({
                ...preOfferAttributes,
                [attributeKey]: productsToBeAddedTableForm.getFieldValue(attributeKey)?.[index],
              }),
              {}
            ),
            SELLER_BARCODE: productsToBeAddedTableForm.getFieldValue(
              ProductsToBeAddedTableFormColumnKey.SELLER_BARCODE
            )[index],
            SELLER_DESCRIPTION: sellerDescription,
            SELLER_BASECODE: baseCode,
          },
          prices: [
            {
              price_list: priceListMasterId,
              ...Object.entries(
                pick(productsToBeAddedTableForm.getFieldsValue(), pricesKeys)
              ).reduce(
                (priceItem, [priceKey, priceValueMap]) => ({
                  ...priceItem,
                  [priceKey]: priceValueMap[index],
                }),
                {}
              ),
            },
          ],
          stocks: [
            {
              stock_list: stockListMasterId,
              ...Object.entries(
                pick(productsToBeAddedTableForm.getFieldsValue(), stocksKeys)
              ).reduce(
                (stockItem, [stockKey, stockValueMap]) => ({
                  ...stockItem,
                  [stockKey]: stockValueMap[index],
                }),
                {}
              ),
            },
          ],
          product: preOffer.id,
        }))
      );
      postPreOffer(
        {
          requestBody,
        },
        {
          onSuccess(response) {
            submitPreOffer(
              {
                preOfferId: response.id,
              },
              {
                onSuccess() {
                  basicProductInfoForm.resetFields();
                  openNotification({
                    message: t('transaction_success'),
                    description: t('create_product_offer_success'),
                    type: 'success',
                  });
                  navigate(RouteUrls.productsAndCategories.offerList.pendingApproval);
                },
              }
            );
          },
          onError(error) {
            openNotification({
              message: t('transaction_failed'),
              description: error?.response?.data?.detail || t('create_product_offer_error'),
              type: 'error',
            });
          },
        }
      );
    });
  };

  return onSubmit;
}
