import { useWidgetQuery } from '@services/api/hooks/useWidgetQuery';
import get from 'lodash/get';
import set from 'lodash/set';
import { useMemo } from 'react';

import { FormItemType, generateFormObject, normalizeFileList, SchemaDataType } from '../../common';

export function useInitialWidgetValues({ widgetSlug, widgetSchema }) {
  const { widget: firstWidget, isWidgetFetching } = useWidgetQuery({
    params: {
      slug: widgetSlug,
    },
  });

  const initialWidgetValues = useMemo(() => {
    if (!firstWidget || !widgetSchema) return {};

    const _initialWidgetValues = {
      ...firstWidget,
    };
    for (const [key, widget] of Object.entries(widgetSchema ?? {})) {
      const {
        widget: _widget,
        name,
        label,
        ...inputs
      } = get(generateFormObject({ widget, prefix: 'attributes' }), key, {});
      const kwargs = _initialWidgetValues.attributes_kwargs[key] ?? {};
      for (const input of Object.values(inputs)) {
        if (input.type === SchemaDataType.FILE || input.type === SchemaDataType.IMAGE) {
          let value = get(firstWidget, input.name);
          if (_widget === FormItemType.MULTI_NESTED) {
            const _namePath = input.name.split('.').slice(0, -1);
            const values = get(firstWidget, _namePath.join('.'));
            if (values?.length > 0) {
              for (const index in values) {
                const _name = [..._namePath, index, input.key].join('.');
                const _value = get(firstWidget, _name);
                set(
                  _initialWidgetValues,
                  _name,
                  normalizeFileList(_value, kwargs?.[index]?.value?.[input.key])
                );
              }
            }
          } else if (input.multi) {
            if (value) {
              const multiValues = normalizeFileList(value, kwargs?.value?.[input.key]);
              set(_initialWidgetValues, input.name, [multiValues]);
            }
          } else {
            set(
              _initialWidgetValues,
              input.name,
              normalizeFileList(value, kwargs?.value?.[input.key])
            );
          }
        }
      }
    }
    return _initialWidgetValues;
  }, [firstWidget, widgetSchema]);

  return {
    initialWidgetValues,
    isWidgetFetching,
  };
}
