import './styles.scss';

import { Table } from 'antd';
import clsx from 'clsx';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';
import React from 'react';

/**
 * @param {import('antd').TableProps} props
 */
const HeadlessAkinonTable = ({ columns, rowClassName, className, ...otherProps }) => {
  const columnsWithUpperCasedTitles = columns.map((column) => ({
    ...column,
    title: isString(column.title) ? column.title?.toUpperCase() : column.title,
  }));

  return (
    <Table
      className={clsx('headless-akinon-table', className)}
      rowClassName={(record, index) =>
        (index % 2 === 0 ? 'even-row' : 'odd-row').concat(
          isFunction(rowClassName) ? ` ${rowClassName(record, index)}` : ` ${rowClassName}`
        )
      }
      columns={columnsWithUpperCasedTitles}
      {...otherProps}
    />
  );
};

export default HeadlessAkinonTable;
