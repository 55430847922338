import keyBy from 'lodash/keyBy';
import merge from 'lodash/merge';
import values from 'lodash/values';

import { filterOutDangerousDynamicSettings } from '../common';
import { useCreateDynamicSettingMutation } from './api/useCreateDynamicSettingMutation';
import { useDeleteDynamicSettingMutation } from './api/useDeleteDynamicSettingMutation';
import { useDynamicSettingDetailQuery } from './api/useDynamicSettingDetailQuery';
import { useDynamicSettingsListQuery } from './api/useDynamicSettingsListQuery';
import { useDynamicSettingsRegistryQuery } from './api/useDynamicSettingsRegistryQuery';
import { useUpdateDynamicSettingMutation } from './api/useUpdateDynamicSettingMutation';

export const useDynamicSettingsDataSource = (props = {}) => {
  const { dynamicSettingId, dynamicSettingMutationOptions } = props;
  const {
    dynamicSettingsList,
    isDynamicSettingsListFetching,
    isDynamicSettingsListLoading,
    dynamicSettingsListError,
  } = useDynamicSettingsListQuery();

  const {
    dynamicSettingsRegistry,
    isDynamicSettingsRegistryFetching,
    isDynamicSettingsRegistryLoading,
    dynamicSettingsRegistryError,
  } = useDynamicSettingsRegistryQuery();

  const { dynamicSettingDetail, isDynamicSettingLoading } = useDynamicSettingDetailQuery({
    dynamicSettingId,
  });

  const { updateDynamicSetting, isDynamingSettingUpdating } = useUpdateDynamicSettingMutation({
    mutationOptions: dynamicSettingMutationOptions,
  });

  const { createDynamicSetting, isDynamicSettingCreating } = useCreateDynamicSettingMutation({
    mutationOptions: dynamicSettingMutationOptions,
  });

  const { deleteDynamicSetting, isDynamicSettingDeleting } = useDeleteDynamicSettingMutation({
    mutationOptions: dynamicSettingMutationOptions,
  });

  const dynamicSettingsWithValidators = values(
    merge(keyBy(dynamicSettingsRegistry, 'key'), keyBy(dynamicSettingsList, 'key'))
  );

  const safeDynamicSettingsWithValidators = filterOutDangerousDynamicSettings(
    dynamicSettingsList,
    dynamicSettingsWithValidators
  );

  return {
    dynamicSettingsDataSource: safeDynamicSettingsWithValidators,
    isDynamicSettingsDataSourceLoading:
      isDynamicSettingsListLoading ||
      isDynamicSettingsListFetching ||
      isDynamicSettingsRegistryLoading ||
      isDynamicSettingsRegistryFetching,
    dynamicSettingsDataSourceError: dynamicSettingsListError || dynamicSettingsRegistryError,
    totalDynamicSettings: safeDynamicSettingsWithValidators?.length,
    updateDynamicSetting,
    isDynamingSettingUpdating,
    createDynamicSetting,
    isDynamicSettingCreating,
    deleteDynamicSetting,
    isDynamicSettingDeleting,
    dynamicSettingDetail,
    isDynamicSettingLoading,
  };
};
