import './styles.scss';

import { Form } from 'antd';
import clsx from 'clsx';
import pick from 'lodash/pick';
import React from 'react';
import { FormItem } from 'react-hook-form-antd';

/**
 * @param {import('antd').FormProps} props
 */
function AkinonForm({ children, className, isFlex, control, schema, ...otherProps }) {
  const wrapChildren = (children) => {
    return Array.isArray(children)
      ? children.map((child) => {
          if (Array.isArray(child)) return child.map((c) => wrapChildren(c));
          else return wrapChild(child);
        })
      : wrapChild(children);
  };

  const wrapChild = (child) => {
    const isHtmlElement = typeof child?.type === 'string';
    if (child.type.toString().includes('Dragger')) return child;

    if (isHtmlElement && child?.props?.children) return wrapChildren(child?.props?.children);
    const formItemProps = pick(child?.props, ['key', 'label', 'name', 'wrapperCol', 'required']);
    // pass-through non-input elements (buttons, divs etc.)
    if (!formItemProps?.name) {
      return child;
    }
    const shape = schema?.shape ?? schema;

    let requiredInSchema = true;

    if (shape?.[formItemProps?.name]?.isOptional?.()) {
      requiredInSchema = false;
    }

    return (
      <FormItem
        key={formItemProps?.key}
        control={control}
        {...formItemProps}
        required={formItemProps?.required ?? requiredInSchema}
      >
        {child}
      </FormItem>
    );
  };

  const _children = control ? wrapChildren(children) : children;

  return (
    <Form
      aria-label="form"
      className={clsx('akinon-form', className, {
        'flex flex-wrap gap-1 justify-between': isFlex,
      })}
      {...otherProps}
    >
      {_children}
    </Form>
  );
}

export default AkinonForm;
