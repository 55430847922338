import { defaultReportWidgetTypes } from '@constants/commontypes';
import snakeCase from 'lodash/snakeCase';

import { FormFieldKey, SchemaType } from './common';

const getDisabledDate = (form) => (currentDate) => {
  const startDate = form.getFieldValue(FormFieldKey.START_DATE);
  if (!startDate) return false;

  return currentDate > startDate.clone().add(31, 'days') || currentDate < startDate;
};

export default function getDynamicFormFields({ t, currentSchema, form }) {
  const result = Object.keys(currentSchema).map((schemaField) => {
    const currentSchemaType = snakeCase(currentSchema[schemaField].type);
    return {
      name: schemaField,
      key: schemaField,
      required: currentSchema[schemaField].required,
      label: currentSchema[schemaField].label,
      colSpan: currentSchemaType === SchemaType.DATE ? 2 : 4,
      widgetProps: {
        ...(schemaField === FormFieldKey.END_DATE && {
          disabledDate: getDisabledDate(form),
        }),
      },
      extra: schemaField === FormFieldKey.START_DATE ? t('reporting.maxDays') : null,
      widget: defaultReportWidgetTypes[currentSchemaType],
      ...((currentSchemaType === SchemaType.CHOICE ||
        currentSchemaType === SchemaType.PK_FIELD) && {
        options:
          currentSchemaType === SchemaType.PK_FIELD
            ? []
            : currentSchema[schemaField]?.choices?.map?.((item, index) => {
                return { pk: index, label: item.display_name, value: item.value };
              }),
      }),
    };
  });

  return result;
}
