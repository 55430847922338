import AkinonButton from '@components/AkinonButton';
import Modal from '@components/Modal/Modal';
import { Image } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import keys from 'lodash/keys';
import omit from 'lodash/omit';
import values from 'lodash/values';
import { useTranslation } from 'react-i18next';

import { DataIndex } from '../common';

const RequestedAttributesSummary = ({
  attributes,
  requestedAttributes,
  record,
  setIsRevisionDetailModalVisible,
  setRevisionDetailModalInfo,
}) => {
  const { t } = useTranslation('ProductsAndCategories');
  const { revisionImages, images } = record;
  const requestedName = record.name;
  const currentName = record?.[DataIndex.CURRENT_ATTRIBUTES]?.name;
  const hasLongItems =
    keys(requestedAttributes).length > 3 ||
    values(requestedAttributes).some((value) => value.length > 150);

  const onDisplayDetailModal = () => {
    setRevisionDetailModalInfo((draft) => {
      draft.hasLongItems = hasLongItems;
      draft.currentAttributes = cloneDeep(record?.[DataIndex.CURRENT_ATTRIBUTES]);
      draft.requestedAttributes = cloneDeep(requestedAttributes);
      draft.record = cloneDeep(record);
    });
    setIsRevisionDetailModalVisible(true);
  };
  const shouldShowImages = revisionImages?.length > 0 || images?.length > 0;

  return (
    <div className="new-offer-attributes">
      {requestedName !== currentName && (
        <div className="offer-attribute">
          <div className="offer-attribute-name">{t('product_name')}:</div>{' '}
          <div className="offer-attribute-value">{requestedName ?? '-'}</div>
        </div>
      )}
      {shouldShowImages && (
        <div className="revision-images-container">
          <div className="offer-attribute-name">
            {t('product_images')}
            {!revisionImages && ': -'}
          </div>
          {revisionImages && (
            <div className="revision-images-container__images">
              <Image.PreviewGroup>
                {revisionImages.slice(0, 3).map((item) => (
                  <Image width={50} key={item.id} src={item.image} />
                ))}
                {revisionImages.slice(3, -1).map((item) => (
                  <Image
                    wrapperStyle={{ display: 'none' }}
                    width={50}
                    key={item.id}
                    src={item.image}
                  />
                ))}
              </Image.PreviewGroup>
              {revisionImages?.length > 3 && (
                <div
                  className="revision-images-container__images-more"
                  onClick={() => {
                    Modal.confirm({
                      title: t('product_images'),
                      icon: null,
                      maskClosable: true,
                      width: 800,
                      content: (
                        <div className="columns-1 md:columns-2 xl:columns-3">
                          {revisionImages.map((item) => (
                            <div key={item.id} className="m-2">
                              <Image src={item.image} />
                            </div>
                          ))}
                        </div>
                      ),
                      okButtonProps: { style: { display: 'none' } },
                      cancelButtonProps: { style: { display: 'none' } },
                      closable: true,
                    });
                  }}
                >
                  {t('n_more', { count: revisionImages?.length - 3 })}
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {Object.entries(omit(requestedAttributes, 'images') ?? {})
        .slice(0, 2)
        .map(([key, value]) => {
          const attributeName = attributes.find((attribute) => attribute.key === key)?.name ?? key;

          return (
            <div className="offer-attribute" key={key}>
              <div className="offer-attribute-name">{attributeName}:</div>{' '}
              <div className="offer-attribute-value">{value ?? '-'}</div>
            </div>
          );
        })}
      {hasLongItems && (
        <AkinonButton type="link" onClick={onDisplayDetailModal}>
          {t('view_revision_details', { count: keys(requestedAttributes).length })}
        </AkinonButton>
      )}
    </div>
  );
};

export default RequestedAttributesSummary;
