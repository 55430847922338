import { getTotalAmount } from '@common/index';
import isEmpty from 'lodash/isEmpty';

export const getPreOrderAmountData = ({ orderDetail, t } = {}) => {
  if (isEmpty(orderDetail)) return [];

  const { amount, discount_amount, shipping_amount, commission_amount } = orderDetail;

  return [
    {
      value: amount,
      label: t('order_amount'),
    },
    {
      value: shipping_amount,
      label: t('shipping_amount'),
    },
    {
      value: discount_amount,
      label: t('discount_amount'),
    },
    {
      value: commission_amount,
      label: t('commission.amount'),
    },
  ];
};

export const getCurrentOrderAmountData = ({ orderDetail, t } = {}) => {
  if (isEmpty(orderDetail)) return [];

  const {
    discount_amount,
    discount_refund_amount,
    amount,
    shipping_interest_amount,
    shipping_refund_amount,
    shipping_amount,
    refund_amount,
    commission_amount,
  } = orderDetail;

  return [
    {
      value: (getTotalAmount({ amount, discount_amount }) - refund_amount).toFixed(2),
      label: t('order_amount'),
    },
    {
      value: (
        parseFloat(shipping_amount) +
        parseFloat(shipping_interest_amount) -
        parseFloat(shipping_refund_amount)
      ).toFixed(2),
      label: t('shipping_amount'),
    },
    {
      value: (discount_amount - discount_refund_amount).toFixed(2),
      label: t('discount_amount'),
    },
    {
      value: commission_amount,
      label: t('commission.amount'),
    },
  ];
};
