import { createSelectOptions } from '@common/index';

import {
  isModalVisibleMapInitialState,
  PRODUCT_ATTRIBUTES,
  ProductsToBeAddedTableFormFormItemKey,
} from '../../common';
import BulkAttributeUpdateModal from '../BulkUpdateProductModal/BulkAttributeUpdateModal';
import BulkPriceUpdateModal from '../BulkUpdateProductModal/BulkPriceUpdateModal';
import BulkStockUpdateModal from '../BulkUpdateProductModal/BulkStockUpdateModal';

const ActionButtonsFormModalList = ({
  dataSource,
  productsToBeAddedTableForm,
  isModalVisibleMap,
  setIsModalVisibleMap,
  productPricesOptions,
  variantListAttributeFields,
  variantDynamicFields,
  variants,
}) => {
  const currencyOptions = createSelectOptions(
    productPricesOptions?.actions?.POST.currency_type.choices,
    {
      valueKey: 'value',
      labelKey: 'display_name',
    }
  );

  const handleSetInvisible = () => {
    setIsModalVisibleMap({
      ...isModalVisibleMapInitialState,
    });
  };

  return (
    <>
      <BulkPriceUpdateModal
        isVisible={isModalVisibleMap[ProductsToBeAddedTableFormFormItemKey.PRICE]}
        form={productsToBeAddedTableForm}
        setInvisible={handleSetInvisible}
        dataSource={dataSource}
        variants={variants}
        currencyOptions={currencyOptions}
        variantDynamicFields={variantDynamicFields}
      />
      <BulkStockUpdateModal
        isVisible={isModalVisibleMap[ProductsToBeAddedTableFormFormItemKey.STOCK]}
        form={productsToBeAddedTableForm}
        setInvisible={handleSetInvisible}
        dataSource={dataSource}
        variants={variants}
        variantDynamicFields={variantDynamicFields}
      />
      <BulkAttributeUpdateModal
        isVisible={isModalVisibleMap[PRODUCT_ATTRIBUTES]}
        form={productsToBeAddedTableForm}
        setInvisible={handleSetInvisible}
        dataSource={dataSource}
        variants={variants}
        variantListAttributeFields={variantListAttributeFields}
        variantDynamicFields={variantDynamicFields}
      />
    </>
  );
};

export default ActionButtonsFormModalList;
