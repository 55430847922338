import { getRequestFiltersParams } from '@common/index';
import { exportOrdersUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { useAkinonQuery } from '../adapters/useAkinonQuery';
import { client } from '../client';

/**
 * @param {{ requestConfig: import('axios').AxiosRequestConfig, queryOptions: import('@tanstack/react-query').UseQueryOptions }} args
 */
export function useExportOrdersQuery({ requestConfig, queryOptions, onSuccess, filters }) {
  const filterParams = getRequestFiltersParams(filters);
  const params = {
    ...requestConfig?.params,
    ...filterParams,
  };
  const _requestConfig = {
    ...requestConfig,
    params,
  };
  const queryResponse = useAkinonQuery({
    queryKey: [QueryKey.EXPORT_ORDERS, _requestConfig],
    async queryFn() {
      const response = await client.get(exportOrdersUrl, _requestConfig);
      onSuccess?.(response);
      return response;
    },
    enabled: false,
    ...queryOptions,
  });

  return queryResponse;
}
