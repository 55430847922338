import omit from 'lodash/omit';
import React from 'react';
import { Link } from 'react-router-dom';

/**
 *
 * @param {import('react-router-dom').LinkProps} props
 */
const AkinonLink = (props) => {
  const path = props.to;
  const omittedProps = omit(props, ['to', 'children']);
  const { children } = props;

  return (
    <Link {...omittedProps} to={path}>
      {children}
    </Link>
  );
};

export default AkinonLink;
