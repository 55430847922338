import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { updateDynamicSetting } from '@services/api/dynamicSettings';

export function useUpdateDynamicSettingMutation({ mutationOptions = {} }) {
  const mutationResponse = useAkinonMutation({
    mutationFn: updateDynamicSetting,
    ...mutationOptions,
  });

  return {
    updateDynamicSetting: mutationResponse.mutate,
    isDynamingSettingUpdating: mutationResponse.isLoading,
  };
}
