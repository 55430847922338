import { approveDataSourceLandingPageUrl } from '@constants/apiUrls';

import { useAkinonMutation } from '../adapters/useAkinonMutation';
import { client } from '../client';

export function useApproveDataSourceLandingPageMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ dataSourceLandingPageId, requestBody, requestConfig }) => {
      return client.post(approveDataSourceLandingPageUrl, requestBody, {
        urlParams: { dataSourceLandingPageId },
        ...requestConfig,
      });
    },
    ...mutationOptions,
  });

  return {
    approveDataSourceLandingPage: mutate,
    isApprovingDataSourceLandingPage: isLoading,
  };
}
