import { getImportDetailUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { client } from '@services/api/client';
import { usePaginatedImportsQuery } from '@services/api/hooks/usePaginatedImportsQuery';
import { useQueries } from '@tanstack/react-query';
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import reject from 'lodash/reject';
import uniq from 'lodash/uniq';

export function useDataSource({ pagination, filters }) {
  const { imports, isFetchingImports, totalImports } = usePaginatedImportsQuery({
    pagination,
    filters,
  });
  const importIds = reject(uniq(map(imports, 'id')), isNil);
  const importDetailsQueriesResult = useQueries({
    queries: importIds.map((id) => ({
      queryKey: [QueryKey.IMPORT_DETAIL, id],
      queryFn: () => client.get(getImportDetailUrl, { urlParams: { key: id } }),
    })),
  });
  const importDetails = map(
    reject(importDetailsQueriesResult, ({ data }) => isNil(data)),
    'data'
  );

  const dataSource = map(imports, (importItem) => {
    const importDetail = find(importDetails, { id: importItem.id });
    return {
      ...importItem,
      ...importDetail,
    };
  });
  return {
    dataSource,
    isLoading: isFetchingImports,
    total: totalImports,
  };
}
