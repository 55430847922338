import useStore from '@zustand-store/index';
import { setPageMetaSelector } from '@zustand-store/selectors/app';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

import { StatusKey } from '../StoreManagementList/common';

export function usePageMeta({
  isSuperUser,
  dataSourceLandingPage,
  handleApproveModalOpen,
  handleRejectModalOpen,
}) {
  const { t } = useTranslation('SellerStoreManagement');
  const setPageMeta = useStore(setPageMetaSelector);

  const status = dataSourceLandingPage?.status;

  useEffect(() => {
    setPageMeta({
      title: t('seller_store_management'),
      description: t('seller_store_management_description'),
      actions: isSuperUser &&
        status === StatusKey.WAITING_APPROVAL && [
          {
            label: t('approve'),
            type: 'primary',
            onClick: handleApproveModalOpen,
          },
          {
            label: t('reject'),
            type: 'danger',
            onClick: handleRejectModalOpen,
          },
        ],
    });
  }, [t, status, isSuperUser]);

  useUnmount(() => {
    setPageMeta({});
  });
}
