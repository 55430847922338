import { defaultDateFormat } from '@constants/index';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CampaignInformation = ({ basketOfferDetail }) => {
  const { t } = useTranslation('Marketing');

  const startDate = basketOfferDetail?.start_datetime;
  const endDate = basketOfferDetail?.end_datetime;
  const campaignName = basketOfferDetail?.promotion?.name;
  const status = basketOfferDetail?.status;
  const discountSharingRate = basketOfferDetail?.basket_offer_kwargs?.discount_sharing_rate;

  return (
    <div className="campaign-information">
      <div className="detail-row">
        <div className="detail-title">{t('campaign_name')}</div>
        <div className="detail-value">{campaignName}</div>
      </div>
      <div className="detail-row">
        <div className="detail-title">{t('start_date')}</div>
        <div className="detail-value">
          {startDate ? moment(startDate).format(defaultDateFormat) : '-'}
        </div>
      </div>
      <div className="detail-row">
        <div className="detail-title">{t('end_date')}</div>
        <div className="detail-value">
          {endDate ? moment(basketOfferDetail.end_datetime).format(defaultDateFormat) : '-'}
        </div>
      </div>
      <div className="detail-row">
        <div className="detail-title">{t('status')}</div>
        <div className="detail-value">{t(status)}</div>
      </div>
      <div className="detail-row">
        <div className="detail-title">{t('discount_sharing_rate')}</div>
        <div className="detail-value">{discountSharingRate ? `${discountSharingRate}%` : '-'}</div>
      </div>
    </div>
  );
};

export default CampaignInformation;
