import { getAttributeOptionsUrl } from '@constants/apiUrls';
import { limitQuery } from '@constants/commontypes';
import { QueryKey } from '@constants/query';

import { useAkinonQuery } from '../adapters/useAkinonQuery';
import { client } from '../client';

export function useAttributeValuesQuery({ queryOptions, onSuccess, params } = {}) {
  const { data, isFetching, isLoading, error } = useAkinonQuery({
    queryKey: [QueryKey.ATTRIBUTE_VALUES, params],
    async queryFn() {
      const response = await client.get(getAttributeOptionsUrl, {
        params: {
          ...limitQuery,
          ...params,
        },
      });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    attributeValues: data?.results ?? [],
    isAttributeValuesLoading: isLoading,
    isFetchingAttributeValues: isFetching,
    attributeValuesError: error,
  };
}
