import { AttributeDataType, limitQuery } from '@constants/commontypes';
import { useAttributeSetQuery } from '@services/api/hooks/useAttributeSetQuery';
import { useAttributesQuery } from '@services/api/hooks/useAttributesQuery';
import { useAttributeValuesQuery } from '@services/api/hooks/useAttributeValuesQuery';

export const useProductDetail = ({ product, isFetch }) => {
  const { attributeSet, isFetchingAttributeSet } = useAttributeSetQuery({
    attributeSetId: product?.product_obj?.attribute_set,
    queryOptions: {
      enabled: isFetch,
    },
  });

  const attributeIds = attributeSet?.attributes ?? [];

  const { attributes, isFetchingAttributes } = useAttributesQuery({
    params: {
      attribute__in: attributeIds.join(','),
      ...limitQuery,
    },
    queryOptions: {
      enabled: !isFetchingAttributeSet && isFetch,
    },
  });

  const productAttributes = attributes?.filter(
    (attribute) =>
      product?.product_obj?.attributes && attribute.key in product.product_obj.attributes
  );

  const productAttributeIds = productAttributes
    ?.filter((attribute) => attribute.data_type === AttributeDataType.DROPDOWN)
    ?.map((attribute) => attribute.id);

  const { attributeValues, isFetchingAttributeValues } = useAttributeValuesQuery({
    params: {
      attribute__in: productAttributeIds?.join(','),
      ...limitQuery,
    },
    queryOptions: {
      enabled: !isFetchingAttributes,
    },
  });

  const productDetailAttributes = productAttributes?.map((productAttribute) => {
    const attribute = attributeValues
      ?.filter((attributeValue) => attributeValue.attribute === productAttribute.id)
      .reduce((acc, attributeValue) => {
        if (attributeValue.value === product?.product_obj?.attributes[productAttribute.key]) {
          return {
            ...acc,
            ...attributeValue,
          };
        }
        return acc;
      }, {});

    return {
      ...productAttribute,
      attribute_value: attribute?.value ?? product?.product_obj?.attributes[productAttribute.key],
      attribute_label: attribute?.label,
    };
  }, {});

  const productDetail = {
    ...product,
    product_obj: {
      ...product?.product_obj,
      attributes: productDetailAttributes,
    },
  };

  return {
    productDetail,
    isProductDetailLoading:
      isFetchingAttributeValues || isFetchingAttributes || isFetchingAttributeSet,
  };
};
