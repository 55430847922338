import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { getExportsUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { useAkinonQuery } from '../adapters/useAkinonQuery';
import { client } from '../client';

export function usePaginatedExportsQuery({ queryOptions, filters, pagination, params }) {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.EXPORTS, filters, pagination, params],
    queryFn() {
      return client.get(getExportsUrl, {
        params: {
          ...getRequestFiltersParams(filters),
          ...getRequestPaginationParams(pagination),
          ...params,
        },
      });
    },
    ...queryOptions,
  });

  return {
    exports: data?.results,
    totalExports: data?.count,
    isFetchingExports: isFetching,
  };
}
