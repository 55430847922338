import { postLoginAsUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';

import { client } from '../client';

/**
 * @param {{ mutationOptions: import('@tanstack/react-query').UseMutationOptions }} args
 */
export function useLoginAsMutation({ mutationOptions = {} } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody, userId }) =>
      client.post(postLoginAsUrl, requestBody, { urlParams: { userId } }),
    ...mutationOptions,
  });

  return {
    postLoginAs: mutate,
    isPostingLoginAs: isLoading,
  };
}
