import './style.scss';

import React, { useEffect, useState } from 'react';

import { buildTransformString } from './common';

const Animate = ({ initial = {}, animate = {}, transition, children, isVisible }) => {
  const { otherStyles: initialStyles } = initial;
  const { otherStyles: animatedStyles } = animate;
  const [currentStyles, setCurrentStyles] = useState(initialStyles);

  let initialTransform = buildTransformString(initial);
  let animatedTransform = buildTransformString(animate);

  useEffect(() => {
    if (isVisible) {
      setCurrentStyles({ ...animatedStyles, transform: animatedTransform });
    } else {
      setCurrentStyles({ ...initialStyles, transform: initialTransform });
    }
  }, [isVisible]);

  return (
    <div className="akinon-animate" style={{ ...currentStyles, transition }}>
      {children}
    </div>
  );
};

export default Animate;
