import { createSelectOptions } from '@common/index';
import { UserRole } from '@constants/auth';
import { WidgetType } from '@constants/commontypes';
import { usePaginatedDataSourcesQuery } from '@services/hooks/datasources/usePaginatedDataSourcesQuery';
import { useUserRole } from '@utils/hooks/useUserRole';
import { useCallback } from 'react';

import { StaticFilterKey } from '../common';

export function useStaticFilters({ t }) {
  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;
  const { data: dataSources, isLoading: isDataSourcesLoading } = usePaginatedDataSourcesQuery({
    ids: [],
  });

  const filterOption = useCallback(
    (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
    []
  );

  const staticFiltersFormMetaFields = [
    isSuperUser && {
      widget: WidgetType.SELECT,
      key: StaticFilterKey.DATASOURCE,
      placeholder: t('seller'),
      options: createSelectOptions(dataSources, {
        labelKey: 'name',
        valueKey: 'id',
      }),
      widgetProps: {
        showSearch: true,
        optionFilterProp: 'children',
        filterOption,
      },
    },
    {
      widget: WidgetType.INPUT,
      key: StaticFilterKey.ORDER,
      placeholder: t('order.number'),
    },
  ].filter(Boolean);

  return {
    staticFiltersFormMetaFields,
    isStaticFiltersLoading: isDataSourcesLoading,
  };
}
