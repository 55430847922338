import { postProductImageRevisionUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { client } from '@services/api/client';

export function usePostCreateProductRevisionImageMutation({ mutationOptions } = {}) {
  const { mutate, isLoading, mutateAsync } = useAkinonMutation({
    mutationFn: ({ formData }) =>
      client.post(postProductImageRevisionUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    ...mutationOptions,
  });

  return {
    createProductImageRevision: mutate,
    asyncCreateProductImageRevision: mutateAsync,
    isCreatingProductImageRevision: isLoading,
  };
}
