export const getMenuItemLevelKeys = (items) => {
  const keys = {};
  const func = (items, level = 1) => {
    items.forEach((item) => {
      if (item.key) {
        keys[item.key] = level;
      }
      if (item.children) {
        func(item.children, level + 1, item.key);
      }
    });
  };
  func(items);
  return keys;
};

export const findCurrentOpenKeys = (menuItems, targetKey) => {
  const result = [];

  function findPath(items, currentPath = []) {
    for (let item of items) {
      const newPath = [...currentPath, item.key];

      if (item.key === targetKey) {
        result.push(...newPath);
        return true;
      }

      if (item.children && findPath(item.children, newPath)) {
        return true;
      }
    }
    return false;
  }

  findPath(menuItems);

  return result;
};
