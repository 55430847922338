import { postNotificationAttachmentUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useQueryClient } from '@tanstack/react-query';
import omit from 'lodash/omit';

import { useAkinonMutation } from '../adapters/useAkinonMutation';
import { client } from '../client';

export function usePostNotificationAttachmentMutation({ mutationOptions } = {}) {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: async ({ requestBody, notificationId }) =>
      await client.post(postNotificationAttachmentUrl, requestBody, {
        urlParams: {
          id: notificationId,
        },
      }),
    onSuccess() {
      mutationOptions?.onSuccess?.();
      queryClient.invalidateQueries([QueryKey.NOTIFICATIONS]);
    },
    ...omit(mutationOptions, ['onSuccess']),
  });

  return {
    postNotificationAttachment: mutate,
    isPostingNotificationAttachment: isLoading,
  };
}
