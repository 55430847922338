import './style.scss';

import AkinonBox from '@components/AkinonBox';
import AkinonButton from '@components/AkinonButton';
import AkinonFlex from '@components/AkinonFlex';
import AkinonForm from '@components/AkinonForm';
import AkinonFormItem from '@components/AkinonFormItem';
import AkinonInput from '@components/AkinonInput';
import AkinonSelect from '@components/AkinonSelect';
import AkinonSwitch from '@components/AkinonSwitch';
import { Color } from '@constants/theme';
import { usePostDataSourceMutation } from '@services/api/hooks/usePostDataSourceMutation';
import { usePostSellerMutation } from '@services/api/hooks/usePostSellerMutation';
import { Form } from 'antd';
import { get, isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getPeriodProperties } from '../SellerManagementDetail/common';
import { SellerSegment } from '../SellerManagementList/common';
import { FormKey, getFormResolver } from './common';
import { useOnSubmit } from './hooks/useOnSubmit';

function NewSellerForm({ setIsNewSellerFormVisible, queryKey }) {
  const { t } = useTranslation('Finance');

  const { createDataSource, isCreatingDataSource } = usePostDataSourceMutation();
  const [createdDataSource, setCreatedDataSource] = useState(null);
  const { createSeller, isCreatingNewSeller } = usePostSellerMutation();

  const successMessage = t('add_success', { variable: t('seller.new') });
  const onSubmit = useOnSubmit({
    createdDataSource,
    setCreatedDataSource,
    createSeller,
    createDataSource,
    queryKey,
    setIsNewSellerFormVisible,
    successMessage,
  });

  const resolver = getFormResolver({ t });
  const { control, handleSubmit } = useForm({
    mode: 'onChange',
    resolver,
  });
  const allFormValues = useWatch({ control });
  const strategyType = get(
    allFormValues,
    'conf.reconciliation_conf.creation_strategy.strategy_type'
  );

  useEffect(() => {
    const dataSourceName = allFormValues[FormKey.NAME];
    const dataSourceSupplierCode = allFormValues[FormKey.SUPPLIER_CODE];
    const isExistingDataSource =
      dataSourceName !== createdDataSource?.[FormKey.NAME] ||
      dataSourceSupplierCode !== createdDataSource?.[FormKey.SUPPLIER_CODE];

    if (createdDataSource && isExistingDataSource) {
      setCreatedDataSource(null);
    }
  }, [allFormValues, createdDataSource]);

  const isStrategyDaily = strategyType === 'daily';
  const isStrategyWeekly = strategyType === 'weekly';

  return (
    <section className="new-seller-form">
      <AkinonBox
        className="box-primary form-box new-seller-form__box"
        title={t('seller_form_new')}
        description={t('seller_form_desc')}
        bannerColor={Color.LIMA}
      >
        <AkinonForm className="akn-form akn-form-v2" layout="vertical">
          <AkinonFormItem label={t('company_name')} name={FormKey.NAME} control={control} required>
            <AkinonInput
              aria-label={t('company_name')}
              placeholder={t('enter_variable', { variable: t('company_name') })}
            />
          </AkinonFormItem>
          <AkinonFormItem
            label={t('seller_code')}
            name={FormKey.SUPPLIER_CODE}
            control={control}
            required
          >
            <AkinonInput
              aria-label={t('seller_code')}
              placeholder={t('enter_variable', { variable: t('seller_code') })}
            />
          </AkinonFormItem>
          <AkinonFormItem
            label={t('authorized_email_address')}
            name={FormKey.EMAIL}
            control={control}
            required
          >
            <AkinonInput
              aria-label={t('authorized_email_address')}
              placeholder={t('enter_variable', { variable: t('authorized_email_address') })}
            />
          </AkinonFormItem>
          <AkinonFormItem
            label={t('authorized_firstname')}
            name={FormKey.FIRST_NAME}
            control={control}
          >
            <AkinonInput
              aria-label={t('authorized_firstname')}
              placeholder={t('enter_variable', { variable: t('authorized_firstname') })}
            />
          </AkinonFormItem>
          <AkinonFormItem
            label={t('authorized_lastname')}
            name={FormKey.LAST_NAME}
            control={control}
          >
            <AkinonInput
              aria-label={t('authorized_lastname')}
              placeholder={t('enter_variable', { variable: t('authorized_lastname') })}
            />
          </AkinonFormItem>
          <AkinonFormItem
            label={t('supplier_segment')}
            name={FormKey.SUPPLIER_SEGMENT}
            control={control}
          >
            <AkinonSelect
              aria-label={t('supplier_segment')}
              options={[
                {
                  value: SellerSegment.STANDARD,
                  label: t('standard'),
                },
                {
                  value: SellerSegment.PREMIUM,
                  label: t('premium'),
                },
              ]}
              placeholder={t('enter_variable', { variable: t('supplier_segment') })}
            />
          </AkinonFormItem>
          <AkinonFormItem
            name={FormKey.IS_ACTIVE}
            label={t('status')}
            control={control}
            initialValue={true}
          >
            <AkinonSwitch
              aria-label={t('status')}
              checkedChildren={t('active')}
              unCheckedChildren={t('passive')}
            />
          </AkinonFormItem>
          <AkinonFormItem
            name={FormKey.AUTO_APPROVE_ENABLED}
            label={t('seller_auto_approve')}
            control={control}
            initialValue={false}
          >
            <AkinonSwitch
              aria-label={t('seller_auto_approve')}
              checkedChildren={t('active')}
              unCheckedChildren={t('passive')}
            />
          </AkinonFormItem>
          <AkinonFormItem
            name={FormKey.STOCK_RELEASE_TYPE}
            label={t('stock_release_type')}
            control={control}
            initialValue={false}
          >
            <AkinonSwitch
              aria-label={t('stock_release_type')}
              checkedChildren={t('manual')}
              unCheckedChildren={t('automatic')}
            />
          </AkinonFormItem>

          <AkinonFlex direction="column" className="gap-4">
            <AkinonFlex justifyContent="space-between" alignItems="center">
              <AkinonFormItem
                isWithColumn
                colSpan={11}
                label={t('reconciliation_period')}
                name={'conf.reconciliation_conf.creation_strategy.strategy_type'}
                control={control}
                required
              >
                <AkinonSelect
                  aria-label={t('reconciliation_period')}
                  placeholder={t('enter_variable', { variable: t('reconciliation_period') })}
                  options={[
                    {
                      id: '1',
                      label: t('daily'),
                      value: 'daily',
                    },
                    {
                      id: '2',
                      label: t('weekly'),
                      value: 'weekly',
                    },
                    {
                      id: '3',
                      label: t('monthly'),
                      value: 'monthly',
                    },
                  ]}
                />
              </AkinonFormItem>

              {!isNil(strategyType) && !isStrategyDaily && (
                <AkinonFormItem
                  isWithColumn
                  colSpan={11}
                  label={t('set_day')}
                  name={
                    isStrategyWeekly
                      ? 'conf.reconciliation_conf.creation_strategy.day_of_week'
                      : 'conf.reconciliation_conf.creation_strategy.day_of_month'
                  }
                  control={control}
                  required={!isStrategyDaily}
                >
                  <AkinonSelect
                    aria-label={t('set_day')}
                    placeholder={t('enter_variable', { variable: t('set_day') })}
                    options={getPeriodProperties({ t })?.[strategyType]?.items}
                  />
                </AkinonFormItem>
              )}
            </AkinonFlex>

            <AkinonFlex justifyContent="space-between" alignItems="center">
              <AkinonFormItem
                name="conf.reconciliation_conf.item_selection_strategy.strategy_type"
                label={t('reconciliation_sales_rule')}
                isWithColumn
                colSpan={11}
                control={control}
                required
              >
                <AkinonSelect
                  aria-label={t('reconciliation_sales_rule')}
                  options={[
                    {
                      id: '1',
                      label: t('reconciliation_sale_rule_after_sale'),
                      value: 'shipped_date',
                    },
                    {
                      id: '2',
                      label: t('reconciliation_sale_rule_after_delivery'),
                      value: 'delivered_date',
                    },
                  ]}
                  placeholder={t('reconciliation_sales_rule')}
                />
              </AkinonFormItem>
              <AkinonFormItem
                name="conf.reconciliation_conf.item_selection_strategy.days_later"
                label={`${t('day')}s`}
                isWithColumn
                colSpan={11}
                control={control}
                required
              >
                <AkinonInput aria-label={`${t('day')}s`} placeholder={`+ ${t('day')}s`} />
              </AkinonFormItem>
            </AkinonFlex>
            <AkinonFormItem
              name="conf.reconciliation_conf.creation_strategy.time_zone"
              label={t('time_zone')}
              control={control}
            >
              <AkinonInput
                aria-label={t('time_zone')}
                type="number"
                role="textbox"
                placeholder={t('time_zone')}
              />
            </AkinonFormItem>
          </AkinonFlex>

          <Form.Item wrapperCol={{ span: 8 }}>
            <AkinonButton
              loading={isCreatingNewSeller || isCreatingDataSource}
              htmlType="button"
              onClick={handleSubmit(onSubmit)}
              type="primary"
              className="new-seller-form__submit-button"
            >
              {t('submit').toUpperCase()}
            </AkinonButton>
          </Form.Item>
        </AkinonForm>
      </AkinonBox>
    </section>
  );
}

export default NewSellerForm;
