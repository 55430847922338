import { postNotificationUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useQueryClient } from '@tanstack/react-query';
import omit from 'lodash/omit';

import { useAkinonMutation } from '../adapters/useAkinonMutation';
import { client } from '../client';

export function usePostNotificationMutation({ mutationOptions } = {}) {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: async ({ requestBody }) => await client.post(postNotificationUrl, requestBody),
    onSuccess() {
      mutationOptions?.onSuccess?.();
      queryClient.invalidateQueries([QueryKey.NOTIFICATIONS]);
    },
    ...omit(mutationOptions, ['onSuccess']),
  });

  return {
    postNotification: mutate,
    isPostingNotification: isLoading,
  };
}
