import { limitQuery } from '@constants/commontypes';
import { useDynamicFormFields } from '@pages/ProductsAndCategories/ProductForm/hooks/useDynamicFormFields';
import { useAttributeConfigsQuery } from '@services/api/hooks/useAttributeConfigsQuery';
import { useCategoriesQuery } from '@services/api/hooks/useCategoriesQuery';
import { useMasterProductPricesQuery } from '@services/api/hooks/useMasterProductPricesQuery';
import { useProductImagesQuery } from '@services/api/hooks/usePaginatedProductImagesQuery';
import { useProductPriceListQuery } from '@services/api/hooks/usePaginatedProductPriceListQuery';
import { useProductStockListQuery } from '@services/api/hooks/usePaginatedProductStockListQuery';
import { useProductAttributesQuery } from '@services/api/hooks/useProductAttributesQuery';
import { useProductLowestPriceQuery } from '@services/api/hooks/useProductLowestPriceQuery';
import { useProductOfferQuery } from '@services/api/hooks/useProductOfferQuery';
import { useProductQuery } from '@services/api/hooks/useProductQuery';
import { pick } from 'lodash';
import first from 'lodash/first';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import omit from 'lodash/omit';
import reject from 'lodash/reject';
import uniq from 'lodash/uniq';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

export const useApprovedOfferDetail = ({ t }) => {
  const { offerId } = useParams();
  const { productOffer, isFetchingProductOffer } = useProductOfferQuery({
    offerId,
    queryOptions: { enabled: Boolean(offerId) },
  });

  const { product, isFetchingProduct } = useProductQuery({
    productId: productOffer?.product,
    queryOptions: { enabled: Boolean(productOffer?.product) },
  });

  const attributeSetId = product?.attribute_set;

  const { productImages } = useProductImagesQuery({
    params: {
      parent__in: productOffer?.product,
    },
    queryOptions: {
      enabled: !isFetchingProductOffer && Boolean(productOffer?.product),
    },
  });

  const { productStocks, isProductStocksFetching } = useProductStockListQuery({
    requestConfig: {
      params: {
        offer: productOffer?.id,
      },
    },
    queryOptions: {
      enabled: !isFetchingProductOffer && Boolean(productOffer?.id),
    },
  });
  const { productPrices, isProductPricesFetching } = useProductPriceListQuery({
    requestConfig: {
      params: {
        offer: productOffer?.id,
      },
    },
    queryOptions: {
      enabled: !isFetchingProductOffer && Boolean(productOffer?.id),
    },
  });
  const { categories } = useCategoriesQuery();
  const { attributeConfigs } = useAttributeConfigsQuery({
    requestConfig: {
      params: {
        attribute_set: attributeSetId,
        ...limitQuery,
      },
    },
    queryOptions: { enabled: Boolean(attributeSetId) },
  });

  const attributeIds = reject(uniq(map(attributeConfigs, 'attribute')), isNil);

  const { productAttributes } = useProductAttributesQuery({
    params: {
      id__in: attributeIds.join(','),
      limit: attributeIds.length,
    },
    queryOptions: {
      enabled: !isEmpty(attributeIds),
    },
  });

  const { masterProductPrices } = useMasterProductPricesQuery({
    params: {
      limit: 1,
      product__in: productOffer?.product,
    },
    queryOptions: {
      enabled: !isEmpty(productOffer?.product),
    },
  });

  const { productLowestPrice } = useProductLowestPriceQuery({
    productId: productOffer?.product,
    queryOptions: {
      enabled: !isEmpty(productOffer?.product),
    },
  });

  const { variantListAttributeFields, variantDynamicFields } = useDynamicFormFields({
    attributeConfigs,
    productAttributes,
    forbiddenKeys: [],
    t,
  });
  const dynamicFields = useMemo(() => {
    if (isEmpty(variantDynamicFields) || isEmpty(variantListAttributeFields)) {
      return [];
    }
    return [...variantDynamicFields, ...variantListAttributeFields];
  }, [variantListAttributeFields, variantDynamicFields]);

  const isDataSourceLoading =
    isFetchingProductOffer ||
    isFetchingProduct ||
    isProductStocksFetching ||
    isProductPricesFetching;

  const dataSource = useMemo(
    () =>
      !isDataSourceLoading
        ? {
            stock: first(productStocks)?.stock,
            attributes: {
              ...pick(
                get(product, 'attributes', {}),
                map(
                  reject(dynamicFields, (field) => field.is_offer === true),
                  'key'
                )
              ),
              ...pick(
                get(productOffer, 'attributes', {}),
                map(
                  reject(dynamicFields, (field) => field.is_offer === false),
                  'key'
                )
              ),
            },
            product_image: first(productImages)?.image,
            price: first(productPrices)?.price,
            retail_price: first(productPrices)?.retail_price,
            tax_rate: first(productPrices)?.tax_rate,
            currency_type: first(productPrices)?.currency_type,
            buybox_price: first(masterProductPrices)?.price,
            lowest_price: productLowestPrice?.price,
            ...omit(productOffer, ['product', 'attributes']),
            product,
          }
        : null,
    [
      product,
      productOffer,
      productImages,
      productStocks,
      productPrices,
      masterProductPrices,
      productLowestPrice,
      isDataSourceLoading,
      dynamicFields,
    ]
  );

  return {
    productOffer,
    product,
    productImages,
    attributeSetId,
    categories,
    productStock: first(productStocks),
    productPrice: first(productPrices),
    productBuyboxPrice: first(masterProductPrices),
    productLowestPrice,
    productStocks,
    productPrices,
    dataSource,
    isDataSourceLoading,
    dynamicFields,
  };
};
