import Anchor from '@components/Anchor';
import React from 'react';

export const renderAsLink = (value, href) => <Anchor href={href}>{value}</Anchor>;

export const ProductStatus = {
  ALL: 'All Products',
  PENDING_APPROVAL: 100,
  APPROVED: 200,
  PENDING_PACKAGING: 300,
  PACKAGED: 400,
  DELIVERED_TO_SHIPPING: 500,
  DELIVERY_FAILED: 540,
  DELIVERED_TO_CUSTOMER: 600,
  CANCELLATION_WAITING: 700,
  CANCELLED_AND_RETURNED: 800,
  CANCEL_TYPE_STATUSES: '700,800',
  REJECTED: 900,
  REFUND_WAITING: 'REFUND_WAITING',
};

export const StatusText = ({ t }) => ({
  [ProductStatus.PENDING_APPROVAL]: t('waiting_approval'),
  [ProductStatus.APPROVED]: t('confirmed'),
  [ProductStatus.PENDING_PACKAGING]: t('waiting_package'),
  [ProductStatus.PACKAGED]: t('packaged'),
  [ProductStatus.DELIVERED_TO_SHIPPING]: t('shipped'),
  [ProductStatus.DELIVERY_FAILED]: t('delivery_failed'),
  [ProductStatus.DELIVERED_TO_CUSTOMER]: t('delivered'),
  [ProductStatus.CANCELLATION_WAITING]: t('waiting_cancel_return'),
  [ProductStatus.CANCELLED_AND_RETURNED]: t('cancel_return'),
  [ProductStatus.REJECTED]: t('rejected'),
  [ProductStatus.REFUND_WAITING]: t('refund_waiting'),
});

export const CancellationRequestStatus = {
  WAITING: 'waiting',
};

export const CancellationRequestType = {
  REFUND: 'refund',
  CANCEL: 'cancel',
};
