import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { limitQuery } from '@constants/commontypes';
import { getPaginatedDataSources } from '@services/api';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';

/**
 * @typedef {import('@tanstack/react-query').UseQueryResult} UseQueryResult
 * @returns {import('@tanstack/react-query').UseQueryResult}
 */
export const usePaginatedDataSourcesQuery = ({
  pagination,
  filters,
  ids,
  queryOptions,
  params = {},
  enabled = true,
}) => {
  const queryKey = ['data-sources', ids, filters, pagination];

  const queryFn = () =>
    getPaginatedDataSources({
      ...params,
      ...getRequestPaginationParams(pagination),
      ...getRequestFiltersParams(filters),
      limit: ids?.length || pagination?.pageSize || limitQuery.limit,
      id__in: ids?.join(','),
    });
  const { data, isFetching, isLoading, error } = useAkinonQuery({
    queryKey,
    queryFn,
    enabled,
    ...queryOptions,
  });

  return {
    data: data?.results,
    total: data?.count,
    isFetching,
    isLoading,
    error,
  };
};
