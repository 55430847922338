import { getRequestFiltersParams } from '@common/index';
import { getDataSources } from '@services/api';
import { useQuery } from '@tanstack/react-query';

/**
 * @typedef {import('@tanstack/react-query').UseQueryResult} UseQueryResult
 * @returns {import('@tanstack/react-query').UseQueryResult}
 */
export const useAllDataSources = ({ filters = {}, enabled = true }) => {
  const queryKey = ['all-data-sources', filters];

  const queryFn = () =>
    getDataSources({
      limit: 9999,
      ...getRequestFiltersParams(filters),
    });
  const { data, isLoading, error, ...rest } = useQuery({ queryKey, queryFn, enabled });

  return {
    data: data?.results,
    total: data?.count,
    isLoading,
    error,
    ...rest,
  };
};
