import AkinonButton from '@components/AkinonButton';
import { UserRole } from '@constants/auth';
import { useUserRole } from '@utils/hooks/useUserRole';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CampaignConditions = ({
  basketOfferDetail,
  isJoiningCampaing,
  onJoinCampaign,
  hasUserJoinedCampaign,
}) => {
  const { t } = useTranslation('Marketing');

  const userRole = useUserRole();
  const isSeller = userRole === UserRole.SELLER;

  const campaignMessage = basketOfferDetail?.label;
  const benefitType = basketOfferDetail?.benefit?.benefit_type;
  const conditionType = basketOfferDetail?.condition?.condition_type;
  const conditionAmount = basketOfferDetail?.condition?.condition_kwargs?.value;
  const discountPercentage = basketOfferDetail?.benefit?.benefit_kwargs?.percentage;
  const discountAmount = basketOfferDetail?.benefit?.benefit_kwargs.amount;
  const maxStockLimit = basketOfferDetail?.max_stock_limit;
  const isPercentage = benefitType === 'percentage';
  return (
    <div className="campaign-information">
      <div className="detail-row">
        <div className="detail-title">{t('campaign_message')}</div>
        <div className="detail-value">{campaignMessage}</div>
      </div>
      <div className="detail-row">
        <div className="detail-title">{t('campaign_type')}</div>
        <div className="detail-value">{t(`campaign_type_option.${benefitType}`)}</div>
      </div>
      <div className="detail-row">
        <div className="detail-title">{t('condition_type')}</div>
        <div className="detail-value">{t(`condition_type_option.${conditionType}`)}</div>
      </div>
      <div className="detail-row">
        <div className="detail-title">{t('condition_amount')}</div>
        <div className="detail-value">{conditionAmount}</div>
      </div>
      <div className="detail-row">
        <div className="detail-title">
          {isPercentage ? t('discount_percentage') : t('discount_amount')}
        </div>
        <div className="detail-value">
          {isPercentage ? discountPercentage + '%' : discountAmount}
        </div>
      </div>
      <div className="detail-row">
        <div className="detail-title">{t('max_stock_limit')}</div>
        <div className="detail-value">{maxStockLimit}</div>
      </div>

      {isSeller && (
        <AkinonButton
          htmlType="submit"
          type="primary"
          className="campaign-information__join-button"
          onClick={onJoinCampaign}
          loading={isJoiningCampaing}
          disabled={hasUserJoinedCampaign}
        >
          {t('join_campaign')}
        </AkinonButton>
      )}
    </div>
  );
};

export default CampaignConditions;
