import './ActionIcon.scss';

import clsx from 'clsx';

/**
 * @param {{ Icon: import('@tabler/icons-react').Icon, onClick: () => {}, className: string }} props
 */
export default function ActionIcon({ Icon, onClick, className }) {
  return (
    <button
      onClick={onClick}
      aria-label="Action Icon"
      className={clsx('akinon-action-icon', className)}
    >
      {<Icon size={14} />}
    </button>
  );
}
