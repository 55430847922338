import './MobileRangePicker.scss';

import AkinonButton from '@components/AkinonButton';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

const MobileRangePicker = ({ title, isOpen, menu, onClear, onApply, isFadingOut }) => {
  const { t } = useTranslation();

  return (
    <div className="mobile-range-picker backdrop-blur-sm">
      <div
        className={clsx({
          'mobile-range-picker__inner': true,
          'mobile-range-picker__inner__hidden': !isOpen,
          'mobile-range-picker__inner--fade-in': isOpen,
          'mobile-range-picker__inner--fade-out': isFadingOut,
        })}
      >
        <div className="mobile-range-picker__header">
          <AkinonButton type="text" onClick={onClear}>
            {t('clear')}
          </AkinonButton>
          {title && <div className="mobile-range-picker__title">{title}</div>}
          <AkinonButton
            type="link"
            className="mobile-range-picker__title-apply-button"
            onClick={onApply}
          >
            {t('apply')}
          </AkinonButton>
        </div>
        {menu}
      </div>
    </div>
  );
};

export default React.memo(MobileRangePicker);
