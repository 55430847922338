import { postApproveProductRevisionUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { client } from '@services/api/client';

export function usePostApproveProductRevisionMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ productRevisionId, requestBody }) =>
      client.post(postApproveProductRevisionUrl, requestBody, {
        urlParams: { productRevisionId },
      }),
    ...mutationOptions,
  });

  return {
    approveProductRevision: mutate,
    isApprovingProductRevision: isLoading,
  };
}
