import './styles.scss';

import { createSelectOptions } from '@common/index';
import AkinonBox from '@components/AkinonBox';
import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import AkinonFormItem from '@components/AkinonFormItem';
import AkinonInput from '@components/AkinonInput';
import AkinonSelect from '@components/AkinonSelect';
import AkinonSpin from '@components/AkinonSpin';
import { UserRole } from '@constants/auth';
import { Color } from '@constants/theme';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCollectionsQuery } from '@services/api/hooks/usePaginatedCollectionsQuery';
import { useDataSourceLandingPagesQuery } from '@services/api/hooks/usePaginatedDataSourceLandingPagesQuery';
import { useDataSourcesQuery } from '@services/api/hooks/usePaginatedDataSourcesQuery';
import { usePatchProductCollectionWidgetMutation } from '@services/api/hooks/usePatchProductCollectionWidgetMutation';
import { usePostDataSourceLandingPageMutation } from '@services/api/hooks/usePostDataSourceLandingPageMutation';
import { usePostProductCollectionWidgetMutation } from '@services/api/hooks/usePostProductCollectionWidgetMutation';
import { useWidgetSchemaQuery } from '@services/api/hooks/useWidgetSchemaQuery';
import { useUserRole } from '@utils/hooks/useUserRole';
import first from 'lodash/first';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useUnmount } from 'react-use';

import {
  generateFormObject,
  getRequestBodyFromFormValues,
  pageWidgetSchemaIdMap,
  SchemaDataType,
} from '../common';
import SchemaManager from '../components/SchemaManager';
import { FormKey, getFormSchema } from './common';
import { useInitialWidgetValues } from './hooks/useInitialWidgetValues';

const CollectionWidgetManagement = () => {
  const { t } = useTranslation('SellerStoreManagement');
  const { dataSources } = useDataSourcesQuery();
  const { collections, isCollectionsLoading } = useCollectionsQuery();
  const { productCollectionWidgetSlug } = useParams();
  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;

  const { widgetSchema, isWidgetSchemaLoading } = useWidgetSchemaQuery({
    schemaId: pageWidgetSchemaIdMap.recommendationSystemId,
  });
  const { initialWidgetValues, isWidgetLoading } = useInitialWidgetValues({
    collections,
    productCollectionWidgetSlug,
    widgetSchema: widgetSchema?.schema,
  });
  const { dataSourceLandingPages } = useDataSourceLandingPagesQuery();

  const schema = getFormSchema({ widgetSchema: widgetSchema?.schema, t });
  const form = useForm({
    mode: 'onChange',
    resolver: zodResolver(schema),
  });
  const { control, handleSubmit, reset } = form;
  const { postProductCollectionWidget, isPostingProductCollectionWidget } =
    usePostProductCollectionWidgetMutation();
  const { patchProductCollectionWidget, isPatchingProductCollectionWidget } =
    usePatchProductCollectionWidgetMutation();
  const { postDataSourceLandingPage, isPostingDataSourceLandingPage } =
    usePostDataSourceLandingPageMutation();

  useEffect(() => {
    if (initialWidgetValues) {
      reset(initialWidgetValues);
    }
  }, [initialWidgetValues]);

  const onSubmit = (formValues) => {
    const dataSourceSlug = first(dataSources)?.slug;
    const requestBody = {
      ...getRequestBodyFromFormValues({
        formValues,
        widgetSchema: widgetSchema?.schema,
      }),
      widget_type: pageWidgetSchemaIdMap.recommendationSystemId,
      slug: `${dataSourceSlug}-${pageWidgetSchemaIdMap.recommendationSystemId}`,
    };

    if (!isEmpty(initialWidgetValues)) {
      patchProductCollectionWidget(
        {
          widgetId: initialWidgetValues.pk,
          requestBody,
        },
        {
          onSuccess() {
            if (isEmpty(dataSourceLandingPages)) {
              postDataSourceLandingPage({
                requestBody: {
                  payload: {
                    widget: `${dataSourceSlug}-${pageWidgetSchemaIdMap.widgetManagementId}`,
                    data_source: dataSourceSlug,
                    product_collection_widget: `${dataSourceSlug}-${pageWidgetSchemaIdMap.recommendationSystemId}`,
                  },
                },
              });
            }
          },
        }
      );
    } else {
      postProductCollectionWidget(
        { requestBody },
        {
          onSuccess() {
            if (isEmpty(dataSourceLandingPages)) {
              postDataSourceLandingPage({
                requestBody: {
                  widget: `${dataSourceSlug}-${pageWidgetSchemaIdMap.widgetManagementId}`,
                  data_source: dataSourceSlug,
                  product_collection_widget: `${dataSourceSlug}-${pageWidgetSchemaIdMap.recommendationSystemId}`,
                },
              });
            }
          },
        }
      );
    }
  };
  const dynamicSchema = useMemo(() => {
    if (isWidgetSchemaLoading || isEmpty(widgetSchema)) return null;
    return Object.entries(widgetSchema.schema ?? {}).map(([key, widget]) => {
      if (widget?.data_type === SchemaDataType.TEXT) {
        return (
          <SchemaManager
            key={key}
            schema={generateFormObject({ widget, prefix: 'attributes' })}
            t={t}
            control={control}
          />
        );
      }
      const schema = get(generateFormObject({ widget, prefix: 'attributes' }), key, {});
      return <SchemaManager key={key} schema={schema} t={t} control={control} />;
    });
  }, [widgetSchema, isWidgetSchemaLoading, generateFormObject, control]);

  const collectionsOptions = createSelectOptions(collections, {
    labelKey: 'name',
    valueKey: 'id',
  });

  useUnmount(() => {
    reset({});
  });

  return (
    <AkinonBox
      className="collection-widget-management"
      title={t('collection_widget_management_title')}
      description={t('collection_widget_management_description')}
      bannerColor={Color.DODGER_BLUE}
    >
      <AkinonSpin
        spinning={isWidgetSchemaLoading || isCollectionsLoading || isWidgetLoading}
        tip={t('loading')}
      >
        <AkinonForm
          className="box-primary form-box collection-widget-management__form"
          layout="vertical"
        >
          <AkinonFormItem control={control} label={t('name')} name={FormKey.NAME} required>
            <AkinonInput placeholder={t('enter_variable', { variable: t('name') })} />
          </AkinonFormItem>
          <AkinonFormItem control={control} label={t('template')} name={FormKey.TEMPLATE} required>
            <AkinonInput placeholder={t('enter_variable', { variable: t('template') })} />
          </AkinonFormItem>
          <AkinonFormItem
            control={control}
            label={t('collection_limit')}
            name={FormKey.PRODUCT_LIMIT}
            required
          >
            <AkinonInput placeholder={t('enter_variable', { variable: t('collection_limit') })} />
          </AkinonFormItem>
          <AkinonFormItem
            control={control}
            label={t('collection')}
            name={FormKey.COLLECTION}
            required
          >
            <AkinonSelect
              options={collectionsOptions}
              placeholder={t('enter_variable', { variable: t('collection') })}
            />
          </AkinonFormItem>

          {dynamicSchema}

          <AkinonFormItem>
            <AkinonButton
              disabled={isSuperUser}
              type="primary"
              onClick={handleSubmit(onSubmit)}
              className="collection-widget-management__save-button"
              loading={
                isPostingDataSourceLandingPage ||
                isPostingProductCollectionWidget ||
                isPatchingProductCollectionWidget
              }
            >
              {t('save').toUpperCase()}
            </AkinonButton>
          </AkinonFormItem>
        </AkinonForm>
      </AkinonSpin>
    </AkinonBox>
  );
};

export default CollectionWidgetManagement;
