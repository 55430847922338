export const dangerousDynamicSettingsKeys = [
  'OMNITRON_API',
  'OMNITRON_CATALOG_ID',
  'OMNITRON_CHANNEL_ID',
];

export const filterOutDangerousDynamicSettings = (
  dynamicSettingsList,
  dynamicSettingsWithValidators
) => {
  if (!dynamicSettingsList?.length) return;

  return dynamicSettingsWithValidators.filter(
    (setting) => !dangerousDynamicSettingsKeys.includes(setting.key)
  );
};

export const getColumns = ({ t }) => {
  return [
    {
      dataIndex: 'key',
      title: t('key'),
      width: '50%',
    },
    {
      dataIndex: 'description',
      title: t('description'),
      width: '50%',
    },
  ];
};
