import { createSelectOptions } from '@common/index';
import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import AkinonFormItem from '@components/AkinonFormItem';
import AkinonSelect from '@components/AkinonSelect';
import { QuillEditor } from '@components/QuillEditor';
import Box from '@components/utility/box';
import { UserRole } from '@constants/auth';
import { QueryKey } from '@constants/query';
import { queryClient } from '@services/api/queryClient';
import { useUserRole } from '@utils/hooks/useUserRole';
import { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ContentType, FormFieldKey } from '../common';
import { useCreateMessageMutation } from '../hooks/api/useCreateMessageMutation';
import { useQuickRepliesQuery } from '../hooks/api/useQuickRepliesQuery';

export const ReplyMessageBox = ({ conversationId }) => {
  const { t } = useTranslation('Messages');

  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;

  const { reset, control, setValue, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: {
      [FormFieldKey.MESSAGE_CONTENT]: '',
    },
  });
  const { quickReplies, isQuickRepliesLoading } = useQuickRepliesQuery({
    requestConfig: {
      params: {
        limit: 999,
      },
    },
  });

  const quickMessageValue = useWatch({ control, name: FormFieldKey.QUICK_MESSAGE });
  const messageContent = useWatch({ control, name: FormFieldKey.MESSAGE_CONTENT });

  const conversation = queryClient.getQueryData([QueryKey.CONVERSATIONS, conversationId]);

  const { createMessage, isCreatingMessage } = useCreateMessageMutation({
    mutationOptions: {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKey.CONVERSATIONS, conversationId]);
        reset();
      },
    },
  });

  const onSubmit = (formValues) => {
    const payload = {
      conversation: conversationId,
      message_content: formValues[FormFieldKey.MESSAGE_CONTENT],
      content_id: conversation?.datasource?.pk,
      content_type: ContentType.DATASOURCE,
      user_type: 'registered',
    };
    createMessage({ requestBody: payload });
  };

  useEffect(() => {
    setValue(FormFieldKey.MESSAGE_CONTENT, quickMessageValue);
  }, [quickMessageValue]);

  return (
    <Box className="box-primary form-box" title={t('reply.messages')}>
      <AkinonForm className="akn-form akn-form-v2" layout="vertical">
        <AkinonFormItem
          name={FormFieldKey.QUICK_MESSAGE}
          label={t('choose.ready.message')}
          control={control}
        >
          <AkinonSelect
            placeholder={t('select.mandatory.placeholder')}
            loading={isQuickRepliesLoading}
            options={createSelectOptions(
              quickReplies?.map((reply) => ({ ...reply, key: reply.id })),
              {
                labelKey: 'title',
                valueKey: 'content',
                customKeys: ['key'],
              }
            )}
          />
        </AkinonFormItem>
        <AkinonFormItem
          name={FormFieldKey.MESSAGE_CONTENT}
          label={t('description')}
          control={control}
        >
          <QuillEditor value={messageContent} />
        </AkinonFormItem>
        <div>
          <AkinonButton
            htmlType="button"
            onClick={handleSubmit(onSubmit)}
            type="primary"
            className="float-left reply-message-button"
            loading={isCreatingMessage}
            disabled={isSuperUser}
          >
            {t('send')}
          </AkinonButton>
        </div>
      </AkinonForm>
    </Box>
  );
};
