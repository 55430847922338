import { RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@root/hooks/useAppNavigate';

import { getRequestBodyFromFormValues } from '../common';

export function useOnSubmit({
  optionId,
  updateShippingCompanyOption,
  createShippingCompanyOptions,
  defaultPriority,
  shippingCompanyOptionHttpOptions,
  isSuperUser,
  configId,
  createDataSourceShippingCompanyOptionConfig,
  updateDataSourceShippingCompanyOptionConfig,
}) {
  const navigate = useAppNavigate();

  return (formValues) => {
    if (!formValues.priority) formValues.priority = defaultPriority;
    const requestBody = getRequestBodyFromFormValues({
      formValues,
      shippingCompanyOptionHttpOptions,
    });
    const patch =
      isSuperUser && Boolean(configId)
        ? updateDataSourceShippingCompanyOptionConfig
        : updateShippingCompanyOption;
    const post =
      isSuperUser && Boolean(configId)
        ? createDataSourceShippingCompanyOptionConfig
        : createShippingCompanyOptions;
    optionId
      ? patch(
          {
            ...(isSuperUser && Boolean(configId) ? { configId } : { optionId }),
            requestBody,
          },
          {
            onSuccess: () => {
              navigate(RouteUrls.settings.deliverySettingsShipmentSettings);
            },
          }
        )
      : post({ requestBody });
  };
}
