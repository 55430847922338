import * as z from 'zod';

export function getVariantsFormSchema({ variantDynamicFields }) {
  return z.object({
    variantsForm: z.object({
      ...variantDynamicFields?.reduce((acc, field) => {
        return {
          ...acc,
          [field.key]: field.required
            ? z.array(z.string()).min(1)
            : z.array(z.nullable()).optional(),
        };
      }, {}),
    }),
  });
}
