import { t } from 'i18next';
import isInteger from 'lodash/isInteger';
import isNumber from 'lodash/isNumber';
import { z } from 'zod';

import { isFloat, isJSON } from '.';

const customErrorMap = (issue) => {
  switch (issue.expected) {
    case 'string':
      return { message: t('formRule.required') };
  }
  switch (issue.validation) {
    case 'email':
      return { message: t('email.validation.message') };
    default:
      return { message: t('formRule.required') };
  }
};

z.setErrorMap(customErrorMap);

export const getFormRuleMap = ({ t }) => ({
  required: {
    required: true,
    message: t('formRule.required'),
  },
  string: {
    type: 'string',
    message: t('string.validation.message'),
  },
  array: {
    type: 'array',
    message: t('array.validation.message'),
  },
  object: {
    type: 'object',
    message: t('object.validation.message'),
  },
  boolean: {
    type: 'boolean',
    message: t('boolean.validation.message'),
  },
  method: {
    type: 'method',
    message: t('method.validation.message'),
  },
  date: {
    type: 'date',
    message: t('date.validation.message'),
  },
  url: {
    type: 'url',
    message: t('url.validation.message'),
  },
  hex: {
    type: 'hex',
    message: t('hex.validation.message'),
  },
  email: {
    type: 'email',
    message: t('email.validation.message'),
  },
  number: {
    async validator(_, value) {
      if (isNumber(+value)) return;
      throw new Error(t('number.validation.message'));
    },
  },
  integer: {
    async validator(_, value) {
      if (isInteger(+value)) return;
      throw new Error(t('integer.validation.message'));
    },
  },
  float: {
    async validator(_, value) {
      if (isFloat(value)) return;
      throw new Error(t('float.validation.message'));
    },
  },
  json: {
    async validator(_, value) {
      if (isJSON(value)) return;
      throw new Error(t('json.format.validation'));
    },
  },
  enum: (enums, name) => {
    return {
      type: 'enum',
      enum: enums,
      message: t('enum.validation.message.rule', { fieldName: name }),
    };
  },
  file: ({ fileName } = {}) => {
    if (!fileName)
      return {
        required: true,
        message: t('upload_at_least_one_file'),
      };

    return {
      required: true,
      message: t('required_file', { fileName }),
    };
  },
  min: (amount) => {
    return {
      min: amount,
      message: t('min.validation.message.rule', { amount }),
    };
  },
  max: (amount) => {
    return {
      max: amount,
      message: t('max.validation.message.rule', { amount }),
    };
  },
  or: (ruleLeft, ruleRight) => {
    return {
      async validator(_, value) {
        const results = [];
        try {
          await ruleLeft.validator(null, value);
        } catch (e) {
          e && results.push(e);
        }
        try {
          await ruleRight.validator(null, value);
        } catch (e) {
          e && results.push(e);
        }
        if (results.length <= 1) return;
        else throw results?.[0];
      },
    };
  },
  empty: {
    async validator(_, value) {
      if (!value || !value?.trim?.()) return;
      throw new Error(t('unexpected.title'));
    },
  },
});

export const getRefineImages =
  ({ t, dimensionBounds }) =>
  async (images = [], ctx, path) => {
    const { maxWidth, maxHeight, minWidth, minHeight } = dimensionBounds;

    for (const image of images) {
      const { width, height } = image;

      const isValid = await new Promise((resolve) => {
        if (width && height) {
          resolve(width < maxWidth && height < maxHeight && width > minWidth && height > minHeight);
        } else {
          const reader = new FileReader();
          reader.readAsDataURL(image.originFileObj);
          reader.onload = (e) => {
            const fileImage = new Image();
            fileImage.src = e.target?.result;
            const { width, height } = fileImage;
            resolve(
              width < maxWidth && height < maxHeight && width > minWidth && height > minHeight
            );
          };
        }
      });

      if (!isValid) {
        const issue = {
          message: t('generic_image_upload_failed_message', {
            imagefileName: image.name,
            maxWidth,
            maxHeight,
            minWidth,
            minHeight,
          }),
          code: z.ZodIssueCode.custom,
        };
        path && (issue.path = path);
        ctx.addIssue(issue);
        break;
      }
    }
  };
