import { RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@root/hooks/useAppNavigate';
import useStore from '@zustand-store/index';
import { setPageMetaSelector } from '@zustand-store/selectors/app';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

export function usePageMeta() {
  const { t } = useTranslation('Messages');
  const navigate = useAppNavigate();
  const setPageMeta = useStore(setPageMetaSelector);

  useEffect(() => {
    setPageMeta({
      title: t('quick.replies'),
      description: t('quick.replies.desc'),
      actions: [
        {
          label: t('new.quick.reply'),
          icon: <i className="icon-arti" />,
          onClick() {
            navigate(RouteUrls.messages.quickReply);
          },
        },
      ],
    });
  }, [t]);

  useUnmount(() => {
    setPageMeta({});
  });
}
