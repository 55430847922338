import './styles.scss';

import AkinonBox from '@components/AkinonBox';
import AkinonButton from '@components/AkinonButton';
import AkinonDragger from '@components/AkinonDragger';
import AkinonForm from '@components/AkinonForm';
import AkinonFormItem from '@components/AkinonFormItem';
import AkinonInput, { AkinonTextArea } from '@components/AkinonInput';
import AkinonSelect from '@components/AkinonSelect';
import { Color } from '@constants/theme';
import { zodResolver } from '@hookform/resolvers/zod';
import { usePostTicketMessageMutation } from '@services/api/hooks/usePostTicketMessageMutation';
import { usePostTicketMutation } from '@services/api/hooks/usePostTicketMutation';
import { Form } from 'antd';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getTicketPriorityOptions } from '../common';
import { FormKey, getFormSchema } from './common';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { useOnSubmit } from './hooks/useOnSubmit';
import { usePageMeta } from './hooks/usePageMeta';

function SupportTicketForm() {
  const { t } = useTranslation('Support');
  const formSchema = getFormSchema({ t });
  const { control, handleSubmit } = useForm({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
  });
  const { createTicket, isCreatingTicket } = usePostTicketMutation();
  const { createTicketMessage, isCreatingTicketMessage } = usePostTicketMessageMutation();

  useBreadcrumbs();
  usePageMeta();

  const onSubmit = useOnSubmit({ createTicket, createTicketMessage, t });

  return (
    <section className="support-ticket-form">
      <AkinonBox title={t('ticket.details')} bannerColor={Color.DODGER_BLUE}>
        <AkinonForm layout="vertical">
          <AkinonFormItem name={FormKey.SUBJECT} label={t('subject')} control={control} required>
            <AkinonInput placeholder={t('enter_variable', { variable: t('subject') })} />
          </AkinonFormItem>
          <AkinonFormItem name={FormKey.CONTENT} label={t('message')} control={control}>
            <AkinonTextArea
              autoSize={{ minRows: 3 }}
              placeholder={t('enter_variable', { variable: t('message') })}
            />
          </AkinonFormItem>
          <AkinonFormItem name={FormKey.PRIORITY} label={t('priority')} control={control}>
            <AkinonSelect
              options={getTicketPriorityOptions({ t })}
              placeholder={t('enter_variable', { variable: t('priority') })}
            />
          </AkinonFormItem>
          <AkinonFormItem name={FormKey.ATTACHMENT} label={t('upload')} control={control}>
            <AkinonDragger multiple />
          </AkinonFormItem>
          <Form.Item wrapperCol={{ span: 16 }}>
            <AkinonButton
              type="primary"
              htmlType="button"
              onClick={handleSubmit(onSubmit)}
              className="support-ticket-form__submit-button"
              loading={isCreatingTicket || isCreatingTicketMessage}
            >
              {t('send').toUpperCase()}
            </AkinonButton>
          </Form.Item>
        </AkinonForm>
      </AkinonBox>
    </section>
  );
}

export default SupportTicketForm;
