import { AttributeDataType, attributeWidgetMap, FilterType } from '@constants/commontypes';
import { useAttributesQuery } from '@services/api/hooks/useAttributesQuery';

import { getDynamicFilterWidgetProps } from './common';

export function useDynamicFilters({ isEnabled } = { isEnabled: true }) {
  const { attributes, isAttributesLoading } = useAttributesQuery({
    queryOptions: {
      enabled: isEnabled,
    },
  });

  const dynamicFiltersFormMetaFields =
    attributes?.map(({ id, key, name, data_type }) => {
      const widget = attributeWidgetMap[data_type];
      const widgetProps = getDynamicFilterWidgetProps({ widget });

      // TODO: remove filterKey
      const filterKey = data_type !== AttributeDataType.DROPDOWN ? `${key}__icontains` : key;

      return {
        id,
        key: filterKey,
        widget,
        data_type,
        options: [],
        filterType: FilterType.DYNAMIC,
        placeholder: name,
        widgetProps,
      };
    }) ?? [];

  return {
    dynamicFiltersFormMetaFields,
    isDynamicFiltersLoading: isAttributesLoading,
  };
}
