import { createNavigationItems, getUserRole } from '@common/auth';
import { RouteUrls } from '@constants/routeUrls';
import React from 'react';

export const getNavigationItems = ({ t, user, isLiveAppVersion }) => {
  const userRole = getUserRole({ user });

  return createNavigationItems({
    navigationItems: [
      {
        to: RouteUrls.settings.userManagement,
        title: t('user_management'),
        description: t('user_management_desc'),
        icon: <i className="icon-musteriler" />,
      },
      {
        to: RouteUrls.settings.ssoLoginSettingsUrl,
        title: t('sso_settings.title'),
        description: t('sso_settings.description'),
        icon: <i className="icon-transferlokasyon" />,
      },
      {
        to: RouteUrls.settings.accessTokens,
        title: t('access_tokens_title'),
        description: t('access_tokens_description'),
        icon: <i className="icon-musteriler" />,
      },
      {
        to: RouteUrls.settings.sellerUserManagementSettings,
        title: t('seller_user_management'),
        description: t('seller_user_management_desc'),
        icon: <i className="icon-musteriler" />,
      },
      {
        to: RouteUrls.settings.deliverySettingsShipmentSettings,
        title: t('delivery_settings'),
        description: t('delivery_settings_desc'),
        icon: <i className="icon-transferlokasyon" />,
      },
      {
        to: RouteUrls.settings.dynamicSettings,
        title: t('dynamic_settings'),
        description: t('dynamic_settings_desc'),
        icon: <i className="icon-transferlokasyon" />,
      },
      {
        to: RouteUrls.settings.sellerOfferRejectReasons,
        title: t('seller_offer_reject_reasons'),
        description: t('seller_offer_reject_reasons_desc'),
        icon: <i className="icon-transferlokasyon" />,
      },
      {
        to: RouteUrls.settings.attributeSetAndCategoryMapping,
        title: t('category_attribute_mapping'),
        description: t('category_attribute_mapping_desc'),
        icon: <i className="icon-transferlokasyon" />,
      },
      {
        to: RouteUrls.reporting.sellerPerformance,
        title: t('seller_performance_settings'),
        description: t('seller_performance_settings_desc'),
        icon: <i className="icon-transferlokasyon" />,
      },
      {
        to: RouteUrls.settings.sellerStateTransitions,
        title: t('seller_state_transitions'),
        description: t('seller_state_transitions_desc'),
        icon: <i className="icon-transferlokasyon" />,
      },
      {
        to: RouteUrls.settings.integrationErrors,
        title: t('integration_errors'),
        description: t('integration_errors_desc'),
        icon: <i className="icon-transferlokasyon" />,
      },
    ],
    userRole,
    isLiveAppVersion,
  });
};
