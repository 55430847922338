import { generateDynamicFilterKey } from '@common/index';
import { WidgetType } from '@constants/commontypes';
import constant from 'lodash/constant';
import matches from 'lodash/matches';
import stubTrue from 'lodash/stubTrue';

export const transformDynamicFilters = (dynamicFilters) => {
  return {
    ...dynamicFilters,
    dynamicFiltersFormMetaFields: dynamicFilters.dynamicFiltersFormMetaFields.map((metaField) => ({
      ...metaField,
      key: generateDynamicFilterKey({
        prefixPairs: [[stubTrue, constant('attributes')]],
        suffixPairs: [[matches(WidgetType.SELECT), constant('exact')]],
        metaField,
      }),
    })),
  };
};

export const getDynamicColumns = ({ filters, dynamicFiltersFormMetaFields }) => {
  const dynamicFilters = Object.keys(filters).filter((dynamicFilterKey) =>
    dynamicFiltersFormMetaFields
      ?.map((dynamicFilter) => dynamicFilter.key)
      .includes(dynamicFilterKey)
  );

  const dynamicColumns = [],
    dynamicFormMetaFields = [];
  dynamicFilters.forEach((dynamicFilterKey) => {
    const formMetaField = dynamicFiltersFormMetaFields.find(
      (dynamicFilter) => dynamicFilter.key === dynamicFilterKey
    );

    const fieldKey = RegExp(/attributes__(.*)__/).exec(dynamicFilterKey)[1];
    dynamicFormMetaFields.push({ ...formMetaField, fieldKey });
    dynamicColumns.push({
      title: formMetaField.placeholder,
      dataIndex: ['attributes', fieldKey],
      key: fieldKey,
      classname: 'uppercase',
      render: (data) => data ?? '-',
    });
  });
  return { dynamicColumns, dynamicFormMetaFields };
};
