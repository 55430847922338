import './styles.scss';

import If from '@components/If';
import { Space, Typography } from 'antd';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getModifiedFilterValue, isFileObject } from './common';

const { Text } = Typography;

const ActiveFilterItem = ({ currentFiltersFormMeta, filter, handleOnRemoveFilter }) => {
  const { t } = useTranslation();
  const [filterKey, filterValue] = filter;

  const currentFiltersFormMetaField = currentFiltersFormMeta.fields.find(
    (filtersFormMetaField) => filtersFormMetaField.key === filterKey
  );

  const modifiedFilterValue = getModifiedFilterValue({
    t,
    currentFiltersFormMetaField,
    filterValue,
  });

  const isIncludedInCurrentFiltersFormMeta = !isEmpty(currentFiltersFormMetaField);
  const isSearchQuery = filterKey === 'q';
  const shouldShowActiveFilter =
    (isIncludedInCurrentFiltersFormMeta || isSearchQuery) && !isEmpty(modifiedFilterValue);

  return (
    <If
      condition={shouldShowActiveFilter}
      then={
        <Space key={filterKey} align="center" className="active-filter-item">
          <Text className="active-filter-item__label">
            {isSearchQuery ? t('search') : currentFiltersFormMetaField?.placeholder}:
          </Text>
          <Text className="active-filter-item__value">{modifiedFilterValue}</Text>
          <i
            onClick={() => handleOnRemoveFilter(filterKey)}
            className="icon-close active-filter-item__remove-filter-icon"
          />
        </Space>
      }
      otherwise={
        <If
          condition={isFileObject(filterValue)}
          then={
            <Space key={filterKey} align="center" className="active-filter-item">
              <Text className="active-filter-item__label">{t('filterFile')}:</Text>
              <Text className="active-filter-item__value">{filterValue?.name}</Text>
              <i
                onClick={() => handleOnRemoveFilter(filterKey)}
                className="icon-close active-filter-item__remove-filter-icon"
              />
            </Space>
          }
          otherwise={<div />}
        />
      }
    />
  );
};

export default ActiveFilterItem;
