import Show from '@components/Show';
import { UserRole } from '@constants/auth';
import { useUserRole } from '@utils/hooks/useUserRole';
import PropTypes from 'prop-types';
import React from 'react';

const ShowForRole = ({ guest, seller, superUser }) => {
  const userRole = useUserRole();

  return (
    <>
      <Show when={userRole === UserRole.GUEST}>{guest}</Show>
      <Show when={userRole === UserRole.SELLER}>{seller}</Show>
      <Show when={userRole === UserRole.SUPER_USER}>{superUser}</Show>
    </>
  );
};

ShowForRole.propTypes = {
  guest: PropTypes.node,
  seller: PropTypes.node,
  superUser: PropTypes.node,
};

export default ShowForRole;
