import * as z from 'zod';

export const FormKey = {
  TITLE: 'title',
  CONTENT: 'content',
};

export const schema = z.object({
  [FormKey.TITLE]: z.string().min(1),
  [FormKey.CONTENT]: z.string().min(1),
});
