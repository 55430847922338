import { get } from 'lodash';
import * as z from 'zod';

export const getImageFormSchema = ({ t, maxImageDimensions, minImageDimensions }) => {
  const refineImages = async (productImages, ctx) => {
    for (const _image of productImages) {
      const validationResult = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(_image.originFileObj);
        reader.onload = (e) => {
          const image = new Image();
          image.src = e.target?.result;
          const { width, height } = image;

          const maxWidth = get(maxImageDimensions, 'WIDTH');
          const maxHeight = get(maxImageDimensions, 'HEIGHT');
          const minWidth = get(minImageDimensions, 'WIDTH');
          const minHeight = get(minImageDimensions, 'HEIGHT');

          if (width < minWidth || height < minHeight) {
            resolve({
              message: t('image_upload_failed_message', {
                imagefileName: _image.name,
                condition: t('min'),
                width: minWidth,
                height: minHeight,
              }),
              code: z.ZodIssueCode.custom,
            });
          } else if (width > maxWidth || height > maxHeight) {
            resolve({
              message: t('image_upload_failed_message', {
                imagefileName: _image.name,
                condition: t('max'),
                width: minWidth,
                height: minHeight,
              }),
              code: z.ZodIssueCode.custom,
            });
          } else {
            resolve(true);
          }
        };
      });

      if (validationResult !== true) {
        ctx.addIssue(validationResult);
        break;
      }
    }
  };
  return z.object({
    bulk: z.object({
      product_image: z.array(z.any()).superRefine(refineImages).optional(),
    }),
    single: z.object({
      product_image: z.array(z.array(z.any()).superRefine(refineImages).optional()).refine(
        (productImages) => {
          return productImages.filter((image) => image?.length > 0).length > 0;
        },
        {
          message: t('min_image_error_message'),
          path: ['minImageError'],
        }
      ),
    }),
  });
};
