import AkinonButton from '@components/AkinonButton';
import React from 'react';

import { BoxSubTitle, BoxTitle } from './boxTitleComponents';

const UtilityBoxTitle = ({ title, subtitle, action }) => {
  const { text, onClick, danger = true, className } = action || {};
  return (
    <div>
      <div className="titleWrapper">
        {title ? <BoxTitle className="isoBoxTitle"> {title} </BoxTitle> : ''}
        {action && (
          <div>
            <AkinonButton type="primary" className={className} danger={danger} onClick={onClick}>
              {text}
            </AkinonButton>
          </div>
        )}
      </div>
      {subtitle ? <BoxSubTitle className="isoBoxSubTitle"> {subtitle} </BoxSubTitle> : ''}
    </div>
  );
};

export default UtilityBoxTitle;
