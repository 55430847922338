import './styles.scss';

import { Color } from '@constants/theme';
import { Popover } from 'antd';
import clsx from 'clsx';

/**
 * @param {import('antd').PopoverProps} props
 */
export default function AkinonPopover({ children, className, ...otherProps }) {
  return (
    <Popover
      color={Color.PICKLED_BLUEWOOD}
      overlayClassName={clsx('akinon-popover', className)}
      {...otherProps}
    >
      {children}
    </Popover>
  );
}
