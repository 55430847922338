import useStore from '@zustand-store/index';
import { setBreadcrumbsSelector } from '@zustand-store/selectors/app';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

export function useBreadcrumbs() {
  const { t } = useTranslation('Finance');
  const setBreadcrumbs = useStore(setBreadcrumbsSelector);

  useEffect(() => {
    setBreadcrumbs([t('seller_store_management')]);
  }, [t]);

  useUnmount(() => {
    setBreadcrumbs([]);
  });
}
