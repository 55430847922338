import { useSetState } from 'react-use';

import { defaultPagination } from './common';

/**
 * @param {import('antd/lib/pagination').PaginationProps} initialPagination
 */
export default function usePagination(initialPagination) {
  const [pagination, setPagination] = useSetState({
    ...defaultPagination,
    ...initialPagination,
  });

  return [pagination, setPagination];
}
