import { useCategoriesQuery } from '@services/api/hooks/useCategoriesQuery';
import { useMasterProductPricesQuery } from '@services/api/hooks/useMasterProductPricesQuery';
import { useProductLowestPriceQuery } from '@services/api/hooks/useProductLowestPriceQuery';
import { useAttributeConfigsQuery } from '@services/hooks/attributes/useAttributeConfigsQuery';
import { useProductAttributesQuery } from '@services/hooks/attributes/useProductAttributesQuery';
import { usePriceListsQuery } from '@services/hooks/common/usePriceListsQuery';
import { useStockListsQuery } from '@services/hooks/common/useStockListsQuery';
import { useDataSourcesQuery } from '@services/hooks/datasources/useDataSourcesQuery';
import { usePreOfferQuery } from '@services/hooks/preOffers/usePreOfferQuery';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { forbiddenKeys } from '../../common';
import { useDynamicFormFields } from '../useDynamicFormFields';

export function useQueries({ t }) {
  const { offerId } = useParams();
  const { preOffer } = usePreOfferQuery({ preOfferId: offerId });
  const attributeSetId = preOffer?.attribute_set;
  const { productAttributes } = useProductAttributesQuery();
  const { categories } = useCategoriesQuery();
  const { attributeConfigs } = useAttributeConfigsQuery({
    attributeSetId,
    queryOptions: { enabled: Boolean(attributeSetId) },
  });
  const { priceLists } = usePriceListsQuery();
  const { stockLists } = useStockListsQuery();
  const { dataSourcesResult: dataSources } = useDataSourcesQuery();

  const { masterProductPrices } = useMasterProductPricesQuery({
    params: {
      limit: 1,
      product__in: preOffer?.product,
    },
    queryOptions: {
      enabled: !isEmpty(preOffer?.product),
    },
  });

  const { productLowestPrice } = useProductLowestPriceQuery({
    productId: preOffer?.product,
    queryOptions: {
      enabled: !isEmpty(preOffer?.product),
    },
  });

  const { variantListAttributeFields, variantDynamicFields } = useDynamicFormFields({
    attributeConfigs,
    productAttributes,
    forbiddenKeys,
    t,
  });
  const dynamicFields = useMemo(() => {
    if (isEmpty(variantDynamicFields) || isEmpty(variantListAttributeFields)) {
      return [];
    }
    return [variantDynamicFields, variantListAttributeFields];
  }, [variantListAttributeFields, variantDynamicFields]);

  return {
    preOffer,
    attributeConfigs,
    categories,
    dataSources,
    priceLists,
    productAttributes,
    attributeSetId,
    stockLists,
    buyboxPrice: first(masterProductPrices)?.price,
    lowestPrice: productLowestPrice?.price,
    dynamicFields,
  };
}
