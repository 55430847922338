import BoxTitleWrapper from '@components/utility/boxTitle';
import classnames from 'classnames';
import React from 'react';

import { BoxWrapper } from './boxComponents';

const Box = (props) => {
  const { className, style, title, subtitle, action, actions, children, checkbox, leftSideColor } =
    props;
  return (
    <BoxWrapper className={`${className} isoBoxWrapper`} style={style}>
      <div className="box-header">
        <div className={classnames(['left-side', leftSideColor])}>
          <BoxTitleWrapper title={title} subtitle={subtitle} action={action} />
        </div>
        {actions && (
          <div className="right-side">
            {checkbox && <span>{checkbox}</span>} {actions}
          </div>
        )}
      </div>
      {children}
    </BoxWrapper>
  );
};

export default Box;
