import { QueryKey } from '@constants/query';
import { getProduct } from '@services/api';
import { useQuery } from '@tanstack/react-query';

/**
 * @param {{ productId: string, requestConfig: import('axios').AxiosRequestConfig, queryOptions: import('@tanstack/react-query').UseQueryOptions }} args
 */
export function useProductQuery({ productId, requestConfig, queryOptions }) {
  const { data, isLoading, isFetching } = useQuery({
    queryKey: [QueryKey.PRODUCT, { productId, requestConfig }],
    queryFn: () => getProduct({ productId, requestConfig }),
    ...queryOptions,
    enabled: Boolean(productId) && queryOptions?.enabled,
  });

  return {
    product: data,
    isProductLoading: isLoading,
    isProductFetching: isFetching,
  };
}
