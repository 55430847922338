import sum from 'lodash/sum';
import { z } from 'zod';

import { TicketStatus } from '../common';

export const shortenId = (id) => id?.replace(/-/g, '').slice(0, 12).toUpperCase();

export const getFormSchema = ({ t }) => {
  return z.object({
    content: z.string().min(1),
    attachment: z
      .object({
        fileList: z
          .array(z.object({ size: z.number(), originFileObj: z.instanceof(File) }))
          .nullish(),
      })
      .superRefine(({ fileList }, ctx) => {
        const size10MB = 1024 * 1024 * 10;
        const sumeOfFileSizes = sum(fileList.map((file) => file.size));
        if (sumeOfFileSizes > size10MB) {
          ctx.addIssue({
            message: t('file_size_error_message', { fileSize: '10MB' }),
            code: z.ZodIssueCode.custom,
          });
        }
      })
      .nullish(),
  });
};

export const FormKey = {
  CONTENT: 'content',
  ATTACHMENT: 'attachment',
};

export const closedTicketStatuses = [TicketStatus.CLOSED, TicketStatus.AUTO_CLOSED];
