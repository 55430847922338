import { QueryKey } from '@constants/query';
import { getAttributeConfigs } from '@services/api';
import { useQuery } from '@tanstack/react-query';

/**
 * @param {{ queryOptions: import('@tanstack/react-query').UseQueryOptions }} args
 */
export function useAttributeConfigsQuery({ attributeSetId, queryOptions }) {
  const { data } = useQuery({
    queryKey: [QueryKey.ATTRIBUTE_CONFIGS, { attributeSetId }],
    queryFn: () => getAttributeConfigs({ attributeSetId }),
    ...queryOptions,
  });

  return {
    attributeConfigs: data?.results,
  };
}
