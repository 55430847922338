export const defaultDateFormat = 'DD/MM/YYYY';
export const defaultDateValueFormat = 'YYYY-MM-DD';
export const defaultTimeFormat = 'HH:mm';
export const defaultTimeFormatWithSeconds = 'HH:mm:ss';
export const defaultTimeFormatWithTimeZone = 'HH:mm:ssZZ';
export const timeFormatWithTimeZone = 'HH:mmZ';
export const timeFormatWithDateAndTime = 'LLL';
export const dateWithMonthName = 'DD MMMM';
export const dateWithMonthNameAndYear = 'DD MMMM YYYY';
export const overallDateFormat = 'DD-MM-YYYY HH:mm:ss';
export const dateTimeLocalSecondsFormat = 'YYYY-MM-DDTHH:mm:ss.SSS';
export const fullDateTimeWithZoneFormat = 'YYYY-MM-DDTHH:mm:ss.SSS\\Z';

export const defaultDateTimeValueFormat = `${defaultDateValueFormat} ${defaultTimeFormatWithSeconds}`;
export const defaultDateTimeFormat = `${defaultDateFormat} ${defaultTimeFormat}`;
export const defaultDateTimeFormatIntl = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  hour12: false,
};

export const defaultDateTimeFormatWithTimeZone = `${defaultDateValueFormat} ${timeFormatWithTimeZone}`;
export const dateTimeFormat = `${defaultDateValueFormat} ${defaultTimeFormat}`;

export const tokenHeaderKEY = 'Bearer';
export const tokenStorageKey = 'tkn';

export const permissionSlugSuffix = '-v2';

export const readResponseMessageKey = 'readResponseMessages';

export const platformLanguage = 'platform_language';

export const contextChannel = 'contextChannel';

export const allowedFileTypes = '.xls, .csv';

export const allowedFileTypesAndXlsx = allowedFileTypes + ', .xlsx';

export const allowedImageTypes = '.png,.jpeg,.jpg,.bmp';

export const allowedHtmlTypes = '.html';

export const omsDashboardStorageKeyName = 'oms_dashboardFilters';

export const dateManipulationKeys = {
  END_OF: 'endOf',
  SUBTRACT: 'subtract',
  ADD: 'add',
};

export const sampleDate = '2022-01-01';

export const defaultRowKey = 'id';

export const Breakpoint = {
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
  DESKTOP: 'DESKTOP',
  LARGE_DESKTOP: 'LARGE_DESKTOP',
};

export const defaultBreakpoints = {
  MOBILE: 576,
  TABLET: 768,
  DESKTOP: 992,
  LARGE_DESKTOP: 1200,
};
