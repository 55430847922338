import { useCancellationPlans } from '@services/hooks/cancellation/useCancellationPlans';

export const useCancellationDataSource = ({ orderId, pagination, filters }) => {
  const {
    data: cancellationPlans,
    total: cancellationDataSourceTotal,
    isFetching: isFetchingCancellationPlans,
  } = useCancellationPlans({
    pagination,
    filters,
    params: { order: orderId },
    enabled: Boolean(orderId),
  });

  return {
    cancellationDataSource: cancellationPlans,
    isCancellationDataSourceLoading: isFetchingCancellationPlans,
    cancellationDataSourceTotal,
  };
};
