import * as z from 'zod';

export const BasicProductInfoFormItemKey = {
  NAME: 'basicProductInfoForm.name',
  BASE_CODE: 'basicProductInfoForm.base_code',
  CATEGORY: 'basicProductInfoForm.id',
};

export const getBasicProductInfoFormSchema = () => {
  return z.object({
    basicProductInfoForm: z.object({
      name: z.string().min(1),
      base_code: z.string().min(1),
      id: z.string().min(1),
    }),
  });
};
