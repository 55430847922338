import { getIsNullBooleanAttributeOptions, WidgetType } from '@constants/commontypes';

import { StaticFilterKey } from './common';

export default function useStaticFilters({ categoryTreeData, isCategoriesLoading, t }) {
  return {
    staticFiltersFormMetaFields: [
      {
        key: StaticFilterKey.NAME,
        widget: WidgetType.INPUT,
        placeholder: t('product_name'),
      },
      {
        key: StaticFilterKey.CATEGORY_PATH,
        placeholder: t('category'),
        widget: WidgetType.TREESELECT,
        widgetProps: {
          showSearch: true,
          treeDefaultExpandAll: true,
          virtual: false,
          style: { width: '100%' },
          dropdownStyle: { maxHeight: 400, overflow: 'auto' },
          treeData: categoryTreeData,
          treeNodeFilterProp: 'title',
          allowClear: true,
        },
      },
      {
        key: StaticFilterKey.BASE_CODE,
        widget: WidgetType.INPUT,
        placeholder: t('base.code'),
      },
      {
        key: StaticFilterKey.SKU,
        widget: WidgetType.INPUT,
        placeholder: t('sku'),
      },
      {
        key: StaticFilterKey.IMAGE_ISNULL,
        widget: WidgetType.SELECT,
        placeholder: t('product_image'),
        options: getIsNullBooleanAttributeOptions({ t }),
      },
    ],
    isStaticFiltersLoading: isCategoriesLoading,
  };
}
