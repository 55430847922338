import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { QueryKey } from '@constants/query';
import { getCancellationPlans } from '@services/api';
import { useQuery } from '@tanstack/react-query';

/**
 * @typedef {import('@tanstack/react-query').UseQueryResult} UseQueryResult
 * @returns {import('@tanstack/react-query').UseQueryResult}
 */
export const useCancellationPlans = ({ pagination, filters, params = {}, enabled = true }) => {
  const queryKey = [QueryKey.CANCELLATION_PLANS, pagination, filters, params];

  const queryFn = () =>
    getCancellationPlans({
      ...getRequestPaginationParams(pagination),
      ...getRequestFiltersParams(filters),
      ...params,
    });
  const { data, isLoading, isFetching, error, ...rest } = useQuery({ queryKey, queryFn, enabled });

  return {
    data: data?.results,
    total: data?.count,
    isLoading,
    isFetching,
    error,
    ...rest,
  };
};
