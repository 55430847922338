import { QueryKey } from '@constants/query';
import { getOrders } from '@services/api/orders/order';
import { useQuery } from '@tanstack/react-query';

export function useOrdersQuery({ requestConfig, queryOption = {} }) {
  const queryKey = [QueryKey.ORDERS, requestConfig];

  const queryFn = () => getOrders({ requestConfig });

  const { data, isFetching, isLoading, error } = useQuery({
    queryKey,
    queryFn,
    ...queryOption,
  });

  return {
    ordersResult: data,
    orders: data?.results,
    isOrdersLoading: isLoading,
    isOrdersFetching: isFetching,
    orderError: error,
  };
}
