import './style.scss';

import AkinonSteps, { AkinonStep } from '@components/AkinonSteps';
import { UserRole } from '@constants/auth';
import { QueryKey } from '@constants/query';
import { useUser } from '@root/contexts/hooks/useUser';
import { useAttachFileWithDataSourceIdMutation } from '@services/api/hooks/useAttachFileWithDataSourceIdMutation';
import { useCategoriesQuery } from '@services/api/hooks/useCategoriesQuery';
import { useCategoryNodePermissionsQuery } from '@services/api/hooks/useCategoryNodePermissionsQuery';
import { useDataSourceDetailQuery } from '@services/api/hooks/useDataSourceDetailQuery';
import { useDeleteCategoryNodePermissionsMutation } from '@services/api/hooks/useDeleteCategoryNodePermissionMutation';
import { usePatchDatasourceDetailMutation } from '@services/api/hooks/usePatchDatasourceDetailMutation';
import { usePatchSupplierDetailMutation } from '@services/api/hooks/usePatchSupplierDetailMutation';
import { usePostCategoryNodePermissionsMutation } from '@services/api/hooks/usePostCategoryNodePermissionMutation';
import { queryClient } from '@services/api/queryClient';
import { useCurrentStep } from '@utils/hooks/useCurrentStep';
import { useUserRole } from '@utils/hooks/useUserRole';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { getSellerManagementFormStepList, SellerManagementStepKeys } from './common';

const SellerManagementDetail = () => {
  const { t } = useTranslation('Finance');
  const { id } = useParams();
  const user = useUser();
  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;

  const dataSourceId = id ?? user?.datasource;
  const { dataSourceDetail, isFetchingDataSourceDetail, refetchDataSourceDetail } =
    useDataSourceDetailQuery({
      dataSourceId,
      queryOptions: {
        enabled: Boolean(dataSourceId),
      },
    });

  const { updateSupplierDetail } = usePatchSupplierDetailMutation({
    mutationOptions: {
      onSuccess: refetchDataSourceDetail,
    },
  });
  const { updateDataSourceDetail } = usePatchDatasourceDetailMutation({
    mutationOptions: {
      onSuccess: refetchDataSourceDetail,
    },
  });
  const { attachFileWithDataSourceId } = useAttachFileWithDataSourceIdMutation({
    mutationOptions: {
      onSuccess: refetchDataSourceDetail,
    },
  });

  const { categories, isFetchingCategories } = useCategoriesQuery();
  const { categoryNodePermissions, isFetchingCategoryNodePermissions } =
    useCategoryNodePermissionsQuery({
      queryOptions: {
        enabled: isSuperUser,
      },
    });

  const userCategoryNodePermissions = categoryNodePermissions?.filter(
    (permission) => permission.datasource === dataSourceId
  );

  const { isCreatingNewCategoryNodePermission, createCategoryNodePermission } =
    usePostCategoryNodePermissionsMutation();
  const { isDeletingNewCategoryNodePermission, deleteCategoryNodePermission } =
    useDeleteCategoryNodePermissionsMutation();

  const handleSubmit = (values) => {
    const step = sellerManagementStepList[currentStep]?.key;
    if (step === SellerManagementStepKeys.DOCUMENTS) {
      handleDocumentSubmit({ extras: values?.extras });
    } else if (step === SellerManagementStepKeys.CATEGORY_PERMISSIONS) {
      handleCategoryPermissionsSubmit(values);
    } else if (step === SellerManagementStepKeys.GENERAL_INFORMATION) {
      handleInformationSubmit(values);
    }
  };
  const sellerManagementStepList = getSellerManagementFormStepList({
    t,
    isSuperUser,
    categories,
    userCategoryNodePermissions,
  });
  const { currentStep, setCurrentStep } = useCurrentStep({
    stepCount: sellerManagementStepList?.length,
    fallbackStep: 0,
  });

  const handleInformationSubmit = (values) => {
    const requestBody = isSuperUser ? values : values.supplier_detail;
    const integrationUrl = values?.conf?.integration_url;
    const reconciliationConf = values?.conf?.reconciliation_conf;
    if (isSuperUser) {
      const modifiedRequestBody = {
        ...requestBody,
        stock_release_type: Number(requestBody.stock_release_type),
        package_management_type: Number(requestBody.package_management_type),
        conf: {
          integration_url: isEmpty(integrationUrl) ? null : integrationUrl,
          reconciliation_conf: reconciliationConf,
        },
      };
      updateDataSourceDetail({
        requestBody: modifiedRequestBody,
        dataSourceId,
        requestConfig: {
          successMessage: t('transaction_success'),
          successDescription: t('changes_saved'),
          errorMessage: t('transaction_failed'),
          errorDescription: t('changes_error'),
        },
      });
    } else {
      updateSupplierDetail({
        requestBody,
        dataSourceId,
        requestConfig: {
          successMessage: t('transaction_success'),
          successDescription: t('changes_saved'),
          errorMessage: t('transaction_failed'),
          errorDescription: t('changes_error'),
        },
      });
    }
  };

  const handleDocumentSubmit = ({ extras }) => {
    const documentFormValues = extras?.files;

    for (const fileType in documentFormValues) {
      const isThereAnyFilesToUpload = documentFormValues[fileType]?.fileList?.length > 0;

      if (isThereAnyFilesToUpload) {
        const requestBody = new FormData();
        requestBody.append('file', documentFormValues[fileType]?.fileList[0]?.originFileObj);
        requestBody.append('file_type', fileType);

        attachFileWithDataSourceId({
          requestBody,
          dataSourceId: dataSourceId,
          requestConfig: {
            successMessage: t('transaction_success'),
            successDescription: t('changes_saved'),
            errorMessage: t('transaction_failed'),
            errorDescription: t('changes_error'),
          },
        });
      }
    }
  };

  const handleCategoryPermissionsSubmit = async (values) => {
    const activeCategoryPaths = values?.category_permissions.active;
    const categoryNodesToActivate = categories?.reduce((acc, category) => {
      const categoryPath = activeCategoryPaths?.find((path) => path === category.path);
      if (categoryPath) {
        acc.push(category.id);
      }
      return acc;
    }, []);

    const inactiveCategoryPaths = values?.category_permissions.inactive;
    const categoryNodesToDeactivate = categories?.reduce((acc, category) => {
      const categoryPath = inactiveCategoryPaths?.find((path) => path === category.path);
      if (categoryPath) {
        acc.push(category.id);
      }
      return acc;
    }, []);

    const deactivatePromises = categoryNodesToDeactivate.map(async (categoryNodeId) => {
      const categoryNodePermissionId = userCategoryNodePermissions.find(
        (permission) => permission.category_node === categoryNodeId
      )?.id;

      if (!categoryNodePermissionId) return Promise.resolve();

      return deleteCategoryNodePermission({
        categoryNodeId: categoryNodePermissionId,
      });
    });
    await Promise.allSettled(deactivatePromises);

    const activatePromises = categoryNodesToActivate.map(async (categoryNodeId) => {
      return createCategoryNodePermission({
        requestBody: {
          datasource: dataSourceId,
          is_active: true,
          category_node: categoryNodeId,
        },
      });
    });

    await Promise.allSettled(activatePromises);

    queryClient.invalidateQueries([QueryKey.CATEGORY_NODE_PERMISSIONS]);
  };

  const {
    key: currentStepKey,
    title: currentStepTitle,
    description: currentStepDescription,
    Component: StepComponent = () => null,
  } = sellerManagementStepList[currentStep] ?? {};

  const isLoading =
    currentStepKey === SellerManagementStepKeys.CATEGORY_PERMISSIONS
      ? isFetchingCategories ||
        isFetchingCategoryNodePermissions ||
        isCreatingNewCategoryNodePermission ||
        isDeletingNewCategoryNodePermission
      : isFetchingDataSourceDetail;

  return (
    <section className="seller-management-detail">
      <AkinonSteps current={currentStep} onChange={setCurrentStep}>
        {sellerManagementStepList.map(({ key, title, icon }) => (
          <AkinonStep key={key} title={title} icon={icon} />
        ))}
      </AkinonSteps>

      <StepComponent
        t={t}
        handleSubmit={handleSubmit}
        title={currentStepTitle}
        description={currentStepDescription}
        stepKey={currentStepKey}
        initialValues={dataSourceDetail}
        isWithAkinonBox={currentStepKey !== SellerManagementStepKeys.CATEGORY_PERMISSIONS}
        isLoading={isLoading}
        isSuperUser={isSuperUser}
        submitButtonProps={{
          isDisabled: (!isSuperUser && currentStep === 2) || (!isSuperUser && currentStep === 3),
          isVisible: currentStepKey !== SellerManagementStepKeys.CATEGORY_PERMISSIONS,
        }}
      />
    </section>
  );
};

export default SellerManagementDetail;
