import * as z from 'zod';

import { getDynamicZodSchema } from '../common';

export const FormKey = {
  NAME: 'name',
  TEMPLATE: 'template',
};

export const getFormSchema = ({ t, widgetSchema }) => {
  return z.object({
    [FormKey.NAME]: z.string({ required_error: t('formRule.required') }).min(1),
    [FormKey.TEMPLATE]: z.string({ required_error: t('formRule.required') }).min(1),
    attributes: z
      .object({
        ...Object.entries(widgetSchema ?? {}).reduce((acc, [key, value]) => {
          return {
            ...acc,
            [key]: getDynamicZodSchema({ t, widget: value }).optional(),
          };
        }, {}),
      })
      .optional(),
  });
};
