import { createProductUrl } from '@constants/apiUrls';

import { useAkinonMutation } from '../adapters/useAkinonMutation';
import { client } from '../client';

export function useCreateProductMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ preOfferId, requestBody }) =>
      client.post(createProductUrl, requestBody, {
        urlParams: { preOfferId },
      }),
    ...mutationOptions,
  });

  return {
    createProduct: mutate,
    isCreatingProduct: isLoading,
  };
}
