import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import noImage from '@assets/images/noimage.png';
import If from '@components/If';
import Box from '@components/utility/box';
import { Carousel, Col, Divider, Image, Row } from 'antd';
import isEmpty from 'lodash/isEmpty';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { getProductInformations } from '../common';

export const ProductInformationBox = ({ conversation, productImages }) => {
  const { t } = useTranslation('Messages');
  const productInformations = getProductInformations({ t, conversation });

  return (
    <Box className="box-primary form-box" title={t('product.information')}>
      <If
        condition={isEmpty(productImages)}
        then={
          <div className="no-image-block">
            <Image src={noImage} />
          </div>
        }
        otherwise={
          <Carousel
            arrows={true}
            slidesToShow={2}
            prevArrow={<LeftOutlined color="white" />}
            nextArrow={<RightOutlined color="white" />}
            dots={null}
          >
            {productImages?.map((productImage, index) => (
              <Image key={index} src={productImage.image} />
            ))}
          </Carousel>
        }
      />
      <Divider />
      {productInformations.map((productInformation, index) => {
        return (
          <Fragment key={index}>
            <Row className="product-information-row">
              <Col span={12}>
                <b>{productInformation.name}</b>
              </Col>
              <Col className="product-information-value" span={12}>
                {productInformation.value}
              </Col>
            </Row>
            <Divider />
          </Fragment>
        );
      })}
    </Box>
  );
};
