import './styles.scss';

import { getPathFragments } from '@common/routing';
import { getInitialFormValues } from '@components/AkinonFilter/common';
import useFilterForm from '@components/AkinonFilter/hooks/context/useFilterForm';
import useFiltersSlice from '@components/AkinonFilter/hooks/store/useFiltersSlice';
import useAppNavigate from '@hooks/useAppNavigate';
import { Col, Row, Space, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import ActiveFilterItem from '../ActiveFilterItem';

const { Text } = Typography;

const ActiveFilters = ({ currentFiltersFormMeta }) => {
  const { t } = useTranslation();
  const { pageFilters, setFilters, resetFilters } = useFiltersSlice();
  const filterForm = useFilterForm();
  const { pathname, search } = useLocation();
  const { mainPath } = getPathFragments(pathname);
  const filterKey = `${mainPath}${search}`;

  const navigate = useAppNavigate();

  const handleOnResetFilters = () => {
    resetFilters({ pagePath: filterKey });
    filterForm.resetFields();
    if (pageFilters?.q) {
      navigate(pathname, { replace: true });
    }
  };

  const handleOnRemoveFilter = (currentFilterKey) => {
    if (currentFilterKey === 'q') {
      navigate(pathname, { replace: true });
    }
    const newFilters = getInitialFormValues({
      fields: currentFiltersFormMeta?.fields,
      pageFilters: Object.entries(pageFilters).reduce((newFilters, [filterKey, filterValue]) => {
        newFilters[filterKey] = filterKey !== currentFilterKey ? filterValue : undefined;
        return newFilters;
      }, {}),
    });

    setFilters({ pagePath: filterKey, filters: newFilters });
    filterForm.setFieldsValue(newFilters);
  };

  return (
    <Row align="middle" className="active-filters">
      <Col className="active-filters__list">
        <Space>
          <Text className="active-filters__label">{t('active.filters')}</Text>
          <Space className="active-filters__items">
            {Object.entries(pageFilters)
              ?.filter(([filterKey, filterValue]) => filterValue !== undefined)
              ?.map(([filterKey, filterValue]) => (
                <ActiveFilterItem
                  key={filterKey}
                  filter={[filterKey, filterValue]}
                  handleOnRemoveFilter={handleOnRemoveFilter}
                  currentFiltersFormMeta={currentFiltersFormMeta}
                />
              ))}
          </Space>
        </Space>
      </Col>
      <Col className="active-filters__clear-filters">
        <i onClick={handleOnResetFilters} className="icon-bin active-filters__reset-filters-icon" />
      </Col>
    </Row>
  );
};

ActiveFilters.propTypes = {
  currentFiltersFormMeta: PropTypes.object.isRequired,
};

export default ActiveFilters;
