import { getOrderCountByDateUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { useAkinonQuery } from '../adapters/useAkinonQuery';
import { client } from '../client';

export function useOrderCountByDateQuery({ params }) {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.ORDER_COUNT_BY_DATE, params],
    queryFn: () => client.get(getOrderCountByDateUrl, { params }),
  });

  return {
    orderCountByDate: data?.order_count_by_date,
    isFetchingOrderCountByDate: isFetching,
  };
}
