// Write a function that converts a file to base64 in promise way

/**
 * @param {File} file
 * @return {Promise<String>} base64 string
 */
export function convertFileToBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}
