import { getRejectionReasonsUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { useAkinonQuery } from '../adapters/useAkinonQuery';
import { client } from '../client';

export function useRejectionReasonsQuery({ params, queryOptions } = {}) {
  const { data, isFetching, isLoading, error } = useAkinonQuery({
    queryKey: [QueryKey.REJECTION_REASONS, params],
    queryFn: () => client.get(getRejectionReasonsUrl, { params }),
    ...queryOptions,
  });

  return {
    rejectionReasonsResponse: data,
    rejectionReasons: data?.results,
    totalRejectionReasons: data?.count,
    isFetchingRejectionReasons: isFetching,
    isRejectionReasonsLoading: isLoading,
    rejectionReasonsError: error,
  };
}
