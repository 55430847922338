import { createContext, useContext } from 'react';

export const AppMetaContext = createContext({
  releaseData: '',
  version: '',
});

export const AppMetaProvider = AppMetaContext.Provider;

export function useAppMeta() {
  return useContext(AppMetaContext);
}
