import { getImageResourceUrl, ImageExtension } from '@common/image';

export const wildButtonProps = {
  style: {
    background: '#f1c494',
    color: '#000000d6',
  },
  icon: (
    <img
      src={getImageResourceUrl({
        name: 'tour',
        extension: ImageExtension.SVG,
      })}
      style={{ width: 28, height: 28, paddingRight: '0.5rem' }}
      alt="Start Tour Icon"
    />
  ),
};
