import { AttributeDataType, FilterType } from '@constants/commontypes';
import { useAttributeValuesMapQueries } from '@services/api/hooks/useAttributeValuesMapQueries';
import find from 'lodash/find';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import reject from 'lodash/reject';
import uniq from 'lodash/uniq';

export function useDynamicColumnsAttributeValues({ dynamicFormMetaFields }) {
  const dynamicFormFields = reject(
    dynamicFormMetaFields,
    (field) =>
      field.data_type !== AttributeDataType.DROPDOWN &&
      field.filterType !== FilterType.DYNAMIC &&
      !Boolean(field.id)
  );
  const attributeIds = uniq(map(dynamicFormFields, 'id'));
  const attributeValueQueriesResult = useAttributeValuesMapQueries({
    queryOptionList: map(attributeIds, (attribute) => ({
      attribute,
    })),
  });
  const isFetchingAttributeValues = attributeValueQueriesResult.some(
    ({ isFetching }) => isFetching
  );
  const attributeValues = reject(flatten(map(attributeValueQueriesResult, 'data.results')), isNil);
  const grouppedAttributeValues = groupBy(
    map(attributeValues, (attrVal) => ({
      ...attrVal,
      key: get(find(dynamicFormFields, { id: attrVal.attribute }), 'fieldKey'),
    })),
    'key'
  );

  return {
    isFetchingAttributeValues,
    attributeValues: grouppedAttributeValues,
  };
}
