import { getUserDetail } from '@services/api';
import { useQuery } from '@tanstack/react-query';

/**
 * @typedef {import('@tanstack/react-query').UseQueryResult} UseQueryResult
 * @returns {import('@tanstack/react-query').UseQueryResult}
 */
export const useUserDetail = ({ id, enabled = true }) => {
  const queryKey = ['userDetail', id];
  const queryFn = () => getUserDetail(id);
  const { data, isLoading, error, refetch, ...rest } = useQuery({ queryKey, queryFn, enabled });

  return {
    data,
    isLoading,
    error,
    refetch,
    ...rest,
  };
};
