import { patchProductStockUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import omit from 'lodash/omit';

import { useAkinonMutation } from '../adapters/useAkinonMutation';
import { client } from '../client';
import { queryClient } from '../queryClient';

export function usePatchProductStockMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody, stockId }) =>
      client.patch(patchProductStockUrl, requestBody, {
        urlParams: { stockId },
      }),
    onSuccess() {
      mutationOptions?.onSuccess?.();
      queryClient.invalidateQueries([QueryKey.PRODUCT_STOCKS]);
    },
    ...omit(mutationOptions, ['onSuccess']),
  });

  return {
    updateProductStockPartially: mutate,
    isProductStockPartiallyUpdating: isLoading,
  };
}
