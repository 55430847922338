import { AttributeDataType, booleanAttributeOptions, WidgetType } from '@constants/commontypes';

export const getAttributeOptions = ({ attributeDataType, attributeOptionsMap, id }) => {
  switch (attributeDataType) {
    case AttributeDataType.BOOLEAN:
      return booleanAttributeOptions;
    case AttributeDataType.DROPDOWN:
      return attributeOptionsMap?.[id];
    default:
      return [];
  }
};

export const getDynamicFilterWidgetProps = ({ widget }) => {
  switch (widget) {
    case WidgetType.SELECT:
      return {
        showSearch: true,
        optionFilterProp: 'label',
      };
    default:
      return {};
  }
};
