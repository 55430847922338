import { addAuthObject } from '@common/auth';
import { getImageResourceUrl, ImageExtension } from '@common/image';
import AkinonSpin from '@components/AkinonSpin';
import { defaultRedirectionUrl, RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { useOAuthAuthenticateMutation } from '@services/api/hooks/useOAuthAuthenticateMutation';
import { useQueryParams } from '@utils/hooks/useQueryParams';
import { Typography } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const { Title, Text } = Typography;

function SignInCallback() {
  const { t } = useTranslation('Authentication');
  const { provider } = useParams();
  const searchQuery = useQueryParams();
  const navigate = useAppNavigate();

  const queryParams = Object.fromEntries(searchQuery.entries());

  const { authenticate, isAuthenticating } = useOAuthAuthenticateMutation({
    mutationOptions: {
      onSuccess(tokenMeta) {
        addAuthObject(tokenMeta);
        navigate(defaultRedirectionUrl);
      },
      onError() {
        navigate(RouteUrls.public.signIn);
      },
    },
  });

  useEffect(() => {
    if (provider) authenticate({ params: queryParams, slug: provider });
  }, [provider]);

  return (
    <AkinonSpin spinning={isAuthenticating}>
      <section className="sign-in">
        <Title level={1} className="sign-in__app-name">
          {t('seller.center').toUpperCase()}
        </Title>

        <footer className="sign-in__footer">
          <img
            className="sign-in__footer-logo"
            src={getImageResourceUrl({
              name: 'akinon-logo',
              extension: ImageExtension.SVG,
            })}
            alt="Akinon Logo"
          />
          <Text className="sign-in__footer-text">
            {t('layout.footer.copyRight', {
              year: new Date().getFullYear(),
            })}
          </Text>
        </footer>
      </section>
    </AkinonSpin>
  );
}

export default SignInCallback;
