import { PluginRenderer } from '@akinon/app-shell';
import { createSelectOptions } from '@common/index';
import AkinonFormBuilder from '@components/AkinonFormBuilder';
import { createTableActions } from '@components/AkinonTable/common';
import ImageTableHead from '@components/ImageTableHead';
import Intrinsic from '@components/Intrinsic';
import SmallColumnImage from '@components/SmallColumnImage';
import TitleWithHint from '@components/TitleWithHint';
import TooltipInput from '@components/TooltipInput';
import { WidgetType } from '@constants/commontypes';
import i18n from '@root/i18n';
import { Button, Tooltip } from 'antd';
import omit from 'lodash/omit';
import React from 'react';

export const ProductsToBeAddedTableFormColumnKey = {
  PRODUCT_IMAGE: 'product_image',
  SKU: 'sku',
  SELLER_BARCODE: 'seller_barcode',
  STOCK: 'stock',
  RETAIL_PRICE: 'retail_price',
  PRICE: 'price',
  REFERENCE_PRICE: 'reference_price',
  CURRENCY_TYPE: 'currency_type',
  TAX_RATE: 'tax_rate',
  TRANSACTIONS: 'transactions',
};

export const ProductsToBeAddedTableFormFormItemKey = {
  ...ProductsToBeAddedTableFormColumnKey,
};

export const PRODUCT_ATTRIBUTES = 'PRODUCT_ATTRIBUTES';

export function shiftVariantListValuesToLeft(variantListFormValues, targetIndex) {
  const numberOfVariantListFormItemColumns = Object.keys(
    variantListFormValues[ProductsToBeAddedTableFormColumnKey.SKU]
  ).length;

  return Object.entries(variantListFormValues).reduce(
    (shiftedVariantListValues, [variantListItemKey, variantListItemValue]) => {
      const shiftedVariantListValue = {};
      for (const [variantItemKey, variantItemValue] of Object.entries(variantListItemValue)) {
        const variantItemIndex = parseInt(variantItemKey);
        if (
          variantItemIndex > targetIndex &&
          variantItemIndex < numberOfVariantListFormItemColumns
        ) {
          const shiftedKey = variantItemIndex - 1;
          shiftedVariantListValue[shiftedKey] = variantItemValue;
        } else {
          shiftedVariantListValue[variantItemKey] = variantItemValue;
        }
      }
      return {
        ...shiftedVariantListValues,
        [variantListItemKey]: shiftedVariantListValue,
      };
    },
    {}
  );
}

export const getColumns = ({
  t,
  variantsFormDynamicMetaFields,
  variantsListAttributeMetaFields,
  dataSource,
  setDataSource,
  form,
  productPricesOptions,
  buyboxPrice,
  isProductPricesOptionsLoading,
  setIsProductImagesModalVisible,
  formRuleMap,
  isMobile,
  isSuperUser,
}) => {
  const variantColumns = variantsFormDynamicMetaFields.map((metaField) => ({
    dataIndex: metaField.key,
    key: metaField.key,
    title: metaField.label,
    render: (cellData) => <Tooltip title={cellData}>{cellData}</Tooltip>,
  }));

  const getTitlePrefix = (isRequired) => (isRequired ? '*' : '');

  const variantAttributeColumns = variantsListAttributeMetaFields.map((metaField) => ({
    dataIndex: metaField.key,
    key: metaField.key,
    title: `${getTitlePrefix(metaField.required)} ${metaField.label}`,
    disabled: true,
    required: metaField.required,
    options: metaField?.options ?? [],
    widget: metaField.widget,
  }));

  const isThereOneProduct = dataSource.length === 1;
  const selectableColumns = [ProductsToBeAddedTableFormColumnKey.CURRENCY_TYPE];
  const currencyOptions = createSelectOptions(
    productPricesOptions?.actions?.POST.currency_type.choices,
    {
      valueKey: 'value',
      labelKey: 'display_name',
    }
  );

  const inputColumns = [
    {
      dataIndex: ProductsToBeAddedTableFormColumnKey.PRODUCT_IMAGE,
      title: <ImageTableHead />,
      render: (productImage) => {
        const onImageClick = () => {
          setIsProductImagesModalVisible(true);
        };
        return <SmallColumnImage onClick={onImageClick} src={productImage} />;
      },
      align: 'center',
    },
    {
      dataIndex: ProductsToBeAddedTableFormColumnKey.SKU,
      title: '* seller sku',
      required: true,
      customWidget: (inputData) => {
        return (
          <div>
            <TooltipInput data={inputData} />
            <PluginRenderer
              placeholderId="ui-protocol-product-detail-sku"
              params={{ sku: inputData?.value }}
            />
          </div>
        );
      },
    },
    {
      dataIndex: ProductsToBeAddedTableFormColumnKey.SELLER_BARCODE,
      title: '* seller barcode',
      required: true,
    },
    {
      dataIndex: ProductsToBeAddedTableFormColumnKey.STOCK,
      title: `* ${t('stock')}`,
      required: true,
      rules: [formRuleMap.integer],
    },
    {
      dataIndex: ProductsToBeAddedTableFormColumnKey.RETAIL_PRICE,
      title: `* ${t('psf')}`,
      required: true,
      rules: [formRuleMap.float],
    },
    {
      dataIndex: ProductsToBeAddedTableFormColumnKey.PRICE,
      title: `* ${t('selling.price')}`,
      required: true,
      rules: [formRuleMap.float],
    },
    {
      dataIndex: ProductsToBeAddedTableFormColumnKey.REFERENCE_PRICE,
      title: (
        <TitleWithHint
          text={t('reference_price').toLocaleUpperCase(i18n.language)}
          hint={t('reference_price_desc')}
        />
      ),
      disabled: true,
      initialValue: buyboxPrice,
    },
    {
      dataIndex: ProductsToBeAddedTableFormColumnKey.CURRENCY_TYPE,
      title: `* ${t('currency')}`,
      options: currencyOptions,
      isLoading: isProductPricesOptionsLoading,
      required: true,
    },
    {
      dataIndex: ProductsToBeAddedTableFormColumnKey.TAX_RATE,
      title: '* vat',
      required: true,
      rules: [formRuleMap.float],
    },
    ...variantAttributeColumns,
    !isThereOneProduct && {
      dataIndex: ProductsToBeAddedTableFormColumnKey.TRANSACTIONS,
      title: t('transactions'),
      fixed: isMobile ? false : 'right',
      render: ({ deleteRow }) => {
        return (
          <Button onClick={deleteRow} className="row-action">
            <Intrinsic className="icon-bin" />
          </Button>
        );
      },
    },
  ]
    .filter(Boolean)
    .map((column) => {
      const shouldHaveTooltip = !column?.render;
      if (shouldHaveTooltip) {
        column.widget = (data) => <TooltipInput data={data} />;
        if (column?.customWidget) {
          column.widget = column.customWidget;
        }
      }
      return {
        ...column,
        disabled: isSuperUser ? true : column.disabled,
        render: (cellData, rowData, rowIndex) => {
          const deleteRow = () => {
            form.setFieldsValue(shiftVariantListValuesToLeft(form.getFieldsValue(), rowIndex));
            setDataSource([...dataSource.slice(0, rowIndex), ...dataSource.slice(rowIndex + 1)]);
          };
          const isColumnSelectable = selectableColumns.includes(column.dataIndex);
          const isProductImageColumn =
            column.dataIndex === ProductsToBeAddedTableFormColumnKey.PRODUCT_IMAGE;

          if (isProductImageColumn) {
            return column.render(cellData, rowData, rowIndex);
          }

          const shouldCustomRender = [ProductsToBeAddedTableFormColumnKey.TRANSACTIONS].includes(
            column.dataIndex
          );

          return shouldCustomRender ? (
            column.render({ deleteRow })
          ) : (
            <AkinonFormBuilder
              form={form}
              meta={[
                {
                  key: `${column.dataIndex}.${rowIndex}`,
                  widget: isColumnSelectable ? WidgetType.SELECT : column.widget,
                  options: column?.options ?? [],
                  rules: [
                    {
                      required: column.required,
                      message: t('validation.message'),
                    },
                    ...(column.rules ?? []),
                  ],
                  initialValue: column.initialValue ?? cellData ?? '',
                  disabled: isSuperUser ? true : column.disabled,
                },
              ]}
            />
          );
        },
      };
    });

  return [...variantColumns, ...inputColumns];
};

export const isModalVisibleMapInitialState = {
  [ProductsToBeAddedTableFormColumnKey.PRODUCT_IMAGE]: false,
  [ProductsToBeAddedTableFormColumnKey.PRICE]: false,
  [ProductsToBeAddedTableFormColumnKey.STOCK]: false,
  [PRODUCT_ATTRIBUTES]: false,
};

export const getTableActions = ({
  t,
  setIsModalVisibleMap,
  dataSource,
  onShowProductRevisionModal,
}) => {
  return createTableActions(
    [
      {
        label: t('add.bulk.price'),
        actionHandler() {
          setIsModalVisibleMap({
            ...isModalVisibleMapInitialState,
            [ProductsToBeAddedTableFormColumnKey.PRICE]: true,
          });
        },
      },
      {
        label: t('add.bulk.stock.amount'),
        actionHandler() {
          setIsModalVisibleMap({
            ...isModalVisibleMapInitialState,
            [ProductsToBeAddedTableFormColumnKey.STOCK]: true,
          });
        },
      },
      {
        label: t('product_revision'),
        actionHandler() {
          onShowProductRevisionModal();
        },
      },
    ].map((tableAction) => ({
      ...tableAction,
      isDisabled: dataSource.length === 0,
    }))
  );
};

export const getDataSource = ({ productMeta }) => {
  return productMeta?.map((productMetaItem) => ({
    ...omit(productMetaItem, ['attributes']),
    ...productMetaItem.attributes,
  }));
};
