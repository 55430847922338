import { standardDateFormat } from '@common/date';
import { createSelectOptions } from '@common/index';
import AkinonButton from '@components/AkinonButton';
import Link from '@components/Link';
import Show from '@components/Show';
import { UserRole } from '@constants/auth';
import { WidgetType } from '@constants/commontypes';
import { RouteUrls } from '@constants/routeUrls';
import { Tooltip } from 'antd';
import cond from 'lodash/cond';
import identity from 'lodash/identity';
import moment from 'moment';
import React from 'react';
import { generatePath } from 'react-router-dom';

import {
  getTicketPriorityOptions,
  getTicketStatusOptions,
  getTicketStatusTag,
  TicketPriority,
  TicketStatus,
} from '../common';

export const DataIndex = {
  SUBJECT: 'subject',
  CREATED_AT: 'created_at',
  SELLER: 'seller',
  MESSAGE: 'message',
  PRIORITY: 'priority',
  STATUS: 'status',
  REPLY: 'reply',
  ASSIGNEE_EMAIL: ['assignee', 'email'],
};

export const getColumns = ({ t, userRole, navigate }) => {
  const isSuperUser = userRole === UserRole.SUPER_USER;

  return [
    {
      dataIndex: DataIndex.CREATED_AT,
      visibleOnFilter: [StaticFilterKey.START_DATE],
      title: t('date'),
      render(createdAt) {
        return moment(createdAt).format(standardDateFormat);
      },
    },
    isSuperUser && {
      dataIndex: DataIndex.SELLER,
      visibleOnFilter: [StaticFilterKey.SELLER],
      title: t('seller'),
    },
    {
      dataIndex: DataIndex.SUBJECT,
      title: t('subject'),
      render(subject, ticket) {
        return (
          <Link
            to={generatePath(RouteUrls.support.supportTicketDetail, { ticketId: ticket.id })}
            state={{
              isScrollToTop: true,
            }}
          >
            {subject}
          </Link>
        );
      },
    },
    isSuperUser && {
      dataIndex: DataIndex.PRIORITY,
      visibleOnFilter: [StaticFilterKey.PRIORITY],
      title: t('priority'),
      render(priority) {
        return cond([
          [(priority) => priority === TicketPriority.DEFAULT, () => t('default')],
          [(priority) => priority === TicketPriority.MEDIUM, () => t('medium')],
          [(priority) => priority === TicketPriority.HIGH, () => t('high')],
        ])(priority);
      },
    },
    isSuperUser && {
      dataIndex: DataIndex.ASSIGNEE_EMAIL,
      title: t('assignee'),
      render(assigneeEmail) {
        return <Tooltip title={assigneeEmail}> {assigneeEmail} </Tooltip>;
      },
    },
    {
      dataIndex: DataIndex.STATUS,
      visibleOnFilter: [StaticFilterKey.STATUS],
      title: t('status'),
      render(status) {
        return getTicketStatusTag({ status, t });
      },
    },
    {
      dataIndex: DataIndex.REPLY,
      title: t('reply'),
      width: 92,
      render(_, ticket) {
        return (
          <Show when={ticket.status === TicketStatus.OPEN}>
            <AkinonButton
              className="support-ticket-list__reply-button"
              type="default"
              icon={<i className="icon-reply" />}
              onClick={() => {
                navigate(RouteUrls.support.supportTicketDetail, {
                  ticketId: ticket.id,
                  state: {
                    preventScrollReset: true,
                    isFocusToMessageInput: true,
                  },
                });
              }}
            />
          </Show>
        );
      },
    },
  ];
};

export const StaticFilterKey = {
  SELLER: 'datasource',
  START_DATE: 'created_at__gte',
  END_DATE: 'created_at__lte',
  PRIORITY: 'priority',
  STATUS: 'status',
};

export const getStaticFilters = ({
  t,
  userRole,
  dataSources,
  disabledStartDate,
  disabledEndDate,
}) => {
  const isSuperUser = userRole === UserRole.SUPER_USER;
  const dataSourcesOptions = createSelectOptions(dataSources, {
    labelKey: 'name',
    valueKey: 'id',
    transformLabel: identity,
  });

  return {
    staticFiltersFormMetaFields: [
      isSuperUser && {
        key: StaticFilterKey.SELLER,
        widget: WidgetType.SELECT,
        placeholder: t('seller'),
        options: dataSourcesOptions,
        widgetProps: {
          showSearch: true,
          optionFilterProp: 'label',
        },
      },
      {
        key: StaticFilterKey.START_DATE,
        widget: WidgetType.DATE_PICKER,
        placeholder: t('startDate'),
        widgetProps: {
          disabledDate: disabledStartDate,
        },
      },
      {
        key: StaticFilterKey.END_DATE,
        widget: WidgetType.DATE_PICKER,
        placeholder: t('endDate'),
        widgetProps: {
          disabledDate: disabledEndDate,
        },
      },
      isSuperUser && {
        key: StaticFilterKey.PRIORITY,
        widget: WidgetType.SELECT,
        placeholder: t('priority'),
        options: getTicketPriorityOptions({ t }),
      },
      {
        key: StaticFilterKey.STATUS,
        widget: WidgetType.SELECT,
        placeholder: t('status'),
        options: getTicketStatusOptions({ t }),
      },
    ].filter(Boolean),
    isStaticFiltersLoading: false,
  };
};
