import { wildButtonProps } from '@components/WildButton';
import { UserRole } from '@constants/auth';
import { useTour } from '@reactour/tour';
import { useUserRole } from '@utils/hooks/useUserRole';
import useStore from '@zustand-store/index';
import { setPageMetaSelector } from '@zustand-store/selectors/app';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

export function usePageMeta() {
  const { t } = useTranslation('Orders');
  const setPageMeta = useStore(setPageMetaSelector);
  const { setIsOpen, setCurrentStep } = useTour();
  const userRole = useUserRole();

  useEffect(() => {
    setPageMeta({
      title: t('orders_list'),
      description: t('orders_list_desc'),
      actions: userRole === UserRole.SELLER && [
        {
          ...wildButtonProps,
          label: t('start_tour'),
          onClick() {
            setCurrentStep(0);
            setIsOpen(true);
          },
        },
      ],
    });
  }, [t, userRole]);

  useUnmount(() => {
    setPageMeta({});
  });
}
