import { WidgetType } from '@constants/commontypes';
import { overallDateFormat } from '@constants/index';
import { getTreeNodeByValue } from '@utils/index';

export const isFileObject = (value) => value?.originFileObj !== undefined;

export const getModifiedFilterValue = ({ t, currentFiltersFormMetaField, filterValue }) => {
  switch (currentFiltersFormMetaField?.widget) {
    case WidgetType.SELECT:
      if (currentFiltersFormMetaField?.widgetProps?.mode === 'multiple') {
        return filterValue
          ?.map((value) => {
            const optionObject = currentFiltersFormMetaField.options?.find(
              (option) => option.value === value
            );
            return optionObject?.label || value;
          })
          .join(', ');
      }

      const optionObject = currentFiltersFormMetaField.options?.find(
        (option) => option.value === filterValue
      );

      return optionObject?.label || filterValue;
    case WidgetType.DATE_PICKER:
      return filterValue?.format?.(overallDateFormat) ?? filterValue;

    case WidgetType.CHECKBOX_GROUP:
      return filterValue?.map(() => t('yes')).join(', ');
    case WidgetType.TREESELECT:
      const tree = currentFiltersFormMetaField?.widgetProps?.treeData;
      const label = getTreeNodeByValue({ tree, nodeValue: filterValue })?.path;
      return label || filterValue;
    default:
      return filterValue;
  }
};
