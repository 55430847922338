import AkinonButton from '@components/AkinonButton';
import React from 'react';
import { useTranslation } from 'react-i18next';

const FiltersDrawerFooter = ({ onApplyFilters, onClearFilters }) => {
  const { t } = useTranslation();
  return (
    <div className="filters-drawer-footer">
      <AkinonButton
        type="text"
        className="filters-drawer-footer__clear-filters-button"
        onClick={onClearFilters}
      >
        {t('clear_filters')}
      </AkinonButton>
      <AkinonButton onClick={onApplyFilters}>{t('apply_filters')}</AkinonButton>
    </div>
  );
};

export default FiltersDrawerFooter;
