import AkinonFilter from '@components/AkinonFilter';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { getExportsUrl } from '@constants/apiUrls';
import { defaultRowKey } from '@constants/index';
import { Divider } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getExportTableColumns, getStaticFilters } from './common';
import { useDataSource } from './hooks/useDataSource';

export default function ExportsTab() {
  const { t } = useTranslation('ImportExportOperations');

  const [pagination, setPagination] = usePagination();
  const { dataSource, isLoading, total } = useDataSource({ pagination });
  const exportTableColumns = getExportTableColumns({ t });
  const staticFilters = getStaticFilters({ t });

  return (
    <section>
      <AkinonFilter title={t('filters')} {...staticFilters} />
      <Divider />
      <AkinonTable
        pagination={pagination}
        setPagination={setPagination}
        rowKey={defaultRowKey}
        title={t('exports')}
        dataSource={dataSource}
        optionsUrl={getExportsUrl}
        loading={isLoading}
        total={total}
        columns={exportTableColumns}
        description={t('n_results_found', { count: total })}
      />
    </section>
  );
}
