import './styles.scss';

import AkinonButton from '@components/AkinonButton';
import AkinonPopover from '@components/AkinonPopover';
// import TableSettings from './TableSettings';
import useTableExport from '@components/AkinonTable/hooks/useTableExport';
import { Color } from '@constants/theme';
// import { IconSettings } from '@tabler/icons-react';
import { Space, Typography } from 'antd';
import isEmpty from 'lodash/isEmpty';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import AkinonPagination from '../AkinonPagination';
import AkinonTableActions from '../AkinonTableActions';
import { ExportModal } from './ExportModal';

const { Title, Text } = Typography;

const StandardAkinonTableHeader = ({
  pagination,
  setPagination,
  selectedRows,
  setSelectedRows,
  tableActions,
  title,
  description,
  pageSizeLabel,
  exportOptions,
  exportFormats,
  columns,
  onExport,
  // columnDisplayStatuses,
  // setColumnDisplayStatus,
  tableHeaderExtra,
}) => {
  const { t } = useTranslation();
  const {
    exportOption,
    setExportOption,
    exportFormat,
    setExportFormat,
    tableExportColumns,
    setTableExportColumns,
  } = useTableExport();
  const hasExportableColumns = columns.filter((column) => column?.exportKey)?.length > 0;
  // const [isTableSettingsVisible, setIsTableSettingsVisible] = useState(false);
  const [isExportModalVisible, setIsExportModalVisible] = useState(false);

  return (
    <>
      <div className="standard-akinon-table-header">
        <div className="standard-akinon-table-header__container">
          <div className="standard-akinon-table-header__info">
            <Title className="standard-akinon-table-header__title" level={3}>
              {title}
            </Title>
            <Text className="standard-akinon-table-header__description">{description}</Text>
          </div>
          <div className="standard-akinon-table-header__export-options">
            {tableHeaderExtra}
            {exportFormats?.length > 0 ? (
              <>
                {exportOptions?.length > 0 ? (
                  <AkinonPopover
                    className="export-list__export-options-popover"
                    content={
                      <Space direction="vertical">
                        {exportFormats.map((_exportFormat) => (
                          <>
                            {exportOptions.map((_exportOption) => (
                              <AkinonButton
                                key={_exportOption.value}
                                style={{ width: '100%' }}
                                type="text"
                                loading={_exportOption.loading}
                                onClick={() => {
                                  setExportOption(_exportOption.value);
                                  setExportFormat(_exportFormat);
                                  if (hasExportableColumns) {
                                    setIsExportModalVisible((prev) => !prev);
                                  } else {
                                    const onExportPayload = {
                                      exportOption: _exportOption.value,
                                      exportFormat: _exportFormat,
                                    };
                                    onExport(onExportPayload);
                                  }
                                }}
                              >
                                {_exportOption.label}
                              </AkinonButton>
                            ))}
                          </>
                        ))}
                      </Space>
                    }
                    trigger="focus"
                  >
                    <AkinonButton style={{ backgroundColor: Color.DODGER_BLUE }} type="primary">
                      {t('export')}
                    </AkinonButton>
                  </AkinonPopover>
                ) : (
                  exportFormats.map((_exportFormat) => (
                    <div
                      onClick={() => {
                        if (hasExportableColumns) {
                          setIsExportModalVisible((prev) => !prev);
                        } else {
                          const onExportPayload = {
                            exportFormat: _exportFormat,
                          };
                          onExport(onExportPayload);
                        }
                      }}
                      key={_exportFormat}
                      className="standard-akinon-table-header__export-option"
                    >
                      <div className="standard-akinon-table-header__export-option-icon">
                        <i className="icon-download" />
                      </div>
                      <label className="standard-akinon-table-header__export-option-label">
                        {_exportFormat}
                      </label>
                    </div>
                  ))
                )}
              </>
            ) : null}
            {/* <div
            className="standard-akinon-table-header__settings"
            onClick={() => setIsTableSettingsVisible((prev) => !prev)}
          >
            <IconSettings stroke="1" />
          </div> */}
            {/* <TableSettings
            open={isTableSettingsVisible}
            onCancel={() => setIsTableSettingsVisible(false)}
            onOpenChange={setIsTableSettingsVisible}
            columns={columns}
            columnDisplayStatuses={columnDisplayStatuses}
            setColumnDisplayStatus={setColumnDisplayStatus}
            exportOptions={exportOptions}
            exportFormats={exportFormats}
            selectedRows={selectedRows}
            onExport={onExport}
            isExportModalVisible={isExportModalVisible}
            setIsExportModalVisible={setIsExportModalVisible}
          /> */}
            <ExportModal
              isExportModalVisible={isExportModalVisible}
              setIsExportModalVisible={setIsExportModalVisible}
              columns={columns}
              exportOption={exportOption}
              exportFormat={exportFormat}
              tableExportColumns={tableExportColumns}
              setTableExportColumns={setTableExportColumns}
              onExport={onExport}
            />
          </div>
        </div>
        <div className="standard-akinon-table-header__actions-container">
          {!isEmpty(tableActions) && (
            <AkinonTableActions
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              tableActions={tableActions}
              placeholder={t('select.action')}
            />
          )}

          {pagination && (
            <AkinonPagination
              pageSizeLabel={pageSizeLabel}
              pagination={pagination}
              setPagination={setPagination}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default StandardAkinonTableHeader;
