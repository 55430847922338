import { QueryKey } from '@constants/query';
import { getDynamicSettingsRegistry } from '@services/api/dynamicSettings';
import { useQuery } from '@tanstack/react-query';

export const useDynamicSettingsRegistryQuery = ({ queryOptions } = {}) => {
  const queryKey = [QueryKey.DYNAMIC_SETTINGS_REGISTRY];

  const queryFn = () => getDynamicSettingsRegistry();
  const { data, isFetching, isLoading, error } = useQuery({
    queryKey,
    queryFn,
    ...queryOptions,
  });

  return {
    dynamicSettingsRegistry: data,
    isDynamicSettingsRegistryFetching: isFetching,
    isDynamicSettingsRegistryLoading: isLoading,
    dynamicSettingsRegistryError: error,
  };
};
