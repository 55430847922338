import { RouteUrls } from '@constants/routeUrls.js';
import React from 'react';
import { generatePath, Navigate, useParams } from 'react-router-dom';

function ExternalDownload() {
  const { cacheKey } = useParams();

  return <Navigate to={generatePath(RouteUrls.download.landing, { cacheKey })} replace />;
}

export default ExternalDownload;
