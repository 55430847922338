import { getBulkTemplateAttributes } from '@services/api';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';

export function useDownloadBulkTemplateAttribute() {
  const mutationResponse = useAkinonMutation({
    mutationFn: getBulkTemplateAttributes,
  });

  return {
    downloadBulkTemplateAttribute: mutationResponse.mutate,
    isBulkTemplateAttributeDownloading: mutationResponse.isLoading,
  };
}
