import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import styled from 'styled-components';

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 8px;
  background-color: rgba(113, 122, 142, 0.2);
  animation-name: base-layout-suspense-animation;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  border: 1px solid rgba(113, 122, 142, 0.4);
`;

const StyledSpin = styled(Spin)`
  color: #4482ff;
`;

export default function BaseLayoutFallback() {
  return (
    <StyledSection>
      <StyledDiv>
        <StyledSpin indicator={<LoadingOutlined spin />} size="large" />
      </StyledDiv>
    </StyledSection>
  );
}
