import { postDataSourcesUrl } from '@constants/apiUrls';

import { useAkinonMutation } from '../adapters/useAkinonMutation';
import { client } from '../client';

export function usePostDataSourceMutation({ mutationOptions } = {}) {
  const { isLoading, mutate } = useAkinonMutation({
    mutationFn: ({ requestBody, requestConfig }) =>
      client.post(postDataSourcesUrl, requestBody, requestConfig),
    ...mutationOptions,
  });

  return {
    createDataSource: mutate,
    isCreatingDataSource: isLoading,
  };
}
