import './styles.scss';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import HeadlessAkinonTable from '../HeadlessAkinonTable';

const StandardAkinonTable = ({ header, tableProps, footer }) => {
  return (
    <div className={clsx('standard-akinon-table', tableProps?.wrapperClassName)}>
      {header}
      <HeadlessAkinonTable {...tableProps} />
      {footer}
    </div>
  );
};

StandardAkinonTable.propTypes = {
  header: PropTypes.node,
  tableProps: PropTypes.object,
  footer: PropTypes.node,
};

export default StandardAkinonTable;
