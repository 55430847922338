import AkinonForm from '@components/AkinonForm';
import AkinonFormItem from '@components/AkinonFormItem';
import AkinonInput from '@components/AkinonInput';
import AkinonTreeSelect from '@components/AkinonTreeSelect';
import Box from '@components/utility/box';
import { verticalFormItemLayout } from '@constants/layoutTypes';
import { useCategoryTreeData } from '@utils/hooks/useCategoryTreeData';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BasicProductInfoFormItemKey } from './common';

/**
 * @param {{basicProductInfoForm: import('react-hook-form').UseFormReturn, }} props
 */
const BasicProductInfoForm = ({ basicProductInfoForm }) => {
  const { control } = basicProductInfoForm;
  const { t } = useTranslation('ProductsAndCategories');

  const { categoryTreeData, isCategoriesLoading } = useCategoryTreeData({
    generateTreeDataOptions: {
      valueKey: 'attribute_set',
      useItemId: true,
    },
  });

  return (
    <Box
      className="box-primary form-box"
      title={t('basic_product_info_title')}
      subtitle={t('basic_product_info_subtitle')}
    >
      <AkinonForm
        className="akn-form akn-form-v2"
        name="basicProductInfoForm"
        layout="vertical"
        colon
        {...verticalFormItemLayout}
      >
        <AkinonFormItem
          control={control}
          name={BasicProductInfoFormItemKey.NAME}
          id={BasicProductInfoFormItemKey.NAME}
          label={t('product_name')}
          requiredMarkPosition="right"
          requiredMarkContent={`* ${t('required')}`}
          required
        >
          <AkinonInput />
        </AkinonFormItem>
        <AkinonFormItem
          control={control}
          name={BasicProductInfoFormItemKey.BASE_CODE}
          label={t('basecode')}
          requiredMarkContent={`* ${t('required')}`}
          requiredMarkPosition="right"
          tooltip={{
            title: t('base_code_info'),
            style: { strokeColor: 'white' },
          }}
          required
        >
          <AkinonInput />
        </AkinonFormItem>

        <AkinonFormItem
          control={control}
          name={BasicProductInfoFormItemKey.CATEGORY}
          label={t('category')}
          requiredMarkContent={`* ${t('required')}`}
          requiredMarkPosition="right"
          tooltip={{
            title: t('category_info'),
          }}
          required
        >
          <AkinonTreeSelect
            loading={isCategoriesLoading}
            treeData={categoryTreeData}
            treeNodeFilterProp={'title'}
            allowClear
            showSearch
            treeDefaultExpandAll
            virtual={false}
            style={{ width: '100%' }}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          />
        </AkinonFormItem>
      </AkinonForm>
    </Box>
  );
};

export default BasicProductInfoForm;
