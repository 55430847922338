import { usePageFilters } from '@components/AkinonFilter/hooks/store/usePageFilters';
import { useContentTypesQuery } from '@services/api/hooks/usePaginatedContentTypesQuery';
import { usePaginatedIntegrationErrorsQuery } from '@services/api/hooks/usePaginatedIntegrationErrorsQuery';
import { useUsersQuery } from '@services/api/hooks/usePaginatedUsersQuery';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

export function useDataSource({ pagination }) {
  const filters = usePageFilters();

  const { integrationErrors, isFetchingIntegrationErrors, totalIntegrationErrors } =
    usePaginatedIntegrationErrorsQuery({
      filters,
      pagination,
      params: {
        sort: '-created_at',
      },
    });
  const contentTypesIds = uniq(
    integrationErrors?.map((integrationError) => integrationError.content_type).filter(Boolean)
  );
  const { contentTypes, isFetchingContentTypes } = useContentTypesQuery({
    params: {
      id__in: contentTypesIds.join(','),
      limit: contentTypesIds.length,
    },
    queryOptions: {
      enabled: !isEmpty(contentTypesIds),
    },
  });

  const usersIds = uniq(
    integrationErrors?.map((integrationError) => integrationError.resolved_by).filter(Boolean)
  );

  const { users } = useUsersQuery({
    params: {
      id__in: usersIds.join(','),
      limit: usersIds.length,
    },
    queryOptions: {
      enabled: !isEmpty(usersIds),
    },
  });

  const getUserName = (user) => user && `${user.first_name} ${user.last_name}`;

  const dataSource = integrationErrors?.map((integrationError) => ({
    ...integrationError,
    content_type: contentTypes?.find(
      (contentType) => contentType?.id === integrationError.content_type
    ),
    resolved_by: getUserName(users?.find((user) => user?.id === integrationError.resolved_by)),
  }));

  return {
    dataSource,
    loading: isFetchingIntegrationErrors || isFetchingContentTypes,
    total: totalIntegrationErrors,
  };
}
