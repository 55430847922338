import AkinonFormItem from '@components/AkinonFormItem';
import clsx from 'clsx';
import React from 'react';

import { QuillEditor } from './QuillEditor';

export * from './QuillEditor';

export const AkinonQuillEditor = ({ className, formItemProps, ...otherProps }) => {
  const component = (
    <QuillEditor
      className={clsx('akinon-quill-editor', className)}
      {...otherProps}
      aria-label={otherProps?.['aria-label'] || otherProps?.label}
    />
  );

  if (formItemProps) return <AkinonFormItem {...formItemProps}>{component}</AkinonFormItem>;
  else return component;
};
