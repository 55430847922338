import usePageData from '@hooks/usePageData';
import { useEffect } from 'react';
import { useMap } from 'react-use';

const useDisplayedColumns = ({ columns = [] }) => {
  const { pageData, setPageData } = usePageData();
  const [columnDisplayStatuses, { set }] = useMap(pageData?.columnDisplayStatuses);
  const displayedColumns = columns.filter((column) => {
    if (columnDisplayStatuses?.[column.key] === 'hide') return false;
    else return true;
  });

  useEffect(() => {
    setPageData({ columnDisplayStatuses });
  }, [columnDisplayStatuses]);

  return { displayedColumns, columnDisplayStatuses, setColumnDisplayStatus: set };
};

export default useDisplayedColumns;
