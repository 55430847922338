import { dateAndTimeFormat } from '@common/date';
import { LogCenterRecordType } from '@common/index';
import {
  IconAlertCircle,
  IconCircleDashedCheck,
  IconError404,
  IconInfoCircle,
} from '@tabler/icons-react';
import { Typography } from 'antd';
import moment from 'moment';
import React from 'react';

const { Text } = Typography;

export const getCSVHeaders = ({ t }) => {
  return [
    {
      label: t('layout.logCenter.csv.headers.type'),
      key: 'type',
    },
    {
      label: t('layout.logCenter.csv.headers.label'),
      key: 'label',
    },
    {
      label: t('layout.logCenter.csv.headers.message'),
      key: 'message',
    },
    {
      label: t('layout.logCenter.csv.headers.date'),
      key: 'date',
    },
  ];
};

export const getRecordFragmentsByType = ({ t }) => {
  return {
    [LogCenterRecordType.SUCCESS]: {
      icon: <IconCircleDashedCheck className="text-xl text-success" />,
      label: <Text type="success">{t('layout.logCenter.label.success')}</Text>,
    },
    [LogCenterRecordType.ERROR]: {
      icon: <IconError404 className="text-xl text-color-error" />,
      label: <Text type="danger">{t('layout.logCenter.label.error')}</Text>,
    },
    [LogCenterRecordType.WARNING]: {
      icon: <IconAlertCircle className="text-xl text-warning" />,
      label: <Text type="warning">{t('layout.logCenter.label.warning')}</Text>,
    },
    [LogCenterRecordType.INFO]: {
      icon: <IconInfoCircle className="text-xl text-info" />,
      label: <Text className="text-info">{t('layout.logCenter.label.info')}</Text>,
    },
  };
};

/**
 * @param {import('@common/index').LogCenterRecord[]} logCenterRecords
 */
export const transformLogCenterRecordsForCSV = (logCenterRecords) => {
  return logCenterRecords.map((record) => ({
    ...record,
    date: moment(record.date).format(dateAndTimeFormat),
  }));
};
