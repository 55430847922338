import './styles.scss';

import { CloseCircleOutlined } from '@ant-design/icons';
import { getPathFragments } from '@common/routing';
import { getInitialFormValues } from '@components/AkinonFilter/common';
import useFilterForm from '@components/AkinonFilter/hooks/context/useFilterForm';
import useFiltersSlice from '@components/AkinonFilter/hooks/store/useFiltersSlice';
import AkinonInput from '@components/AkinonInput';
import useAppNavigate from '@hooks/useAppNavigate';
import { Row } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useLocation } from 'react-router-dom';

const MobileSearchFilter = ({ currentFiltersFormMeta, searchQuery }) => {
  const { pageFilters, setFilters } = useFiltersSlice();
  const filterForm = useFilterForm();
  const { pathname, search } = useLocation();
  const { mainPath } = getPathFragments(pathname);
  const filterKey = `${mainPath}${search}`;

  const navigate = useAppNavigate();

  const handleOnRemoveFilter = (currentFilterKey) => {
    navigate(pathname, { replace: true });
    const newFilters = getInitialFormValues({
      fields: currentFiltersFormMeta?.fields,
      pageFilters: Object.entries(pageFilters).reduce((newFilters, [filterKey, filterValue]) => {
        newFilters[filterKey] = filterKey !== currentFilterKey ? filterValue : undefined;
        return newFilters;
      }, {}),
    });

    setFilters({ pagePath: filterKey, filters: newFilters });
    filterForm.setFieldsValue(newFilters);
  };

  if (!searchQuery) return null;

  return (
    <Row align="middle" className="mobile-search-filter akinon-form-dark">
      <AkinonInput
        value={searchQuery}
        readOnly
        suffix={
          <div className="mobile-search-filter__button" onClick={() => handleOnRemoveFilter('q')}>
            <CloseCircleOutlined />
          </div>
        }
      />
    </Row>
  );
};

MobileSearchFilter.propTypes = {
  currentFiltersFormMeta: PropTypes.object.isRequired,
};

export default MobileSearchFilter;
