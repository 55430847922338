import './styles.scss';

import { usePageFilters } from '@components/AkinonFilter/hooks/store/usePageFilters';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { getStockListsUrl } from '@constants/apiUrls';
import { defaultRowKey } from '@constants/index';
import { RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { usePaginatedStockLists } from '@services/hooks/products/usePaginatedStockLists';
import useResetTableCurrentPageWhenFiltersChanged from '@utils/hooks/useResetTableCurrentPageWhenFiltersChanged';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getColumns } from './common';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { usePageMeta } from './hooks/usePageMeta';

const StockList = () => {
  const { t } = useTranslation('ProductsAndCategories');
  const navigate = useAppNavigate();
  const filters = usePageFilters();
  const [pagination, setPagination] = usePagination();
  const { data, total, isLoading } = usePaginatedStockLists({ pagination, filters });

  useBreadcrumbs();
  usePageMeta();

  const columns = getColumns({ t });
  useResetTableCurrentPageWhenFiltersChanged({ pagination, setPagination, filters });

  const onRowClick = (record) => () => {
    navigate(RouteUrls.productsAndCategories.inventoryManagement.stocks.stockDetail, {
      stockId: record.id,
    });
  };

  return (
    <section className="stock-list">
      <AkinonTable
        title={t('stock.list')}
        columns={columns}
        dataSource={data}
        optionsUrl={getStockListsUrl}
        loading={isLoading}
        total={total}
        rowKey={defaultRowKey}
        pagination={pagination}
        setPagination={setPagination}
        description={`${total} ${t('results.found')}`}
        tableActions={[]}
        onRow={(record) => ({
          onClick: onRowClick(record),
        })}
      />
    </section>
  );
};

export default StockList;
