import { usePageFilters } from '@components/AkinonFilter/hooks/store/usePageFilters';
import { getDynamicColumns, transformDynamicFilters } from '@pages/ProductsAndCategories/common';
import { useDynamicFilters } from '@utils/hooks/useDynamicFilters';
import intersection from 'lodash/intersection';

import AkinonTableSorter from '../components/AkinonTableSorter';
// import { getSimpleDynamicColumns } from './common';
import { useOrderingQuery } from './useOrderingQuery';

const useColumns = ({
  columns = [],
  appendDynamicColumnsAt = -1,
  enableDynamicColumns,
  optionsUrl,
}) => {
  const dynamicFilters = transformDynamicFilters(
    useDynamicFilters({ isEnabled: Boolean(enableDynamicColumns) })
  );
  const { ordering } = useOrderingQuery({
    queryOptions: {
      enabled: Boolean(optionsUrl),
    },
    url: optionsUrl,
  });
  const filters = usePageFilters();

  const { dynamicFiltersFormMetaFields } = dynamicFilters;

  const filteredStaticColumns = columns.filter((column) => {
    if (
      column?.visibleOnFilter &&
      !intersection(Object.keys(filters), column.visibleOnFilter)?.length
    )
      return false;
    else return true;
  });
  // const simpleDynamicColumns = getSimpleDynamicColumns({ dynamicFiltersFormMetaFields });
  // const _dynamicColumns = enableDynamicColumns ? simpleDynamicColumns : [];
  const { dynamicColumns } = getDynamicColumns({
    filters,
    dynamicFiltersFormMetaFields,
  });
  const _dynamicColumns = enableDynamicColumns ? dynamicColumns : [];

  const slicedColumns = filteredStaticColumns.slice(0, appendDynamicColumnsAt);
  const restColumns = filteredStaticColumns.slice(appendDynamicColumnsAt);
  const visibleColumns = [...slicedColumns, ..._dynamicColumns, ...restColumns];
  const allColumns = visibleColumns
    .filter(Boolean)
    .map((column, index) => {
      let key = column.key;
      if (!key) {
        key = column?.dataIndex ?? index;
        if (Array.isArray(key)) key = key.join('_');
      }

      return { ...column, key };
    })
    .map((column) => {
      if (ordering?.includes?.(column.sortKey ?? column.dataIndex)) {
        return {
          ...column,
          title: (
            <AkinonTableSorter sortKey={column.sortKey ?? column.dataIndex} title={column.title} />
          ),
        };
      }
      return column;
    });

  return { columns: allColumns };
};

export default useColumns;
