import usePageData from '@hooks/usePageData';
import { useEffect, useState } from 'react';
import { useMap } from 'react-use';

const useTableExport = () => {
  const { pageData, setPageData } = usePageData();
  const [exportFormat, setExportFormat] = useState('XLSX');
  const [exportOption, setExportOption] = useState();
  const [tableExportColumns, { set: setTableExportColumns }] = useMap(pageData?.tableExportColumns);

  useEffect(() => {
    setPageData({ tableExportColumns });
  }, [tableExportColumns]);

  return {
    exportOption,
    setExportOption,
    exportFormat,
    setExportFormat,
    tableExportColumns,
    setTableExportColumns,
  };
};

export default useTableExport;
