import { getTicketMessagesUrl } from '@constants/apiUrls';
import { limitQuery } from '@constants/commontypes';
import { QueryKey } from '@constants/query';

import { useAkinonQuery } from '../adapters/useAkinonQuery';
import { client } from '../client';

export function useTicketMessagesQuery({ queryOptions, params, ticketId }) {
  const { data, isFetching, isLoading, refetch } = useAkinonQuery({
    queryKey: [QueryKey.TICKET_MESSAGES, params, ticketId],
    queryFn: () =>
      client.get(getTicketMessagesUrl, {
        params: {
          ...limitQuery,
          ...params,
        },
        urlParams: { ticketId },
      }),
    ...queryOptions,
  });

  return {
    ticketMessages: data,
    isFetchingTicketMessages: isFetching,
    isTicketMessagesLoading: isLoading,
    refetchTicketMessages: refetch,
  };
}
