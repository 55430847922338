import { z } from 'zod';

export const FormKey = {
  VALID_UNTIL: 'valid_until',
  NOTE: 'note',
};

export const schema = z.object({
  [FormKey.VALID_UNTIL]: z.coerce.date(),
  [FormKey.NOTE]: z.string().optional(),
});
