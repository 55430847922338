import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { getProducts } from '@services/api';
import { useQuery } from '@tanstack/react-query';

export const useProductDetailQuery = ({ pagination, filters, ids, enabled = true }) => {
  const queryKey = ['product-detail', ...(ids ?? []), pagination ?? {}, filters ?? []];
  const queryFn = () =>
    getProducts({
      ...getRequestPaginationParams(pagination),
      ...getRequestFiltersParams(filters),
      id__in: ids?.join(','),
    });
  const { data, isLoading, isFetching, error, ...rest } = useQuery({
    queryKey,
    queryFn,
    enabled,
  });

  return { ...rest, data: data?.results, total: data?.count, isLoading, isFetching, error };
};
