import { getRefineImages } from '@common/form';
import { ApiConfigType } from '@pages/ProductsAndCategories/ProductForm/common';
import { assign } from 'lodash';
import { z } from 'zod';

export const FormKey = {
  NAME: 'name',
  IMAGES: 'images',
};

export const getFormSchema = ({ t, productAttributes, dimensionBounds }) => {
  if (!productAttributes) return {};
  const refineImages = getRefineImages({ t, dimensionBounds });

  const dynamicSchemaFields = productAttributes.map((attribute) => {
    if (attribute?.data_type === ApiConfigType.BOOL) {
      return { [attribute.key]: z.boolean().optional() };
    }
    return { [attribute.key]: z.string().optional() };
  });

  const objectSchema = assign(
    {
      [FormKey.IMAGES]: z.array(z.any()).superRefine(refineImages).optional(),
    },
    {
      [FormKey.NAME]: z.string().optional(),
    },
    ...dynamicSchemaFields
  );

  return z.object(objectSchema);
};
