import AkinonInput, { AkinonInputNumber } from '@components/AkinonInput';
import { omit } from 'lodash';

import { FormKey } from '../common';

export const CalculatorFormKey = {
  CalculatorType: 'calculator.type',
};

export const FieldType = {
  LIST: 'list',
  NESTED: 'nested object',
  INPUT: 'input',
  STRING: 'string',
  NUMBER: 'number',
  FLOAT: 'float',
  CHOICE: 'choice',
  BOOLEAN: 'boolean',
  SELECT: 'select',
  PASSWORD: 'password',
  EMAIL: 'email',
  INTEGER: 'integer',
  DECIMAL: 'decimal',
  PERCENTAGE: 'percentage',
};

export const getCalculatorTypeOptions = (calculators) => {
  if (!calculators) return [];
  return calculators.map((calculator) => ({
    label: calculator.name,
    value: calculator.slug,
  }));
};

const getFieldCreator = (control, fieldType) => {
  return {
    [FieldType.STRING]: ({ field, name, disabled }) => (
      <AkinonInput
        formItemProps={{
          control,
          name,
          label: field.label,
        }}
        placeholder={field.label}
        disabled={disabled}
      />
    ),
    [FieldType.DECIMAL]: ({ field, name, disabled }) => (
      <AkinonInputNumber
        formItemProps={{
          control,
          name,
          label: field.label,
        }}
        placeholder={field.label}
        disabled={disabled}
        step={0.1}
      />
    ),
    //? [FieldType.NESTED]: TODO, <-- BE NOT READY
  }[fieldType];
};

export const getSelectedCalculatorFields = ({ control, selectedCalculator, disabled }) => {
  if (!selectedCalculator) return [];
  // Slug will be derived from the selected calculator, see SELLER-2359
  const fieldsToGenerate = Object.keys(omit(selectedCalculator.params, 'slug'));

  const fields = fieldsToGenerate.flatMap((paramKey) => {
    const param = selectedCalculator.params[paramKey];
    const field = getFieldCreator?.(
      control,
      param.type
    )?.({
      field: param,
      fieldType: param.type,
      name: [FormKey.CALCULATOR, paramKey].join('.'),
      disabled,
    });
    return field;
  });
  return fields ?? [];
};
