import * as z from 'zod';

export const FormKey = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  EMAIL: 'email',
};

export const getFormSchema = () => {
  return z.object({
    first_name: z.string().optional(),
    last_name: z.string().optional(),
    email: z.string().email(),
  });
};
