/**
 * @param {string} src
 * @param {string} imageDimension
 */
export const getResizedImageSource = ({ src, imageDimension }) => {
  const extensionPointIndex = src.lastIndexOf('.');
  return `${src.substring(0, extensionPointIndex)}_size${imageDimension}${src.substring(
    extensionPointIndex,
    src.length
  )}`;
};

export const createImageDimension = (width, height) => `${width}x${height}`;

export const standardImageDimensions = {
  smallImage: createImageDimension(50, 50),
};

export const getSmallImageSource = (src) =>
  getResizedImageSource({ src, imageDimension: standardImageDimensions.smallImage });

export const getImageResourceUrl = ({ name, extension, folder }) => {
  const folderPath = folder ? `${folder}/` : '';
  return `/images/${folderPath}${name}.${extension}`;
};

export const ImageExtension = {
  PNG: 'png',
  JPG: 'jpg',
  SVG: 'svg',
  WEBP: 'webp',
};

const TO_RADIANS = Math.PI / 180;

export async function getCroppedImageBlob({
  image,
  crop,
  scale = 1,
  rotation = 0,
  type = 'image/jpeg',
}) {
  if (!crop) {
    crop = {
      unit: 'px',
      width: image.width,
      height: image.height,
      x: 0,
      y: 0,
    };
  }
  /** @type {HTMLCanvasElement} */
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const pixelRatio = window.devicePixelRatio;

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = 'high';

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotationRadians = rotation * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  // Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // Move the origin to the center of the original position
  ctx.translate(centerX, centerY);
  // Rotate around the origin
  ctx.rotate(rotationRadians);
  // Scale the image
  ctx.scale(scale, scale);
  // Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  return new Promise((resolve) => {
    canvas.toBlob((imageBlob) => {
      resolve(imageBlob);
    }, type);
  });
}
