import { postUsersUrl } from '@constants/apiUrls';

import { useAkinonMutation } from '../adapters/useAkinonMutation';
import { client } from '../client';

export function usePostSellerMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody, requestConfig }) =>
      client.post(postUsersUrl, requestBody, requestConfig),
    ...mutationOptions,
  });

  return {
    createSeller: mutate,
    isCreatingNewSeller: isLoading,
  };
}
