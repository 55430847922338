import './styles.scss';

import { Col, List, Row, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import { getCurrentOrderAmountData, getPreOrderAmountData } from './common';

const OrderInfoPanels = ({ orderDetail }) => {
  const { t } = useTranslation('Orders');
  return (
    <div className="order-info-bottom-container">
      <Row>
        <Col>
          <div className="order-info-bottom__list">
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane
                tab={t('order_amount_before_cancel_refund')}
                key="1"
                className="order-info-bottom__list-tabpane"
              >
                {getPreOrderAmountData({ orderDetail, t }).map((amount) => (
                  <List.Item className="box-item" key={amount.label}>
                    <span className="label">{amount.label}</span>
                    <span className="value">
                      {orderDetail?.currency && amount.value
                        ? new Intl.NumberFormat(orderDetail?.language_code, {
                            style: 'currency',
                            currency: orderDetail?.currency,
                          }).format(amount.value)
                        : '-'}
                    </span>
                  </List.Item>
                ))}
              </Tabs.TabPane>
            </Tabs>
          </div>
        </Col>
        <Col className="order-amount-info-box">
          <div className="order-info-bottom__list">
            <Tabs defaultActiveKey="1">
              <Tabs.TabPane
                tab={t('current_order_amount')}
                key="1"
                className="order-info-bottom__list-tabpane"
              >
                {getCurrentOrderAmountData({ orderDetail, t }).map((amount) => (
                  <List.Item className="box-item" key={amount.label}>
                    <span className="label">{amount.label}</span>
                    <span className="value">
                      {orderDetail?.currency && amount.value
                        ? new Intl.NumberFormat(orderDetail?.language_code, {
                            style: 'currency',
                            currency: orderDetail?.currency,
                          }).format(amount.value)
                        : '-'}
                    </span>
                  </List.Item>
                ))}
              </Tabs.TabPane>
            </Tabs>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default OrderInfoPanels;
