import './styles.scss';

import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import AkinonPopover from '@components/AkinonPopover';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { defaultRowKey } from '@constants/index';
import { useIsMobile } from '@hooks/useIsMobile';
import { useOptionsProductPricesQuery } from '@services/hooks/products/useOptionsProductPricesQuery';
import useStore from '@zustand-store/index';
import { setProductsToBeAddedTableFormDataSourceSelector } from '@zustand-store/selectors/preOffer';
import { Space } from 'antd';
import range from 'lodash/range';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDeepCompareEffect } from 'react-use';

import ProductImagesFormModal from '../ProductImagesFormModal';
import {
  getColumns,
  getDataSource,
  isModalVisibleMapInitialState,
  PRODUCT_ATTRIBUTES,
  ProductsToBeAddedTableFormColumnKey,
  shiftVariantListValuesToLeft,
} from './common';
import ActionButtonsFormModalList from './components/ActionButtonsFormModalList';
import AddImagesToSelectedProductsModal from './components/AddImagesToSelectedProductsModal';

/**
 * @param {{ form: import('react-hook-form').UseFormReturn,
 * variants: Object,
 * productFeatures: Object,
 * variantDynamicFields: any[],
 * variantListAttributeFields: any[] }} props
 *
 */
const ProductsToBeAddedTableForm = ({
  form,
  variants,
  productFeatures,
  variantDynamicFields,
  variantListAttributeFields,
}) => {
  const { t } = useTranslation('ProductsAndCategories');
  const isMobile = useIsMobile();
  const { reset, control } = form;
  const [pagination, setPagination] = usePagination();
  const [selectedVariantToShowImages, setSelectedVariantToShowImages] = useState(null);
  const setProductsToBeAddedTableFormDataSource = useStore(
    setProductsToBeAddedTableFormDataSourceSelector
  );

  const formValues = useWatch({
    control,
  });

  const dataSource = useMemo(() => {
    return Object.entries(formValues).reduce((acc, [key, values]) => {
      values?.forEach?.((value, index) => {
        acc[index] = {
          ...acc[index],
          [key]: value,
          id: index,
        };
      });
      return acc;
    }, []);
  }, [formValues]);

  useEffect(() => {
    setProductsToBeAddedTableFormDataSource(dataSource);
  }, [dataSource]);

  const [isAddImagesToSelectedProductsModalVisible, setIsAddImagesToSelectedProductsModalVisible] =
    useState(false);
  const [isModalVisibleMap, setIsModalVisibleMap] = useState(isModalVisibleMapInitialState);
  const { productPricesOptions, isProductPricesOptionsLoading } = useOptionsProductPricesQuery();

  useDeepCompareEffect(() => {
    const productCombinations = getDataSource({ variants, variantDynamicFields });
    const productsToBeAddedTableFormDefaultValues = {};
    productCombinations.forEach((combination) => {
      Object.entries(productFeatures).forEach(([productFeatureKey, productFeatureValue]) => {
        if (!productsToBeAddedTableFormDefaultValues[productFeatureKey]) {
          productsToBeAddedTableFormDefaultValues[productFeatureKey] = range(
            productCombinations.length
          ).fill(productFeatureValue);
        }
      });
      Object.entries(combination).forEach(([variantKey, variantValue]) => {
        if (!productsToBeAddedTableFormDefaultValues[variantKey]) {
          productsToBeAddedTableFormDefaultValues[variantKey] = [];
        }
        productsToBeAddedTableFormDefaultValues[variantKey].push(variantValue);
      });
    });

    reset(productsToBeAddedTableFormDefaultValues);
  }, [productFeatures, variants, variantDynamicFields]);

  const deleteRow = useCallback(
    (rowIndex) => {
      reset(shiftVariantListValuesToLeft({ ...formValues }, rowIndex));
    },
    [reset, formValues]
  );

  const columns = getColumns({
    t,
    variantDynamicFields,
    variantListAttributeFields,
    dataSource,
    form,
    productPricesOptions,
    isProductPricesOptionsLoading,
    isMobile,
    setSelectedVariantToShowImages,
    deleteRow,
  });

  return (
    <Space
      className="products-to-be-added-table-form"
      style={{ width: '100%' }}
      direction="vertical"
      size="large"
    >
      <AkinonForm className="akn-form akn-form-v2" name="productsToBeAddedTableForm">
        <AkinonTable
          tableHeaderExtra={
            <AkinonPopover
              content={
                <Space
                  direction="vertical"
                  align="center"
                  className="products-to-be-added-table-form__bulk-actions-popover"
                >
                  <AkinonButton
                    htmlType="button"
                    type="text"
                    onClick={() => setIsAddImagesToSelectedProductsModalVisible(true)}
                  >
                    {t('add.images.to.selected.products').toUpperCase()}
                  </AkinonButton>
                  <AkinonButton
                    htmlType="button"
                    type="text"
                    onClick={() =>
                      setIsModalVisibleMap({
                        ...isModalVisibleMapInitialState,
                        [ProductsToBeAddedTableFormColumnKey.PRICE]: true,
                      })
                    }
                  >
                    {t('add.bulk.price').toUpperCase()}
                  </AkinonButton>
                  <AkinonButton
                    htmlType="button"
                    type="text"
                    onClick={() =>
                      setIsModalVisibleMap({
                        ...isModalVisibleMapInitialState,
                        [ProductsToBeAddedTableFormColumnKey.STOCK]: true,
                      })
                    }
                  >
                    {t('add.bulk.stock.amount').toUpperCase()}
                  </AkinonButton>
                  <AkinonButton
                    htmlType="button"
                    type="text"
                    onClick={() =>
                      setIsModalVisibleMap({
                        ...isModalVisibleMapInitialState,
                        [PRODUCT_ATTRIBUTES]: true,
                      })
                    }
                  >
                    {t('edit.product.attributes').toUpperCase()}
                  </AkinonButton>
                </Space>
              }
            >
              <AkinonButton>{t('bulk.actions')}</AkinonButton>
            </AkinonPopover>
          }
          className="variants-list"
          title={t('product_summary')}
          description={t('products.to.be.added.table.description')}
          columns={columns}
          rowKey={defaultRowKey}
          dataSource={dataSource}
          pagination={pagination}
          setPagination={setPagination}
          total={dataSource.length}
        />
        <ActionButtonsFormModalList
          isModalVisibleMap={isModalVisibleMap}
          setIsModalVisibleMap={setIsModalVisibleMap}
          dataSource={dataSource}
          productsToBeAddedTableForm={form}
          productPricesOptions={productPricesOptions}
          isProductPricesOptionsLoading={isProductPricesOptionsLoading}
          variantListAttributeFields={variantListAttributeFields}
          variantDynamicFields={variantDynamicFields}
          variants={variants}
        />
        <ProductImagesFormModal
          form={form}
          setSelectedVariantToShowImages={setSelectedVariantToShowImages}
          isProductImagesModalVisible={
            selectedVariantToShowImages !== null &&
            dataSource[selectedVariantToShowImages]?.product_image?.length > 0
          }
          onCancel={() => setSelectedVariantToShowImages(null)}
          productImages={dataSource[selectedVariantToShowImages]?.product_image}
          selectedVariantToShowImages={selectedVariantToShowImages}
          productsToBeAddedTableFormDataSource={dataSource}
        />
        <AddImagesToSelectedProductsModal
          variantDynamicFields={variantDynamicFields}
          variants={variants}
          form={form}
          dataSource={dataSource}
          modalProps={{
            open: isAddImagesToSelectedProductsModalVisible,
            onCancel: () => setIsAddImagesToSelectedProductsModalVisible(false),
          }}
        />
      </AkinonForm>
    </Space>
  );
};

export default memo(ProductsToBeAddedTableForm);
