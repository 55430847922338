import { openNotification } from '@components/akinonNotification';
import { RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@root/hooks/useAppNavigate';
import useStore from '@zustand-store/index';
import { productsToBeAddedTableFormDataSourceSelector } from '@zustand-store/selectors/preOffer';
import first from 'lodash/first';
import get from 'lodash/get';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { BasicProductInfoFormItemKey } from '../components/BasicProductInfoForm/common';
import { DescriptionFormKey } from '../components/DescriptionForm/common';
import { ProductsToBeAddedTableFormFormItemKey } from '../components/ProductsToBeAddedTableForm/common';

/**
 * @param {{ basicProductInfoForm: import('react-hook-form').UseFormReturn,
 * productFeaturesForm: import('react-hook-form').UseFormReturn,
 * productsToBeAddedTableForm: import('react-hook-form').UseFormReturn,
 * descriptionForm: import('react-hook-form').UseFormReturn,
 * priceLists: any[], stockLists: any[], dataSources: any[], attributeSetId: string, postPreOffers: Function }} param0
 *
 * }} param0
 */
export function useOnSubmit({
  basicProductInfoForm,
  productFeaturesForm,
  productsToBeAddedTableForm,
  descriptionForm,
  priceLists,
  stockLists,
  dataSources,
  attributeSetId,
  postPreOffers,
}) {
  const { t } = useTranslation('ProductsAndCategories');
  const navigate = useAppNavigate();
  const productsToBeAddedTableFormDataSource = useStore(
    productsToBeAddedTableFormDataSourceSelector
  );

  const forms = [
    basicProductInfoForm,
    productFeaturesForm,
    productsToBeAddedTableForm,
    descriptionForm,
  ];
  const excludedVariantsListAttributeKeys = [ProductsToBeAddedTableFormFormItemKey.PRODUCT_IMAGE];

  return useCallback(() => {
    Promise.all(forms.map((form) => form?.trigger?.())).then((formsPromiseResult) => {
      const inValidFormIndex = formsPromiseResult.findIndex((result) => !result);
      const areFormsValid = inValidFormIndex === -1;
      if (!areFormsValid) {
        openNotification({
          message: t('transaction_failed'),
          description: t('required_fields_error_message'),
          type: 'error',
        });
        return;
      }
      const pricesKeys = [
        ProductsToBeAddedTableFormFormItemKey.CURRENCY_TYPE,
        ProductsToBeAddedTableFormFormItemKey.PRICE,
        ProductsToBeAddedTableFormFormItemKey.RETAIL_PRICE,
        ProductsToBeAddedTableFormFormItemKey.TAX_RATE,
      ];
      const stocksKeys = [ProductsToBeAddedTableFormFormItemKey.STOCK];
      const priceListMasterId = priceLists?.find?.(({ is_master }) => is_master)?.id;
      const stockListMasterId = stockLists?.find?.(({ is_master }) => is_master)?.id;
      const descriptionFormValues = descriptionForm.getValues();
      const sellerDescription = get(descriptionFormValues, DescriptionFormKey.SELLER_DESCRIPTION);
      const basicProductInfoFormValues = basicProductInfoForm.getValues();
      const baseCode = get(basicProductInfoFormValues, BasicProductInfoFormItemKey.BASE_CODE);
      const commonRequestBody = {
        name: get(basicProductInfoFormValues, BasicProductInfoFormItemKey.NAME),
        base_code: baseCode,
        attribute_set: attributeSetId,
        datasource: first(dataSources)?.id,
        description: sellerDescription,
      };

      const requestBodyList = productsToBeAddedTableFormDataSource.map((preOffer) => ({
        ...commonRequestBody,
        sku: get(preOffer, ProductsToBeAddedTableFormFormItemKey.SKU),
        attributes: {
          ...omit(preOffer, excludedVariantsListAttributeKeys),
          SELLER_BARCODE: get(preOffer, ProductsToBeAddedTableFormFormItemKey.SELLER_BARCODE),
          SELLER_DESCRIPTION: sellerDescription,
          SELLER_BASECODE: baseCode,
        },
        prices: [
          {
            price_list: priceListMasterId,
            ...pick(preOffer, pricesKeys),
          },
        ],
        stocks: [
          {
            stock_list: stockListMasterId,
            ...pick(preOffer, stocksKeys),
          },
        ],
        product: null,
      }));
      const imageFileListSet = productsToBeAddedTableFormDataSource?.map((preOffer) =>
        get(preOffer, ProductsToBeAddedTableFormFormItemKey.PRODUCT_IMAGE, [])?.map(
          ({ originFileObj }) => originFileObj
        )
      );

      postPreOffers(
        {
          requestBodyList,
          imageFileListSet,
        },
        {
          onSuccess() {
            basicProductInfoForm.reset();
            openNotification({
              message: t('transaction_success'),
              description: t('create_pre-offer_success'),
              type: 'success',
            });
            navigate(RouteUrls.productsAndCategories.offerList.pendingApproval);
          },
          onError() {
            openNotification({
              message: t('transaction_failed'),
              description: t('create_pre-offer_error'),
              type: 'error',
            });
          },
        }
      );
    });
  }, [productsToBeAddedTableFormDataSource, forms]);
}
