import AkinonTable from '@components/AkinonTable';
import { RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@root/hooks/useAppNavigate';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getColumns } from './common';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { useDynamicSettingsDataSource } from './hooks/useDynamicSettingsDataSource';
import { usePageMeta } from './hooks/usePageMeta';

const DynamicSettings = () => {
  const { t } = useTranslation('Settings');
  const navigate = useAppNavigate();
  const { dynamicSettingsDataSource, isDynamicSettingsDataSourceLoading, totalDynamicSettings } =
    useDynamicSettingsDataSource();

  useBreadcrumbs();
  usePageMeta();

  const columns = getColumns({ t });

  const onRowClick = ({ key, pk }) => {
    navigate(RouteUrls.settings.dynamicSettingsForm, {
      dynamicSettingName: key,
      dynamicSettingId: pk,
    });
  };

  return (
    <section className="dynamic-settings">
      <AkinonTable
        title={t('dynamic_settings')}
        dataSource={dynamicSettingsDataSource}
        columns={columns}
        total={totalDynamicSettings}
        rowKey="key"
        pagination={false}
        loading={isDynamicSettingsDataSourceLoading}
        description={`${totalDynamicSettings} ${t('results.found')}`}
        onRow={(row) => {
          return {
            onClick: () => onRowClick(row),
          };
        }}
      />
    </section>
  );
};

export default DynamicSettings;
