import { getCityUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { client } from '@services/api/client';
import { useQuery } from '@tanstack/react-query';

export function useCityQuery({ queryOptions, cityId }) {
  const { data, isLoading } = useQuery({
    queryKey: [QueryKey.CITY, cityId],
    queryFn: () => client.get(getCityUrl, { urlParams: { cityId } }),
    ...queryOptions,
  });

  return {
    city: data,
    isCityLoading: isLoading,
  };
}
