import './styles.scss';

import React from 'react';

const AkinonDividerBox = ({ header, children }) => {
  return (
    <div className="akinon-divider-box">
      <div className="akinon-divider-box__header">{header}</div>
      <div className="akinon-divider-box__content">{children}</div>
    </div>
  );
};

export default AkinonDividerBox;
