import './style.scss';

import AddProductsFromCatalogImage from '@assets/images/add-products-from-catalog.jpg';
import AkinonButton from '@components/AkinonButton';
import Intrinsic from '@components/Intrinsic';
import Box from '@components/utility/box';
import { RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { useUserRole } from '@utils/hooks/useUserRole';
import { Col, Form, Image, Input, Row, Typography } from 'antd';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { usePageMeta } from './hooks/usePageMeta';

const { Text } = Typography;

const AddProductsFromCatalog = () => {
  const { t } = useTranslation('ProductsAndCategories');
  const navigate = useAppNavigate();
  const [form] = Form.useForm();
  const userRole = useUserRole();

  useBreadcrumbs();
  usePageMeta();

  const handleSubmit = (formValues) => {
    const { search } = formValues;
    navigate(RouteUrls.productsAndCategories.products.productPool, {
      state: { searchQuery: search },
    });
  };

  return (
    <section className="add-products-from-catalog">
      <Box className="add-products-from-catalog__box" title={t('add.from.marketplace.catalog')}>
        <Row className="add-products-from-catalog__row" gutter={[16, 16]}>
          <Col span={24} sm={12}>
            <div className="add-products-from-catalog__content">
              <Text>
                <Trans i18nKey={t(`add_products_from_catalog_info__for_${userRole}`)} />
              </Text>
              <Form onFinish={handleSubmit} form={form}>
                <Form.Item className="add-products-from-catalog__search" name="search">
                  <Input
                    className="add-products-from-catalog__search-input"
                    placeholder={t('add.products.from.catalog.search.placeholder')}
                    prefix={<Intrinsic className="icon-search" />}
                  />
                </Form.Item>
                <Form.Item>
                  <AkinonButton htmlType="submit" type="primary" block>
                    {t('search').toUpperCase()}
                  </AkinonButton>
                </Form.Item>
              </Form>
            </div>
          </Col>
          <Col span={24} sm={12}>
            <Image
              preview={false}
              src={AddProductsFromCatalogImage}
              alt={t('add.from.marketplace.catalog')}
            />
          </Col>
        </Row>
      </Box>
    </section>
  );
};

export default AddProductsFromCatalog;
