import { standardDateFormat } from '@common/date';
import { WidgetType } from '@constants/commontypes';
import moment from 'moment';

export const DataIndex = {
  START_DATE: 'start_date',
  END_DATE: 'end_date',
  TITLE: 'title',
  AUDIENCES: 'audiences',
};

export const getColumns = ({ t }) => {
  return [
    {
      title: t('startDate'),
      dataIndex: DataIndex.START_DATE,
      render: (startDate, record) => (
        <span>{moment(startDate ?? record?.created_at).format(standardDateFormat)}</span>
      ),
    },
    {
      title: t('endDate'),
      dataIndex: DataIndex.END_DATE,
      render: (endDate, record) => (
        <span>{moment(endDate ?? record?.created_at).format(standardDateFormat)}</span>
      ),
    },
    {
      title: t('title'),
      dataIndex: DataIndex.TITLE,
      render: (title) => <span>{title ?? '-'}</span>,
    },
    {
      title: t('audience'),
      dataIndex: DataIndex.AUDIENCES,
      render: (audiences) => <span>({audiences?.length ?? 0})</span>,
    },
  ];
};

export const StaticFilterKey = {
  START_DATE: 'created_at__gte',
  END_DATE: 'created_at__lte',
};

export const getStaticFilters = ({ t, disabledStartDate, disabledEndDate }) => {
  return {
    staticFiltersFormMetaFields: [
      {
        key: StaticFilterKey.START_DATE,
        widget: WidgetType.DATE_PICKER,
        placeholder: t('startDate'),
        widgetProps: {
          disabledDate: disabledStartDate,
        },
      },
      {
        key: StaticFilterKey.END_DATE,
        widget: WidgetType.DATE_PICKER,
        placeholder: t('endDate'),
        widgetProps: {
          disabledDate: disabledEndDate,
        },
      },
    ],
    isStaticFiltersLoading: false,
  };
};
