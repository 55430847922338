import Link from '@components/Link';
import { RouteUrls } from '@constants/routeUrls';
import useStore from '@zustand-store/index';
import { setBreadcrumbsSelector } from '@zustand-store/selectors/app';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

export function useBreadcrumbs({ dynamicSettingDetail, settingKey }) {
  const { t } = useTranslation('Settings');
  const setBreadcrumbs = useStore(setBreadcrumbsSelector);

  useEffect(() => {
    setBreadcrumbs([
      <Link key={RouteUrls.settings.landing} to={RouteUrls.settings.landing}>
        {t('settings')}
      </Link>,
      settingKey ?? t('delivery_settings'),
    ]);
  }, [t, dynamicSettingDetail]);

  useUnmount(() => {
    setBreadcrumbs([]);
  });
}
