import isString from 'lodash/isString';
import { useTranslation } from 'react-i18next';

export function useStringCaseConverter() {
  const { i18n } = useTranslation();

  function toUpperCase(string) {
    if (!isString(string)) return string;
    return string.toLocaleUpperCase(i18n.language);
  }

  function toLowerCase(string) {
    if (!isString(string)) return string;
    return string.toLocaleLowerCase(i18n.language);
  }

  return {
    toUpperCase,
    toLowerCase,
  };
}
