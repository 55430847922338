import './styles.scss';

import AkinonFormItem from '@components/AkinonFormItem';
import MobileSelectDropdown from '@components/AkinonSelect/components/MobileSelectDropdown';
import { useIsMobile } from '@hooks/useIsMobile';
import { TreeSelect } from 'antd';
import clsx from 'clsx';
import omit from 'lodash/omit';
import React, { forwardRef, useState } from 'react';

/**
 * @param {import('antd').SelectProps} props
 */
const AkinonTreeSelect = forwardRef(({ formItemProps, ...props }, ref) => {
  const [value, setValue] = useState(props.value);
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useIsMobile();
  const [isFadingOut, setIsFadingOut] = useState(false);

  const onClear = () => {
    //? workaround for antd bug, onChange sometimes does not trigger when the value is undefined.
    props.onChange(false);
    setTimeout(() => {
      props.onChange(undefined);
    }, 0);
    onClose();
  };

  const onApply = () => {
    onClose();
    props.onChange(value);
  };

  const onClose = () => {
    setIsFadingOut(true);
    setTimeout(() => {
      setIsOpen(false);
      setIsFadingOut(false);
    }, 300);
  };

  const mobileProps = isMobile
    ? {
        showSearch: false, // <- very annoying on a real device
        onClick: (e) => {
          // this is more reliable then onFocus
          const selectParentsClasses = [
            'ant-select-selection-item',
            'ant-select-selection-search-input',
          ];
          const isSelectParent = selectParentsClasses.some((className) =>
            e.target?.className.includes?.(className)
          );
          if (isSelectParent) {
            setIsOpen(true);
          }
        },
        onChange: props.readOnly ? undefined : (val) => setValue(val),
        open: isOpen,
      }
    : {};

  const component = (
    <TreeSelect
      className={clsx('akinon-tree-select', props.className)}
      ref={ref}
      dropdownRender={
        isMobile
          ? (menu) => (
              <MobileSelectDropdown
                onClear={onClear}
                title={props?.placeholder}
                menu={menu}
                onApply={onApply}
                onClose={onClose}
                isFadingOut={isFadingOut}
              />
            )
          : null
      }
      {...omit(props, ['className', 'suffixIcon'])}
      {...mobileProps}
    />
  );

  if (formItemProps) {
    return <AkinonFormItem {...formItemProps}>{component}</AkinonFormItem>;
  }
  return component;
});

AkinonTreeSelect.displayName = 'AkinonTreeSelect';
export default AkinonTreeSelect;
