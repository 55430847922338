export const Color = {
  AFFAIR: '#72467b',
  WAIKAWA_GRAY: '#5969a5',
  SLATE_GRAY: '#788195',
  TEXAS_ROSE: '#ffa154',
  SHIP_COVE: '#6c7ebe',
  MULLED_WINE: '#534474',
  DODGER_BLUE: '#4482ff',
  PICKLED_BLUEWOOD: '#2b344c',
  LIMA: '#7ed321',
  CRIMSON: '#cf1322',
  SUN: '#faad14',
  WILD_WATERMELON: '#ff5163',
};
