import useStore from '@zustand-store/index';
import { setPageMetaSelector } from '@zustand-store/selectors/app';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

export function usePageMeta() {
  const { t } = useTranslation('Reporting');
  const setPageMeta = useStore(setPageMetaSelector);

  useEffect(() => {
    setPageMeta({
      title: t('seller.performance.dashboard'),
      description: t('seller.performance.dashboard.desc'),
      tutorials: [
        {
          title: t('sellerPerformance.tutorial.customerMetricsTitle'),
          description: t('sellerPerformance.tutorial.customerMetricsDescription'),
        },
        {
          title: t('sellerPerformance.tutorial.sellerReviewsTitle'),
          description: t('sellerPerformance.tutorial.sellerReviewsDescription'),
        },
        {
          title: t('sellerPerformance.tutorial.productReviewsTitle'),
          description: t('sellerPerformance.tutorial.productReviewsDescription'),
        },
        {
          title: t('sellerPerformance.tutorial.customerComplaintRateTitle'),
          description: t('sellerPerformance.tutorial.customerComplaintRateDescription'),
        },
        {
          title: t('sellerPerformance.tutorial.questionReturnTimeTitle'),
          description: t('sellerPerformance.tutorial.questionReturnTimeDescription'),
        },
        {
          title: t('sellerPerformance.tutorial.returnActionTimeTitle'),
          description: t('sellerPerformance.tutorial.returnActionTimeDescription'),
        },
        {
          title: t('sellerPerformance.tutorial.operationalMetricsTitle'),
          description: t('sellerPerformance.tutorial.operationalMetricsDescription'),
        },
        {
          title: t('sellerPerformance.tutorial.failureToSupplyTitle'),
          description: t('sellerPerformance.tutorial.failureToSupplyDescription'),
        },
        {
          title: t('sellerPerformance.tutorial.deliveryTimeToCargoTitle'),
          description: t('sellerPerformance.tutorial.deliveryTimeToCargoDescription'),
        },
        {
          title: t('sellerPerformance.tutorial.onTimeDeliveryRateToCargoTitle'),
          description: t('sellerPerformance.tutorial.onTimeDeliveryRateToCargoDescription'),
        },
        {
          title: t('sellerPerformance.tutorial.disputedReturnRateTitle'),
          description: t('sellerPerformance.tutorial.disputedReturnRateDescription'),
        },
      ],
    });
  }, [t]);

  useUnmount(() => {
    setPageMeta({});
  });
}
