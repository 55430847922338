import { submitPreOfferURL } from '@constants/apiUrls';
import { client } from '@services/api/client';

const useSubmitOffers = ({ refetchOffers }) => {
  const submitSelectedItems = async ({ selectedRows, mutationOptions }) => {
    const { onSuccess } = mutationOptions;

    const submitPreOfferPromises = selectedRows.map((row) => {
      return () =>
        client.post(submitPreOfferURL, {}, { urlParams: { id: row.id } }).then((result) => {
          onSuccess(result);
        });
    });

    await Promise.allSettled(submitPreOfferPromises.map((f) => f()));
    refetchOffers();
  };

  return { submitSelectedItems };
};

export default useSubmitOffers;
