import {
  dynamicSettingsDetailURL,
  dynamicSettingsRegistryURL,
  dynamicSettingsURL,
} from '@constants/apiUrls';
import { client } from '@services/api/client';

export const getDynamicSettings = (params) => {
  return client.get(dynamicSettingsURL, { params });
};

export const getDynamicSettingsRegistry = (params) => {
  return client.get(dynamicSettingsRegistryURL, { params });
};

export const getDynamicSetting = ({ dynamicSettingId }) => {
  return client.get(dynamicSettingsDetailURL, { urlParams: { dynamicSettingId } });
};

export const createDynamicSetting = ({ data, requestConfig }) => {
  return client.post(dynamicSettingsURL, data, requestConfig);
};

export const deleteDynamicSetting = ({ dynamicSettingId }) => {
  return client.delete(dynamicSettingsDetailURL, { urlParams: { dynamicSettingId } });
};

export const updateDynamicSetting = ({ dynamicSettingId, data, requestConfig }) => {
  return client.patch(dynamicSettingsDetailURL, data, {
    ...requestConfig,
    urlParams: { dynamicSettingId },
  });
};
