import { findElementByInnerText, getTourSelectorWithFallback } from '@common/index';
import AkinonButton from '@components/AkinonButton';
import ImageTableHead from '@components/ImageTableHead';
import SmallColumnImage from '@components/SmallColumnImage';
import TitleWithHint from '@components/TitleWithHint';
import { WidgetType } from '@constants/commontypes';
import i18n from '@root/i18n';
import { Tooltip, Typography } from 'antd';
import clsx from 'clsx';
import React, { Fragment } from 'react';

const { Text, Title } = Typography;

const DataIndex = {
  IMAGE: ['image', 'image'],
  PRODUCT: {
    NAME: ['product', 'name'],
    BASE_CODE: ['product', 'base_code'],
    SKU: ['product', 'sku'],
  },
  SKU: 'offer_sku',
  CURRENCY_TYPE: 'currency_type',
  GRADE: 'grade',
  GRADE_VERSION: 'grade_version',
  RETAIL_PRICE: 'retail_price',
  PRICE: 'price',
  REFERENCE_PRICE: 'buybox_price',
  LOWEST_PRICE: 'lowest_price',
  TAX_RATE: 'tax_rate',
  DATASOURCE: ['datasource', 'name'],
  IS_ACTIVE: 'is_active',
};

export const StaticFilterKey = {
  NAME: 'offer__product__name__icontains',
  OFFER_SKU: 'offer__sku__contains',
  PRODUCT_SKU: 'offer__product__sku',
  BASE_CODE: 'offer__product__base_code',
  IS_ACTIVE: 'is_active',
};

export const getColumns = ({
  t,
  isSuperUser,
  editingKey,
  isEditing,
  handleEditRow,
  handleCancelEditing,
  handleSaveRow,
  isMobile,
}) =>
  [
    {
      dataIndex: DataIndex.IMAGE,
      title: <ImageTableHead />,
      render: (image) => <SmallColumnImage src={image} />,
      align: 'center',
    },
    {
      dataIndex: DataIndex.PRODUCT.NAME,
      visibleOnFilter: [StaticFilterKey.NAME],
      title: t('product_name'),
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      dataIndex: DataIndex.PRODUCT.BASE_CODE,
      title: t('basecode'),
      render: (baseCode) => <Tooltip title={baseCode}>{baseCode?.toUpperCase()}</Tooltip>,
    },
    {
      dataIndex: DataIndex.SKU,
      visibleOnFilter: [StaticFilterKey.OFFER_SKU],
      title: t('offer_code'),
      render: (offerCode) => <Tooltip title={offerCode}>{offerCode}</Tooltip>,
    },
    {
      dataIndex: DataIndex.PRODUCT.SKU,
      visibleOnFilter: [StaticFilterKey.PRODUCT_SKU],
      title: t('sku'),
      render: (productSku) => <Tooltip title={productSku}>{productSku}</Tooltip>,
    },
    {
      dataIndex: DataIndex.CURRENCY_TYPE,
      title: t('currency.type'),
    },
    isSuperUser && {
      dataIndex: DataIndex.GRADE,
      title: t('grade'),
    },
    isSuperUser && {
      dataIndex: DataIndex.GRADE_VERSION,
      title: t('grade.version'),
    },
    {
      dataIndex: DataIndex.RETAIL_PRICE,
      title: t('psf'),
      editable: true,
      inputProps: {
        className: 'editable-cell-input',
        type: 'number',
        min: 0,
        step: '0.01',
      },
    },
    {
      dataIndex: DataIndex.PRICE,
      title: t('selling.price'),
      editable: true,
      inputProps: {
        className: 'editable-cell-input',
        type: 'number',
        min: 0,
        step: '0.01',
      },
    },
    {
      dataIndex: DataIndex.REFERENCE_PRICE,
      className: 'price-list-detail__reference-price_column',
      title: (
        <TitleWithHint
          text={t('reference_price').toLocaleUpperCase(i18n.language)}
          hint={t('reference_price_desc')}
        />
      ),
    },
    {
      dataIndex: DataIndex.LOWEST_PRICE,
      className: 'price-list-detail__lowest-price_column',
      title: (
        <TitleWithHint
          text={t('lowest_price').toLocaleUpperCase(i18n.language)}
          hint={t('lowest_price_desc')}
        />
      ),
    },
    {
      dataIndex: DataIndex.TAX_RATE,
      title: t('VAT'),
      editable: true,
      inputProps: {
        className: 'editable-cell-input',
        type: 'number',
        min: 0,
        step: '0.01',
      },
    },
    isSuperUser && {
      dataIndex: DataIndex.DATASOURCE,
      title: t('seller'),
      className: 'col-type-product-name',
      render: (name) => <Tooltip title={name}>{name}</Tooltip>,
    },
    {
      dataIndex: DataIndex.IS_ACTIVE,
      visibleOnFilter: [StaticFilterKey.IS_ACTIVE],
      title: t('status'),
      fixed: isMobile ? false : 'right',
      className: 'price-list-detail__status_column',
      render: (status) => {
        return (
          <div
            className={clsx('offer-status', {
              'offer-approved': status,
              'offer-rejected': !status,
            })}
          >
            {status ? t('active') : t('passive')}
          </div>
        );
      },
    },
    {
      title: t('save'),
      dataIndex: t('save'),
      fixed: isMobile ? false : 'right',
      align: 'center',
      render: (_, record, index) => {
        const editable = isEditing(record);
        const isFirstRow = index === 0;
        return editable ? (
          <span className="price-list-detail__actions">
            <AkinonButton
              className="price-list-detail__actions__button"
              onClick={() => handleSaveRow(record)}
            >
              {t('save')}
            </AkinonButton>
            <AkinonButton
              className="price-list-detail__actions__button"
              type="link"
              onClick={handleCancelEditing}
            >
              <Text underline type="danger">
                {t('cancel')}
              </Text>
            </AkinonButton>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ''}
            onClick={() => handleEditRow(record)}
            className={clsx('price-list-detail__edit-button', {
              'price-list-detail__edit-button--first-row': isFirstRow,
            })}
          >
            {t('edit')}
          </Typography.Link>
        );
      },
    },
  ]
    .filter(Boolean)
    .map((col) => {
      if (!col?.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          inputProps: col.inputProps,
          editing: isEditing(record),
          rules: [
            {
              required: true,
              message: t('validation.message'),
            },
          ],
        }),
      };
    });

export const getStaticFilters = ({ t }) => ({
  staticFiltersFormMetaFields: [
    {
      key: StaticFilterKey.NAME,
      widget: WidgetType.INPUT,
      placeholder: t('product_name'),
    },
    {
      key: StaticFilterKey.OFFER_SKU,
      widget: WidgetType.INPUT,
      placeholder: t('offer_code'),
    },
    {
      key: StaticFilterKey.PRODUCT_SKU,
      widget: WidgetType.INPUT,
      placeholder: t('sku'),
    },
    {
      key: StaticFilterKey.BASE_CODE,
      widget: WidgetType.INPUT,
      placeholder: t('basecode'),
    },
    {
      key: StaticFilterKey.IS_ACTIVE,
      widget: WidgetType.SELECT,
      widgetProps: {
        showSearch: true,
        optionFilterProp: 'label',
      },
      placeholder: t('status'),
      options: [
        { label: t('active'), value: true },
        { label: t('passive'), value: false },
      ],
    },
  ],
  isStaticFiltersLoading: false,
});

export const getTourSteps = ({ t }) => {
  return [
    {
      selector: '.app-layout__page-meta',
      content: (
        <Fragment>
          <Title level={4}>{t('price_detail_tutorial.page_title')}</Title>
          <Text>{t('price_detail_tutorial.page')}</Text>
        </Fragment>
      ),
    },
    {
      selector: '.price-list-detail__bulk-update-price-info-button',
      content: (
        <Fragment>
          <Title level={4}>{t('price_detail_tutorial.bulk_price_update_title')}</Title>
          <Text>{t('price_detail_tutorial.bulk_price_update')}</Text>
        </Fragment>
      ),
    },
    {
      selector: '.filter-options__export-item',
      content: (
        <Fragment>
          <Title level={4}>{t('price_detail_tutorial.xlsx_filter_title')}</Title>
          <Text>{t('price_detail_tutorial.xlsx_filter')}</Text>
        </Fragment>
      ),
    },
    {
      selector: '.price-list-detail__export-options',
      content: (
        <Fragment>
          <Title level={4}>{t('price_detail_tutorial.select_an_export_option_title')}</Title>
          <Text>{t('price_detail_tutorial.select_an_export_option')}</Text>
        </Fragment>
      ),
    },
    {
      selector: getTourSelectorWithFallback(
        ['.price-list-detail__edit-button--first-row'],
        findElementByInnerText(t('save').toLocaleUpperCase(i18n.language), 'th')
      ),
      content: (
        <Fragment>
          <Title level={4}>{t('price_detail_tutorial.edit_price_amount_title')}</Title>
          <Text>{t('price_detail_tutorial.edit_price_amount')}</Text>
        </Fragment>
      ),
    },
  ];
};

export const ExportOption = {
  EXPORT_PRICE_LIST_TEMPLATE: 'EXPORT_PRICE_LIST_TEMPLATE',
  EXPORT_PRICE_LIST: 'EXPORT_PRICE_LIST',
};
