import { getCitiesUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { client } from '@services/api/client';

import { useAkinonQuery } from '../adapters/useAkinonQuery';

export function useCitiesQuery({ queryOptions } = {}) {
  const { data, isLoading } = useAkinonQuery({
    queryKey: [QueryKey.CITIES],
    queryFn: () => client.get(getCitiesUrl),
    ...queryOptions,
  });

  return {
    cities: data?.results,
    isCitiesLoading: isLoading,
  };
}
