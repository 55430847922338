import './styles.scss';

import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { getIntegrationErrorsUrl } from '@constants/apiUrls';
import { defaultRowKey } from '@constants/index';
import { useIsMobile } from '@hooks/useIsMobile';
import { useResolveIntegrationErrorMutation } from '@services/api/hooks/useResolveIntegrationErrorMutation';
import { useTranslation } from 'react-i18next';

import { getColumns } from './common';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { useDataSource } from './hooks/useDataSource';
import { usePageMeta } from './hooks/usePageMeta';

export default function IntegrationErrorsList() {
  const { t } = useTranslation('Settings');
  const isMobile = useIsMobile();
  const [pagination, setPagination] = usePagination();
  const { dataSource, total, loading } = useDataSource({ pagination });
  const { resolveIntegrationError } = useResolveIntegrationErrorMutation();

  useBreadcrumbs();
  usePageMeta();

  const columns = getColumns({ t, resolveIntegrationError, isMobile });

  return (
    <section className="integration-errors">
      <AkinonTable
        dataSource={dataSource}
        optionsUrl={getIntegrationErrorsUrl}
        loading={loading}
        total={total}
        columns={columns}
        pagination={pagination}
        setPagination={setPagination}
        rowKey={defaultRowKey}
        description={t('n_results_found', { count: total })}
        title={t('integration_errors')}
      />
    </section>
  );
}
