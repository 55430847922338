import {
  getPackageCargoLabelUrl,
  getPackageItemsUrl,
  getPackagesIdUrl,
  listPackagesUrl,
  postPackageGenerateShipmentUrl,
} from '@constants/apiUrls';
import { client } from '@services/api/client';

/**
 * @param {{ requestConfig: import('axios').AxiosRequestConfig }} args
 */
export const getPackages = ({ requestConfig }) => {
  return client.get(listPackagesUrl, requestConfig);
};

export const getPackageDetail = (packageId) => {
  return client.get(getPackagesIdUrl, { urlParams: { packageId } });
};

export const patchPackage = (url, { params = {} }, requestConfig) => {
  return client.patch(url, params, requestConfig);
};

export const getPackageStatuses = async (url) => {
  const response = await client.options(url);
  return response?.actions;
};

export const postPackageReCreate = (url, params = {}) => {
  return client.post(url, { ...params });
};

export const getPackageItems = (params = {}) => {
  return client.get(getPackageItemsUrl, { params });
};

export const getPackageCargoLabel = (params = {}) => {
  const { packageId } = params;
  return client.get(getPackageCargoLabelUrl, { urlParams: { packageId } });
};

export const postPackageGenerateShipment = (packageId, requestBody = {}) => {
  return client.post(postPackageGenerateShipmentUrl, requestBody, {
    urlParams: { packageId },
  });
};
