import { QueryKey } from '@constants/query';
import { optionsProductPrices } from '@services/api';
import { useQuery } from '@tanstack/react-query';

export function useOptionsProductPricesQuery() {
  const queryResponse = useQuery({
    queryKey: [QueryKey.PRODUCT_PRICES_OPTIONS],
    queryFn: () => optionsProductPrices(),
  });

  return {
    ...queryResponse,
    productPricesOptions: queryResponse.data,
    isProductPricesOptionsLoading: queryResponse.isLoading,
  };
}
