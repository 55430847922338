import './styles.scss';

import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { getFormRuleMap } from '@common/form';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { UserRole } from '@constants/auth';
import { defaultRowKey } from '@constants/index';
import { useIsMobile } from '@hooks/useIsMobile';
import { useProductImagesQuery } from '@services/api/hooks/usePaginatedProductImagesQuery';
import { useOptionsProductPricesQuery } from '@services/hooks/products/useOptionsProductPricesQuery';
import { useUserRole } from '@utils/hooks/useUserRole';
import useStore from '@zustand-store/index';
import {
  productsToBeAddedTableFormDataSourceSelector,
  setProductsToBeAddedTableFormDataSourceSelector,
  variantsFormDynamicMetaFieldsSelector,
  variantsListAttributeMetaFieldsSelector,
} from '@zustand-store/selectors/preOffer';
import { Carousel, Form, Image, Modal, Space } from 'antd';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useQueriesContext } from '../../contexts/Queries';
import { useQueries } from '../../hooks/api/useQueries';
import {
  getColumns,
  getDataSource,
  getTableActions,
  isModalVisibleMapInitialState,
  ProductsToBeAddedTableFormColumnKey,
} from './common';
import ActionButtonsFormModalList from './components/ActionButtonsFormModalList';

const ProductsToBeAddedTableForm = ({ form, onShowProductRevisionModal }) => {
  const { t } = useTranslation('ProductsAndCategories');
  const isMobile = useIsMobile();
  const [isProductImagesModalVisible, setIsProductImagesModalVisible] = useState(false);
  const [pagination, setPagination] = usePagination();
  const { buyboxPrice } = useQueries();
  const { productMeta } = useQueriesContext();
  const productsToBeAddedTableFormDataSource = useStore(
    productsToBeAddedTableFormDataSourceSelector
  );
  const setProductsToBeAddedTableFormDataSource = useStore(
    setProductsToBeAddedTableFormDataSourceSelector
  );
  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;
  const { productImages, isProductImagesLoading } = useProductImagesQuery({
    params: {
      limit: productsToBeAddedTableFormDataSource?.length,
      parent__in: productsToBeAddedTableFormDataSource
        ?.map((product) => product.id)
        .filter(Boolean),
    },
    queryOptions: { enabled: !isEmpty(productsToBeAddedTableFormDataSource) },
  });
  const [isModalVisibleMap, setIsModalVisibleMap] = useState(isModalVisibleMapInitialState);
  const variantsFormDynamicMetaFields = useStore(variantsFormDynamicMetaFieldsSelector);
  const variantsListAttributeMetaFields = useStore(variantsListAttributeMetaFieldsSelector);
  const { productPricesOptions, isProductPricesOptionsLoading } = useOptionsProductPricesQuery();

  useEffect(() => {
    if (productMeta) {
      setProductsToBeAddedTableFormDataSource(getDataSource({ productMeta }));
    }
  }, [productMeta]);

  const dataSourceWithImages = productsToBeAddedTableFormDataSource?.map((item) => ({
    ...item,
    [ProductsToBeAddedTableFormColumnKey.PRODUCT_IMAGE]: productImages?.find(
      (productImage) => productImage.parent === item.id
    )?.image,
  }));
  const tableActions = getTableActions({
    t,
    setIsModalVisibleMap,
    dataSource: productsToBeAddedTableFormDataSource,
    onShowProductRevisionModal,
  });
  const formRuleMap = getFormRuleMap({ t });
  const columns = getColumns({
    t,
    variantsFormDynamicMetaFields,
    variantsListAttributeMetaFields,
    setDataSource: setProductsToBeAddedTableFormDataSource,
    dataSource: productsToBeAddedTableFormDataSource,
    form,
    productPricesOptions,
    buyboxPrice,
    isProductPricesOptionsLoading,
    setIsProductImagesModalVisible,
    formRuleMap,
    isMobile,
    isSuperUser,
  });
  const isTableLoading = isProductImagesLoading || isEmpty(productMeta);

  const onImageModalCancel = () => {
    setIsProductImagesModalVisible(false);
  };

  return (
    <Space
      className="products-to-be-added-table-form"
      style={{ width: '100%' }}
      direction="vertical"
      size="large"
    >
      <Form className="akn-form akn-form-v2" form={form}>
        <AkinonTable
          className="variants-list"
          title={t('product_summary')}
          description={t('products.to.be.added.table.description')}
          columns={columns}
          rowKey={defaultRowKey}
          dataSource={dataSourceWithImages}
          tableActions={isSuperUser ? [] : tableActions}
          pagination={pagination}
          setPagination={setPagination}
          total={productsToBeAddedTableFormDataSource.length}
          loading={isTableLoading}
        />
        <ActionButtonsFormModalList
          isModalVisibleMap={isModalVisibleMap}
          setIsModalVisibleMap={setIsModalVisibleMap}
          dataSource={productsToBeAddedTableFormDataSource}
          productsToBeAddedTableForm={form}
          productPricesOptions={productPricesOptions}
          isProductPricesOptionsLoading={isProductPricesOptionsLoading}
        />
      </Form>
      <Modal
        className="products-to-be-added-table-form__product-images-modal"
        footer={null}
        width={800}
        onCancel={onImageModalCancel}
        open={isProductImagesModalVisible}
        title={t('product.images.title')}
      >
        <Carousel
          arrows
          prevArrow={<LeftOutlined color="white" />}
          nextArrow={<RightOutlined color="white" />}
          slidesToShow={4}
          dots={null}
          infinite={productImages?.length > 4}
        >
          {productImages?.map((productImage) => (
            <Space
              className="products-to-be-added-table-form__product-image-container"
              align="center"
              direction="vertical"
              key={productImage.id}
            >
              <Image
                className="products-to-be-added-table-form__product-image"
                src={productImage.image}
              />
            </Space>
          ))}
        </Carousel>
      </Modal>
    </Space>
  );
};

export default ProductsToBeAddedTableForm;
