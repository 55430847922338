import { QueryKey } from '@constants/query';
import { getAttributeSets } from '@services/api';
import { useQuery } from '@tanstack/react-query';

/**
 * @param {{ queryOptions: import('@tanstack/react-query').UseQueryOptions, requestConfig: import('axios').AxiosRequestConfig }} args
 */
export function useAttributeSetsQuery({ queryOptions, requestConfig } = {}) {
  const { data, isLoading } = useQuery({
    queryKey: [QueryKey.ATTRIBUTE_SETS, requestConfig],
    queryFn: () => getAttributeSets({ requestConfig }),
    ...queryOptions,
  });

  return {
    attributeSets: data?.results,
    isAttributeSetsLoading: isLoading,
  };
}
