import './styles.scss';

import AkinonFormBuilder from '@components/AkinonFormBuilder';
import { Checkbox, Divider, Typography } from 'antd';
import clsx from 'clsx';
import React from 'react';

const { Text } = Typography;

const FormModalItem = ({
  formMeta,
  form,
  order,
  isProductSelectedList,
  setIsProductSelectedList,
  variantsPairs,
  commonFormItemWidgetProps,
  isAttributeForm,
}) => {
  const handleCheckboxChange = (e) => {
    const newIsProductSelectedList = [...isProductSelectedList];
    newIsProductSelectedList[order] = e.target.checked;
    setIsProductSelectedList(newIsProductSelectedList);
  };

  const derivedMeta = {
    fields: formMeta.map((metaItem) => ({
      ...metaItem,
      key: `${metaItem.key}.${order}`,
      className: 'form-modal-item__form-builder',
      widgetProps: {
        ...metaItem.widgetProps,
        ...commonFormItemWidgetProps,
      },
      label: isAttributeForm ? metaItem.label : null,
    })),
  };

  return (
    <div className="form-modal-item">
      <div className="form-modal-item__wrapper">
        <Checkbox className="form-modal-item__checkbox" onChange={handleCheckboxChange} />
        <Text
          className={clsx('form-modal-item__variant', {
            'singular-form-modal-item__variant': formMeta.length === 1,
          })}
        >
          {variantsPairs[order].join('/')}
        </Text>
        <AkinonFormBuilder form={form} meta={derivedMeta} />
      </div>
      <Divider className="form-modal-item__divider" />
    </div>
  );
};

export default FormModalItem;
