import { getFulfillmentTimeUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { useAkinonQuery } from '../adapters/useAkinonQuery';
import { client } from '../client';

export function useFulfillmentTimeQuery({ params }) {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.FULFILLMENT_TIME, params],
    queryFn: () => client.get(getFulfillmentTimeUrl, { params }),
  });

  return {
    fulfillmentTime: data?.fulfillment_time,
    isFetchingFulfillmentTime: isFetching,
  };
}
