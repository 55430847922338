import { Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import CurrentAttributes from './CurrentAttributes';
import RequestedAttributes from './RequestedAttributes';

const RevisionDetailModal = ({
  title,
  isVisible,
  onCancel,
  revisionDetailModalInfo,
  attributes,
}) => {
  const { t } = useTranslation('ProductsAndCategories');
  if (!revisionDetailModalInfo?.hasLongItems) return null;

  return (
    <div>
      <Modal open={isVisible} title={title} onCancel={onCancel} centered footer={null} width={800}>
        <div className="revision-detail-modal">
          <div className="revision-detail-modal__column">
            <div className="revision-detail-modal__column-title">
              {t('current_product_attributes')}
            </div>
            <hr />
            <CurrentAttributes
              attributes={attributes}
              currentAttributes={revisionDetailModalInfo.currentAttributes}
              record={revisionDetailModalInfo.record}
            />
          </div>
          <div className="revision-detail-modal__column">
            <div className="revision-detail-modal__column-title">
              {t('requested_product_attributes')}
            </div>
            <hr />
            <RequestedAttributes
              attributes={attributes}
              requestedAttributes={revisionDetailModalInfo.requestedAttributes}
              record={revisionDetailModalInfo.record}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RevisionDetailModal;
