import { submitAllPreOffers } from '@services/api';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';

// NOSONAR
export function useSubmitAllPreOffersMutation({ mutationOptions = {} }) {
  const mutationResponse = useAkinonMutation({
    mutationFn: submitAllPreOffers,
    ...mutationOptions,
  });

  return {
    ...mutationResponse,
    submitAllPreOffers: mutationResponse.mutate,
    isSubmitPreOffersLoading: mutationResponse.isLoading,
  };
}
