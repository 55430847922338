import './styles.scss';

import AkinonFormItem from '@components/AkinonFormItem';
import { useIsMobile } from '@hooks/useIsMobile';
import { Select } from 'antd';
import clsx from 'clsx';
import omit from 'lodash/omit';
import React, { forwardRef, useState } from 'react';

import MobileSelectDropdown from './components/MobileSelectDropdown';
/**
 * @param {import('antd').SelectProps} props
 */
const AkinonSelect = forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value);
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useIsMobile();
  const [isFadingOut, setIsFadingOut] = useState(false);

  const onClear = () => {
    //? workaround for antd bug, onChange sometimes does not trigger when the value is undefined.
    props?.onChange?.(false);
    setTimeout(() => {
      props?.onChange?.(undefined);
    }, 0);
    onClose();
  };

  const onApply = () => {
    onClose();
    props?.onChange?.(value);
  };

  const onClose = () => {
    setIsFadingOut(true);
    setTimeout(() => {
      setIsOpen(false);
      setIsFadingOut(false);
    }, 300);
  };

  const mobileProps = isMobile
    ? {
        showSearch: false, // <- very annoying on a real device
        onClick: (e) => {
          // this is more reliable then onFocus
          const selectParentsClasses = [
            'ant-select-selection-item',
            'ant-select-selection-search-input',
          ];
          const isSelectParent = selectParentsClasses.some((className) =>
            e.target?.className.includes?.(className)
          );
          if (isSelectParent) {
            setIsOpen(true);
          }
        },
        onChange: (val) => setValue(val),
        open: isOpen,
      }
    : {};

  const component = (
    <Select
      className={clsx('akinon-select', props?.className)}
      aria-label={props?.['aria-label'] ?? props?.label ?? props?.formItemProps?.label ?? props?.id}
      ref={ref}
      dropdownRender={
        isMobile
          ? (menu) => (
              <MobileSelectDropdown
                onClear={onClear}
                title={props?.placeholder}
                menu={menu}
                onApply={onApply}
                onClose={onClose}
                isFadingOut={isFadingOut}
              />
            )
          : null
      }
      {...omit(props, ['className', 'suffixIcon', 'aria-label'])}
      {...mobileProps}
    />
  );

  if (props?.formItemProps) {
    return <AkinonFormItem {...props.formItemProps}>{component}</AkinonFormItem>;
  }

  return component;
});

AkinonSelect.displayName = 'AkinonSelect';
export default AkinonSelect;
