import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { getUsersUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { getUsers } from '@services/api';
import { client } from '@services/api/client';
import { useQuery } from '@tanstack/react-query';

/**
 * @typedef {import('@tanstack/react-query').UseQueryResult} UseQueryResult
 * @returns {import('@tanstack/react-query').UseQueryResult}
 */
export const useUsers = ({ pagination, filters, enabled = true }) => {
  const queryKey = ['users', pagination, filters];
  const queryFn = () =>
    getUsers({
      ...getRequestPaginationParams(pagination),
      ...getRequestFiltersParams(filters),
    });
  const { data, isLoading, error } = useQuery({ queryKey, queryFn, enabled });

  return {
    data: data?.results,
    total: data?.count,
    isLoading,
    error,
  };
};

export function usePaginatedUsersQuery({ queryOptions, filters, pagination, params, onSuccess }) {
  const { data, isFetching, error } = useQuery({
    queryKey: [QueryKey.USERS, filters, pagination, params],
    async queryFn() {
      const response = await client.get(getUsersUrl, {
        params: {
          ...getRequestPaginationParams(pagination),
          ...getRequestFiltersParams(filters),
          ...params,
        },
      });

      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    userResponse: data,
    users: data?.results,
    isFetchingUsers: isFetching,
    totalUsers: data?.count,
    usersError: error,
  };
}
