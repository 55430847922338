import * as z from 'zod';

export const FormMetaKey = {
  PASSWORD: 'password',
  CONFIRM: 'confirm',
};

export const getFormSchema = ({ t }) => {
  return z
    .object({
      [FormMetaKey.PASSWORD]: z.string({ required_error: t('this_field_is_required') }).min(1),
      [FormMetaKey.CONFIRM]: z.string({ required_error: t('this_field_is_required') }).min(1),
    })
    .refine(({ confirm, password }) => confirm === password, {
      message: t('repeated.pwd.validation.message'),
      path: [FormMetaKey.CONFIRM],
    });
};
