import './styles.scss';

import AkinonFormItem from '@components/AkinonFormItem';
import { useIsMobile } from '@hooks/useIsMobile';
import { DatePicker } from 'antd';
import omit from 'lodash/omit';
import React, { useState } from 'react';

import MobileDatePicker from './components/MobileDatePicker';

/**
 * @param {import('antd').DatePickerProps} props
 */
const AkinonDatePicker = (props) => {
  const [value, setValue] = useState(props.value);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useIsMobile();

  const onClear = () => {
    //? workaround for antd bug, onChange sometimes does not trigger when the value is undefined.
    props?.onChange?.(false);
    setTimeout(() => {
      props?.onChange?.(undefined);
    }, 0);
    onClose();
  };

  const onApply = () => {
    onClose();
    props?.onChange?.(value);
  };

  const onClose = () => {
    setIsFadingOut(true);
    setTimeout(() => {
      setIsOpen(false);
      setIsFadingOut(false);
    }, 300);
  };

  const mobileProps = isMobile
    ? {
        onClick: (e) => {
          if (e.target.tagName === 'INPUT') {
            setIsOpen(true);
          }
        },
        onChange: (val) => setValue(val),
        open: isOpen,
      }
    : {};

  const component = (
    <DatePicker
      aria-label={props?.['aria-label'] ?? props?.name}
      panelRender={(originPanel) => {
        if (!isMobile) {
          return originPanel;
        }

        return (
          <MobileDatePicker
            onClear={onClear}
            title={props?.placeholder}
            onApply={onApply}
            onClose={onClose}
            isFadingOut={isFadingOut}
            menu={originPanel}
          />
        );
      }}
      {...omit(props, ['aria-label'])}
      {...mobileProps}
    />
  );

  if (props?.formItemProps) {
    return <AkinonFormItem {...props?.formItemProps}>{component}</AkinonFormItem>;
  }

  return component;
};

export default AkinonDatePicker;
