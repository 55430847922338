import { getCategoryNodePermissionsUrl } from '@constants/apiUrls';

import { useAkinonMutation } from '../adapters/useAkinonMutation';
import { client } from '../client';

export function usePostCategoryNodePermissionsMutation({ mutationOptions } = {}) {
  const { mutateAsync, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody }) => client.post(getCategoryNodePermissionsUrl, requestBody),
    ...mutationOptions,
  });

  return {
    createCategoryNodePermission: mutateAsync,
    isCreatingNewCategoryNodePermission: isLoading,
  };
}
