import { findElementByInnerText, getTourSelectorWithFallback } from '@common/index';
import AkinonButton from '@components/AkinonButton';
import ImageTableHead from '@components/ImageTableHead';
import SmallColumnImage from '@components/SmallColumnImage';
import i18n from '@root/i18n';
import { Tooltip, Typography } from 'antd';
import clsx from 'clsx';
import React, { Fragment } from 'react';

import { StaticFilterKey } from './hooks/common';

const { Text, Title } = Typography;

export const DataIndex = {
  IMAGE: ['image', 'image'],
  NAME: ['product', 'name'],
  BASE_CODE: ['product', 'base_code'],
  OFFER_SKU: 'offer_sku',
  PRODUCT_SKU: ['product', 'sku'],
  STOCK: 'stock',
  SELLER: ['datasource', 'name'],
  IS_ACTIVE: 'is_active',
};

export const getColumns = ({
  t,
  editingKey,
  isEditing,
  handleEditRow,
  handleCancelEditing,
  handleSaveRow,
  isSuperUser,
  isMobile,
}) =>
  [
    {
      dataIndex: DataIndex.IMAGE,
      title: <ImageTableHead />,
      render: (imageSrc) => <SmallColumnImage preview={true} src={imageSrc} />,
      className: 'image-table-column',
    },
    {
      dataIndex: DataIndex.NAME,
      visibleOnFilter: [StaticFilterKey.PRODUCT_NAME],
      title: t('product_name'),
      render: (productName) => <Tooltip title={productName}>{productName}</Tooltip>,
    },
    {
      dataIndex: DataIndex.BASE_CODE,
      visibleOnFilter: [StaticFilterKey.PRODUCT_BASE_CODE],
      title: t('base.code'),
      render: (baseCode) => <Tooltip title={baseCode}>{baseCode?.toUpperCase()}</Tooltip>,
    },
    {
      dataIndex: DataIndex.OFFER_SKU,
      visibleOnFilter: [StaticFilterKey.OFFER_SKU],
      title: t('offer_code'),
      render: (offerCode) => <Tooltip title={offerCode}>{offerCode}</Tooltip>,
    },
    {
      dataIndex: DataIndex.PRODUCT_SKU,
      visibleOnFilter: [StaticFilterKey.PRODUCT_SKU],
      title: t('sku'),
      render: (sku) => <Tooltip title={sku}>{sku}</Tooltip>,
    },
    {
      dataIndex: DataIndex.STOCK,
      title: t('units.in.stock'),
      editable: true,
      inputProps: {
        className: 'editable-cell-input',
        type: 'number',
        min: 0,
        step: '1',
      },
    },
    isSuperUser && {
      dataIndex: DataIndex.SELLER,
      title: t('seller'),
      render: (seller) => <Tooltip title={seller}>{seller}</Tooltip>,
    },
    {
      dataIndex: DataIndex.IS_ACTIVE,
      visibleOnFilter: [StaticFilterKey.IS_ACTIVE],
      title: t('status'),
      fixed: isMobile ? false : 'right',
      className: 'stock-detail__status_column',
      render: (status) => {
        return (
          <div
            className={clsx('offer-status', {
              'offer-approved': status,
              'offer-rejected': !status,
            })}
          >
            {status ? t('active') : t('passive')}
          </div>
        );
      },
    },
    {
      title: t('save'),
      dataIndex: 'save',
      fixed: isMobile ? false : 'right',
      render: (_, record, index) => {
        const editable = isEditing(record);
        const isFirstRow = index === 0;
        return editable ? (
          <span className="stock-detail__actions">
            <AkinonButton
              className="stock-detail__actions__button"
              onClick={() => handleSaveRow(record)}
            >
              {t('save')}
            </AkinonButton>
            <AkinonButton
              className="stock-detail__actions__button"
              type="link"
              onClick={handleCancelEditing}
            >
              <Text underline type="danger">
                {t('cancel')}
              </Text>
            </AkinonButton>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ''}
            onClick={() => handleEditRow(record)}
            className={clsx('stock-detail__edit-button', {
              'stock-detail__edit-button--first-row': isFirstRow,
            })}
          >
            {t('edit')}
          </Typography.Link>
        );
      },
    },
  ].map((col) => {
    if (!col?.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        inputProps: col.inputProps,
        editing: isEditing(record),
        rules: [
          {
            required: true,
            message: t('validation.message'),
          },
        ],
      }),
    };
  });

export const getTourSteps = ({ t }) => {
  return [
    {
      selector: '.app-layout__page-meta',
      content: (
        <Fragment>
          <Title level={4}>{t('stock_detail_tutorial.page_title')}</Title>
          <Text>{t('stock_detail_tutorial.page')}</Text>
        </Fragment>
      ),
    },
    {
      selector: '.stock-detail__bulk-update-stock-info-button',
      content: (
        <Fragment>
          <Title level={4}>{t('stock_detail_tutorial.bulk_stock_update_title')}</Title>
          <Text>{t('stock_detail_tutorial.bulk_stock_update')}</Text>
        </Fragment>
      ),
    },
    {
      selector: '.filter-options__export-item',
      content: (
        <Fragment>
          <Title level={4}>{t('stock_detail_tutorial.xlsx_filter_title')}</Title>
          <Text>{t('stock_detail_tutorial.xlsx_filter')}</Text>
        </Fragment>
      ),
    },
    {
      selector: '.stock-detail__export-popover',
      content: (
        <Fragment>
          <Title level={4}>{t('stock_detail_tutorial.select_an_export_option_title')}</Title>
          <Text>{t('stock_detail_tutorial.select_an_export_option')}</Text>
        </Fragment>
      ),
    },
    {
      selector: getTourSelectorWithFallback(
        ['.price-list-detail__edit-button--first-row'],
        findElementByInnerText(t('save').toLocaleUpperCase(i18n.language), 'th')
      ),
      content: (
        <Fragment>
          <Title level={4}>{t('stock_detail_tutorial.edit_stock_amount_title')}</Title>
          <Text>{t('stock_detail_tutorial.edit_stock_amount')}</Text>
        </Fragment>
      ),
    },
  ];
};

export const ExportOption = {
  EXPORT_STOCK_LIST_TEMPLATE: 'export_stock_list_template',
  EXPORT_STOCK_LIST: 'export_stock_list',
};
