import Link from '@components/Link';
import { RouteUrls } from '@constants/routeUrls';
import useStore from '@zustand-store/index';
import { setBreadcrumbsSelector } from '@zustand-store/selectors/app';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

export function useBreadcrumbs({ isSuperUser }) {
  const { t } = useTranslation('SellerStoreManagement');
  const setBreadcrumbs = useStore(setBreadcrumbsSelector);

  useEffect(() => {
    setBreadcrumbs(
      [
        isSuperUser && (
          <Link
            key={RouteUrls.sellerStoreManagement.landing}
            to={RouteUrls.sellerStoreManagement.landing}
          >
            {t('seller_store_management_list')}
          </Link>
        ),
        t('seller_store_management'),
      ].filter(Boolean)
    );
  }, [t]);

  useUnmount(() => {
    setBreadcrumbs([]);
  });
}
