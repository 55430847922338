const useRejectOrderItems = ({ orderId, rejectOrderItems }) => {
  const onRejectOrderItems = ({ selectedRows, mutationOptions }) => {
    const orderItemIds = selectedRows.map((row) => row.id);
    rejectOrderItems({ orderId, orderItems: orderItemIds }, mutationOptions);
  };

  return { onRejectOrderItems };
};

export default useRejectOrderItems;
