import ImageTableHead from '@components/ImageTableHead';
import SmallColumnImage from '@components/SmallColumnImage';
import { getTreeNodeByValue } from '@utils/index';
import { Tooltip, Typography } from 'antd';
import React from 'react';

import { StaticFilterKey } from './hooks/common';

export const DataIndex = {
  IMAGE: 'image',
  NAME: 'name',
  BASE_CODE: 'base_code',
  SKU: 'sku',
  CATEGORY: 'category',
};

/** @returns {import('antd/lib/table').ColumnsType} */
export const getColumns = ({ t, categoryTreeData }) => [
  {
    dataIndex: DataIndex.IMAGE,
    visibleOnFilter: [StaticFilterKey.IMAGE_ISNULL],
    title: <ImageTableHead />,
    render: (imageSrc) => <SmallColumnImage src={imageSrc} />,
    className: 'image-table-column',
    align: 'center',
  },
  {
    dataIndex: DataIndex.NAME,
    visibleOnFilter: [StaticFilterKey.NAME],
    title: t('product_name'),
    render: (productName) => <Tooltip title={productName}>{productName}</Tooltip>,
  },
  {
    dataIndex: DataIndex.BASE_CODE,
    visibleOnFilter: [StaticFilterKey.BASE_CODE],
    title: t('base.code'),
    render: (baseCode) => <Tooltip title={baseCode}>{baseCode.toUpperCase()}</Tooltip>,
  },
  {
    dataIndex: DataIndex.SKU,
    visibleOnFilter: [StaticFilterKey.SKU],
    title: t('sku'),
    render: (sku) => <Tooltip title={sku}>{sku}</Tooltip>,
  },
  {
    dataIndex: DataIndex.CATEGORY,
    visibleOnFilter: [StaticFilterKey.CATEGORY_PATH],
    title: t('category'),
    render: (category) => {
      const label =
        getTreeNodeByValue({ tree: categoryTreeData, nodeValue: category })?.path ?? '-';
      return <Tooltip title={label}>{label}</Tooltip>;
    },
  },
];

export const getTourSteps = ({ t }) => {
  return [
    {
      selector: '.standard-akinon-table-header__title',
      content: (
        <>
          <Typography.Title level={4}>{t('product_pool_guide_step_1_title')}</Typography.Title>
          <Typography.Text>{t('product_pool_guide_step_1_desc')}</Typography.Text>
        </>
      ),
    },
    {
      selector: '.filter-options',
      content: (
        <>
          <Typography.Title level={4}>{t('product_pool_guide_step_2_title')}</Typography.Title>
          <Typography.Text>{t('product_pool_guide_step_2_desc')}</Typography.Text>
        </>
      ),
    },
  ];
};
