import * as z from 'zod';

import { ApiConfigType } from '../../common';

export function getProductFeaturesFormSchema({
  variantListAttributeFields,
  isOfferProductsReadyToBeDisplayed,
}) {
  return z.object({
    productFeaturesForm: z.object({
      ...variantListAttributeFields?.reduce((acc, field) => {
        let schema;

        if (field.data_type === ApiConfigType.BOOL) {
          schema =
            field.required && !isOfferProductsReadyToBeDisplayed
              ? z.boolean()
              : z.boolean().optional();
        } else {
          schema =
            field.required && !isOfferProductsReadyToBeDisplayed
              ? z.string().min(1)
              : z.string().optional();
        }

        return {
          ...acc,
          [field.key]: schema,
        };
      }, {}),
    }),
  });
}
