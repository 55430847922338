import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { QueryKey } from '@constants/query';
import { getProductOffers } from '@services/api';
import { useQuery } from '@tanstack/react-query';

/**
 * @typedef {import('@tanstack/react-query').UseQueryResult} UseQueryResult
 * @returns {import('@tanstack/react-query').UseQueryResult}
 */
export const usePaginatedProductOffersQuery = ({
  pagination,
  filters,
  ids,
  enabled = true,
  params = {},
}) => {
  const queryKey = [QueryKey.PRODUCT_OFFERS, pagination, filters, ids];
  const queryFn = () =>
    getProductOffers({
      ...params,
      ...getRequestPaginationParams(pagination),
      ...getRequestFiltersParams(filters),
      limit: ids?.length || pagination?.pageSize,
      id__in: ids?.join(','),
    });
  const { data, isFetching, isLoading, error, ...rest } = useQuery({
    queryKey,
    queryFn,
    enabled,
  });

  return {
    ...rest,
    data: data?.results,
    total: data?.count,
    isFetching,
    isLoading,
    error,
  };
};
