const initialState = {
  variantsListAttributeMetaFields: [],
  isVariantsListAttributeMetaFieldsLoading: false,
  variantsFormDynamicMetaFields: [],
  isVariantsFormDynamicMetaFieldsLoading: false,
  productsToBeAddedTableFormDataSource: [],
  selectedRelatedProduct: null,
};

export const createPreOfferSlice = (set) => ({
  ...initialState,
  setVariantsFormDynamicMetaFields: (variantsFormDynamicMetaFields) =>
    set(() => ({ variantsFormDynamicMetaFields })),
  setIsVariantsListAttributeMetaFieldsLoading: (isVariantsListAttributeMetaFieldsLoading) =>
    set(() => ({ isVariantsListAttributeMetaFieldsLoading })),
  setVariantsListAttributeMetaFields: (variantsListAttributeMetaFields) =>
    set(() => ({ variantsListAttributeMetaFields })),
  setIsVariantsFormDynamicMetaFieldsLoading: (isVariantsFormDynamicMetaFieldsLoading) =>
    set(() => ({ isVariantsFormDynamicMetaFieldsLoading })),
  setProductsToBeAddedTableFormDataSource: (productsToBeAddedTableFormDataSource) =>
    set(() => ({ productsToBeAddedTableFormDataSource })),
  setSelectedRelatedProduct: (selectedRelatedProduct) => set(() => ({ selectedRelatedProduct })),
  resetPreOffer: () => set(() => ({ ...initialState })),
});
