import { useEffect, useState } from 'react';

import { ShippingMethodSlug } from '../PackageDetail/common';

export const useOtherCargoCompanyOption = (shippingOptionList) => {
  const [cargoOtherOption, setCargoOtherOption] = useState({});

  useEffect(() => {
    if (shippingOptionList?.length) {
      const otherCargoOption = shippingOptionList.find(
        (cargo) => cargo?.name?.toLowerCase() === ShippingMethodSlug.OTHER
      );
      setCargoOtherOption(otherCargoOption);
    }
  }, [shippingOptionList]);

  return [cargoOtherOption, setCargoOtherOption];
};
