import { getTownshipUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { client } from '@services/api/client';
import { useQuery } from '@tanstack/react-query';

export function useTownshipQuery({ queryOptions, townshipId }) {
  const { data, isLoading } = useQuery({
    queryKey: [QueryKey.TOWNSHIP, townshipId],
    queryFn: () =>
      client.get(getTownshipUrl, {
        urlParams: { townshipId },
      }),
    ...queryOptions,
  });

  return {
    township: data,
    isTownshipLoading: isLoading,
  };
}
