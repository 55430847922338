import { useLocation, useParams } from 'react-router-dom';

export function useCurrentRoutePath() {
  const location = useLocation();
  const { pathname } = location;
  const params = useParams();

  if (!Object.keys(params).length) {
    return pathname;
  }

  let path = pathname;
  Object.entries(params).forEach(([paramName, paramValue]) => {
    if (paramValue) {
      path = path.replace(paramValue, `:${paramName}`);
    }
  });
  return path;
}
