import useStore from '@zustand-store/index';
import { resetFiltersSelector, setFiltersSelector } from '@zustand-store/selectors/filters';

import { usePageFilters } from './usePageFilters';

export default function useFiltersSlice() {
  const pageFilters = usePageFilters();
  const setFilters = useStore(setFiltersSelector);
  const resetFilters = useStore(resetFiltersSelector);

  return {
    pageFilters,
    setFilters,
    resetFilters,
  };
}
