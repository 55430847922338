import { usePageFilters } from '@components/AkinonFilter/hooks/store/usePageFilters';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { oAuthProvidersUrl } from '@constants/apiUrls';
import { useTranslation } from 'react-i18next';

import { getColumns } from './common';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { useDataSource } from './hooks/useDataSource';
import { usePageMeta } from './hooks/usePageMeta';

export default function SsoProviderList() {
  const { t } = useTranslation('Settings');
  const filters = usePageFilters();

  const { dataSource, isDataSourceLoading } = useDataSource({ filters });
  const [pagination, setPagination] = usePagination();

  useBreadcrumbs();
  usePageMeta();

  const columns = getColumns({ t });

  return (
    <section>
      <AkinonTable
        dataSource={dataSource}
        optionsUrl={oAuthProvidersUrl}
        loading={isDataSourceLoading}
        total={dataSource?.length ?? 0}
        columns={columns}
        pagination={pagination}
        setPagination={setPagination}
        rowKey="slug"
        description={t('n_results_found', { count: dataSource?.length ?? 0 })}
        title={t('sso_settings.title')}
      />
    </section>
  );
}
