import { attachFileWithDataSourceIdUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';

import { client } from '../client';

/**
 * @param {{ mutationOptions: import('@tanstack/react-query').UseMutationOptions }} args
 */
export function useAttachFileWithDataSourceIdMutation({ mutationOptions } = {}) {
  const { mutate } = useAkinonMutation({
    mutationFn: ({ requestBody, requestConfig, dataSourceId }) =>
      client.post(attachFileWithDataSourceIdUrl, requestBody, {
        ...requestConfig,
        urlParams: {
          dataSourceId,
        },
      }),
    ...mutationOptions,
  });

  return {
    attachFileWithDataSourceId: mutate,
  };
}
