import { defaultDateFormat, defaultDateValueFormat } from '@constants';
import moment from 'moment';

export const TOP_FIVE_PRODUCTS = [
  {
    name: 'Slim Fit Yarn Dye Oxford Shirt',
    product_count: 109,
    product_rate: '4,7',
  },
  {
    name: 'Slim Fit Yarn Dye Oxford Shirt with Short Sleeves',
    product_count: 87,
    product_rate: '4,7',
  },
  {
    name: 'Ultimate Organic Cotton T Shirt With Crew Neck',
    product_count: 79,
    product_rate: '4,7',
  },
  {
    name: 'Frill Neck Mini Dress With Fluted Three Quarter Sleeve',
    product_count: 67,
    product_rate: '4,7',
  },
  {
    name: 'Wide Fit Embrace Leather High Heeled Square Toe Boots',
    product_count: 45,
    product_rate: '4,7',
  },
];

export const TOP_FIVE_CATEGORIES = [
  {
    name: 'Clothing',
    item_count: 169,
  },
  {
    name: 'Phone',
    item_count: 123,
  },
  {
    name: 'Electronics',
    item_count: 108,
  },
  {
    name: 'Shoes',
    item_count: 78,
  },
  {
    name: 'Appliance',
    item_count: 54,
  },
];

export const TOP_FIVE_CITIES = [
  {
    name: 'Istanbul',
    item_count: 254,
  },
  {
    name: 'Ankara',
    item_count: 86,
  },
  {
    name: 'Izmir',
    item_count: 63,
  },
  {
    name: 'Bursa',
    item_count: 51,
  },
  {
    name: 'Antalya',
    item_count: 47,
  },
];

export const MOCK_GRPAH_LABELS = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

export const MOCK_GRAPH_VALUES = [15, 3, 9, 6, 12, 1, 3, 3, 11, 12];

export const MOCK_GRAPH_VALUES_SELLER_RATING = [4.3, 5, 4.1, 4.7, 4.2, 5, 5, 4, 4.2, 4.3];

export function generateDateParams(date) {
  return moment(date, defaultDateFormat).format(defaultDateValueFormat);
}

export const initialDates = {
  start_date: generateDateParams(moment().add(-1, 'months')),
  end_date: generateDateParams(moment()),
};

export function generateChartData(orderCountByDate, start, end) {
  const getDaysArray = function (s, e) {
    for (var a = [], d = new Date(s); d <= new Date(e); d.setDate(d.getDate() + 1)) {
      a.push(new Date(d));
    }
    return a;
  };

  const dates = getDaysArray(start, end);
  const [labels, values] = dates.reduce(
    (acc, curr) => {
      const _date = moment(curr).format('YYYY-MM-DD');
      acc[0].push(_date);
      acc[1].push(orderCountByDate?.[_date] || 0);
      return acc;
    },
    [[], []]
  );
  return { labels, values };
}
