import { useDataSourceDetailQuery } from '@services/api/hooks/useDataSourceDetailQuery';
import { useMeQuery } from '@services/api/hooks/useMeQuery';
import { useIsLoggedIn } from '@utils/hooks/useIsLoggedIn';

export function useUser() {
  const isLoggedIn = useIsLoggedIn();

  const { me: user } = useMeQuery({
    queryOptions: {
      enabled: isLoggedIn,
    },
  });

  const { dataSourceDetail } = useDataSourceDetailQuery({
    dataSourceId: user?.datasource,
    queryOptions: {
      enabled: !!user?.datasource,
    },
  });
  return { ...user, ...(dataSourceDetail ? { dataSourceDetail } : {}) };
}
