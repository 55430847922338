import { sendRequest } from '@common/network';
import { orderReportingUrl, salesReportingUrl } from '@constants/apiUrls';
import { httpMethods } from '@constants/commontypes';
import { Modal } from 'antd';
import omit from 'lodash/omit';
import moment from 'moment';

import { dateFormat, FormFieldKey, ReportType } from './common';

const omitReportTypeThenFormatFormDateValues = (initialFormValues) => {
  const formValuesWithoutReportType = omit(initialFormValues, FormFieldKey.REPORT_TYPE);
  Object.keys(formValuesWithoutReportType).forEach((formItemKey) => {
    if (formItemKey.includes('date')) {
      formValuesWithoutReportType[formItemKey] = moment(
        formValuesWithoutReportType[formItemKey]
      ).format(dateFormat);
    }
  });

  return formValuesWithoutReportType;
};

const showSuccessModal = ({ t, resetForm }) => {
  Modal.info({
    title: t('reporting.success.header'),
    content: t('reporting.success.message'),
    onOk() {
      resetForm();
    },
  });
};

export default function getOnSubmit({ t, reportType, setLoading, resetForm }) {
  return (formValues) => {
    const formValuesWithoutReportType = omitReportTypeThenFormatFormDateValues(formValues);

    sendRequest({
      url: reportType === ReportType.SALES ? orderReportingUrl : salesReportingUrl,
      method: httpMethods.GET,
      params: { ...formValuesWithoutReportType },
      onBegin: () => {
        setLoading(true);
      },
      onFail: () => {},
      onSuccess: () => {
        showSuccessModal({ t, resetForm });
      },
      onFinally: () => {
        setLoading(false);
      },
    });
  };
}
