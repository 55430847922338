import './style.scss';

import { Col, Row } from 'antd';
import { useParams } from 'react-router-dom';

import { MessagesBox } from './components/MessagesBox';
import { ProductInformationBox } from './components/ProductInformationBox';
import { ReplyMessageBox } from './components/ReplyMessageBox';
import { useConversationAndProductsQuery } from './hooks/api/useConversationAndProductsQuery';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { usePageMeta } from './hooks/usePageMeta';

function ProductAnswer() {
  const { id } = useParams();

  useBreadcrumbs();
  usePageMeta();

  const { conversation, productImages, isConversationLoading } = useConversationAndProductsQuery({
    conversationId: id,
  });

  return (
    <section className="product-answer-detail">
      <Row
        gutter={40}
        style={{
          rowGap: '40px',
        }}
      >
        <Col span={24} sm={12} xl={10} style={{ height: 'fit-content' }}>
          <ProductInformationBox conversation={conversation} productImages={productImages} />
        </Col>

        <Col
          span={24}
          lg={16}
          xl={12}
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '40px',
          }}
        >
          <MessagesBox isConversationLoading={isConversationLoading} conversation={conversation} />
          <ReplyMessageBox conversationId={id} />
        </Col>
      </Row>
    </section>
  );
}

export default ProductAnswer;
