import { getActiveAuthObject } from '@common/auth';
import { postBlacklistTokenUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { client } from '@services/api/client';

/**
 * @param {import('@tanstack/react-query').UseMutationOptions} mutationOptions
 */
export function useBlacklistRefreshTokenMutation(mutationOptions) {
  const mutationResponse = useAkinonMutation({
    mutationFn: ({ requestConfig }) =>
      client.post(
        postBlacklistTokenUrl,
        {
          refresh: getActiveAuthObject()?.refresh,
        },
        requestConfig
      ),
    ...mutationOptions,
  });

  return {
    ...mutationResponse,
    blacklistRefreshToken: mutationResponse.mutate,
  };
}
