import './style.scss';

import PngNotFound from '@assets/images/404-notfound.png';
import AkinonButton from '@components/AkinonButton';
import Link from '@components/Link';
import { defaultRedirectionUrl, RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { Col } from 'antd';
import { Trans, useTranslation } from 'react-i18next';

const NotFound = () => {
  const navigate = useAppNavigate();
  const { t } = useTranslation('ProductsAndCategories');

  return (
    <div className="not-found-page">
      <Col flex={1} span={24}>
        <span className="not-found-page-title">404</span>
      </Col>
      <Col flex={1} span={24}>
        <span className="not-found-page-description">
          <Trans
            i18nKey="404.description"
            values={{
              page1: t('approved_offers'),
              page2: t('packages'),
            }}
            components={[
              <Link key={0} to={RouteUrls.productsAndCategories.offerList.approved} />,
              <Link key={1} to={RouteUrls.order.packages.packageList} />,
            ]}
          />
        </span>
      </Col>
      <Col flex={1} span={24}>
        <img src={PngNotFound} alt="" className="not-found-page-image" />
      </Col>
      <Col flex={1} span={24} className="not-found-page-button-container mt-16">
        <AkinonButton
          type="default"
          onClick={() => navigate(defaultRedirectionUrl)}
          variant="outline"
        >
          {t('404.button')}
        </AkinonButton>
      </Col>
    </div>
  );
};

export default NotFound;
