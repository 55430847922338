import { WidgetType } from '@constants/commontypes';
import { verticalFormItemLayout } from '@constants/layoutTypes';
import isEmpty from 'lodash/isEmpty';

export const BasicProductInfoFormItemKey = {
  NAME: 'name',
  BASE_CODE: 'base_code',
  ATTRIBUTE_SET: 'id',
};

export const getBasicProductInfoFormMeta = ({ attributeSetOptions, product, t }) => ({
  formItemLayout: verticalFormItemLayout,
  colon: true,
  fields: [
    {
      key: BasicProductInfoFormItemKey.NAME,
      required: true,
      label: t('product_name'),
      widget: WidgetType.INPUT,
      disabled: true,
      initialValue: product?.name,
    },
    {
      key: BasicProductInfoFormItemKey.BASE_CODE,
      required: true,
      label: t('basecode'),
      widget: WidgetType.INPUT,
      disabled: true,
      initialValue: product?.base_code,
    },
    {
      key: BasicProductInfoFormItemKey.ATTRIBUTE_SET,
      required: true,
      label: t('attribute_set'),
      widget: WidgetType.SELECT,
      options: attributeSetOptions,
      disabled: true,
      initialValue: product?.attribute_set,
      widgetProps: {
        virtual: false,
        showSearch: true,
        optionFilterProp: 'label',
        loading: isEmpty(attributeSetOptions),
      },
    },
  ],
});
