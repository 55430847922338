import {
  deletePreOfferImageUrl,
  getProductImagesUrl,
  getProductStockListUrl,
  getProductsUrl,
  getProductUrl,
} from '@constants/apiUrls';
import { limitQuery } from '@constants/commontypes';
import { client } from '@services/api/client';

export const getProducts = (params) => {
  return client.get(getProductsUrl, { params });
};

export const getProductImages = (requestConfig) => {
  return client.get(getProductImagesUrl, requestConfig);
};

export const getProductStocks = (params) => {
  return client.get(getProductStockListUrl, { params });
};

export const deletePreOfferImage = ({ imageId }) => {
  return client.delete(deletePreOfferImageUrl, {
    urlParams: { imageId },
  });
};

export const getProductMeta = ({ metaId }) => {
  return client.get(getProductsUrl, {
    params: {
      meta: metaId,
      ...limitQuery,
    },
  });
};

/**
 * @param {{ productId: string, requestConfig: import('axios').AxiosRequestConfig }} args
 */
export const getProduct = ({ productId, requestConfig }) => {
  return client.get(getProductUrl, {
    ...requestConfig,
    urlParams: {
      productId,
    },
  });
};
