import { createSelectOptions } from '@common/index';
import AkinonFormBuilder from '@components/AkinonFormBuilder';
import Box from '@components/utility/box';
import { useAttributeSetQuery } from '@services/hooks/attributes/useAttributeSetQuery';
import { Form } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useQueriesContext } from '../../contexts/Queries';
import { getBasicProductInfoFormMeta } from './common';

const BasicProductInfoForm = ({ form }) => {
  const { attributeSetId, product } = useQueriesContext();
  const { t } = useTranslation('ProductsAndCategories');
  const { attributeSet } = useAttributeSetQuery({
    attributeSetId,
    queryOptions: { enabled: Boolean(attributeSetId) },
  });

  useEffect(() => {
    form.resetFields();
  }, [product]);

  const attributeSetOptions = createSelectOptions([attributeSet ?? {}], {
    labelKey: 'name',
    valueKey: 'id',
  });

  const meta = getBasicProductInfoFormMeta({
    attributeSetOptions,
    product,
    t,
  });

  return (
    <Box
      className="box-primary form-box"
      title={t('basic_product_info_title')}
      subtitle={t('basic_product_info_subtitle')}
    >
      <Form className="akn-form akn-form-v2" form={form} layout="vertical">
        <AkinonFormBuilder meta={meta} form={form} />
      </Form>
    </Box>
  );
};

export default BasicProductInfoForm;
