import { WidgetType } from '@constants/commontypes';

import { StaticFilterKey } from '../common';

export default function useStaticFilters({ t }) {
  return {
    staticFiltersFormMetaFields: [
      {
        key: StaticFilterKey.PRODUCT_NAME,
        widget: WidgetType.INPUT,
        placeholder: t('product_name'),
      },
      {
        key: StaticFilterKey.BASE_CODE,
        widget: WidgetType.INPUT,
        placeholder: t('base.code'),
      },
      {
        key: StaticFilterKey.SKU,
        widget: WidgetType.INPUT,
        placeholder: t('sku'),
      },
    ],
  };
}
