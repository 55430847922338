import {
  attachFileWithDataSourceIdUrl,
  getDataSourceDetailUrl,
  getDataSourcePermissionsUrl,
  getDataSourcesUrl,
  postDataSourceCreateUserUrl,
} from '@constants/apiUrls';
import { limitQuery } from '@constants/commontypes';
import { client } from '@services/api/client';

export const getDataSources = () => {
  return client.get(getDataSourcesUrl, {
    params: {
      is_active: true,
      ...limitQuery,
    },
  });
};

export const getPaginatedDataSources = (params) => {
  return client.get(getDataSourcesUrl, { params });
};

export const getDataSourceDetail = (dataSourceId) => {
  if (!dataSourceId) return null;

  return client.get(getDataSourceDetailUrl, { urlParams: { dataSourceId } });
};

export const getDataSourcePermissions = (dataSourceId, requestConfig = {}) => {
  return client.get(getDataSourcePermissionsUrl, {
    ...requestConfig,
    urlParams: {
      dataSourceId,
    },
  });
};

export const postDataSourceCreateUser = (dataSourceId, requestBody = {}) => {
  return client.post(postDataSourceCreateUserUrl, requestBody, {
    urlParams: {
      dataSourceId,
    },
  });
};

export const attachFileWithDataSourceId = ({ dataSourceId, requestBody, requestConfig = {} }) => {
  return client.post(attachFileWithDataSourceIdUrl, requestBody, {
    ...requestConfig,
    urlParams: {
      dataSourceId,
    },
  });
};
