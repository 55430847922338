import AkinonTable from '@components/AkinonTable';
import AkinonDraggableBodyRow from '@components/AkinonTable/components/AkinonDraggableBodyRow';
import { RouteUrls } from '@constants/routeUrls';
import { arrayMove } from '@dnd-kit/sortable';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { usePatchDataSourceShippingCompanyOptionConfigMutation } from '@services/api/hooks/usePatchDataSourceShippingCompanyOptionConfigMutation';
import { usePostDataSourceShippingCompanyOptionConfigMutation } from '@services/api/hooks/usePostDataSourceShippingCompanyOptionConfigMutation';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { DataIndex, getColumns } from './common';
import useDatasource from './hooks/useDatasource';

export default function DeliverySettings() {
  const { t } = useTranslation('Settings');
  const navigate = useAppNavigate();
  const { id } = useParams();

  const { datasource, isLoading, total } = useDatasource({
    datasourceId: id,
  });
  const { updateDataSourceShippingCompanyOptionConfig } =
    usePatchDataSourceShippingCompanyOptionConfigMutation();
  const { createDataSourceShippingCompanyOptionConfig } =
    usePostDataSourceShippingCompanyOptionConfigMutation();

  const columns = getColumns({ t });

  const updatePriority = ({ dragRow, hoverRow }) => {
    const { shipping_company_option_config_obj } = dragRow ?? {};

    const newPriority = hoverRow?.priority;

    const isConfigObjectInactive =
      !shipping_company_option_config_obj?.id && !shipping_company_option_config_obj?.is_active;

    if (isConfigObjectInactive) {
      createDataSourceShippingCompanyOptionConfig({
        requestBody: {
          shipping_company_option: dragRow.id,
          is_active: dragRow[DataIndex.IS_REAL_ACTIVE],
          priority: newPriority,
        },
      });

      return;
    }

    updateDataSourceShippingCompanyOptionConfig({
      configId: shipping_company_option_config_obj?.id,
      requestBody: {
        priority: newPriority,
      },
    });
  };

  const moveRow = useCallback(
    (activeIndex, hoverIndex) => {
      const [dragRow] = datasource.slice(activeIndex, activeIndex + 1);
      const [hoverRow] = datasource.slice(hoverIndex, hoverIndex + 1);
      datasource.splice(hoverIndex, 0, dragRow);
      arrayMove(datasource, activeIndex, hoverIndex);

      if (dragRow.priority === hoverRow.priority) {
        return;
      }

      updatePriority({ dragRow, hoverRow });
    },
    [datasource]
  );

  const onRow = (record, index) => ({
    onClick() {
      navigate(RouteUrls.settings.deliverySettingsShipmentSettingsDetail, {
        id: record.id,
        configId: record?.shipping_company_option_config_obj?.id,
      });
    },
    index,
    moveRow,
  });

  return (
    <section className="shipment-settings">
      <AkinonTable
        title={t('shipping_companies')}
        description={t('Finance:delivery_settings.description')}
        rowKey="id"
        dataSource={datasource}
        columns={columns}
        loading={isLoading}
        total={total}
        pagination={false}
        components={{
          body: {
            row: AkinonDraggableBodyRow,
          },
        }}
        onRow={onRow}
      />
    </section>
  );
}
