import AkinonBox from '@components/AkinonBox';
import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import { openNotification } from '@components/akinonNotification';
import AkinonSpin from '@components/AkinonSpin';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDynamicFormFields } from '@pages/ProductsAndCategories/ProductForm/hooks/useDynamicFormFields';
import useImageDimensionsDataSourceQuery from '@services/api/hooks/useImageDimensionsDataSourceQuery';
import { Divider, Pagination, Space } from 'antd';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import uniq from 'lodash/uniq';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { getFormSchema } from './common';
import MultipleProductRevisionFields from './MultipleProductsRevisionFields';

const MultipleProductsRevisionsForm = ({
  isLoading,
  productRevisions,
  productMeta,
  attributeConfigs,
  productAttributes,
  productImages,
  pageSize = 4,
  onSubmit,
}) => {
  const { t } = useTranslation('ProductsAndCategories');
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [pagination, setPagination] = useState({ current: 1 });
  const { dimensionBounds } = useImageDimensionsDataSourceQuery();
  const schema = getFormSchema({ t, productAttributes, dimensionBounds });

  const { handleSubmit, setValue, getValues, control, watch } = useForm({
    resolver: zodResolver(z.record(z.string(), schema)),
    defaultValues: {
      bulkUpdate: {},
    },
  });

  const { variantListAttributeFields, variantDynamicFields } = useDynamicFormFields({
    attributeConfigs,
    productAttributes,
    forbiddenKeys: [],
    t,
  });

  const allAttributeFields = [...variantListAttributeFields, ...variantDynamicFields];

  const revisionFieldsProps = {
    control,
    attributeConfigs,
    productAttributes,
    productRevisions,
    allAttributeFields,
    variantDynamicFields,
    productImages,
    getValues,
    setValue,
    style: {
      width: `${40 / pageSize}vw`,
    },
    selectedProducts,
    setSelectedProducts,
    pagination,
    pageSize,
    watch,
  };

  const onSubmitAll = () => {
    if (selectedProducts?.length < 1) {
      openNotification({
        message: t('invalid_operation'),
        description: t('select_at_least_one_product'),
        type: 'warning',
      });
      return;
    }
    const values = getValues();
    selectedProducts.forEach((productId) => {
      const value = values?.[productId];
      onSubmit(value, { productId });
    });
  };

  const allProducts = productMeta?.map?.((productMeta) => productMeta.id);
  const handleCleanAllContent = () => {
    const values = getValues();
    allProducts.forEach((productId) => {
      const prevValues = values?.[productId];
      setValue(
        productId,
        Object.keys(prevValues).reduce((acc, key) => ({ ...acc, [key]: undefined }), {})
      );
    });
  };
  const handleApplyToAll = () => {
    setSelectedProducts(uniq(allProducts));
    const values = getValues();
    allProducts.forEach((productId) => {
      const prevValues = values?.[productId];
      setValue(productId, { ...prevValues, ...omitBy(values.bulkUpdate, isEmpty) });
    });
  };
  const handleApplyToSelectedOnes = () => {
    const values = getValues();
    selectedProducts.forEach((productId) => {
      const prevValues = values?.[productId];
      setValue(productId, { ...prevValues, ...omitBy(values.bulkUpdate, isEmpty) });
    });
  };
  const handlePaginationChange = (page) => {
    setPagination({ current: page });
  };

  return (
    <AkinonSpin spinning={isLoading}>
      <div className="multiple-product-revision">
        <AkinonBox className="box-primary form-box">
          <AkinonForm className="akn-form" onFinish={handleSubmit(onSubmitAll)}>
            <div
              className={clsx('offer-revision-modal__root-form-builder', {
                'singular-form-modal__root-form-builder': productMeta?.length === 1,
              })}
            >
              <MultipleProductRevisionFields
                formName="bulkUpdate"
                {...omit(revisionFieldsProps, 'productRevision')}
                disableSelection
              />
            </div>
            <Space direction="horizontal" className="offer-revision-modal__form-actions">
              <AkinonButton onClick={handleApplyToAll} htmlType="button" type="primary">
                {t('apply.to.all').toUpperCase()}
              </AkinonButton>
              <AkinonButton onClick={handleApplyToSelectedOnes} htmlType="button" type="primary">
                {t('apply.to.selected.ones').toUpperCase()}
              </AkinonButton>
            </Space>
            <Divider className="product-revision-form-wrapper__divider" />

            <div className="product-revision-forms-wrapper">
              {productMeta?.map?.((productMeta) => (
                <MultipleProductRevisionFields
                  productMeta={productMeta}
                  productRevision={productRevisions?.find(
                    (revision) => revision?.product === productMeta?.id
                  )}
                  key={productMeta.id}
                  formName={productMeta.id}
                  {...revisionFieldsProps}
                />
              ))}
            </div>
            <section className="product-revision-modal__pagination-box">
              <Pagination
                pageSize={pageSize}
                total={allAttributeFields?.length + 1}
                className="product-revision-modal__pagination"
                onChange={handlePaginationChange}
                simple
              />
            </section>
            <Space>
              <AkinonButton type="primary" htmlType="submit" loading={isLoading}>
                {t('send_for_approval').toUpperCase()}
              </AkinonButton>
              <AkinonButton onClick={handleCleanAllContent} type="danger" htmlType="button">
                {t('clean.all.content').toUpperCase()}
              </AkinonButton>
            </Space>
          </AkinonForm>
        </AkinonBox>
      </div>
    </AkinonSpin>
  );
};

export default MultipleProductsRevisionsForm;
