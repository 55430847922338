import './styles.scss';

import AkinonSteps, { AkinonStep } from '@components/AkinonSteps';
import { useCurrentStep } from '@utils/hooks/useCurrentStep';
import { useTranslation } from 'react-i18next';

import { getStepItems, PageStep } from './common';
import { useBreadcrumbs } from './hooks/useBreadrumbs';
import { usePageMeta } from './hooks/usePageMeta';

export default function ImportExportOperations() {
  const { t } = useTranslation('ImportExportOperations');

  const { currentStep, setCurrentStep } = useCurrentStep({
    stepCount: 2,
    fallbackStep: PageStep.IMPORTS,
  });

  useBreadcrumbs();
  usePageMeta();

  const stepHandleOnChange = (step) => {
    setCurrentStep(step);
  };

  const stepItems = getStepItems({ t });
  return (
    <section className="import-export-options">
      <AkinonSteps current={currentStep} onChange={stepHandleOnChange}>
        {stepItems.map(({ key, title, icon }) => (
          <AkinonStep key={key} title={title} icon={icon} />
        ))}
      </AkinonSteps>
      <div className="import-export-options__content">
        {stepItems.find((_, index) => index === currentStep).content}
      </div>
    </section>
  );
}
