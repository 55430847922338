import './styles.scss';

import { convertFileToBase64 } from '@common/file';
import AkinonDragger from '@components/AkinonDragger';
import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { FileType, getFileTypeOfUrl } from '@layouts/AppLayout/Announcements/common';
import React, { useEffect, useState } from 'react';

import SortableDocItem from './components/SortableDocItem';
import SortableImageItem from './components/SortableImageItem';

const ProductImageUpload = (props) => {
  const [fileList, setFileList] = useState(props?.fileList ?? []);
  useEffect(() => {
    setFileList(props?.fileList ?? []);
  }, [props?.fileList]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const beforeUpload = (file) => {
    convertFileToBase64(file).then((base64) => {
      file.thumbUrl = base64;
    });
  };

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      const activeIndex = fileList.findIndex((i) => i.uid === active.id);
      const overIndex = fileList.findIndex((i) => i.uid === over?.id);
      const newFileList = arrayMove(fileList, activeIndex, overIndex);
      onChange({ fileList: newFileList });
    }
  };

  const onChange = ({ fileList: newFileList }) => {
    props.onChange?.(newFileList);
    setFileList(newFileList);
  };

  const onRemove = ({ fileId }) => {
    const newFileList = fileList?.filter((file) => file.uid !== fileId);
    onChange({ fileList: newFileList });
  };

  const onUpdate = (callback) => {
    const newFileList = callback(fileList);
    onChange({ fileList: newFileList });
  };

  const imageFiles = fileList?.filter(
    (file) => getFileTypeOfUrl(file?.name ?? file?.url) === FileType.IMAGE
  );
  const docFiles = fileList?.filter(
    (file) => getFileTypeOfUrl(file?.name ?? file?.url) !== FileType.IMAGE
  );

  return (
    <div className="product-image-upload">
      <AkinonDragger
        beforeUpload={beforeUpload}
        {...props}
        onChange={onChange}
        fileList={fileList}
        showUploadList={false}
        multiple={true}
      />
      <DndContext onDragEnd={onDragEnd} sensors={sensors}>
        <SortableContext items={fileList?.map?.((file) => file.uid) ?? []}>
          <div className="product-image-upload__image-list">
            {imageFiles?.map?.((imageFile) => (
              <SortableImageItem
                onUpdate={onUpdate}
                key={imageFile.uid}
                file={imageFile}
                onRemoveFile={onRemove}
                disabled={props?.disabled}
              />
            ))}
            {docFiles?.map?.((imageFile) => (
              <SortableDocItem
                onUpdate={onUpdate}
                key={imageFile.uid}
                file={imageFile}
                onRemoveFile={onRemove}
                disabled={props?.disabled}
              />
            ))}
          </div>
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default ProductImageUpload;
