import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { getProductOffersUrl } from '@constants/apiUrls';
import { defaultRowKey } from '@constants/index';
import { useIsMobile } from '@hooks/useIsMobile';
import { useProductOffersQuery } from '@pages/Messages/ProductAnswer/hooks/api/useProductOffersQuery';
import { useProductsQuery } from '@pages/ProductsAndCategories/Stocks/StockDetail/hooks/api/useProductsQuery';
import { useCollectionItemsQuery } from '@services/api/hooks/useCollectionItemsQuery';
import { useTranslation } from 'react-i18next';

import {
  getCollectionItemDetail,
  getCollectionProductsColumns,
  getCollectionProductsTableActions,
} from '../common';

const CampaignProducts = ({
  collectionId,
  collectionDetail,
  filters,
  deleteProductFromCollectionAsync,
  onShowDeleteItemsModal,
  onRow,
  isAddingProductToCollection,
  isDeletingProductFromCollection,
}) => {
  const { t } = useTranslation(['Marketing', 'ProductsAndCategories']);
  const isMobile = useIsMobile();

  const [pagination, setPagination] = usePagination();

  const { productOffers, productOffersTotal } = useProductOffersQuery({
    requestConfig: {
      params: {
        in_collection: collectionId,
        ...getRequestPaginationParams(pagination),
        ...getRequestFiltersParams(filters),
      },
    },
  });

  const { products: collectionProducts, isCollectionProductsFetching } = useProductsQuery({
    productOffers,
  });

  const { collectionItems, isFetchingCollectionItems } = useCollectionItemsQuery({
    params: {
      offer__in: productOffers?.map((productOffer) => productOffer?.id).join(','),
      collection: collectionId,
    },
  });

  const mappedProductOffers = getCollectionItemDetail({
    collectionItems,
    collectionProducts,
    productOffers,
  });

  const collectionProductsTableColumns = getCollectionProductsColumns({
    t,
    deleteProductFromCollectionAsync,
    collectionId,
    isMobile,
  });

  const collectionProductsTableActions = getCollectionProductsTableActions({
    t,
    onShowDeleteItemsModal,
  });

  return (
    <AkinonTable
      title={collectionDetail?.name || t('collection_products')}
      columns={collectionProductsTableColumns}
      dataSource={mappedProductOffers}
      optionsUrl={getProductOffersUrl}
      loading={
        isCollectionProductsFetching ||
        isFetchingCollectionItems ||
        isDeletingProductFromCollection ||
        isAddingProductToCollection
      }
      total={productOffersTotal}
      rowKey={defaultRowKey}
      pagination={pagination}
      setPagination={setPagination}
      tableActions={collectionProductsTableActions}
      rowSelection={true}
      description={`${productOffersTotal || 0} ${t('results.found')}`}
      onRow={onRow}
    />
  );
};

export default CampaignProducts;
