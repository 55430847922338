import { SearchOutlined } from '@ant-design/icons';
import AkinonFormBuilder from '@components/AkinonFormBuilder';
import { Form } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import first from 'lodash/first';

const HighlightedFiltersForm = ({ form, currentFiltersFormMeta, handleOnValuesChange }) => {
  const highlightedFields = cloneDeep(
    currentFiltersFormMeta.fields.filter((filter) => filter?.isHighlightedField)
  );
  const onlyFirstFilterMeta = {
    ...currentFiltersFormMeta,
    fields: highlightedFields,
  };

  if (highlightedFields?.length === 0) return null;

  const firstField = first(highlightedFields);
  firstField.widgetProps = firstField?.widgetProps ?? {};
  firstField.widgetProps.suffix = <SearchOutlined />;
  firstField.widgetProps.allowClear = true;

  return (
    <Form
      className="akinon-form-dark akinon-filter__form akinon-filter__form--highlighted"
      form={form}
      onValuesChange={handleOnValuesChange}
    >
      <AkinonFormBuilder form={form} meta={onlyFirstFilterMeta} />
    </Form>
  );
};

export default HighlightedFiltersForm;
