import { getReconciliationDetail } from '@services/api';
import { useQuery } from '@tanstack/react-query';

/**
 * @typedef {import('@tanstack/react-query').UseQueryResult} UseQueryResult
 * @returns {import('@tanstack/react-query').UseQueryResult}
 */
export const useReconciliationDetail = ({ reconciliationId, enabled = true }) => {
  const queryKey = ['reconciliation-detail', reconciliationId];

  const queryFn = () => getReconciliationDetail(reconciliationId);
  const { data, isLoading, error, refetch } = useQuery({ queryKey, queryFn, enabled });

  return {
    data,
    isLoading,
    error,
    refetch,
  };
};
