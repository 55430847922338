import { withdrawPreOfferUrl } from '@constants/apiUrls';

import { useAkinonMutation } from '../adapters/useAkinonMutation';
import { client } from '../client';

export function useWithdrawMutation() {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn({ preOfferId, requestConfig } = {}) {
      return client.post(withdrawPreOfferUrl, null, {
        ...requestConfig,
        urlParams: { preOfferId },
      });
    },
  });

  return {
    withdrawPreOffer: mutate,
    isWithdrawingPreOffer: isLoading,
  };
}
