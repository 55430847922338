import { getMostSellingProductsUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { useAkinonQuery } from '../adapters/useAkinonQuery';
import { client } from '../client';

export function useMostSellingProductsQuery({ params }) {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.MOST_SELLING_PRODUCTS, params],
    queryFn: () => client.get(getMostSellingProductsUrl, { params }),
  });

  return {
    mostSellingProducts: data?.most_selling_products,
    isFetchingMostSellingProducts: isFetching,
  };
}
