import useStore from '@zustand-store/index';
import { setPageMetaSelector } from '@zustand-store/selectors/app';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

export function usePageMeta({ setShowRejectReasonModal }) {
  const { t } = useTranslation('Settings');
  const setPageMeta = useStore(setPageMetaSelector);

  useEffect(() => {
    setPageMeta({
      title: t('seller_offer_reject_reasons'),
      description: t('seller_offer_reject_reasons_desc'),
      actions: [
        {
          label: t('add_new_offer_rejection_reason'),
          icon: <i className="icon-arti" />,
          onClick() {
            setShowRejectReasonModal(true);
          },
        },
      ],
    });
  }, [t]);

  useUnmount(() => {
    setPageMeta({});
  });
}
