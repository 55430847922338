import './styles.scss';

import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { Modal } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ProductsToBeAddedTableFormFormItemKey } from '../ProductsToBeAddedTableForm/common';
import SortableImageItem from '../ProductsToBeAddedTableForm/components/ProductImageUpload/components/SortableImageItem';

/**
 * @param {{ form: import('react-hook-form').UseFormReturn, }}
 */
const ProductImagesFormModal = ({
  form,
  isProductImagesModalVisible,
  onCancel,
  productImages,
  selectedVariantToShowImages,
}) => {
  const { t } = useTranslation('ProductsAndCategories');
  const { setValue } = form;

  const onImageModalCancel = () => {
    onCancel();
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const onDragEnd = useCallback(
    ({ active, over }) => {
      if (active.id !== over?.id) {
        const activeIndex = productImages.findIndex((i) => i.uid === active.id);
        const overIndex = productImages.findIndex((i) => i.uid === over?.id);
        const sortedProductImages = arrayMove(productImages, activeIndex, overIndex);
        setValue(
          `${ProductsToBeAddedTableFormFormItemKey.PRODUCT_IMAGE}.${selectedVariantToShowImages}`,
          sortedProductImages
        );
      }
    },
    [productImages, selectedVariantToShowImages, setValue]
  );

  const onDeleteProductImage = useCallback(
    ({ fileId }) => {
      const nextProductImages = productImages.filter((file) => file.uid !== fileId);

      setValue(
        `${ProductsToBeAddedTableFormFormItemKey.PRODUCT_IMAGE}.${selectedVariantToShowImages}`,
        nextProductImages
      );
    },
    [productImages, selectedVariantToShowImages, setValue]
  );

  const onUpdate = useCallback(
    (callback) => {
      const nextProductImages = callback(productImages);
      setValue(
        `${ProductsToBeAddedTableFormFormItemKey.PRODUCT_IMAGE}.${selectedVariantToShowImages}`,
        nextProductImages
      );
    },
    [productImages, selectedVariantToShowImages, setValue]
  );

  return (
    <Modal
      className="offers-to-be-added-table-form__product-images-modal"
      footer={null}
      width={800}
      onCancel={onImageModalCancel}
      afterClose={onImageModalCancel}
      open={isProductImagesModalVisible}
      title={t('product.images.title')}
    >
      <div className="product-images-list">
        <DndContext onDragEnd={onDragEnd} sensors={sensors}>
          <SortableContext items={productImages?.map?.((image) => image.uid) ?? []}>
            <div className="product-image-upload__image-list">
              {productImages?.map?.((image) => (
                <SortableImageItem
                  onUpdate={onUpdate}
                  key={image.uid}
                  file={image}
                  onRemoveFile={onDeleteProductImage}
                />
              ))}
            </div>
          </SortableContext>
        </DndContext>
      </div>
    </Modal>
  );
};

export default ProductImagesFormModal;
