import Box from '@components/utility/box';
import React from 'react';

import CustomerInfo from './CustomerInfo';
import OrderInfo from './OrderInfo';
import ShippingInfo from './ShippingInfo';

const OrderDetailList = ({ t, packages, orderDetail }) => {
  return (
    <div className="detail-wrapper order-detail-info-boxes">
      <div className={'detail-list-item'}>
        <div className="detail-box-wrapper">
          <Box className="detail-box box-primary" title={t('orders.info')}>
            <div className="detail-list-wrapper">
              <OrderInfo t={t} orderDetail={orderDetail} />
            </div>
          </Box>
        </div>
        <div className="detail-box-wrapper">
          <Box className="detail-box box-primary" title={t('customer_info')} leftSideColor="blue">
            <div className="detail-list-wrapper">
              <CustomerInfo t={t} orderDetail={orderDetail} />
            </div>
          </Box>
        </div>
        <div className="detail-box-wrapper shipping-info-box">
          <Box className="detail-box box-primary" title={t('cargo')} leftSideColor="yellow">
            <div className="detail-list-wrapper">
              <ShippingInfo t={t} packages={packages} orderDetail={orderDetail} />
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailList;
