import { Image } from 'antd';
import keys from 'lodash/keys';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CurrentAttributes = ({ attributes, currentAttributes, record }) => {
  const { t } = useTranslation('ProductsAndCategories');
  const requestedAttributes = record?.attributes;
  const attributesToDisplay = omit(pick(currentAttributes, keys(requestedAttributes)), 'name');
  const { images, revisionImages } = record;
  const shouldShowImages = revisionImages?.length > 0 || images?.length > 0;

  // fill default values
  keys(requestedAttributes).forEach((key) => {
    if (!(key in currentAttributes)) {
      attributesToDisplay[key] = '-';
    }
  });

  return (
    <div className="old-offer-attributes">
      <div className="offer-attribute">
        <div className="offer-attribute-name">{t('product_name')}:</div>{' '}
        <div className="offer-attribute-value">{currentAttributes?.name ?? '-'}</div>
      </div>
      {shouldShowImages && (
        <div className="revision-images-container">
          <div className="offer-attribute-name">
            {t('product_images')}
            {!images && ': -'}
          </div>
          {images && (
            <div className="revision-images-container__all-images">
              <Image.PreviewGroup>
                {images.map((item) => (
                  <Image width="calc(25% - 0.8rem)" key={item.id} src={item.image} />
                ))}
              </Image.PreviewGroup>
            </div>
          )}
        </div>
      )}
      {Object.entries(attributesToDisplay ?? {}).map(([key, value]) => {
        const attributeName = attributes.find((attribute) => attribute.key === key)?.name ?? key;
        return (
          <div className="offer-attribute" key={key}>
            <div className="offer-attribute-name">{attributeName}:</div>
            <div className="offer-attribute-value">{value}</div>
          </div>
        );
      })}
    </div>
  );
};

export default CurrentAttributes;
