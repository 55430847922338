import './style.scss';

import PngUnexpectedError from '@assets/images/unexpected-error.png';
import Link from '@components/Link';
import { Col } from 'antd';
import { Trans, useTranslation } from 'react-i18next';

const UnexpectedError = () => {
  const { t } = useTranslation();

  return (
    <div className="unexpected-error-page">
      <Col flex={1} span={24}>
        <span className="unexpected-error-page-title">{t('unexpected.title')}</span>
      </Col>
      <Col flex={1} span={24}>
        <span className="unexpected-error-page-description">
          <Trans i18nKey="unexpected.description" components={[<Link key={0} to={-1} />]} />
        </span>
      </Col>
      <Col flex={1} span={24}>
        <img src={PngUnexpectedError} alt="" className="unexpected-error-page-image" />
      </Col>
    </div>
  );
};

export default UnexpectedError;
