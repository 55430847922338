import { useShippingCompaniesQuery } from '@services/api/hooks';
import { useOrdersQuery } from '@services/api/hooks/useOrdersQuery';
import { usePaginatedDataSourcesQuery } from '@services/hooks/datasources/usePaginatedDataSourcesQuery';
import { useOrderItemsQuery } from '@services/hooks/orders/useOrderItemsQuery';
import { usePaginatedProductOffersQuery } from '@services/hooks/products/usePaginatedProductOffersQuery';
import { useProducts } from '@services/hooks/products/useProducts';
import get from 'lodash/get';

import { useCancellationRequestsQuery } from './api/useCancellationRequestsQuery';

export function useDataSource({ pagination, filters }) {
  const { cancellationRequests, total, isCancellationRequestsLoading } =
    useCancellationRequestsQuery({
      pagination,
      filters,
    });

  const { shippingCompanies } = useShippingCompaniesQuery();
  const { orders, isOrdersLoading } = useOrdersQuery({
    orderIds: cancellationRequests?.map(({ order }) => order),
    enabled: !!cancellationRequests?.length && !isCancellationRequestsLoading,
  });

  const { data: orderItems, isLoading: isOrderItemsLoading } = useOrderItemsQuery({
    orderItemIds: cancellationRequests?.map(({ order_item }) => order_item),
    enabled: !!cancellationRequests?.length && !isCancellationRequestsLoading,
  });

  const { data: productOffers, isLoading: isLoadingProductOffers } = usePaginatedProductOffersQuery(
    {
      ids: [...new Set(orderItems?.map(({ product_offer }) => product_offer))],
      enabled: !!orderItems?.length && !isOrderItemsLoading,
    }
  );

  const { data: products, isLoading: isProductsLoading } = useProducts({
    ids: [...new Set(productOffers?.map(({ product }) => product))],
    enabled: !!productOffers?.length && !isLoadingProductOffers,
  });

  const { data: dataSources, isLoading: isDataSourcesLoading } = usePaginatedDataSourcesQuery({
    ids: orders?.map(({ datasource }) => datasource),
  });

  const dataSource =
    orders?.length && dataSources?.length
      ? cancellationRequests?.map((request) => {
          const order = orders?.find((order) => order?.id === request?.order);
          const orderItem = orderItems?.find((orderItem) => orderItem?.id === request?.order_item);
          const datasource = dataSources?.find(
            (dataSource) => dataSource?.id === order?.datasource
          );
          const productOffer = productOffers?.find(
            (productOffer) => productOffer?.id === orderItem?.product_offer
          );
          const product = products?.find((product) => product?.id === productOffer?.product);
          const shippingCompany = shippingCompanies?.find(
            (shippingCompany) => shippingCompany?.slug === request?.easy_return?.shipping_company
          );
          const shippingCompanyName = get(shippingCompany, 'name', '');
          const cancellationCode = get(request, 'easy_return.code', '');
          const return_code = `${cancellationCode ? cancellationCode : ''}${shippingCompanyName ? `(${shippingCompanyName})` : ''}`;

          return {
            ...request,
            return_code,
            datasource,
            product,
            order,
            productOffer,
          };
        })
      : [];

  return {
    dataSource,
    isDataSourceLoading:
      isCancellationRequestsLoading ||
      isOrdersLoading ||
      isDataSourcesLoading ||
      isOrderItemsLoading ||
      isLoadingProductOffers ||
      isProductsLoading,
    total,
  };
}
