import { getProductCollectionWidgetsUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useQuery } from '@tanstack/react-query';
import first from 'lodash/first';

import { client } from '../client';

export function useProductCollectionWidgetQuery({ queryOptions, params } = {}) {
  const { data, isLoading } = useQuery({
    queryKey: [QueryKey.PRODUCT_COLLECTION_WIDGET, params],
    queryFn: () => client.get(getProductCollectionWidgetsUrl, { params }),
    ...queryOptions,
  });

  return {
    productCollectionWidget: first(data?.results),
    isProductCollectionWidgetLoading: isLoading,
  };
}
