import './index.scss';

import AkinonButton from '@components/AkinonButton';
import AkinonFormBuilder from '@components/AkinonFormBuilder';
import AkinonSpin from '@components/AkinonSpin';
import Box from '@components/utility/box/index';
import { reportingTypes } from '@constants/commontypes';
import { Form } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getStaticFormFields, ReportType } from './common';
import getDynamicFormFields from './getDynamicFormFields';
import getOnSubmit from './getOnSubmit';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { usePageMeta } from './hooks/usePageMeta';
import useSchemas from './hooks/useSchemas';

const SalesPerformance = () => {
  const { t } = useTranslation('Reporting');
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [reportType, setReportType] = useState(ReportType.SALES);

  const { orderSchema, salesSchema } = useSchemas();

  useBreadcrumbs();
  usePageMeta();

  const resetForm = () => {
    form.resetFields();
    setReportType(reportingTypes[0].value);
  };

  const onSubmit = getOnSubmit({
    t,
    reportType,
    setIsLoading,
    resetForm,
  });

  const getFormFields = () => {
    const currentSchema = reportType === ReportType.SALES ? salesSchema : orderSchema;
    const dynamicFormFields = getDynamicFormFields({
      t,
      currentSchema,
      form,
    });

    const staticFormFields = getStaticFormFields({ t });

    return [...staticFormFields, ...dynamicFormFields];
  };

  const handleReportTypeChange = (formValues) => {
    if (formValues.reportType) {
      setReportType(formValues.reportType);
    }
  };

  return (
    <AkinonSpin spinning={isLoading}>
      <Box className="box-primary form-box" title={t('reporting.header')}>
        <Form
          onValuesChange={handleReportTypeChange}
          className="akn-form akn-form-v2"
          onFinish={onSubmit}
          form={form}
          layout="vertical"
        >
          <AkinonFormBuilder
            meta={{
              columns: 4,
              colon: true,
              formItemLayout: null,
              fields: getFormFields(),
            }}
            form={form}
          />
          <Form.Item
            className="ant-col-md-24 ant-col-sm-24 ant-col-xs-24"
            wrapperCol={{ span: 18, offset: 0 }}
          >
            <AkinonButton htmlType="submit" type="primary" className="akn-submit-button">
              {t('submit')}
            </AkinonButton>
          </Form.Item>
        </Form>
      </Box>
    </AkinonSpin>
  );
};

export default SalesPerformance;
