import { getShippingOptionsByIdUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { client } from '@services/api/client';

export const useShippingMethodQuery = ({ shippingOptionId, queryOptions, params, onSuccess }) => {
  const { data, isLoading, error } = useAkinonQuery({
    queryKey: [QueryKey.SHIPPING_METHOD, params, shippingOptionId],
    async queryFn() {
      const response = await client.get(getShippingOptionsByIdUrl, {
        urlParams: { shippingOptionId },
      });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    shippingMethod: data,
    isShippingMethodLoading: isLoading,
    shippingMethodError: error,
  };
};
