import {
  deleteProductOffersUrl,
  getProductOffersUrl,
  getProductOfferUrl,
  getProductPricesUrl,
  getProductStockListUrl,
  getRejectionsUrl,
  patchProductOffersUrl,
  preOfferImagesUrl,
  preOffersUrl,
} from '@constants/apiUrls';
import { client } from '@services/api/client';

export const getProductOffers = (params) => {
  return client.get(getProductOffersUrl, { params });
};

export const getProductStockList = ({ requestConfig }) => {
  return client.get(getProductStockListUrl, requestConfig);
};

export const getProductPriceList = ({ requestConfig }) => {
  return client.get(getProductPricesUrl, requestConfig);
};

export const getProductOffer = ({ offerId, requestConfig }) => {
  return client.get(getProductOfferUrl, { ...requestConfig, urlParams: { offerId } });
};

export const getPreOffers = (params) => {
  return client.get(preOffersUrl, { params });
};

export const getPaginatedPreOfferImages = (params) => {
  return client.get(preOfferImagesUrl, { params });
};

export const getRejections = (params) => {
  return client.get(getRejectionsUrl, { params });
};

export const deleteProductOffers = ({ offerId, requestConfig }) => {
  return client.delete(deleteProductOffersUrl, { ...requestConfig, urlParams: { offerId } });
};

export const patchProductOffers = ({ offerId, requestBody }) => {
  return client.patch(patchProductOffersUrl, requestBody, {
    urlParams: { offerId },
  });
};
