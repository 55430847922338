import PropTypes from 'prop-types';
import React from 'react';

const Intrinsic = (props) => {
  return <i {...props} />;
};

Intrinsic.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
};

export default Intrinsic;
