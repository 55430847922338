import { getNotificationDetailUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { useAkinonQuery } from '../adapters/useAkinonQuery';
import { client } from '../client';

export function useNotificationQuery({ queryOptions, onSuccess, notificationId } = {}) {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.NOTIFICATIONS, notificationId],
    async queryFn() {
      const response = await client.get(getNotificationDetailUrl, {
        urlParams: {
          id: notificationId,
        },
      });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
    enabled: Boolean(notificationId),
  });

  return {
    notificationDetail: data,
    isFetchingNotificationDetail: isFetching,
  };
}
