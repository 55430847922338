import { AttributeDataType, FilterType } from '@constants/commontypes';

import { useAttributeValuesMapQueries } from './useAttributeValuesMapQueries';

export function useAttributeOptionsMapQueries({ attributes, queryOptions = {} }) {
  const queryOptionList = attributes
    .filter(
      (attribute) =>
        attribute?.filterType === FilterType.DYNAMIC &&
        attribute.data_type === AttributeDataType.DROPDOWN
    )
    .map((attribute) => ({ attribute: attribute?.id, ...queryOptions }));

  return useAttributeValuesMapQueries({
    queryOptionList,
  });
}
