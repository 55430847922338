import { useProductImagesQuery } from '@services/api/hooks/usePaginatedProductImagesQuery';
import first from 'lodash/first';

import { useConversationQuery } from './useConversationQuery';
import { useProductOffersQuery } from './useProductOffersQuery';

export function useConversationAndProductsQuery({ conversationId }) {
  const { conversation, isConversationFetching, isConversationLoading } = useConversationQuery({
    conversationId,
  });

  const { productOffers, isProductOffersFetching } = useProductOffersQuery({
    requestConfig: {
      params: {
        sku: conversation?.item_object?.sku,
      },
    },
    queryOption: {
      enabled: !!conversation?.item_object?.sku && !isConversationFetching,
    },
  });

  const { productImages } = useProductImagesQuery({
    params: {
      parent: first(productOffers)?.product,
    },
    queryOptions: {
      enabled: !isProductOffersFetching && Boolean(first(productOffers)?.product),
    },
  });

  return {
    conversation,
    productImages,
    isConversationLoading,
  };
}
