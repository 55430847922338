import { usePaginatedIntegrationAccessTokensQuery } from '@services/api/hooks/usePaginatedIntegrationAccessTokensQuery';
import moment from 'moment';

export const useDataSource = ({ pagination, filters, params }) => {
  const {
    integrationAccessTokens,
    isFetchingIntegrationAccessTokens,
    totalIntegrationAccessTokens,
  } = usePaginatedIntegrationAccessTokensQuery({
    pagination,
    filters,
    params,
  });

  const dataSource = integrationAccessTokens?.map((integrationAccessToken) => {
    const status = moment(integrationAccessToken?.valid_until).isAfter(moment());
    return {
      ...integrationAccessToken,
      status,
    };
  });

  return {
    dataSource,
    isDataSourceLoading: isFetchingIntegrationAccessTokens,
    total: totalIntegrationAccessTokens,
  };
};
