import useSWR from 'swr';

export const defaultSWRConfigOptions = {
  revalidateOnFocus: false,
  keepPreviousData: true,
};

/**
 * @deprecated Use Tanstack Query instead
 * @param {import('swr').Key} key
 * @param {import('swr').Fetcher} fetcher
 * @param {import('swr').SWRConfiguration} options
 */
export const useQuery = (key, fetcher, options) => {
  return useSWR(key, fetcher, { ...defaultSWRConfigOptions, ...options });
};

export * from './address';
export * from './attributes';
export * from './cancellation';
export * from './common';
export * from './customer';
export * from './datasources';
export * from './finance';
export * from './offer';
export * from './orders';
export * from './packages';
export * from './preOffers';
export * from './products';
export * from './shipping';
// TODO: Remove one of these.
export * from './user';
export * from './users';
