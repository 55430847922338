import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

import { SellerSegment } from '../SellerManagementList/common';

export const FormKey = {
  NAME: 'name',
  SUPPLIER_CODE: 'supplier_code',
  EMAIL: 'email',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  SUPPLIER_SEGMENT: 'supplier_segment',
  IS_ACTIVE: 'is_active',
  AUTO_APPROVE_ENABLED: 'auto_approve_enabled',
  STOCK_RELEASE_TYPE: 'stock_release_type',
};

export const getFormResolver = ({ t }) => {
  return zodResolver(
    z.object({
      [FormKey.NAME]: z.string().min(1),
      [FormKey.SUPPLIER_CODE]: z.string().min(1),
      [FormKey.EMAIL]: z.string().email(t('email.validation.message')),
      [FormKey.FIRST_NAME]: z.string().optional(),
      [FormKey.LAST_NAME]: z.string().optional(),
      [FormKey.SUPPLIER_SEGMENT]: z
        .union([z.literal(SellerSegment.STANDARD), z.literal(SellerSegment.PREMIUM)])
        .optional(),
      [FormKey.IS_ACTIVE]: z.boolean(),
      [FormKey.AUTO_APPROVE_ENABLED]: z.boolean(),
      [FormKey.STOCK_RELEASE_TYPE]: z.boolean(),
      conf: z.object({
        reconciliation_conf: z.object({
          creation_strategy: z
            .discriminatedUnion('strategy_type', [
              z.object({
                strategy_type: z.literal('daily'),
              }),
              z.object({
                strategy_type: z.literal('weekly'),
                day_of_week: z.coerce.number().min(0).max(7),
              }),
              z.object({
                strategy_type: z.literal('monthly'),
                day_of_month: z.coerce.number().min(1).max(31),
              }),
            ])
            .and(
              z.object({
                time_zone: z.coerce
                  .number()
                  .min(-11, {
                    message: t('formRule.min', {
                      amount: -11,
                    }),
                  })
                  .max(14, {
                    message: t('formRule.max', {
                      amount: 14,
                    }),
                  })
                  .optional(),
              })
            ),
          item_selection_strategy: z.object({
            strategy_type: z.union([z.literal('shipped_date'), z.literal('delivered_date')]),
            days_later: z.coerce.number().int().min(1),
          }),
        }),
      }),
    })
  );
};
export const DatasourcePermissionLevel = {
  EMPLOYEE: 0,
  MANAGER: 1,
  ADMIN: 2,
};
