import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { createQuickReply, updateQuickReply } from '@services/api/orders/message';

export function useCreateOrUpdateQuickReplyMutation({ mutationOptions = {}, isUpdate }) {
  const { mutate: createOrUpdateQuickReply, isLoading: isCreatingOrUpdatingQuickReply } =
    useAkinonMutation({
      mutationFn: isUpdate ? updateQuickReply : createQuickReply,
      ...mutationOptions,
    });

  return {
    createOrUpdateQuickReply,
    isCreatingOrUpdatingQuickReply,
  };
}
