import './styles.scss';

import Link from '@components/Link';
import { Col, Row, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const { Title, Text } = Typography;

const StyledIconBox = styled(Col)`
  background-color: ${(props) => props.color};
`;

/**
 * @typedef {{ icon: import('react').ElementType, title: string, description: string, backgroundColor: string, to: string, isVisible: boolean }} NavigationCardProps
 */

/**
 * @param {NavigationCardProps} props
 */
const NavigationCard = ({ icon, title, description, backgroundColor, to }) => {
  return (
    <Link to={to}>
      <Row className="navigation-card">
        <StyledIconBox
          data-testid="navigation-card-icon-box"
          span={4}
          color={backgroundColor}
          className="navigation-card__icon-box"
        >
          {icon}
        </StyledIconBox>
        <Col span={20} className="navigation-card__page-info">
          <Title className="navigation-card__page-title" level={2}>
            {title}
          </Title>
          <Text className="navigation-card__page-description">{description}</Text>
        </Col>
      </Row>
    </Link>
  );
};

NavigationCard.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  to: PropTypes.string.isRequired,
};

NavigationCard.defaultProps = {
  isVisible: true,
};

export default NavigationCard;
