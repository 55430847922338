import { zodResolver } from '@hookform/resolvers/zod';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import reduce from 'lodash/reduce';
import * as z from 'zod';

import { DataIndex } from '../../SsoProviderList/common';

export const FormKey = {
  ...DataIndex,
  CLIENT_SECRET: 'client_secret',
  ICON: 'icon',
};

export const getFormResolver = ({ providerOptions, isEditing }) => {
  return zodResolver(
    z.object({
      [FormKey.NAME]: z.string().min(1),
      [FormKey.SLUG]: z.string().min(1),
      [FormKey.PROVIDER]: z.union(providerOptions?.map((provider) => z.literal(provider.value))),
      [FormKey.CLIENT_ID]: z.string().min(1),
      [FormKey.CLIENT_SECRET]: isEditing ? z.string().nullish() : z.string().min(1),
      [FormKey.AUTHORIZATION_URL]: z.string().min(1),
      [FormKey.TOKEN_URL]: z.string().min(1),
      [FormKey.USER_INFO_URL]: z.string().min(1),
      [FormKey.REDIRECT_URL]: z.string().min(1),
      [FormKey.ADDITIONAL_ARGS]: z
        .array(
          z.object({
            key: z.string().min(1),
            value: z.string().min(1),
          })
        )
        .nullish()
        .optional(),
      [FormKey.ICON]: z.object({
        fileList: z
          .array(
            z.object({ url: z.string().optional(), originFileObj: z.instanceof(File).optional() })
          )
          .nullish(),
      }),
      [FormKey.IS_ACTIVE]: z.boolean(),
    })
  );
};

export const getInitialValues = (provider) => {
  if (!provider) return { is_active: true };
  const kwargs = Object.entries(provider?.[FormKey.ADDITIONAL_ARGS] ?? {}).map(([key, value]) => {
    return { key, value };
  });

  const icon = provider[FormKey.ICON]
    ? {
        fileList: [
          {
            uid: '-1',
            name: provider[FormKey.ICON].split('/').at(-1),
            status: 'done',
            url: provider[FormKey.ICON],
          },
        ],
      }
    : [];

  return {
    ...provider,
    kwargs,
    icon,
  };
};

export const getRequestBodyFromFormValues = ({ formValues }) => {
  const formData = new FormData();
  const _formValues = omitBy(formValues, (val) => isNil(val) || val === '');

  if (_formValues[FormKey.ICON]?.fileList?.at(-1)?.originFileObj) {
    formData.append(FormKey.ICON, _formValues[FormKey.ICON]?.fileList?.at(-1)?.originFileObj);
  }

  delete _formValues[FormKey.ICON];

  Object.entries(_formValues).forEach(([key, value]) => {
    formData.append(
      key,
      key === FormKey.ADDITIONAL_ARGS
        ? JSON.stringify(
            reduce(
              _formValues[key],
              (acc, { key, value }) => {
                return { ...acc, [key]: value };
              },
              {}
            )
          )
        : String(value)
    );
  });

  return formData;
};
