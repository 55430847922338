import useStore from '@zustand-store/index';
import { setPageMetaSelector } from '@zustand-store/selectors/app';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

export function usePageMeta({ toggleIsBulkUpdatePackagesActive }) {
  const { t } = useTranslation('Orders');
  const setPageMeta = useStore(setPageMetaSelector);

  useEffect(() => {
    setPageMeta({
      title: t('packages_waiting_for_shipment'),
      description: t('packages_waiting_for_shipment_desc'),
      actions: [
        {
          label: t('bulk_package_info_update'),
          type: 'primary',
          onClick: toggleIsBulkUpdatePackagesActive,
        },
      ],
    });
  }, [t]);

  useUnmount(() => {
    setPageMeta({});
  });
}
