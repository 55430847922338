import './styles.scss';

import { Button } from 'antd';
import clsx from 'clsx';
import React from 'react';

/**
 * @param {import('antd').ButtonProps} props
 */
const AkinonButton = ({ children, className, type = 'primary', ...rest }) => {
  return (
    <Button className={clsx('akinon-button', className)} type={type} {...rest}>
      {children}
    </Button>
  );
};

export default AkinonButton;
