import Box from '@components/utility/box';
import { RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { Button, Col, Divider, Row } from 'antd';
import isEmpty from 'lodash/isEmpty';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { getOrderInformations } from '../common';

export const OrderInformationBox = ({ conversation, order }) => {
  const { t } = useTranslation('Messages');
  const navigate = useAppNavigate();
  const orderInformations = getOrderInformations({ t, conversation, order });

  const handleClick = () => {
    navigate(RouteUrls.order.orderDetail, {
      orderId: order?.id,
    });
  };

  return (
    <Box className="box-primary form-box" title={t('orders.info')}>
      <Divider />
      {orderInformations?.map((orderInformation, index) => {
        return (
          <Fragment key={index}>
            <Row className="order-information-row">
              <Col span={12}>
                <b>{orderInformation.name}</b>
              </Col>
              <Col className="order-information-value" span={12}>
                {orderInformation.value}
              </Col>
            </Row>
            <Divider />
          </Fragment>
        );
      })}
      <div className="go-detail-btn-block">
        <Button className="go-detail-btn" onClick={handleClick} disabled={isEmpty(order)}>
          {t('view.order.detail')}
        </Button>
      </div>
    </Box>
  );
};
