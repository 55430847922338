import { postRejectProductRevisionUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { client } from '@services/api/client';

export function usePostRejectProductRevisionMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ productRevisionId, requestBody }) =>
      client.post(postRejectProductRevisionUrl, requestBody, {
        urlParams: { productRevisionId },
      }),
    ...mutationOptions,
  });

  return {
    rejectProductRevision: mutate,
    isRejectingProductRevision: isLoading,
  };
}
