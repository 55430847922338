import { widgetsUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { client } from '@services/api/client';
import { useQuery } from '@tanstack/react-query';
import first from 'lodash/first';

export function useWidgetQuery({ queryOptions, params } = {}) {
  const { data, isLoading, isFetching } = useQuery({
    queryKey: [QueryKey.WIDGET, params],
    queryFn: () => client.get(widgetsUrl, { params }),
    ...queryOptions,
  });
  return {
    widget: first(data?.results),
    isWidgetLoading: isLoading,
    isWidgetFetching: isFetching,
  };
}
