import { openNotification } from '@components/akinonNotification';
import { useDynamicColumnsAttributeValues } from '@pages/ProductsAndCategories/hooks/useDynamicColumnsAttributeValues';
import { OfferStatus } from '@pages/ProductsAndCategories/OfferList/common';
import { useMasterProductPricesQuery } from '@services/api/hooks/useMasterProductPricesQuery';
import { usePaginatedDataSourcesQuery } from '@services/api/hooks/usePaginatedDataSourcesQuery';
import { usePreOfferImagesQuery } from '@services/api/hooks/usePaginatedPreOfferImagesQuery';
import { usePaginatedPreOffersQuery } from '@services/api/hooks/usePaginatedPreOffersQuery';
import { useProductsQuery } from '@services/api/hooks/usePaginatedProductsQuery';
import { useRejectionReasonsQuery } from '@services/api/hooks/useRejectionReasonsQuery';
import { useRejectionsQuery } from '@services/api/hooks/useRejectionsQuery';
import { useCategoryTreeData } from '@utils/hooks/useCategoryTreeData';
import { getTreeNodeByValue } from '@utils/index';
import entries from 'lodash/entries';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import uniq from 'lodash/uniq';
import { useTranslation } from 'react-i18next';

export const useDataSource = ({ dynamicFormMetaFields, pagination, filters }) => {
  const { t } = useTranslation('ProductsAndCategories');
  const { preOffers, totalPreOffers, preOffersError, isFetchingPreOffers } =
    usePaginatedPreOffersQuery({
      pagination,
      filters,
      params: {
        approvalStatus: OfferStatus.rejected,
      },
      onError: (error) => {
        openNotification({
          message: t('filtering_unsuccessful'),
          description: error.response?.data?.detail ?? error.message,
          type: 'error',
          t,
        });
      },
    });

  const datasourceIds = uniq(preOffers?.map((item) => item.datasource)).filter(Boolean);
  const { dataSources, isFetchingDataSources } = usePaginatedDataSourcesQuery({
    params: {
      id__in: datasourceIds.join(','),
    },
    queryOptions: {
      enabled: !isFetchingPreOffers && !isEmpty(datasourceIds),
    },
  });

  const productIds = uniq(preOffers?.map(({ product }) => product)).filter(Boolean);
  const { products, isFetchingProducts } = useProductsQuery({
    params: {
      id__in: productIds.join(','),
    },
    queryOptions: {
      enabled: !isFetchingPreOffers && !isEmpty(productIds),
    },
  });

  const { masterProductPrices } = useMasterProductPricesQuery({
    params: {
      limit: productIds?.length,
      product__in: productIds?.join(','),
    },
    queryOptions: {
      enabled: !isEmpty(productIds) && !isFetchingPreOffers,
    },
  });

  const preOfferIds = uniq(preOffers?.map(({ id }) => id)).filter(Boolean);
  const { preOfferImages, isFetchingPreOfferImages } = usePreOfferImagesQuery({
    params: {
      limit: preOfferIds?.length,
      parent__in: preOfferIds.join(','),
      order: 0,
    },
    queryOptions: {
      enabled: !isFetchingPreOffers && !isEmpty(preOfferIds),
    },
  });
  const { rejections, isFetchingRejections } = useRejectionsQuery({
    params: {
      limit: preOfferIds?.length,
      parent__in: preOfferIds.join(','),
    },
    queryOptions: {
      enabled: !isFetchingPreOffers && !isEmpty(preOfferIds),
    },
  });

  const reasonIds = uniq(rejections?.map(({ reason }) => reason)).filter(Boolean);
  const { rejectionReasons, isFetchingRejectionReasons } = useRejectionReasonsQuery({
    params: {
      id__in: reasonIds.join(','),
      limit: reasonIds?.length,
    },
    queryOptions: {
      enabled: !isFetchingRejections && !isEmpty(reasonIds),
    },
  });
  const { attributeValues, isFetchingAttributeValues } = useDynamicColumnsAttributeValues({
    dynamicFormMetaFields,
  });
  const { categoryTreeData, isCategoriesFetching } = useCategoryTreeData({
    queryOptions: {
      enabled: !isFetchingPreOffers,
    },
  });

  const isLoading =
    isFetchingAttributeValues ||
    isFetchingDataSources ||
    isFetchingPreOfferImages ||
    isCategoriesFetching ||
    isFetchingProducts ||
    isFetchingRejectionReasons;

  // Map related data.
  const data = preOffers?.map((item) => {
    const datasource = dataSources?.find(({ id }) => id === item.datasource);
    const product = products?.find(({ id }) => id === item?.product);
    const image = preOfferImages?.find(({ parent }) => parent === item?.id);
    const category = getTreeNodeByValue({ tree: categoryTreeData, nodeValue: item?.category });

    const rejection = rejections?.find(({ parent }) => parent === item?.id);
    const reason = rejectionReasons?.find(({ id }) => id === rejection?.reason);
    const masterProductPrice = masterProductPrices?.find(
      ({ product }) => product === item?.product
    )?.price;
    const attributes = reduce(
      entries(item?.attributes),
      (acc, [key, value]) => {
        const attributeOptions = attributeValues?.[key] ?? [];
        const valueLabel = get(find(attributeOptions, { value }), 'label', value);
        return {
          ...acc,
          [key]: valueLabel,
        };
      },
      {}
    );

    return {
      ...item,
      attributes,
      buybox_price: masterProductPrice,
      datasource,
      image,
      category,
      product,
      reason,
      rejection,
    };
  });

  return {
    data,
    total: totalPreOffers,
    isLoading,
    error: preOffersError,
  };
};
