import { useTranslation } from 'react-i18next';

import { StaticFilterKey } from '../common';

export const useStaticFilters = ({ isPending }) => {
  const { t } = useTranslation('Marketing');
  return {
    staticFiltersFormMetaFields: [
      {
        key: StaticFilterKey.NAME,
        placeholder: t('campaign_name'),
      },
    ],
    isStaticFiltersLoading: isPending,
  };
};
