export const ALLOWED_FILE_TYPES = '.csv';
export const ALLOWED_OFFER_FILE_TYPES = '.csv,.xlsx,.xls';

export const BulkTemplateFileName = {
  PRICES: 'bulk-product-prices-template.xlsx',
  STOCKS: 'bulk-product-stocks-template.xlsx',
  OFFERS: 'bulk-product-offers-template.csv',
  ATTRIBUTE: 'bulk-product-attributes-template.xlsx',
};

export const BulkImportTypes = {
  OFFER: 'OFFER',
  PACKAGE: 'PACKAGE',
  PRICE: 'PRICE',
  STOCK: 'STOCK',
  OFFER_STATUS: 'OFFER_STATUS',
};

export const antdProgressStatus = {
  active: 'active',
  exception: 'exception',
  normal: 'normal',
  success: 'success',
};

export const FormField = {
  FILE: 'file',
  ATTRIBUTE_SET: 'attribute_set',
  ID: 'id',
  ATTRIBUTE: 'attribute',
};
