import { getPathFragments } from '@common/routing';
import useStore from '@zustand-store/index';
import { pageDataSelector, setPageDataSelector } from '@zustand-store/selectors/pageData';
import { useLocation } from 'react-router-dom';

export default function usePageData() {
  const { pathname, search } = useLocation();
  const { mainPath } = getPathFragments(pathname);
  const storePageData = useStore(pageDataSelector);
  const setStorePageData = useStore(setPageDataSelector);
  const pageData = storePageData?.[`${mainPath}${search}`] ?? {};

  return {
    pageData,
    setPageData: (newPageData) =>
      setStorePageData({
        pagePath: `${mainPath}${search}`,
        pageData: { ...pageData, ...newPageData },
      }),
  };
}
