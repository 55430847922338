import { useBillingAddressQuery } from '@services/api/hooks/useBillingAddressQuery';
import { useCityQuery } from '@services/api/hooks/useCityQuery';
import { useCountryQuery } from '@services/api/hooks/useCountryQuery';
import { useDataSourceDetailQuery } from '@services/api/hooks/useDataSourceDetailQuery';
import { useDistrictQuery } from '@services/api/hooks/useDistrictQuery';
import { useGenerateLabelQuery } from '@services/api/hooks/useGenerateLabelQuery';
import { useOrderDetailQuery } from '@services/api/hooks/useOrderDetailQuery';
import { usePackageDetailQuery } from '@services/api/hooks/usePackageDetailQuery';
import { usePackageStatusesQuery } from '@services/api/hooks/usePackageStatusesQuery';
import { usePaginatedPackagesQuery } from '@services/api/hooks/usePaginatedPackagesQuery';
import { useTownshipQuery } from '@services/api/hooks/useTownshipQuery';
import isEmpty from 'lodash/isEmpty';

export function usePackageInfo({ t, packageId }) {
  const { packageDetail } = usePackageDetailQuery({
    packageId,
    queryOptions: { enabled: !isEmpty(packageId) },
  });

  const { packages } = usePaginatedPackagesQuery({
    params: {
      order: packageDetail?.order,
    },
    queryOptions: {
      enabled: !isEmpty(packageDetail?.order),
    },
  });

  const { refetchGenerateLabel } = useGenerateLabelQuery({
    packageId,
    queryOptions: {
      enabled: false,
    },
  });

  const { orderDetail } = useOrderDetailQuery({
    orderId: packageDetail?.order,
    queryOptions: {
      enabled: !isEmpty(packageDetail),
    },
  });

  const { dataSourceDetail: sellerDetail } = useDataSourceDetailQuery({
    dataSourceId: orderDetail?.datasource,
    queryOptions: {
      enabled: !isEmpty(orderDetail),
    },
  });

  const { billingAddress } = useBillingAddressQuery({
    billingAddressId: orderDetail?.billing_address,
    queryOptions: {
      enabled: !isEmpty(orderDetail),
    },
  });

  const { country: billingCountry, isCountryLoading: isBillingCountryLoading } = useCountryQuery({
    countryId: billingAddress?.country,
    queryOptions: {
      enabled: !isEmpty(billingAddress),
    },
  });
  const { city: billingCity, isCityLoading: isBillingCityLoading } = useCityQuery({
    cityId: billingAddress?.city,
    queryOptions: {
      enabled: !isEmpty(billingAddress),
    },
  });
  const { township: billingTownship, isTownshipLoading: isBillingTownshipLoading } =
    useTownshipQuery({
      townshipId: billingAddress?.township,
      queryOptions: {
        enabled: !isEmpty(billingAddress),
      },
    });
  const { district: billingDistrict, isDistrictLoading: isBillingDistrictLoading } =
    useDistrictQuery({
      districtId: billingAddress?.district,
      queryOptions: {
        enabled: !isEmpty(billingAddress),
      },
    });

  const { packageStatuses } = usePackageStatusesQuery();

  const packageStatusChoices =
    packageStatuses?.actions?.POST?.status?.choices
      ?.map((choice) => {
        const display_name = t(choice.display_name?.toString().replaceAll(' ', '.').toLowerCase());
        return {
          [choice.value]: display_name,
        };
      })
      ?.reduce((acc, cur) => ({ ...acc, ...cur }), {}) ?? {};

  const packageInfo = {
    billingCountry,
    billingCity,
    billingDistrict,
    billingTownship,
  };

  const isPackageInfoLoading =
    isBillingCountryLoading ||
    isBillingCityLoading ||
    isBillingDistrictLoading ||
    isBillingTownshipLoading;

  return {
    packageInfo,
    packageDetail,
    packages,
    orderDetail,
    sellerDetail,
    billingAddress,
    isPackageInfoLoading,
    refetchGenerateLabel,
    packageStatuses: packageStatusChoices,
  };
}
