import './style.scss';

import { Popover } from 'antd';
import classnames from 'classnames';
import React from 'react';

const PopoverWrapper = (Component) => (props) => {
  const className = classnames(props.className, 'popoverWrapper');
  return <Component {...props} className={className} />;
};

export default PopoverWrapper(Popover);
