import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { getDataSourcePermissions } from '@services/api/datasources';
import { useQuery } from '@tanstack/react-query';

/**
 * @typedef {import('@tanstack/react-query').UseQueryResult} UseQueryResult
 * @returns {import('@tanstack/react-query').UseQueryResult}
 */

export const useDataSourcePermissions = ({ dataSourceId, pagination, filters, enabled = true }) => {
  const queryKey = ['data-source-permissions', dataSourceId ?? '', pagination, filters];
  const queryFn = () =>
    getDataSourcePermissions(dataSourceId, {
      ...getRequestPaginationParams(pagination),
      ...getRequestFiltersParams(filters),
    });
  const { data, isLoading, isFetching, error, ...rest } = useQuery({ queryKey, queryFn, enabled });

  return {
    ...rest,
    data,
    isLoading,
    isFetching,
    error,
    total: data?.length || 0,
  };
};
