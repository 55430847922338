import './styles.scss';

import Show from '@components/Show';
import { Color } from '@constants/theme';
import isUndefined from 'lodash/isUndefined';
import PropTypes from 'prop-types';
import React from 'react';

import NavigationCard from '../Card';

/**
 * @typedef {import('../Card').NavigationCardProps & { isVisible: boolean }} NavigationListItem
 */

/**
 * @param {{ items: NavigationListItem[], cardColor: string }} props
 */
const NavigationList = ({ items, cardColor }) => {
  return (
    <div className="navigation-list">
      {items.map((item) => (
        <Show when={isUndefined(item.isVisible) || item.isVisible} key={item.title}>
          <div span={8} className="navigation-list__item">
            <NavigationCard backgroundColor={item.backgroundColor ?? cardColor} {...item} />
          </div>
        </Show>
      ))}
    </div>
  );
};

NavigationList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(NavigationCard.propTypes)).isRequired,
};

NavigationList.defaultProps = {
  cardColor: Color.AFFAIR,
};

export default NavigationList;
