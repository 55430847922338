import Link from '@components/Link';
import { RouteUrls } from '@constants/routeUrls';
import useStore from '@zustand-store/index';
import { setBreadcrumbsSelector } from '@zustand-store/selectors/app';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

export function useBreadcrumbs() {
  const { t } = useTranslation('Settings');
  const setBreadcrumbs = useStore(setBreadcrumbsSelector);

  useEffect(() => {
    document.title = t('sso_settings.title');
    setBreadcrumbs([
      <Link key={RouteUrls.settings.landing} to={RouteUrls.settings.landing}>
        {t('settings')}
      </Link>,
      t('sso_settings.title'),
    ]);
  }, [t]);

  useUnmount(() => {
    document.title = 'Akinon';
    setBreadcrumbs([]);
  });
}
