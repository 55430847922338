import { useMasterProductPricesQuery } from '@services/api/hooks/useMasterProductPricesQuery';
import { useAttributeConfigsQuery } from '@services/hooks/attributes/useAttributeConfigsQuery';
import { useProductAttributesQuery } from '@services/hooks/attributes/useProductAttributesQuery';
import { usePriceListsQuery } from '@services/hooks/common/usePriceListsQuery';
import { useStockListsQuery } from '@services/hooks/common/useStockListsQuery';
import { useDataSourcesQuery } from '@services/hooks/datasources/useDataSourcesQuery';
import { useProductMetaQuery } from '@services/hooks/products/useProductMetaQuery';
import { useProductQuery } from '@services/hooks/products/useProductQuery';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router-dom';

export function useQueries() {
  const { productId } = useParams();
  const { product } = useProductQuery({ productId });
  const { productMeta } = useProductMetaQuery({
    metaId: product?.meta,
    queryOptions: { enabled: !isEmpty(product) },
  });
  const { productAttributes } = useProductAttributesQuery();
  const attributeSetId = product?.attribute_set;
  const { attributeConfigs } = useAttributeConfigsQuery({
    attributeSetId,
    queryOptions: { enabled: Boolean(attributeSetId) },
  });
  const { priceLists } = usePriceListsQuery();
  const { stockLists } = useStockListsQuery();
  const { dataSourcesResult: dataSources } = useDataSourcesQuery();

  const { masterProductPrices } = useMasterProductPricesQuery({
    params: {
      limit: 1,
      product__in: product?.id,
    },
    queryOptions: {
      enabled: !isEmpty(product),
    },
  });

  return {
    product,
    attributeConfigs,
    dataSources,
    priceLists,
    productAttributes,
    attributeSetId,
    stockLists,
    productMeta,
    buyboxPrice: first(masterProductPrices)?.price,
  };
}
