import AkinonCodeEditor from '@components/AkinonCodeEditor';
import AkinonDatePicker from '@components/AkinonDatePicker';
import AkinonDragger from '@components/AkinonDragger';
import AkinonInput, { AkinonPasswordInput, AkinonTextArea } from '@components/AkinonInput';
import AkinonSelect from '@components/AkinonSelect';
import AkinonTreeSelect from '@components/AkinonTreeSelect';
import { WidgetType } from '@constants/commontypes';
import { InputNumber } from 'antd';
import { cloneDeep } from 'lodash';

function customizeSelect({ field }) {
  field.widget = (props) => {
    return <AkinonSelect aria-label={field?.label} options={field?.options} {...props} />;
  };

  return field;
}

function customizeTreeSelect({ field }) {
  field.widget = (props) => {
    return <AkinonTreeSelect aria-label={field?.label} options={field?.options} {...props} />;
  };

  return field;
}

function customizeDatePicker({ field }) {
  field.widget = (props) => {
    return <AkinonDatePicker aria-label={field?.label} {...props} />;
  };

  return field;
}

function customizeInput({ field }) {
  field.widget = (props) => {
    return <AkinonInput aria-label={field?.label} {...props} />;
  };

  return field;
}

function customizePasswordInput({ field }) {
  field.widget = (props) => {
    return <AkinonPasswordInput aria-label={field?.label} {...props} />;
  };

  return field;
}

function customizeTextArea({ field }) {
  field.widget = (props) => {
    return <AkinonTextArea aria-label={field?.label} {...props} />;
  };

  return field;
}

function customizeFileUpload({ field }) {
  field.widget = (props) => {
    return <AkinonDragger aria-label={field?.label} {...props} />;
  };

  return field;
}

function customizeCodeEditor({ field }) {
  field.widget = (props) => {
    return <AkinonCodeEditor aria-label={field?.label} {...props} />;
  };

  return field;
}

function customizeNumberInput({ field }) {
  field.widget = (props) => {
    return <InputNumber aria-label={field?.label} {...props} />;
  };

  return field;
}

const customizers = [
  {
    widgetType: WidgetType.SELECT,
    customize: customizeSelect,
  },
  {
    widgetType: WidgetType.TREESELECT,
    customize: customizeTreeSelect,
  },
  {
    widgetType: WidgetType.DATE_PICKER,
    customize: customizeDatePicker,
  },
  {
    widgetType: WidgetType.INPUT,
    customize: customizeInput,
  },
  {
    widgetType: WidgetType.TEXTAREA,
    customize: customizeTextArea,
  },
  {
    widgetType: WidgetType.PASSWORD,
    customize: customizePasswordInput,
  },
  {
    widgetType: WidgetType.NUMBER,
    customize: customizeNumberInput,
  },
  {
    widgetType: WidgetType.FILE,
    customize: customizeFileUpload,
  },
  { widgetType: WidgetType.CODE, customize: customizeCodeEditor },
];

const matchCustomizer = (field, customizer) => {
  return field.widget === customizer.widgetType;
};

export function customizeMetaFields({ meta }) {
  const customizedFields = cloneDeep(meta);
  const fields = Array.isArray(customizedFields) ? customizedFields : customizedFields?.fields;
  if (!fields) return customizedFields;

  fields.map((field) => {
    const customizer = customizers.find((customizer) => matchCustomizer(field, customizer));
    if (customizer) return customizer.customize({ field });
    else return field;
  });
  return customizedFields;
}
