import { AttributeDataType, limitQuery } from '@constants/commontypes';
import { useAttributeSetQuery } from '@services/api/hooks/useAttributeSetQuery';
import { useAttributesQuery } from '@services/api/hooks/useAttributesQuery';
import { useAttributeValuesQuery } from '@services/api/hooks/useAttributeValuesQuery';
import isEmpty from 'lodash/isEmpty';

export const useProductDetail = ({ product, shouldFetch }) => {
  const { attributeSet, isFetchingAttributeSet } = useAttributeSetQuery({
    attributeSetId: product?.product?.attribute_set,
    queryOptions: {
      enabled: shouldFetch,
    },
  });

  const attributeIds = attributeSet?.attributes ?? [];

  const { attributes, isFetchingAttributes } = useAttributesQuery({
    params: {
      attribute__in: attributeIds.join(','),
      ...limitQuery,
    },
    queryOptions: {
      enabled: !isFetchingAttributeSet && shouldFetch,
    },
  });

  const productAttributes = attributes?.filter(
    (attribute) => product?.product?.attributes && attribute.key in product.product.attributes
  );

  const productAttributeIds = productAttributes
    ?.filter((attribute) => attribute.data_type === AttributeDataType.DROPDOWN)
    ?.map((attribute) => attribute.id);

  const { attributeValues, isFetchingAttributeValues } = useAttributeValuesQuery({
    params: {
      attribute__in: productAttributeIds?.join(','),
      ...limitQuery,
    },
    queryOptions: {
      enabled: !isEmpty(productAttributeIds) && shouldFetch && !isFetchingAttributes,
    },
  });

  const productDetailAttributes = productAttributes?.map((productAttribute) => {
    const attribute = attributeValues
      ?.filter((attributeValue) => attributeValue.attribute === productAttribute.id)
      .reduce((acc, attributeValue) => {
        if (attributeValue.value === product?.product?.attributes[productAttribute.key]) {
          return {
            ...acc,
            ...attributeValue,
          };
        }
        return acc;
      }, {});

    return {
      ...productAttribute,
      attribute_value: attribute?.value ?? product?.product?.attributes[productAttribute.key],
      attribute_label: attribute?.label,
    };
  }, {});

  const productDetail = {
    ...product,
    product: {
      ...product?.product,
      attributes: productDetailAttributes,
    },
  };

  return {
    productDetail,
    isProductDetailLoading:
      isFetchingAttributeValues || isFetchingAttributes || isFetchingAttributeSet,
  };
};
