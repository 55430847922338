import { updateCategoryAttributeSetUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { client } from '@services/api/client';

export function usePutCategoryAttributeSetMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ categoryId, requestBody }) =>
      client.put(updateCategoryAttributeSetUrl, requestBody, {
        urlParams: { categoryId },
      }),
    ...mutationOptions,
  });

  return {
    putCategoryAttributeSet: mutate,
    isPuttingCategoryAttributeSet: isLoading,
  };
}
