import './index.scss';

import { PluginRenderer } from '@akinon/app-shell';
import { createSelectOptions } from '@common/index';
import AkinonForm from '@components/AkinonForm';
import AkinonFormItem from '@components/AkinonFormItem';
import AkinonInput from '@components/AkinonInput';
import { openNotification } from '@components/akinonNotification';
import AkinonSelect from '@components/AkinonSelect';
import AkinonTreeSelect from '@components/AkinonTreeSelect';
import DynamicFormElement from '@components/DynamicFormElement';
import { QuillEditor } from '@components/QuillEditor';
import Show from '@components/Show';
import TitleWithHint from '@components/TitleWithHint';
import Box from '@components/utility/box';
import { UserRole } from '@constants/auth';
import { editableOfferApprovalStatuses, OfferApprovalStatus } from '@constants/commontypes';
import { verticalFormItemLayout } from '@constants/layoutTypes';
import i18n from '@root/i18n';
import { useCreateProductMutation } from '@services/api/hooks/useCreateProductMutation';
import { useDynamicSettingsQuery } from '@services/api/hooks/useDynamicSettingsQuery';
import { useApprovePreOfferMutation } from '@services/hooks/preOffers/useApprovePreOfferMutation';
import { usePatchPreOffersMutation } from '@services/hooks/preOffers/usePatchPreOffersMutation';
import { useSubmitPreOfferMutation } from '@services/hooks/preOffers/useSubmitPreOfferMutation';
import { useOptionsProductPricesQuery } from '@services/hooks/products/useOptionsProductPricesQuery';
import { useCategoryTreeData } from '@utils/hooks/useCategoryTreeData';
import { useUserRole } from '@utils/hooks/useUserRole';
import useStore from '@zustand-store/index';
import { resetPreOfferSelector } from '@zustand-store/selectors/preOffer';
import { Col, Space } from 'antd';
import first from 'lodash/first';
import get from 'lodash/get';
import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useBoolean, useUnmount } from 'react-use';

import { DataType, DataTypeWidgetMap, FormKey, getOfferDetailFormResolver } from './common';
import CreateProductModal from './components/CreateProductModal';
import ProductImageUpload from './components/ProductImageUpload';
import RejectModal from './components/RejectModal';
import RelatedProductsTable from './components/RelatedProductsTable';
import SubmitForm from './components/SubmitForm';
import WithdrawModal from './components/WithdrawModal';
import { useQueries } from './hooks/api/useQueries';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { useOnSubmit } from './hooks/useOnSubmit';
import { usePageMeta } from './hooks/usePageMeta';

const OfferDetail = () => {
  const { t } = useTranslation('ProductsAndCategories');
  const { offerId } = useParams();

  const { categoryTreeData, isCategoriesLoading } = useCategoryTreeData();

  const { productPricesOptions, isProductPricesOptionsLoading } = useOptionsProductPricesQuery();
  const currencyOptions = createSelectOptions(
    productPricesOptions?.actions?.POST.currency_type.choices,
    {
      valueKey: 'value',
      labelKey: 'display_name',
    }
  );

  const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
  const [isCreateProductModalVisible, setIsCreateProductModalVisible] = useState(false);
  const [isWithdrawModalVisible, setIsWithdrawModalVisible] = useBoolean(false);
  const userRole = useUserRole();
  const { patchPreOffers, isPatchPreOffersMutating } = usePatchPreOffersMutation();
  const { submitPreOffer, isSubmitPreOfferMutating } = useSubmitPreOfferMutation();
  const { approvePreOffer, isApprovePreOfferMutating } = useApprovePreOfferMutation();
  const resetPreOffer = useStore(resetPreOfferSelector);
  const {
    preOffer,
    dataSources,
    categories,
    priceLists,
    stockLists,
    buyboxPrice,
    lowestPrice,
    dynamicFields,
  } = useQueries({ t });

  const [variantDynamicFields, variantListAttributeFields] = dynamicFields;

  const isSuperUser = userRole === UserRole.SUPER_USER;

  const canEditOffer =
    isSuperUser || editableOfferApprovalStatuses.includes(preOffer?.approval_status);

  const { createProduct, isCreatingProduct } = useCreateProductMutation({
    mutationOptions: {
      onSuccess() {
        openNotification({
          message: t('operation_successful'),
          description: t('product_created_successfully'),
          type: 'success',
        });
      },
      onError() {
        openNotification({
          message: t('an_error_occurred'),
          description: t('product_created_error'),
          type: 'error',
        });
      },
      onSettled() {
        setIsCreateProductModalVisible(false);
      },
    },
  });
  const isPendingApproval = preOffer?.approval_status === OfferApprovalStatus.PENDING_APPROVAL;
  const isSeller = userRole === UserRole.SELLER;
  const canWithdrawOffer = isPendingApproval && isSeller;

  const onSubmit = useOnSubmit({
    preOffer,
    priceLists,
    stockLists,
    dataSources,
    patchPreOffers,
    submitPreOffer,
    approvePreOffer,
    setIsRejectModalVisible,
    isSuperUser,
    variantListAttributeFields,
  });

  useUnmount(() => {
    resetPreOffer();
  });

  const showCreateProductModal = () => {
    setIsCreateProductModalVisible(true);
  };

  useBreadcrumbs();
  usePageMeta({ showCreateProductModal, canWithdrawOffer, setIsWithdrawModalVisible });

  const initialFormValues = useMemo(() => {
    return {
      sku: preOffer?.sku,
      stock: first(preOffer?.stocks)?.stock,
      retail_price: first(preOffer?.prices)?.retail_price,
      price: first(preOffer?.prices)?.price,
      reference_price: buyboxPrice,
      lowest_price: lowestPrice,
      currency_type: first(preOffer?.prices)?.currency_type,
      tax_rate: first(preOffer?.prices)?.tax_rate,
      SELLER_BARCODE: preOffer?.attributes?.SELLER_BARCODE,
      attributes: {
        ...preOffer?.attributes,
      },
    };
  }, [preOffer, buyboxPrice, lowestPrice]);

  const form = useForm({
    mode: 'onChange',
    resolver: getOfferDetailFormResolver({ t, dynamicFields }),
    values: initialFormValues,
  });

  const { control, handleSubmit } = form;

  const onHnadleSubmit = useCallback(
    ({ isRejectAction, isSubmitAction }) =>
      handleSubmit((formValues) => {
        onSubmit({ formValues, isRejectAction, isSubmitAction });
      })(),
    [handleSubmit, onSubmit]
  );

  const { dynamicSettings: maxImageDimensionSettings } = useDynamicSettingsQuery({
    params: {
      key: 'MAX_IMAGE_DIMENSIONS',
      is_active: true,
    },
  });
  const { dynamicSettings: minImageDimensionSettings } = useDynamicSettingsQuery({
    params: {
      key: 'MIN_IMAGE_DIMENSIONS',
      is_active: true,
    },
  });

  const maxImageDimensions = maxImageDimensionSettings?.find(
    (setting) => setting.key === 'MAX_IMAGE_DIMENSIONS'
  )?.value;
  const minImageDimensions = minImageDimensionSettings?.find(
    (setting) => setting.key === 'MIN_IMAGE_DIMENSIONS'
  )?.value;

  return (
    <section className="single-product-form">
      <Box
        className="box-primary form-box"
        title={t('basic_product_info_title')}
        subtitle={t('basic_product_info_subtitle')}
      >
        <AkinonInput
          formItemProps={{
            ...verticalFormItemLayout,
            label: t('product_name'),
            required: true,
          }}
          value={preOffer?.name}
          disabled
        />

        <AkinonInput
          formItemProps={{
            ...verticalFormItemLayout,
            label: t('basecode'),
            tooltip: {
              title: t('base_code_info'),
              style: { strokeColor: 'white' },
            },
            required: true,
          }}
          value={preOffer?.base_code}
          disabled
        />

        <AkinonTreeSelect
          formItemProps={{
            ...verticalFormItemLayout,
            label: t('category'),
            tooltip: {
              title: t('category_info'),
            },
            required: true,
          }}
          value={preOffer?.category}
          loading={isCategoriesLoading}
          treeData={categoryTreeData}
          treeNodeFilterProp={'title'}
          allowClear
          showSearch
          disabled
          treeDefaultExpandAll
          virtual={false}
          style={{ width: '100%' }}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        />
      </Box>
      <Space style={{ width: '100%' }} direction="vertical" size="large">
        <Box
          className="box-primary form-box"
          title={t('product_summary', { amount: 1 })}
          subtitle={
            isSuperUser
              ? t('products_to_be_added_form_description_mpo')
              : t('products_to_be_added_form_description')
          }
        >
          <AkinonForm isFlex>
            {variantDynamicFields?.map(({ key, label, data_type }) => (
              <DynamicFormElement
                key={key}
                formItemProps={{
                  control,
                  ...verticalFormItemLayout,
                  name: `attributes.${key}`,
                  label: label || key,
                  isWithColumn: true,
                  disabled: true,
                  colSpan: 11,
                }}
                widget={DataTypeWidgetMap[data_type]}
                placeholder={label || key}
              />
            ))}
            {variantListAttributeFields?.map(({ key, label, is_offer, data_type, ...rest }) => {
              const props =
                data_type === DataType.DROPDOWN ? { options: get(rest, 'options', []) } : {};
              return (
                <DynamicFormElement
                  key={key}
                  formItemProps={{
                    control,
                    ...verticalFormItemLayout,
                    name: `attributes.${key}`,
                    label: label || key,
                    required: rest.required,
                    isWithColumn: true,
                    disabled: !canEditOffer,
                    colSpan: 11,
                  }}
                  placeholder={label || key}
                  widget={DataTypeWidgetMap[data_type]}
                  {...props}
                />
              );
            })}

            <Col span={11}>
              <AkinonInput
                formItemProps={{
                  ...verticalFormItemLayout,
                  control,
                  name: FormKey.SKU,
                  label: t('sku'),
                  required: true,
                  disabled: !canEditOffer,
                }}
                placeholder={t('sku')}
              />
              <PluginRenderer
                placeholderId="ui-protocol-product-detail-sku"
                params={{ sku: preOffer?.sku }}
              />
            </Col>

            <AkinonInput
              formItemProps={{
                ...verticalFormItemLayout,
                control,
                name: FormKey.SELLER_BARCODE,
                label: t('seller_barcode'),
                required: true,
                disabled: !canEditOffer,
                isWithColumn: true,
                colSpan: 11,
              }}
              placeholder={t('seller_barcode')}
            />

            <AkinonInput
              formItemProps={{
                ...verticalFormItemLayout,
                control,
                name: FormKey.STOCK,
                label: t('stock'),
                required: true,
                disabled: !canEditOffer,
                isWithColumn: true,
                colSpan: 11,
              }}
              placeholder={t('stock')}
            />

            <AkinonInput
              formItemProps={{
                ...verticalFormItemLayout,
                control,
                name: FormKey.PRICE,
                label: t('selling.price'),
                required: true,
                disabled: !canEditOffer,
                isWithColumn: true,
                colSpan: 11,
              }}
              placeholder={t('selling.price')}
            />

            <AkinonInput
              formItemProps={{
                ...verticalFormItemLayout,
                control,
                name: FormKey.RETAIL_PRICE,
                label: t('psf'),
                required: true,
                disabled: !canEditOffer,
                isWithColumn: true,
                colSpan: 11,
              }}
              placeholder={t('psf')}
            />

            <AkinonInput
              formItemProps={{
                ...verticalFormItemLayout,
                control,
                name: FormKey.REFERENCE_PRICE,
                label: (
                  <TitleWithHint
                    text={t('reference_price').toLocaleUpperCase(i18n.language)}
                    hint={t('reference_price_desc')}
                  />
                ),
                disabled: true,
                isWithColumn: true,
                colSpan: 11,
              }}
              placeholder={t('reference_price')}
            />

            <AkinonInput
              formItemProps={{
                ...verticalFormItemLayout,
                control,
                name: FormKey.LOWEST_PRICE,
                label: (
                  <TitleWithHint
                    style={{ color: 'white' }}
                    text={t('lowest_price')}
                    hint={t('lowest_price_desc')}
                  />
                ),
                disabled: true,
                isWithColumn: true,
                colSpan: 11,
              }}
              placeholder={t('lowest_price')}
            />

            <AkinonInput
              formItemProps={{
                ...verticalFormItemLayout,
                control,
                name: FormKey.TAX_RATE,
                label: t('tax_rate'),
                required: true,
                disabled: !canEditOffer,
                isWithColumn: true,
                colSpan: 11,
              }}
              placeholder={t('tax_rate')}
            />

            <AkinonSelect
              formItemProps={{
                ...verticalFormItemLayout,
                control,
                name: FormKey.CURRENCY_TYPE,
                label: t('currency'),
                required: true,
                disabled: !canEditOffer,
                isWithColumn: true,
                colSpan: 11,
              }}
              loading={isProductPricesOptionsLoading}
              options={currencyOptions}
              placeholder={t('currency')}
            />
          </AkinonForm>
        </Box>
        <Box
          className="box-primary form-box"
          title={t('product.images.title')}
          subtitle={t('product.images.description.short')}
        >
          <ProductImageUpload
            minImageDimensions={minImageDimensions}
            maxImageDimensions={maxImageDimensions}
            accept="image/*"
            multiple
          />
        </Box>
        <Show when={isSuperUser}>
          <RelatedProductsTable categories={categories} preOffer={preOffer} />
        </Show>
        <Box
          className="box-primary form-box description-form"
          title="Description"
          subtitle={<div>{t('product.description.form.description')}</div>}
        >
          <AkinonFormItem
            control={control}
            label={t('product.description')}
            name={FormKey.SELLER_DESCRIPTION}
            wrapperCol={verticalFormItemLayout.wrapperCol}
            required
          >
            <QuillEditor readOnly={!canEditOffer} />
          </AkinonFormItem>
        </Box>
        <RejectModal
          isRejectModalVisible={isRejectModalVisible}
          setRejectModalVisible={setIsRejectModalVisible}
          isSubmitPreOfferMutating={isSubmitPreOfferMutating}
          submitPreOffer={submitPreOffer}
          priceLists={priceLists}
          stockLists={stockLists}
          dataSources={dataSources}
        />
        <SubmitForm
          isPatchPreOffersMutating={isPatchPreOffersMutating}
          isSubmitPreOfferMutating={isSubmitPreOfferMutating}
          isApprovePreOfferMutating={isApprovePreOfferMutating}
          onSubmit={onHnadleSubmit}
          canEditOffer={canEditOffer}
        />
      </Space>
      <CreateProductModal
        modalProps={{
          open: isCreateProductModalVisible,
          onCancel: () => setIsCreateProductModalVisible(false),
        }}
        buttonProps={{
          onClick: () => createProduct({ preOfferId: offerId }),
          loading: isCreatingProduct,
        }}
      />
      <WithdrawModal
        preOffer={preOffer}
        isWithdrawModalVisible={isWithdrawModalVisible}
        onClose={() => {
          setIsWithdrawModalVisible(false);
        }}
      />
    </section>
  );
};

export default OfferDetail;
