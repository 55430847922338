import Link from '@components/Link';
import { RouteUrls } from '@constants/routeUrls';
import useStore from '@zustand-store/index';
import { setBreadcrumbsSelector } from '@zustand-store/selectors/app';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

export function useBreadcrumbs({ campaignId }) {
  const { t } = useTranslation('Marketing');
  const setBreadcrumbs = useStore(setBreadcrumbsSelector);

  useEffect(() => {
    setBreadcrumbs([
      <Link key={RouteUrls.marketing.landing} to={RouteUrls.marketing.landing}>
        {t('marketing')}
      </Link>,
      <Link
        key={RouteUrls.marketing.productCollections.landing}
        to={RouteUrls.marketing.productCollections.landing}
      >
        {t('campaigns')}
      </Link>,
      campaignId ? t('edit_campaign') : t('add_new_campaign'),
    ]);
  }, [t]);

  useUnmount(() => {
    setBreadcrumbs([]);
  });
}
