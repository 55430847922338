import './styles.scss';

import AkinonForm from '@components/AkinonForm';
import AkinonFormItem from '@components/AkinonFormItem';
import { QuillEditor } from '@components/QuillEditor';
import Box from '@components/utility/box';
import { verticalFormItemLayout } from '@constants/layoutTypes';
import { useTranslation } from 'react-i18next';

import { DescriptionFormKey } from './common';

/**
 * @param {{ descriptionForm: import('react-hook-form').UseFormReturn}} props
 */

const DescriptionForm = ({ descriptionForm }) => {
  const { t } = useTranslation('ProductsAndCategories');
  const { control } = descriptionForm;

  return (
    <Box
      className="box-primary form-box description-form"
      title="Description"
      subtitle={<div>{t('product.description.form.description')}</div>}
    >
      <AkinonForm
        className="language-settings__form"
        layout="vertical"
        name="descriptionForm"
        {...verticalFormItemLayout}
      >
        <AkinonFormItem
          name={DescriptionFormKey.SELLER_DESCRIPTION}
          label={t('product.description')}
          control={control}
          requiredMarkContent={`* ${t('required')}`}
          requiredMarkPosition="right"
          required
        >
          <QuillEditor isReactHookForm />
        </AkinonFormItem>
      </AkinonForm>
    </Box>
  );
};

export default DescriptionForm;
