import { postBulkProductPricesUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { client } from '@services/api/client';

/**
 * @param {{ mutationOptions: import('@tanstack/react-query').UseMutationOptions }} args
 */
export function useBulkImportPricesMutation({ mutationOptions } = {}) {
  const { isError, isSuccess, isLoading, data, mutate, reset } = useAkinonMutation({
    mutationFn: ({ formData, onProgress }) =>
      client.post(postBulkProductPricesUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => onProgress?.(progressEvent),
      }),
    ...mutationOptions,
  });

  return {
    bulkImportPrices: mutate,
    resetBulkImportPrices: reset,
    isBulkImportingPrices: isLoading,
    isBulkImportPricesSuccess: isSuccess,
    isBulkImportPricesError: isError,
    bulkImportPricesData: data,
  };
}
