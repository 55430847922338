import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { createMessage as createMessageService } from '@services/api/orders/message';

export function useCreateMessageMutation({ mutationOptions = {} }) {
  const { mutate: createMessage, isLoading: isCreatingMessage } = useAkinonMutation({
    mutationFn: createMessageService,
    ...mutationOptions,
  });

  return {
    createMessage,
    isCreatingMessage,
  };
}
