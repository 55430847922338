import './styles.scss';

import { getPathFragments } from '@common/routing';
import { getInitialFormValues } from '@components/AkinonFilter/common';
import useFiltersSlice from '@components/AkinonFilter/hooks/store/useFiltersSlice';
import { useIsMobile } from '@hooks/useIsMobile';
import { Form, Space } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import FilterActions from '../FilterActions';
import FilterOptionsDrawer from '../FilterOptionsDrawer';
import FilterOptionsModal from '../FilterOptionsModal';
import {
  getModalFormInitialValues,
  transformFiltersFormMetaFieldsToModalFormMetaFields,
} from './common';
import useOnModalCancel from './hooks/useOnModalCancel';

const FilterOptions = ({
  includeFileFilter,
  setCurrentFiltersFormMeta,
  staticFiltersFormMetaFields,
  dynamicFiltersFormMetaFields,
  currentFiltersFormMeta,
  isDynamicFiltersLoading,
  isFilterOptionsModalOpen,
  setIsFilterOptionsModalOpen,
}) => {
  const { pageFilters, setFilters } = useFiltersSlice();
  const [search, setSearch] = useState(null);
  const { pathname, search: _search } = useLocation();
  const { mainPath } = getPathFragments(pathname);
  const filterKey = `${mainPath}${_search}`;
  const isMobile = useIsMobile();
  const [modalForm] = Form.useForm();
  const onModalCancel = useOnModalCancel({
    setCurrentFiltersFormMeta,
    modalForm,
    setIsFilterOptionsModalOpen,
    staticFiltersFormMetaFields,
    dynamicFiltersFormMetaFields,
  });

  const modalFormInitialValues = getModalFormInitialValues(currentFiltersFormMeta);

  const allFormMetaFields = transformFiltersFormMetaFieldsToModalFormMetaFields([
    ...staticFiltersFormMetaFields,
    ...dynamicFiltersFormMetaFields,
  ]);

  const formMetaFields = useMemo(
    () =>
      search
        ? allFormMetaFields.filter((field) =>
            field?.children?.toLowerCase?.().includes(search?.toLowerCase?.())
          )
        : allFormMetaFields,
    [search, allFormMetaFields]
  );

  const handleOpenModalIconClick = () => {
    setIsFilterOptionsModalOpen(true);
  };

  const addFileToFilters = (selectFile) => {
    setFilters({
      pagePath: filterKey,
      filters: getInitialFormValues({
        fields: currentFiltersFormMeta?.fields,
        pageFilters: { ...pageFilters, filter_file: selectFile.file },
      }),
    });
  };

  const onSearch = (val) => {
    setSearch(val);
  };

  return (
    <Space className="filter-options">
      <FilterActions
        includeFileFilter={includeFileFilter}
        addFileToFilters={addFileToFilters}
        isDynamicFiltersLoading={isDynamicFiltersLoading}
        handleOpenModalIconClick={handleOpenModalIconClick}
      />
      {isMobile ? (
        <FilterOptionsDrawer
          isFilterOptionsModalOpen={isFilterOptionsModalOpen}
          onModalCancel={onModalCancel}
          search={search}
          onSearch={onSearch}
          modalFormInitialValues={modalFormInitialValues}
          modalForm={modalForm}
          formMetaFields={formMetaFields}
        />
      ) : (
        <FilterOptionsModal
          isFilterOptionsModalOpen={isFilterOptionsModalOpen}
          onModalCancel={onModalCancel}
          search={search}
          onSearch={onSearch}
          modalFormInitialValues={modalFormInitialValues}
          modalForm={modalForm}
          formMetaFields={formMetaFields}
        />
      )}
    </Space>
  );
};

FilterOptions.propTypes = {
  includeFileFilter: PropTypes.bool.isRequired,
  setCurrentFiltersFormMeta: PropTypes.func.isRequired,
  staticFiltersFormMetaFields: PropTypes.array.isRequired,
  dynamicFiltersFormMetaFields: PropTypes.array.isRequired,
  currentFiltersFormMeta: PropTypes.object.isRequired,
  isDynamicFiltersLoading: PropTypes.bool.isRequired,
};

FilterOptions.defaultProps = {
  exportOptions: [],
};

export default FilterOptions;
