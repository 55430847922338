import {
  IconCategory,
  IconCategoryFilled,
  IconFiles,
  IconInfoSquareRounded,
  IconTag,
  IconTruckDelivery,
} from '@tabler/icons-react';
import { getTreeNodeByValue, linkParents, markPermission } from '@utils/index';
import cloneDeep from 'lodash/cloneDeep';
import uniq from 'lodash/uniq';

import CategoryBasedCommissionForm from './components/CategoryBasedCommissionForm';
import CategoryPermissions from './components/CategoryPermissions';
import DeliverySettings from './components/DeliverySettings';
import DocumentsForm from './components/DocumentsForm';
import GeneralInfoForm from './components/GeneralInfoForm';
import ProductBasedCommission from './components/ProductBasedCommission';

export const DataIndex = {
  NAME: 'name',
};

export const SellerManagementStepKeys = {
  GENERAL_INFORMATION: 'generalInformation',
  DOCUMENTS: 'documents',
  CATEGORY_BASED_COMMISSION: 'categoryBasedCommission',
  MATURITY: 'maturity',
  CATEGORY_PERMISSIONS: 'categoryPermissions',
  PRODUCT_BASED_COMMISSION: 'productBasedCommission',
  DELIVERY_SETTINGS: 'deliverySettings',
};

export const getPeriodProperties = ({ t }) => ({
  weekly: {
    items: [
      { label: t('monday') },
      { label: t('tuesday') },
      { label: t('wednesday') },
      { label: t('thursday') },
      { label: t('friday') },
      { label: t('saturday') },
      { label: t('sunday') },
    ].map((item, value) => ({ ...item, label: t(item.label), value })),
    placeholder: t('set_day'),
  },
  monthly: {
    items: Array(31)
      .fill()
      .map((_, value) => ({ label: String(value + 1), value: value + 1 })),
    placeholder: t('set_day'),
  },
});

export const getSellerManagementFormStepList = ({
  t,
  isSuperUser,
  uploadedFiles,
  categories,
  userCategoryNodePermissions,
}) => {
  return [
    {
      title: t('general_information'),
      icon: <IconInfoSquareRounded size={20} />,
      Component: GeneralInfoForm,
      key: SellerManagementStepKeys.GENERAL_INFORMATION,
    },
    {
      title: t('documents'),
      icon: <IconFiles size={20} />,
      Component: DocumentsForm,
      key: SellerManagementStepKeys.DOCUMENTS,
    },
    ...(isSuperUser
      ? [
          {
            title: t('category_permissions'),
            icon: <IconCategoryFilled size={20} />,
            key: SellerManagementStepKeys.CATEGORY_PERMISSIONS,
            Component: (props) => (
              <CategoryPermissions
                categories={categories}
                userCategoryNodePermissions={userCategoryNodePermissions}
                {...props}
              />
            ),
          },
          {
            title: t('common:delivery_settings'),
            icon: <IconTruckDelivery size={20} />,
            key: SellerManagementStepKeys.DELIVERY_SETTINGS,
            Component: DeliverySettings,
          },
        ]
      : []),
    {
      title: t('product_based_commission'),
      icon: <IconTag size={20} style={{ transform: 'rotate(90deg)' }} />,
      key: SellerManagementStepKeys.PRODUCT_BASED_COMMISSION,
      Component: ProductBasedCommission,
    },
    {
      title: t('category_based_commission'),
      Component: CategoryBasedCommissionForm,
      icon: <IconCategory size={20} />,
      key: SellerManagementStepKeys.CATEGORY_BASED_COMMISSION,
    },
  ];
};

const markAllParentsDirty = (treeData, key) => {
  const treeNode = getTreeNodeByValue({ tree: treeData, nodeValue: key, key: 'path' });
  if (treeNode?.parent) {
    treeNode.parent.dirty = true;
  }
  if (key.length === 4) {
    return;
  } else {
    markAllParentsDirty(treeData, key.slice(0, -4));
  }
};

const getParentWithoutPermissionUntilDirty = (currentNode) => {
  if (currentNode?.parent?.dirty || currentNode?.parent?.hasPermission) {
    return currentNode;
  }
  return currentNode?.parent
    ? getParentWithoutPermissionUntilDirty(currentNode.parent)
    : currentNode;
};

const getParentWithPermissionUntilDirty = (currentNode) => {
  if (currentNode?.parent?.dirty || !currentNode?.parent?.hasPermission) {
    return currentNode;
  }
  return currentNode?.parent ? getParentWithPermissionUntilDirty(currentNode.parent) : currentNode;
};

const findAnyParentWithPermissionUntilDirty = (currentNode) => {
  if (currentNode?.parent?.hasPermission && !currentNode?.parent?.dirty) {
    return currentNode.parent;
  }
  return currentNode?.parent ? findAnyParentWithPermissionUntilDirty(currentNode.parent) : null;
};
const findAnyParentWithPermission = (currentNode) => {
  if (currentNode?.parent?.hasPermission) {
    return currentNode.parent;
  }
  return currentNode?.parent ? findAnyParentWithPermission(currentNode.parent) : null;
};

function getLeafNodes(node) {
  if (!node.children || node.children.length === 0) {
    return node;
  }

  let leaves = [];
  for (let child of node.children) {
    leaves = leaves.concat(getLeafNodes(child));
  }

  return leaves;
}

export const optimizeCheckedKeys = (userCategoryNodePermissions, treeData, checkedKeys) => {
  const clonedTreeData = cloneDeep(treeData)
    .map((node) => linkParents({ currentNode: node }))
    .map((node) => markPermission({ node, userCategoryNodePermissions }));

  checkedKeys.forEach((key) => {
    const treeNode = getTreeNodeByValue({
      tree: clonedTreeData,
      nodeValue: key,
      key: 'path',
    });
    treeNode.isChecked = true;
    if (treeNode?.parent) {
      const siblings = treeNode.parent.children;
      if (siblings.length > 1) {
        if (!siblings.every((sibling) => checkedKeys.includes(sibling.key)))
          markAllParentsDirty(clonedTreeData, treeNode.key);
      }
    }
  });

  const leafNodes = clonedTreeData.flatMap((node) => getLeafNodes(node));

  let nodesToActivate = [];
  let nodesToDeactivate = [];

  leafNodes.forEach((node) => {
    const { isChecked, hasPermission, dirty } = node;

    const firstParentWithPermission = findAnyParentWithPermissionUntilDirty(node);
    if (!isChecked && !hasPermission) {
      const firstParentWithPermission = findAnyParentWithPermission(node);
      nodesToDeactivate.push(firstParentWithPermission);
    } else if (isChecked && !hasPermission) {
      if (dirty) {
        nodesToActivate.push(node);
      } else {
        nodesToActivate.push(getParentWithoutPermissionUntilDirty(node) ?? node);
      }
    } else if (!isChecked && hasPermission) {
      if (dirty) {
        nodesToDeactivate.push(node);
      } else {
        nodesToDeactivate.push(getParentWithPermissionUntilDirty(node));
      }
    } else if (firstParentWithPermission) {
      nodesToDeactivate.push(firstParentWithPermission);
    }
  });

  nodesToActivate.forEach((node) => {
    const parentNodeToDeactivate = findAnyParentWithPermissionUntilDirty(node);
    parentNodeToDeactivate && nodesToDeactivate.push(parentNodeToDeactivate);
  });

  const keysToActivate = uniq(nodesToActivate.map((node) => node?.key));
  const keysToDeactivate = uniq(nodesToDeactivate.filter(Boolean).map((node) => node?.key));

  return [keysToActivate, keysToDeactivate];
};
