import Show from '@components/Show';
import { useUserRole } from '@utils/hooks/useUserRole';
import PropTypes from 'prop-types';
import React from 'react';

const AuthorizedComponent = ({ children, userRole: authorizedUserRole }) => {
  const userRole = useUserRole();

  return <Show when={userRole === authorizedUserRole}>{children}</Show>;
};

AuthorizedComponent.propTypes = {
  children: PropTypes.node.isRequired,
  userRole: PropTypes.string.isRequired,
};

export default AuthorizedComponent;
