import { Divider } from 'antd';
import React from 'react';

import ChangePasswordForm from './components/ChangePasswordForm';
import UserProfileForm from './components/UserProfileForm';

const Profile = () => (
  <>
    <UserProfileForm />
    <Divider />
    <ChangePasswordForm />
  </>
);

export default Profile;
