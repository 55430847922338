import { deleteIntegrationAccessTokensUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import omit from 'lodash/omit';

import { client } from '../client';
import { queryClient } from '../queryClient';

export function useDeleteIntegrationAccessToken({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ accessTokenId }) =>
      client.delete(deleteIntegrationAccessTokensUrl, { urlParams: { accessTokenId } }),
    onSuccess() {
      mutationOptions?.onSuccess?.();
      queryClient.invalidateQueries([QueryKey.INTEGRATION_ACCESS_TOKENS]);
    },
    ...omit(mutationOptions, ['onSuccess']),
  });

  return {
    deleteIntegrationAccessToken: mutate,
    isDeletingIntegrationAccessToken: isLoading,
  };
}
