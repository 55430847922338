import { rejectPreOfferURL, submitPreOfferURL } from '@constants/apiUrls';
import { client } from '@services/api/client';

import { PreOfferStatus } from '../../common';
import { FormKey } from '../../OfferDetail/components/RejectModal/common';

const useRejectOffers = ({ refetchOffers, resetFields }) => {
  const rejectSelectedItems = async ({
    selectedRows,
    [FormKey.REJECTION_REASON]: rejectionReason,
    [FormKey.REJECTION_EXPLANATION]: rejectionExplanation,
    mutationOptions,
  }) => {
    const { onSuccess, onError } = mutationOptions;

    const approvalRequiredSelectedRows = selectedRows.filter(
      (selectedRow) => selectedRow.approval_status === PreOfferStatus.APPROVAL_REQUIRED
    );

    const approvePreOfferPromises = approvalRequiredSelectedRows.map((row) => {
      return () =>
        client.post(submitPreOfferURL, {}, { urlParams: { id: row.id } }).catch(() => {});
    });

    const rejectionPromises = selectedRows.map((row) => {
      return () =>
        client
          .post(
            rejectPreOfferURL,
            {
              reason: rejectionReason,
              explanation: rejectionExplanation,
            },
            { urlParams: { id: row.id } }
          )
          .then((result) => {
            onSuccess(result);
          })
          .catch((error) => {
            onError(error);
          });
    });

    await Promise.allSettled(approvePreOfferPromises.map((f) => f()));
    await Promise.allSettled(rejectionPromises.map((f) => f()));

    refetchOffers();
    resetFields?.();
  };

  return {
    rejectSelectedItems,
  };
};

export default useRejectOffers;
