import { getRefineImages } from '@common/form';
import { FileType, getFileTypeOfUrl } from '@layouts/AppLayout/Announcements/common';
import isEmpty from 'lodash/isEmpty';
import * as z from 'zod';

import IconBanner from './IconBanner';
import IconPopup from './IconPopup';
import IconPush from './IconPush';

export const DeliveryType = {
  PUSH: 1,
  POPUP: 2,
  BANNER: 3,
};

export const getDeliveryTypeOptions = ({ t }) => {
  return [
    {
      label: t('push_notification'),
      value: DeliveryType.PUSH,
      tooltip: (
        <div>
          <div className="w-100 flex justify-center">
            <IconPush className="mb-2 " />
          </div>
          {t('push_notification_info')}
        </div>
      ),
    },
    {
      label: t('popup_notification'),
      value: DeliveryType.POPUP,
      tooltip: (
        <div>
          <div className="w-100 flex justify-center">
            <IconPopup className="mb-2 " />
          </div>
          {t('popup_notification_info')}
        </div>
      ),
    },
    {
      label: t('banner_notification'),
      value: DeliveryType.BANNER,
      tooltip: (
        <div>
          <div className="w-100 flex justify-center">
            <IconBanner className="mb-2 " />
          </div>
          {t('banner_notification_info')}
        </div>
      ),
    },
  ];
};

export const getTargetAudienceOptions = ({ users }) => {
  return users
    ?.filter((user) => user?.is_active)
    ?.map((user) => {
      return {
        value: user.id,
        label: user.name,
      };
    });
};
export const FormKey = {
  AUDIENCES: 'audiences',
  TITLE: 'title',
  MESSAGE: 'message',
  DELIVERY: 'delivery',
  DELIVERY_TYPE: 'delivery.ntype',
  ATTACHMENTS: 'delivery.attachments',
};

export const SchemaKey = {
  ...FormKey,
  DELIVERY_TYPE: 'ntype',
  ATTACHMENTS: 'attachments',
};

export const getFormSchema = ({ t, dimensionBounds }) => {
  const refineImages = getRefineImages({ t, dimensionBounds });

  return z.object({
    [SchemaKey.AUDIENCES]: z.string().array().min(1),
    [SchemaKey.TITLE]: z.string().min(1),
    [SchemaKey.MESSAGE]: z.string().min(1),
    [SchemaKey.DELIVERY]: z
      .object({
        [SchemaKey.DELIVERY_TYPE]: z.number(),
        [SchemaKey.ATTACHMENTS]: z.any(),
      })
      .superRefine((value, ctx) => {
        const notificationType = value[SchemaKey.DELIVERY_TYPE];
        const attachments = value[SchemaKey.ATTACHMENTS] ?? [];
        if (notificationType === DeliveryType.POPUP && isEmpty(attachments)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('formRule.required'),
            path: [SchemaKey.ATTACHMENTS],
          });
          return z.NEVER;
        }
        const images = attachments.filter(
          (attachment) => getFileTypeOfUrl(attachment.name) === FileType.IMAGE
        );

        if (notificationType === DeliveryType.POPUP && images.length > 0) {
          return refineImages(images, ctx, [SchemaKey.ATTACHMENTS]);
        }
      }),
  });
};
