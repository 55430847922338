import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';
import React from 'react';

const { Text } = Typography;

function TitleWithHint({ hint, text, style }) {
  return (
    <>
      <Text style={style} className="mr-1 standard-text-color">
        {text}
      </Text>
      <Tooltip title={hint}>
        <InfoCircleOutlined />
      </Tooltip>
    </>
  );
}

export default TitleWithHint;
