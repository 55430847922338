import { validationTypes, WidgetType } from '@constants/commontypes';
import { defaultDateFormat } from '@constants/index';
import moment from 'moment';

export const DataIndex = {
  EMAIL: 'email',
  LAST_LOGIN: 'last_login',
};

export const FormKey = {
  EMAIL: 'email',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
};

export const getColumns = ({ t }) => {
  return [
    {
      dataIndex: DataIndex.EMAIL,
      title: t('email'),
    },
    {
      dataIndex: DataIndex.LAST_LOGIN,
      title: t('last_login'),
      render: (lastLogin) => {
        return lastLogin && moment(lastLogin).format(defaultDateFormat);
      },
    },
  ];
};

export const getCreateUserFormFields = ({ t }) => {
  return {
    columns: 2,
    formItemLayout: null,
    colon: true,
    fields: [
      {
        colSpan: 2,
        label: t('email'),
        key: FormKey.EMAIL,
        widget: WidgetType.INPUT,
        rules: [validationTypes.required(t('email'))],
        placeholder: t('enter.variable', { variable: t('email') }),
      },
      {
        colSpan: 2,
        label: t('first_name'),
        key: FormKey.FIRST_NAME,
        widget: WidgetType.INPUT,
        rules: [validationTypes.required(t('first_name'))],
        placeholder: t('enter.variable', { variable: t('first_name') }),
      },
      {
        colSpan: 2,
        label: t('last_name'),
        key: FormKey.LAST_NAME,
        widget: WidgetType.INPUT,
        rules: [validationTypes.required(t('last_name'))],
        placeholder: t('enter.variable', { variable: t('last_name') }),
      },
    ],
  };
};
