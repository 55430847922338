import Link from '@components/Link';
import { RouteUrls } from '@constants/routeUrls';
import { TranslationKey } from '@root/i18n';
import useStore from '@zustand-store/index';
import { setBreadcrumbsSelector } from '@zustand-store/selectors/app';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

export function useBreadcrumbs() {
  const { t } = useTranslation(TranslationKey.LOCATIONS);
  const setBreadcrumbs = useStore(setBreadcrumbsSelector);

  useEffect(() => {
    setBreadcrumbs([
      <Link key={RouteUrls.stockLocations.landing} to={RouteUrls.stockLocations.landing}>
        {t('locations_title')}
      </Link>,
      t('location_edit_title'),
    ]);
  }, [t]);

  useUnmount(() => {
    setBreadcrumbs([]);
  });
}
