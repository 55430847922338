import { Col, Form } from 'antd';
import React, { memo } from 'react';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';

import { withTooltip } from './withTooltip';

const StyledFormItem = styled(Form.Item)`
  .ant-form-item-required {
    width 100%;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-style: italic;
    font-size: 12px;
    font-family: SimSun, sans-serif;
    align-self: center;
    ${(props) => props.requiredMarkPosition === 'right' && 'position: absolute; right: 0;'}
    line-height: 1;
    content: ${(props) => (props.requiredMarkContent ? `'${props.requiredMarkContent}'` : '*')};
  }
`;

const AkinonFormItem = memo(
  ({
    children,
    label,
    name,
    control,
    required,
    disabled,
    initialValue,
    help,
    id,
    inputRef,
    isWithColumn = false,
    valuePropName,
    colSpan = 24,
    ...rest
  }) => {
    return control ? (
      <Controller
        name={name}
        control={control}
        disabled={disabled}
        defaultValue={initialValue}
        key={name}
        render={({ field, fieldState }) => {
          const child = React.Children.toArray(children)[0];
          const component = (
            <StyledFormItem
              label={label}
              required={required}
              validateStatus={fieldState.invalid ? 'error' : undefined}
              help={fieldState.error?.message ?? help}
              {...rest}
            >
              {React.cloneElement(child, {
                ...child.props,
                ...field,
                onChange: (...params) => {
                  child.props.onChange && child.props.onChange(...params);
                  field.onChange(...params);
                },
                onBlur: () => {
                  child.props.onBlur && child.props.onBlur();
                  field.onBlur();
                },
                ref: (_ref) => {
                  if (inputRef) {
                    inputRef.current = _ref;
                  }
                  field.ref(_ref);
                },
                ...(valuePropName && {
                  [valuePropName]: field.value,
                }),
              })}
            </StyledFormItem>
          );

          return isWithColumn ? <Col span={colSpan}>{component}</Col> : component;
        }}
      />
    ) : (
      <Form.Item label={label} required={required} initialValue={initialValue} {...rest}>
        {children}
      </Form.Item>
    );
  }
);

export default AkinonFormItem;

const AkinonFormItemWithTooltip = withTooltip(AkinonFormItem);
export { AkinonFormItemWithTooltip };
