import './styles.scss';

import AkinonSteps, { AkinonStep } from '@components/AkinonSteps';
import { UserRole } from '@constants/auth';
import { QueryKey } from '@constants/query';
import { useDataSourceLandingPageQuery } from '@services/api/hooks/usePaginatedDataSourceLandingPagesQuery';
import { useUserRole } from '@utils/hooks/useUserRole';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useBoolean } from 'react-use';

import { getStepItems, PageStep } from './common';
import ApproveDataSourceLandingPageModal from './components/ApproveDataSourceLandingPageModal';
import RejectDataSourceLandingPageModal from './components/RejectDataSourceLandingPageModal';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { usePageMeta } from './hooks/usePageMeta';

const SellerStoreManagement = () => {
  const { t } = useTranslation('SellerStoreManagement');
  const [currentStep, setCurrentStep] = React.useState(PageStep.WIDGET_MANAGEMENT);
  const stepItems = getStepItems({ t });
  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;
  const { dataSourceLandingPageId } = useParams();
  const [rejectDataSourceLandingPageModalVisible, setRejectDataSourceLandingPageModalVisible] =
    useBoolean(false);
  const [approveDataSourceLandingPageModalVisible, setApproveDataSourceLandingPageModalVisible] =
    useBoolean(false);

  const { dataSourceLandingPage } = useDataSourceLandingPageQuery({
    dataSourceLandingPageId,
  });

  const handleApproveModalClose = () => {
    setApproveDataSourceLandingPageModalVisible(false);
  };

  const handleApproveModalOpen = () => {
    setApproveDataSourceLandingPageModalVisible(true);
  };

  const handleRejectModalClose = () => {
    setRejectDataSourceLandingPageModalVisible(false);
  };

  const handleRejectModalOpen = () => {
    setRejectDataSourceLandingPageModalVisible(true);
  };

  useBreadcrumbs({ isSuperUser });
  usePageMeta({
    isSuperUser,
    dataSourceLandingPage,
    handleApproveModalOpen,
    handleRejectModalOpen,
  });

  const stepHandleOnChange = (step) => {
    setCurrentStep(step);
  };

  return (
    <section className="seller-store-management">
      <AkinonSteps current={currentStep} onChange={stepHandleOnChange}>
        {stepItems.map(({ key, title, icon }) => (
          <AkinonStep key={key} title={title} icon={icon} />
        ))}
      </AkinonSteps>
      {stepItems.find((_, index) => index === currentStep).content}
      <RejectDataSourceLandingPageModal
        dataSourceLandingPage={dataSourceLandingPage}
        isVisible={rejectDataSourceLandingPageModalVisible}
        onClose={handleRejectModalClose}
        queryKey={[QueryKey.DATA_SOURCE_LANDING_PAGE, dataSourceLandingPageId]}
      />
      <ApproveDataSourceLandingPageModal
        dataSourceLandingPage={dataSourceLandingPage}
        isVisible={approveDataSourceLandingPageModalVisible}
        onClose={handleApproveModalClose}
        queryKey={[QueryKey.DATA_SOURCE_LANDING_PAGE, dataSourceLandingPageId]}
      />
    </section>
  );
};

export default SellerStoreManagement;
