import { getExportUrl } from '@constants/apiUrls.js';
import { QueryKey } from '@constants/query.js';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery.js';
import { client } from '@services/api/client';

export function useExportQuery({ cacheKey }) {
  const { data, error, isLoading } = useAkinonQuery({
    queryKey: [QueryKey.EXPORT, cacheKey],
    queryFn: () => client.get(getExportUrl, { urlParams: { cacheKey } }),
  });

  return {
    downloadUrl: data?.file,
    isExporting: isLoading,
    exportError: error,
  };
}
