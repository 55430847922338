import './styles.scss';

import { CloseOutlined } from '@ant-design/icons';
import AkinonFormBuilder from '@components/AkinonFormBuilder';
import { AkinonSearch } from '@components/AkinonInput';
import Drawer from '@components/Drawer';
import { Col, Form, Row, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getDrawerFormMeta } from '../FilterOptions/common';

const { Title } = Typography;

const FilterOptionsDrawer = ({
  isFilterOptionsModalOpen,
  onModalCancel,
  search,
  onSearch,
  modalFormInitialValues,
  modalForm,
  formMetaFields,
}) => {
  const { t } = useTranslation();

  return (
    <Drawer
      open={isFilterOptionsModalOpen}
      closable={false}
      width={'100%'}
      className="filter-options-drawer"
    >
      <Row justify="space-between" className="filter-drawer-header">
        <Col>
          <Title className="filters-drawer__title">{t('filters')}</Title>
        </Col>
        <CloseOutlined className="filters-drawer__close-icon" onClick={onModalCancel} />
      </Row>
      <div className="filter-options__search-filter">
        <AkinonSearch
          defaultValue={search}
          onKeyUp={(e) => onSearch(e.target.value)}
          onBlur={(e) => onSearch(e.target.value)}
          style={{ width: 300 }}
          placeholder={t('search')}
          data-testid="search-filter"
          onSearch={onSearch}
        />
      </div>
      <Form
        data-testid="filter-options-modal-form"
        className="filter-options__modal-form"
        initialValues={modalFormInitialValues}
        form={modalForm}
      >
        <AkinonFormBuilder form={modalForm} meta={getDrawerFormMeta(formMetaFields)} />
      </Form>
    </Drawer>
  );
};

export default FilterOptionsDrawer;
