import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { postDataSourceCreateUser } from '@services/api/datasources';

export const useDataSourceCreateUser = ({ dataSourceId, onSuccess = () => {} }) => {
  const mutationKey = ['data-source-create-user', dataSourceId ?? ''];
  const mutationFn = (requestBody) => postDataSourceCreateUser(dataSourceId, requestBody);

  const { data, mutate, isLoading, isError, ...rest } = useAkinonMutation({
    mutationKey,
    mutationFn,
    onSuccess,
  });

  return {
    ...rest,
    data,
    mutate,
    isError,
    isLoading,
  };
};
