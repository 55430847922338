import { postPreOffersUrl } from '@constants/apiUrls';

import { useAkinonMutation } from '../adapters/useAkinonMutation';
import { client } from '../client';

export function usePostPreOfferMutation() {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn({ requestBody }) {
      return client.post(postPreOffersUrl, requestBody);
    },
  });

  return {
    postPreOffer: mutate,
    isPostingPreOffer: isLoading,
  };
}
