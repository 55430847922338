import UserNotAuthorized from '@components/UserNotAuthorized';
import NotFound from '@pages/NotFound';
import UnexpectedError from '@pages/UnexpectedError';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

function RootErrorBoundary() {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <NotFound />;
    }

    if (error.status === 401) {
      return <UserNotAuthorized />;
    }
  }

  return <UnexpectedError />;
}

export default RootErrorBoundary;
