import Box from '@components/utility/box';
import React from 'react';

import AddressInfo from './AddressInfo';
import OrderInfo from './OrderInfo';
import OtherPackagesInfo from './OtherPackagesInfo';
import PackageInfo from './PackageInfo';

const PackagesDetailList = ({
  t,
  packageDetail,
  packages,
  packageInfo,
  shippingMethod,
  orderDetail,
  billingAddress,
}) => {
  return (
    <div className="detail-wrapper">
      <div className={`detail-list-item`}>
        <div className="detail-box-wrapper">
          <Box className="detail-box box-primary" title={t('package_info')}>
            <div className="detail-list-wrapper">
              <PackageInfo
                t={t}
                packageDetail={packageDetail}
                shippingMethod={shippingMethod}
                orderDetail={orderDetail}
              />
            </div>
          </Box>
        </div>
        <div className="detail-box-wrapper">
          <Box className="detail-box box-primary" title={t('orders.info')} leftSideColor="blue">
            <div className="detail-list-wrapper">
              <OrderInfo t={t} orderDetail={orderDetail} billingAddress={billingAddress} />
            </div>
          </Box>
        </div>
        <div className="detail-box-wrapper">
          <Box className="detail-box box-primary" title={t('address_info')} leftSideColor="yellow">
            <div className="detail-list-wrapper">
              <AddressInfo t={t} billingAddress={billingAddress} packageInfo={packageInfo} />
            </div>
          </Box>
        </div>
        <div className="detail-box-wrapper">
          <Box
            className="detail-box box-primary"
            title={t('related_other_packages')}
            leftSideColor="green"
          >
            <div className="detail-list-wrapper" id="package_detail_other_packages_info">
              <OtherPackagesInfo
                t={t}
                packages={packages}
                billingAddress={billingAddress}
                packageInfo={packageInfo}
                packageDetail={packageDetail}
              />
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default PackagesDetailList;
