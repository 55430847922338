import { Input } from 'antd';

import { InputGroupWrapper, InputWrapper, TextAreaWrapper } from './inputs';

const { TextArea, Group } = Input;

const StyledInput = InputWrapper(Input);
const InputGroup = InputGroupWrapper(Group);
const Textarea = TextAreaWrapper(TextArea);

export default StyledInput;
export { InputGroup, Textarea };
