import './styles.scss';

import { RightCircleOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import React from 'react';

const CollapseExpandIcon = ({ isExpanded }) => {
  return (
    <RightCircleOutlined
      className={clsx('collapse-expand-icon', { 'collapse-expand-icon--expanded': isExpanded })}
    />
  );
};

export default CollapseExpandIcon;
