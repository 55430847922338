import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { getIntegrationErrorsUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { useAkinonQuery } from '../adapters/useAkinonQuery';
import { client } from '../client';

export function usePaginatedIntegrationErrorsQuery({ queryOptions, filters, pagination, params }) {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.INTEGRATION_ERRORS, filters, pagination, params],
    queryFn: () =>
      client.get(getIntegrationErrorsUrl, {
        params: {
          ...getRequestFiltersParams(filters),
          ...getRequestPaginationParams(pagination),
          ...params,
        },
      }),
    ...queryOptions,
  });

  return {
    integrationErrors: data?.results,
    totalIntegrationErrors: data?.count,
    isFetchingIntegrationErrors: isFetching,
  };
}
