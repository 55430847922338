import { changePasswordUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { client } from '@services/api/client';

export function useChangePasswordMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody }) =>
      client.put(changePasswordUrl, requestBody, {
        suppressedNotifications: ['success', 'error'],
      }),
    ...mutationOptions,
  });
  return {
    changePassword: mutate,
    isChangingPassword: isLoading,
  };
}
