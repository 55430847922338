import './styles.scss';

import AkinonButton from '@components/AkinonButton';
import AkinonFilter from '@components/AkinonFilter';
import { usePageFilters } from '@components/AkinonFilter/hooks/store/usePageFilters';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { getProductsUrl } from '@constants/apiUrls';
import { UserRole } from '@constants/auth';
import { defaultRowKey } from '@constants/index';
import { RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { useCategoryTreeData } from '@utils/hooks/useCategoryTreeData';
import { useDynamicFilters } from '@utils/hooks/useDynamicFilters';
import useResetTableCurrentPageWhenFiltersChanged from '@utils/hooks/useResetTableCurrentPageWhenFiltersChanged';
import { useUserRole } from '@utils/hooks/useUserRole';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getDynamicColumns, transformDynamicFilters } from '../common';
import { getColumns } from './common';
import { useDataSource } from './hooks/useDataSource';
import { usePageMeta } from './hooks/usePageMeta';
import useStaticFilters from './hooks/useStaticFilters';

const ProductPool = () => {
  const { t } = useTranslation('ProductsAndCategories');
  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;
  const navigate = useAppNavigate();
  const filters = usePageFilters();
  const [pagination, setPagination] = usePagination();
  usePageMeta();

  const { categoryTreeData, isCategoriesLoading } = useCategoryTreeData({
    generateTreeDataOptions: {
      valueKey: 'path',
    },
  });

  const staticFilters = useStaticFilters({ categoryTreeData, isCategoriesLoading, t });
  const dynamicFilters = transformDynamicFilters(useDynamicFilters());

  useResetTableCurrentPageWhenFiltersChanged({ pagination, setPagination, filters });

  const { dynamicFiltersFormMetaFields } = dynamicFilters;

  const { dynamicFormMetaFields } = getDynamicColumns({
    filters,
    dynamicFiltersFormMetaFields,
  });
  const { dataSource, total, isDataSourceLoading } = useDataSource({
    pagination,
    filters,
    dynamicFormMetaFields,
  });
  const columns = getColumns({ t, categoryTreeData });

  const onRow = (record) => ({
    onClick: () => {
      navigate(RouteUrls.productsAndCategories.products.detail, {
        productId: record.id,
      });
    },
  });

  const onGotoAddSingleProduct = () => {
    navigate(RouteUrls.productsAndCategories.products.productForm);
  };

  return (
    <section className="product-pool">
      <AkinonFilter title={t('filters')} {...staticFilters} {...dynamicFilters} total={total} />
      <AkinonTable
        locale={{
          ...(!isSuperUser && {
            emptyText: (
              <div className="product-pool__navigate-to-form-container">
                <AkinonButton onClick={onGotoAddSingleProduct}>
                  {t('add.single.product')}
                </AkinonButton>
              </div>
            ),
          }),
        }}
        loading={isDataSourceLoading}
        dataSource={dataSource}
        optionsUrl={getProductsUrl}
        total={total}
        columns={columns}
        appendDynamicColumnsAt={columns.length}
        enableDynamicColumns
        title={t('product.pool')}
        description={`${total} ${t('results.found')}`}
        pagination={pagination}
        rowKey={defaultRowKey}
        setPagination={setPagination}
        tableActions={[]}
        onRow={onRow}
      />
    </section>
  );
};

export default ProductPool;
