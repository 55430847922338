export const SchemaType = {
  CHOICE: 'choice',
  INTEGER: 'integer',
  STRING: 'string',
  DATE: 'date',
  PK_FIELD: 'pk_field',
};

export const ReportType = {
  SALES: 'sales',
  ORDER: 'order',
};

const getReportTypeOptions = ({ t }) =>
  Object.values(ReportType).map((reportTypeValue) => ({
    value: reportTypeValue,
    label: t(`${reportTypeValue}.report`),
  }));

export const getStaticFormFields = ({ t }) => [
  {
    name: 'reportType',
    key: 'reportType',
    label: t('report.type'),
    widget: 'radio-group',
    colSpan: 4,
    options: getReportTypeOptions({ t }),
    initialValue: ReportType.SALES,
    extra: t('fields.required'),
    fieldProps: {
      className: 'radio-group-row',
    },
  },
];

export const FormFieldKey = {
  REPORT_TYPE: 'reportType',
  START_DATE: 'start_date',
  END_DATE: 'end_date',
};

export const dateFormat = 'YYYY-MM-DD';
