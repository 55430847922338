import { UserRole } from '@constants/auth';
import { useUserRole } from '@utils/hooks/useUserRole';
import useStore from '@zustand-store/index';
import { setPageMetaSelector } from '@zustand-store/selectors/app';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

export function usePageMeta({
  showCreateProductModal,
  canWithdrawOffer,
  setIsWithdrawModalVisible,
}) {
  const { t } = useTranslation('ProductsAndCategories');
  const setPageMeta = useStore(setPageMetaSelector);

  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;
  useEffect(() => {
    setPageMeta({
      title: t('offer.actions'),
      description: t('offer.actions.desc'),
      actions: [
        isSuperUser && {
          label: t('add_to_marketplace_catalog'),
          onClick() {
            showCreateProductModal();
          },
        },
        canWithdrawOffer && {
          label: t('recall'),
          onClick: setIsWithdrawModalVisible,
          type: 'primary',
        },
      ].filter(Boolean),
    });
  }, [t, canWithdrawOffer]);

  useUnmount(() => {
    setPageMeta({});
  });
}
