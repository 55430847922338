import useStore from '@zustand-store/index';
import {
  languageSelector,
  resetLanguageSelector,
  setLanguageSelector,
} from '@zustand-store/selectors/language';

export default function useLanguageSlice() {
  const language = useStore(languageSelector);
  const setLanguage = useStore(setLanguageSelector);
  const resetLanguage = useStore(resetLanguageSelector);

  return {
    language,
    setLanguage,
    resetLanguage,
  };
}
