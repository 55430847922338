import './styles.scss';

import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import AkinonFilter from '@components/AkinonFilter';
import { usePageFilters } from '@components/AkinonFilter/hooks/store/usePageFilters';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { dataSourceLandingPagesUrl } from '@constants/apiUrls';
import { UserRole } from '@constants/auth';
import { QueryKey } from '@constants/query';
import { RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { useAllDataSources } from '@services/hooks';
import { useResetTableCurrentPageWhenFiltersChanged } from '@utils/hooks';
import { useUserRole } from '@utils/hooks/useUserRole';
import { Divider } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBoolean } from 'react-use';

import ApproveDataSourceLandingPageModal from '../components/ApproveDataSourceLandingPageModal';
import RejectDataSourceLandingPageModal from '../components/RejectDataSourceLandingPageModal';
import { getColumns, getStaticFilters } from './common';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { useDatasource } from './hooks/useDatasource';
import { usePageMeta } from './hooks/usePageMeta';

const StoreManagementList = () => {
  const { t } = useTranslation('SellerStoreManagement');
  const navigate = useAppNavigate();
  const filters = usePageFilters();
  const [pagination, setPagination] = usePagination();
  const [dataSourceLandingPage, setDataSourceLandingPage] = useState(null);
  const [isApproveDataSourceLandingPageModalVisible, setApproveDataSourceLandingPageModalVisible] =
    useBoolean(false);
  const [isRejectDataSourceLandingPageModalVisible, setRejectDataSourceLandingPageModalVisible] =
    useBoolean(false);
  const { dataSourceLandingPages, isDataSourceLandingPagesFetching, dataSourceLandingPagesTotal } =
    useDatasource({
      filters: { ...getRequestFiltersParams(filters) },
      pagination: { ...getRequestPaginationParams(pagination) },
    });

  const { data: dataSources, isLoading: isDataSourcesLoading } = useAllDataSources({});
  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;

  useResetTableCurrentPageWhenFiltersChanged({ pagination, setPagination, filters });
  useBreadcrumbs();
  usePageMeta();

  const onApproveDataSourceLandingPage = (_dataSourceLandingPage) => {
    setDataSourceLandingPage(_dataSourceLandingPage);
    setApproveDataSourceLandingPageModalVisible(true);
  };

  const onRejectDataSourceLandingPage = (_dataSourceLandingPage) => {
    setDataSourceLandingPage(_dataSourceLandingPage);
    setRejectDataSourceLandingPageModalVisible(true);
  };

  const onApproveDataSourceLandingPageModalClose = () => {
    setDataSourceLandingPage(null);
    setApproveDataSourceLandingPageModalVisible(false);
  };

  const onRejectDataSourceLandingPageModalClose = () => {
    setDataSourceLandingPage(null);
    setRejectDataSourceLandingPageModalVisible(false);
  };

  const columns = getColumns({
    t,
    onApproveDataSourceLandingPage,
    onRejectDataSourceLandingPage,
  });

  const staticFilters = getStaticFilters({
    t,
    dataSources,
    isDataSourcesLoading,
    isSuperUser,
  });

  const onRow = (record) => ({
    onClick: () => {
      navigate(RouteUrls.sellerStoreManagement.detail, {
        widgetSlug: record.payload.widget,
        dataSourceLandingPageId: record.id,
        productCollectionWidgetSlug: record.payload.product_collection_widget,
      });
    },
  });

  return (
    <section className="store-management-list">
      {isSuperUser && (
        <>
          <AkinonFilter title={t('filter')} {...staticFilters} />
          <Divider />
        </>
      )}
      <AkinonTable
        title={t('seller_landing_page_approval_requests')}
        columns={columns}
        loading={isDataSourceLandingPagesFetching}
        dataSource={dataSourceLandingPages}
        optionsUrl={dataSourceLandingPagesUrl}
        total={dataSourceLandingPagesTotal}
        rowKey="id"
        pagination={pagination}
        setPagination={setPagination}
        description={`${dataSourceLandingPagesTotal} ${t('results.found')}`}
        onRow={onRow}
        rowClassName="store-management-list__row"
      />
      <ApproveDataSourceLandingPageModal
        isVisible={isApproveDataSourceLandingPageModalVisible && !!dataSourceLandingPage}
        onClose={onApproveDataSourceLandingPageModalClose}
        dataSourceLandingPage={dataSourceLandingPage}
        queryKey={[
          QueryKey.DATA_SOURCE_LANDING_PAGES,
          { ...getRequestFiltersParams(filters), ...getRequestPaginationParams(pagination) },
        ]}
      />
      <RejectDataSourceLandingPageModal
        isVisible={isRejectDataSourceLandingPageModalVisible && !!dataSourceLandingPage}
        onClose={onRejectDataSourceLandingPageModalClose}
        dataSourceLandingPage={dataSourceLandingPage}
        queryKey={[
          QueryKey.DATA_SOURCE_LANDING_PAGES,
          { ...getRequestFiltersParams(filters), ...getRequestPaginationParams(pagination) },
        ]}
      />
    </section>
  );
};

export default StoreManagementList;
