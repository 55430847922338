import PropTypes from 'prop-types';

const If = ({ condition, then, otherwise }) => {
  if (condition) {
    return then;
  } else {
    return otherwise;
  }
};

If.propTypes = {
  condition: PropTypes.bool.isRequired,
  then: PropTypes.node.isRequired,
  otherwise: PropTypes.node.isRequired,
};

export default If;
