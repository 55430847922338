import { getMostSellingCategoriesUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { useAkinonQuery } from '../adapters/useAkinonQuery';
import { client } from '../client';

export function useMostSellingCategoriesQuery({ params }) {
  const { data, isFetching } = useAkinonQuery({
    queryKey: [QueryKey.MOST_SELLING_CATEGORIES, params],
    queryFn: () => client.get(getMostSellingCategoriesUrl, { params }),
  });

  return {
    mostSellingCategories: data?.most_selling_categories,
    isFetchingMostSellingCategories: isFetching,
  };
}
