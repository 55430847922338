import { QueryParamsBuilder } from '@common/query-params-builder';
import AkinonFilter from '@components/AkinonFilter';
import { usePageFilters } from '@components/AkinonFilter/hooks/store/usePageFilters';
import { openNotification } from '@components/akinonNotification';
import AkinonTable from '@components/AkinonTable';
import { ExportFormat } from '@components/AkinonTable/components/StandardAkinonTableHeader/ExportModal';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { Animations } from '@components/Animate/common';
import Show from '@components/Show';
import { exportPackageItemsUrl, exportPackagesUrl, listPackagesUrl } from '@constants/apiUrls';
import { UserRole } from '@constants/auth';
import { FileExtension, PackageStatus } from '@constants/commontypes';
import { defaultRowKey } from '@constants/index';
import { QueryKey } from '@constants/query';
import { client } from '@services/api/client';
import { queryClient } from '@services/api/queryClient';
import { useUserRole } from '@utils/hooks/useUserRole';
import { Divider } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { useBoolean } from 'react-use';

import { getColumns, getStaticFilters, StaticFiltersNameKey } from '../common';
import BulkUpdatePackages from '../components/BulkUpdatePackages';
import { useDataSource } from '../hooks';
import { ExportOption } from '../PackageList/common';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { usePageMeta } from './hooks/usePageMeta';

const PackagesWaitingForCargo = () => {
  const { t } = useTranslation('Orders');
  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;
  const filters = usePageFilters();
  const [pagination, setPagination] = usePagination();
  const [isBulkUpdatePackagesActive, toggleIsBulkUpdatePackagesActive] = useBoolean(false);

  const {
    dataSource,
    isDataSourceLoading,
    total,
    shippingCompanies,
    shippingMethodOptions,
    dataSources,
  } = useDataSource({
    pagination,
    filters,
    params: {
      status: PackageStatus.PREPARING,
    },
    isSuperUser,
  });

  const onExportSuccess = () => {
    openNotification({
      type: 'success',
      message: t('file.preparing'),
      description: t('file.download.modal.description'),
    });
  };

  const onExportPackagesAsXlsx = ({ fieldsToExport }) => {
    const params = {
      ...filters,
      ...QueryParamsBuilder.new()
        .set('extension', FileExtension.XLSX)
        .set('status', PackageStatus.PREPARING)
        .set('fields', isEmpty(fieldsToExport) ? undefined : fieldsToExport.join(','))
        .build(),
    };
    queryClient.fetchQuery({
      queryKey: [QueryKey.EXPORT_PACKAGES],
      async queryFn() {
        await client.get(exportPackagesUrl, {
          params,
        });
        onExportSuccess();
      },
    });
  };

  const onExportPackageItemsAsXlsx = ({ fieldsToExport }) => {
    const params = {
      ...filters,
      ...QueryParamsBuilder.new()
        .set('extension', FileExtension.XLSX)
        .set('package__status', PackageStatus.PREPARING)
        .set('fields', isEmpty(fieldsToExport) ? undefined : fieldsToExport.join(','))
        .build(),
    };
    queryClient.fetchQuery({
      queryKey: [QueryKey.EXPORT_PACKAGE_ITEMS],
      async queryFn() {
        await client.get(exportPackageItemsUrl, { params });
        onExportSuccess();
      },
    });
  };

  useBreadcrumbs();
  usePageMeta({ toggleIsBulkUpdatePackagesActive });

  const columns = getColumns({
    t,
    isSuperUser,
    staticFilterNameKey: StaticFiltersNameKey.PACKAGE_PREPARING,
  });
  const staticFilters = getStaticFilters({
    t,
    staticFilterNameKey: StaticFiltersNameKey.PACKAGE_PREPARING,
    shippingCompanies,
    shippingMethodOptions,
    dataSources,
    isSuperUser,
  });

  const onExport = ({ exportFormat, exportOption, fieldsToExport }) => {
    if (exportFormat === ExportFormat.XLSX) {
      switch (exportOption) {
        case ExportOption.PACKAGES:
          onExportPackagesAsXlsx({ fieldsToExport });
          break;
        case ExportOption.PACKAGE_ITEMS:
          onExportPackageItemsAsXlsx({ fieldsToExport });
          break;
      }
    }
  };

  return (
    <section>
      <Show when={isBulkUpdatePackagesActive} animateProps={Animations.fade}>
        <BulkUpdatePackages />
      </Show>
      <AkinonFilter {...staticFilters} total={total} includeFileFilter={true} />
      <Divider />
      <AkinonTable
        title={t('packages')}
        columns={columns}
        dataSource={dataSource}
        optionsUrl={listPackagesUrl}
        rowKey={defaultRowKey}
        loading={isDataSourceLoading}
        pagination={pagination}
        setPagination={setPagination}
        total={total}
        exportFormats={[ExportFormat.XLSX]}
        exportOptions={[
          {
            label: t('export_packages'),
            value: ExportOption.PACKAGES,
            loading: queryClient.isFetching([QueryKey.EXPORT_PACKAGES]),
          },
          {
            label: t('export_package_items'),
            value: ExportOption.PACKAGE_ITEMS,
            loading: queryClient.isFetching([QueryKey.EXPORT_PACKAGE_ITEMS]),
          },
        ]}
        onExport={onExport}
        description={`${total} ${t('results.found')}`}
      />
    </section>
  );
};

export default PackagesWaitingForCargo;
