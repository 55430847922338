import { useDataSourcesQuery } from '@services/api/hooks';
import { useDataSourceLandingPagesQuery } from '@services/api/hooks/usePaginatedDataSourceLandingPagesQuery';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import reject from 'lodash/reject';
import uniq from 'lodash/uniq';

export const useDatasource = ({ filters, pagination }) => {
  const { dataSourceLandingPages, isDataSourceLandingPagesFetching, dataSourceLandingPagesTotal } =
    useDataSourceLandingPagesQuery({
      params: {
        ...filters,
        ...pagination,
      },
    });
  const datasourceIds = reject(uniq(map(dataSourceLandingPages, 'datasource')), isNil);
  const { dataSources, isFetchingDataSources } = useDataSourcesQuery({
    params: {
      id__in: datasourceIds.join(','),
      limit: datasourceIds.length,
    },
    queryOptions: {
      enabled: !isEmpty(datasourceIds),
    },
  });

  const data = map(dataSourceLandingPages, (dataSourceLandingPage) => {
    const datasource = dataSources?.find((d) => d.id === dataSourceLandingPage.datasource);
    return {
      ...dataSourceLandingPage,
      datasource,
    };
  });

  return {
    dataSourceLandingPages: data,
    isDataSourceLandingPagesFetching: isDataSourceLandingPagesFetching || isFetchingDataSources,
    dataSourceLandingPagesTotal,
  };
};
