import { UserRole } from '@constants/auth';
import { useUserRole } from '@utils/hooks/useUserRole';
import { useStore } from '@zustand-store/index';
import { setPageMetaSelector } from '@zustand-store/selectors/app';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

export function usePageMeta() {
  const { t } = useTranslation('Marketing');
  const setPageMeta = useStore(setPageMetaSelector);

  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;

  useEffect(() => {
    setPageMeta({
      title: t('campaigns'),
      description: isSuperUser ? t('super_user_campaigns_desc') : t('seller_campaigns_desc'),
    });
  }, [t]);

  useUnmount(() => {
    setPageMeta({});
  });
}
