import { UserRole } from '@constants/auth';
import { RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { useUserRole } from '@utils/hooks/useUserRole';
import useStore from '@zustand-store/index';
import { setPageMetaSelector } from '@zustand-store/selectors/app';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

export function usePageMeta() {
  const { t } = useTranslation('Settings');
  const navigate = useAppNavigate();
  const setPageMeta = useStore(setPageMetaSelector);
  const userRole = useUserRole();

  useEffect(() => {
    setPageMeta({
      title: t('delivery_settings'),
      description: t('delivery_settings_desc'),
      actions: [
        userRole === UserRole.SUPER_USER && {
          label: t('add_new_delivery_setting'),
          icon: <i className="icon-arti" />,
          onClick() {
            navigate(RouteUrls.settings.deliverySettingsShipmentSettingsDetail, { id: '' });
          },
        },
      ],
    });
  }, [t, userRole]);

  useUnmount(() => {
    setPageMeta({});
  });
}
