import { WidgetType } from '@constants/commontypes';
import { useTranslation } from 'react-i18next';

import { StaticFilterKey } from '../common';

export const useStaticFilters = ({ isSuperUser, isPending }) => {
  const { t } = useTranslation('Marketing');
  return {
    staticFiltersFormMetaFields: [
      ...(isSuperUser
        ? [
            {
              key: StaticFilterKey.SELLER,
              placeholder: t('seller'),
            },
          ]
        : []),
      {
        key: StaticFilterKey.NAME,
        placeholder: t('collections_name'),
      },
      {
        key: StaticFilterKey.STATUS,
        widget: WidgetType.SELECT,
        placeholder: t('status'),
        options: [
          { label: t('active'), value: true },
          { label: t('passive'), value: false },
        ],
        widgetProps: {
          showSearch: true,
          optionFilterProp: 'label',
        },
      },
    ].filter(Boolean),
    isStaticFiltersLoading: isPending,
  };
};
