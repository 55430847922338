import { oAuthProvidersUrl } from '@constants/apiUrls';

import { useAkinonMutation } from '../adapters/useAkinonMutation';
import { client } from '../client';

export function usePostOauthProviderMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody }) => client.post(oAuthProvidersUrl, requestBody),
    ...mutationOptions,
  });

  return {
    createProvider: mutate,
    isProviderCreating: isLoading,
  };
}
