import './style.scss';

import PngSystemError from '@assets/images/system-error.png';
import { Col } from 'antd';
import { Trans, useTranslation } from 'react-i18next';

const SystemError = () => {
  const { t } = useTranslation();

  return (
    <div className="system-error-page">
      <Col flex={1} span={24}>
        <span className="system-error-page-title">{t('500.title')}</span>
      </Col>
      <Col flex={1} span={24}>
        <span className="system-error-page-description">
          <Trans
            i18nKey="500.description"
            components={[
              <a key={0} href="mailto:test@akinon.cm">
                {''}
              </a>,
            ]}
          />
        </span>
      </Col>
      <Col flex={1} span={24}>
        <img src={PngSystemError} alt="" className="system-error-page-image" />
      </Col>
    </div>
  );
};

export default SystemError;
