import sum from 'lodash/sum';
import { z } from 'zod';

export const getFormSchema = ({ t }) => {
  return z.object({
    subject: z.string().min(1),
    content: z.string().optional(),
    priority: z.number().optional(),
    attachment: z
      .object({
        fileList: z
          .array(z.object({ size: z.number(), originFileObj: z.instanceof(File) }))
          .nullish(),
      })
      .superRefine(({ fileList }, ctx) => {
        const size10MB = 1024 * 1024 * 10;
        const sumeOfFileSizes = sum(fileList.map((file) => file.size));
        if (sumeOfFileSizes > size10MB) {
          ctx.addIssue({
            message: t('file_size_error_message', { fileSize: '10MB' }),
            code: z.ZodIssueCode.custom,
          });
        }
      })
      .nullish(),
  });
};

export const FormKey = {
  SUBJECT: 'subject',
  CONTENT: 'content',
  PRIORITY: 'priority',
  ATTACHMENT: 'attachment',
};
