import React from 'react';

import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { usePageMeta } from './hooks/usePageMeta';

const SellerStateTransitions = () => {
  useBreadcrumbs();
  usePageMeta();

  return <div>SellerStateTransitions</div>;
};

export default SellerStateTransitions;
