import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { getProductsUrl } from '@constants/apiUrls';
import { useQuery } from '@services/api';
import { fetcher } from '@services/api/client';
import get from 'lodash/get';
import { useLocation } from 'react-router-dom';

/**
 * @param {{pagination: import('antd').PaginationProps, filters: Record<string, any> }} args
 */
export function useProductsQuery({ pagination, filters, is_active = undefined }) {
  const location = useLocation();
  const searchQuery = get(location, ['state', 'searchQuery']);
  const queryResponse = useQuery(
    [
      getProductsUrl,
      {
        params: {
          ...(searchQuery ? { q: searchQuery } : {}),
          ...getRequestPaginationParams(pagination),
          ...getRequestFiltersParams(filters),
          is_active,
        },
      },
    ],
    fetcher
  );

  return {
    ...queryResponse,
    products: queryResponse.data?.results,
    total: queryResponse.data?.count,
  };
}
