import { getAttributesUrl } from '@constants/apiUrls';
import { limitQuery } from '@constants/commontypes';
import { QueryKey } from '@constants/query';

import { useAkinonQuery } from '../adapters/useAkinonQuery';
import { client } from '../client';

export function useAttributesQuery({ queryOptions, params, onSuccess } = {}) {
  const { data, isFetching, isLoading, error } = useAkinonQuery({
    queryKey: [QueryKey.ATTRIBUTES, params],
    async queryFn() {
      const response = await client.get(getAttributesUrl, {
        params: {
          is_active: true,
          ...limitQuery,
          ...params,
        },
      });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    attributes: data?.results ?? [],
    isAttributesLoading: isLoading,
    isFetchingAttributes: isFetching,
    attributesError: error,
  };
}
