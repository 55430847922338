import { findElementByInnerText, getTourSelectorWithFallback } from '@common/index';
import { defaultDateTimeFormat } from '@constants/index';
import { RouteUrls } from '@constants/routeUrls';
import i18n from '@root/i18n';
import { dateFormatter } from '@utils/index';
import { Tag, Tooltip, Typography } from 'antd';
import clsx from 'clsx';
import moment from 'moment';
import { Fragment } from 'react';
import { generatePath, Link } from 'react-router-dom';

import { getPackagesStatusRenderMap, StaticFilterKey } from '../common';

const { Title, Text } = Typography;

export const ExportOption = {
  PACKAGES: 'packages',
  PACKAGE_ITEMS: 'packageItems',
};

export const DataIndex = {
  NUMBER: 'number',
  ORDER: 'order',
  UPDATED_AT: 'updated_at',
  STATUS: 'status',
  SHIPPING_METHOD: 'shippingMethod',
  SHIPPING_COMPANY: 'shippingCompany',
  SELLER: ['order', 'datasource', 'name'],
  EMAIL: ['order', 'shipping_address', 'email'],
  TRACKING_NUMBER: 'tracking_number',
};

export const getColumns = ({ t, isSuperUser }) => {
  const packageStatusRenderMap = getPackagesStatusRenderMap({ t });

  return [
    {
      dataIndex: DataIndex.NUMBER,
      visibleOnFilter: [StaticFilterKey.PACKAGE_NUMBER],
      exportKey: StaticFilterKey.PACKAGE_NUMBER,
      title: t('packaging_no').toLocaleUpperCase(i18n.language),
      render: (_, record, index) => {
        const isFirstRow = index === 0;
        return (
          <Link
            className={clsx('package_list__package-number', {
              'package_list__package-number--first-row': isFirstRow,
            })}
            to={generatePath(RouteUrls.order.packages.packageDetail, {
              packageId: record.id,
            })}
          >
            {record.number}
          </Link>
        );
      },
    },
    {
      dataIndex: DataIndex.ORDER,
      visibleOnFilter: [StaticFilterKey.ORDER_NUMBER],
      exportKey: StaticFilterKey.ORDER_NUMBER,
      title: t('order.number').toLocaleUpperCase(i18n.language),
      render: (_, record, index) => {
        const isFirstRow = index === 0;
        return (
          <Link
            className={clsx('package_list__package-order', {
              'package_list__package-order--first-row': isFirstRow,
            })}
            to={generatePath(RouteUrls.order.orderDetail, {
              orderId: record.order?.id,
            })}
          >
            {record.order?.number}
          </Link>
        );
      },
    },
    isSuperUser && {
      dataIndex: DataIndex.SELLER,
      visibleOnFilter: [StaticFilterKey.SELLER],
      exportKey: StaticFilterKey.SELLER,
      title: t('seller').toLocaleUpperCase(i18n.language),
      render: (sellerName) => <Tooltip title={sellerName}>{sellerName}</Tooltip>,
    },
    {
      dataIndex: DataIndex.EMAIL,
      visibleOnFilter: [StaticFilterKey.ORDER_SHIPPING.ADDRESS_EMAIL],
      exportKey: StaticFilterKey.ORDER_SHIPPING.ADDRESS_EMAIL,
      title: t('email').toLocaleUpperCase(i18n.language),
      render: (shippingAddressEmail) => (
        <Tooltip title={shippingAddressEmail}>{shippingAddressEmail}</Tooltip>
      ),
    },
    {
      dataIndex: DataIndex.TRACKING_NUMBER,
      visibleOnFilter: [StaticFilterKey.TRACKING_NUMBER],
      exportKey: StaticFilterKey.TRACKING_NUMBER,
      title: t('trackingNumber').toLocaleUpperCase(i18n.language),
      render: (trackingNumber) => <Tooltip title={trackingNumber}>{trackingNumber}</Tooltip>,
    },
    {
      dataIndex: DataIndex.UPDATED_AT,
      visibleOnFilter: [StaticFilterKey.UPDATED_AT],
      exportKey: StaticFilterKey.UPDATED_AT,
      title: t('last.modified.date').toLocaleUpperCase(i18n.language),
      render: dateFormatter,
    },
    {
      dataIndex: DataIndex.ORDER,
      exportKey: 'order_date_placed',
      title: t('order.date').toLocaleUpperCase(i18n.language),
      render: (_, record) => moment(record.order?.date_placed).format(defaultDateTimeFormat),
    },
    {
      dataIndex: DataIndex.STATUS,
      visibleOnFilter: [StaticFilterKey.PACKAGE_STATUS],
      exportKey: StaticFilterKey.PACKAGE_STATUS,
      title: t('package_status'),
      render: (packageStatus) => {
        const { tagColor, label } = packageStatusRenderMap[packageStatus];
        return <Tag color={tagColor}>{label}</Tag>;
      },
    },
    {
      dataIndex: DataIndex.SHIPPING_METHOD,
      visibleOnFilter: [StaticFilterKey.SHIPPING_METHOD],
      exportKey: StaticFilterKey.SHIPPING_METHOD,
      title: t('shipping_option').toLocaleUpperCase(i18n.language),
      render: (_, record) => (
        <Tooltip title={record.shipping_option?.name}>{record.shipping_option?.name}</Tooltip>
      ),
    },
    {
      dataIndex: DataIndex.SHIPPING_COMPANY,
      visibleOnFilter: [StaticFilterKey.SHIPPING_COMPANY],
      exportKey: StaticFilterKey.SHIPPING_COMPANY,
      title: t('shipping.company').toLocaleUpperCase(i18n.language),
      render: (_, record) => (
        <Tooltip title={record.shipping_company?.name}>{record.shipping_company?.name}</Tooltip>
      ),
    },
  ];
};

export const getTourSteps = ({ t }) => {
  return [
    {
      selector: '.package-list__bulk-update-button',
      content: (
        <Fragment>
          <Title level={4}>{t('package_list_tutorial.bulk_package_info_update_title')}</Title>
          <Text>{t('package_list_tutorial.bulk_package_info_update')}</Text>
        </Fragment>
      ),
    },
    {
      selector: '.filter-options__filter-modal-open-button',
      content: (
        <Fragment>
          <Title level={4}>{t('package_list_tutorial.dynamic_filters_title')}</Title>
          <Text>{t('package_list_tutorial.dynamic_filters')}</Text>
        </Fragment>
      ),
    },
    {
      selector: '.standard-akinon-table-header',
      content: (
        <Fragment>
          <Title level={4}>{t('package_list_tutorial.table_title')}</Title>
          <Text>{t('package_list_tutorial.table')}</Text>
        </Fragment>
      ),
    },
    {
      selector: '.export-list__export-options-popover',
      content: (
        <Fragment>
          <Title level={4}>{t('package_list_tutorial.table_export_title')}</Title>
          <Text>{t('package_list_tutorial.table_export')}</Text>
        </Fragment>
      ),
    },
    {
      selector: getTourSelectorWithFallback(
        ['.package_list__package-number--first-row'],
        findElementByInnerText(t('packaging_no').toLocaleUpperCase(i18n.language), 'th')
      ),
      content: (
        <Fragment>
          <Title level={4}>{t('package_list_tutorial.table_package_number_title')}</Title>
          <Text>{t('package_list_tutorial.table_package_number')}</Text>
        </Fragment>
      ),
    },
    {
      selector: getTourSelectorWithFallback(
        ['.package_list__package-order--first-row'],
        findElementByInnerText(t('order.number').toLocaleUpperCase(i18n.language), 'th')
      ),
      content: (
        <Fragment>
          <Title level={4}>{t('package_list_tutorial.table_order_number_title')}</Title>
          <Text>{t('package_list_tutorial.table_order_number')}</Text>
        </Fragment>
      ),
    },
  ];
};
