import AkinonButton from '@components/AkinonButton';
import AkinonFlex from '@components/AkinonFlex';
import AkinonSpin from '@components/AkinonSpin';
import { client } from '@services/api/client';
import { IconArrowLeft, IconArrowRight, IconDownload, IconX } from '@tabler/icons-react';
import { downloadBinaryFile, getFilenameFromURL } from '@utils/index';
import { Carousel, Divider, Image, Typography } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FileType, getFileTypeOfUrl } from '../common';

const { Text, Paragraph } = Typography;

const AnnouncementSlider = ({ images }) => {
  return (
    <div className="akinon-announcement-popup__slider mb-5">
      <Carousel
        arrows={true}
        prevArrow={<IconArrowLeft color="white" />}
        nextArrow={<IconArrowRight color="white" />}
        infinite={false}
      >
        {images?.map((imageUrl) => (
          <div className="carousel-item" key={imageUrl}>
            <Image src={imageUrl} alt={imageUrl} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

const AnnouncementTitle = ({ title, displayWithHeader }) => {
  if (displayWithHeader) {
    return (
      <>
        <div className="akinon-announcement-popup__modal-header">
          <div className="akinon-announcement-popup__modal-header-banner" />
          <h1 className="akinon-announcement-popup__modal-header-title">{title}</h1>
        </div>
        <Divider className="akinon-announcement-popup__modal-divider" />
      </>
    );
  }

  return (
    <AkinonFlex className="w-full">
      <Text
        ellipsis={{
          tooltip: <span> {title} </span>,
        }}
        className="text-white text-lg font-bold flex-1 text-left mb-3"
      >
        {title}
      </Text>
    </AkinonFlex>
  );
};

const AnnouncementMessage = ({ message, children, ...rest }) => {
  return (
    <AkinonFlex direction="column" {...rest}>
      <Paragraph className="text-white  akinon-announcement-popup__message">{message}</Paragraph>
      {children}
    </AkinonFlex>
  );
};

const AnnouncementFileAttachments = ({ announcementFileAttachments }) => {
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);

  const startDownload = async () => {
    setIsDownloading(true);
    const config = {
      responseType: 'blob',
    };
    try {
      const data = await client.get(downloadUrl, config);
      downloadBinaryFile(data, getFilenameFromURL(downloadUrl));
    } catch (e) {
    } finally {
      setDownloadUrl(null);
      setIsDownloading(false);
    }
  };

  useEffect(() => {
    if (downloadUrl) {
      startDownload();
    }
  }, [downloadUrl]);

  return (
    <>
      <div className="mb-3">
        {announcementFileAttachments?.length > 0 && (
          <Divider className="akinon-announcement-popup__modal-divider my-3" />
        )}
        {announcementFileAttachments?.map((attachment) => {
          return (
            <div key={attachment.file}>
              <div className="flex justify-between">
                <AkinonButton
                  href={attachment.file}
                  target="_blank"
                  type="link"
                  download
                  className="px-0 "
                >
                  <Text
                    ellipsis={{
                      tooltip: <span>{attachment.file}</span>,
                    }}
                    className="text-dodger-blue font-bold capitalize underline"
                  >
                    {attachment.file.split('/').pop()}
                  </Text>
                </AkinonButton>
                <div className="download-icon-wrapper cursor-pointer">
                  <AkinonSpin
                    spinning={isDownloading && downloadUrl === attachment.file}
                    tip=""
                    size="small"
                  >
                    <IconDownload size={16} onClick={() => setDownloadUrl(attachment.file)} />
                  </AkinonSpin>
                </div>
              </div>
              <Divider className="akinon-announcement-popup__modal-divider my-3" />
            </div>
          );
        })}
      </div>
    </>
  );
};

export const AnnouncementPopupContent = ({ onClose, announcement }) => {
  const { t } = useTranslation();
  const announcementFileAttachments = announcement.attachments
    ?.map((attachment) =>
      getFileTypeOfUrl(attachment.file) === FileType.DOCUMENT ? attachment : false
    )
    .filter(Boolean);
  const announcementImages = announcement.attachments
    ?.map((attachment) =>
      getFileTypeOfUrl(attachment.file) === FileType.IMAGE ? attachment.file : false
    )
    .filter(Boolean);

  if (!isEmpty(announcementImages))
    return (
      <ImageAnnouncementPopupContent
        images={announcementImages}
        announcementFileAttachments={announcementFileAttachments}
        announcement={announcement}
        onClose={onClose}
      />
    );

  return (
    <>
      <AnnouncementTitle title={announcement.title} displayWithHeader />
      <AnnouncementMessage message={announcement.message}>
        <AnnouncementFileAttachments announcementFileAttachments={announcementFileAttachments} />
      </AnnouncementMessage>
      <AkinonButton onClick={onClose}>{t('modal_close')}</AkinonButton>
      <IconX className="top-3 right-3 absolute cursor-pointer text-slate-white" onClick={onClose} />
    </>
  );
};

export const ImageAnnouncementPopupContent = ({
  images,
  announcementFileAttachments,
  announcement,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <AnnouncementSlider images={images} />
      <AnnouncementTitle title={announcement.title} />
      <AnnouncementMessage message={announcement.message}>
        <AnnouncementFileAttachments announcementFileAttachments={announcementFileAttachments} />
      </AnnouncementMessage>
      <AkinonButton onClick={onClose}>{t('modal_close')}</AkinonButton>
      <IconX className="top-3 right-3 absolute cursor-pointer text-slate-white" onClick={onClose} />
    </>
  );
};
