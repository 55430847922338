import { attributeSetUrl } from '@constants/apiUrls';
import { limitQuery } from '@constants/commontypes';
import { QueryKey } from '@constants/query';

import { useAkinonQuery } from '../adapters/useAkinonQuery';
import { client } from '../client';

export function useAttributeSetQuery({ attributeSetId, queryOptions, onSuccess, params } = {}) {
  const { data, isFetching, isLoading, error } = useAkinonQuery({
    queryKey: [QueryKey.ATTRIBUTE_SET, attributeSetId, params],
    async queryFn() {
      const response = await client.get(attributeSetUrl, {
        urlParams: { attributeSetId },
        params: { ...limitQuery, ...params },
      });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    attributeSet: data,
    isAttributeSetLoading: isLoading,
    isFetchingAttributeSet: isFetching,
    attributeSetError: error,
  };
}
