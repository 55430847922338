import './styles.scss';

import AkinonButton from '@components/AkinonButton';
import AkinonFormBuilder from '@components/AkinonFormBuilder';
import If from '@components/If';
import Show from '@components/Show';
import Box from '@components/utility/box';
import { verticalFormItemLayout } from '@constants/layoutTypes';
import useStore from '@zustand-store/index';
import { variantsFormDynamicMetaFieldsSelector } from '@zustand-store/selectors/preOffer';
import { Divider, Form, Modal, Pagination, Space } from 'antd';
import clsx from 'clsx';
import last from 'lodash/last';
import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import range from 'lodash/range';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormModalItem from './components/FormModalItem';

const FormModal = ({
  title,
  description,
  updateFormTitle,
  formMeta,
  dataSource,
  isVisible,
  setInvisible,
  onSubmit,
  productsToBeAddedTableForm,
  isAttributeForm,
}) => {
  const { t } = useTranslation('ProductsAndCategories');
  const [pagination, setPagination] = useState({ current: 1 });
  const numberOfItemsPerPagination = 4;
  const isPaginationEnabled = formMeta.length > numberOfItemsPerPagination;
  const [form] = Form.useForm();
  const variantsKeys = useStore(variantsFormDynamicMetaFieldsSelector).map((metaField) =>
    last(metaField.key.split('.'))
  );

  const paginatedFormMeta = isPaginationEnabled
    ? formMeta.slice(
        (pagination.current - 1) * numberOfItemsPerPagination,
        pagination.current * numberOfItemsPerPagination
      )
    : formMeta;

  const numberOfTableColumns = dataSource?.length;
  const variantsPairs = dataSource.map((dataSourceItem) =>
    Object.values(pick(dataSourceItem, variantsKeys))
  );

  const [isProductSelectedList, setIsProductSelectedList] = useState(
    range(numberOfTableColumns).map(() => false)
  );

  useEffect(() => {
    setPagination({ current: 1 });
    setIsProductSelectedList(range(numberOfTableColumns).map(() => false));
    if (isVisible) {
      form.setFieldsValue(productsToBeAddedTableForm.getFieldsValue());
    }
  }, [isVisible]);

  const commonFormItemWidgetProps = {
    style: {
      width: `${40 / paginatedFormMeta.length}vw`,
    },
  };

  const rootFormBuilderMeta = paginatedFormMeta.map((metaItem) => ({
    ...metaItem,
    key: `${metaItem.key}.root`,
    widgetProps: {
      ...metaItem.widgetProps,
      ...commonFormItemWidgetProps,
    },
  }));

  const onModalClose = () => {
    form.resetFields();
    setInvisible();
  };

  const handleFormSubmit = () => {
    const formValues = form.getFieldsValue();

    form.resetFields();
    productsToBeAddedTableForm.setFieldsValue(
      Object.entries(formValues).reduce(
        (formValuesWithoutRootValues, [formItemKey, formItemValue]) => ({
          ...formValuesWithoutRootValues,
          [formItemKey]: omit(formItemValue, 'root'),
        }),
        {}
      )
    );
    setInvisible();
    onSubmit?.(formValues);
  };

  const handleCleanAllContent = () => {
    form.resetFields();
  };

  const handleApplyToAll = () => {
    form.setFieldsValue(
      Object.entries(form.getFieldsValue()).reduce(
        (updatedFormFieldsValue, [formItemKey, formItemValue]) => ({
          ...updatedFormFieldsValue,
          [formItemKey]: mapValues(formItemValue, () => formItemValue.root),
        }),
        {}
      )
    );
  };

  const handlePaginationChange = (page) => {
    setPagination({ current: page });
  };

  const handleApplyToSelectedOnes = () => {
    const selectedProductIndices = isProductSelectedList.reduce(
      (selectedProductIndices, isProductSelected, index) =>
        isProductSelected ? [...selectedProductIndices, index] : selectedProductIndices,
      []
    );
    form.setFieldsValue(
      Object.entries(form.getFieldsValue()).reduce(
        (updatedFormFieldsValue, [attributeKey, { root: attributeValue }]) => ({
          ...updatedFormFieldsValue,
          [attributeKey]: selectedProductIndices.reduce(
            (updatedAttributeValues, selectedProductIndex) => ({
              ...updatedAttributeValues,
              [selectedProductIndex]: attributeValue,
            }),
            {}
          ),
        }),
        {}
      )
    );
  };

  return (
    <Modal
      className="form-modal"
      onCancel={onModalClose}
      open={isVisible}
      width={'75%'}
      destroyOnClose
      footer={null}
    >
      <Box className="box-primary form-box">
        <Form
          className="akn-form"
          layout="vertical"
          form={form}
          onFinish={handleFormSubmit}
          wrapperCol={verticalFormItemLayout.wrapperCol}
        >
          <div className="form-modal__header">
            <div className="form-modal__header-banner" />
            <div className="form-modal__header-title">{title}</div>
          </div>
          <Divider className="form-modal__divider" />
          <div className="form-modal__description">{description}</div>
          <Divider orientation="left" className="form-modal__divider">
            {updateFormTitle.toUpperCase()}
          </Divider>
          <div
            className={clsx('form-modal__root-form-builder', {
              'singular-form-modal__root-form-builder': formMeta.length === 1,
            })}
          >
            <AkinonFormBuilder form={form} meta={rootFormBuilderMeta} />
          </div>
          <If
            condition={Boolean(isAttributeForm)}
            then={
              <Space direction="horizontal" className="form-modal__form-actions">
                <AkinonButton onClick={handleApplyToSelectedOnes} htmlType="button" type="primary">
                  {t('apply.to.selected.ones').toUpperCase()}
                </AkinonButton>
                <AkinonButton onClick={handleCleanAllContent} htmlType="button" type="danger">
                  {t('clean.all.content').toUpperCase()}
                </AkinonButton>
              </Space>
            }
            otherwise={
              <Space direction="horizontal" className="form-modal__form-actions">
                <AkinonButton onClick={handleApplyToAll} htmlType="button" type="primary">
                  {t('apply.to.all').toUpperCase()}
                </AkinonButton>
                <AkinonButton onClick={handleApplyToSelectedOnes} htmlType="button" type="primary">
                  {t('apply.to.selected.ones').toUpperCase()}
                </AkinonButton>
              </Space>
            }
          />
          <Show when={Boolean(isPaginationEnabled)}>
            <section className="form-modal__pagination-box">
              <Pagination
                pageSize={numberOfItemsPerPagination}
                total={formMeta.length}
                className="form-modal__pagination"
                onChange={handlePaginationChange}
                simple
              />
            </section>
          </Show>
          {range(numberOfTableColumns).map((_, index) => (
            <FormModalItem
              key={index}
              order={index}
              isAttributeForm={isAttributeForm}
              formMeta={paginatedFormMeta}
              isProductSelectedList={isProductSelectedList}
              setIsProductSelectedList={setIsProductSelectedList}
              variantsPairs={variantsPairs}
              form={form}
              commonFormItemWidgetProps={commonFormItemWidgetProps}
            />
          ))}
          <Space>
            <AkinonButton type="primary" htmlType="submit">
              {t('save').toUpperCase()}
            </AkinonButton>
            <AkinonButton onClick={handleCleanAllContent} type="danger" htmlType="button">
              {t('clean.all.content').toUpperCase()}
            </AkinonButton>
          </Space>
        </Form>
      </Box>
    </Modal>
  );
};

export default FormModal;
