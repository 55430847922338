import { postBulkPackageListUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { client } from '@services/api/client';

/**
 * @param {{ mutationOptions: import('@tanstack/react-query').UseMutationOptions }} args
 */
export function useBulkImportPackagesMutation({ mutationOptions } = {}) {
  const { isLoading, mutate, data, isError, isSuccess, reset } = useAkinonMutation({
    mutationFn: ({ formData, onProgress }) =>
      client.post(postBulkPackageListUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => onProgress?.(progressEvent),
      }),
    ...mutationOptions,
  });

  return {
    bulkImportPackages: mutate,
    resetBulkImportPackages: reset,
    isBulkImportingPackages: isLoading,
    isBulkImportPackagesError: isError,
    isBulkImportPackagesSuccess: isSuccess,
    bulkImportPackagesData: data,
  };
}
