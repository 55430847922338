import { z } from 'zod';

export const FormKey = {
  EMAIL: 'email',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
};

export const schema = z.object({
  [FormKey.EMAIL]: z.string().email().min(1),
  [FormKey.FIRST_NAME]: z.string(),
  [FormKey.LAST_NAME]: z.string(),
});
