import AkinonInput, { AkinonTextArea } from '@components/AkinonInput';
import AkinonSelect from '@components/AkinonSelect';
import AkinonSwitch from '@components/AkinonSwitch';

export const forbiddenKeys = ['SELLER_BARCODE', 'SELLER_BASECODE', 'SELLER_DESCRIPTION'];

export const ApiConfigType = {
  TEXT: 'text',
  AREA: 'area',
  DROPDOWN: 'dropdown',
  BOOL: 'bool',
};

export const ConfigTypeInputMap = {
  [ApiConfigType.TEXT]: AkinonInput,
  [ApiConfigType.AREA]: AkinonTextArea,
  [ApiConfigType.DROPDOWN]: AkinonSelect,
  [ApiConfigType.BOOL]: AkinonSwitch,
};
