import { QueryKey } from '@constants/query';
import { getConversations } from '@services/api/orders/message';
import { useQuery } from '@tanstack/react-query';

export function useOrderMessagesQuery({ requestConfig, queryOption = {} }) {
  const queryKey = [QueryKey.CONVERSATIONS, requestConfig];

  const { data, isFetching, isLoading, error } = useQuery({
    queryKey,
    queryFn: () => getConversations({ requestConfig }),
    ...queryOption,
  });

  return {
    orderMessages: data?.results,
    isOrderMessagesLoading: isLoading,
    isOrderMessagesFetching: isFetching,
    orderMessagesError: error,
    orderMessagesTotal: data?.count,
  };
}
