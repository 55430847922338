import { IconFileExport, IconFileImport } from '@tabler/icons-react';

import ExportsTab from './components/ExportsTab';
import ImportsTab from './components/ImportTab';

export const PageStep = {
  IMPORTS: 0,
  EXPORTS: 1,
};

export const getStepItems = ({ t }) => {
  return [
    {
      title: t('imports'),
      content: <ImportsTab />,
      icon: <IconFileImport />,
      key: PageStep.IMPORTS,
    },
    {
      title: t('exports'),
      content: <ExportsTab />,
      icon: <IconFileExport />,
      key: PageStep.EXPORTS,
    },
  ];
};
