import { createSelectOptions } from '@common/index';
import AkinonButton from '@components/AkinonButton';
import { UserRole } from '@constants/auth';
import { WidgetType } from '@constants/commontypes';
import { RouteUrls } from '@constants/routeUrls';
import { Tag, Tooltip } from 'antd';

import CurrentAttributesSummary from './components/CurrentAttributesSummary';
import RequestedAttributesSummary from './components/RequestedAttributesSummary';

export const RevisionStatus = {
  SUBMIT: {
    DRAFT: 0,
    SUBMITTED: 1,
  },
  APPROVE: {
    APPROVED: 2,
    PUBLISHING: 4,
    PUBLISHED: 5,
  },
  REJECTED: 3,
};

export const revisionStatusMapping = {
  revision_submitted: [RevisionStatus.SUBMIT.DRAFT, RevisionStatus.SUBMIT.SUBMITTED],
  revision_approved: [
    RevisionStatus.APPROVE.APPROVED,
    RevisionStatus.APPROVE.PUBLISHING,
    RevisionStatus.APPROVE.PUBLISHED,
  ],
  revision_rejected: [RevisionStatus.REJECTED],
};

const revisionColors = {
  blue: [RevisionStatus.SUBMIT.DRAFT, RevisionStatus.SUBMIT.SUBMITTED],
  green: [
    RevisionStatus.APPROVE.APPROVED,
    RevisionStatus.APPROVE.PUBLISHING,
    RevisionStatus.APPROVE.PUBLISHED,
  ],
  red: [RevisionStatus.REJECTED],
};

const getRevisionStatuses = ({ t }) => [
  { value: Object.values(RevisionStatus.SUBMIT).join(','), label: t('revision_submitted') },
  { value: Object.values(RevisionStatus.APPROVE).join(','), label: t('revision_approved') },
  { value: RevisionStatus.REJECTED, label: t('revision_rejected') },
];

const getStatusText = ({ t, status }) => {
  return t(
    Object.keys(revisionStatusMapping).find((key) => revisionStatusMapping[key].includes(status))
  );
};

const getTagColor = ({ status }) => {
  return Object.keys(revisionColors).find((key) => revisionColors[key].includes(status));
};

export const DataIndex = {
  NAME: 'name',
  ID: 'id',
  CURRENT_ATTRIBUTES: 'current_attributes',
  NEW_ATTRIBUTES: 'attributes',
  STATUS: 'status',
};

const StaticFilterKey = {
  PRODUCT: 'product',
  STATUS: 'status__in',
};

export const getStaticFilters = ({ t, products }) => {
  return {
    staticFiltersFormMetaFields: [
      {
        key: StaticFilterKey.STATUS,
        placeholder: t('status'),
        widget: WidgetType.SELECT,
        options: getRevisionStatuses({ t }),
      },
      {
        key: StaticFilterKey.PRODUCT,
        placeholder: t('product_name'),
        widget: WidgetType.SELECT,
        options: createSelectOptions(products, {
          labelKey: 'name',
          valueKey: 'id',
        }),
        widgetProps: {
          showSearch: true,
          optionFilterProp: 'label',
        },
      },
    ],
  };
};

export function getColumns({
  t,
  userRole,
  attributes,
  setIsApproveModalVisible,
  setIsRejectModalVisible,
  setSelectedRevision,
  setIsRevisionDetailModalVisible,
  setRevisionDetailModalInfo,
  navigate,
}) {
  const isSuperUser = userRole === UserRole.SUPER_USER;
  return [
    {
      dataIndex: DataIndex.NAME,
      visibleOnFilter: [StaticFilterKey.PRODUCT],
      title: t('product_name'),
      render: (_newName, record) => {
        if (!isSuperUser) {
          return (
            <AkinonButton
              htmlType="button"
              type="link"
              onClick={() => {
                navigate(RouteUrls.productsAndCategories.products.detail, {
                  productId: record.product,
                  state: {
                    isEditDisabled: true,
                  },
                });
              }}
            >
              {record?.[DataIndex.CURRENT_ATTRIBUTES]?.name}
            </AkinonButton>
          );
        }

        return record?.[DataIndex.CURRENT_ATTRIBUTES]?.name;
      },
    },
    {
      dataIndex: DataIndex.ID,
      title: t('sku'),
      render: (_, record) => {
        return record?.product_detail?.sku;
      },
    },
    {
      dataIndex: DataIndex.CURRENT_ATTRIBUTES,
      title: t('current_product_attributes'),
      render: (currentAttributes = {}, record) => {
        return (
          <CurrentAttributesSummary
            currentAttributes={currentAttributes}
            record={record}
            attributes={attributes}
            setIsRevisionDetailModalVisible={setIsRevisionDetailModalVisible}
            setRevisionDetailModalInfo={setRevisionDetailModalInfo}
          />
        );
      },
    },
    {
      dataIndex: DataIndex.NEW_ATTRIBUTES,
      title: t('requested_product_attributes'),
      render: (requestedAttributes, record) => {
        return (
          <RequestedAttributesSummary
            attributes={attributes}
            requestedAttributes={requestedAttributes}
            record={record}
            setIsRevisionDetailModalVisible={setIsRevisionDetailModalVisible}
            setRevisionDetailModalInfo={setRevisionDetailModalInfo}
          />
        );
      },
    },
    {
      dataIndex: DataIndex.STATUS,
      visibleOnFilter: [StaticFilterKey.STATUS],
      title: t('status'),
      render: (status) => {
        const statusText = getStatusText({ t, status });
        const statusColor = getTagColor({ status });
        return (
          <Tooltip title={statusText}>
            <Tag color={statusColor}>{statusText}</Tag>
          </Tooltip>
        );
      },
    },
    isSuperUser && {
      dataIndex: 'actions',
      title: t('actions'),

      render: (_, record) => {
        if (record?.status === RevisionStatus.SUBMIT.SUBMITTED)
          return (
            <div className="w-full flex gap-1 flex-auto">
              <AkinonButton
                type="primary"
                className="w-1/2"
                onClick={() => {
                  setIsApproveModalVisible(true);
                  setSelectedRevision(record?.id);
                }}
                size="small"
              >
                {t('approve').toUpperCase()}
              </AkinonButton>
              <AkinonButton
                type="danger"
                className="w-1/2"
                onClick={() => {
                  setIsRejectModalVisible(true);
                  setSelectedRevision(record?.id);
                }}
                size="small"
              >
                {t('reject').toUpperCase()}
              </AkinonButton>
            </div>
          );
      },
    },
  ];
}
