import { openNotification } from '@components/akinonNotification';
import { getOrderDetail } from '@services/api';
import { useQuery } from '@tanstack/react-query';
import { T } from '@utils/languageProvider';

export const orderDetailQueryKey = ['order-detail'];

/**
 * @typedef {import('@tanstack/react-query').UseQueryResult} UseQueryResult
 * @returns {import('@tanstack/react-query').UseQueryResult}
 */

export const useOrderDetailQuery = ({ orderId, enabled = true }) => {
  const queryKey = ['order-detail', { orderId }];
  const queryFn = () => getOrderDetail({ orderId });

  const { data, error, mutate, isFetching, ...rest } = useQuery({
    queryKey,
    queryFn,
    enabled,
  });

  const onError = () => {
    const message = error?.response?.data?.detail;

    openNotification({
      message: T('error'),
      description: message,
      type: 'error',
    });
  };

  if (error) {
    onError();
  }

  return {
    ...rest,
    data,
    mutate,
    isLoading: isFetching,
    error,
  };
};
