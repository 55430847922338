import { postProductRevisionUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { client } from '@services/api/client';

export function usePostCreateProductRevisionMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ productId, requestBody }) =>
      client.post(postProductRevisionUrl, requestBody, {
        urlParams: { productId },
      }),
    ...mutationOptions,
  });

  return {
    createProductRevision: mutate,
    isCreatingProductRevision: isLoading,
  };
}
