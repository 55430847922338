import { postDataSourceUpdateUserUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { client } from '@services/api/client';

export const useDataSourceUpdateUserMutation = ({ mutationOptions } = {}) => {
  const { mutate, isLoading, error } = useAkinonMutation({
    mutationFn: ({ dataSourceId, userId, params }) =>
      client.patch(postDataSourceUpdateUserUrl, params, {
        urlParams: { dataSourceId, userId },
      }),
    ...mutationOptions,
  });

  return {
    updateUser: mutate,
    isUpdatingUser: isLoading,
    updateUserError: error,
  };
};
