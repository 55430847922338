import { postResetPassword } from '@services/api';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';

export function usePostResetPasswordMutation({ mutationOptions } = {}) {
  const { mutate, isLoading, error } = useAkinonMutation({
    mutationFn: postResetPassword,
    ...mutationOptions,
  });

  return {
    sendResetPasswordMail: mutate,
    isSendingResetPasswordMail: isLoading,
    sendResetPasswordMailError: error,
  };
}
