import * as z from 'zod';

export const FormKey = {
  OLD_PASSWORD: 'old_password',
  NEW_PASSWORD: 'new_password',
  NEW_PASSWORD_REPEAT: 'new_password_repeat',
};

export const getFormSchema = ({ t }) => {
  return z
    .object({
      [FormKey.OLD_PASSWORD]: z.string().min(1),
      [FormKey.NEW_PASSWORD]: z.string().min(1),
      [FormKey.NEW_PASSWORD_REPEAT]: z.string().min(1),
    })
    .refine(
      (values) => {
        return values[FormKey.NEW_PASSWORD] === values[FormKey.NEW_PASSWORD_REPEAT];
      },
      {
        message: t('repeated.pwd.validation.message'),
        path: [FormKey.NEW_PASSWORD_REPEAT],
      }
    );
};
