import './styles.scss';

import { getCroppedImageBlob } from '@common/image';
import ActionIcon from '@components/ActionIcon/ActionIcon';
import AkinonButton from '@components/AkinonButton';
import AkinonSpin from '@components/AkinonSpin';
import Show from '@components/Show';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useCrop } from '@hooks/useCrop';
import { useStringCaseConverter } from '@hooks/useStringCaseConverter';
import { useDeletePreOfferImageMutation } from '@services/api/hooks/useDeletePreOfferImageMutation';
import { usePatchPreOfferImageMutation } from '@services/api/hooks/usePatchPreOfferImageMutation';
import {
  IconPhotoEdit,
  IconRotate2,
  IconRotateClockwise2,
  IconTrash,
  IconX,
} from '@tabler/icons-react';
import { Image, Modal, Typography } from 'antd';
import { nanoid } from 'nanoid';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactCrop from 'react-image-crop';

const { Title, Text } = Typography;

function SortableImageItem({ image }) {
  const { t } = useTranslation();
  const { toUpperCase } = useStringCaseConverter();
  const imageRef = useRef(null);
  const [isImageModalVisible, setIsImageModalVisible] = useState(false);
  const {
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    rotation,
    setRotation,
    scale,
    resetCrop,
    isCropValid,
  } = useCrop();
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: image.id,
  });
  const { deletePreOfferImage, isDeletingPreOfferImage } = useDeletePreOfferImageMutation();
  const { patchPreOfferImage, isPatchingPreOfferImage } = usePatchPreOfferImageMutation();

  const src = image.image;
  const imageExtension = src.split('.').pop();
  const imageType = `image/${imageExtension}`;
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const getDeletePreOfferImageHandler = (imageId) => () => {
    deletePreOfferImage({ imageId });
  };

  const onEditImageClick = () => {
    setIsImageModalVisible(true);
  };

  const closeModal = () => {
    setIsImageModalVisible(false);
    resetCrop();
  };

  const onSaveChanges = async () => {
    const croppedImageBlob = await getCroppedImageBlob({
      rotation,
      scale,
      image: imageRef.current,
      crop: completedCrop,
      type: imageType,
    });
    const imageFile = new File([croppedImageBlob], nanoid().concat('.', imageExtension), {
      type: imageType,
    });
    const formData = new FormData();
    formData.append('image', imageFile);
    patchPreOfferImage(
      { imageId: image.id, requestBody: formData },
      {
        onSettled() {
          closeModal();
        },
      }
    );
  };

  const onRotateClockwise = () => {
    setRotation((prevRotation) => (prevRotation + 90) % 360);
  };

  const onRotateCounterClockwise = () => {
    setRotation((prevRotation) => (prevRotation - 90) % 360);
  };

  return (
    <AkinonSpin spinning={isDeletingPreOfferImage || isPatchingPreOfferImage}>
      <article ref={setNodeRef} key={src} className="offer-sortable-image-item" style={style}>
        <Image
          {...attributes}
          {...listeners}
          className="offer-sortable-image-item__image"
          src={src}
          alt="product"
          preview={false}
        />
        <div className="offer-sortable-image-item__actions">
          <ActionIcon Icon={IconTrash} onClick={getDeletePreOfferImageHandler(image.id)} />
          <ActionIcon Icon={IconPhotoEdit} onClick={onEditImageClick} />
        </div>
      </article>
      {src && (
        <Modal
          className="offer-sortable-image-item-edit-modal"
          closeIcon={<ActionIcon Icon={IconX} onClick={closeModal} />}
          open={isImageModalVisible}
          footer={null}
          destroyOnClose
        >
          <Title className="offer-sortable-image-item-edit-modal__title" level={4}>
            {t('edit_image')}
          </Title>
          <div className="offer-sortable-image-item-edit-modal__options">
            <ActionIcon onClick={onRotateCounterClockwise} Icon={IconRotate2} />
            <ActionIcon onClick={onRotateClockwise} Icon={IconRotateClockwise2} />
          </div>
          {src && (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(crop) => setCompletedCrop(crop)}
              style={{ width: '100%' }}
            >
              <img
                crossOrigin="anonymous"
                ref={imageRef}
                src={src}
                alt="product"
                style={{
                  border: '1px solid #717a8e',
                  width: '100%',
                  transform: `scale(${scale}) rotate(${rotation}deg)`,
                }}
              />
            </ReactCrop>
          )}
          <Show when={!isCropValid}>
            <Text className="offer-sortable-image-item-edit-modal__crop-warning">
              {t('crop_size_constraint')}
            </Text>
          </Show>
          <AkinonButton
            disabled={!isCropValid}
            loading={isPatchingPreOfferImage}
            onClick={onSaveChanges}
          >
            {toUpperCase(t('save_changes'))}
          </AkinonButton>
        </Modal>
      )}
    </AkinonSpin>
  );
}
export default SortableImageItem;
