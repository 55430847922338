import { getProductOfferUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';

import { useAkinonQuery } from '../adapters/useAkinonQuery';
import { client } from '../client';

export function useProductOfferQuery({ offerId, queryOptions, onSucces, params } = {}) {
  const { data, isFetching, isLoading, error } = useAkinonQuery({
    queryKey: [QueryKey.PRODUCT_OFFER, offerId, params],
    async queryFn() {
      const response = await client.get(getProductOfferUrl, {
        params,
        urlParams: { offerId },
      });
      onSucces?.(response);

      return response;
    },
    ...queryOptions,
  });

  return {
    productOffer: data,
    isProductOfferLoading: isLoading,
    isFetchingProductOffer: isFetching,
    productOfferError: error,
  };
}
