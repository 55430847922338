import { limitQuery } from '@constants/commontypes';
import { QueryKey } from '@constants/query';
import { getDynamicSettings } from '@services/api/dynamicSettings';
import { useQuery } from '@tanstack/react-query';

export const useDynamicSettingsListQuery = ({ queryOptions } = {}) => {
  const queryKey = [QueryKey.DYNAMIC_SETTINGS];

  const queryFn = () =>
    getDynamicSettings({
      ...limitQuery,
    });
  const { data, isFetching, isLoading, error } = useQuery({ queryKey, queryFn, ...queryOptions });

  return {
    dynamicSettingsList: data?.results,
    dynamicSettingsListResult: data,
    isDynamicSettingsListFetching: isFetching,
    isDynamicSettingsListLoading: isLoading,
    dynamicSettingsListError: error,
  };
};
