import './styles.scss';

import { getPathFragments } from '@common/routing';
import { useIsMobile } from '@hooks/useIsMobile';
import { useAttributeOptionsMapQueries } from '@services/api/hooks/useAttributeOptionsMapQueries';
import { Col, Form, Row, Typography } from 'antd';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useMount, usePrevious, useUnmount } from 'react-use';

import {
  getCompoundFiltersFormMeta,
  getCurrentFiltersFormMeta,
  getInitialFormValues,
  removeEmptyFilters,
} from './common';
import FilterOptions from './components/FilterOptions';
import { fillFilterWidgets } from './components/FilterOptions/common';
import FiltersForm from './components/FiltersForm';
import FilterFormContext from './contexts/FilterForm';
import useFiltersSlice from './hooks/store/useFiltersSlice';

const { Title } = Typography;

/**
 * @param {{ title: string, staticFiltersFormMetaFields: any[], dynamicFiltersFormMetaFields: any[], includeFileFilter: boolean, isDynamicFiltersLoading: boolean, isStaticFiltersLoading: boolean }} props
 */
const AkinonFilter = ({
  title,
  staticFiltersFormMetaFields,
  dynamicFiltersFormMetaFields,
  isDynamicFiltersLoading,
  isStaticFiltersLoading,
  includeFileFilter = false,
  total,
}) => {
  const location = useLocation();
  const searchQuery = get(location, ['state', 'searchQuery']);
  const { t } = useTranslation();
  const [filterForm] = Form.useForm();
  const { pageFilters, setFilters } = useFiltersSlice();
  const isMobile = useIsMobile();
  const [isFilterOptionsModalOpen, setIsFilterOptionsModalOpen] = useState(false);
  const [currentFiltersFormMeta, setCurrentFiltersFormMeta] = useReducer(
    (_, metaFields) => getCurrentFiltersFormMeta(metaFields, isMobile),
    getCurrentFiltersFormMeta(
      getCompoundFiltersFormMeta({
        staticFiltersFormMetaFields,
        dynamicFiltersFormMetaFields,
        pageFilters,
      })
    )
  );
  const previousStaticFiltersFormMetaFields = usePrevious(staticFiltersFormMetaFields);
  const previousDynamicFiltersFormMetaFields = usePrevious(dynamicFiltersFormMetaFields);
  const { pathname, search } = location;
  const { mainPath } = getPathFragments(pathname);
  const filterKey = `${mainPath}${search}`;

  useAttributeOptionsMapQueries({
    attributes: dynamicFiltersFormMetaFields,
    queryOptions: {
      enabled: false,
    },
  });

  useMount(() => {
    delete pageFilters.filter_file;
    const fields = getCompoundFiltersFormMeta({
      staticFiltersFormMetaFields,
      dynamicFiltersFormMetaFields,
      pageFilters,
    });
    const initialValues = getInitialFormValues({
      pageFilters,
      fields,
      searchQuery,
    });

    filterForm.setFieldsValue(initialValues);
  });

  const updateFiltersFormMeta = () => {
    const fields = getCompoundFiltersFormMeta({
      staticFiltersFormMetaFields,
      dynamicFiltersFormMetaFields,
      pageFilters,
    });
    fillFilterWidgets({ metaFields: fields })
      .then((newFields) => {
        setCurrentFiltersFormMeta(newFields);
      })
      .catch(() => {
        setCurrentFiltersFormMeta(fields);
      });
  };

  useEffect(() => {
    if (
      !isEqual(previousStaticFiltersFormMetaFields, staticFiltersFormMetaFields) ||
      !isEqual(previousDynamicFiltersFormMetaFields, dynamicFiltersFormMetaFields)
    ) {
      updateFiltersFormMeta();
    }
  }, [staticFiltersFormMetaFields, dynamicFiltersFormMetaFields]);

  useEffect(() => {
    updateFiltersFormMeta();
  }, [isMobile]);

  const applyFilters = () => {
    setFilters({
      pagePath: filterKey,
      filters: {
        ...merge(filterForm.getFieldsValue(true), pick(pageFilters, 'filter_file')),
      },
    });
  };

  const clearFilters = () => {
    filterForm.resetFields();
    setFilters({
      pagePath: filterKey,
      filters: {},
    });
  };

  useEffect(() => {
    filterForm.setFieldsValue(
      currentFiltersFormMeta.fields.reduce((acc, field) => {
        acc[field.key] = filterForm.getFieldsValue(true)[field.key];
        return acc;
      }, {})
    );
    applyFilters();
  }, [currentFiltersFormMeta]);

  const handleOnValuesChange = useCallback(
    debounce((_, allValues) => {
      if (searchQuery) allValues.q = searchQuery;
      const values = removeEmptyFilters(allValues);
      const newFilters = merge(values, pick(pageFilters, 'filter_file'));

      setFilters({
        pagePath: filterKey,
        filters: newFilters,
      });
    }, 700),
    [pageFilters, filterKey]
  );

  useUnmount(() => {
    filterForm.resetFields();
  });

  return (
    <section className="box-primary form-box akinon-filter">
      <FilterFormContext.Provider value={filterForm}>
        <Row justify="space-between" align="middle" className="akinon-filter__header">
          <Col>
            <Title className="akinon-filter__title" level={3}>
              {title ?? t('filters')}
            </Title>
          </Col>
          <Col>
            <FilterOptions
              includeFileFilter={includeFileFilter}
              staticFiltersFormMetaFields={staticFiltersFormMetaFields}
              dynamicFiltersFormMetaFields={dynamicFiltersFormMetaFields}
              setCurrentFiltersFormMeta={setCurrentFiltersFormMeta}
              currentFiltersFormMeta={currentFiltersFormMeta}
              isDynamicFiltersLoading={isDynamicFiltersLoading}
              isFilterOptionsModalOpen={isFilterOptionsModalOpen}
              setIsFilterOptionsModalOpen={setIsFilterOptionsModalOpen}
            />
          </Col>
        </Row>
        <FiltersForm
          isFiltersLoading={isStaticFiltersLoading || isDynamicFiltersLoading}
          currentFiltersFormMeta={currentFiltersFormMeta}
          filterForm={filterForm}
          handleOnValuesChange={handleOnValuesChange}
          applyFilters={applyFilters}
          clearFilters={clearFilters}
          total={total}
          searchQuery={searchQuery}
        />
      </FilterFormContext.Provider>
    </section>
  );
};

AkinonFilter.propTypes = {
  title: PropTypes.string.isRequired,
  staticFiltersFormMetaFields: PropTypes.array.isRequired,
  dynamicFiltersFormMetaFields: PropTypes.array.isRequired,
  exportOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  isDynamicFiltersLoading: PropTypes.bool.isRequired,
};

AkinonFilter.defaultProps = {
  dynamicFiltersFormMetaFields: [],
  isDynamicFiltersLoading: false,
  isStaticFiltersLoading: false,
  exportOptions: [],
};

export default React.memo(AkinonFilter);
