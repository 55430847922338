import './Modal.scss';

import ActionIcon from '@components/ActionIcon/ActionIcon';
import { IconX } from '@tabler/icons-react';
import { Modal as AntdModal } from 'antd';
import clsx from 'clsx';

/**
 * @param {import('antd').ModalProps} props
 */
export default function Modal({
  className,
  footer = null,
  children,
  closeIcon,
  onCancel,
  ...otherProps
}) {
  return (
    <AntdModal
      closeIcon={closeIcon ?? <ActionIcon Icon={IconX} onClick={onCancel} />}
      className={clsx('akinon-modal', className)}
      footer={footer}
      {...otherProps}
    >
      {children}
    </AntdModal>
  );
}

/**
 * @param {import('antd').ModalFuncProps} otherProps
 */
Modal.confirm = ({ className, closeIcon, onCancel, t, ...otherProps }) => {
  const actionTranslations = t
    ? {
        okText: t('modal_ok'),
        cancelText: t('modal_cancel'),
      }
    : {};

  return AntdModal.confirm({
    className: clsx('akinon-modal', className),
    closeIcon: closeIcon ?? <ActionIcon Icon={IconX} onClick={onCancel} />,
    ...actionTranslations,
    ...otherProps,
  });
};
