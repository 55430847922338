import * as z from 'zod';

import { getDynamicZodSchema } from '../common';

export const FormKey = {
  NAME: 'name',
  TEMPLATE: 'template',
  PRODUCT_LIMIT: 'product_limit',
  COLLECTION: 'product_collection',
};

export const getFormSchema = ({ t, widgetSchema }) => {
  return z.object({
    [FormKey.NAME]: z.string({ required_error: t('formRule.required') }).min(1),
    [FormKey.TEMPLATE]: z.string({ required_error: t('formRule.required') }).min(1),
    [FormKey.PRODUCT_LIMIT]: z.coerce
      .number({ required_error: t('formRule.required') })
      .int({
        message: t('integer.validation.message'),
      })
      .min(1),
    [FormKey.COLLECTION]: z.string({ required_error: t('formRule.required') }).min(1),
    attributes: z.object({
      ...Object.entries(widgetSchema ?? {}).reduce((acc, [key, value]) => {
        return {
          ...acc,
          [key]: getDynamicZodSchema({ t, widget: value }).optional(),
        };
      }, {}),
    }),
  });
};
