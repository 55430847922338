import * as z from 'zod';

export const FormKey = {
  COMMISSION: 'commission',
  MATURITY: 'maturity',
};

export const getFormSchema = () => {
  return z.object({
    [FormKey.COMMISSION]: z.coerce.number().min(0).max(100),
    [FormKey.MATURITY]: z.coerce.number().min(0),
  });
};
