import { createSelectOptions } from '@common/index';
import { WidgetType } from '@constants/commontypes';

import { FormKey } from '../../../OfferDetail/components/RejectModal/common';

export const getFormMeta = ({ t, rejectionReasons }) => ({
  formItemLayout: [24, 24],
  fields: [
    {
      label: t('rejection.reason'),
      key: FormKey.REJECTION_REASON,
      widget: WidgetType.SELECT,
      required: true,
      options: createSelectOptions(rejectionReasons, {
        labelKey: 'reason',
        valueKey: 'id',
      }),
    },
    {
      label: t('rejection.reason.explanation'),
      key: FormKey.REJECTION_EXPLANATION,
      widget: WidgetType.INPUT,
      placeholder: t('enter_variable', { variable: t('rejection.reason.explanation') }),
    },
  ],
});
