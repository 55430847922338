import Animate from '@components/Animate';
import PropTypes from 'prop-types';

const Show = ({ when, fallback, children, animateProps }) => {
  if (animateProps) {
    if (fallback) {
      throw new Error(
        "Can't animate with fallback, wrap the show component with <Animate /> instead"
      );
    }

    return (
      <Animate {...animateProps} isVisible={when}>
        {children}
      </Animate>
    );
  }

  return when ? children : fallback;
};

Show.propTypes = {
  when: PropTypes.bool.isRequired,
  fallback: PropTypes.node,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

Show.defaultProps = {
  fallback: null,
};

export default Show;
