import { Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const SingleRejectModal = ({
  onSingleReject,
  isSingleRejectModalVisible,
  onCancelSingleRejectModal,
  isRejecting,
}) => {
  const { t } = useTranslation('Orders');

  return (
    <Modal
      width={800}
      open={isSingleRejectModalVisible}
      onOk={onSingleReject}
      okButtonProps={{ loading: isRejecting }}
      onCancel={onCancelSingleRejectModal}
      title={t('reject_order')}
    >
      <div>{t('are_you_sure_to_reject_product')}</div>
    </Modal>
  );
};

export default SingleRejectModal;
