import './styles.scss';

import React, { useEffect, useState } from 'react';

import CategoriesTree from './components/CategoriesTree';
import CategoryPermissionsTree from './components/CategoryPermissionsTree';

const CategoryPermissions = ({
  categories,
  userCategoryNodePermissions,
  handleSubmit,
  isLoading,
}) => {
  const [checkedKeys, setCheckedKeys] = useState([]);

  useEffect(() => {
    const allPermissions =
      userCategoryNodePermissions?.map((item) => item.category_node).filter(Boolean) ?? [];

    const initialCheckedKeys = categories
      ?.filter((category) => allPermissions.includes(category.id))
      .map((item) => item.path);

    setCheckedKeys(initialCheckedKeys);
  }, [categories, userCategoryNodePermissions]);

  return (
    <div className="category-permissions">
      <CategoriesTree
        categories={categories}
        checkedKeys={checkedKeys}
        setCheckedKeys={setCheckedKeys}
        isLoading={isLoading}
      />
      <CategoryPermissionsTree
        userCategoryNodePermissions={userCategoryNodePermissions}
        categories={categories}
        checkedKeys={checkedKeys}
        setCheckedKeys={setCheckedKeys}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
      />
    </div>
  );
};

export default CategoryPermissions;
