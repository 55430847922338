import first from 'lodash/first';

import { useConversationQuery } from './useConversationQuery';
import { useOrdersQuery } from './useOrdersQuery';

export function useConversationAndOrdersQuery({ conversationId }) {
  const { conversation, isConversationFetching, isConversationLoading } = useConversationQuery({
    conversationId,
  });

  const { orders } = useOrdersQuery({
    requestConfig: {
      params: {
        number: conversation?.item_object?.order_number,
      },
    },
    queryOption: {
      enabled: !isConversationFetching && !!conversation?.item_object?.order_number,
    },
  });

  return {
    conversation,
    order: first(orders),
    isConversationLoading,
  };
}
