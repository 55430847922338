import { useState } from 'react';

export default function useSchemas() {
  const [orderSchema] = useState({});
  const [salesSchema] = useState({});

  return {
    orderSchema,
    salesSchema,
  };
}
