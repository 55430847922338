import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { getRejectionReasonsUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { client } from '@services/api/client';

export const useOfferRejectReasonsQuery = ({ pagination, filters, params } = {}) => {
  const queryKey = [QueryKey.REJECTION_REASONS, pagination, filters, params];
  const queryFn = () =>
    client.get(getRejectionReasonsUrl, {
      params: {
        ...getRequestPaginationParams(pagination),
        ...getRequestFiltersParams(filters),
      },
    });

  const { data, isLoading, error, ...rest } = useAkinonQuery({
    queryKey,
    queryFn,
  });

  return {
    data: data?.results ?? data,
    total: data?.count,
    isLoading,
    error,
    ...rest,
  };
};
