import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { patchProductOffers as patchProductOffersService } from '@services/api/products/offers';

export function usePatchProductOffersMutation({ mutationOptions }) {
  const {
    mutate: patchProductOffers,
    mutateAsync: patchProductOffersAsync,
    isLoading: isPatchProductOffersMutating,
    error: patchProductOffersError,
  } = useAkinonMutation({
    mutationFn: patchProductOffersService,
    ...mutationOptions,
  });

  return {
    patchProductOffers,
    patchProductOffersAsync,
    isPatchProductOffersMutating,
    patchProductOffersError,
  };
}
