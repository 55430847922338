import './style.scss';

import { IconInfoCircleFilled } from '@tabler/icons-react';
import { Radio, Tooltip } from 'antd';
import { forwardRef } from 'react';

const AkinonRadioGroup = Radio.Group;
const AkinonRadioButton = Radio.Button;
const AkinonRadioBox = Radio.Box;

const AkinonRadio = forwardRef((props, ref) => {
  const { options, ...restProps } = props;
  return (
    <div className="akinon-radio">
      <AkinonRadioGroup {...restProps} ref={ref}>
        {options.map((option, index) => {
          const { label, value, optionType, tooltip, ...rest } = option;
          const Option = optionType ? Radio[optionType] : Radio;
          return (
            <Option key={index} value={value} {...rest}>
              {label}
              {tooltip && (
                <Tooltip title={tooltip} className="akinon-radio__tooltip">
                  <IconInfoCircleFilled size={20} />
                </Tooltip>
              )}
            </Option>
          );
        })}
      </AkinonRadioGroup>
    </div>
  );
});

export { AkinonRadio, AkinonRadioBox, AkinonRadioButton, AkinonRadioGroup };
