import './styles.scss';

import AkinonFormItem from '@components/AkinonFormItem';
import { Input, InputNumber } from 'antd';
import clsx from 'clsx';
import React, { forwardRef } from 'react';

/**
 * @param {import('antd').InputProps} props
 */
const AkinonInput = forwardRef(({ className, formItemProps, ...otherProps }, ref) => {
  const component = (
    <Input
      ref={ref}
      className={clsx('akinon-input', className)}
      {...otherProps}
      aria-label={otherProps?.['aria-label'] || otherProps?.label || formItemProps?.label}
    />
  );

  if (formItemProps) {
    return <AkinonFormItem {...formItemProps}>{component}</AkinonFormItem>;
  }

  return component;
});

export const AkinonInputNumber = forwardRef(({ className, formItemProps, ...otherProps }, ref) => {
  const component = (
    <InputNumber
      ref={ref}
      className={clsx('akinon-input-number', className)}
      {...otherProps}
      aria-label={otherProps?.['aria-label'] || otherProps?.label}
    />
  );

  if (formItemProps) {
    return <AkinonFormItem {...formItemProps}>{component}</AkinonFormItem>;
  }

  return component;
});

export const AkinonPasswordInput = ({ className, formItemProps, ...otherProps }) => {
  const component = (
    <Input.Password
      className={clsx('akinon-password-input', className)}
      {...otherProps}
      aria-label={otherProps?.['aria-label'] || otherProps?.label}
    />
  );

  if (formItemProps) {
    return <AkinonFormItem {...formItemProps}>{component}</AkinonFormItem>;
  }

  return component;
};

/**
 * @type {import('react').FC<import('antd/lib/input').TextAreaProps & { ref: import('react').ForwardedRef}>}
 */
export const AkinonTextArea = forwardRef(({ className, formItemProps, ...otherProps }, ref) => {
  const component = (
    <Input.TextArea className={clsx('akinon-textarea', className)} {...otherProps} ref={ref} />
  );

  if (formItemProps) {
    return <AkinonFormItem {...formItemProps}>{component}</AkinonFormItem>;
  }

  return component;
});

export const AkinonSearch = ({ className, formItemProps, ...otherProps }) => {
  const component = <Input.Search className={clsx('akinon-search', className)} {...otherProps} />;

  if (formItemProps) {
    return <AkinonFormItem {...formItemProps}>{component}</AkinonFormItem>;
  }

  return component;
};
export default AkinonInput;
