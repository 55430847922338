import { QueryKey } from '@constants/query';
import { getRejectionReasons } from '@services/api';
import { useQuery } from '@tanstack/react-query';

/**
 * @param {{ queryOptions: import('@tanstack/react-query').UseQueryOptions, requestConfig: import('axios').AxiosRequestConfig }} args
 */
export function useRejectionReasonsQuery({ queryOptions, requestConfig } = {}) {
  const { data, isLoading } = useQuery({
    queryKey: [QueryKey.REJECTION_REASONS, requestConfig],
    queryFn: () => getRejectionReasons({ requestConfig }),
    ...queryOptions,
  });

  return {
    rejectionReasons: data?.results,
    isRejectionReasonsLoading: isLoading,
  };
}
