import NavigationList from '@components/Navigation/List';
import { Color } from '@constants/theme';
import { useUser } from '@root/contexts/hooks/useUser';
import { useIsLiveAppVersion } from '@root/hooks/useIsLiveAppVersion';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getNavigationItems } from './common';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { usePageMeta } from './hooks/usePageMeta';

const ReportingLanding = () => {
  const { t } = useTranslation('Reporting');
  const user = useUser();
  const isLiveAppVersion = useIsLiveAppVersion();

  useBreadcrumbs();
  usePageMeta();

  const navigationItems = getNavigationItems({ t, user, isLiveAppVersion });

  return <NavigationList items={navigationItems} cardColor={Color.WAIKAWA_GRAY} />;
};

export default ReportingLanding;
