import useStore from '@zustand-store/index';
import { setPageMetaSelector } from '@zustand-store/selectors/app';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

export function usePageMeta({ showAddNewUserModal }) {
  const { t } = useTranslation('Settings');
  const setPageMeta = useStore(setPageMetaSelector);

  useEffect(() => {
    setPageMeta({
      title: t('users'),
      description: t('seller_user_management_desc'),
      actions: [
        {
          label: t('add_new_user'),
          icon: <i className="icon-arti" />,
          onClick() {
            showAddNewUserModal();
          },
        },
      ],
    });
  }, [t]);

  useUnmount(() => {
    setPageMeta({});
  });
}
