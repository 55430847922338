import AkinonFormItem from '@components/AkinonFormItem';
import AkinonInput from '@components/AkinonInput';
import DynamicFormElement from '@components/DynamicFormElement';
import ProductImageUpload from '@pages/ProductsAndCategories/ProductForm/components/ProductsToBeAddedTableForm/components/ProductImageUpload';
import { Checkbox, Divider, Space, Typography } from 'antd';
import clsx from 'clsx';
import { last, pick, uniq, values } from 'lodash';
import omit from 'lodash/omit';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { FormKey } from './common';

const MultipleProductRevisionFields = ({
  productRevision,
  productMeta,
  productImages,
  selectedProducts,
  setSelectedProducts,
  pagination,
  disableSelection,
  allAttributeFields,
  variantDynamicFields,
  control,
  watch,
}) => {
  const fieldsContainerRef = useRef(null);

  const alreadyHasRevision = Boolean(productRevision);
  const { t } = useTranslation('ProductsAndCategories');

  const variantsKeys = variantDynamicFields.map((metaField) => last(metaField.key.split('.')));
  const productName = values(pick(productMeta?.attributes, variantsKeys)).join('/');
  const formValues = watch();

  const currentPage = pagination?.current ?? 1;

  useEffect(() => {
    if (fieldsContainerRef?.current) {
      const nodes = fieldsContainerRef?.current.querySelectorAll(
        '.product-revision-form-wrapper__inner > div'
      );
      const nodeIndexToScrollIn = Math.min(
        currentPage === 1 ? currentPage - 1 : currentPage * 4,
        nodes.length - 1
      );

      if (nodeIndexToScrollIn === 0) {
        fieldsContainerRef.current.scrollLeft = 0;
      } else {
        nodes[nodeIndexToScrollIn]?.scrollIntoView?.();
      }
    }
  }, [currentPage, fieldsContainerRef]);

  const prefixWithId = (key) =>
    productMeta?.id ? `${productMeta.id}.${key}` : 'bulkUpdate.' + key;

  const formItems = [
    <AkinonFormItem
      control={control}
      key={prefixWithId(FormKey.IMAGES)}
      name={prefixWithId(FormKey.IMAGES)}
      id={prefixWithId(FormKey.IMAGES)}
    >
      <ProductImageUpload
        label={t('images')}
        accept="image/*"
        disabled={alreadyHasRevision}
        fileList={
          formValues?.[productMeta?.id]?.[FormKey.IMAGES] ??
          productImages?.map((productImage) => {
            return {
              url: productImage?.image ?? productImage?.url,
              uuid: productImage.id,
              ...productImage,
            };
          }) ??
          []
        }
        style={{ marginBottom: 15 }}
        multiple
      />
    </AkinonFormItem>,
    <AkinonFormItem
      control={control}
      name={prefixWithId(FormKey.NAME)}
      key={prefixWithId(FormKey.NAME)}
      id={prefixWithId(FormKey.NAME)}
      disabled={alreadyHasRevision}
      label={t('product_name')}
      required={false}
    >
      <AkinonInput placeholder={t('product_name')} />
    </AkinonFormItem>,
    allAttributeFields?.length > 0 &&
      allAttributeFields.map((field) => {
        const { key, options } = field ?? {};
        const props = {
          ...omit(field, 'mode'),
          name: prefixWithId(key),
          required: false,
          disabled: alreadyHasRevision,
        };
        if (options) props.options = options;

        return (
          <AkinonFormItem key={prefixWithId(key)} control={control} {...props}>
            <DynamicFormElement
              widget={field.data_type}
              options={props?.options}
              placeholder={props?.placeholder}
            />
          </AkinonFormItem>
        );
      }),
  ];

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      setSelectedProducts(uniq([...selectedProducts, productMeta?.id]));
    } else {
      setSelectedProducts(selectedProducts.filter((id) => id !== productMeta?.id));
    }
  };

  return (
    <div className="product-revision-form-wrapper" ref={fieldsContainerRef}>
      {!disableSelection ? (
        <Checkbox
          className="product-revision-form-wrapper__checkbox"
          onChange={handleCheckboxChange}
          disabled={alreadyHasRevision}
          checked={selectedProducts.includes(productMeta?.id)}
        />
      ) : (
        <div style={{ marginRight: '1rem' }}></div>
      )}
      <Typography.Text className={clsx('product-revision-form-wrapper__variant')}>
        {productName}
      </Typography.Text>
      {alreadyHasRevision ? (
        <Space className="existing-revision-warning">{t('already_has_a_revision')}</Space>
      ) : (
        <>
          <div className="product-revision-form-wrapper__inner">{formItems}</div>
          {!disableSelection && <Divider className="product-revision-form-wrapper__divider" />}
        </>
      )}
    </div>
  );
};

export default MultipleProductRevisionFields;
