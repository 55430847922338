import './style.scss';

import AkinonButton from '@components/AkinonButton';
import AkinonFormBuilder from '@components/AkinonFormBuilder';
import { useIsMobile } from '@hooks/useIsMobile';
import { IconAdjustments } from '@tabler/icons-react';
import { Form, Spin } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ActiveFilters from '../ActiveFilters';
import FiltersDrawer from '../FiltersDrawer';
import HighlightedFiltersForm from '../HighlightedFiltersForm';
import MobileSearchFilter from '../MobileSearchFilter';

const FiltersForm = ({
  currentFiltersFormMeta,
  isFiltersLoading,
  filterForm,
  handleOnValuesChange,
  applyFilters,
  clearFilters,
  total,
  searchQuery,
}) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const [isFiltersModalVisible, setIsFiltersModalVisible] = useState(false);
  if (isMobile) {
    return (
      <>
        <MobileSearchFilter
          currentFiltersFormMeta={currentFiltersFormMeta}
          searchQuery={searchQuery}
        />
        <HighlightedFiltersForm
          form={filterForm}
          currentFiltersFormMeta={currentFiltersFormMeta}
          handleOnValuesChange={handleOnValuesChange}
        />
        <AkinonButton
          className="mobile-show-filters-button"
          onClick={() => setIsFiltersModalVisible(true)}
        >
          {t('filters')}
          <IconAdjustments />
        </AkinonButton>
        <FiltersDrawer
          isFiltersModalVisible={isFiltersModalVisible}
          setIsFiltersModalVisible={setIsFiltersModalVisible}
          isFiltersLoading={isFiltersLoading}
          filterForm={filterForm}
          currentFiltersFormMeta={currentFiltersFormMeta}
          applyFilters={applyFilters}
          clearFilters={clearFilters}
          total={total}
        />
      </>
    );
  }

  return (
    <>
      <ActiveFilters currentFiltersFormMeta={currentFiltersFormMeta} />
      <Spin spinning={isFiltersLoading}>
        <Form
          className="akinon-form-dark akinon-filter__form"
          form={filterForm}
          onValuesChange={handleOnValuesChange}
        >
          <AkinonFormBuilder form={filterForm} meta={currentFiltersFormMeta} />
        </Form>
      </Spin>
    </>
  );
};

export default FiltersForm;
