import AkinonFilter from '@components/AkinonFilter';
import { usePageFilters } from '@components/AkinonFilter/hooks/store/usePageFilters';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import { usePaginatedDataSourcesQuery } from '@services/hooks/datasources/usePaginatedDataSourcesQuery';
import { useReconciliationItems } from '@services/hooks/finance/useReconciliationItems';
import { useReconciliations } from '@services/hooks/finance/useReconciliations';
import { Divider } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function ItemList({ staticFilters, columns, reconciliationId = null }) {
  const { t } = useTranslation('Finance');
  const filters = usePageFilters();
  const [pagination, setPagination] = usePagination();
  const { reconciliations, isLoading, total } = useReconciliationItems({
    pagination,
    filters,
    id: reconciliationId,
  });

  const reconciliationIds = reconciliations
    ?.map((reconciliation) => reconciliation.reconciliation)
    .filter(Boolean);
  const datasources = reconciliations?.map((reconciliation) => reconciliation.datasource);

  const { reconciliations: dataReconciliations } = useReconciliations({
    pagination,
    filters: { id__in: reconciliationIds?.join(',') },
    enabled: !isLoading && !!reconciliationIds?.length,
  });

  const { data: dataSources } = usePaginatedDataSourcesQuery({}, undefined, datasources);

  reconciliations?.forEach((reconciliation) => {
    const reconciliationData = dataReconciliations?.find(
      (dataReconciliation) => dataReconciliation.id === reconciliation.reconciliation
    );
    const dataSource = dataSources?.find(
      (dataSource) => dataSource.id === reconciliation.datasource
    );

    reconciliation.name = reconciliationData?.name;
    reconciliation.supplier = dataSource?.name;
  });

  return (
    <>
      <AkinonFilter title={t('filters')} {...staticFilters} total={total} />

      <Divider />

      <AkinonTable
        title={t('reconciliations')}
        columns={columns}
        dataSource={reconciliations}
        loading={isLoading}
        total={total}
        pagination={pagination}
        rowKey="pk"
        setPagination={setPagination}
        description={`${total} ${t('results.found')}`}
      />
    </>
  );
}
