import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { customerAddressesUrl, getCustomerAddressesUrl } from '@constants/apiUrls';
import { client } from '@services/api/client';
import { useQuery } from '@tanstack/react-query';

const customerAddressQueryKey = 'customer-address';

/**
 * @typedef {import('@tanstack/react-query').UseQueryResult} UseQueryResult
 * @returns {import('@tanstack/react-query').UseQueryResult}
 */

export const useCustomerAddressQuery = ({ addressId, queryOptions } = {}) => {
  const { data, isLoading, error, ...rest } = useQuery({
    queryKey: [customerAddressQueryKey, { addressId }],
    queryFn: () => client.get(customerAddressesUrl, { urlParams: { addressId } }),
    ...queryOptions,
  });

  return {
    ...rest,
    data,
    isLoading,
    error,
  };
};

export const useCustomerAddressesQuery = ({
  filters,
  pagination,
  requestConfig,
  queryOptions,
} = {}) => {
  const { data, isLoading, error, isFetching } = useQuery({
    queryKey: ['customer-addresses', requestConfig, filters, pagination],
    queryFn: () =>
      client.get(getCustomerAddressesUrl, {
        params: {
          ...getRequestPaginationParams(pagination),
          ...getRequestFiltersParams(filters),
        },
        ...requestConfig,
      }),
    ...queryOptions,
  });

  return {
    customerAddresses: data?.results,
    total: data?.count,
    isCustomerAddressesLoading: isLoading,
    customerAddressesError: error,
    isCustomerAddressesFetching: isFetching,
  };
};
