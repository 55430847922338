import { generatePackageLabelUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { client } from '@services/api/client';

export function useGenerateLabelQuery({ packageId, queryOptions, onSuccess }) {
  const { data, isLoading, error, refetch } = useAkinonQuery({
    queryKey: [QueryKey.GENERATE_LABEL, packageId],
    async queryFn() {
      const response = await client.get(generatePackageLabelUrl, { urlParams: { packageId } });
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    generateLabel: data?.results,
    isGenerateLabelLoading: isLoading,
    generateLabelError: error,
    refetchGenerateLabel: refetch,
  };
}
