import { getCountriesUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { client } from '@services/api/client';

import { useAkinonQuery } from '../adapters/useAkinonQuery';

export function useCountriesQuery({ queryOptions } = {}) {
  const { data, isLoading } = useAkinonQuery({
    queryKey: [QueryKey.COUNTRIES],
    queryFn: () => client.get(getCountriesUrl),
    ...queryOptions,
  });

  return {
    countries: data?.results,
    isCountriesLoading: isLoading,
  };
}
