import 'moment/min/locales';

import { fullDateTimeWithZoneFormat } from '@constants/index';
import cond from 'lodash/cond';
import identity from 'lodash/identity';
import isNil from 'lodash/isNil';
import mapValues from 'lodash/mapValues';
import merge from 'lodash/merge';
import pickBy from 'lodash/pickBy';
import moment from 'moment';
import { z } from 'zod';

export function getTotalAmount(order) {
  return order.amount * 1 + (order.discount_amount * 1 || 0);
}

const createSelectOptionsOptions = {
  labelKey: '',
  valueKey: '',
  customKeys: [],
  transformLabel: identity,
  transformValue: identity,
};

/**
 * @typedef {<T, I, R>(value: T, dataItem: I) => R} SelectOptionTransformer
 */

/**
 * @template D
 * @template V
 * @param {D} data
 * @param {{ labelKey: string, valueKey: string, customKeys: string[], transformLabel: SelectOptionTransformer, transformValue: SelectOptionTransformer}} options
 * @returns {[{label: string, value: V} | Record<string, any>]}
 */
export function createSelectOptions(data, options = createSelectOptionsOptions) {
  const { labelKey, valueKey, customKeys, transformLabel, transformValue } = merge(
    createSelectOptionsOptions,
    options
  );
  const getCustomKeyOptions = (item) =>
    customKeys?.reduce(
      (acc, customKey) => ({
        ...acc,
        [customKey]: item[customKey],
      }),
      {}
    );
  return (data || []).map((item) => ({
    label: transformLabel(item[labelKey], item),
    value: transformValue(item[valueKey], item),
    ...getCustomKeyOptions(item),
  }));
}

export function createSelectValidation(selectOptions, { valueKey = 'id' } = {}) {
  return z.union(selectOptions?.map((option) => z.literal(option[valueKey])));
}

/**
 * @param {import('antd').PaginationProps} pagination
 */
export const getRequestPaginationParams = (pagination) => {
  if (!pagination) return;

  return {
    limit: pagination.pageSize,
    page: pagination.current,
  };
};

export const getRequestFiltersParams = (filters) => {
  if (!filters) return;

  const filledFilters = pickBy(filters, (filterValue) => !isNil(filterValue));
  return mapValues(filledFilters, (filterValue) => {
    if (moment.isMoment(filterValue)) {
      return filterValue.format(fullDateTimeWithZoneFormat);
    }
    return filterValue;
  });
};

/**
 * @typedef {[predicate: (...args) => boolean, getKeyPart: () => string][]} KeyPairs
 */

/**
 * @param {{ prefixPairs: KeyPairs, suffixPair: KeyPairs, metaField: { key: string, widget: string } }} arg
 */
export const generateDynamicFilterKey = ({ prefixPairs, suffixPairs, metaField }) => {
  const dynamicKeyPrefix = cond(prefixPairs)(metaField.widget);
  const dynamicKeySuffix = cond(suffixPairs)(metaField.widget);

  return [dynamicKeyPrefix, metaField.key, dynamicKeySuffix].filter(Boolean).join('__');
};

export const LogCenterRecordType = {
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
};

/**
 * @typedef {{ type: 'success' | 'error' | 'warning' | 'info',  message: string,description: string, }} LogCenterRecord
 */

/**
 * @param {LogCenterRecord} record
 * @returns {LogCenterRecord & { date: number }}
 */
export const createLogCenterRecord = (record) => {
  return {
    ...record,
    date: Date.now(),
  };
};

/*
 * @description this serializes the given value to JSON and parses it back to a JS object.
 */
export const safeJSONSerialize = (value) => {
  try {
    return JSON.parse(JSON.stringify(value));
  } catch (error) {
    return value;
  }
};

export const isFloat = (number) => {
  return !isNaN(parseFloat(Number(number).toString()));
};

export const isJSON = (string) => {
  try {
    JSON.parse(string);
  } catch (e) {
    return false;
  }
  return true;
};

export const getDocumentMeta = () => {
  const metaTags = document.getElementsByTagName('meta');
  return Array.from(metaTags).reduce((metaObj, metaTag) => {
    const name = metaTag.getAttribute('name');
    metaObj[name] = metaTag.getAttribute('content');

    return metaObj;
  }, {});
};

export const getUserName = ({ user }) => {
  const userName = [user?.first_name, user?.last_name].filter(Boolean).join(' ');

  return userName || user?.email;
};

export const getTourSelectorWithFallback = (selectors = [], fallback) => {
  const firstTruthySelector = selectors.reduce((acc, selector) => {
    const foundBefore = document.querySelector(acc) && acc;
    const foundNow = document.querySelector(selector) && selector;

    return foundBefore ?? foundNow ?? null;
  }, null);

  return firstTruthySelector ?? fallback;
};

export const findElementByInnerText = (text = '', elemType = 'div') => {
  const xpath = `//${elemType}[text()='${text}']`;
  return document.evaluate(xpath, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null)
    .singleNodeValue;
};

export const responsiveBreakpointMap = {
  smallestMobile: 321,
  smallMobile: 370,
  mobile: 576,
  tablet: 768,
  desktop: 992,
  largeDesktop: 1200,
};

export const AppShellAppType = {
  FULL_PAGE: 'full_page',
  PLUGIN: 'plugin',
};

export const AppTypeToAppShellType = {
  [AppShellAppType.FULL_PAGE]: 'fullpage',
  [AppShellAppType.PLUGIN]: 'plugin',
};
