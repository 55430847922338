import { getPathFragments } from '@common/routing';
import AkinonFlex from '@components/AkinonFlex';
import { IconCaretDownFilled, IconCaretUpFilled } from '@tabler/icons-react';
import useStore from '@zustand-store/index';
import { filtersSelector, setFiltersSelector } from '@zustand-store/selectors/filters';
import clsx from 'clsx';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import { useLocation } from 'react-router-dom';

const SortDirection = {
  ASC: 'asc',
  DESC: 'desc',
};

const AkinonTableSorter = ({ title, sortKey }) => {
  const location = useLocation();
  const { pathname, search } = location;
  const { mainPath } = getPathFragments(pathname);
  const filterKey = `${mainPath}${search}`;

  const setFilters = useStore(setFiltersSelector);
  const pageFilters = useStore(filtersSelector);

  const currentPageFilters = pageFilters[filterKey];

  const sortKeys = currentPageFilters?.sort ? currentPageFilters.sort.split(',') : [];
  const activeSortKeyIndex = sortKeys.findIndex((key) => key === sortKey || key === `-${sortKey}`);
  const activeSortKey = sortKeys[activeSortKeyIndex];

  const direction = activeSortKey
    ? activeSortKey[0] === '-'
      ? SortDirection.DESC
      : SortDirection.ASC
    : null;

  const handleSorterClick = () => {
    const newSortKeys = sortKeys.filter((_, index) => index !== activeSortKeyIndex);

    if (direction === null) {
      newSortKeys.push(sortKey);
    } else if (direction === SortDirection.ASC) {
      newSortKeys.push(`-${sortKey}`);
    }

    setFilters({
      pagePath: filterKey,
      filters: {
        ...currentPageFilters,
        sort: isEmpty(newSortKeys) ? undefined : newSortKeys.join(','),
      },
    });
  };

  return (
    <AkinonFlex justifyContent="space-between" alignItems="center">
      {isString(title) ? title?.toUpperCase() : title}
      <AkinonFlex direction="column" className="cursor-pointer" onClick={handleSorterClick}>
        <IconCaretUpFilled
          size={14}
          className={clsx({
            'text-info': direction === SortDirection.ASC,
          })}
        />
        <IconCaretDownFilled
          size={14}
          className={clsx({
            'text-info': direction === SortDirection.DESC,
          })}
        />
      </AkinonFlex>
    </AkinonFlex>
  );
};

export default AkinonTableSorter;
