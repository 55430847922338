import { getIsPublicPrivateOptions, WidgetType } from '@constants/commontypes';
import { useTranslation } from 'react-i18next';

import { StaticFilterKey } from '../common';

export const useStaticFilters = () => {
  const { t } = useTranslation('Messages');
  return {
    staticFiltersFormMetaFields: [
      {
        key: StaticFilterKey.BASE_CODE,
        widget: WidgetType.INPUT,
        placeholder: t('base.code'),
      },
      {
        key: StaticFilterKey.FIRST_CREATE_MESSAGE_DATE,
        widget: WidgetType.DATE_PICKER,
        placeholder: t('first.message.creation.date'),
      },
      {
        key: StaticFilterKey.LAST_CREATE_MESSAGE_DATE,
        widget: WidgetType.DATE_PICKER,
        placeholder: t('last.message.creation.date'),
      },
      {
        key: StaticFilterKey.FIRST_UPDATE_MESSAGE_DATE,
        widget: WidgetType.DATE_PICKER,
        placeholder: t('first.message.update.date'),
      },
      {
        key: StaticFilterKey.LAST_UPDATE_MESSAGE_DATE,
        widget: WidgetType.DATE_PICKER,
        placeholder: t('last.message.update.date'),
      },
      {
        key: StaticFilterKey.IS_PUBLIC,
        widget: WidgetType.SELECT,
        placeholder: t('messagesConfig.messageState'),
        options: getIsPublicPrivateOptions({ t }),
      },
    ],
    isStaticFiltersLoading: false,
  };
};
