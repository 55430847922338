import AkinonFormItem from '@components/AkinonFormItem';
import DynamicFormElement from '@components/DynamicFormElement';
import { WidgetType } from '@constants/commontypes';
import { Collapse } from 'antd';
import { memo } from 'react';

import { FormItemType, SchemaDataType } from '../common';
import CollapseExpandIcon from '../components/CollapseExpandIcon';
import CustomDragger from './CustomDragger';
import MultiNestedWidget from './MultiNestedWidget';

const SchemaManager = memo(({ schema, t, control }) => {
  const { type, widget, label, name, key, ..._schema } = schema;
  if (widget === FormItemType.MULTI_NESTED) {
    return (
      <MultiNestedWidget
        t={t}
        control={control}
        schema={_schema}
        name={name}
        label={label}
        parentSchemaType={widget}
      />
    );
  } else if (widget === FormItemType.NESTED) {
    return (
      <Collapse
        className="nested-widget"
        expandIcon={({ isActive }) => <CollapseExpandIcon isExpanded={isActive} />}
        bordered={false}
      >
        <Collapse.Panel header={label}>
          {Object.entries(_schema).map(([key, widget]) => {
            return <SchemaManager key={key} schema={widget} t={t} control={control} />;
          })}
        </Collapse.Panel>
      </Collapse>
    );
  } else if (widget === FormItemType.MULTI) {
    return (
      <MultiNestedWidget
        t={t}
        control={control}
        parentSchemaType={widget}
        schema={_schema}
        name={name}
        label={label}
      />
    );
  }

  let widgetType = WidgetType.INPUT;

  if (type === SchemaDataType.FILE || type === SchemaDataType.IMAGE) {
    widgetType = WidgetType.CUSTOM;
  }
  if (type === SchemaDataType.HTML_EDITOR) {
    widgetType = WidgetType.EDITOR;
  }

  return (
    <AkinonFormItem control={control} label={label} name={name}>
      <DynamicFormElement
        widget={widgetType}
        Component={(props) => <CustomDragger {...props} maxCount={1} />}
      />
    </AkinonFormItem>
  );
});

export default SchemaManager;
