import { QueryKey } from '@constants/query';
import { queryClient } from '@services/api/queryClient';

const useChangeMessagePrivacyStatus = ({ createConversationStatusAsync }) => {
  const changeMessageStatus = ({ isPublic, selectedRows, mutationOptions }) => {
    const { onSuccess, onError } = mutationOptions;
    const promises = selectedRows.map((row) => {
      return () =>
        createConversationStatusAsync({
          conversationId: row.id,
          requestBody: {
            is_public: isPublic,
          },
        })
          .then((result) => {
            onSuccess(result);
          })
          .catch((error) => {
            onError(error);
          });
    });

    Promise.allSettled(promises.map((f) => f())).then(() => {
      queryClient.invalidateQueries([QueryKey.CONVERSATIONS]);
    });
  };

  const onMakePublic = ({ selectedRows, mutationOptions }) => {
    changeMessageStatus({ isPublic: true, selectedRows, mutationOptions });
  };
  const onMakePrivate = ({ selectedRows, mutationOptions }) => {
    changeMessageStatus({ isPublic: false, selectedRows, mutationOptions });
  };
  return { onMakePublic, onMakePrivate };
};

export default useChangeMessagePrivacyStatus;
