import { useLayoutEffect, useState } from 'react';

const useInputHasOverflow = ({ inputRef, inputValue }) => {
  const [hasOverflow, setHasOverflow] = useState(false);

  const checkOverflow = (input) => {
    const scrollWidth = input?.scrollWidth ?? input?.srcElement?.scrollWidth;
    const clientWidth = input?.clientWidth ?? input?.srcElement?.clientWidth;
    let isOverflowed = scrollWidth > clientWidth;
    setHasOverflow(isOverflowed);
  };

  const checkOverflowEvent = (inputEvent) => {
    checkOverflow(inputEvent.srcElement);
  };

  useLayoutEffect(() => {
    const input = inputRef.current?.input;
    checkOverflow(input);
    input.addEventListener?.('input', checkOverflowEvent);

    return () => {
      input.removeEventListener?.('input', checkOverflowEvent);
    };
  }, [inputValue]);

  return hasOverflow;
};

export default useInputHasOverflow;
