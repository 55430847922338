import { useCitiesQuery } from '@services/api/hooks/useCitiesQuery';
import { useCountriesQuery } from '@services/api/hooks/useCountriesQuery';
import { useDistrictsQuery } from '@services/api/hooks/useDistrictsQuery';
import { useTownshipsQuery } from '@services/api/hooks/useTownshipsQuery';

export function useDatasource() {
  const { countries, isCountriesLoading } = useCountriesQuery();
  const { cities, isCitiesLoading } = useCitiesQuery();
  const { districts, isDistrictsLoading } = useDistrictsQuery();
  const { townships, isTownshipsLoading } = useTownshipsQuery();
  return {
    countries,
    cities,
    districts,
    townships,
    isLoading: isCountriesLoading || isCitiesLoading || isDistrictsLoading || isTownshipsLoading,
  };
}
