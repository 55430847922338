import './styles.scss';

import { injectAccessibilityRole } from '@utils/index';
import { Drawer as AntDrawer } from 'antd';
import clsx from 'clsx';
import React from 'react';

/**
 * @param {import('antd').DrawerProps} props
 */
export default function Drawer({ className, ...otherProps }) {
  return injectAccessibilityRole({
    element: (
      <AntDrawer destroyOnClose className={clsx('akinon-drawer', className)} {...otherProps} />
    ),
    role: 'dialog',
  });
}
