import { openNotification } from '@components/akinonNotification';
import { useDynamicColumnsAttributeValues } from '@pages/ProductsAndCategories/hooks/useDynamicColumnsAttributeValues';
import { usePaginatedDataSourcesQuery } from '@services/api/hooks/usePaginatedDataSourcesQuery';
import { useProductImagesQuery } from '@services/api/hooks/usePaginatedProductImagesQuery';
import { usePaginatedProductOffersQuery } from '@services/api/hooks/usePaginatedProductOffersQuery';
import { useProductsQuery } from '@services/api/hooks/usePaginatedProductsQuery';
import { useCategoryTreeData } from '@utils/hooks/useCategoryTreeData';
import { getTreeNodeByValue } from '@utils/index';
import cloneDeep from 'lodash/cloneDeep';
import entries from 'lodash/entries';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import uniq from 'lodash/uniq';
import { useTranslation } from 'react-i18next';

export const useDataSource = ({ dynamicFormMetaFields, pagination, filters, params = {} }) => {
  const { t } = useTranslation('ProductsAndCategories');

  const { productOffers, isFetchingProductOffers, totalProductOffers, productOffersError } =
    usePaginatedProductOffersQuery({
      pagination,
      filters,
      params,
      onError: (error) => {
        openNotification({
          message: t('filtering_unsuccessful'),
          description: error.response?.data?.detail ?? error.message,
          type: 'error',
          t,
        });
      },
    });

  const datasourceIds = uniq(productOffers?.map((item) => item.datasource)).filter(Boolean);
  const { dataSources, isFetchingDataSources } = usePaginatedDataSourcesQuery({
    params: { id__in: datasourceIds.join(','), limit: datasourceIds?.length },
    queryOptions: {
      enabled: !isFetchingProductOffers && !isEmpty(datasourceIds),
    },
  });

  const productIds = uniq(productOffers?.map(({ product }) => product)).filter(Boolean);
  const { products, isFetchingProducts } = useProductsQuery({
    params: {
      id__in: productIds?.join(','),
      limit: productIds?.length,
    },
    queryOptions: {
      enabled: !isFetchingProductOffers && !isEmpty(productIds),
    },
  });

  const { productImages, isFetchingProductImages } = useProductImagesQuery({
    params: {
      limit: productIds?.length,
      parent__in: productIds?.join(','),
      order: 0,
    },
    queryOptions: {
      enabled: !isFetchingProductOffers && !isEmpty(productIds),
    },
  });
  const { attributeValues, isFetchingAttributeValues } = useDynamicColumnsAttributeValues({
    dynamicFormMetaFields,
  });
  const { categoryTreeData, isCategoriesFetching } = useCategoryTreeData({
    queryOptions: {
      enabled: !isFetchingProducts,
    },
  });

  const isLoading =
    isFetchingAttributeValues ||
    isFetchingDataSources ||
    isFetchingProducts ||
    isFetchingProductImages ||
    isCategoriesFetching;

  // Map related data.
  const data = productOffers?.map((offer) => {
    const datasource = dataSources?.find(({ id }) => id === offer.datasource);
    const product = products?.find(({ id }) => id === offer?.product);
    const image = productImages?.find(({ parent }) => parent === product?.id);
    const category = getTreeNodeByValue({ tree: categoryTreeData, nodeValue: product?.category });

    const clonedOffer = cloneDeep(offer);
    clonedOffer.attributes = {
      ...reduce(
        entries({ ...clonedOffer.attributes, ...cloneDeep(product?.attributes) }),
        (acc, [key, value]) => {
          const attributeOptions = attributeValues?.[key] ?? [];
          const valueLabel = get(find(attributeOptions, { value }), 'label', value);
          return {
            ...acc,
            [key]: valueLabel,
          };
        },
        {}
      ),
    };

    return {
      ...clonedOffer,
      offer_code: clonedOffer.sku,
      datasource,
      product,
      image,
      category,
    };
  });

  return {
    data,
    total: totalProductOffers,
    isLoading,
    error: productOffersError,
  };
};
