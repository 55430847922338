import { useCollections } from './api/useCollections';
import { useDataSources } from './api/useDataSources';

export const useCollectionsData = ({ pagination, filters }) => {
  const {
    collections,
    isLoading: isCollectionsLoading,
    total,
  } = useCollections({
    pagination,
    filters,
  });
  const { dataSources } = useDataSources({ collections });

  const data = collections?.map((collection) => ({
    ...collection,
    seller: dataSources?.find(({ id }) => id === collection?.datasource),
  }));

  return {
    data,
    isCollectionsLoading,
    total,
  };
};
