const initialState = {
  filters: {
    emptyPath: {},
  },
};

export const createFiltersSlice = (set) => ({
  ...initialState,
  setFilters: ({ pagePath, filters }) =>
    set((state) => ({ filters: { ...state.filters, [pagePath]: filters } })),
  resetFilters: ({ pagePath }) =>
    set((state) => ({
      filters: {
        ...state.filters,
        [pagePath]: Object.keys(state.filters[pagePath]).reduce((acc, key) => {
          acc[key] = undefined;
          return acc;
        }, {}),
      },
    })),
});
