import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { getProducts } from '@services/api';
import { useQuery } from '@tanstack/react-query';

export const useProducts = ({ pagination, filters, ids, enabled = true }) => {
  const queryKey = ['products', ...(ids ?? []), pagination ?? {}, filters ?? []];
  const queryFn = () =>
    getProducts({
      ...getRequestPaginationParams(pagination),
      ...getRequestFiltersParams(filters),
      limit: ids?.length,
      id__in: ids?.join(','),
    });
  const { data, isFetching, isLoading, error } = useQuery({ queryKey, queryFn, enabled });

  return {
    data: data?.results,
    total: data?.count,
    isFetching,
    isLoading,
    error,
  };
};
