import './style.scss';

import { generateTreeDataSource } from '@common/data';
import AkinonButton from '@components/AkinonButton';
import Show from '@components/Show';
import { limitQuery } from '@constants/commontypes';
import { useCategoriesQuery } from '@services/api/hooks/useCategoriesQuery';
import { useAttributeSetsQuery } from '@services/hooks/common/useAttributeSetsQuery';
import { Empty, Spin, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DataIndex, getColumns, getExpandableConfig } from './common';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import { usePageMeta } from './hooks/usePageMeta';

const AttributeSetAndCategoryMapping = () => {
  const { t } = useTranslation('Settings');
  const { categories, isCategoriesLoading } = useCategoriesQuery();
  const { attributeSets, isAttributeSetsLoading } = useAttributeSetsQuery({
    requestConfig: { params: limitQuery },
  });
  const [expandedKeys, setExpandedKeys] = useState([]);

  useBreadcrumbs();
  usePageMeta();

  useEffect(() => {
    setExpandedKeys(categories?.map((item) => item.id));
  }, [categories]);

  const isLoading = isCategoriesLoading || isAttributeSetsLoading;
  const columns = getColumns({ attributeSets });
  const expandableConfig = getExpandableConfig({ setExpandedKeys, expandedKeys });
  const treeDataSource = generateTreeDataSource({
    data: categories,
    columnKeys: Object.values(DataIndex),
  });

  const renderEmptyTableContent = () => (
    <Show condition={!isLoading}>
      <div className="empty-tree-table-wrapper">
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={false} />
        <div className="empty-tree-table-desc">{t('select_source_category')}</div>
      </div>
    </Show>
  );

  const renderTableHeader = () => {
    const handleExpandAll = () => {
      setExpandedKeys(categories?.map((item) => item.id));
    };

    const handleCollapseAll = () => {
      setExpandedKeys([]);
    };
    return (
      <div className="tree-table-actions">
        {treeDataSource?.length > 0 && (
          <>
            <AkinonButton className="title-section-btn" onClick={handleExpandAll}>
              {t('expand_all')}
            </AkinonButton>
            <AkinonButton className="title-section-btn" onClick={handleCollapseAll}>
              {t('minimize_all')}
            </AkinonButton>
          </>
        )}
      </div>
    );
  };

  return (
    <Table
      key="tree-table"
      className="tree-table"
      columns={columns}
      dataSource={treeDataSource}
      loading={{
        spinning: isLoading,
        indicator: <Spin />,
      }}
      locale={{
        emptyText: renderEmptyTableContent(),
      }}
      expandable={expandableConfig}
      title={renderTableHeader}
    />
  );
};

export default AttributeSetAndCategoryMapping;
