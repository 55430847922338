import { UserRole } from '@constants/auth';
import { limitQuery } from '@constants/commontypes';
import { useDataSourceShippingCompanyOptionConfigsQuery } from '@services/api/hooks/usePaginatedDataSourceShippingCompanyOptionConfigsQuery';
import { useShippingCompaniesQuery } from '@services/api/hooks/useShippingCompaniesQuery';
import { useShippingCompanyOptionsQuery } from '@services/api/hooks/useShippingCompanyOptionsQuery';
import { useShippingOptionsQuery } from '@services/api/hooks/useShippingOptionsQuery';
import { useUserRole } from '@utils/hooks/useUserRole';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import property from 'lodash/property';
import uniq from 'lodash/uniq';

export function useDataSource() {
  const userRole = useUserRole();
  const isSeller = userRole === UserRole.SELLER;

  const {
    dataSourceShippingCompanyOptionConfigs,
    isFetchingDataSourceShippingCompanyOptionConfigs,
    isFetchedDataSourceShippingCompanyOptionConfigs,
  } = useDataSourceShippingCompanyOptionConfigsQuery({
    queryOptions: {
      enabled: isSeller,
    },
  });

  const dataSourceShippingCompanyOptions = dataSourceShippingCompanyOptionConfigs?.map(
    (config) => config.shipping_company_option
  );
  const shippingCompanyOptionsParams = dataSourceShippingCompanyOptions
    ? {
        id__in: dataSourceShippingCompanyOptions.join(','),
        ...limitQuery,
      }
    : {
        sort: 'priority',
        ...limitQuery,
      };

  const { shippingCompanyOptions, isFetchingShippingCompanyOptions, totalShippingCompanyOptions } =
    useShippingCompanyOptionsQuery({
      params: shippingCompanyOptionsParams,
      queryOptions: {
        enabled: isSeller ? isFetchedDataSourceShippingCompanyOptionConfigs : true,
      },
    });

  const companyIds = uniq(map(shippingCompanyOptions, property('shipping_company')));
  const shippingOptionIds = uniq(map(shippingCompanyOptions, property('shipping_option')));

  const { shippingCompanies, isFetchingShippingCompanies } = useShippingCompaniesQuery({
    params: {
      id__in: companyIds.join(','),
      length: companyIds.length,
    },
    queryOptions: {
      enabled: !isFetchingShippingCompanyOptions && !isEmpty(companyIds),
    },
  });

  const { shippingOptions, isFetchingShippingOptions } = useShippingOptionsQuery({
    params: {
      id__in: shippingOptionIds.join(','),
      length: shippingOptionIds.length,
    },
    queryOptions: {
      enabled: !isFetchingShippingCompanyOptions && !isEmpty(shippingOptionIds),
    },
  });

  const mpoDataSource = shippingCompanyOptions?.map((shippingCompanyOption) => {
    const shippingCompany = shippingCompanies?.find(
      (shippingCompany) => shippingCompany.id === shippingCompanyOption.shipping_company
    );

    const shippingOption = shippingOptions?.find(
      (shippingOption) => shippingOption.id === shippingCompanyOption.shipping_option
    );

    return {
      ...shippingCompanyOption,
      shipping_company_obj: shippingCompany,
      shipping_option_obj: shippingOption,
      is_real_active: shippingCompanyOption.is_active,
    };
  });

  const sellerDataSource = shippingCompanyOptions
    ?.map((shippingCompanyOption) => {
      const shippingCompany = shippingCompanies?.find(
        (shippingCompany) => shippingCompany.id === shippingCompanyOption.shipping_company
      );

      const shippingOption = shippingOptions?.find(
        (shippingOption) => shippingOption.id === shippingCompanyOption.shipping_option
      );

      const shippingCompanyOptionConfig = dataSourceShippingCompanyOptionConfigs?.find(
        (shippingCompanyOptionConfig) =>
          shippingCompanyOptionConfig.shipping_company_option === shippingCompanyOption.id
      );

      if (!shippingCompanyOptionConfig) {
        return false;
      }

      return {
        ...shippingCompanyOption,
        shipping_company_obj: shippingCompany,
        shipping_option_obj: shippingOption,
        shipping_company_option_config_obj: shippingCompanyOptionConfig,
        is_real_active: shippingCompanyOptionConfig?.is_active,
      };
    })
    .filter(Boolean);

  return {
    dataSource: isSeller ? sellerDataSource : mpoDataSource,
    total: totalShippingCompanyOptions,
    isLoading:
      isFetchingShippingCompanyOptions ||
      isFetchingShippingCompanies ||
      isFetchingShippingOptions ||
      isFetchingDataSourceShippingCompanyOptionConfigs,
  };
}
