import { getUserRole } from '@common/auth';
import { UserRole } from '@constants/auth';
import { useUser } from '@root/contexts/hooks/useUser';
import useStore from '@zustand-store/index';
import { setPageMetaSelector } from '@zustand-store/selectors/app';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUnmount } from 'react-use';

export function usePageMeta({ setIsNewSellerFormVisible }) {
  const { t } = useTranslation('Finance');
  const setPageMeta = useStore(setPageMetaSelector);
  const user = useUser();

  const userRole = getUserRole({ user });
  const isSuperUser = userRole === UserRole.SUPER_USER;

  useEffect(() => {
    setPageMeta({
      title: isSuperUser ? t('seller.management') : t('account_management'),
      description: t('seller.management.desc'),
      actions: isSuperUser && [
        {
          label: t('seller_add_new'),
          onClick() {
            setIsNewSellerFormVisible(true);
          },
        },
      ],
    });
  }, [t]);

  useUnmount(() => {
    setPageMeta({});
  });
}
