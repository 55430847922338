import { AppstoreAddOutlined, DeploymentUnitOutlined } from '@ant-design/icons';
import cloneDeep from 'lodash/cloneDeep';
import first from 'lodash/first';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import omit from 'lodash/omit';
import reject from 'lodash/reject';
import set from 'lodash/set';
import * as z from 'zod';

import CollectionWidgetManagement from './CollectionWidgetManagement';
import WidgetManagement from './WidgetManagement';

export const PageStep = {
  WIDGET_MANAGEMENT: 0,
  RECOMMENDATION_SYSTEM: 1,
};

export const pageWidgetSchemaIdMap = {
  widgetManagementId: 0,
  recommendationSystemId: 1,
};

const StoreManagementStepKeys = {
  WIDGET_MANAGEMENT: 'widgetManagement',
  RECOMMENDATION_SYSTEM: 'recommendationSystem',
};

export const getStepItems = ({ t }) => {
  return [
    {
      title: t('widget_management_title'),
      content: <WidgetManagement />,
      icon: <AppstoreAddOutlined />,
      key: StoreManagementStepKeys.WIDGET_MANAGEMENT,
    },
    {
      title: t('recommendation_system_title'),
      content: <CollectionWidgetManagement />,
      icon: <DeploymentUnitOutlined />,
      key: StoreManagementStepKeys.RECOMMENDATION_SYSTEM,
    },
  ];
};

export const SchemaDataType = {
  TEXT: 'text',
  IMAGE: 'image',
  FILE: 'file',
  TEXTAREA: 'textarea',
  HTML_EDITOR: 'html-editor',
  DATEPICKER: 'datepicker',
  NESTED: 'nested',
  DROPDOWN: 'dropdown',
};

export const FormItemType = {
  NESTED: 'NestedWidget',
  MULTI_NESTED: 'MultiNestedWidget',
  MULTI: 'MultiWidget',
  SINGLE: 'SingleWidget',
};

// nested means object multi means array if it's nested abd multi it means array of objects

export const getDynamicZodSchema = ({ t, widget }) => {
  const { schema, data_type, multi, display } = widget;
  const type = display ?? data_type;
  if (data_type === SchemaDataType.NESTED && multi) {
    return z.array(
      z.object({
        ...Object.entries(schema ?? {}).reduce((acc, [key, value]) => {
          return {
            ...acc,
            [key]: getDynamicZodSchema({ t, widget: value }).optional(),
          };
        }, {}),
      })
    );
  } else if (multi) {
    return z.array(getDynamicZodSchema({ t, widget: { data_type } }).optional());
  } else if (data_type === SchemaDataType.NESTED) {
    return z.object({
      ...Object.entries(schema ?? {}).reduce((acc, [key, value]) => {
        return {
          ...acc,
          [key]: getDynamicZodSchema({ t, widget: value }).optional(),
        };
      }, {}),
    });
  } else if (type === SchemaDataType.FILE || type === SchemaDataType.IMAGE) {
    return z.array(
      z
        .object({
          base64: z.string().optional(),
        })
        .optional()
    );
  } else if (type === SchemaDataType.DATEPICKER) {
    return z.date();
  }

  return z.string().nullable();
};

export const getRequestBodyFromFormValues = ({ formValues, widgetSchema }) => {
  const requestBody = cloneDeep(formValues);

  for (const [key, widget] of Object.entries(widgetSchema ?? {})) {
    const {
      widget: _widget,
      name,
      label,
      ...inputs
    } = get(generateFormObject({ widget, prefix: 'attributes' }), key, {});

    const getInputs = (_inputs) => {
      return Object.values(_inputs)
        .map((input) => {
          if (input.widget === FormItemType.SINGLE) {
            return input;
          } else {
            return getInputs(omit(input, ['widget', 'label', 'name']));
          }
        })
        .flat();
    };

    for (const input of getInputs(inputs)) {
      const isFileInput = input.type === SchemaDataType.FILE || input.type === SchemaDataType.IMAGE;
      if (!isFileInput) continue;
      let value = get(formValues, input.name);
      if (_widget === FormItemType.MULTI_NESTED) {
        const _namePath = input.name.split('.').slice(0, -1);
        const values = get(formValues, _namePath.join('.'));
        if (values?.length > 0) {
          for (const index in values) {
            const _name = [..._namePath, index, input.key].join('.');
            const _value = get(formValues, _name);
            const firstValue = first(
              reject(
                _value?.map((file) => file?.base64),
                isNil
              )
            );

            if (firstValue) set(requestBody, _name, firstValue);
            else {
              const deleted = omit(requestBody, _name);
              set(requestBody, _namePath.join('.'), get(deleted, _namePath.join('.'), []));
            }
          }
        }
      } else if (input.multi && value) {
        const multiValues = value?.flatMap((files) =>
          reject(
            files?.map((file) => file?.base64),
            isNil
          )
        );
        if (multiValues.length > 0) set(requestBody, input.name, multiValues);
        else {
          const deleted = omit(requestBody, input.name);
          set(requestBody, name, get(deleted, name, []));
        }
      } else {
        const firstValue = first(
          reject(
            value?.map((file) => file?.base64),
            isNil
          )
        );
        if (firstValue) set(requestBody, input.name, firstValue);
        else {
          const deleted = omit(requestBody, input.name);
          set(requestBody, name, get(deleted, name, {}));
        }
      }
    }
  }

  return requestBody;
};

export const normalizeFileList = (value, kwargs) => {
  const fileList = isString(value)
    ? [{ url: kwargs?.url, name: value, uid: '-1' }]
    : isArray(value)
      ? value
      : [];
  return fileList;
};

export const generateFormObject = ({ widget, prefix = 'attributes' }) => {
  const { data_type, display, key, label, multi, schema } = widget;
  const __key = `${prefix}.${key}`;
  if (data_type === SchemaDataType.NESTED && multi) {
    const multiNestedSchema = {};
    for (const [schemaKey, schemaValue] of Object.entries(schema ?? {})) {
      multiNestedSchema[key] = {
        ...multiNestedSchema[key],
        [schemaKey]: generateFormObject({
          widget: schemaValue,
          prefix: `${__key}`,
        }),
      };
    }
    multiNestedSchema[key] = {
      ...multiNestedSchema[key],
      widget: FormItemType.MULTI_NESTED,
      name: `${__key}`,
      label,
    };
    return multiNestedSchema;
  } else if (multi) {
    const multiSchema = schema ?? {
      name: __key,
      widget: FormItemType.MULTI,
      label,
    };
    return {
      ...multiSchema,
      [key]: {
        type: display ?? data_type,
        multi: true,
        name: __key,
        label,
        widget: FormItemType.SINGLE,
      },
    };
  } else if (data_type === SchemaDataType.NESTED) {
    const nestedSchema = {};
    for (const [schemaKey, schemaValue] of Object.entries(schema ?? {})) {
      nestedSchema[key] = {
        ...nestedSchema[key],
        [schemaKey]: generateFormObject({
          widget: schemaValue,
          prefix: `${__key}`,
        }),
      };
    }
    nestedSchema[key] = {
      ...nestedSchema[key],
      widget: FormItemType.NESTED,
      name: __key,
      label,
    };
    return nestedSchema;
  }

  return {
    type: display ?? data_type,
    label,
    name: __key,
    key,
    widget: FormItemType.SINGLE,
  };
};
