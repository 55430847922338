import {
  attributeSetUrl,
  getAttributeConfigsUrl,
  getAttributeOptionsUrl,
} from '@constants/apiUrls';
import { limitQuery } from '@constants/commontypes';
import { client } from '@services/api/client';

export const getAttributeConfigs = async ({ attributeSetId }) => {
  return client.get(getAttributeConfigsUrl, {
    params: {
      attribute_set: attributeSetId,
      ...limitQuery,
    },
  });
};

export const getAttributeSet = async ({ attributeSetId }) => {
  return client.get(attributeSetUrl, {
    urlParams: { attributeSetId },
  });
};

export const getAttributeValues = async ({ requestConfig }) => {
  return client.get(getAttributeOptionsUrl, requestConfig);
};
