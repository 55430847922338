import { exportOffersUrl } from '@constants/apiUrls';
import { HttpMethod } from '@constants/commontypes';
import isNil from 'lodash/isNil';

import { useAkinonMutation } from '../adapters/useAkinonMutation';
import { client } from '../client';

/**
 * @param {{ mutationOptions: import('@tanstack/react-query').UseMutationOptions }} args
 */
export function useExportOffersMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn({ requestBody }) {
      const fileFilter = Object.values(requestBody ?? {}).find((filter) => filter?.originFileObj);
      const isFilterWithFile = !isNil(fileFilter);

      if (isFilterWithFile) {
        const formData = new FormData();
        formData.append('filter_file', new Blob([fileFilter.originFileObj]), fileFilter.name);
        requestBody.data = formData;
      }
      const { originFileObj, filter_file, data, ...params } = requestBody;
      return client.post(exportOffersUrl, data, {
        params,
        headers: {
          'Origin-Method': HttpMethod.GET,
        },
      });
    },
    ...mutationOptions,
  });

  return {
    exportOffers: mutate,
    isExportingOffers: isLoading,
  };
}
