import { liveVersionUrlPredicates, urlUserRoleMap, UserRole } from '@constants/auth';
import store from '@zustand-store/index';
import first from 'lodash/first';
import compose from 'lodash/fp/compose';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';

export const getAuthObjects = () => store.getState().tokens;

export const addAuthObject = (tokenMeta) => {
  const addToken = store.getState().addToken;
  addToken(tokenMeta);
};

export const getActiveAuthObject = compose(first, getAuthObjects);

export const updateAuthObject = (access, tokenMeta) => {
  const updateToken = store.getState().updateToken;
  updateToken(access, tokenMeta);
};

export const removeAuthObject = (access) => {
  const removeToken = store.getState().removeToken;
  removeToken(access);
};

export const resetAuthObjects = () => {
  const resetTokens = store.getState().resetTokens;
  resetTokens();
};

export const getUserRole = ({ user }) => {
  if (isEmpty(user)) return UserRole.GUEST;

  return user.is_superuser ? UserRole.SUPER_USER : UserRole.SELLER;
};

export const findIsUserRoleAuthorized = ({ currentUserRole, authorizedUserRole }) => {
  return isUndefined(authorizedUserRole) || authorizedUserRole === currentUserRole;
};

/**
 * @param {{ navigationItems: import('@components/Navigation/List').NavigationListItem[], userRole: string, isLiveAppVersion: boolean }} args
 * @returns {import('@components/Navigation/List').NavigationListItem[]}
 */
export const createNavigationItems = ({ navigationItems, userRole, isLiveAppVersion }) =>
  navigationItems.map((navigationItem) => ({
    ...navigationItem,
    isVisible:
      (navigationItem.isVisible ?? true) &&
      findIsUserRoleAuthorized({
        currentUserRole: userRole,
        authorizedUserRole: urlUserRoleMap[navigationItem.to],
      }) &&
      (!isLiveAppVersion ||
        liveVersionUrlPredicates.some((predicate) => predicate(navigationItem.to))),
  }));
