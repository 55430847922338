import omit from 'lodash/omit';
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';

/**
 *
 * @param {import('react-router-dom').LinkProps} props
 */
const Link = (props) => {
  const isLocalAbsoluteUrl = props.to?.startsWith?.('/');
  const appPath = props.to;
  const omittedProps = omit(props, ['to', 'children']);
  const { children } = props;

  return (
    <ReactRouterLink {...omittedProps} to={isLocalAbsoluteUrl ? appPath : props.to}>
      {children}
    </ReactRouterLink>
  );
};

export default Link;
