import { getDataSourceDetail } from '@services/api';
import { useQuery } from '@tanstack/react-query';

/**
 * @typedef {import('@tanstack/react-query').UseQueryResult} UseQueryResult
 * @returns {import('@tanstack/react-query').UseQueryResult}
 */
export const useDataSourceDetail = ({ dataSourceId, enabled = true }) => {
  const queryKey = ['data-source-detail', dataSourceId];
  const queryFn = () => getDataSourceDetail(dataSourceId);
  const { data, isLoading, error } = useQuery({ queryKey, queryFn, enabled });

  return {
    data,
    isLoading,
    error,
  };
};
