import {
  approveCancellationRequestUrl,
  getCancellationRequestsUrl,
  rejectCancellationRequestUrl,
} from '@constants/apiUrls';
import { client } from '@services/api/client';

export const getCancellationRequests = (params) => {
  return client.get(getCancellationRequestsUrl, { params });
};

export const approveCancellationRequest = ({ options = {} }) => {
  const { requestId, params } = options;
  return client.post(
    approveCancellationRequestUrl,
    {
      params,
    },
    {
      urlParams: { requestId },
    }
  );
};

export const rejectCancellationRequest = ({ options = {} }) => {
  const { requestId, params } = options;

  return client.post(
    rejectCancellationRequestUrl,
    {
      params,
    },
    { urlParams: { requestId } }
  );
};
