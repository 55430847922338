import { limitQuery } from '@constants/commontypes';
import { useProductImagesQuery, useProductsQuery } from '@services/api/hooks';
import { useAttributesQuery } from '@services/api/hooks/useAttributesQuery';
import { usePaginatedProductRevisionsQuery } from '@services/api/hooks/usePaginatedProductRevisionsQuery';
import { useProductRevisionsImagesQuery } from '@services/api/hooks/useProductRevisionsImagesQuery';
import isNil from 'lodash/isNil';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import uniq from 'lodash/uniq';

import { DataIndex } from '../common';

const useDataSource = ({ pagination, filters }) => {
  const { productRevisions, totalProductRevisions, isFetchingProductRevisions } =
    usePaginatedProductRevisionsQuery({ pagination, filters });

  const { attributes } = useAttributesQuery();

  const productIds = uniq(map(productRevisions, 'product'));

  const { productRevisionImages } = useProductRevisionsImagesQuery({
    params: {
      id__in: productIds?.join(','),
    },
  });
  const { productImages } = useProductImagesQuery({
    params: {
      parent__in: productIds?.join(','),
      ...limitQuery,
    },
  });

  const { products } = useProductsQuery({
    params: {
      limit: productIds?.length,
      id__in: productIds?.join(','),
    },
  });
  const productsMap = keyBy(products, 'id');
  const dataSource = productRevisions?.map?.((productRevision) => {
    const product = productsMap[productRevision.product];
    const revisionImages = productRevisionImages?.filter(
      (image) => image.parent === productRevision.id && !isNil(image.image)
    );
    const images = productImages?.filter(
      (image) => image.parent === product?.id && !isNil(image.image)
    );

    if (product) {
      productRevision[DataIndex.CURRENT_ATTRIBUTES] = merge(
        product?.attributes,
        pick(product, 'name')
      );
      productRevision.product_detail = product;
    }
    if (revisionImages?.length > 0) {
      productRevision.revisionImages = revisionImages;
    }
    if (images?.length > 0) {
      productRevision.images = images;
    }

    return productRevision;
  });

  return {
    dataSource,
    isFetchingProductRevisions,
    totalProductRevisions,
    products,
    attributes,
  };
};

export default useDataSource;
