import { useProductImagesQuery } from '@services/api/hooks/usePaginatedProductImagesQuery';
import { usePaginatedProductsQuery } from '@services/api/hooks/usePaginatedProductsQuery';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

import { DataIndexKey } from '../common';

export function useDataSource({ pagination, filters }) {
  const { products, isFetchingProducts, totalProducts } = usePaginatedProductsQuery({
    pagination,
    filters,
  });

  const productIds = products?.map(({ id }) => id).filter(Boolean);
  const { productImages, isFetchingProductImages } = useProductImagesQuery({
    params: {
      limit: productIds?.length,
      parent__in: uniq(productIds).join(','),
    },
    queryOptions: {
      enabled: !isEmpty(productIds),
    },
  });

  const dataSource = products?.map((product) => ({
    ...product,
    [DataIndexKey.IMAGE]: productImages?.find((image) => image?.parent === product?.id)?.image,
  }));
  const isDataSourceLoading = isFetchingProducts || isFetchingProductImages;

  return {
    dataSource,
    isDataSourceLoading,
    total: totalProducts,
  };
}
