import { cancelPreviousRequests } from '@services/api/client';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

const useCancelPreviousRequests = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [previousPath, setPreviousPath] = useLocalStorage(currentPath);

  useEffect(() => {
    if (previousPath !== currentPath) {
      cancelPreviousRequests(previousPath);
      setPreviousPath(currentPath);
    }
  }, [currentPath]);
};

export default useCancelPreviousRequests;
