import { patchProductPrice } from '@services/api';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';

export function usePatchProductPriceMutation({ mutationOptions = {} }) {
  const { mutate, mutateAsync, isLoading } = useAkinonMutation({
    mutationFn: patchProductPrice,
    ...mutationOptions,
  });

  return {
    patchProductPrice: mutate,
    patchProductPriceAsync: mutateAsync,
    isPatchProductPriceLoading: isLoading,
  };
}
