import { QueryKey } from '@constants/query';
import { getLanguages } from '@services/api/common';
import { useQuery } from '@tanstack/react-query';

/**
 * @param {import('@tanstack/react-query').UseQueryOptions} queryOptions
 */
export function useLanguagesQuery(queryOptions = {}) {
  const queryResponse = useQuery({
    queryKey: [QueryKey.LANGUAGES],
    queryFn: getLanguages,
    ...queryOptions,
  });

  return {
    ...queryResponse,
    languages: queryResponse.data,
    getLanguages: queryResponse.refetch,
    isLanguagesLoading: queryResponse.isLoading,
    isLanguagesInitialLoading: queryResponse.isInitialLoading,
  };
}
