import './style.scss';

import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import AkinonInput from '@components/AkinonInput';
import Box from '@components/utility/box';
import { RouteUrls } from '@constants/routeUrls';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUser } from '@root/contexts/hooks/useUser';
import useAppNavigate from '@root/hooks/useAppNavigate';
import { useDataSourceCreateUser } from '@services/hooks/datasources/useDataSourceCreateUser';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormKey, schema } from './common';

const SellerCreateUser = () => {
  const { t } = useTranslation('Settings');
  const navigate = useAppNavigate();
  const user = useUser();
  const dataSourceId = user?.datasource;
  const { handleSubmit, control } = useForm({
    defaultValues: {},
    resolver: zodResolver(schema),
  });

  const { mutate: createUser, isLoading } = useDataSourceCreateUser({
    dataSourceId,
    onSuccess: () => navigate(RouteUrls.settings.sellerUserManagementSettings),
  });

  const onSubmit = (values) => {
    createUser(values);
  };

  return (
    <section className="seller-create-user-form">
      <Box className="box-primary" title={t('add_user')} subtitle={t('add_user_desc')}>
        <AkinonForm className="akn-form akn-form-v2" layout="vertical">
          <AkinonInput
            formItemProps={{
              label: t('email'),
              name: FormKey.EMAIL,
              control,
              required: true,
            }}
            placeholder={t('enter_variable', { variable: t('email') })}
          />
          <AkinonInput
            formItemProps={{
              label: t('first_name'),
              name: FormKey.FIRST_NAME,
              control,
              required: true,
            }}
            placeholder={t('enter_variable', { variable: t('first_name') })}
          />
          <AkinonInput
            formItemProps={{
              label: t('last_name'),
              name: FormKey.LAST_NAME,
              control,
              required: true,
            }}
            placeholder={t('enter_variable', { variable: t('last_name') })}
          />
          <AkinonButton
            htmlType="submit"
            type="primary"
            className="akn-submit-button"
            loading={isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            {t('save')}
          </AkinonButton>
        </AkinonForm>
      </Box>
    </section>
  );
};

export default SellerCreateUser;
