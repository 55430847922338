import { createNavigationItems, getUserRole } from '@common/auth';
import { RouteUrls } from '@constants/routeUrls';
import React from 'react';

export const getNavigationItems = ({ t, user, isLiveAppVersion }) => {
  const userRole = getUserRole({ user });

  return createNavigationItems({
    navigationItems: [
      {
        to: RouteUrls.reporting.sellerPerformance,
        title: t('seller.performance.dashboard'),
        description: t('seller.performance.dashboard.desc'),
        icon: <i className="icon-iade" />,
      },
      {
        to: RouteUrls.reporting.salesPerformance,
        title: t('sales.performance'),
        description: t('sales.performance.desc'),
        icon: <i className="icon-siparis" />,
      },
    ],
    userRole,
    isLiveAppVersion,
  });
};
