import { getRequestFiltersParams, getRequestPaginationParams } from '@common/index';
import { getProductPricesUrl } from '@constants/apiUrls';
import { HttpMethod } from '@constants/commontypes';
import { QueryKey } from '@constants/query';

import { useAkinonQuery } from '../adapters/useAkinonQuery';
import { client } from '../client';

const fetchProductPriceList = async (queryParams, fileFilter) => {
  if (fileFilter) {
    const formData = new FormData();
    formData.append('filter_file', new Blob([fileFilter.originFileObj]), fileFilter.name);
    return await client.post(getProductPricesUrl, formData, {
      params: queryParams,
      headers: {
        'Origin-Method': HttpMethod.GET,
      },
    });
  }
  return await client.get(getProductPricesUrl, { params: queryParams });
};

export function useProductPriceListQuery({ requestConfig, queryOptions = {}, onSuccess } = {}) {
  const { data, isFetching, isLoading, error } = useAkinonQuery({
    queryKey: [QueryKey.PRODUCT_PRICES, requestConfig],
    async queryFn() {
      const response = await client.get(getProductPricesUrl, requestConfig);
      onSuccess?.(response);

      return response;
    },
    ...queryOptions,
  });

  return {
    productPrices: data?.results,
    isProductPricesLoadingMutating: isLoading,
    isProductPricesFetching: isFetching,
    productPricesError: error,
  };
}

export function usePaginatedProductPriceListQuery({
  pagination,
  filters,
  params,
  onSuccess,
  queryOptions,
} = {}) {
  const { data, isFetching, error, refetch } = useAkinonQuery({
    queryKey: [QueryKey.PRODUCT_PRICES, pagination, filters, params],
    async queryFn() {
      const fileFilter = Object.values(filters ?? {}).find((filter) => filter?.originFileObj);
      const filterParams = getRequestFiltersParams(filters);
      delete filterParams?.filter_file;
      const queryParams = {
        ...getRequestPaginationParams(pagination),
        ...filterParams,
        ...params,
      };

      const response = await fetchProductPriceList(queryParams, fileFilter);
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    productPrices: data?.results,
    totalProductPrices: data?.count,
    isFetchingProductPrices: isFetching,
    productPricesError: error,
    refetchProductPrices: refetch,
  };
}
