import './styles.scss';

import { DownloadOutlined } from '@ant-design/icons';
import { dateWith12HoursFormat } from '@common/date';
import { getUserName } from '@common/index';
import AkinonSpin from '@components/AkinonSpin';
import { useUser } from '@root/contexts/hooks/useUser';
import useDocumentDownloadQuery from '@services/api/hooks/useDocumentDownloadQuery';
import { downloadBinaryFile, getFilenameFromURL } from '@utils/index';
import { Divider, Space, Tooltip, Typography } from 'antd';
import clsx from 'clsx';
import DOMPurify from 'dompurify';
import last from 'lodash/last';
import truncate from 'lodash/truncate';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const { Title, Text } = Typography;

function TicketMessage({ message }) {
  const { t } = useTranslation('Support');
  const me = useUser();
  const senderName = getUserName({ user: message?.sender });
  const isMessageMine = me?.id === message?.sender?.id;
  const [documentUrl, setDcoumentUrl] = useState();
  const { isFetching } = useDocumentDownloadQuery({
    documentUrl: documentUrl,
    queryOptions: {
      enabled: Boolean(documentUrl),
    },
    onSuccess: (data) => {
      downloadBinaryFile(data, getFilenameFromURL(documentUrl));
      setDcoumentUrl(null);
    },
  });
  return (
    <article className={clsx('ticket-message')}>
      <div
        className={clsx('ticket-content-wrapper', {
          'ticket-content-wrapper--mine': isMessageMine,
        })}
      >
        <Title
          level={3}
          className={clsx('ticket-message__title', {
            'ticket-message__title--mine': isMessageMine,
          })}
        >
          {moment(message?.created_at).format(dateWith12HoursFormat)} (
          {isMessageMine ? t('you') : senderName})
        </Title>
        <div
          className={clsx('ticket-message__body', {
            'ticket-message__body--mine': isMessageMine,
          })}
        >
          <div
            className={clsx('ticket-message__content', {
              'ticket-message__content--mine': isMessageMine,
            })}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message?.content) }}
          />
          <div
            className={clsx('ticket-message__attachments', {
              'ticket-message__attachments--mine': isMessageMine,
            })}
          >
            {message?.attachments?.map((attachment) => (
              <AkinonSpin key={attachment?.file} spinning={isFetching}>
                <button
                  className={clsx('ticket-message__attachment', {
                    'ticket-message__attachment--mine': isMessageMine,
                  })}
                  onClick={(e) => {
                    e.preventDefault();
                    setDcoumentUrl(attachment?.file);
                  }}
                >
                  <Space size={12}>
                    <Text
                      className={clsx('ticket-message__attachment-name', {
                        'ticket-message__attachment-name--mine': isMessageMine,
                      })}
                    >
                      <Tooltip title={last(attachment?.file?.split('/'))}>
                        {t('uploadedFile', {
                          fileName: truncate(last(attachment?.file?.split('/')), { length: 12 }),
                        })}
                      </Tooltip>
                    </Text>
                    <DownloadOutlined style={{ color: 'white' }} />
                  </Space>
                </button>
              </AkinonSpin>
            ))}
          </div>
        </div>
      </div>
      <Divider className="ticket-message__divider" />
    </article>
  );
}

export default TicketMessage;
