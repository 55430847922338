import { postSubmitProductRevisionUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { client } from '@services/api/client';

export function usePostSubmitProductRevisionMutation({ mutationOptions } = {}) {
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ productRevisionId, requestBody }) =>
      client.post(postSubmitProductRevisionUrl, requestBody, {
        urlParams: { productRevisionId },
      }),
    ...mutationOptions,
  });

  return {
    submitProductRevision: mutate,
    isSubmittingProductRevision: isLoading,
  };
}
