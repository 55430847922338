import { postDataSourceShippingCompanyOptionConfigUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useQueryClient } from '@tanstack/react-query';

import { useAkinonMutation } from '../adapters/useAkinonMutation';
import { client } from '../client';

export function usePostDataSourceShippingCompanyOptionConfigMutation() {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useAkinonMutation({
    mutationFn: ({ requestBody }) =>
      client.post(postDataSourceShippingCompanyOptionConfigUrl, requestBody),
    onSuccess() {
      queryClient.invalidateQueries([QueryKey.DATA_SOURCE_SHIPPING_COMPANY_OPTION_CONFIGS]);
    },
  });

  return {
    createDataSourceShippingCompanyOptionConfig: mutate,
    isCreatingDataSourceShippingCompanyOptionConfig: isLoading,
  };
}
