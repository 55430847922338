import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

export const FormMetaKey = {
  EMAIL: 'email',
  PASSWORD: 'password',
};

export const signInFormResolver = zodResolver(
  z.object({
    [FormMetaKey.EMAIL]: z.string().min(1),
    [FormMetaKey.PASSWORD]: z.string().min(1),
  })
);
