import { postBasketOfferParticipantsUrl } from '@constants/apiUrls';
import { useAkinonMutation } from '@services/api/adapters/useAkinonMutation';
import { client } from '@services/api/client';

/**
 * @param {{ mutationOptions: import('@tanstack/react-query').UseMutationOptions }} args
 */
export function usePostBasketOfferParticipantMutation({ mutationOptions } = {}) {
  const { isLoading, mutate } = useAkinonMutation({
    mutationFn: ({ requestBody }) => client.post(postBasketOfferParticipantsUrl, requestBody),
    ...mutationOptions,
  });

  return {
    postBasketOfferParticipants: mutate,
    isPostingBasketOfferParticipants: isLoading,
  };
}
