import { getBasketOfferUrl } from '@constants/apiUrls';
import { QueryKey } from '@constants/query';
import { useAkinonQuery } from '@services/api/adapters/useAkinonQuery';
import { client } from '@services/api/client';

export function useBasketOfferQuery({ queryOptions, onSuccess } = {}) {
  const { data, isLoading } = useAkinonQuery({
    queryKey: [QueryKey.BASKET_OFFER],
    async queryFn() {
      const response = await client.get(getBasketOfferUrl);
      onSuccess?.(response);
      return response;
    },
    ...queryOptions,
  });

  return {
    basketOffers: data?.results,
    isBasketOffersLoading: isLoading,
    total: data?.count,
  };
}
