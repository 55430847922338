import { QueryKey } from '@constants/query';
import { getPaginatedDataSources } from '@services/api';
import { useQuery } from '@tanstack/react-query';

/**
 * @param {{ queryOptions: import('@tanstack/react-query').UseQueryOptions }} args
 */
export function useDataSourcesQuery({ queryOptions, params = {} } = {}) {
  const { data, isLoading } = useQuery({
    queryKey: [QueryKey.DATA_SOURCES, params],
    queryFn: () => getPaginatedDataSources(params),
    ...queryOptions,
  });

  return {
    dataSourcesResult: data,
    dataSources: data?.results,
    isDataSourcesLoading: isLoading,
  };
}
