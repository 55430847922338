import { useEffect } from 'react';

const useResetTableCurrentPageWhenFiltersChanged = ({ pagination, setPagination, filters }) => {
  useEffect(() => {
    setPagination({
      ...pagination,
      current: 1,
    });
  }, [filters]);
};

export { useResetTableCurrentPageWhenFiltersChanged };
// Deprecate this. Only named-exports.
export default useResetTableCurrentPageWhenFiltersChanged;
