import { QueryKey } from '@constants/query';
import { getProductMeta } from '@services/api';
import { useQuery } from '@tanstack/react-query';

/**
 * @param {{ metaId: string, queryOptions: import('@tanstack/react-query').UseQueryOptions }} args
 */
export function useProductMetaQuery({ metaId, queryOptions }) {
  const { data, isLoading } = useQuery({
    queryKey: [QueryKey.PRODUCT_META, metaId],
    queryFn: () => getProductMeta({ metaId }),
    ...queryOptions,
  });

  return {
    productMetaResult: data,
    productMeta: data?.results,
    isProductMetaLoading: isLoading,
  };
}
