import { openNotification } from '@components/akinonNotification';
import { RouteUrls } from '@constants/routeUrls';
import useAppNavigate from '@root/hooks/useAppNavigate';
import isEmpty from 'lodash/isEmpty';
import negate from 'lodash/negate';
import pick from 'lodash/pick';

import { FormKey } from '../common';

export function useOnSubmit({ createTicket, createTicketMessage, t }) {
  const navigate = useAppNavigate();

  return (formValues) => {
    createTicket(
      {
        requestBody: pick(formValues, [FormKey.SUBJECT, FormKey.PRIORITY]),
        requestConfig: {
          suppressedNotifications: ['error', 'success'],
        },
      },
      {
        onSuccess({ id }) {
          const isMessageFieldFilled = negate(isEmpty)(formValues[FormKey.CONTENT]?.trim());
          const formData = new FormData();
          formData.append(FormKey.CONTENT, formValues[FormKey.CONTENT]);
          formValues[FormKey.ATTACHMENT]?.fileList.forEach((file) => {
            formData.append(FormKey.ATTACHMENT, file.originFileObj);
          });
          if (isMessageFieldFilled) {
            createTicketMessage(
              {
                requestBody: formData,
                requestConfig: {
                  suppressedNotifications: ['error', 'success'],
                  urlParams: { ticketId: id },
                },
              },
              {
                onSuccess() {
                  openNotification({
                    message: t('transactions.title.success'),
                    description: t('transactions.body.create_ticket_success'),
                    type: 'success',
                  });
                  navigate(RouteUrls.support.landing);
                },
                onError() {
                  openNotification({
                    message: t('transactions.title.unsuccess'),
                    description: t('transactions.body.create_ticket_unsuccess'),
                    type: 'error',
                  });
                },
              }
            );
          } else {
            openNotification({
              message: t('transactions.title.success'),
              description: t('transactions.body.create_ticket_success'),
              type: 'success',
            });
            navigate(RouteUrls.support.landing);
          }
        },
        onError() {
          openNotification({
            message: t('transactions.title.unsuccess'),
            description: t('transactions.body.create_ticket_unsuccess'),
            type: 'error',
          });
        },
      }
    );
  };
}
